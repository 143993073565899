import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { fetchAccounts } from 'state/accounts/actions';
import AccountDomains from './accountDomains';
import UsersSettings from './usersSettings';
import EstimateSettings from './estimateSettings';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
  item: {
    paddingBottom: theme.spacing(6),
  },
}));

const Settings = ({ accountId, fetchAccounts }) => {
  const classes = useStyles();
  useEffect(() => {
    fetchAccounts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (accountId) {
    return (
      <Paper className={classes.container}>
        <AccountDomains accountId={accountId} className={classes.item} />
        <UsersSettings accountId={accountId} className={classes.item} />
        <EstimateSettings accountId={accountId} className={classes.item} />
      </Paper>
    );
  }

  return <div>Select an account</div>;
};

const mapDispatchToProps = (dispatch, { accountId }) => ({
  fetchAccounts: () => dispatch(fetchAccounts()),
});

export default connect(null, mapDispatchToProps)(Settings);
