import React from 'react';
import { Formik, Field } from 'formik';
import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import FormikComboBox from 'components/filters/formik.comboBox';
import { map, times } from 'lodash/fp';
import { Events, track } from 'components/analytics';
import { getExportEstimatesWithLineItemsUrl } from 'state/estimates/selectors';
import { getAccounts } from 'state/accounts/selectors';
import { connect } from 'react-redux';
import { getDisplayCurrency, getUser } from 'state/authentication/selectors';

const useStyles = makeStyles(theme => ({
  content: {
    minWidth: 520,
  },
  field: {
    width: '48%',
    marginBottom: theme.spacing(2),
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionContainer: {
    display: 'flex',
    padding: theme.spacing(1, 0),
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  actionButton: {
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

const startYear = 2019;
const budgetYears = [
  startYear,
  ...times(i => startYear + i + 1, new Date().getFullYear() - startYear + 1),
];
const budgetYearOptions = map(year => ({ value: String(year), label: String(year) }), budgetYears);

const initialValues = {
  year: '',
  clientId: '',
};

const ExportSchema = Yup.object().shape({
  clientId: Yup.string().required('Required'),
  year: Yup.string().required('Required'),
});

const ExportLineItemsModal = ({ open, handleClose, accounts, displayCurrency, user }) => {
  const classes = useStyles();

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    window.location.href = getExportEstimatesWithLineItemsUrl(
      values.year,
      values.clientId,
      displayCurrency,
      user.client.id
    );
    handleClose(true);
    track(Events.EXPORTS_ALL_ESTIMATE_DATA);
    setSubmitting(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="estimate-lines-export-metadata"
        id="export-estimate-lines-modal"
      >
        <DialogTitle>Export Line Items</DialogTitle>
        <DialogContent className={classes.content}>
          <Formik
            validationSchema={ExportSchema}
            validateOnChange
            onSubmit={onSubmit}
            initialValues={initialValues}
          >
            {({ handleSubmit, isSubmitting, submitForm, errors, dirty, touched, setTouched }) => (
              <form onSubmit={handleSubmit}>
                <div className={classes.inputContainer}>
                  <Field
                    name="clientId"
                    label="Client"
                    variant="outlined"
                    id="client-select"
                    options={accounts}
                    className={classes.field}
                    classes={{ label: classes.label, root: classes.field }}
                    disableClearable={true}
                    component={FormikComboBox}
                  />

                  <Field
                    name="year"
                    label="Year"
                    variant="outlined"
                    id="year-select"
                    margin="none"
                    className={classes.field}
                    classes={{ label: classes.label, root: classes.field }}
                    options={budgetYearOptions}
                    disableClearable={true}
                    component={FormikComboBox}
                  />
                </div>
                <div className={classes.actionContainer}>
                  <Button color="primary" data-test="modal-cancel" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    data-test="export"
                    className={classes.actionButton}
                    type="submit"
                  >
                    Export
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    accounts: getAccounts(state)
      .filter(account => !account.agency)
      .map(account => ({
        value: account.id,
        label: account.name,
      })),
    displayCurrency: getDisplayCurrency(state),
    user: getUser(state),
  };
};

export default connect(mapStateToProps)(ExportLineItemsModal);
