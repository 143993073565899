import { findIndex } from 'lodash/fp';
import { NOTIFICATION_ADD, NOTIFICATION_CLOSE } from './actions';

const defaultState = {
  notifications: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case NOTIFICATION_ADD: {
      const notification = {
        ...action.notification,
        open: true,
      };
      return {
        notifications: [notification, ...state.notifications],
      };
    }

    case NOTIFICATION_CLOSE: {
      const i = findIndex({ id: action.notificationId }, state.notifications);
      if (i >= 0) {
        const notification = { ...state.notifications[i], open: false };
        const notifications = state.notifications.splice(i, 0, notification);
        return {
          ...state,
          notifications,
        };
      }
      return state;
    }

    default:
      return state;
  }
};
