import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { isEmpty, toUpper, map } from 'lodash/fp';
import { compose, getContext, withProps } from 'recompose';
import { Droppable, DragDropContext, Draggable } from 'react-beautiful-dnd';
import { formatAsCurrency } from 'cr-core/currencyUtils';
import withEditability from 'components/withEditability';
import withScrollTo from 'components/withScrollTo';
import SvgCollapseIcon from 'icons/CollapseIcon';
import SvgExpandIcon from 'icons/ExpandIcon';
import SvgRecommendedIcon from 'icons/RecommendedIcon';
import { getEstimateBidTotal, getValueCurrenciesForEstimate } from 'state/bids/selectors';
import {
  Expander,
  Row,
  RowsContainer,
  SubRowsContainer,
  RowTitle,
  FullWidthCell,
  TableFooterCell,
  TableHeaderCell,
  TableSection,
  HeaderCellActions,
} from './tableComponents';
import SelectBidButton from './selectBidButton';
import DeleteBidButton from './deleteBidButton';
import CostCategoryRow from './costCategoryRow';
import CostCategorySelect from './costCategorySelect';
import EditableNotesCell from './editableNotesCell';
import withExpandableness from './expandableness';
import LineItems from './lineItems';
import withAlternativeCurrencies from './withAlternativeCurrencies';
import withEditableness from './withEditableness';
import withForwardingRef from './withForwardingRef';
import withRecommendedBidSelected from './withRecommendedBidSelected';

export const HeaderActionContainer = styled.div`
  width: 305px;
  display: flex;
  justify-content: space-between;
`;

const RecommendedIcon = styled(SvgRecommendedIcon)`
  margin-right: 14px;
  flex-shrink: 0;
  font-size: 30px;
`;

export const AlternativeCurrencies = styled.span`
  color: #aaaaaa;
  font-weight: 400;
  margin-right: 5px;
`;

export const BidHeaderCell = compose(
  getContext({ estimate: PropTypes.object, readOnly: PropTypes.bool }),
  withRecommendedBidSelected,
  comp =>
    styled(comp)`
      &:last-child {
        // the size of the delete row button
        margin-right: 35px;
      }
    `
)(({ estimate, bidId, isRecommended, readOnly, deletable, pageId, index, ...props }) => {
  // const label = flow(get('bids'), find({ id: bidId }), get('label'))(estimate);
  const label = `Bid ${index + 1}`;
  return (
    <TableHeaderCell readOnly={readOnly} {...props} data-test="bid-label">
      {isRecommended && <RecommendedIcon title="Selected bid" />}
      <HeaderCellActions>
        {!readOnly && !isRecommended && (
          <SelectBidButton estimateId={estimate.id} bidId={bidId} pageId={pageId} />
        )}
        {!readOnly && deletable && (
          <DeleteBidButton estimateId={estimate.id} bidId={bidId} pageId={pageId} />
        )}
      </HeaderCellActions>
      {label}{' '}
    </TableHeaderCell>
  );
});

const BidFooterCell = compose(
  getContext({ estimate: PropTypes.object }),
  connect((state, { bidId, currency, estimate }) => ({
    values: getEstimateBidTotal(estimate, currency, bidId),
  })),
  withAlternativeCurrencies,
  withRecommendedBidSelected
)(
  ({
    alternativeCurrencies,
    displayCurrency,
    total,
    showAlternativeCurrencies = true,
    ...props
  }) => (
    <TableFooterCell {...props}>
      <div>
        {showAlternativeCurrencies && !isEmpty(alternativeCurrencies) && (
          <AlternativeCurrencies>({alternativeCurrencies})</AlternativeCurrencies>
        )}
        {total ? formatAsCurrency(displayCurrency, total) : '-'}
      </div>
    </TableFooterCell>
  )
);

const NewCostCategoryRow = compose(
  withEditability,
  getContext({
    estimate: PropTypes.object,
  })
)(({ estimate, pageId }) => (
  <RowsContainer>
    <Row>
      <CostCategorySelect estimate={estimate} pageId={pageId} />
    </Row>
  </RowsContainer>
));

const CostCategorySection = compose(
  withForwardingRef,
  withExpandableness,
  getContext({ readOnly: PropTypes.bool, isExpanded: PropTypes.bool })
)(
  ({
    costCategoryId,
    isExpanded,
    readOnly,
    forwardedRef,
    draggableProps,
    dragHandleProps,
    pageId,
  }) => (
    <TableSection ref={forwardedRef} {...draggableProps} data-test="cost-category-section">
      <CostCategoryRow
        costCategoryId={costCategoryId}
        dragHandleProps={dragHandleProps}
        pageId={pageId}
      />
      {(isExpanded || readOnly) && <LineItems costCategoryId={costCategoryId} pageId={pageId} />}
    </TableSection>
  )
);

const EditableCostCategorySection = props => (
  <TableSection {...props}>
    <NewCostCategoryRow {...props} />
  </TableSection>
);

export const EditableCostCategorySectionWithScrollTo = withScrollTo(EditableCostCategorySection);

const NotesRenderer = styled.div`
  white-space: pre-line;
  min-height: 150px;
  padding: 12px;
`;

const NotesCell = compose(
  getContext({ readOnly: PropTypes.bool }),
  withEditableness,
  withProps(({ cancel, save }) => ({
    cancel: e => {
      e.stopPropagation();
      cancel();
    },
    save: e => {
      e && e.stopPropagation();
      save();
    },
  }))
)(({ notes, readOnly, isEditing, save, edit, cancel, ...props }) => (
  <FullWidthCell onClick={edit} readOnly={readOnly} style={{ padding: 0 }}>
    {(readOnly || !isEditing) && <NotesRenderer>{notes}</NotesRenderer>}
    {!readOnly && isEditing && <EditableNotesCell save={save} cancel={cancel} />}
  </FullWidthCell>
));

export const NotesSection = compose(
  withExpandableness,
  getContext({
    estimate: PropTypes.object,
    readOnly: PropTypes.bool,
    toggleExpanded: PropTypes.func,
  }),
  withProps(({ estimate }) => ({
    notes: estimate.notes,
  }))
)(({ notes, isExpanded, readOnly, toggleExpanded }) => {
  return (
    <TableSection>
      <RowsContainer enabled={!readOnly} onClick={toggleExpanded}>
        <Row>
          <Expander>{isExpanded ? <SvgCollapseIcon /> : <SvgExpandIcon />}</Expander>
          <RowTitle>Notes</RowTitle>
        </Row>
      </RowsContainer>
      {(isExpanded || readOnly) && (
        <SubRowsContainer>
          <Row>
            <NotesCell notes={notes} />
          </Row>
        </SubRowsContainer>
      )}
    </TableSection>
  );
});

const BidFooterTitle = styled(RowTitle)`
  justify-content: flex-end;
  font-weight: bold;
`;

const TotalRow = styled(Row)`
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  &:first-child {
    border: 0;
  }
`;

const DeleteRowButtonPlaceHolder = styled.div`
  width: 35px;
`;

export const BidFooterRows = compose(
  getContext({
    estimate: PropTypes.object,
  }),
  connect((state, { estimate }) => ({
    bidIds: map('id', estimate.bids),
    // bidIds: getBidIdsForEstimate(state),
    currencies: getValueCurrenciesForEstimate(state),
  }))
)(({ bidIds, currencies }) => {
  return (
    <RowsContainer>
      {currencies.map(currency => (
        <Row className="row" key={currency}>
          <BidFooterTitle>Total payable in {toUpper(currency)}</BidFooterTitle>
          {bidIds.map(bidId => (
            <BidFooterCell key={bidId} bidId={bidId} currency={currency} />
          ))}
          <DeleteRowButtonPlaceHolder />
        </Row>
      ))}
      <TotalRow className="row">
        <BidFooterTitle>Total</BidFooterTitle>
        {bidIds.map(bidId => (
          <BidFooterCell
            className="last"
            key={bidId}
            bidId={bidId}
            data-test="bidTotal"
            showAlternativeCurrencies={false}
          />
        ))}
        <DeleteRowButtonPlaceHolder />
      </TotalRow>
    </RowsContainer>
  );
});

export const DraggableCostCategories = ({
  costCategoryIds,
  onDragEnd,
  expanded,
  lastLineItemIdAddedCategory,
  pageId,
}) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="costCategories" type="costCategory">
      {(provided, snapshot) => (
        <div ref={provided.innerRef}>
          {costCategoryIds.map((costCategoryId, index) => (
            <Draggable
              key={costCategoryId}
              draggableId={costCategoryId}
              index={index}
              type="costCategory"
            >
              {(provided, snapshot) => (
                <CostCategorySection
                  key={costCategoryId}
                  costCategoryId={costCategoryId}
                  ref={provided.innerRef}
                  draggableProps={provided.draggableProps}
                  dragHandleProps={provided.dragHandleProps}
                  expanded={expanded || !index || lastLineItemIdAddedCategory === costCategoryId}
                  pageId={pageId}
                />
              )}
            </Draggable>
          ))}
        </div>
      )}
    </Droppable>
  </DragDropContext>
);

export const StaticCostCategories = ({ costCategoryIds, expanded, pageId }) => (
  <>
    {costCategoryIds.map((costCategoryId, index) => (
      <CostCategorySection
        key={costCategoryId}
        costCategoryId={costCategoryId}
        expanded={expanded && index}
        pageId={pageId}
      />
    ))}
  </>
);
