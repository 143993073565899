import { find } from 'lodash/fp';
import { fetch } from 'state/fetchMiddleware';
import { fetchEstimate } from 'state/estimates/actions';
import { addCostCategory } from 'state/costCategories/actions';
import { getCostCategoryById } from 'state/costCategories/selectors';

export const ADD_COST_LINE_ITEM_SUCCESS = 'lineItems/ADD_SUCCESS';
export const REMOVE_LINE_ITEM_SUCCESS = 'lineItems/REMOVE_SUCCESS';
export const CREATE_LINE_ITEM_REQUEST = 'lineItems/CREATE_LINE_ITEM_REQUEST';
export const CREATE_LINE_ITEM_SUCCESS = 'lineItems/CREATE_LINE_ITEM_SUCCESS';
export const SAVE_LINE_ITEM_ORDER_REQUEST = 'estimate/SAVE_LINE_ITEM_ORDER_REQUEST';
export const SAVE_LINE_ITEM_ORDER_SUCCESS = 'estimate/SAVE_LINE_ITEM_ORDER_SUCCESS';
export const ADD_EMPTY_LINE_ITEM_TO_COST_CATEGORY = 'estimate/ADD_EMPTY_LINE_ITEM_TO_COST_CATEGORY';
export const REMOVE_EMPTY_LINE_ITEM_TO_COST_CATEGORY =
  'estimate/REMOVE_EMPTY_LINE_ITEM_TO_COST_CATEGORY';
export const RESET_LAST_LINE_ITEM_ADDED = 'lineItem/RESET_LAST_LINE_ITEM_ADDED';

// cretes a new line item in costCategory
export const createLineItem = (name, costCategoryId, accountId) => async dispatch => {
  dispatch({ type: CREATE_LINE_ITEM_REQUEST });
  const { data: lineItem } = await dispatch(
    fetch(`/costCategories/${costCategoryId}/lineItems`, {
      method: 'POST',
      data: {
        name,
        accountId,
      },
    })
  );

  dispatch({ type: CREATE_LINE_ITEM_SUCCESS, costCategoryId, lineItem });
  return lineItem;
};

export const addEmptyLineItemToCostCategory = (estimateId, costCategoryId, insertionIndex = -1) => {
  return {
    type: ADD_EMPTY_LINE_ITEM_TO_COST_CATEGORY,
    estimateId,
    costCategoryId,
    insertionIndex,
  };
};

export const removeNewLineItemFromCostCategory = (estimateId, costCategoryId) => ({
  type: REMOVE_EMPTY_LINE_ITEM_TO_COST_CATEGORY,
  estimateId,
  costCategoryId,
});

const addLineItemPost = (estimateId, costCategoryId, lineItemId, position) =>
  fetch(`/estimates/${estimateId}/costCategories/${costCategoryId}/lineItems/${lineItemId}`, {
    method: 'POST',
    data: {
      position,
    },
  });

// add line
export const addLineItem = (estimate, costCategoryId, lineItemId, position, pageId) => async (
  dispatch,
  getState
) => {
  const costCategory = find({ id: costCategoryId }, estimate.lineItemGroups);
  if (!costCategory) {
    await dispatch(addCostCategory(estimate.id, costCategoryId, position, pageId));
    const costCategory = getCostCategoryById(costCategoryId)(getState());
    const lineItem = find({ id: lineItemId }, costCategory.lineItemNames);
    if (lineItem.optional) {
      await dispatch(addLineItemPost(estimate.id, costCategoryId, lineItemId, position));
    }
  } else {
    const lineItem = find({ id: lineItemId }, costCategory.lineItemNames);
    if (!lineItem) {
      await dispatch(addLineItemPost(estimate.id, costCategoryId, lineItemId, position));
    }
  }

  dispatch({
    type: ADD_COST_LINE_ITEM_SUCCESS,
    estimateId: estimate.id,
    costCategoryId,
    lineItemId,
    position,
  });

  // for now refresh estimate:
  dispatch(fetchEstimate(estimate.id, pageId));
};

export const removeLineItem = (
  estimateId,
  costCategoryId,
  lineItemId,
  pageId
) => async dispatch => {
  await dispatch(
    fetch(`/estimates/${estimateId}/costCategories/${costCategoryId}/lineItems/${lineItemId}`, {
      method: 'DELETE',
    })
  );

  dispatch({ type: REMOVE_LINE_ITEM_SUCCESS, estimateId, costCategoryId });

  // for now refresh estimate:
  dispatch(fetchEstimate(estimateId, pageId));
};

export const saveLineItemOrder = ({
  estimateId,
  costCategoryId,
  lineItemNameId,
  position,
  pageId,
}) => async dispatch => {
  dispatch({
    type: SAVE_LINE_ITEM_ORDER_REQUEST,
    estimateId,
    costCategoryId,
    lineItemNameId,
    position,
  });

  await dispatch(
    fetch(`/estimates/${estimateId}/costCategories/${costCategoryId}/lineItems/${lineItemNameId}`, {
      method: 'PUT',
      data: {
        position,
      },
    })
  );

  dispatch({
    type: SAVE_LINE_ITEM_ORDER_SUCCESS,
    estimateId,
    costCategoryId,
    lineItemNameId,
    position,
  });

  // for now refresh estimate:
  dispatch(fetchEstimate(estimateId, pageId));
};

export const resetLastLineItemAdded = () => async dispatch => {
  dispatch({
    type: RESET_LAST_LINE_ITEM_ADDED,
  });
};
