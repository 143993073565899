import React from 'react';
import styled from 'styled-components';
import { MediaTypeIcon } from 'icons';

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  .icon {
    margin-right: 10px;
  }
`;

const Title = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;

export default ({ name, mediaType, className }) => (
  <TitleContainer className={className}>
    <MediaTypeIcon type={mediaType} />
    <Title data-test="title-text">{name ? name : <i>No Name</i>}</Title>
  </TitleContainer>
);
