import { fetch } from 'state/fetchMiddleware';

export const FETCH_EXCHANGE_RATES_REQUEST = 'exchangeRates/FETCH_EXCHANGE_RATES_REQUEST';
export const FETCH_EXCHANGE_RATES_SUCCESS = 'exchangeRates/FETCH_EXCHANGE_RATES_SUCCESS';

export const fetchExchangeRates = async dispatch => {
  dispatch({ type: FETCH_EXCHANGE_RATES_REQUEST });
  const { data: exchangeRates } = await dispatch(fetch('/exchangeRates'));
  dispatch({ type: FETCH_EXCHANGE_RATES_SUCCESS, exchangeRates });
};
