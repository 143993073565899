import styled from 'styled-components';
import { colors, font } from 'shared/styles';

export const ContentPageTitle = styled.div`
  font-size: ${font.sizes.veryLarge};
  font-weight: ${font.weights.bold};
  color: ${colors.text};
`;

export const Body = styled.div`
  font-size: ${font.sizes.large};
  color: ${colors.text};
`;
