import { filter } from 'lodash/fp';
import styled from 'styled-components';

export const getFilteredMenuItems = (items, { flags, isAdmin }) => {
  const filtered = filter(({ admin, flag }) => {
    if (admin && !isAdmin) {
      return false;
    }
    if (flag && !flags[flag]) {
      return false;
    }
    return true;
  }, items);

  return filtered;
};

export const Divider = styled.div`
  width: 2px;
  background-color: white;
  align-self: flex-start;
  flex-shrink: 0;
`;
