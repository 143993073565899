import React from 'react';
import { connect } from 'react-redux';
import { flow, map, groupBy, sumBy, sortBy, values, forEach } from 'lodash/fp';
import { BarChart } from 'components/charts';
import { getDisplayCurrency } from 'state/authentication/selectors';
import { ChartContainer } from '../components';

const getChartData = (estimates, displayCurrency) =>
  flow(
    forEach(item => {
      if (!item.leadMarket) {
        item.leadMarket = 'Not Set';
      }
    }),
    groupBy('leadMarket'),
    map(items => ({
      value: sumBy('costs.lowest', items),
      label: items[0].leadMarket,
    })),
    values,
    sortBy(({ value }) => -value),
  )(estimates);

const Chart = ({ estimates, displayCurrency }) => (
  <ChartContainer>
    <BarChart
      data={getChartData(estimates, displayCurrency)}
      displayCurrency={displayCurrency}
      width={100}
      height={40}
    />
  </ChartContainer>
);

const mapStateToProps = state => ({
  displayCurrency: getDisplayCurrency(state),
});

export default connect(mapStateToProps)(Chart);
