import ApiService from './ApiService';

interface Account {
  id: string;
}

interface User {
  id: string;
  isConsultant: boolean;
  accounts: Account[];
}

interface Workspace {
  id: string;
  name: string;
}

const WorkspaceService = {
  getAccountWorkspaces: (accountId: string) => ApiService.get(`/workspaces/accounts/${accountId}`),

  getWorkspaceUsers: (workspaceId: string) =>
    ApiService.get<{ data: User[] }>(`/workspaces/${workspaceId}/users`),

  getDivisonWorkspaces: (data: { divisions: string[] }) =>
    ApiService.post<{ data: Workspace[] }>(`/workspaces/byDivison`, data),

  getCampaigns: (workspaceId: string) => ApiService.get(`/campaignsByWorkspace/${workspaceId}`),
};

export default WorkspaceService;
