import styled from 'styled-components';
import { colors } from 'shared/styles';

const Loader = styled.div`
  // Animation
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }

  height: 16px;
  background: ${colors.cancel};
  content: ' ';
  width: 20%;
  margin-right: 20px;

  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: ${colors.loading};
  background: linear-gradient(to right, ${colors.loading} 8%, #f0f0f0 18%, ${colors.loading} 33%);
  background-size: 800px 45px;
  position: relative;
  box-sizing: border-box;

  &:last-child {
    margin-right: 0;
  }
`;

export default Loader;

export const LoaderIcon = styled(Loader)`
  width: 30px;
  height: 30px;
`;

export const LoaderLine = styled(Loader)`
  margin-top: 4px;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const LoaderLineSmall = styled(Loader)`
  height: 12px
  margin-top: 8px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;
