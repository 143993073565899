import React from 'react';
import onClickOutside from 'react-onclickoutside';
import { compose, toNumber, get } from 'lodash/fp';
import { connect } from 'react-redux';
import { withState, withHandlers, withProps } from 'recompose';
import styled from 'styled-components';
import { colors } from 'shared/styles';
import CurrencySelector from 'components/currencySelector';
import withEditConfirmationDialog from 'components/estimateEdit/withEditConfirmationDialog';
import SaveCancelPopup from 'components/forms/saveCancelPopup';
import { getDisplayCurrency } from 'state/authentication/selectors';
import { saveLineItemValue } from 'state/bids/actions';
import { getBidValueForLineItemId, getLineItemById } from 'state/bids/selectors';
import { Cell } from './tableComponents';
import { PseudoInput } from './editableCell';
import { showErrorNotification } from 'state/notifications/actions';
import LineItemSuppliers from './LineItemSuppliers';
import { Box } from '@material-ui/core';

const EditableContainer = styled(Cell)`
  position: relative;
  overflow: visible;
  overflow: visible;

  .currency-selector {
    height: 100%;
    width: auto;
  }

  .reactSelect__control {
    border-right: 0;
    border-radius: 0;
    height: 100%;
    width: 85px;
    border-color: #2684ff;
  }

  .reactSelect__value-container {
    padding: 0 2px;
  }

  .reactSelect__dropdown-indicator {
    padding: 0;
    color: ${colors.border};

    svg {
      height: 14px;
      width: 14px;
      margin: 3px;
    }
  }

  .reactSelect__input input {
    max-width: 2px;
  }
`;

const EditableCostCell = ({
  handleSubmit,
  cancel,
  isDirty,
  onAmountChange,
  onCurrencyChange,
  currency,
  amount,
  handleFocus,
  handleKeyPress,
  className,
  pageId,
  estimateId,
  suppliers,
  clientId,
  bidId,
  save,
  onRef,
  lineItemNameId,
  mandatory,
  costCategoryId,
  supplierRequiredStatus,
}) => {
  return (
    <Box display="flex" flexDirection="column" minWidth={100} maxWidth={250}>
      <div>
        <LineItemSuppliers
          accountId={clientId}
          initialValue={suppliers}
          bidId={bidId}
          estimateId={estimateId}
          ref={onRef}
          lineItemNameId={lineItemNameId}
        />
      </div>
      <EditableContainer className={className}>
        <CurrencySelector
          onChange={({ value }) => onCurrencyChange(value)}
          selectedCurrency={currency}
        />
        <PseudoInput>
          <input
            defaultValue={amount}
            type="number"
            name="amount"
            autoFocus
            onChange={onAmountChange}
            onFocus={handleFocus}
            onKeyPress={handleKeyPress}
          />
        </PseudoInput>
        {isDirty && (
          <SaveCancelPopup
            onSave={handleSubmit}
            onCancel={cancel}
            className="editableCell-saveCancelPopup"
          />
        )}
      </EditableContainer>
    </Box>
  );
};

const mapStateToProps = (
  state,
  { estimateId, bidId, lineItemNameId, mandatory, supplierRequiredStatus, costCategoryId }
) => {
  const cellValue = getBidValueForLineItemId(bidId, lineItemNameId)(state);
  const displayCurrency = getDisplayCurrency(state);
  const lineItemName = getLineItemById(lineItemNameId)(state);

  return {
    initialValues: cellValue || { currency: displayCurrency },
    mandatory: lineItemName.mandatory,
    supplierRequiredStatus: lineItemName.supplierRequiredStatus,
    costCategoryId: lineItemName.costCategoryId,
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    estimateId,
    bidId,
    lineItemNameId,
    save,
    currency,
    amount = 0,
    isDirty,
    pageId,
    initialValues,
    suppliers,
    mandatory,
    costCategoryId,
    supplierRequiredStatus,
  }
) => ({
  handleSubmit: (shouldClose = false) => {
    if (isDirty) {
      const parsedAmount = toNumber(amount);

      if (!parsedAmount && !initialValues.amount) {
        save();
        return;
      }

      if (parsedAmount < 0) {
        dispatch(showErrorNotification({ message: 'Cost should not be a negative value' }));

        if (shouldClose) {
          save();
        }

        return;
      }

      dispatch(
        saveLineItemValue({
          estimateId,
          bidId,
          lineItemName: {
            lineItemSuppliers: (suppliers || []).map(({ value, label }) => ({
              supplier: {
                id: value,
                name: label,
              },
            })),
            supplierRequiredStatus,
          },
          lineItemNameId,
          value: { currency, amount: parsedAmount },
          pageId,
          mandatory,
          costCategoryId,
        })
      );
    }
    save();
  },
});

export default compose(
  connect(mapStateToProps),
  withState('currency', 'setCurrency', get('initialValues.currency')),
  withState('amount', 'setAmount', get('initialValues.amount')),
  withHandlers(() => {
    let ref = null;

    return {
      onRef: () => instance => {
        ref = instance;
      },

      saveSuppliers: () => () => {
        ref && ref.save();
      },

      onCurrencyChange:
        ({ setCurrency }) =>
        value =>
          setCurrency(value),

      onAmountChange:
        ({ setAmount }) =>
        e =>
          setAmount(e.target.value),
    };
  }),
  withProps(({ currency, amount, initialValues }) => {
    return {
      handleFocus: event => event.target.select(),
      isDirty: currency !== initialValues.currency || amount !== initialValues.amount,
    };
  }),
  connect(null, mapDispatchToProps),
  withEditConfirmationDialog('handleSubmit'),
  withProps(({ isDirty, handleSubmit, save }) => ({
    saveValue: (shouldClose = true) => {
      if (isDirty) {
        handleSubmit(shouldClose);
        return;
      }

      save();
    },
  })),
  withProps(({ saveValue, saveSuppliers }) => ({
    handleClickOutside: e => {
      saveSuppliers();
      saveValue();
    },
    handleKeyPress: event => {
      if (event.key === 'Enter') {
        // TODO: rework this prop drilling and everything else that causes it
        // it's hideous!
        saveValue(false);
      }
    },
  })),
  onClickOutside
)(EditableCostCell);
