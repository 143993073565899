import styled from 'styled-components';
import { colors, font, buttonStyles } from 'shared/styles';
import { NavLink } from 'react-router-dom';

export const EstimateNavigationList = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const EstimateNavigationItem = styled(NavLink)`
  ${buttonStyles}
  margin-bottom: 5px;
  color: ${colors.text};
  font-size: ${font.sizes.button};
  background: transparent;

  &.active,
  &:hover {
    background-color: ${colors.button};
    color: white;
  }
`;

export const EstimateNavigationItemDisabled = styled.div`
  ${buttonStyles}
  margin-bottom: 5px;
  color: ${colors.text};
  font-size: ${font.sizes.button};
  background: transparent;
  opacity: 0.4;

  &.active,
  &:hover {
    background: transparent;
    color: ${colors.text};
  }
`;

export const HorizontalDivider = styled.div`
  width: 160px;
  height: 3px;
  background-color: #d0d0d0;
  margin: 20px auto 20px auto;
`;
