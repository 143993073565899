import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Grid, Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { font } from 'shared/styles';
import { resetPassword } from 'state/authentication/actions';
import { getResetPasswordSent } from 'state/authentication/selectors';
import Footer from 'components/common/footer';
import EmailPasswordForm from 'components/auth/resetPasswordForm';
import { SUCCESS, ERROR } from 'state/resourceStatus/reducer';

const isDemoSite = window.location.host.includes('demo');

const useStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  header: {
    background: theme.palette.primary.main,
    color: 'white',
    textAlign: 'center',
    padding: !isDemoSite ? theme.spacing(4) : theme.spacing(2),
  },
  title: {
    width: 70,
    margin: 'auto',
    textAlign: 'right',
    color: 'white',
    lineHeight: '22px',
    letterSpacing: -1.3,
    fontWeight: font.weights.bold,
    fontSize: font.sizes.veryLarge,
    fontFamily: 'Montserrat,sans-serif',
    textDecoration: 'none',
  },

  errorContainer: {
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  success: {
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  error: {
    color: theme.palette.error.main,
  },
  backLink: {
    display: 'block',
    textAlign: 'left',
    ...theme.typography.body2,
  },
}));

const ResetPassword = ({ resetPassword, status, emailSent }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container justify="center" alignItems="center" className={classes.container}>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Paper square>
            <Box className={classes.header}>
              {!isDemoSite ? (
                <div className={classes.title}>Control Room</div>
              ) : (
                <img
                  src="/images/cr-logo.png"
                  width="250px"
                  height="70px"
                  alt="control-room-logo"
                />
              )}
            </Box>
            <Box className={classes.body}>
              {(status !== SUCCESS || !emailSent) && (
                <>
                  <NavLink to="/login" className={classes.backLink}>
                    Back to login
                  </NavLink>
                  <EmailPasswordForm onSubmit={resetPassword} />
                </>
              )}
              {status === ERROR && emailSent && (
                <div className={classes.error}>
                  An error occurred while resetting your password. Please try again.
                </div>
              )}
              {status === SUCCESS && emailSent && (
                <div>
                  <p>
                    Please check your email. If we have an account registered for that email address
                    you should receive password reset instructions shortly.
                  </p>
                  <NavLink to="/">&lt; Return to Login</NavLink>
                </div>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

const mapStateToProps = state => ({
  emailSent: getResetPasswordSent(state),
  status: state.resourceStatus.resetPassword,
});

const mapDispatchToProps = dispatch => ({
  resetPassword: ({ email }) => email && dispatch(resetPassword(email)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(ResetPassword);
