var _require = require('cr-core/constants'),
    MediaTypeLabels = _require.MediaTypeLabels;

var traditional = [MediaTypeLabels.TVC, MediaTypeLabels.ANIMATICS, MediaTypeLabels.PRINT];
var nonTraditional = [MediaTypeLabels.DIGITAL_VIDEO, MediaTypeLabels.DIGITAL, MediaTypeLabels.MOBILE_AND_WEB_APPS, MediaTypeLabels.AUDIO, MediaTypeLabels.CRM, MediaTypeLabels.AV];
var mediaAdvertisingTypes = [{
  type: 'Traditional',
  typeList: traditional
}, {
  type: 'Non-Traditional',
  typeList: nonTraditional
}];
module.exports = {
  mediaAdvertisingTypes: mediaAdvertisingTypes
};