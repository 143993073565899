import React, { useState } from 'react';
import uuid from 'uuid/v4';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { last } from 'lodash/fp';
import { MediaTypesWithDeliverables } from 'cr-core/constants/marsDeliverables';
import { Events, track, getEstimateData } from 'components/analytics';
import { updateEstimate } from 'state/estimates/actions';
import { canEdit, getEstimateById } from 'state/estimates/selectors';
import { isResourceLoading } from 'state/resourceStatus/selectors';
import Edit from './edit';
import View from './view';

const Deliverables = ({
  estimate,
  isLoading,
  updateDeliverables,
  history,
  location,
  match,
  canEdit,
}) => {
  const [pageId] = useState(uuid());
  const edit = last(location.pathname.split('/')) === 'edit';

  const onSave = async data => {
    await updateDeliverables(estimate.id, data, pageId);
    track(Events.ESTIMATE_DELIVERABLES_SAVE, getEstimateData({ ...estimate, data }));

    if (estimate.bidValues.length) {
      history.push(`/estimates/${match.params.id}/deliverables`);
    } else {
      // estimate has not bids values (so it's a new estimate/new estimate user journey)
      history.push(`/estimates/${match.params.id}/bids`);
    }
  };

  return edit && MediaTypesWithDeliverables.includes(estimate.mediaType) ? (
    canEdit ? (
      <Edit estimate={estimate} onSave={onSave} />
    ) : (
      <Redirect to={`/estimates/${match.params.id}/deliverables`} />
    )
  ) : (
    <View estimate={estimate} />
  );
};

const mapStateToProps = (state, { match }) => ({
  estimate: getEstimateById(match.params.id)(state),
  isLoading: isResourceLoading('estimateInvoices')(state),
  canEdit: canEdit(match.params.id)(state),
});

const mapDispatchToProps = (dispatch, state) => ({
  updateDeliverables: (estimateId, data, pageId) =>
    dispatch(updateEstimate(estimateId, data, pageId, { invalidateApprovals: false })),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Deliverables);
