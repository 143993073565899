import {
  keyBy,
  set,
  reject,
  update,
  concat,
  omit,
  get,
  flow,
  uniqBy,
  find,
  getOr,
} from 'lodash/fp';
import {
  ESTIMATES_RESET,
  ESTIMATES_FETCH_REQUEST,
  ESTIMATES_FETCH_SUCCESS,
  ESTIMATES_CAMPAIGN_FETCH_REQUEST,
  ESTIMATES_CAMPAIGN_FETCH_SUCCESS,
  ESTIMATE_FETCH_REQUEST,
  ESTIMATE_FETCH_SUCCESS,
  ESTIMATE_CREATE_REQUEST,
  ESTIMATE_CREATE_SUCCESS,
  ESTIMATE_UPDATE_REQUEST,
  ESTIMATE_UPDATE_SUCCESS,
  ESTIMATE_UPDATE_NOTES_REQUEST,
  ESTIMATE_UPDATE_FIELD_SUCCESS,
  CLOSE_ACTUALISATION_ESTIMATE_SUCCESS,
  CLOSE_ACTUALISATION_ESTIMATE_REQUEST,
  REMOVE_ESTIMATE_FILE_SUCCESS,
  SUBMIT_ESTIMATE_SUCCESS,
  UPDATE_LINE_ITEM_NAME,
} from 'state/estimates/actions';
import { LINE_ITEM_EDIT_REQUEST, REMOVE_BID_REQUEST } from 'state/bids/actions';
import {
  APPROVAL_UPDATE_SUCCESS,
  APPROVAL_DELETE_SUCCESS,
  APPROVAL_CREATE_REQUEST,
  APPROVAL_DELETE_REQUEST,
  APPROVAL_CREATE_SUCCESS,
  APPROVAL_CREATE_ERROR,
} from 'state/estimateApprovals/actions';
import { FILE_CREATE_SUCCESS } from 'state/files/actions';
import { EstimateApprovalStatuses } from 'cr-core/constants';
import { SUBMIT_ESTIMATE_REQUEST } from './actions.estimate';

const defaultState = {
  list: [],
  pagination: { totalResults: 10, page: 0 },
  campaignEstimates: { list: [], campaignId: null, lastRequestId: null },
  lastRequestId: null,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case ESTIMATES_RESET: {
      return defaultState;
    }

    case ESTIMATE_FETCH_REQUEST:
    case ESTIMATE_CREATE_REQUEST:
    case ESTIMATE_UPDATE_REQUEST:
    case CLOSE_ACTUALISATION_ESTIMATE_REQUEST: {
      const newState = { ...state };
      if (action.requestId) {
        newState.lastRequestId = action.requestId;
      }

      return newState;
    }

    case ESTIMATES_FETCH_REQUEST: {
      const newState = { ...state };

      if (action.requestId) {
        newState.lastRequestId = action.requestId;
      }

      if (action.query.page === 1) {
        newState.list = [];
        newState.pagination = {};
        newState.byId = {};
      }
      if (!action.query.page) {
        newState.pagination = {};
      }

      return newState;
    }

    case ESTIMATES_FETCH_SUCCESS:
      if (state.lastRequestId && state.lastRequestId !== action.requestId) {
        return state;
      }

      const byId = keyBy('id', action.estimates);
      let list;
      if (action.pagination.page === 1) {
        list = action.estimates;
        return { ...state, byId, list, pagination: action.pagination };
      }
      list = uniqBy('id', [...state.list, ...action.estimates]);
      return {
        ...state,
        byId: { ...state.byId, ...byId },
        list,
        pagination: action.pagination,
      };

    case SUBMIT_ESTIMATE_REQUEST: {
      return {
        ...state,
        lastRequestId: action.requestId,
      };
    }

    case ESTIMATE_CREATE_SUCCESS:
    case ESTIMATE_FETCH_SUCCESS:
    case SUBMIT_ESTIMATE_SUCCESS:
    case ESTIMATE_UPDATE_SUCCESS:
    case CLOSE_ACTUALISATION_ESTIMATE_SUCCESS: {
      if (state.lastRequestId && action.requestId && state.lastRequestId !== action.requestId) {
        return state;
      }
      let lineItemBeingAdded = get(`byId.${action.estimate.id}.lineItemBeingAdded`);
      const newState = set(
        `byId.${action.estimate.id}`,
        omit(['campaign'], action.estimate),
        state
      );

      if (!lineItemBeingAdded) {
        return newState;
      }

      return set(`byId.${action.estimate.id}.lineItemBeingAdded`, lineItemBeingAdded, newState);
    }

    case ESTIMATES_CAMPAIGN_FETCH_REQUEST: {
      return {
        ...state,
        campaignEstimates: {
          campaignId: action.campaignId,
          lastRequestId: action.requestId,
          list: [],
        },
      };
    }

    case ESTIMATES_CAMPAIGN_FETCH_SUCCESS:
      if (state.campaignEstimates.lastRequestId !== action.requestId) {
        return state;
      }

      return {
        ...state,
        campaignEstimates: {
          campaignId: action.campaignId,
          lastRequestId: action.requestId,
          list: action.estimates,
        },
      };

    case ESTIMATE_UPDATE_FIELD_SUCCESS:
      return set(['byId', action.estimateId, action.field], action.value, state);

    case ESTIMATE_UPDATE_NOTES_REQUEST:
      return set(`byId.${action.estimateId}.notes`, action.patch.notes, state);

    case APPROVAL_DELETE_SUCCESS:
      return update(
        `byId.${action.estimateId}.approvals`,
        reject({ approverId: action.approverId }),
        state
      );

    case APPROVAL_UPDATE_SUCCESS:
      return update(`byId.${action.estimateId}.approvals`, concat([action.approval]), state);

    case FILE_CREATE_SUCCESS:
      return update(`byId.${action.estimateId}.files`, concat([action.file]), state);

    case REMOVE_ESTIMATE_FILE_SUCCESS:
      return update(`byId.${action.estimateId}.files`, reject({ id: action.fileId }), state);

    case UPDATE_LINE_ITEM_NAME:
      const value = find(
        value => value.lineItemNameId === action.lineItemNameId && value.bidId === action.bidId,
        getOr([], `byId.${action.estimateId}.bidValues`)(state)
      );

      if (!value) {
        return update(
          `byId.${action.estimateId}.bidValues`,
          values => {
            return [
              {
                lineItemName: action.lineItemName,
                mandatory: action.mandatory,
                ...action.meta,
                lineItemNameId: action.lineItemNameId,
                bidId: action.bidId,
              },
              ...values,
            ];
          },
          state
        );
      }

      if (!value.value?.amount && !action.payload.lineItemSuppliers?.length) {
        return update(
          `byId.${action.estimateId}.bidValues`,
          flow(reject({ lineItemNameId: action.lineItemNameId, bidId: action.bidId })),
          state
        );
      }

      return update(
        `byId.${action.estimateId}.bidValues`,
        values => {
          return values.map(x =>
            x.lineItemNameId === action.lineItemNameId && x.bidId === action.bidId
              ? { ...x, lineItemName: { ...x.lineItemName, ...action.payload } }
              : x
          );
        },
        state
      );

    case LINE_ITEM_EDIT_REQUEST:
      return update(
        `byId.${action.estimateId}.bidValues`,
        flow(
          reject({ lineItemNameId: action.lineItemNameId, bidId: action.bidId }),
          concat([
            {
              type: action.valueType,
              lineItemNameId: action.lineItemNameId,
              bidId: action.bidId,
              value: action.value,
              lineItemName: action.lineItemName,
              mandatory: action.mandatory,
              optional: action.optional,
              costCategoryId: action.costCategoryId,
            },
          ])
        ),
        state
      );

    case REMOVE_BID_REQUEST:
      return update(`byId.${action.estimateId}.bids`, reject({ id: action.bidId }), state);

    case APPROVAL_CREATE_REQUEST:
      return update(
        `byId.${action.estimateId}.approvals`,
        approvals =>
          action.retry && action.approval
            ? (approvals || []).map(approval =>
                approval.id === action.approval.id
                  ? { ...approval, status: EstimateApprovalStatuses.LOADING }
                  : approval
              )
            : [action.approval, ...(approvals || [])],
        state
      );

    case APPROVAL_CREATE_SUCCESS:
      const { nonce, ...data } = action.approval;

      return update(
        `byId.${action.estimateId}.approvals`,
        approvals =>
          nonce
            ? (approvals || []).map(approval => (approval.nonce === nonce ? data : approval))
            : [...(approvals || []), data],
        state
      );

    case APPROVAL_CREATE_ERROR:
      const { approvalId, estimateId } = action;

      return update(
        `byId.${estimateId}.approvals`,
        approvals =>
          (approvals || []).map(approval =>
            approval.id === approvalId
              ? { ...approval, status: EstimateApprovalStatuses.ERROR }
              : approval
          ),
        state
      );

    case APPROVAL_DELETE_REQUEST:
      return update(
        `byId.${action.estimateId}.approvals`,
        approvals =>
          (approvals || []).filter(
            approval => approval && approval.approver && approval.approver.id !== action.approverId
          ),
        state
      );

    default:
      return state;
  }
};

export default reducer;
