import React, { useEffect, useState } from 'react';
import { compact, get, flow, reject, sumBy } from 'lodash/fp';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, CircularProgress, Tooltip } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { CampaignStatuses, CampaignStatusLabels, EstimateStatuses } from 'cr-core/constants';
import { formatAsCurrency } from 'cr-core/currencyUtils';
import Menu from 'components/common/menu';
import { getDisplayCurrency } from 'state/authentication/selectors';
import { fetchCampaign } from 'state/campaigns/actions';
import { canBeClosed, canBeDeleted, getCampaignById } from 'state/campaigns/selectors';
import { fetchCampaignEstimates } from 'state/estimates/actions';
import { getEstimatesByCampaignId, getCampaignEstimates } from 'state/estimates/selectors';
import { isResourceLoading } from 'state/resourceStatus/selectors';
import { getSidePanelData } from 'state/ui/selectors';
import MediaTypeBreakdownWidget from './widgets/mediaTypeBreakdownWidget';
import StatusBreakdownWidget from './widgets/statusBreakdownWidget';
import UsersWidget from './widgets/usersWidget';
import { widgetStyles } from './widgets/utils';

const useStyles = makeStyles(theme => ({
  ...widgetStyles(theme),

  body: {
    padding: theme.spacing(4, 3),
    position: 'relative',
  },
  bodyContainer: {
    width: '100%',
  },
  menuButton: {
    position: 'absolute',
    right: 2,
    top: 10,
  },
  poCode: {
    paddingTop: theme.spacing(2),
  },
  accountName: {
    marginRight: 30,
  },
  workspace: {
    padding: theme.spacing(1, 0),
  },
  dataTypeSwitch: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: -theme.spacing(2),
  },
  dataTypeSwitchButton: {
    textTransform: 'none',
  },
  teamBody: {
    display: 'flex',
  },
  teamButton: {
    margin: `auto ${theme.spacing(2)}px`,
  },
  tooltip: {
    ...theme.typography.body2,
  },
}));

const DataTypeSwitchButton = ({
  dataType,
  onClick,
  campaign,
  campaignEstimates,
  filteredEstimates,
  campaignEstimatesLoading,
  displayCurrency,
}) => {
  const classes = useStyles();
  const filteredOutCount = campaignEstimates.length - filteredEstimates.length;
  const filteredOutTotalSpend =
    campaign.totalCost -
    flow(
      reject({ status: EstimateStatuses.CANCELLED }),
      sumBy(({ recommendedBidTotal, actualisedTotal }) => actualisedTotal || recommendedBidTotal),
    )(filteredEstimates);

  if (campaignEstimatesLoading || filteredOutCount === 0) {
    return null;
  }

  return (
    <div className={classes.dataTypeSwitch}>
      {dataType === 'all' && (
        <Tooltip
          placement="top"
          classes={{ tooltip: classes.tooltip }}
          title={`${filteredOutCount} estimate${filteredOutCount > 1 ? 's' : ''} will be filtered
    out (${formatAsCurrency(displayCurrency, filteredOutTotalSpend)})`}
        >
          <Button
            color="primary"
            className={classes.dataTypeSwitchButton}
            onClick={() => onClick('filtered')}
            data-test="dataTypeSwitch"
          >
            Show data based on filtered results
          </Button>
        </Tooltip>
      )}

      {dataType === 'filtered' && (
        <Button
          color="primary"
          className={classes.dataTypeSwitchButton}
          onClick={() => onClick('all')}
          data-test="dataTypeSwitch"
        >
          Show all data
        </Button>
      )}
    </div>
  );
};

const Details = ({
  pageId,
  campaign,
  filteredEstimates,
  campaignEstimates,
  editCampaign,
  closeCampaign,
  openCampaign,
  deleteCampaign,
  displayCurrency,
  fetchCampaign,
  fetchCampaignEstimates,
  showCloseCampaignBtn,
  showDeleteMenuOption,
  campaignEstimatesLoading,
}) => {
  const classes = useStyles();
  const menuItems = compact([
    {
      label: 'Edit Campaign',
      Icon: EditIcon,
      dataTest: 'edit-campaign-menu-item',
      onClick: () => editCampaign('campaign_details_menu')(campaign),
    },
    showCloseCampaignBtn && {
      label: 'Close Campaign',
      Icon: LockIcon,
      dataTest: 'close-campaign-menu-item',
      onClick: () => closeCampaign('campaign_details_menu')(campaign),
    },
    showDeleteMenuOption && {
      label: 'Delete Campaign',
      Icon: DeleteIcon,
      dataTest: 'delete-campaign-menu-item',
      onClick: () => deleteCampaign('campaign_details_menu')(campaign),
    },
  ]);
  const [dataType, setDataType] = useState('all');
  const estimates = dataType === 'filtered' ? filteredEstimates : campaignEstimates;

  useEffect(() => {
    if (campaign) {
      fetchCampaign(campaign.id);
      fetchCampaignEstimates(campaign.id, pageId);
    }
  }, [get('id', campaign)]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className={classes.body} data-test="campaign-details-body">
        <Menu items={menuItems} className={classes.menuButton} data-test="dropdownMenuBtn" />
        <Grid container spacing={2}>
          {Boolean(campaign) && (
            <>
              <Typography variant="h5" className={classes.accountName} data-test="campaign-name">
                {campaign.name}
              </Typography>
              <div className={classes.bodyContainer}>
                {campaign.poCode && (
                  <div className={classes.poCode} data-test="campaign-poCode">
                    PO: {campaign.poCode}
                  </div>
                )}
                <div className={classes.workspace} data-test="campaign-workspace">
                  Workspace: {campaign.workspace.name}
                </div>

                <StatusBreakdownWidget
                  estimates={estimates}
                  campaign={campaign}
                  displayCurrency={displayCurrency}
                />

                <DataTypeSwitchButton
                  dataType={dataType}
                  onClick={setDataType}
                  campaign={campaign}
                  campaignEstimates={campaignEstimates}
                  filteredEstimates={filteredEstimates}
                  campaignEstimatesLoading={campaignEstimatesLoading}
                  displayCurrency={displayCurrency}
                />

                <MediaTypeBreakdownWidget estimates={estimates} displayCurrency={displayCurrency} />

                <UsersWidget estimates={estimates} />

                <div className={classes.widget}>
                  <div className={classes.widgetTitle}>Campaign Status</div>
                  <div className={classes.row}>
                    <div data-test="campaign-status">{CampaignStatusLabels[campaign.status]}</div>
                    {campaign.status === CampaignStatuses.OPEN && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<LockIcon />}
                        data-test="close-campaign-btn"
                        disabled={!showCloseCampaignBtn}
                        onClick={() => closeCampaign('campaign_details_status_button')(campaign)}
                      >
                        Close Campaign
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          {!campaign && (
            <div>
              <CircularProgress />
            </div>
          )}
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const campaignId = get('campaign.id', getSidePanelData(state));
  return {
    campaign: getCampaignById(campaignId)(state),
    filteredEstimates: getEstimatesByCampaignId(campaignId)(state),
    campaignEstimates: getCampaignEstimates(campaignId)(state),
    campaignEstimatesLoading: isResourceLoading('campaignEstimates')(state),
    displayCurrency: getDisplayCurrency(state),
    showCloseCampaignBtn: canBeClosed(campaignId)(state),
    showDeleteMenuOption: canBeDeleted(campaignId)(state),
    editCampaign: get('editCampaign', getSidePanelData(state)),
    deleteCampaign: get('deleteCampaign', getSidePanelData(state)),
    closeCampaign: get('closeCampaign', getSidePanelData(state)),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCampaign: id => dispatch(fetchCampaign(id)),
  fetchCampaignEstimates: (id, pageId) => dispatch(fetchCampaignEstimates(id, pageId)),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Details);
