import React from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Footer from 'components/common/footer';
import AppContainer from 'components/appContainerSimple';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(6),
  },
  body: {
    padding: theme.spacing(3),
  },
}));

const TermAndConditions = () => {
  const classes = useStyles();
  return (
    <AppContainer>
      <Grid container justify="center" alignItems="center" className={classes.container}>
        <Grid xs={12} spacing={3}>
          <Typography variant="h2">Terms & Conditions</Typography>
          <Paper square className={classes.body}>
            <p>
              ALL USE OF CONTROL ROOM Invest® IS SUBJECT TO THE FOLLOWING TERMS. <br />
              YOU MUST NOT ACCESS OR USE CONTROL ROOM Invest® UNLESS YOU AGREE TO AND ACCEPT ALL THE
              FOLLOWING TERMS AND CONDITIONS. <br />
              IF YOU DO NOT AGREE TO OR CHOOSE NOT TO ACCEPT THE TERMS AND CONDITIONS, YOU MAY NOT
              ACCESS CONTROL ROOM Invest®.
            </p>
            <Typography variant="h5">1. Definitions</Typography>
            <p>
              In these Terms of Use, the following words and expressions have the following
              meanings:
              <br /> <br />
              “MurphyCobb” means MurphyCobb & Associates Limited, a company registered in England
              and Wales with registration number 5665258 and whose registered office is at 10 Orange
              Street, Haymarket, London WC2H 7DQ, and its assignees; <br />
              <br />
              “Licence” means the limited personal licence rights granted in paragraph 3;
              <br /> <br />
              “Site” means any site where the Client accesses Control Room Invest® “Control Room®”
              means the intuitive, online platform developed and owned by MurphyCobb which allows
              agencies to create estimates and submit them to clients and allows Clients can approve
              estimates, access to detailed costs reports and manage their advertising budgets;
              <br />
              <br /> and “the Client” means a user, or licensee of Control Room Invest®.
            </p>
            <Typography variant="h5">2. MurphyCobb Retains All Ownership</Typography>
            <p>
              MurphyCobb retains all ownership including all right, title and interest in and to
              Control Room Invest®, the underlying code, and any copies thereof. The Client
              understands and agrees that MurphyCobb has and keeps all proprietary rights including
              without limitation all intellectual property rights therein, including copyrights,
              patents, and trademarks which all remain exclusively MurphyCobb 's property. More
              particularly the Client agrees and acknowledges that Control Room Invest® is
              MurphyCobb 's exclusive property and that Control Room Invest® is protected by
              copyright, trademark protection, database rights and other intellectual property
              rights. MurphyCobb grants the Client no express or implied right under its copyrights,
              trademarks, or other intellectual property or proprietary rights. The Client further
              understands and agrees that the Client may not, and will not, in whole or in part,
              adapt, alter, assign, clone, copy, create a derivative of, decode, decompile,
              disassemble, distribute, lease, license, modify, publish, reproduce, reverse engineer,
              sell, transfer, translate, or vary Control Room Invest® without the prior written
              authorisation of MurphyCobb, directly or indirectly through any of the Client’s
              employees or under the Client’s authorisation, direction, or control. The Client
              further understands and agrees that to the extent that Control Room Invest® utilises,
              incorporates, or references any third party software, such third party software may
              remain the intellectual property of such third parties. The only rights the Client has
              regarding Control Room Invest® are those usage rights expressly provided in the
              licence below. the Client also understands that the Client may not, directly or
              indirectly remove any MurphyCobb proprietary notices from Control Room Invest®
              including logos, copyright or trademark notices. Likewise, any third party notices or
              marking must remain intact.
            </p>
            <Typography variant="h5">3. Limited Licence Granted to the Client</Typography>
            <p>
              MurphyCobb hereby grants to the Client a non-exclusive, non-transferable, revocable,
              limited, and personal licence to use Control Room Invest® in accordance with the terms
              of the Client’s subscription and MurphyCobb’s Acceptable Use Policy, as amended from
              time to time. The Client agrees to comply with any restrictions therein. The Client
              understands that MurphyCobb reserves the right, in its sole discretion, to discontinue
              any aspect(s) of Control Room Invest® at any time. The limited licence rights granted
              in these Terms of Use are personal to the Client, as are the obligations, duties,
              representations and warranties made by the Client herein and the Client may not
              transfer, license, sublicense, assign, or otherwise transfer the Client’s rights or
              duties to any third party. The Client may not remove, move, or relocate Control Room
              Invest® from the web site or any server on which it is located, unless the Client are
              expressly authorised to do so in writing by MurphyCobb.
            </p>
            <Typography variant="h5">4. Usage Limitations and Fees</Typography>
            <p>
              MurphyCobb reserves the right to implement limitations or additional fees on certain
              features or aspects of Control Room Invest®.
            </p>
            <Typography variant="h5">
              5. Disclaimer: Control Room Invest® is Provided “As Is” with no Guarantees or
              Warranties
            </Typography>
            <p>
              While Control Room Invest® is reasonably believed to be functional and to accurately
              perform its function(s), MurphyCobb cannot guarantee that Control Room Invest® will
              not contain errors or omissions, now or in the future. Control Room Invest® has been
              rigorously tested proven with extensive use by third parties. However, certain errors
              may be present but not readily detected and may remain hidden. Access to Control Room
              Invest® is provided to the Client on an “as is” basis, with any and all faults or
              failings. The Client agrees that the Client will access and use Control Room Invest®
              entirely at the Client’s own risk. MurphyCobb makes absolutely no express guarantees
              or warranties about Control Room Invest® or the Client’s use of Control Room Invest®
              for any purpose. All conditions, warranties, terms, representations and undertakings,
              express or implied, in respect of Control Room Invest® are expressly excluded.
              Moreover, to the extent permissible under applicable law, MurphyCobb specifically
              disclaims any and all warranties or guarantees, including any that may be implied by
              law or otherwise, including without limitation any warranty of performance, accuracy,
              completeness, quality, merchantability, fitness for a particular purpose, and any
              warranty of non-infringement of any intellectual property or other rights of any third
              party. Further, MurphyCobb does not warrant or guarantee that the Client’s use of
              Control Room Invest® is legal/permissible under the applicable law in the Client’s
              locality. The Client agrees with the foregoing and assumes all risks associated with
              accessing or using Control Room Invest® for any purpose.
            </p>
            <Typography variant="h5">6. MurphyCobb’s Liability</Typography>
            <p>
              Except in the case of death or personal injury, the Client accepts all responsibility
              and liability for any damages the Client may suffer in connection with accessing or
              using Control Room Invest®. The Client understands that MurphyCobb's liability is
              strictly limited by these Terms of Use. In no event shall MurphyCobb, or its
              directors, officers, agents, employees or licensors be liable for any direct or
              indirect losses or damages whatsoever or howsoever incurred as a result of any access
              or use of, or inability to access or use Control Room Invest® or any interruption or
              cessation of Control Room Invest’s® operation, including, without limitation, lost
              profits, lost opportunities, business interruption, or lost information, except to the
              extent which it is strictly unlawful to exclude such liability. The Client’s use of
              Control Room Invest® is entirely at the Client’s own risk and MurphyCobb shall not be
              liable for any bugs, viruses, malware, spyware, trojan horses or the like which may be
              transmitted to or through Control Room Invest® by any third party. In the event that
              any exclusion contained in these Terms of Use shall be held to be invalid for any
              reason and MurphyCobb becomes liable for any loss or damage, the Client agrees that
              MurphyCobb's liability shall be limited to the amount of the fees that the Client has
              paid to license Control Room Invest® over the previous 6 (six) months, as agreed
              liquidated damages.
            </p>
            <Typography variant="h5">7. MurphyCobb’s Security Procedures</Typography>
            <p>
              The Client agrees to comply with MurphyCobb’s security procedures including password
              policies (including changing the Client’s password from time to time if directed by
              MurphyCobb), not to circumvent any security or access provisions or procedures
              established by MurphyCobb, not to implement or utilise any alternative access or
              backdoors to Control Room Invest® , and to advise MurphyCobb as soon as reasonably
              practical of any security issues the Client discovers or of which the Client becomes
              aware.
            </p>
            <Typography variant="h5">8. Client’s Indemnity</Typography>
            <p>
              The Client agrees to indemnify and hold harmless MurphyCobb, its officers, directors,
              employees, and agents, from and against any and all claims, damages, obligations,
              losses, or liabilities, arising from: (i) the Client’s use of Control Room Invest® ;
              (ii) the Client’s breach of any of these Terms of Use; (iii) the Client’s breach of
              any third party right.
            </p>
            <Typography variant="h5">9. Termination</Typography>
            <p>
              Without prejudice to any other rights, MurphyCobb may terminate the Client’s right to
              use Control Room Invest® without notice or refund if the Client does not comply with
              these Terms of Use or if MurphyCobb, in its reasonable sole discretion, is of the view
              that the Client’s continued use of Control Room Invest® may infringe or cause damage
              to any third party rights. Upon termination, the Client shall promptly cease use of
              Control Room Invest®. All other obligations the Client has agreed shall survive
              Termination of use for any reason.
            </p>
            <Typography variant="h5">10. Applicable Law</Typography>
            <p>
              These Terms of Use shall be governed by the laws of England and the parties hereby
              agree to the exclusive jurisdiction of the English courts.
            </p>
            <Typography variant="h5">11. Entire Agreement</Typography>
            <p>
              These Terms of Use constitute the entire agreement between the Client and MurphyCobb
              with respect to its subject matter and supersedes all prior representations,
              proposals, and agreements in relation thereto. No waiver or amendment of any of these
              Terms of Use shall be effective unless made in writing and signed by both parties. No
              failure or delay in exercising any right, power, or remedy shall operate as a waiver
              of any such right, power or remedy. If any part of these Terms of Use is determined by
              a court of competent jurisdiction to be invalid or unenforceable, that part shall be
              limited or eliminated to the minimum extent necessary so that the remainder of these
              Terms of Use shall be fully enforceable and legally binding. MurphyCobb & Associates 1
              January 2020
            </p>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </AppContainer>
  );
};

export default TermAndConditions;
