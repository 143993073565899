import moment from 'moment';
import {
  find,
  first,
  flatten,
  flow,
  forEach,
  get,
  getOr,
  indexOf,
  map,
  sum,
  sumBy,
  uniq,
  values,
} from 'lodash/fp';
import { regions } from 'cr-core/marsRegions';
import { initiallyDisplayedFilters } from 'pages/reports';

export const aggregateCosts = costs => ({
  highest: sumBy('highest', costs),
  lowest: sumBy('lowest', costs),
  quoteCurrency: flow(first, get('quoteCurrency'))(costs),
});

export const sortByEnum = list => (a, b, colId, desc) => {
  a = a.values[colId];
  b = b.values[colId];
  a = a === null || a === undefined ? -Infinity : a;
  b = b === null || b === undefined ? -Infinity : b;
  a = typeof a === 'string' ? a.toLowerCase() : a;
  b = typeof b === 'string' ? b.toLowerCase() : b;

  const greater = desc ? -1 : 1;
  const lower = desc ? 1 : -1;

  if (typeof a === 'string' && typeof b === 'string') {
    if (a.includes('not set')) {
      return greater;
    }
    if (b.includes('not set')) {
      return lower;
    }
  }

  let x = indexOf(a, list);
  let y = indexOf(b, list);

  if (x > y) {
    return greater;
  }
  if (x < y) {
    return lower;
  }
  return 0;
};

export const sortWithMultipleValues = (a, b, colId, desc) => {
  a = a.values[colId];
  b = b.values[colId];
  a = a === null || a === undefined ? -Infinity : a;
  b = b === null || b === undefined ? -Infinity : b;
  a = typeof a === 'string' ? a.toLowerCase() : a;
  b = typeof b === 'string' ? b.toLowerCase() : b;

  const greater = desc ? -1 : 1;
  const lower = desc ? 1 : -1;

  if (a === b) {
    return 0;
  }

  if (typeof a === 'string' && typeof b === 'string') {
    if (a.includes('multiple') && b.includes('not set')) {
      return lower;
    }
    if (a.includes('not set') && b.includes('multiple')) {
      return greater;
    }
    if (a.includes('multiple') || a.includes('not set')) {
      return greater;
    }
    if (b.includes('multiple') || b.includes('not set')) {
      return lower;
    }
  }

  if (a > b) {
    return greater;
  }
  if (a < b) {
    return lower;
  }
  return 0;
};

export const roundNumber = num => Number(num.toFixed(2));

export const roundValue = field => row => {
  const value = getOr(0, field)(row);
  return value ? roundNumber(value) : 0;
};

export const roundHoursValue = field => row => {
  const value = getOr(0, field)(row);
  return value ? roundNumber(value) : 'N/A';
};

export const getDivision = country => {
  if (country === 'Global') {
    return 'Global';
  }
  const division =
    find(({ countries }) => countries.includes(country), regions) ||
    find({ name: country }, regions);
  return getOr('Not Set', 'name', division);
};

export const downloadCsv = (data, cols, reportName) => {
  const csvData = map(item => {
    const row = {};
    forEach(({ label, value }) => {
      if (typeof value === 'string') {
        row[label] = `"${get(value, item)}"`;
      } else {
        row[label] = `"${value(item)}"`;
      }
    }, cols);
    return row;
  }, data);

  const headers = map('label', cols);
  const rows = map(values, csvData);
  let csv = rows.map(e => e.join(',')).sort();
  csv.unshift(headers.join(','));
  csv = csv.join('\n');
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const exportedFilename = `Report_${reportName}_${moment().format('YYYY-MM-DD')}.csv`;

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link); // Required for FF
      link.click();
      link.remove();
    }
  }
};

export const estimateCostAccessor = ({ actualisedTotal, recommendedBidTotal }) =>
  actualisedTotal || recommendedBidTotal || 0;

export const aggregateSum = flow(
  map(x => x || 0),
  sum
);

export const aggregateAverage = values =>
  flow(
    map(x => x || 0),
    sum,
    total => total / values.length
  )(values);

export const checkIfFieldPopulated = value =>
  (typeof value === 'string' && value !== '') ||
  (Array.isArray(value) && value.length !== 0) ||
  typeof value == 'boolean';

export const getPopulatedFilters = (filters, queryParams) => {
  const populatedFilters = {};
  for (const filter in filters) {
    if (
      checkIfFieldPopulated(filters[filter]) &&
      (initiallyDisplayedFilters.includes(filter) || Object.keys(queryParams).includes(filter))
    ) {
      populatedFilters[filter] = filters[filter];
    }
  }
  return populatedFilters;
};

export const concatenatePropItems = propKey =>
  flow(
    map('products'),
    map(values => (values.length ? values : [{ [propKey]: 'Not Set' }])),
    flatten,
    map(propKey),
    uniq,
    categories => categories.join(', ')
  );
