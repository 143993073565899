import { fetch } from 'state/fetchMiddleware';
import { getEstimateWatchers } from './selectors';

export const ESTIMATE_WATCHER_CREATE_REQUEST = 'estimateWatchers/ESTIMATE_WATCHERS_CREATE_REQUEST';
export const ESTIMATE_WATCHER_CREATE_SUCCESS = 'estimateWatchers/ESTIMATE_WATCHERS_CREATE_SUCCESS';
export const ESTIMATE_WATCHER_CREATE_ERROR = 'estimateWatchers/ESTIMATE_WATCHERS_CREATE_ERROR';

export const ESTIMATE_WATCHER_DELETE_REQUEST = 'estimateWatchers/ESTIMATE_WATCHERS_DELETE_REQUEST';
export const ESTIMATE_WATCHER_DELETE_SUCCESS = 'estimateWatchers/ESTIMATE_WATCHERS_DELETE_SUCCESS';
export const ESTIMATE_WATCHERS_FETCH_REQUEST = 'estimateWatchers/FETCH_REQUEST';
export const ESTIMATE_WATCHERS_FETCH_SUCCESS = 'estimateWatchers/FETCH_SUCCESS';
export const ESTIMATE_WATCHERS_FETCH_ERROR = 'estimateWatchers/ESTIMATE_WATCHERS_FETCH_ERROR';

export const fetchWatchers = estimateId => async dispatch => {
  dispatch({ type: ESTIMATE_WATCHERS_FETCH_REQUEST });
  try {
    const { data: watchers } = await dispatch(fetch(`/estimates/${estimateId}/watchers`));
    dispatch({ type: ESTIMATE_WATCHERS_FETCH_SUCCESS, watchers });
  } catch (error) {
    dispatch({ type: ESTIMATE_WATCHERS_FETCH_ERROR, error });
  }
};

export const createWatcher = (
  estimateId,
  userId,
  pageId,
  watcher,
  retry = false,
) => async dispatch => {
  try {
    dispatch({ type: ESTIMATE_WATCHER_CREATE_REQUEST, estimateId, userId, watcher, retry });
    const { data } = await dispatch(
      fetch(`/estimates/${estimateId}/watchers`, {
        method: 'POST',
        data: { userId },
      }),
    );
    dispatch({
      type: ESTIMATE_WATCHER_CREATE_SUCCESS,
      estimateId,
      watcher: { ...data, nonce: (watcher && watcher.nonce) || undefined },
    });
  } catch {
    dispatch({ type: ESTIMATE_WATCHER_CREATE_ERROR, estimateId, watcherId: watcher && watcher.id });
  }
  // await dispatch(fetchWatchers(estimateId));
};

export const retryCreateWatcher = (estimateId, watcherId) => (dispatch, getState) => {
  const state = getState();
  const watchers = getEstimateWatchers(state);
  const watcher = watchers.find(watcher => watcher.id === watcherId);

  if (watcher) {
    dispatch(createWatcher(estimateId, watcher.userId, null, watcher, true));
  }
};

export const deleteWatcher = (estimateId, watcherId, pageId, isEmphemeral) => async dispatch => {
  dispatch({ type: ESTIMATE_WATCHER_DELETE_REQUEST, watcherId });

  if (!isEmphemeral) {
    await dispatch(fetch(`/estimates/${estimateId}/watchers/${watcherId}`, { method: 'DELETE' }));
    dispatch({ type: ESTIMATE_WATCHER_DELETE_SUCCESS, estimateId });
  }
};
