import React from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose, withState, withHandlers } from 'recompose';
import { FieldContainer, InputContainer, Label, SubLabel, Select } from 'components/forms';
import { createCampaign } from 'state/campaigns/actions';
import { getCampaignCreationInProgress } from 'state/campaigns/selectors';
import { useQuery } from 'react-query';
import WorkspaceService from 'services/WorkspaceService';

const createCampaignSelectOption = ({ id, name, humanId }) => ({
  value: id,
  label: `${humanId} - ${name}`,
});
// const createCampaignSelectOptions = map(createCampaignSelectOption);

const mapStateToProps = (state, { form, workspaceId }) => {
  return {
    // campaignOptions: flow(getCampaignsByWorkspace, createCampaignSelectOptions)(state),
    campaignCreationInProgress: getCampaignCreationInProgress(state),
  };
};

const mapDispatchToProps = (dispatch, { workspaceId }) => ({
  // fetchCampaignsByWorkspace: () => dispatch(fetchCampaignsByWorkspace(workspaceId)),
  createCampaign: (id, name, workspaceId) => dispatch(createCampaign(id, name, workspaceId)),
});

const CampaignField = ({
  campaignOptions,
  campaignCreationInProgress,
  createCampaign,
  openModal,
  shouldShowModal,
  name,
  workspaceId,
  onModalClose,
  ...props
}) => {
  const { data = [] } = useQuery(['workspace', workspaceId, 'campaigns'], () =>
    WorkspaceService.getCampaigns(workspaceId).then(({ data }) => data)
  );

  const options = data.map(createCampaignSelectOption);

  return (
    <FieldContainer>
      <Label htmlFor="campaign">
        Campaign
        <SubLabel>Select an existing or create a new one.</SubLabel>
      </Label>
      <InputContainer>
        <Field
          name="campaign"
          component={({ input }) => (
            <Select
              id="select-campaign-select"
              placeholder="Select Campaign..."
              options={options}
              onChange={input.onChange}
              value={input.value}
              isLoading={campaignCreationInProgress}
            />
          )}
        />
      </InputContainer>
    </FieldContainer>
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  // lifecycle({
  //   componentDidMount() {
  //     this.props.fetchCampaignsByWorkspace();
  //   },
  // }),
  withState('shouldShowModal', 'showModal', false),
  withState('name', 'setName'),
  withHandlers({
    openModal:
      ({ showModal, setName }) =>
      name => {
        showModal(true);
        setName(name);
      },
    onModalClose:
      ({ showModal, formChange }) =>
      (newCampaign = {}) => {
        showModal(false);
        if (newCampaign.id) {
          formChange('campaign', createCampaignSelectOption(newCampaign));
        }
      },
  })
)(CampaignField);
