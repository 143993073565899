import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose, getContext, withHandlers } from 'recompose';
import styled from 'styled-components';
import { Events, track, getEstimateData } from 'components/analytics';
import withEditability from 'components/withEditability';
import SvgAddIcon from 'icons/AddIcon';
import { addEmptyCostCategory } from 'state/costCategories/actions';

import { Row, RowsContainer, RowTitle, TableSection } from './tableComponents';

const RowStyled = styled(Row)`
  display: flex;
  align-items: center;
  opacity: 0.5;
  transition: 0.2s opacity;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const AddCostCategoryButton = ({ onClick, className }) => (
  <TableSection className={className}>
    <RowsContainer onClick={onClick} data-test="add-cost-category-btn">
      <RowStyled className="addCostCategoryButtonRaw">
        <SvgAddIcon className="icon" />
        <RowTitle>Add Cost Category</RowTitle>
      </RowStyled>
    </RowsContainer>
  </TableSection>
);

const AddCostCategoryStyled = styled(AddCostCategoryButton)`
  .icon {
    font-size: 28px;
  }
`;

const mapDispatchToProps = (dispatch, { estimate }) => ({
  addCostCategory: () => dispatch(addEmptyCostCategory(estimate.id, -1)),
});

export default compose(
  withEditability,
  getContext({
    estimate: PropTypes.object,
  }),
  connect(null, mapDispatchToProps),
  withHandlers({
    onClick: ({ addCostCategory, estimate }) => () => {
      addCostCategory();
      track(Events.ESTIMATE_ADD_COST_CATEGORY_CLICK, getEstimateData(estimate));
    },
  })
)(AddCostCategoryStyled);
