import { EstimateApprovalStatuses } from 'cr-core/constants';
import { getUserId } from 'state/authentication/selectors';
import { fetchEstimate } from 'state/estimates/actions';
import { fetchWatchers } from 'state/estimateWatchers/actions';
import { fetch } from 'state/fetchMiddleware';
import { getEstimateApprovalsFor } from './selectors';

export const APPROVAL_DELETE_REQUEST = 'estimateApprovals/ESTIMATE_APPROVALS_DELETE_REQUEST';
export const APPROVAL_DELETE_SUCCESS = 'estimateApprovals/ESTIMATE_APPROVALS_DELETE_SUCCESS';

export const APPROVAL_UPDATE_REQUEST = 'estimateApprovals/ESTIMATE_APPROVALS_UPDATE_REQUEST';
export const APPROVAL_UPDATE_SUCCESS = 'estimateApprovals/ESTIMATE_APPROVALS_UPDATE_SUCCESS';

export const APPROVAL_CREATE_REQUEST = 'estimateApprovals/ESTIMATE_APPROVALS_CREATE_REQUEST';
export const APPROVAL_CREATE_SUCCESS = 'estimateApprovals/ESTIMATE_APPROVALS_CREATE_SUCCESS';
export const APPROVAL_CREATE_ERROR = 'estimateApprovals/ESTIMATE_APPROVALS_CREATE_ERROR';

export const APPROVE_ESTIMATE_SUCCESS = 'estimate/APPROVE_ESTIMATE_SUCCESS';
export const APPROVE_ESTIMATE_RESET = 'estimate/APPROVE_ESTIMATE_RESET';

export const SEND_REQUEST_FOR_CHANGES_REQUESTED = 'estimates/SEND_REQUEST_FOR_CHANGES_REQUESTED';
export const SEND_REQUEST_FOR_CHANGES_SUCCESS = 'estimates/SEND_REQUEST_FOR_CHANGES_SUCCESS';

export const deleteApproval = (estimateId, approverId, pageId, isEmphemeral) => async dispatch => {
  if (isEmphemeral) {
    dispatch({ type: APPROVAL_DELETE_REQUEST, approverId, estimateId });
  } else {
    await dispatch(fetch(`/estimates/${estimateId}/approvers/${approverId}`, { method: 'DELETE' }));

    await dispatch(fetchEstimate(estimateId, pageId));
    fetchWatchers(estimateId)(dispatch);
  }

  // dispatch({ type: APPROVAL_DELETE_SUCCESS, approverId, estimateId });

  // feels a bit hacky but force a refresh of the estimateStatus instead of dispatching an APPROVAL_DELETE_SUCCESS event
  // await dispatch(fetchEstimate(estimateId, pageId));
};

export const updateApproval =
  ({ estimateId, approverId, status = EstimateApprovalStatuses.APPROVED, message, pageId }) =>
  async dispatch => {
    dispatch({ type: APPROVAL_UPDATE_REQUEST, estimateId, approverId, status, message });
    await dispatch(
      fetch(`/estimates/${estimateId}/approvers/${approverId}`, {
        method: 'PUT',
        data: { status, message },
      })
    );
    // dispatch({ type: APPROVAL_UPDATE_SUCCESS, estimateId, approval });

    // feels a bit hacky but force a refresh of the estimateStatus instead of dispatching an APPROVAL_UPDATE_SUCCESS event
    await dispatch(fetchEstimate(estimateId, pageId));
  };

export const createApproval =
  (estimateId, approverId, pageId, approval, retry = false) =>
  async dispatch => {
    dispatch({ type: APPROVAL_CREATE_REQUEST, estimateId, approverId, approval, retry });

    try {
      const { data } = await dispatch(
        fetch(`/estimates/${estimateId}/approvers`, {
          method: 'POST',
          data: {
            approverId,
            nonce: approval ? approval.nonce : undefined,
            approvalType: approval?.approvalType,
          },
        })
      );

      await dispatch(fetchEstimate(estimateId, pageId));

      dispatch({ type: APPROVAL_CREATE_SUCCESS, estimateId, approval: data });

      fetchWatchers(estimateId)(dispatch);
    } catch {
      dispatch({
        type: APPROVAL_CREATE_ERROR,
        estimateId,
        approvalId: approval.id,
      });
    }
  };

export const retryCreateApproval = (estimateId, approvalId) => (dispatch, getState) => {
  const state = getState();
  const approvals = getEstimateApprovalsFor(estimateId)(state);
  const approval = approvals.find(approval => approval.id === approvalId);

  if (approval) {
    dispatch(createApproval(estimateId, approval.approverId, null, approval, true));
  }
};

export const resetApproveEstimateStatus = pageId => dispatch =>
  dispatch({ type: APPROVE_ESTIMATE_RESET });

export const approveEstimate = (estimateId, message, pageId) => async (dispatch, getState) => {
  const state = getState();
  const approverId = getUserId(state);
  await dispatch(
    updateApproval({
      estimateId,
      approverId,
      status: EstimateApprovalStatuses.APPROVED,
      message,
      pageId,
    })
  );
  dispatch({ type: APPROVE_ESTIMATE_SUCCESS, estimateId, approverId, message });
};

export const sendRequestForChanges =
  (estimateId, { message }, pageId) =>
  async (dispatch, getState) => {
    const approverId = getUserId(getState());

    dispatch({ type: SEND_REQUEST_FOR_CHANGES_REQUESTED, message, estimateId, approverId });

    await dispatch(
      updateApproval({
        estimateId,
        approverId,
        status: EstimateApprovalStatuses.CHANGES_REQUESTED,
        message,
        pageId,
      })
    );
    dispatch({ type: SEND_REQUEST_FOR_CHANGES_SUCCESS, message, estimateId, approverId });
  };
