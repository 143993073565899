import React from 'react';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-daterange-picker/dist/css/react-calendar.css';
import { compose, lifecycle, withState, withProps, withHandlers } from 'recompose';
import onClickOutside from 'react-onclickoutside';
import moment from 'moment';
import styled from 'styled-components';
import { colors, font, borderRadius } from 'shared/styles';

const CalendarStyled = styled(Calendar)`
  border-radius: ${borderRadius};
  border: 1px solid ${colors.border};
  overflow: hidden;
  z-index: 1;
`;

const DatePickerWrapped = compose(
  withHandlers({
    handleClickOutside: ({ onClickOutside }) => onClickOutside,
    handleSelect:
      ({ input, setDatePickerVisible }) =>
      value => {
        input.onChange(value);
        setDatePickerVisible(false);
      },
  }),
  onClickOutside
)(({ datePickerVisible, input, handleSelect }) => {
  return datePickerVisible ? (
    <div>
      <CalendarStyled
        date={input.value instanceof Date ? input.value : null}
        onChange={handleSelect}
      />
    </div>
  ) : null;
});

const DateFieldContainer = styled.div`
  position: relative;

  input.textInput {
    font-size: ${font.sizes.large};
  }

  .rdrCalendarWrapper {
    width: 100%;
  }

  .rdrMonth {
    margin: 0 auto;
  }
`;

const CompositeDatePicker = ({
  datePickerVisible,
  setDatePickerVisible,
  input,
  displayValue,
  placeholder,
}) => (
  <DateFieldContainer>
    <input
      className="input textInput"
      type="text"
      readOnly={true}
      placeholder={placeholder ? placeholder : 'Air Date'}
      value={displayValue}
      onFocus={() => setDatePickerVisible(true)}
      onClick={() => setDatePickerVisible(true)}
      data-test="reportingDate"
    />

    <DatePickerWrapped
      input={input}
      onClickOutside={() => setDatePickerVisible(false)}
      setDatePickerVisible={setDatePickerVisible}
      datePickerVisible={datePickerVisible}
    />
  </DateFieldContainer>
);

export default compose(
  withProps(({ input, placeholder }) => ({
    displayValue: (input.value && moment(input.value).format('Do MMMM YYYY')) || '',
    placeholder,
  })),
  withState('datePickerVisible', 'setDatePickerVisible', false),
  withHandlers({
    keyupHanlder:
      ({ setDatePickerVisible }) =>
      ({ key }) => {
        if (key === 'Escape') {
          // esc
          setDatePickerVisible(false);
        }
      },
  }),
  lifecycle({
    componentDidMount() {
      window.addEventListener('keyup', this.props.keyupHanlder);
    },
    componentDidUpdate(prevProps) {
      if (prevProps.visible !== this.props.visible) {
        this.props.setDatePickerVisible(this.props.visible);
      }
    },
    componentWillUnmount() {
      window.removeEventListener('keyup', this.props.keyupHanlder);
    },
  })
)(CompositeDatePicker);
