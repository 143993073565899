import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles(theme => {
  return {
    listIcon: {
      minWidth: 24,
    },
    listItem: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    listItemImg: {
      height: 10,
      display: 'inline',
    },
  };
});

export const SimpleOption = ({
  option,
  index,
  optionProps,
  isSelected,
  className,
  SecondaryAction,
  withCheckbox = true,
}) => {
  const classes = useStyles();
  return (
    <ListItem
      button
      {...optionProps({ option, index })}
      selected={isSelected(option.value)}
      className={clsx(className, classes.listItem, optionProps({ option, index }).className)}
    >
      {withCheckbox && (
        <ListItemIcon className={classes.listIcon}>
          <Checkbox
            disableRipple
            edge="start"
            color="primary"
            checked={isSelected(option.value, option.group)}
            tabIndex={-1}
            inputProps={{ 'aria-labelledby': `checkbox-list-label-${option.value}` }}
          />
        </ListItemIcon>
      )}
      {option.img && (
        <ListItemIcon className={classes.listIcon}>
          <img src={option.img} className={classes.listItemImg} alt={option.label} />
        </ListItemIcon>
      )}
      <ListItemText id={`checkbox-list-label-${option.value}`} primary={option.label} />
      {SecondaryAction}
    </ListItem>
  );
};

export const SelectAllOption = ({ optionProps, isSelectedAll, selectedCount, className }) => {
  const classes = useStyles();
  const option = { value: 'select-all' };

  return (
    <ListItem
      button
      {...optionProps({ option })}
      className={clsx(className, classes.listItem, optionProps({ option }).className)}
    >
      <ListItemIcon className={classes.listIcon}>
        <Checkbox
          disableRipple
          edge="start"
          color="primary"
          tabIndex={-1}
          checked={isSelectedAll}
          indeterminate={selectedCount > 0 && !isSelectedAll}
          inputProps={{ 'aria-labelledby': 'checkbox-list-label-select-all' }}
        />
      </ListItemIcon>
      <ListItemText id="checkbox-list-label-select-all" primary="Select All" />
    </ListItem>
  );
};

export const LoadingOption = () => {
  const classes = useStyles();
  return (
    <ListItem button className={classes.listItem}>
      <ListItemIcon className={classes.listIcon}>
        <CircularProgress size={16} />
      </ListItemIcon>
      <ListItemText id="loading-list-item" primary="Loading" />
    </ListItem>
  );
};

export const NoResultsOptions = () => (
  <ListItem button>
    <ListItemText id="empty-list-item" primary="No Results" />
  </ListItem>
);
