import { getFormValues } from 'redux-form';
import { flow, toPairs, fromPairs, filter } from 'lodash/fp';
import { fetch } from 'state/fetchMiddleware';

export const TEAM_CREATE_REQUEST = 'TEAM/CREATE_REQUEST';
export const TEAM_CREATE_SUCCESS = 'TEAM/CREATE_SUCCESS';
export const TEAM_CREATE_ERROR = 'TEAM/CREATE_ERROR';
export const TEAM_EDIT_REQUEST = 'TEAM/TEAM_EDIT_REQUEST';
export const TEAM_EDIT_SUCCESS = 'TEAM/TEAM_EDIT_SUCCESS';
export const TEAM_EDIT_ERROR = 'TEAM/TEAM_EDIT_ERROR';
export const TEAM_FETCH_REQUEST = 'TEAM/FETCH_REQUEST';
export const TEAM_FETCH_SUCCESS = 'TEAM/FETCH_SUCCESS';
export const TEAM_FETCH_ERROR = 'TEAM/FETCH_ERROR';
export const TEAM_DELETE_REQUEST = 'TEAM/DELETE_REQUEST';
export const TEAM_DELETE_SUCCESS = 'TEAM/DELETE_SUCCESS';
export const TEAM_DELETE_ERROR = 'TEAM/DELETE_ERROR';

export const TEAM_ADD_USER_REQUEST = 'TEAM/ADD_USER_REQUEST';
export const TEAM_ADD_USER_SUCCESS = 'TEAM/ADD_USER_SUCCESS';
export const TEAM_ADD_USER_ERROR = 'TEAM/ADD_USER_ERROR';
export const TEAM_REMOVE_USER_REQUEST = 'TEAM/REMOVE_USER_REQUEST';
export const TEAM_REMOVE_USER_SUCCESS = 'TEAM/REMOVE_USER_SUCCESS';
export const TEAM_REMOVE_USER_ERROR = 'TEAM/REMOVE_USER_ERROR';

export const TEAMS_FETCH_REQUEST = 'TEAMS/FETCH_REQUEST';
export const TEAMS_FETCH_SUCCESS = 'TEAMS/FETCH_SUCCESS';
export const TEAMS_FETCH_ERROR = 'TEAMS/FETCH_ERROR';
export const TEAMS_RESET = 'TEAMS/TEAMS_RESET';

const cleanFilters = flow(
  toPairs,
  filter(([key, value]) => Boolean(value)),
  fromPairs
);

export const fetchTeams = filters => async (dispatch, getState) => {
  dispatch({ type: TEAMS_FETCH_REQUEST });
  filters = cleanFilters(filters);
  try {
    const {
      data: { data: teams, pagination },
    } = await dispatch(fetch('/teams', { params: { ...filters } }));
    dispatch({ type: TEAMS_FETCH_SUCCESS, teams, pagination });
  } catch (error) {
    dispatch({ type: TEAMS_FETCH_ERROR, error });
  }
};

export const fetchTeam = (teamId, accountId) => async dispatch => {
  dispatch({ type: TEAM_FETCH_REQUEST });
  try {
    const { data: team } = await dispatch(fetch(`/teams/${teamId}`, { params: { accountId } }));
    dispatch({ type: TEAM_FETCH_SUCCESS, team });
  } catch (error) {
    dispatch({ type: TEAM_FETCH_ERROR, error });
  }
};

export const resetTeams = () => dispatch => dispatch({ type: TEAMS_RESET });

export const createTeam = ({ name, accountId }) => async (dispatch, getState) => {
  dispatch({ type: TEAM_CREATE_REQUEST });
  try {
    const { data: team } = await dispatch(
      fetch('/teams', {
        method: 'POST',
        data: { name, accountId },
      })
    );
    dispatch({ type: TEAM_CREATE_SUCCESS, team });

    const filters = getFormValues('teamFilters')(getState()) || {};
    dispatch(fetchTeams(filters));
  } catch (error) {
    dispatch({ type: TEAM_CREATE_ERROR, error });
    throw new Error(error.message || 'An error occurred while creating a new team.');
  }
};

export const editTeam = (id, { name }) => async (dispatch, getState) => {
  dispatch({ type: TEAM_EDIT_REQUEST });
  try {
    const { data: team } = await dispatch(
      fetch(`/teams/${id}`, {
        method: 'PUT',
        data: { name },
      })
    );
    dispatch({ type: TEAM_EDIT_SUCCESS, team });

    const filters = getFormValues('teamFilters')(getState()) || {};
    dispatch(fetchTeams(filters));
  } catch (error) {
    dispatch({ type: TEAM_EDIT_ERROR, error });
    throw new Error(error.message || 'An error occurred while editing the team.');
  }
};

export const deleteTeam = teamId => async dispatch => {
  dispatch({ type: TEAM_DELETE_REQUEST });
  try {
    await dispatch(fetch(`/teams/${teamId}`, { method: 'DELETE' }));
    dispatch({ type: TEAM_DELETE_SUCCESS, teamId });
  } catch (error) {
    dispatch({ type: TEAM_DELETE_ERROR, error });
    throw error;
  }
};

export const addUser = (teamId, userId) => async dispatch => {
  dispatch({ type: TEAM_ADD_USER_REQUEST });
  try {
    const { data: team } = await dispatch(
      fetch(`/teams/${teamId}/users`, {
        method: 'POST',
        data: { userId },
      })
    );
    dispatch({ type: TEAM_ADD_USER_SUCCESS, team });
  } catch (error) {
    dispatch({ type: TEAM_ADD_USER_ERROR, error });
    throw new Error(error.message || 'An error occurred while add a user to the team.');
  }
};

export const removeUser = (teamId, userId) => async dispatch => {
  dispatch({ type: TEAM_REMOVE_USER_REQUEST });
  try {
    await dispatch(fetch(`/teams/${teamId}/users/${userId}`, { method: 'DELETE' }));
    dispatch({ type: TEAM_REMOVE_USER_SUCCESS, teamId });
  } catch (error) {
    dispatch({ type: TEAM_REMOVE_USER_ERROR, error });
    throw error;
  }
};
