import React from 'react';
import styled from 'styled-components';
import { shadows, colors, inputHeight } from 'shared/styles';
import ApproveIcon from 'icons/ApproveIcon';
import CancelIcon from 'icons/CancelIcon';

const SaveCancelPopupContainer = styled.div`
  display: flex;
  box-shadow: ${shadows.text};
  position: absolute;
  width: 100px;
  height: ${inputHeight};
  border: 1px solid lightgray;
  border-radius: 5px;
  background-color: white;
  top: -50px;
  right: 0;
`;

const SaveCancelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

const Notch = styled.div`
  width: 14px;
  height: 14px;
  background-color: white;
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;
  transform: rotate(-135deg);
  position: absolute;
  right: 15px;
  bottom: -8px;
`;

const SaveEditButton = styled(({ className, ...props }) => {
  return (
    <div className={className} {...props}>
      <ApproveIcon className="icon" />
    </div>
  );
})`
  display: flex;
  cursor: pointer;
  .icon {
    color: ${colors.approve};
    margin: 0px !important;
  }
`;

const CancelEditButton = styled(({ className, ...props }) => {
  return (
    <div className={className} {...props}>
      <CancelIcon className="icon" />
    </div>
  );
})`
  display: flex;
  cursor: pointer;
  .icon {
    color: ${colors.cancelChanges};
    margin: 0px !important;
  }
`;

const SaveCancelPopup = ({ onSave, onCancel }) => (
  <SaveCancelPopupContainer>
    <SaveCancelContainer>
      <SaveEditButton onClick={onSave} />
      <CancelEditButton onClick={onCancel} />
      <Notch />
    </SaveCancelContainer>
  </SaveCancelPopupContainer>
);

export default SaveCancelPopup;
