import React from 'react';
import styled from 'styled-components';
import { font, colors, buttonStyles } from 'shared/styles';
import { InlineLoader } from 'components/spinner';
import { NavLink } from 'react-router-dom';

export const Button = styled(({ Component = 'button', className, ...restProps }) => (
  <Component type="button" className={`button ${className}`} {...restProps} />
))`
  ${buttonStyles}
`;

export const LinkButton = styled(NavLink)`
  ${buttonStyles}
`;

export const CancelButton = styled(Button)`
  background-color: ${colors.cancel};
  &:hover {
    background-color: ${colors.cancelHover};
  }
`;

export const ApproveButton = styled(Button)`
  background-color: ${colors.approve};
  &:hover {
    background-color: ${colors.approveHover};
  }
`;

export const ConfirmApprovalButton = styled(ApproveButton)`
  padding: 0 15px;
  width: auto;
`;

export const ConfirmEditButton = ApproveButton;

export const WarningButton = styled(Button)`
  background-color: ${colors.requestChanges};
  &:hover {
    background-color: ${colors.requestChangesHover};
  }
`;

export const SendRequestForChangesButton = styled(WarningButton)`
  padding: 0 15px;
  width: auto;
`;

export const AlertButton = styled(Button)`
  background-color: ${colors.alert};
  &:hover {
    background-color: ${colors.alertHover};
  }
`;

const ButtonLoader = styled(InlineLoader)`
  display: flex;
`;

export const ButtonWithLoading = ({ className, disabled, loading, children, ...props }) => (
  <Button className={className} type="submit" disabled={disabled} {...props}>
    {loading ? <ButtonLoader color="white" fadeIn="none" /> : children}
  </Button>
);

export const RoundButton = styled.div`
  width: 36px;
  height: 36px;
  background-color: white;
  border-radius: 18px;
  font-weight: ${font.weights.bold};
  letter-spacing: -0.4px;
  text-align: center;
  color: ${colors.primary};
  margin-right: 10px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  :hover {
    color: #ffffff;
    background: #87badc;
  }
  .icon {
    font-size: 24px;
  }
`;

export const InverseRoundButton = styled(RoundButton)`
  color: #ffffff;
  background: #87badc;

  :hover {
    color: ${colors.primary};
    background-color: white;
  }
`;

export const SmallFormButton = styled(Button)`
  border-width: 1px;
  height: 35px;
  line-height: ${font.sizes.normal};
  font-size: ${font.sizes.normal};
  background-color: ${colors.cancel};
  &:hover {
    background-color: ${colors.cancelHover};
  }
`;

export const FlatButton = styled(Button)`
  border: none;
  background: inherit;
  color: ${colors.text};
  &:hover {
    background-color: #33333311;
  }
`;
