import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { Events, track, getEstimateData } from 'components/analytics';
import { AlertButton, CancelButton, RoundButton } from 'components/buttons';
import SvgTrashIcon from 'icons/TrashIcon';
import { deleteApproval } from 'state/estimateApprovals/actions';
import { getEstimateById } from 'state/estimates/selectors';
import { showErrorNotification } from 'state/notifications/actions';
import { ApprovalType, AccountSetting, EstimateApprovalStatuses } from 'cr-core/constants';
import { getClientSettings } from 'state/authentication/selectors';
import Modal from 'components/modal';
import SvgBackIcon from 'icons/BackIcon';
import SvgCancelIcon from 'icons/CancelIcon';

const Button = ({
  handleRemoveApprover,
  estimate,
  approverId,
  agencyApproversEnabled,
  estimateApprovals,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [clientApprovals, agencyApprovals] = estimateApprovals;

  const approval = [...clientApprovals, ...agencyApprovals].find(
    approval => approval.approver?.id === approverId
  );

  const buttons = (
    <>
      <CancelButton onClick={() => setOpen(false)}>
        <SvgBackIcon className="icon" />
        Cancel
      </CancelButton>
      <AlertButton onClick={handleRemoveApprover}>
        <SvgCancelIcon className="icon" />
        Remove
      </AlertButton>
    </>
  );

  const shouldConfirm = () => {
    if (!approval) {
      return false;
    }

    const filteredApprovals = (
      (approval.approvalType === ApprovalType.CLIENT ? clientApprovals : agencyApprovals) ?? []
    ).filter(({ id }) => id !== approval.id);

    return (
      !isOpen &&
      (approval.approvalType === ApprovalType.CLIENT ? agencyApprovals.length : true) &&
      approval?.status === EstimateApprovalStatuses.PENDING_APPROVAL &&
      filteredApprovals.length >= 1 &&
      filteredApprovals.every(({ status }) => status === EstimateApprovalStatuses.APPROVED)
    );
  };

  const handleClick = () => {
    agencyApproversEnabled && shouldConfirm() ? setOpen(true) : handleRemoveApprover();
  };

  return (
    <>
      <RoundButton onClick={handleClick} data-test="remove-approver">
        <SvgTrashIcon className="icon" />
      </RoundButton>
      {agencyApproversEnabled && (
        <Modal
          isOpen={isOpen}
          buttons={buttons}
          onRequestClose={() => setOpen(false)}
          title="Are you sure?"
        >
          {approval.approvalType === ApprovalType.CLIENT ? (
            <>
              If you remove this user, the Estimate will be considered as approved by all client
              users and the agency approver(s) will be notified that the Estimate can be now
              approved.
            </>
          ) : (
            <>
              If you remove this user, the Estimate will be considered as approved and all approvers
              and watchers will be notified that the Estimate has been approved by all approvers.
            </>
          )}
          <br />
          <br />
          If you want to change the approver, please first add another user before removing the
          existing one.
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state, { estimateId }) => {
  const clientSettings = getClientSettings(state);
  const estimate = getEstimateById(estimateId)(state);
  const estimateApprovals = estimate?.approvals?.reduce(
    (acc, cur) => {
      cur.approvalType === ApprovalType.CLIENT ? acc[0].push(cur) : acc[1].push(cur);
      return acc;
    },
    [[], []]
  );

  return {
    estimate,
    agencyApproversEnabled: clientSettings[AccountSetting.AgencyApprovers],
    estimateApprovals: estimateApprovals ?? [[], []],
  };
};

const mapDispatchToProps = (dispatch, { estimateId, approverId, pageId }) => ({
  removeApprover: isEmphemeral =>
    dispatch(deleteApproval(estimateId, approverId, pageId, isEmphemeral)),

  showError: message => dispatch(showErrorNotification({ message })),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handleRemoveApprover:
      ({
        removeApprover,
        estimate,
        clientSettings,
        approverId,
        isEmphemeral,
        showError,
        agencyApproversEnabled,
        estimateApprovals,
      }) =>
      () => {
        if (agencyApproversEnabled) {
          const approvals = estimate?.approvals;
          const approval = approvals?.find(approval => approval.approver.id === approverId);
          const [clientApprovals, agencyApprovals] = estimateApprovals;

          const everyClientUserApproved =
            clientApprovals.length &&
            clientApprovals.every(({ status }) => status === EstimateApprovalStatuses.APPROVED);

          const everyAgencyUserApproved =
            agencyApprovals.length &&
            agencyApprovals.every(({ status }) => status === EstimateApprovalStatuses.APPROVED);

          if (everyClientUserApproved && everyAgencyUserApproved) {
            showError("Can't remove approvers when all approvers already approved");
            return;
          }

          if (
            approval?.approvalType === ApprovalType.CLIENT &&
            agencyApprovals.length &&
            everyClientUserApproved
          ) {
            showError(
              "Can't remove client approver while agency approver(s) is pending for approval"
            );
            return;
          }

          if (
            approval?.approvalType === ApprovalType.CLIENT &&
            clientApprovals.length === 1 &&
            agencyApprovals.length
          ) {
            showError("Can't remove client approver while agency approver is still present");
            return;
          }
        }

        removeApprover(isEmphemeral);
        track(Events.ESTIMATE_DELETE_APPROVER, {
          ...getEstimateData(estimate),
          approverId,
        });
      },
  })
)(Button);
