import React from 'react';
import UserAvatar from 'components/userAvatar';
import { map } from 'lodash/fp';

const Team = ({ team }) =>
  map(
    user => <UserAvatar key={user.id} user={user} showName={false} data-test="user-avatar" />,
    team
  );

export default Team;
