import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { Events, track, getEstimateData } from 'components/analytics';
import { ConfirmApprovalButton, CancelButton } from 'components/buttons';
import ApprovalsList from 'components/estimateApprovals/approvalsList';
import EstimateBidsTable from 'components/estimateBidsTable';
import EstimateInfo from 'components/estimateInfoForPrint';
import Modal from 'components/modal';
import SvgBackIcon from 'icons/BackIcon';
import SvgPrintIcon from 'icons/PrintIcon';
import { getEstimateById } from 'state/estimates/selectors';

const ModalStyled = styled(Modal)`
  .body {
    background: grey;
    overflow: scroll;
  }

  .footer {
    border-top: 1px solid lightgray;
  }
`;

const ModalBody = styled.div`
  width: 100%;
  zoom: 0.8;
  display: flex;
  flex-direction: column;
  background: white;
  margin-bottom: 30px;

  .modalWindow {
    width: 90%;
  }

  @media print and (min-width: 480px) {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    overflow: visible;
    background-color: white;
  }
`;

const DocumentBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const EstimateTableContainer = styled.div`
  display: flex;
  flex-shrink: 0;
`;

const PrintableEstimateTable = styled(EstimateBidsTable)`
  box-sizing: border-box;
  .toolbar {
    display: none;
  }
  & * {
    box-shadow: none !important;
  }
`;

const ApprovalsListForPrint = styled(ApprovalsList)`
  margin-left: 20px;
  margin-right: 20px;
  .actions {
    display: none;
  }
`;

const TimestampContainer = styled.div`
  margin: 20px;
  color: #ccc;
`;

const Timestamp = () => (
  <TimestampContainer>Printed at: {moment().format('DD MMM YYYY HH:SS')}</TimestampContainer>
);

const mapStateToProps = (state, { estimateId }) => {
  return {
    estimate: getEstimateById(estimateId)(state),
  };
};

export default connect(mapStateToProps)(
  ({ onRequestClose, estimateId, estimate, ...restProps }) => {
    if (!estimate) {
      return null;
    }
    const buttons = (
      <>
        <CancelButton
          onClick={() => {
            track(Events.ESTIMATE_PRINT_CANCEL, getEstimateData(estimate));
            onRequestClose();
          }}
        >
          <SvgBackIcon className="icon" />
          Cancel
        </CancelButton>
        <ConfirmApprovalButton
          onClick={() => {
            track(Events.ESTIMATE_PRINT_SUCCESS, getEstimateData(estimate));
            window.print();
            onRequestClose();
          }}
        >
          <SvgPrintIcon className="icon" />
          Print
        </ConfirmApprovalButton>
      </>
    );
    return (
      <ModalStyled
        title="Print Estimate"
        buttons={buttons}
        {...restProps}
        onRequestClose={onRequestClose}
      >
        <ModalBody id="printTarget">
          <DocumentBody>
            <EstimateInfo estimateId={estimateId} />
            <EstimateTableContainer>
              <PrintableEstimateTable readOnly />
            </EstimateTableContainer>
            <ApprovalsListForPrint estimateId={estimateId} />
            <Timestamp />
          </DocumentBody>
        </ModalBody>
        <style>
          {`
          @media print {
            html,
            body {
              -webkit-print-color-adjust: exact;
            }

            #root {
              visibility: hidden;
              display: none;
            }

            .modalWindow {
              position: unset;
            }

            #printTarget {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              overflow: visible;
            }

            html,
            body,
            .ReactModal__Overlay {
              overflow: visible !important;
            }

            .ReactModal__Overlay {
              position: absolute !important;
            }
          }
          `}
        </style>
      </ModalStyled>
    );
  }
);
