import React from 'react';
import styled from 'styled-components';
import { colors, font } from 'shared/styles';

const Warnings = styled.div`
  background: ${colors.warningBackground};
  padding: 20px;
  color: #4a4a4a;
  border-left: 4px solid orange;
`;

const WarningHeader = styled.div`
  font-weight: ${font.weights.bold};
  margin-bottom: 10px;
  color: ${colors.requestChangesHover};
`;

const WarningBody = styled.div``;

const WarningBlock = ({ children, className }) => (
  <Warnings data-test="warning-message" className={className}>
    <WarningHeader>Warning</WarningHeader>
    <WarningBody>{children}</WarningBody>
  </Warnings>
);

export default WarningBlock;
