import React from 'react';

const SvgBidsIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="bids-icon_svg__a"
        width="134.8%"
        height="133.3%"
        x="-17.4%"
        y="-16.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      d="M.603 20.718h12.33c.33 0 .6.271.6.605v2.072a.603.603 0 0 1-.6.605H.602c-.331 0-.6-.27-.6-.605v-2.072c0-.334.269-.605.6-.605zM4.54 7.501l2.908-2.929a.597.597 0 0 1 .849 0l14.527 14.65a.609.609 0 0 1 0 .856l-2.905 2.93a.597.597 0 0 1-.849 0L4.54 8.357a.609.609 0 0 1 0-.856zM12.655.177l5.812 5.86a.609.609 0 0 1 0 .855l-2.908 2.932a.597.597 0 0 1-.849 0L8.901 3.96a.609.609 0 0 1 0-.855L11.807.177a.597.597 0 0 1 .849 0zM3.93 8.967l5.812 5.86a.609.609 0 0 1 0 .856l-2.905 2.93a.597.597 0 0 1-.85 0l-5.811-5.86a.609.609 0 0 1 0-.857l2.905-2.93a.597.597 0 0 1 .849 0z"
      filter="url(#bids-icon_svg__a)"
      transform="translate(4 3)"
    />
  </svg>
);

export default SvgBidsIcon;
