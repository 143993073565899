import React from 'react';

const SvgCopyrightIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="copyright-icon_svg__a"
        width="141.7%"
        height="141.7%"
        x="-20.8%"
        y="-20.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.356 9.588c.168-.3.408-.552.708-.744.288-.18.648-.264 1.092-.276.276.012.528.06.756.156.24.108.456.252.624.432.168.18.3.396.408.636.108.24.156.504.168.768h2.148a4.295 4.295 0 0 0-.336-1.548 3.601 3.601 0 0 0-.84-1.212 3.92 3.92 0 0 0-1.296-.792c-.504-.192-1.056-.276-1.668-.276-.78 0-1.464.132-2.04.408a4.127 4.127 0 0 0-1.44 1.104 4.511 4.511 0 0 0-.852 1.632 7.028 7.028 0 0 0-.288 1.968v.324c0 .696.096 1.344.276 1.968.18.624.468 1.164.852 1.62.384.456.864.828 1.44 1.092.576.264 1.26.408 2.04.408.564 0 1.092-.096 1.584-.276.492-.18.924-.432 1.296-.756s.672-.696.888-1.128a3.23 3.23 0 0 0 .36-1.38h-2.148c-.012.252-.072.48-.18.696a1.766 1.766 0 0 1-.432.552 1.89 1.89 0 0 1-.624.36c-.228.084-.468.108-.72.12-.432-.012-.792-.096-1.068-.276-.3-.192-.54-.444-.708-.744-.168-.3-.3-.66-.36-1.056a8.088 8.088 0 0 1-.096-1.2v-.324c0-.42.036-.816.096-1.212.06-.396.192-.744.36-1.044zM12 0C5.376 0 0 5.376 0 12s5.376 12 12 12 12-5.376 12-12S18.624 0 12 0zm0 21.6c-5.292 0-9.6-4.308-9.6-9.6S6.708 2.4 12 2.4s9.6 4.308 9.6 9.6-4.308 9.6-9.6 9.6z"
      filter="url(#copyright-icon_svg__a)"
      transform="translate(3 3)"
    />
  </svg>
);

export default SvgCopyrightIcon;
