import React from 'react';

import useSuppliers from 'hooks/useSuppliers';
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Supplier } from 'services/SupplierService';
import { useState } from 'react';
import SupplierModal from './SupplierModal';
import ColumnModal from './ColumnModal';
import SupplierActions from './SupplierActions';
import { Waypoint } from 'react-waypoint';
import { download } from 'utils';
import config from 'config';
import queryString from 'query-string';
import { getUser } from 'state/authentication/selectors';
import { connect } from 'react-redux';
import useSupplierColumns from 'hooks/useSupplierColumns';
import SuppliersImportModal from './SuppliersImportModal';

interface Props {
  accountId: string;
  user: any;
}

const useStyles = makeStyles(theme => ({
  addSuppliers: {
    marginRight: 10,
  },

  tableContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  header: {
    fontWeight: 600,
  },

  row: {
    height: 80,
  },

  archived: {
    color: '#f44336',
  },

  importSuppliers: {
    marginRight: 10,
  },
}));

const SuppliersTable = ({ accountId, user }: Props) => {
  const styles = useStyles();
  const { data: columns, isLoading: isLoadingColumns } = useSupplierColumns(accountId);
  const {
    data,
    isLoading: isLoadingSuppliers,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useSuppliers(accountId);
  const suppliers = data?.pages.reduce((acc, cur) => acc.concat(cur.data), [] as Supplier[]) ?? [];
  const [modal, setModal] = useState<'supplier' | 'column' | 'import' | null>(null);
  const isLoading = isLoadingColumns || isLoadingSuppliers;

  const closeModal = () => {
    setModal(null);
  };

  const openSupplierModal = () => {
    setModal('supplier');
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box>
        <Box display="flex" justifyContent="space-between" mb={3}>
          <Box>
            <Button
              variant="contained"
              onClick={() => setModal('import')}
              className={styles.importSuppliers}
            >
              Import
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                download(
                  queryString.stringifyUrl({
                    url: `${config.apiEndpoint}/suppliers/export`,
                    query: { accountId, clientId: user.client.id },
                  })
                );
              }}
            >
              Export
            </Button>
          </Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              onClick={openSupplierModal}
              className={styles.addSuppliers}
            >
              Add Supplier
            </Button>
            <Button variant="contained" onClick={() => setModal('column')}>
              Add Column
            </Button>
          </Box>
        </Box>
        {!isLoading && (
          <>
            {!suppliers.length ? (
              <Box flexDirection="column" display="flex" alignItems="center">
                <Box mb={1}>No suppliers added yet.</Box>
                <Button color="primary" variant="contained" onClick={openSupplierModal}>
                  Add Supplier
                </Button>
              </Box>
            ) : (
              <Paper className={styles.tableContainer}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={styles.header}>Name</TableCell>
                        {columns?.map(column => (
                          <TableCell key={column.id} className={styles.header}>
                            {column.name}
                          </TableCell>
                        ))}
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {suppliers.map(({ id, status, name, deletedAt, metadata }) => (
                        <TableRow key={id} className={styles.row}>
                          <TableCell>{name}</TableCell>
                          {columns?.map(column => (
                            <TableCell key={`${id}-${column.id}`}>
                              {metadata[column.id]?.value}
                            </TableCell>
                          ))}
                          <TableCell align="right">
                            <Box minWidth={150}>
                              {deletedAt ? (
                                <span className={styles.archived}>Archived</span>
                              ) : (
                                <SupplierActions
                                  supplierId={id}
                                  supplierStatus={status}
                                  accountId={accountId}
                                />
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {isFetchingNextPage && (
                  <Box display="flex" justifyContent="center" mt={2}>
                    <CircularProgress />
                  </Box>
                )}
                {!isFetchingNextPage && hasNextPage && (
                  <>
                    <Box display="flex" justifyContent="center" mt={2}>
                      <Button onClick={() => fetchNextPage()} variant="contained" color="primary">
                        Load More
                      </Button>
                    </Box>
                    <Waypoint onEnter={() => fetchNextPage()} />
                  </>
                )}
              </Paper>
            )}
          </>
        )}
      </Box>

      <SupplierModal accountId={accountId} open={modal === 'supplier'} onClose={closeModal} />
      <ColumnModal accountId={accountId} open={modal === 'column'} onClose={closeModal} />
      <SuppliersImportModal accountId={accountId} open={modal === 'import'} onClose={closeModal} />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  user: getUser(state),
});

export default connect(mapStateToProps)(SuppliersTable);
