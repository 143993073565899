import React from 'react';

const SvgSaveIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="save-icon_svg__a"
        width="128.6%"
        height="144.6%"
        x="-14.3%"
        y="-21.4%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      d="M22.575 7.047A8.738 8.738 0 0 0 14 0a8.745 8.745 0 0 0-7.758 4.713A6.993 6.993 0 0 0 0 11.667c0 3.861 3.138 7 7 7h15.167A5.835 5.835 0 0 0 28 12.833c0-3.08-2.392-5.576-5.425-5.786zm-10.908 8.12l-4.084-4.084 1.645-1.645 2.439 2.427 6.043-6.032 1.645 1.645-7.688 7.689z"
      filter="url(#save-icon_svg__a)"
      transform="translate(1 6)"
    />
  </svg>
);

export default SvgSaveIcon;
