import React from 'react';
import SuppliersTable from './SuppliersTable';

interface Props {
  accountId: string | undefined;
}

const Suppliers = ({ accountId }: Props) => {
  if (!accountId) {
    return <div>Select an account</div>;
  }

  return <SuppliersTable accountId={accountId} />;
};

export default Suppliers;
