import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { map, sumBy, groupBy, mapValues, toPairs, sortBy, flow } from 'lodash/fp';
import { CampaignStatuses } from 'cr-core/constants';
import { formatAsCurrency } from 'cr-core/currencyUtils';
import StatusBreakdownChart from './budgetBreakdownSimple';
import Status from '../estimateStatus';
import { estimateStatusOrder } from '../utils';
import { getCost, widgetStyles } from './utils';

const useStyles = makeStyles(theme => ({
  ...widgetStyles(theme),

  container: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  body: {
    display: 'flex',
  },
  statusRow: {
    margin: theme.spacing(0.5, 0),
  },
  rows: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
  footer: {
    borderTop: `2px solid ${theme.palette.grey[200]}`,
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  figure: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1rem',
  },
  overbudget: {
    color: theme.palette.error.main,
  },
  savings: {
    color: theme.palette.success.main,
  },
}));

const remainingLabels = {
  SAVINGS: 'Savings',
  REMAININGS: 'Budget Left',
  OVERBUDGET: 'Overbudget',
};

export default ({ campaign, estimates, displayCurrency }) => {
  const classes = useStyles();
  const remaining = campaign.budget - campaign.totalCost;
  const splitByStatus = flow(
    groupBy('status'),
    mapValues(sumBy(getCost)),
    toPairs,
    sortBy(([status]) => estimateStatusOrder[status])
  )(estimates);
  const remainingsType =
    remaining >= 0
      ? campaign.status === CampaignStatuses.ACTUALISED
        ? 'SAVINGS'
        : 'REMAININGS'
      : 'OVERBUDGET';

  return (
    <div className={classes.container} data-test="campaign-status-breakdown-widget">
      <div className={classes.body}>
        <StatusBreakdownChart
          statusBreakdown={splitByStatus}
          displayCurrency={displayCurrency}
          status={campaign.status}
          totalSpend={campaign.totalCost}
          budget={campaign.budget}
        />
        {!splitByStatus.length && (
          <div>
            <i>This campaign has no estimates</i>
          </div>
        )}
        <div className={classes.rows} data-test="statuses-costs">
          {map(
            ([status, cost]) => (
              <div className={clsx(classes.row, classes.statusRow)} key={status}>
                <Status status={status} />{' '}
                <div className={classes.figure}>{formatAsCurrency(displayCurrency, cost)}</div>
              </div>
            ),
            splitByStatus
          )}
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.row}>
          <div>Total Spend</div>
          <div className={classes.figure} data-test="campaign-total-spend">
            {formatAsCurrency(displayCurrency, campaign.totalCost)}
          </div>
        </div>
        <div className={classes.row}>
          <div>Budget</div>
          <div className={classes.figure} data-test="campaign-budget">
            {formatAsCurrency(displayCurrency, campaign.budget)}
          </div>
        </div>
        <div className={classes.row}>
          <div data-test="campaign-remainings-label">{remainingLabels[remainingsType]}</div>
          <div
            className={clsx(classes.figure, {
              [classes.savings]: remainingsType === 'SAVINGS',
              [classes.overbudget]: remainingsType === 'OVERBUDGET',
            })}
            data-test="campaign-remainings"
          >
            {formatAsCurrency(displayCurrency, Math.abs(remaining))}
          </div>
        </div>
      </div>
    </div>
  );
};
