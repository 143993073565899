import React from 'react';
import { ifProp } from 'styled-tools';
import ReactSelect from 'react-select';
import ReactSelectAsync from 'react-select/lib/Async';
import CreatableReactSelect from 'react-select/lib/Creatable';
import CreatableReactSelectAsync from 'react-select/lib/AsyncCreatable';
import styled, { css } from 'styled-components';
import { colors, font, inputHeight } from 'shared/styles';
import TextField from 'components/textField';
import { withProps, compose, withState, withHandlers } from 'recompose';
import { map } from 'lodash/fp';
import { InlineLoader } from '../spinner';

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  select {
    font-family: ${font.fontFamily};
    font-size: ${font.sizes.normal};
  }
`;

export const InputContainer = styled.div`
  width: 350px;

  [type='text'] {
    width: 100%;
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  justify-content: center;
  margin-right: 10px;
  width: 200px;
  font-size: ${font.sizes.large};
  font-weight: ${font.weights.bold};
  color: ${({ hasError }) => (hasError ? colors.error : colors.text)};

  &.top {
    justify-content: flex-start;
    margin-top: 15px;
  }
`;

export const SubLabel = styled.div`
  font-weight: ${font.weights.regular};
  font-size: ${font.sizes.small};
  text-align: right;
`;

export const CheckboxLabel = styled(Label)`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
  input[type='checkbox'] {
    appearance: checkbox;
    margin-right: 10px;
    transform: scale(1.5);
  }
`;

export const CheckboxGroupContainer = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
`;

const setLabel = ({ label, meta }) => {
  const hasError = meta.touched && meta.error;
  return {
    hasError,
    errorMessage: meta.error,
  };
};

const ErrorLabel = styled.div`
  display: flex;
  margin-top: 10px;
  font-size: ${font.sizes.large};
  font-weight: ${font.weights.regular};
  color: ${colors.error};
`;

export const InputTextField = withProps(setLabel)(
  ({ type, placeholder, input, label, hasError, errorMessage }) => (
    <FieldContainer>
      {label && <Label htmlFor={input.name}>{label}</Label>}
      <TextField placeholder={placeholder} type={type} {...input} />
      {hasError && <ErrorLabel>{errorMessage}</ErrorLabel>}
    </FieldContainer>
  )
);

const withOpenness = compose(
  withState('isOpen', 'setIsOpen', false),
  withHandlers({
    onMenuOpen: ({ setIsOpen }) => () => setIsOpen(true),
    onMenuClose: ({ setIsOpen }) => () => setIsOpen(false),
  })
);

const reactSelectStyles = `
  color: ${colors.text};
  width: 100%;
  ${ifProp(
    'isOpen',
    css`
      z-index: 1 !important;
    `
  )}
  input {
    height: 20px !important;
  }
  .reactSelect__control {
    height: ${inputHeight};
  }
  .reactSelect__value-container {
    padding: 2px 4px 2px 12px;
  }
  .reactSelect__placeholder {
    margin: 0;
  }
  .reactSelect__single-value {
    margin-left: 0;
    margin-right: 0;
  }
  &.error .reactSelect__control {
    border-color: ${colors.error};
  }

  &.reactSelect__multi .reactSelect__control {
    height: auto;
    min-height: ${inputHeight};
  }

  .reactSelect__multi-value__remove {
    cursor: pointer;
  }
`;

export const Select = withOpenness(styled(({ className, ...props }) => (
  <ReactSelect className={`${className} reactSelect`} classNamePrefix="reactSelect" {...props} />
))`
  ${reactSelectStyles}
`);

export const SelectAsync = withOpenness(styled(({ className, ...props }) => (
  <ReactSelectAsync
    className={`${className} reactSelect`}
    classNamePrefix="reactSelect"
    {...props}
  />
))`
  ${reactSelectStyles}
`);

const CreatableLoader = styled(InlineLoader)`
  margin-right: 5px;
  &.ball-beat > div {
    width: 10px;
    height: 10px;
  }
`;

export const CreatableSelectComponent = Component =>
  withOpenness(styled(({ className, ...props }) => (
    <Component
      className={`${className} reactSelect creatable`}
      components={{ LoadingIndicator: CreatableLoader }}
      classNamePrefix="reactSelect"
      {...props}
    />
  ))`
    ${reactSelectStyles}
  `);

export const CreatableSelect = CreatableSelectComponent(CreatableReactSelect);
export const CreatableSelectAsync = CreatableSelectComponent(CreatableReactSelectAsync);

export const SelectField = withProps(setLabel)(({ values, input, label, hasError }) => (
  <FieldContainer>
    {label && (
      <Label htmlFor={input.name} hasError={hasError}>
        {label}
      </Label>
    )}
    <select {...input}>
      {map(
        ({ name, id }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ),
        values
      )}
    </select>
  </FieldContainer>
));

export const Error = styled.div`
  color: ${colors.error};
  margin-bottom: 20px;
`;

export const createSelectOptionFromString = value => ({ label: value, value });
