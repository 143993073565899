import React, { useEffect } from 'react';
import { get } from 'lodash/fp';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import AppContainer from 'components/appContainer';
import { getWorkspacesPagination } from 'state/workspaces/selectors';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Hidden } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/Add';
import WorkspacesList from 'components/workspaces/workspacesList';
import Filters from 'components/workspaces/workspaceFilters';
import WorkspaceDetails from 'components/workspaces/workspaceDetails';
import CreateWorkspace from 'components/workspaces/createWorkspace';
import { getAccountById } from 'state/accounts/selectors';
import { getUser } from 'state/authentication/selectors';
import { openSidePanel, closeSidePanel } from 'state/ui/actions';
import { getSidePanelData } from 'state/ui/selectors';
import { getSearchFromQSParams } from 'shared/browserUtils';

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  headerRight: {
    display: 'flex',
  },
}));

const WorkspacesPage = ({
  pagination,
  sidePanelData,
  openSidePanel,
  closeSidePanel,
  match,
  history,
  user,
  selectedAccount,
}) => {
  const classes = useStyles();
  let sidePanelContent;

  if (sidePanelData.view === 'WORKSPACE_DETAILS') {
    sidePanelContent = <WorkspaceDetails />;
  } else if (sidePanelData.view === 'CREATE_WORKSPACE') {
    sidePanelContent = <CreateWorkspace />;
  }

  useEffect(() => {
    const { workspaceId } = match.params;
    if (workspaceId && workspaceId === 'new') {
      openSidePanel({ view: 'CREATE_WORKSPACE' });
    }
    return () => closeSidePanel();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addWorkspace = () => {
    history.push({
      pathname: '/workspaces/new',
      search: getSearchFromQSParams(),
    });
    openSidePanel({ view: 'CREATE_WORKSPACE' });
  };

  const onCloseSidePanel = () => history.push('/workspaces' + window.location.search);

  const canCreateWorkspace =
    !get('agency', selectedAccount) && !get('consultancy', selectedAccount);

  return (
    <AppContainer sidePanelContent={sidePanelContent} onCloseSidePanel={onCloseSidePanel}>
      <div className={classes.header}>
        <div className={classes.headerRight}>
          <Typography variant="h4">Workspaces</Typography>
          <Filters />
        </div>
        <div>
          {Boolean(pagination.totalResults) && (
            <Hidden smDown>{pagination.totalResults} workspaces</Hidden>
          )}
          {canCreateWorkspace && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<AddCircleIcon />}
              onClick={addWorkspace}
            >
              Add Workspace
            </Button>
          )}
        </div>
      </div>
      <WorkspacesList />
    </AppContainer>
  );
};

const mapStateToProps = state => {
  const accountId = get('accountId', getFormValues('workspaceFilters')(state));
  return {
    pagination: getWorkspacesPagination(state),
    sidePanelData: getSidePanelData(state),
    user: getUser(state),
    selectedAccount: getAccountById(accountId)(state),
  };
};

const mapDispatchToProps = dispatch => ({
  openSidePanel: data => dispatch(openSidePanel(data)),
  closeSidePanel: data => dispatch(closeSidePanel(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkspacesPage);
