import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  action: string | null;
}

const ApproveConfirmationModal = ({ open, onClose, onUpdate, action }: Props) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Are you sure?</DialogTitle>
      { action === 'approve' && 
        <DialogContent>
          Are you sure you want to approve this supplier?
        </DialogContent>
      }
      { action === 'reject' && 
        <DialogContent>
          Are you sure you want to reject this supplier?
        </DialogContent>
      }
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        { action === 'approve' && 
          <Button variant="contained" color="primary" onClick={onUpdate}>
            Approve
          </Button>
        }
        { action === 'reject' && 
          <Button variant="contained" color="secondary" onClick={onUpdate}>
            Reject
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
};

export default ApproveConfirmationModal;
