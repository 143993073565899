import { flow, get, orderBy, filter, identity, find } from 'lodash/fp';
import { formName } from 'components/users/usersFilters';

export const getUsersFiltersValues = get(`form.${formName}.values`);

export const getUsers = flow(get('users.users'), orderBy([], []));

export const getFilteredUsers = state => {
  const filters = getUsersFiltersValues(state);
  const workspaceId = get('workspaceId.value', filters);

  return flow(
    getUsers,
    workspaceId ? filter(({ workspaceIds }) => workspaceIds.includes(workspaceId)) : identity
  )(state);
};

export const getUsersPagination = get('users.pagination');

export const getUsersError = get('users.error');

export const getUserById = id => state => {
  const users = get('users.users', state);
  const user = find({ id }, users);
  return user;
};
