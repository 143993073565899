import React from 'react';
import moment from 'moment';
import { flow, min, max, map, flatten } from 'lodash/fp';
import { Line } from 'react-chartjs-2';
import chroma from 'chroma-js';
import { EstimateStatuses, EstimateStatusLabels } from 'cr-core/constants';
import { formatBigNumberCurrency, tooltipsCallbacksLabel } from './utils';
import { colors } from 'shared/styles';

const labelsColors = {
  [EstimateStatuses.ACTUALISED]: chroma(colors.actualised).darken(),
  [EstimateStatuses.APPROVED]: chroma(colors.approve),
  [EstimateStatuses.PENDING_APPROVAL]: chroma(colors.pendingApproval),
  [EstimateStatuses.DRAFT]: chroma(colors.draft),
  [EstimateStatuses.CANCELLED]: chroma(colors.cancelled),
};

const labels = EstimateStatusLabels;

const buildChartData = data => {
  if (!data || !data.length) {
    return [];
  }
  const chartData = {
    datasets: data.map(({ label, data }, i) => ({
      data,
      label: labels[label],
      pointRadius: 0,
      backgroundColor: labelsColors[label].alpha(0.5).css(),
      hoverBackgroundColor: labelsColors[label].alpha(0.5).css(),
      borderColor: labelsColors[label],
      borderWidth: 1,
      hoverBorderColor: labelsColors[label],
    })),
  };

  // this is so the areas fill don't overlay
  chartData.datasets[0].fill = 'origin';

  return chartData;
};

export default ({ data, displayCurrency, width, height, stacked = false }) => {
  const allDates = flow(map('data'), flatten, map('x'))(data);
  const minDate = moment(min(allDates));
  const maxDate = moment(max(allDates));
  const months = moment.duration(maxDate.diff(minDate)).months();
  return (
    <Line
      data={buildChartData(data)}
      width={width}
      height={height}
      options={{
        elements: {
          line: {
            fill: '-1', // by default, fill lines to the previous dataset,
            tension: 0.05,
          },
        },
        maintainAspectRatio: true,
        legend: {
          display: true,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              type: 'time',
              distribution: 'linear',
              time: {
                minUnit: months > 3 ? 'month' : 'day',
              },
            },
          ],
          yAxes: [
            {
              stacked,
              ticks: {
                callback: (value, index, values) => formatBigNumberCurrency(value, displayCurrency),
                min: 0,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: tooltipsCallbacksLabel(displayCurrency),
          },
        },
      }}
    />
  );
};
