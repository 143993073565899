import uuid from 'uuid/v4';
import { map, forEach, find, findIndex, get } from 'lodash/fp';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  ESTIMATES_FETCH_REQUEST,
  ESTIMATES_FETCH_SUCCESS,
  ESTIMATE_FETCH_REQUEST,
  ESTIMATE_FETCH_SUCCESS,
  ESTIMATE_FETCH_ERROR,
  SUBMIT_ESTIMATE_REQUEST,
  SUBMIT_ESTIMATE_SUCCESS,
  SUBMIT_ESTIMATE_ERROR,
  SUBMIT_ESTIMATE_RESET,
  RESUBMIT_ESTIMATE_REQUEST,
  RESUBMIT_ESTIMATE_SUCCESS,
  RESUBMIT_ESTIMATE_ERROR,
  RESUBMIT_ESTIMATE_RESET,
  ESTIMATES_CAMPAIGN_FETCH_REQUEST,
  ESTIMATES_CAMPAIGN_FETCH_SUCCESS,
} from 'state/estimates/actions';
import {
  APPROVAL_UPDATE_REQUEST,
  APPROVE_ESTIMATE_SUCCESS,
  APPROVE_ESTIMATE_RESET,
} from 'state/estimateApprovals/actions';
import {
  CAMPAIGNS_RESET,
  CAMPAIGN_CREATE_REQUEST,
  CAMPAIGN_CREATE_SUCCESS,
  CAMPAIGN_CREATE_ERROR,
  CAMPAIGNS_FETCH_REQUEST,
  CAMPAIGNS_FETCH_SUCCESS,
} from 'state/campaigns/actions';
import { CREATE_LINE_ITEM_REQUEST, CREATE_LINE_ITEM_SUCCESS } from 'state/lineItems/actions';
import {
  REPORT_COST_CATEGORIES_FETCH_REQUEST,
  REPORT_COST_CATEGORIES_FETCH_SUCCESS,
  REPORT_DASHBOARD_REQUEST,
  REPORT_DASHBOARD_SUCCESS,
} from 'state/reports/actions';
import {
  VERIFY_SIGNUP_CODE_REQUEST,
  VERIFY_SIGNUP_CODE_SUCCESS,
  VERIFY_SIGNUP_CODE_ERROR,
  COMPLETE_SIGNUP_REQUEST,
  COMPLETE_SIGNUP_SUCCESS,
  COMPLETE_SIGNUP_ERROR,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
} from 'state/authentication/actions';
import { FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS, FETCH_USERS_ERROR } from 'state/users/actions';
import {
  ESTIMATE_INOVICE_FETCH_REQUEST,
  ESTIMATE_INOVICE_FETCH_SUCCESS,
  ESTIMATE_INOVICE_FETCH_ERROR,
} from 'state/estimateInvoices/actions';
import {
  WORKSPACES_FETCH_SUCCESS,
  WORKSPACES_FETCH_REQUEST,
  WORKSPACES_FETCH_ERROR,
} from 'state/workspaces/actions';
import { TEAMS_FETCH_SUCCESS, TEAMS_FETCH_REQUEST, TEAMS_FETCH_ERROR } from 'state/teams/actions';
import { PRODUCTS_FETCH_REQUEST, PRODUCTS_FETCH_SUCCESS } from 'state/products/actions';

export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';

const defaultState = {
  estimates: undefined,
  estimatesLastRequestId: undefined,
  campaign: undefined,
  campaignLastRequestId: undefined,
  resetPassword: undefined,
  workspaces: undefined,
  products: undefined,
  page: {},
  campaignsPage: {
    campaigns: [],
    lastIndex: 0,
  },
};

const reducers = (state = defaultState, action) => {
  switch (action.type) {
    // estimates
    case ESTIMATES_FETCH_REQUEST: {
      const newState = { ...state, estimates: LOADING };
      if (action.requestId) {
        newState.estimatesLastRequestId = action.requestId;
      }
      return newState;
    }
    case ESTIMATES_FETCH_SUCCESS: {
      if (state.estimatesLastRequestId && state.estimatesLastRequestId !== action.requestId) {
        return state;
      }

      if (action.query.campaignId) {
        forEach(id => {
          const campaign = find({ id }, state.campaignsPage.campaigns);
          if (campaign) {
            campaign.estimatesLoaded = true;
          }
        }, action.query.campaignId);
      }

      return {
        ...state,
        estimates: SUCCESS,
        estimatesLastRequestId: undefined,
        campaignsPage: state.campaignsPage,
      };
    }
    case ESTIMATES_CAMPAIGN_FETCH_REQUEST:
      return {
        ...state,
        campaignEstimates: LOADING,
        campaignEstimatesLastRequestId: action.requestId,
      };
    case ESTIMATES_CAMPAIGN_FETCH_SUCCESS:
      if (state.campaignEstimatesLastRequestId !== action.requestId) {
        return state;
      }
      return { ...state, campaignEstimates: SUCCESS };
    case ESTIMATE_FETCH_REQUEST:
      return { ...state, estimate: LOADING };
    case ESTIMATE_FETCH_SUCCESS:
      return { ...state, estimate: SUCCESS };
    case ESTIMATE_FETCH_ERROR:
      return { ...state, estimate: ERROR };
    case SUBMIT_ESTIMATE_REQUEST:
      return { ...state, estimateSubmit: LOADING };
    case SUBMIT_ESTIMATE_SUCCESS:
      return { ...state, estimateSubmit: SUCCESS };
    case SUBMIT_ESTIMATE_ERROR:
      return { ...state, estimateSubmit: ERROR };
    case SUBMIT_ESTIMATE_RESET:
      return { ...state, estimateSubmit: null };
    case RESUBMIT_ESTIMATE_REQUEST:
      return { ...state, estimateResubmit: LOADING };
    case RESUBMIT_ESTIMATE_SUCCESS:
      return { ...state, estimateResubmit: SUCCESS };
    case RESUBMIT_ESTIMATE_ERROR:
      return { ...state, estimateResubmit: ERROR };
    case RESUBMIT_ESTIMATE_RESET:
      return { ...state, estimateResubmit: null };
    case APPROVAL_UPDATE_REQUEST:
      return { ...state, estimateApproval: LOADING };
    case APPROVE_ESTIMATE_SUCCESS:
      return { ...state, estimateApproval: SUCCESS };
    case APPROVE_ESTIMATE_RESET:
      return { ...state, estimateApproval: null };

    case ESTIMATE_INOVICE_FETCH_REQUEST:
      return { ...state, estimateInvoices: LOADING };
    case ESTIMATE_INOVICE_FETCH_SUCCESS:
      return { ...state, estimateInvoices: SUCCESS };
    case ESTIMATE_INOVICE_FETCH_ERROR:
      return { ...state, estimateInvoices: ERROR };

    // campaigns
    case CAMPAIGN_CREATE_REQUEST:
      return { ...state, campaign: LOADING };
    case CAMPAIGN_CREATE_SUCCESS:
      return { ...state, campaign: SUCCESS };
    case CAMPAIGN_CREATE_ERROR:
      return { ...state, campaign: ERROR };
    case CAMPAIGNS_FETCH_REQUEST:
      const campaignsPage = { ...state.campaignsPage };
      if (get('query.page', action) === 1) {
        campaignsPage.lastIndex = 0;
        campaignsPage.campaigns = [];
      }
      return { ...state, campaigns: LOADING, campaignsPage };
    case CAMPAIGNS_FETCH_SUCCESS: {
      const campaignsPage = { ...state.campaignsPage };
      if (action.pagination.page === 1) {
        campaignsPage.lastIndex = 0;
        campaignsPage.campaigns = [];
      } else if (action.pagination.page) {
        campaignsPage.lastIndex =
          findIndex({ estimatesLoaded: true }, campaignsPage.campaigns) || 0;
        if (campaignsPage.lastIndex < 0) {
          campaignsPage.lastIndex = 0;
        } else if (campaignsPage.lastIndex > 0) {
          campaignsPage.lastIndex--;
        }
      }
      if (action.pagination.page) {
        campaignsPage.campaigns = campaignsPage.campaigns.concat(
          map(
            ({ id }) => ({ id, page: action.pagination, estimatesLoaded: false }),
            action.campaigns
          )
        );
      }
      return {
        ...state,
        campaigns: SUCCESS,
        campaignsPage,
      };
    }
    case CAMPAIGNS_RESET:
      return {
        ...state,
        campaigns: SUCCESS,
        campaignsPage: {
          campaigns: [],
          lastIndex: 0,
        },
      };
    // lineItem
    case CREATE_LINE_ITEM_REQUEST:
      return { ...state, lineItemName: LOADING };
    case CREATE_LINE_ITEM_SUCCESS:
      return { ...state, lineItemName: SUCCESS };

    // reports
    case REPORT_DASHBOARD_REQUEST:
      return { ...state, dashboardData: LOADING };
    case REPORT_DASHBOARD_SUCCESS:
      return { ...state, dashboardData: SUCCESS };

    //auth
    case VERIFY_SIGNUP_CODE_REQUEST:
      return { ...state, authVerifySignupCode: LOADING };
    case VERIFY_SIGNUP_CODE_SUCCESS:
      return { ...state, authVerifySignupCode: SUCCESS };
    case VERIFY_SIGNUP_CODE_ERROR:
      return { ...state, authVerifySignupCode: ERROR };

    case COMPLETE_SIGNUP_REQUEST:
      return { ...state, completeSignup: LOADING };
    case COMPLETE_SIGNUP_SUCCESS:
      return { ...state, completeSignup: SUCCESS };
    case COMPLETE_SIGNUP_ERROR:
      return { ...state, completeSignup: SUCCESS };

    case PASSWORD_RESET_REQUEST:
      return { ...state, resetPassword: LOADING };
    case PASSWORD_RESET_SUCCESS:
      return { ...state, resetPassword: SUCCESS };
    case PASSWORD_RESET_ERROR:
      return { ...state, resetPassword: ERROR };

    case FETCH_USERS_REQUEST:
      return { ...state, users: LOADING };
    case FETCH_USERS_SUCCESS:
      return { ...state, users: SUCCESS };
    case FETCH_USERS_ERROR:
      return { ...state, users: ERROR };

    case WORKSPACES_FETCH_REQUEST:
      return { ...state, workspaces: LOADING };
    case WORKSPACES_FETCH_SUCCESS:
      return { ...state, workspaces: SUCCESS };
    case WORKSPACES_FETCH_ERROR:
      return { ...state, workspaces: ERROR };

    case TEAMS_FETCH_REQUEST:
      return { ...state, teams: LOADING };
    case TEAMS_FETCH_SUCCESS:
      return { ...state, teams: SUCCESS };
    case TEAMS_FETCH_ERROR:
      return { ...state, teams: ERROR };

    case PRODUCTS_FETCH_REQUEST:
      return { ...state, products: LOADING };
    case PRODUCTS_FETCH_SUCCESS:
      return { ...state, products: SUCCESS };

    // pageId
    case LOCATION_CHANGE:
      const location = action.payload.location.pathname;
      const prevLocation = state.page.location;
      let pageId = state.page.pageId;

      if (
        !prevLocation ||
        (location !== prevLocation &&
          // doesn't change pageId if is in one of the estimate details page
          (!RegExp(
            '/estimates/[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89AB][0-9a-f]{3}-[0-9a-f]{12}/'
          ).test(location) ||
            !prevLocation.includes(`/estimates/${location.split('/')[2]}`)))
      ) {
        pageId = uuid();
      }

      if (location === '/estimates/new') {
        pageId = undefined;
      }

      return {
        ...state,
        page: {
          location,
          pageId,
        },
      };

    // cost categories report
    case REPORT_COST_CATEGORIES_FETCH_REQUEST:
      return { ...state, costCategoriesReport: LOADING };
    case REPORT_COST_CATEGORIES_FETCH_SUCCESS:
      return { ...state, costCategoriesReport: SUCCESS };

    default:
      return state;
  }
};

export default reducers;
