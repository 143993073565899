import { useMutation, useQueryClient } from 'react-query';
import SupplierService from 'services/SupplierService';

const useCreateSupplierColumn = () => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(SupplierService.createSupplierColumn, {
    onSuccess: (_data, { accountId }) => {
      queryClient.invalidateQueries(['account', accountId, 'suppliers', 'columns'], {
        exact: true,
      });
    },
  });

  return { createSupplierColumn: mutate, ...rest };
};

export default useCreateSupplierColumn;
