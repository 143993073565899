import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { map, filter } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  ERROR_NOTIFICATION,
  SUCCESS_NOTIFICATION,
  closeNotification,
} from 'state/notifications/actions';
import { getNotifications } from 'state/notifications/selectors';

const SUPPORTED_TYPES = [ERROR_NOTIFICATION, SUCCESS_NOTIFICATION];

const useStyles = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
    lineHeight: '1rem',
  },
}));

export const Notification = ({ open, handleClose, message, className, icon }) => {
  const classes = useStyles();
  if (message === 'An internal server error occurred') {
    message = 'Ops, something went wrong. Please retry it later.';
  }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      onClose={handleClose}
    >
      <SnackbarContent
        open={open}
        className={clsx(classes.margin, className)}
        aria-describedby="notification-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <span className={classes.iconContainer}>{icon}</span>
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export const Notifications = ({ notifications, handleClose }) => {
  const classes = useStyles();
  return (
    <div>
      {map(({ id, type, message }) => {
        if (SUPPORTED_TYPES.includes(type)) {
          let className;
          let icon;
          switch (type) {
            case ERROR_NOTIFICATION:
              className = classes.error;
              icon = <ErrorIcon />;
              break;
            case SUCCESS_NOTIFICATION:
              className = classes.success;
              icon = <CheckCircleIcon />;
              break;
            default:
          }
          return (
            <Notification
              className={className}
              message={message}
              handleClose={handleClose(id)}
              icon={icon}
              open={true}
              key={id}
            />
          );
        }
      }, filter({ open: true }, notifications))}
    </div>
  );
};

const mapStateToProps = state => ({
  notifications: getNotifications(state),
});

const mapDispatchToProps = dispatch => ({
  handleClose: notificationId => (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(closeNotification(notificationId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
