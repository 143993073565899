import React from 'react';

const SvgGoogleIcon = props => (
  <svg width="26px" height="27px" viewBox="0 0 26 27" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M25.953 13.522c0-.938-.084-1.84-.24-2.707H13.25v5.119h7.12a6.087 6.087 0 0 1-2.64 3.993v3.32h4.277c2.502-2.303 3.945-5.696 3.945-9.725z"
        fill="#4285F4"
      />
      <path
        d="M13.257 26.475c3.572 0 6.567-1.185 8.757-3.206l-4.277-3.32c-1.184.794-2.7 1.264-4.48 1.264-3.447 0-6.363-2.328-7.404-5.455h-4.42v3.428c2.177 4.324 6.651 7.29 11.824 7.29z"
        fill="#34A853"
      />
      <path
        d="M5.828 15.762a7.954 7.954 0 0 1-.415-2.514c0-.872.15-1.72.415-2.514V7.306h-4.42A13.226 13.226 0 0 0 0 13.248c0 2.135.511 4.156 1.407 5.942l4.42-3.428z"
        fill="#FBBC05"
      />
      <path
        d="M13.257 5.263c1.942 0 3.686.667 5.058 1.978l3.795-3.795C19.818 1.311 16.823 0 13.257 0 8.084 0 3.61 2.965 1.433 7.29l4.42 3.428c1.04-3.128 3.957-5.455 7.404-5.455z"
        fill="#EA4335"
      />
    </g>
  </svg>
);

export default SvgGoogleIcon;
