import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { EstimateStatuses, EstimateApprovalStatuses } from 'cr-core/constants';
import EstimateStatus from 'components/estimateStatus';
import { ListItem, Body, Actions } from 'components/listItemWithActions';
import UserAvatar from 'components/userAvatar';
import { colors, font } from 'shared/styles';
import {
  getEstimateApprovalsFor,
  getUnsubmittedEstimateApprovalsFor,
  getNonApprovedClientApproversForEstimate,
} from 'state/estimateApprovals/selectors';
import { getEstimateStatusFor } from 'state/estimates/selectors';
import RemoveApproverButton from './removeApprover';
import SvgMailIcon from '../../icons/MailIcon';
import { Box, Link, makeStyles, Typography } from '@material-ui/core';
import { retryCreateApproval } from 'state/estimateApprovals/actions';
import { compact } from 'lodash/fp';
import { getClientSettings } from 'state/authentication/selectors';
import { AccountSetting, ApprovalType } from 'cr-core/constants';

const EstimateApprovalListContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-shrink: 0;
`;

const ItemHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ApproverAvatar = styled(UserAvatar)`
  display: flex;
  .userName {
    color: ${colors.text};
    font-weight: ${font.weights.bold};
    text-shadow: none;
  }
`;

const ApprovalStatus = styled(EstimateStatus)`
  display: flex;
  margin-left: auto;
`;

const EmptyState = ({ className, children }) => (
  <EstimateApprovalListContainer className={className}>
    <ListItem data-test="approvals-empty-message">{children}</ListItem>
  </EstimateApprovalListContainer>
);

const Bottom = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 15px;
`;

const Sent = styled.div`
  display: flex;
  align-items: center;
  font-size: ${font.sizes.small};

  .icon {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
`;

const Dates = styled.div`
  text-align: right;

  .date {
    font-weight: 600;
    font-size: ${font.sizes.largish};
  }

  .fromNow {
    font-size: ${font.sizes.normal};
  }
`;

const ApprovalBody = styled(Body)`
  width: auto;
`;

const useStyles = makeStyles({
  tryAgain: {
    display: 'inline',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
});

const ApprovalItemRenderer = ({ approval, estimateStatus, pageId, retry }) => {
  const styles = useStyles();

  const {
    id,
    approver,
    approverId,
    status,
    updatedAt,
    estimateId,
    requestApprovalEmailSent,
    nonce,
  } = approval;

  const approved = status === EstimateApprovalStatuses.APPROVED;
  const isEmphemeral = Boolean(nonce);
  const isError = status === EstimateApprovalStatuses.ERROR;

  const handleRetry = e => {
    e.preventDefault();
    retry(id);
  };

  return (
    <ListItem key={id} data-test="approver-block">
      <ApprovalBody>
        <ItemHeader>
          <ApproverAvatar user={approver} />
          {!isError && (
            <ApprovalStatus className="status" status={status} approvals={{ [status]: 1 }} />
          )}
          {isError && (
            <Box ml="auto" alignSelf="center">
              <Typography>
                Failed to add approver.{' '}
                <Link href="#" className={styles.tryAgain} color="error" onClick={handleRetry}>
                  Try again
                </Link>
              </Typography>
            </Box>
          )}
        </ItemHeader>

        <Bottom>
          {requestApprovalEmailSent && (
            <Sent>
              <SvgMailIcon className="icon" />
              Sent
            </Sent>
          )}
          {approved && (
            <Dates>
              <div className="date">{moment(updatedAt).format('LL')}</div>
              <div className="fromNow">{moment(updatedAt).fromNow()}</div>
            </Dates>
          )}
        </Bottom>
      </ApprovalBody>
      <Actions>
        {status !== EstimateApprovalStatuses.LOADING &&
          (isEmphemeral || estimateStatus !== EstimateStatuses.APPROVED) && (
            <RemoveApproverButton
              estimateId={estimateId}
              approverId={approverId}
              pageId={pageId}
              isEmphemeral={isEmphemeral}
            />
          )}
      </Actions>
    </ListItem>
  );
};

const ApprovalsList = ({
  estimateApprovals,
  estimateStatus,
  className,
  ItemRenderer = ApprovalItemRenderer,
  pageId,
  clientSettings,
  retryAddApprover,
  approvalType,
}) => {
  const agencyApproversEnabled = clientSettings[AccountSetting.AgencyApprovers];

  if (!estimateApprovals?.length) {
    return (
      <EmptyState>
        {compact([
          "This estimate doesn't have any",
          agencyApproversEnabled && (approvalType === ApprovalType.CLIENT ? 'client' : 'agency'),
          'approvers assigned.',
        ]).join(' ')}
      </EmptyState>
    );
  }

  return (
    <EstimateApprovalListContainer className={className}>
      {estimateApprovals.map(approval => (
        <ItemRenderer
          key={approval.id}
          approval={approval}
          estimateStatus={estimateStatus}
          pageId={pageId}
          retry={retryAddApprover}
        />
      ))}
    </EstimateApprovalListContainer>
  );
};

const mapStateToProps = (
  state,
  { estimateId, approvalType, onlyUnsent = false, onlyNonApprovedClientApprovers = false }
) => {
  const approvalsSelector = onlyNonApprovedClientApprovers
    ? getNonApprovedClientApproversForEstimate
    : onlyUnsent
    ? getUnsubmittedEstimateApprovalsFor
    : getEstimateApprovalsFor;
  return {
    estimateApprovals: approvalsSelector(estimateId, approvalType)(state),
    estimateStatus: getEstimateStatusFor(estimateId)(state),
    clientSettings: getClientSettings(state),
  };
};

const mapDispatchToProps = (dispatch, { estimateId }) => ({
  retryAddApprover: approvalId => {
    dispatch(retryCreateApproval(estimateId, approvalId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalsList);
