import React from 'react';
import clsx from 'clsx';
import { map } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import LoadingBlock from 'components/loadingBlock';
import styled from 'styled-components';

const LoadingMetrics = styled(LoadingBlock)`
  height: 100px;
  width: 220px;
  margin: 5px 0;
`;
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    margin: theme.spacing(2, 0, 0),
    maxWidth: '100%',
  },
  box: {
    minWidth: 320,
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(2),
    '@media (max-width: 1439px)': {
      minWidth: 300,
    },
  },
  boxWrap: {
    minWidth: 320,
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
    width: '22%',
    '@media (max-width: 1439px)': {
      minWidth: 300,
    },
    '@media (max-width: 1279px)': {
      width: '35%',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  overbudget: {
    color: theme.palette.error.main,
  },
  savings: {
    color: theme.palette.success.main,
  },
}));

const Box = ({ title, value, subtitle = '', classes = {}, isLoading }) => {
  const boxClasses = useStyles();

  return (
    <div className={boxClasses.boxWrap}>
      {!isLoading && (
        <Paper className={boxClasses.box}>
          <Typography variant="h6" className={boxClasses.title}>
            {title}
          </Typography>
          <Typography variant="h4" className={clsx(boxClasses.value, classes.value)}>
            {value}
          </Typography>
          <div className={clsx(boxClasses.subtitle, classes.subtitle)}> {subtitle} </div>
        </Paper>
      )}
      {isLoading && (
        <Paper className={boxClasses.box}>
          <LoadingMetrics />
        </Paper>
      )}
    </div>
  );
};

const Metrics = ({ metrics = [], isLoading }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {map(
        ({ ...props }) => (
          <Box {...props} key={props.title} isLoading={isLoading} />
        ),
        metrics
      )}
    </div>
  );
};

export default Metrics;
