import {
  ESTIMATE_INOVICE_FETCH_REQUEST,
  ESTIMATE_INOVICE_FETCH_SUCCESS,
  ESTIMATE_INOVICE_FETCH_ERROR,
  ESTIMATE_INVOICE_CREATE_REQUEST,
  ESTIMATE_INVOICE_CREATE_SUCCESS,
  ESTIMATE_INVOICE_CREATE_ERROR,
} from 'state/estimateInvoices/actions';

const defaultState = { list: [] };

export default (state = defaultState, action) => {
  switch (action.type) {
    case ESTIMATE_INOVICE_FETCH_REQUEST:
      return { list: [] };
    case ESTIMATE_INOVICE_FETCH_SUCCESS:
      return { list: action.estimateInvoices };
    case ESTIMATE_INOVICE_FETCH_ERROR:
      return { list: [], error: action.error };

    case ESTIMATE_INVOICE_CREATE_REQUEST:
      return { ...state, createError: null };
    case ESTIMATE_INVOICE_CREATE_SUCCESS:
      return { list: [...state.list, action.estimateInvoice] };
    case ESTIMATE_INVOICE_CREATE_ERROR:
      return { ...state, createError: action.error };

    default:
      return state;
  }
};
