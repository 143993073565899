import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { compose, withHandlers } from 'recompose';
import styled from 'styled-components';
import { find, flow, get, getOr, filter } from 'lodash/fp';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { EstimateStatuses } from 'cr-core/constants';
import { Events, track, getEstimateData } from 'components/analytics';
import { ConfirmApprovalButton, CancelButton } from 'components/buttons';
import InfoBox from 'components/infoBox';
import Modal from 'components/modal';
import { getEstimateById, getPoCodeError } from 'state/estimates/selectors';
import SvgBackIcon from 'icons/BackIcon';
import SvgApproveIcon from 'icons/ApproveIcon';
import { font } from 'shared/styles';
import { getUser } from 'state/authentication/selectors';
import { LOADING, SUCCESS } from 'state/resourceStatus/reducer';
import { resetApproveEstimateStatus } from 'state/estimateApprovals/actions';
import SuccessModal from './success';
import { withRouter } from 'react-router';
import PoCodeErrorModal from './PoCodeErrorModal';

export const AdditionalMessage = styled.div`
  margin: 20px 0;

  .messageTitle {
    font-size: ${font.sizes.normal};
    margin-bottom: 10px;
  }

  .messageInput {
    width: 100%;
    height: 120px;
  }
`;

const selectFromForm = formValueSelector('approve');

const EstimateApprovalLoadingModal = ({ closeSuccessModal, ...props }) => (
  <SuccessModal
    header={'Your estimate Approval is being approved'}
    onRequestClose={closeSuccessModal}
    {...props}
  />
);

const EstimateApprovalSuccessModal = ({ closeSuccessModal, ...props }) => {
  const header = (
    <>
      Great!
      <br />
      You have approved the estimate
    </>
  );
  const description = 'A notification has been sent to all approvers and watchers';
  return (
    <SuccessModal
      completed={true}
      header={header}
      description={description}
      {...props}
      onRequestClose={closeSuccessModal}
    />
  );
};

const ApprovalModal = ({
  onConfirmApproval,
  onRequestClose,
  handleSubmit,
  cancel,
  estimateId,
  estimateApprovalStatus,
  poCodeError,
  ...restProps
}) => {
  if (poCodeError.hasError) {
    return (
      <PoCodeErrorModal
        cancel={cancel}
        estimateId={estimateId}
        noEstimatePoCode={poCodeError.noEstimatePoCode}
        noCampaignPoCode={poCodeError.noCampaignPoCode}
        {...restProps} 
      />
    );
  }

  if (estimateApprovalStatus === LOADING) {
    return EstimateApprovalLoadingModal(restProps);
  }
  if (estimateApprovalStatus === SUCCESS) {
    return EstimateApprovalSuccessModal(restProps);
  }

  const buttons = (
    <>
      <CancelButton onClick={cancel}>
        <SvgBackIcon className="icon" />
        Cancel
      </CancelButton>
      <ConfirmApprovalButton onClick={handleSubmit}>
        <SvgApproveIcon className="icon" />
        Confirm Approval
      </ConfirmApprovalButton>
    </>
  );

  return (
    <Modal title="Confirm Approval" buttons={buttons} {...restProps} onRequestClose={cancel}>
      <div>
        <div className="title">Are you sure you want to approve this estimate?</div>
        <form onSubmit={handleSubmit}>
          <AdditionalMessage>
            <div className="messageTitle">Additional Message (Optional)</div>
            <Field className="messageInput" name="message" component="textarea" />
          </AdditionalMessage>
        </form>
        <InfoBox>
          An email will be sent to approvers and watchers with estimate information and your
          comment.
        </InfoBox>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state, { estimateId }) => ({
  message: selectFromForm(state, 'message'),
  estimate: getEstimateById(estimateId)(state),
  estimateApprovalStatus: state.resourceStatus.estimateApproval,
  user: getUser(state),
  poCodeError: getPoCodeError(estimateId)(state),
});

const mapDispatchToProps = dispatch => ({
  resetApproveEstimateStatus: () => dispatch(resetApproveEstimateStatus()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    close: ({ onRequestClose }) => () => onRequestClose(),
  }),
  withRouter,
  withHandlers({
    onSubmit: ({ estimate, onRequestClose, onConfirmApproval, message, user }) => () => {
      onConfirmApproval(message);
      // event tracking
      const approvedCount = flow(
        getOr([], 'approvals'),
        filter({ status: EstimateStatuses.APPROVED }),
        approvedList => approvedList.length,
      )(estimate);
      const missingApprovals = getOr(0, 'approvals.length', estimate) - approvedCount;
      const data = {
        ...getEstimateData(estimate),
        message: Boolean(message),
        messageLength: message ? message.length : 0,
        estimateFullyApproved: missingApprovals === 1,
        timeToApprove: flow(
          getOr([], 'approvals'),
          find({ approverId: user.id }),
          get('updatedAt'),
          updatedAt => {
            if (!updatedAt) {
              return null;
            }
            updatedAt = moment(updatedAt);
            return moment().diff(updatedAt, 'days');
          },
        )(estimate),
      };
      if (data.estimateFullyApproved) {
        data.overallTimeToApprove = moment().diff(
          moment(get('lockedExchangeRatesAt', estimate)),
          'days',
        );
      }

      track(Events.ESTIMATE_APPROVE_SUCCESS, data);
    },
    cancel: ({ estimate, onRequestClose }) => () => {
      track(Events.ESTIMATE_APPROVE_CANCEL, getEstimateData(estimate));
      onRequestClose();
    },
    closeSuccessModal: ({ onRequestClose, resetApproveEstimateStatus }) => () => {
      resetApproveEstimateStatus();
      onRequestClose();
    },
    addPoCode: ({ estimateId, history, onRequestClose }) => () => {
      history.push(`/estimates/${estimateId}/edit`);
      onRequestClose();
    },
  }),
  reduxForm({ form: 'approve' }),
)(ApprovalModal);
