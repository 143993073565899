import React from 'react';

const SvgSendIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="send-icon_svg__a"
        width="128.6%"
        height="133.3%"
        x="-14.3%"
        y="-16.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0 13.289L28 0l-5.358 20.027-10.223-3.141 8.784-10.388-11.401 9.604L0 13.289zm12.074 5.132l3.454.93L12.074 24v-5.579z"
      filter="url(#send-icon_svg__a)"
      transform="translate(1 3)"
    />
  </svg>
);

export default SvgSendIcon;
