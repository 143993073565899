import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { TextField, Checkbox } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControlLabel, Paper, Typography } from '@material-ui/core';
import { createAccount } from 'state/accounts/actions';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },

  formControl: {
    marginRight: theme.spacing(2),
    minWidth: 200,
    display: 'block',
  },
  label: {
    background: 'white',
    padding: '0 4px',
  },
  button: {
    display: 'block',
  },
}));

const AccountSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  agency: Yup.boolean(),
});

const CreateAccount = ({ accountId, createAccount }) => {
  const classes = useStyles();
  const initialValues = { name: '', agency: true };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const success = await createAccount(values);
    setSubmitting(false);
    if (success) {
      resetForm(initialValues);
    }
  };

  return (
    <Paper className={classes.container}>
      <Typography variant="h6" className={classes.title}>
        Create a new account
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={AccountSchema}
        validateOnChange
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Field
              component={TextField}
              name="name"
              variant="outlined"
              label="Name"
              className={classes.formControl}
              inputProps={{
                id: 'workspace-id',
              }}
            />
            <FormControlLabel
              control={<Field component={Checkbox} name="agency" />}
              label="Agency"
            />
            <Button
              data-test="saveNewProduct"
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              className={classes.button}
            >
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

const mapDispatchToProps = (dispatch, { accountId }) => ({
  createAccount: data => dispatch(createAccount(data)),
});

export default connect(null, mapDispatchToProps)(CreateAccount);
