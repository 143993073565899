import styled from 'styled-components';
import { colors, font } from '../../shared/styles';

export const FiltersPanel = styled.div`
  display: flex;
`;

export const PageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  font-family: 'Montserrat', sans-serif;
`;

export const MainContent = styled.div`
  flex: 1;
  overflow: hidden;
`;
// overflow: hidden;

export const StandardBox = styled.div`
  padding: 20px;
  margin: 20px;
  background-color: white;
  color: ${colors.text};
  border-radius: 7px;
  border: 1px solid ${colors.containerBorder};
`;

export const BoxHeader = styled.div`
  font-size: ${font.sizes.largish};
  font-weight: ${font.weights.bold};
  margin: 0 0 20px 0;
`;

export const BoxBody = styled.div`
  font-size: ${font.sizes.largish};
`;
