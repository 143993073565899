import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles(theme => {
  return {
    showFiltersButton: {
      borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
      marginTop: theme.spacing(2),
      padding: `6px ${theme.spacing(1)}px`,
      minWidth: 'auto',
      ...theme.typography.caption,
    },
    showFiltersButtonIcon: {
      fontSize: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    showFiltersButtonLabel: {
      display: 'flex',
      flexDirection: 'column',
    },
    showFiltersButtonText: {
      writingMode: 'vertical-rl',
      textOrientation: 'mixed',
      marginBottom: theme.spacing(1),
    },
  };
});

const Collapsed = ({ collapsed, setCollapsed }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.showFiltersButton}
      variant="contained"
      onClick={() => setCollapsed(false)}
      style={collapsed ? {} : { display: 'none' }}
      classes={{ label: classes.showFiltersButtonLabel }}
    >
      <FilterListIcon className={classes.showFiltersButtonIcon} />
      <div className={classes.showFiltersButtonText}>Show Filters</div>
    </Button>
  );
};

export default Collapsed;
