import { flow, filter, get, map, uniq, keyBy, orderBy, uniqBy } from 'lodash/fp';

export const getProducts = flow(
  get('products'),
  map(product => {
    const { productBrand, productCategory, ...rest } = product;
    return {
      ...rest,
      brandName: productBrand?.name,
      productBrandId: productBrand?.id,
      brandCategory: productCategory?.name,
      productCategoryId: productCategory?.id,
      productCategory,
      productBrand,
    };
  }),
  orderBy(['brandName', 'productName'], ['asc', 'asc'])
);
export const getProductsById = flow(getProducts, keyBy('id'));

export const getProductCategories = flow(getProducts, map('brandCategory'), uniq);

export const getBrandNames = flow(getProducts, map('brandName'), uniq);

export const getAccountProducts = accountId => flow(getProducts, filter({ accountId }));

export const getBrandNamesFilteredByAccount = accountId =>
  flow(
    getAccountProducts(accountId),
    map(product => ({
      productBrandId: product.productBrand.id,
      productBrandName: product.productBrand.name,
    })),
    uniqBy('productBrandId')
  );

export const getProductCategoriesFilteredByAccount = accountId =>
  flow(
    getAccountProducts(accountId),
    map(product => ({
      productCategoryId: product.productCategory.id,
      productCategoryName: product.productCategory.name,
    })),
    uniqBy('productCategoryId')
  );
