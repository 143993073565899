import React from 'react';
import { useField } from 'formik';
import { find, get, pick } from 'lodash/fp';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

const textFieldProps = ['label', 'variant'];

const ComboBox = ({ ...props }) => {
  const name = props.field.name;
  const form = props.form;
  const helpers = useField(name)[2];
  const errorProps = {};
  if (form.errors[name] && (form.touched[name] || form.submitCount)) {
    errorProps.error = true;
    errorProps.helperText = form.errors[name];
  }

  return (
    <Autocomplete
      getOptionLabel={get('label')}
      openOnFocus={true}
      {...props}
      onChange={(event, value) => helpers.setValue(get('value', value))}
      onBlur={() => helpers.setTouched(true)}
      defaultValue={find({ value: form.initialValues[name] }, props.options)}
      renderInput={params => (
        <TextField {...params} {...errorProps} {...pick(textFieldProps, props)} />
      )}
    />
  );
};

export default ComboBox;
