import React from 'react';

const SvgBackIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="back-icon_svg__a"
        width="138.1%"
        height="144.4%"
        x="-19%"
        y="-22.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      d="M20.47 7.412a.53.53 0 0 1 .53.53v2.117a.53.53 0 0 1-.53.53H5.862l5.007 5.015a.53.53 0 0 1 0 .748l-1.494 1.493a.53.53 0 0 1-.748 0l-8.47-8.47a.53.53 0 0 1 0-.75l8.47-8.47a.53.53 0 0 1 .747-.001l1.504 1.493a.53.53 0 0 1 0 .75L5.864 7.412H20.47z"
      filter="url(#back-icon_svg__a)"
      transform="translate(5 6)"
    />
  </svg>
);

export default SvgBackIcon;
