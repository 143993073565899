import { fetch } from 'state/fetchMiddleware';
import { fetchAccounts } from 'state/accounts/actions';

export const ACCOUNT_DOMAIN_CREATE_REQUEST = 'accountDomain/ACCOUNT_DOMAIN_CREATE_REQUEST';
export const ACCOUNT_DOMAIN_CREATE_SUCCESS = 'accountDomain/ACCOUNT_DOMAIN_CREATE_SUCCESS';
export const ACCOUNT_DOMAIN_CREATE_ERROR = 'accountDomain/ACCOUNT_DOMAIN_CREATE_ERROR';

export const ACCOUNT_DOMAIN_DELETE_REQUEST = 'accountDomain/ACCOUNT_DOMAIN_DELETE_REQUEST';
export const ACCOUNT_DOMAIN_DELETE_SUCCESS = 'accountDomain/ACCOUNT_DOMAIN_DELETE_SUCCESS';
export const ACCOUNT_DOMAIN_DELETE_ERROR = 'accountDomain/ACCOUNT_DOMAIN_DELETE_ERROR';

export const createAccontDomain = ({ domain, accountId }) => async dispatch => {
  dispatch({ type: ACCOUNT_DOMAIN_CREATE_REQUEST });
  try {
    const { data: accountDomain } = await dispatch(
      fetch(`/v2/accounts/${accountId}/domains`, { method: 'POST', data: { domain } })
    );
    dispatch({ type: ACCOUNT_DOMAIN_CREATE_SUCCESS, accountDomain });
    dispatch(fetchAccounts());
    return accountDomain;
  } catch (error) {
    dispatch({ type: ACCOUNT_DOMAIN_CREATE_ERROR, error });
  }
};

export const removeAccontDomain = ({ domainId, accountId }) => async dispatch => {
  dispatch({ type: ACCOUNT_DOMAIN_DELETE_REQUEST });
  try {
    await dispatch(fetch(`/v2/accounts/${accountId}/domains/${domainId}`, { method: 'DELETE' }));
    dispatch({ type: ACCOUNT_DOMAIN_DELETE_SUCCESS, domainId, accountId });
    dispatch(fetchAccounts());
  } catch (error) {
    dispatch({ type: ACCOUNT_DOMAIN_DELETE_ERROR, error });
  }
};
