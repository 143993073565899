import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withState, withHandlers } from 'recompose';
import { deleteEstimate } from 'state/estimates/actions';
import { Events, track, getEstimateData } from 'components/analytics';
import { AlertButton, CancelButton } from 'components/buttons';
import SuccessModal from 'components/modals/success';
import SvgCancelIcon from 'icons/CancelIcon';
import { compose } from 'lodash/fp';
import Modal from 'components/modal';
import SvgBackIcon from 'icons/BackIcon';

const EstimateCancelledModal = ({ close, ...props }) => {
  const header = (
    <>
      Ok, that’s done!
      <br />
      Your estimate has now been marked as cancelled
    </>
  );
  const description = 'A notification has been sent to all approvers and watchers';
  return (
    <SuccessModal
      completed={true}
      header={header}
      description={description}
      {...props}
      onRequestClose={close}
    />
  );
};

const DeleteButton = ({
  handleDeleteEstimate,
  isModalOpen,
  isCancelled,
  onClick,
  cancel,
  toggleModal,
  closeSuccessModal,
}) => {
  const buttons = (
    <>
      <CancelButton onClick={cancel}>
        <SvgBackIcon className="icon" />
        No
      </CancelButton>
      <AlertButton onClick={handleDeleteEstimate}>
        <SvgCancelIcon className="icon" />
        Yes
      </AlertButton>
    </>
  );

  return (
    <>
      <AlertButton className="delete" onClick={onClick} data-test="cancelEstimate">
        <SvgCancelIcon className="icon" />
        Cancel Estimate
      </AlertButton>
      <Modal
        isOpen={isModalOpen && !isCancelled}
        title="Cancel Estimate"
        onRequestClose={cancel}
        buttons={buttons}
      >
        Are you sure you want to mark this estimate as Cancelled?
      </Modal>
      <EstimateCancelledModal
        close={() => closeSuccessModal()}
        isOpen={isModalOpen && isCancelled}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch, { estimate, setCancelled }) => ({
  handleDeleteEstimate: async () => {
    dispatch(deleteEstimate(estimate.id));
    track(Events.ESTIMATE_CANCEL_SUCCESS, getEstimateData(estimate));
    setCancelled(true);
  },
  closeSuccessModal: () => {
    dispatch(push('/campaigns'));
  },
});

export default compose(
  withState('isModalOpen', 'toggleModal', false),
  withState('isCancelled', 'setCancelled', false),
  withHandlers({
    onClick: ({ toggleModal, estimate, setCancelled }) => () => {
      toggleModal(true);
      track(Events.ESTIMATE_CANCEL_CLICK, getEstimateData(estimate));
    },
    cancel: ({ toggleModal, estimate }) => () => {
      toggleModal(false);
      track(Events.ESTIMATE_CANCEL_CANCEL, getEstimateData(estimate));
    },
  }),
  connect(null, mapDispatchToProps)
)(DeleteButton);
