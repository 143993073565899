import { SET_FLAGS } from './actions';
import { merge } from 'lodash/fp';

const defaultState = {
  createEstimate: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_FLAGS:
      return merge(state, action.flags);
    default:
      return state;
  }
};
