import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core';
import {
  BudgetYearFilter,
  CampaignIDFilter,
  CampaignStatusFilter,
  CategoryBrandFilter,
  DivisionFilter,
  EstimateIDFilter,
  EstimateStatusFilter,
  LeadMarketFilter,
  MediaTypesFilter,
  PayingCountryFilter,
  WorkspaceFilter,
  ReportingDateControl,
  ProductionStartDateControl,
  DeliveryDateControl,
  ScaleOrNonScaleFilter,
} from 'components/filtersSidebar/filters';
import { Form, useFormikContext } from 'formik';
import { compact, isEmpty } from 'lodash/fp';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getDisplayCurrency, getUser } from 'state/authentication/selectors';
import { download, hasEmptyValues, stripEmpty } from 'utils';
import ReportFilter from '../ReportFilter';
// @ts-ignore
import { AccountSetting } from 'cr-core/constants';
import useDivisionsWorkspaces from 'hooks/useDivisionsWorkspaces';
import { getUserWorkspaces } from 'state/workspaces/selectors';
import { getProductBrand } from 'components/filtersSidebar/utils';
import queryString from 'query-string';
import config from 'config';
import { isResourceLoading } from 'state/resourceStatus/selectors';
import InfoBox from 'components/infoBox';
import { format } from 'date-fns';

interface Props {
  user: any;
  workspaces: any[];
  brands: any[];
  displayCurrency: any;
  isWorkspacesLoading: boolean;
  isBrandsLoading: boolean;
}

export const EMPTY_FILTERS = {
  budgetYear: [],
  workspaceId: '',
  division: '',
  campaignHumanId: [],
  estimateHumanId: [],
  campaignStatus: '',
  productBrand: '',
  estimateStatus: '',
  mediaType: '',
  leadMarket: '',
  payingCountries: '',
  reportingDate: '',
  productionStartDate: '',
  deliveryDate: '',
  scaleOrNonScale: [],
};

const useStyles = makeStyles({
  exportButton: {
    marginLeft: 10,
  },

  info: {
    marginBottom: 20,
  },
});

const filterProps = { displayStackedOptions: true };

const { apiEndpoint } = config;

const DeliverablesFilters = ({
  user,
  workspaces = [],
  brands = [],
  displayCurrency,
  isWorkspacesLoading,
  isBrandsLoading,
}: Props) => {
  const styles = useStyles();
  const { values, setValues } = useFormikContext();
  const clientSettings = user?.client?.accountSettings;
  const budgetYearEnabled = clientSettings?.[AccountSetting.BudgetYear];
  const [divisions, setDivisions] = useState<string[]>([]);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState<any[]>([]);
  const [clearSelectedWorkspaces, setClearSelectedWorkspaces] = useState<boolean>(false);
  const { data: queryWorkspaces } = useDivisionsWorkspaces(user.client.id, divisions, workspaces);

  const updateSelectedWorkspaces = (value: any[]) => {
    setSelectedWorkspaces(value);
    clearSelectedWorkspaces && setClearSelectedWorkspaces(false);
  };

  const updateDivisions = (divisions: string[], clearWorkspaces: boolean) => {
    setDivisions(divisions);
    setClearSelectedWorkspaces(clearWorkspaces);
  };

  const exportData = () => {
    const { productionStartDate, deliveryDate, reportingDate, ...rest } = values as any;

    const reportingDateField =
      reportingDate.start && reportingDate.end
        ? {
            reportingDateStart: format(new Date(reportingDate.start), 'yyyy-MM-dd'),
            reportingDateEnd: format(new Date(reportingDate.end), 'yyyy-MM-dd'),
          }
        : undefined;

    const productionStartDateField =
      productionStartDate.start && productionStartDate.end
        ? {
            productionStartDateStart: format(new Date(productionStartDate.start), 'yyyy-MM-dd'),
            productionStartDateEnd: format(new Date(productionStartDate.end), 'yyyy-MM-dd'),
          }
        : undefined;

    const deliveryDateField =
      deliveryDate.start && deliveryDate.end
        ? {
            deliveryDateStart: format(new Date(deliveryDate.start), 'yyyy-MM-dd'),
            deliveryDateEnd: format(new Date(deliveryDate.end), 'yyyy-MM-dd'),
          }
        : undefined;

    const url = queryString.stringifyUrl({
      url: `${apiEndpoint}/export/deliverables`,
      query: stripEmpty({
        ...productionStartDateField,
        ...deliveryDateField,
        ...reportingDateField,
        ...rest,
        displayCurrency,
        clientId: user.client.id,
        brandCategories: JSON.stringify(brandCategories),
      }),
    });

    download(url);
  };

  const [brandCategories, setBrandCategories] = useState(null);
  const [clearFilters, setClearFilters] = useState(false);

  useEffect(() => {
    if (!isEmpty(brands) || clearFilters) {
      const groups = [...(new Set(brands.map(brand => brand.brandCategory)) as any)];
      let brandCategories: any = {};
      groups.forEach(item => {
        brandCategories[item] = [];
      });

      setBrandCategories(brandCategories);
    }
  }, [brands, clearFilters]);

  const filters = compact([
    budgetYearEnabled && { filter: BudgetYearFilter, props: filterProps },
    {
      filter: WorkspaceFilter,
      props: {
        ...filterProps,
        workspaces: queryWorkspaces,
        updateSelectedWorkspaces,
        clearSelectedOptions: clearSelectedWorkspaces,
      },
    },
    {
      filter: DivisionFilter,
      props: { ...filterProps, updateDivisions, selectedWorkspaces: !!selectedWorkspaces?.length },
    },
    { filter: CampaignIDFilter, props: filterProps },
    { filter: EstimateIDFilter, props: filterProps },
    { filter: CampaignStatusFilter, props: filterProps },
    {
      filter: CategoryBrandFilter,
      props: { ...filterProps, brands, brandCategories, setBrandCategories },
    },
    { filter: EstimateStatusFilter, props: filterProps },
    { filter: MediaTypesFilter, props: filterProps },
    { filter: LeadMarketFilter, props: filterProps },
    { filter: PayingCountryFilter, props: filterProps },
    { filter: ScaleOrNonScaleFilter, props: filterProps },
    { filter: ProductionStartDateControl, props: filterProps },
    { filter: DeliveryDateControl, props: filterProps },
    { filter: ReportingDateControl, props: filterProps },
  ]);

  if (isWorkspacesLoading || isBrandsLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Form>
        <InfoBox className={styles.info}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              This custom Additional Deliverables report will export the following data:
              <Box mt={1}>
                Breakdown of Additional Deliverables # per estimate, as well as Budget Year, Region,
                Workspace, Campaign Name and ID #, Campaign Budget, Estimate Name and ID #, Media
                Type, Segment, Brand, Originals and Edits, Estimated Spend and Export Currency.
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flex="0 0 auto"
              justifyContent="flex-end"
              width={250}
            >
              {!hasEmptyValues(values as object) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setValues(EMPTY_FILTERS as any, false);
                    setClearFilters(true);
                  }}
                >
                  Clear all
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={exportData}
                className={styles.exportButton}
              >
                Export
              </Button>
            </Box>
          </Box>
        </InfoBox>
        <Box display="flex" flexWrap="wrap" mx="-10px">
          {filters.map(({ filter: Filter, props }, index) => (
            <ReportFilter key={index}>
              {/* @ts-ignore */}
              <Filter {...props} />
            </ReportFilter>
          ))}
        </Box>
      </Form>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  brands: getProductBrand(state),
  workspaces: getUserWorkspaces(state),
  displayCurrency: getDisplayCurrency(state),
  user: getUser(state),
  isBrandsLoading: isResourceLoading('products')(state),
  isWorkspacesLoading: isResourceLoading('workspaces')(state),
});

export default connect(mapStateToProps)(DeliverablesFilters);
