import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { reducer as form } from 'redux-form';
import { pageLoad } from 'components/analytics';

import fetchMiddleware from './fetchMiddleware';
import analyticsMiddleware from './analyticsMiddleware';

import accounts from './accounts/reducer';
import auth from './authentication/reducer';
import bids from './bids/reducer';
import campaigns from './campaigns/reducer';
import costCategories from './costCategories/reducer';
import estimateHistory from './estimateHistory/reducer';
import estimateInvoices from './estimateInvoices/reducer';
import estimates from './estimates/reducer';
import flags from './flags/reducer';
import notifications from './notifications/reducer';
import products from './products/reducer';
import resourceStatus from './resourceStatus/reducer';
import ui from './ui/reducer';
import users from './users/reducer';
import estimateWatchers from './estimateWatchers/reducer';
import exchangeRates from './exchangeRates/reducer';
import reports from './reports/reducer';
import teams from './teams/reducer';
import workspaces from './workspaces/reducer';
import lineItems from './lineItems/reducer';

export const history = createBrowserHistory();

history.listen((location, action) => pageLoad());

export const reducers = {
  accounts,
  auth,
  bids,
  campaigns,
  costCategories,
  estimateHistory,
  estimateInvoices,
  estimates,
  estimateWatchers,
  exchangeRates,
  flags,
  form,
  lineItems,
  notifications,
  products,
  reports,
  teams,
  ui,
  users,
  workspaces,
  resourceStatus,
};

/* eslint-disable no-underscore-dangle, no-undef */
const store = createStore(
  combineReducers({
    ...reducers,
    router: connectRouter(history),
  }),
  compose(
    applyMiddleware(thunk, fetchMiddleware(), routerMiddleware(history), analyticsMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : compose
  )
);
/* eslint-enable */

export default store;
