import { get, flow, find } from 'lodash/fp';

export const getTeams = get('teams.list');
export const getTeamsError = get('teams.error');
export const getTeamsPagination = get('teams.pagination');

export const getTeamById = id =>
  flow(
    getTeams,
    find({ id })
  );
