import React from 'react';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flexGrow: 1,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  submitButton: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  info: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  resetPassword: {
    ...theme.typography.body2,
    textAlign: 'center',
  },
}));

export default ({ onSubmit }) => {
  const classes = useStyles();
  const initialValues = {
    email: '',
    password: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ values, isSubmitting, handleChange, handleBlur }) => (
        <Form className={classes.form}>
          <Field
            label="Email"
            variant="outlined"
            type="email"
            name="email"
            className={classes.textField}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            component={TextField}
          />
          <Button
            type="submit"
            variant="contained"
            className={classes.submitButton}
            size="large"
            color="primary"
            disabled={isSubmitting}
          >
            Reset Password
          </Button>
        </Form>
      )}
    </Formik>
  );
};
