import { connect } from 'react-redux';
import { withProps, compose } from 'recompose';
import { isBidIdRecommendedOnEstimate } from 'state/estimates/selectors';

const withRecommendedBidSelected = compose(
  connect((state, { bidId, estimate }) => ({
    isRecommended: isBidIdRecommendedOnEstimate(bidId, estimate),
  })),
  withProps(({ isRecommended, className }) => ({
    className: isRecommended ? `${className} selected` : className,
  }))
);

export default withRecommendedBidSelected;
