import React from 'react';

const SvgTargetIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="target-icon_svg__a"
        width="132.1%"
        height="133.3%"
        x="-17.9%"
        y="-16.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      fill="currentColor"
      fillRule="evenodd"
      filter="url(#target-icon_svg__a)"
      transform="translate(1 3)"
    >
      <path
        fillRule="nonzero"
        d="M17.83 3.746c-5.615 0-10.168 4.534-10.168 10.127 0 2.686 1.071 5.262 2.978 7.16A10.19 10.19 0 0 0 17.83 24a10.19 10.19 0 0 0 7.192-2.966A10.106 10.106 0 0 0 28 13.873c0-2.686-1.071-5.262-2.978-7.161a10.19 10.19 0 0 0-7.191-2.966zm-.069 2.08c4.5 0 8.15 3.634 8.15 8.116 0 4.482-3.65 8.116-8.15 8.116s-8.15-3.634-8.15-8.116c0-4.482 3.65-8.115 8.15-8.115zm.07 1.943c-3.385 0-6.13 2.733-6.13 6.104s2.745 6.104 6.13 6.104a6.142 6.142 0 0 0 4.334-1.788 6.091 6.091 0 0 0 1.795-4.316c0-3.371-2.744-6.104-6.13-6.104zm0 2.08a4.031 4.031 0 0 1 4.04 4.024 4.031 4.031 0 0 1-4.04 4.023 4.031 4.031 0 0 1-4.04-4.023 4.031 4.031 0 0 1 4.04-4.023zm-.07 2.082c-1.115 0-2.02.9-2.02 2.011 0 1.111.905 2.012 2.02 2.012 1.116 0 2.02-.9 2.02-2.012a2.016 2.016 0 0 0-2.02-2.011z"
      />
      <path d="M9.037 6.68l9.355 6.31-1.318 1.955-9.355-6.31z" />
      <path
        fillRule="nonzero"
        d="M7.078 3.724a.224.224 0 0 1 .176-.262l1.629-.32a.224.224 0 0 1 .263.176l1.035 5.286a.224.224 0 0 1-.177.262L4.72 9.88a.224.224 0 0 1-.262-.177L4.14 8.08a.224.224 0 0 1 .177-.263l3.432-.663-.671-3.43z"
      />
      <path
        fillRule="nonzero"
        d="M3.776 1.449a.224.224 0 0 1 .176-.263l1.63-.32a.224.224 0 0 1 .263.177l1.034 5.285a.224.224 0 0 1-.177.263L1.418 7.605a.224.224 0 0 1-.262-.177L.838 5.804a.224.224 0 0 1 .177-.262l3.432-.663-.671-3.43z"
      />
    </g>
  </svg>
);

export default SvgTargetIcon;
