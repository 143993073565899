import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { compose, branch, renderNothing } from 'recompose';
import AddIcon from '@material-ui/icons/Add';
import { Events, track } from 'components/analytics';
import CampaignModal from 'components/modals/campaignModal';
import { canCreateEstimate } from 'state/workspaces/selectors';

const NewCampaignButtonWithModal = ({ onClick }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const onBtnClick = e => {
    onClick && onClick(e);
    setModalOpen(true);
    track(Events.CAMPAIGN_CREATE_CLICK, { origin: 'page_campaign' });
  };

  return (
    <>
      <Button
        size="large"
        color="primary"
        variant="contained"
        onClick={onBtnClick}
        startIcon={<AddIcon />}
        data-test="createCampaignBtn"
      >
        Create Campaign
      </Button>
      <CampaignModal open={modalOpen} handleClose={() => setModalOpen(false)} />
    </>
  );
};

const mapStateToProps = state => ({
  canCreateEstimate: canCreateEstimate(state),
});

export default compose(
  connect(mapStateToProps),
  branch(({ canCreateEstimate }) => !canCreateEstimate, renderNothing)
)(NewCampaignButtonWithModal);
