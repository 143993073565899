import React from 'react';

const SvgRecommendedIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="recommended-icon_svg__a"
        width="133.3%"
        height="133.3%"
        x="-16.7%"
        y="-16.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      d="M11.988 0C18.622 0 24 5.374 24 12s-5.378 12-12.012 12C5.366 24 0 18.626 0 12S5.366 0 11.988 0zm5.308 18.87l-1.244-5.357 4.144-3.589a.4.4 0 0 0-.228-.7l-5.467-.467-2.133-5.033a.4.4 0 0 0-.736 0L9.498 8.768l-5.466.467a.4.4 0 0 0-.228.701l4.144 3.589-1.244 5.344a.4.4 0 0 0 .596.434l4.7-2.834 4.7 2.834a.4.4 0 0 0 .596-.434z"
      filter="url(#recommended-icon_svg__a)"
      transform="translate(3 3)"
    />
  </svg>
);

export default SvgRecommendedIcon;
