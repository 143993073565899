import React from 'react';
import CloseCampaignModal from 'components/modals/closeCampaign';
import EditCampaignModal from 'components/modals/campaignModal';
import DeleteCampaignModal from 'components/modals/deleteCampaign';
import OpenCampaignModal from 'components/modals/openCampaign';

const CampaignModals = ({
  modalName,
  shouldShowModal,
  hideModal,
  modalProps,
  history,
  fetchCampaigns,
}) => {
  return (
    <>
      {modalName === 'updateCampaign' && (
        <EditCampaignModal open={shouldShowModal} handleClose={hideModal} {...modalProps} />
      )}
      {modalName === 'deleteCampaign' && (
        <DeleteCampaignModal open={shouldShowModal} onModalClose={hideModal} {...modalProps} />
      )}
      {modalName === 'closeCampaign' && (
        <CloseCampaignModal
          open={shouldShowModal}
          onModalClose={closed => {
            if (closed) {
              fetchCampaigns(1);
            }
            hideModal();
          }}
          {...modalProps}
        />
      )}
      {modalName === 'openCampaign' && (
        <OpenCampaignModal
          isOpen={shouldShowModal}
          onRequestClose={opened => {
            if (opened) {
              // redirect to new estimate
              history.push(modalProps.url);
            }
            hideModal();
          }}
          {...modalProps}
        />
      )}
    </>
  );
};

export default CampaignModals;
