import { fetchEstimate, fetchEstimateStatus } from 'state/estimates/actions';
import { getLineItemType } from 'state/bids/selectors';
import { fetchWatchers } from 'state/estimateWatchers/actions';
import { fetch } from 'state/fetchMiddleware';
import { showErrorNotification } from 'state/notifications/actions';
import { UPDATE_LINE_ITEM_NAME } from 'state/estimates/actions.estimate';

export const LINE_ITEM_EDIT_REQUEST = 'estimate/LINE_ITEM_EDIT_REQUEST';
export const LINE_ITEM_EDIT_SUCCESS = 'estimate/LINE_ITEM_EDIT_SUCCESS';
export const LINE_ITEM_EDIT_ERROR = 'estimate/LINE_ITEM_EDIT_ERROR';

export const ADD_BID_REQUEST = 'estimate/ADD_BID_REQUEST';
export const ADD_BID_SUCCESS = 'estimate/ADD_BID_SUCCESS';

export const REMOVE_BID_REQUEST = 'estimate/REMOVE_BID_REQUEST';
export const REMOVE_BID_SUCCESS = 'estimate/REMOVE_BID_SUCCESS';

export const BIDS_RESET = 'estimate/BIDS_RESET';

export const RESET_COST_CATEGORY_BEING_ADDED = 'estimate/RESET_COST_CATEGORY_BEING_ADDED';

export const updateLineItemName = ({
  estimateId,
  bidId,
  lineItemNameId,
  data,
  meta,
  lineItemName,
  mandatory,
}) => ({
  type: UPDATE_LINE_ITEM_NAME,
  estimateId,
  bidId,
  lineItemNameId,
  payload: data,
  meta,
  lineItemName,
  mandatory,
});

export const saveLineItemValue =
  ({ estimateId, bidId, lineItemNameId, value, pageId, lineItemName, mandatory, costCategoryId }) =>
  async (dispatch, getState) => {
    try {
      const valueType = getLineItemType(lineItemNameId)(getState());

      dispatch({
        type: LINE_ITEM_EDIT_REQUEST,
        estimateId,
        bidId,
        lineItemNameId,
        value,
        valueType,
        lineItemName,
        mandatory,
        costCategoryId,
      });

      await dispatch(
        fetch(`/estimates/${estimateId}/bids/${bidId}/lineItems/${lineItemNameId}`, {
          method: 'PUT',
          data: { value },
        })
      );
      dispatch({
        type: LINE_ITEM_EDIT_SUCCESS,
        estimateId,
        bidId,
        lineItemNameId,
        value,
        valueType,
        lineItemName,
        mandatory,
      });
      await dispatch(fetchEstimateStatus(estimateId, pageId));
      fetchWatchers(estimateId)(dispatch);
    } catch (error) {
      dispatch({ type: LINE_ITEM_EDIT_ERROR, estimateId, bidId, lineItemNameId, value });
      dispatch(
        showErrorNotification({
          message: 'Error saving edit. Please refresh and try again.',
        })
      );
      await dispatch(fetchEstimate(estimateId));
    }
  };

export const addBidToEstimate =
  ({ estimateId, pageId }) =>
  async dispatch => {
    dispatch({ type: ADD_BID_REQUEST, estimateId });

    const { data: bid } = await dispatch(
      fetch(`/estimates/${estimateId}/bids`, {
        method: 'POST',
        data: {},
      })
    );
    dispatch({ type: ADD_BID_SUCCESS, estimateId, bid });
    await dispatch(fetchEstimate(estimateId, pageId));
    fetchWatchers(estimateId)(dispatch);
  };

export const removeBidFromEstimate =
  ({ estimateId, bidId, pageId }) =>
  async dispatch => {
    dispatch({ type: REMOVE_BID_REQUEST, estimateId, bidId });

    await dispatch(
      fetch(`/estimates/${estimateId}/bids/${bidId}`, {
        method: 'DELETE',
        data: {},
      })
    );
    dispatch({ type: REMOVE_BID_SUCCESS, estimateId, bidId });
    await dispatch(fetchEstimate(estimateId, pageId));
    fetchWatchers(estimateId)(dispatch);
  };
