import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withHandlers, withState, withProps, compose, getContext, branch } from 'recompose';
import { flow, map, get } from 'lodash/fp';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import { Events, track, getEstimateData } from 'components/analytics';
import withEditConfirmationDialog from 'components/estimateEdit/withEditConfirmationDialog';
import { CreatableSelectAsync, SelectAsync } from 'components/forms';
import NewLineItemModal from 'components/modals/createLineItem';
import withEditability from 'components/withEditability';
import {
  getPickableLineItemsForCostCategoryOnEstimate,
  searchForLineItem,
  getCostCategoryById,
} from 'state/costCategories/selectors';
import { getBidIdsForEstimate } from 'state/bids/selectors';
import { isBidIdRecommendedOnEstimate } from 'state/estimates/selectors';
import { addLineItem, removeNewLineItemFromCostCategory } from 'state/lineItems/actions';
import { getCampaignById } from 'state/campaigns/selectors';
import { Cell, Row } from './tableComponents';

const getCreateLabel = costCategory => value =>
  (
    <span>
      <span className="reactSelect__createable-option-prefix">Create</span>{' '}
      <span className="reactSelect__createable-option-value">"{value}"</span>
      <span className="reactSelect__createable-option-sublabel">
        {' '}
        in{' '}
        <span className="reactSelect__createable-option-sublabel--value">{costCategory.name}</span>
      </span>
    </span>
  );

const styles = `
  width: 305px;
  position: relative;

  input {
    line-height: 28px !important;
    padding: 0px !important;
  }

  .reactSelect__control {
    border-radius: 0 0 0 4px;
    border: 0;
    border: 1px solid #2684ff;
    background: transparent:
  }

  .reactSelect__createable-option-prefix {
    font-weight: 500;
  }

  .reactSelect__createable-option-value {
    font-weight: 600;
  }

  .reactSelect__createable-option-sublabel {
    display: block;
    font-weight: 500;
    margin-top: 2px;
  }

  .reactSelect__createable-option-sublabel--value {
    color: #999;
    cursor: inline-block;
    text-transform: uppercase;
    box-sizing: border-box;
  }
`;

const NewLineItemSelectCreateable = styled(
  ({
    lineItemOptions,
    costCategory,
    addLineItemToCostCategory,
    className,
    showCreateLineItemModal,
    searchForLineItem,
  }) => (
    <CreatableSelectAsync
      data-test="select-line-item"
      placeholder="Search Line Item..."
      className={className}
      onChange={addLineItemToCostCategory}
      // isLoading={campaignCreationInProgress}
      onCreateOption={showCreateLineItemModal}
      defaultOptions={[
        {
          label: get('name', costCategory),
          options: lineItemOptions,
        },
      ]}
      loadOptions={searchForLineItem}
      formatCreateLabel={getCreateLabel(costCategory)}
      autoFocus={true}
    />
  )
)`
  ${styles}
`;

const NewLineItemSelect = styled(
  ({
    lineItemOptions,
    costCategory,
    addLineItemToCostCategory,
    className,
    showCreateLineItemModal,
    searchForLineItem,
  }) => (
    <SelectAsync
      data-test="select-line-item"
      placeholder="Search Line Item..."
      className={className}
      onChange={addLineItemToCostCategory}
      // isLoading={campaignCreationInProgress}
      defaultOptions={[
        {
          label: get('name', costCategory),
          options: lineItemOptions,
        },
      ]}
      loadOptions={searchForLineItem}
      noOptionsMessage={() => 'No line item found'}
      autoFocus={true}
    />
  )
)`
  ${styles}
`;

const DeleteButtonPlaceHolder = styled.div`
  width: 35px;
`;

const LineItemSelect = withEditability(
  ({
    bidIds,
    estimate,
    shouldShowModal,
    onModalClose,
    modalProps,
    isWithEditConfirmationDialogOpen,
    canCreateNewLineItems,
    ...props
  }) => (
    <Row data-test="new-line-item-row">
      {canCreateNewLineItems ? (
        <NewLineItemSelectCreateable {...props} />
      ) : (
        <NewLineItemSelect {...props} />
      )}
      {bidIds.map(bidId => (
        <Cell key={bidId} className={isBidIdRecommendedOnEstimate(bidId, estimate) && 'selected'}>
          -
        </Cell>
      ))}
      <DeleteButtonPlaceHolder />
      {shouldShowModal && (
        <NewLineItemModal isOpen={shouldShowModal} onRequestClose={onModalClose} {...modalProps} />
      )}
    </Row>
  )
);

const mapStateToProps = (state, { estimate, costCategoryId }) => {
  const campaign = getCampaignById(estimate.campaignId)(state);
  const account = get('workspace.account', campaign);

  return {
    lineItemOptions: flow(
      getPickableLineItemsForCostCategoryOnEstimate(
        costCategoryId,
        estimate?.requireLineItemChecks
      ),
      map(({ id, name, costCategoryId, costCategoryName, createdBy }) => ({
        value: id,
        label: name,
        costCategoryId,
        costCategoryName,
        userCreated: Boolean(createdBy),
      }))
    )(state),
    searchForLineItem: searchForLineItem(costCategoryId, state, estimate?.requireLineItemChecks),
    costCategory: getCostCategoryById(costCategoryId)(state),
    bidIds: getBidIdsForEstimate(estimate),
    canCreateNewLineItems: account.usersCreateNewLineItems,
  };
};

const mapDispatchToProps = (dispatch, { estimate, costCategoryId, pageId }) => ({
  addLineItem: (lineItemId, lineItemCostCategoryId, costCategoryId) => {
    dispatch(addLineItem(estimate, lineItemCostCategoryId, lineItemId, 99, pageId));
  },
  removeNewLineItemFromCostCategory: () =>
    dispatch(removeNewLineItemFromCostCategory(estimate.id, costCategoryId)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('modalProps', 'setModalProps', {}),
  withState('shouldShowModal', 'setShowModal', false),
  withHandlers({
    showModal:
      ({ setShowModal }) =>
      () =>
        setShowModal(true),
    hideModal:
      ({ setShowModal }) =>
      () =>
        setShowModal(false),
    addLineItemToCostCategory:
      ({ addLineItem, costCategoryId, estimate, costCategory }) =>
      ({
        value: lineItemId,
        label: lineItemName,
        costCategoryId: lineItemCostCategoryId,
        costCategoryName: lineItemCostCategoryName,
        userCreated,
      }) => {
        addLineItem(lineItemId, lineItemCostCategoryId, costCategoryId);
        track(Events.ESTIMATE_ADD_LINE_ITEM_SUCCESS, {
          ...getEstimateData(estimate),
          costCategoryId,
          costCategoryName: costCategory.name,
          lineItemId,
          lineItemName,
          lineItemCostCategoryId,
          lineItemCostCategoryName,
          lineItemAddToSameCostCategory: lineItemCostCategoryId === costCategoryId,
          userCreated,
        });
      },
  }),
  withHandlers({
    showCreateLineItemModal:
      ({ setShowModal, setModalProps, estimate, costCategoryId, costCategory }) =>
      name => {
        setModalProps({
          name,
          estimate,
          costCategoryId,
        });
        setShowModal(true);
        track(Events.ESTIMATE_CREATE_NEW_LINE_ITEM_CLICK, {
          ...getEstimateData(estimate),
          lineItemName: name,
          costCategoryId,
          costCategoryName: costCategory.name,
        });
      },
    onModalClose:
      ({ hideModal, costCategoryId, addLineItemToCostCategory }) =>
      async lineItem => {
        if (lineItem) {
          await addLineItemToCostCategory({
            value: lineItem.id,
            label: lineItem.name,
            costCategoryId: lineItem.costCategoryId,
            userCreated: true,
          });
        }
        hideModal();
      },
  }),
  withProps(({ shouldShowModal, removeNewLineItemFromCostCategory }) => ({
    handleClickOutside: () => !shouldShowModal && removeNewLineItemFromCostCategory(),
  })),
  withEditConfirmationDialog('addLineItemToCostCategory', {
    trackEvent: {
      cancel: Events.ESTIMATE_ADD_LINE_ITEM_CANCEL,
      props: ['costCategoryId'],
    },
  }),
  getContext({ isWithEditConfirmationDialogOpen: PropTypes.bool }),
  branch(
    ({ isWithEditConfirmationDialogOpen }) => !isWithEditConfirmationDialogOpen,
    onClickOutside
  )
)(LineItemSelect);
