import React from 'react';
import { noop } from 'lodash/fp';
import { connect } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Notications from 'components/common/notifications';
import Header from 'components/header';
import { closeSidePanel } from 'state/ui/actions';
import { isSidePanelOpen } from 'state/ui/selectors';
import { dimensions } from 'shared/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    // display: 'flex',
    width: '100%',
    height: `calc(100% - ${dimensions.headerHeight} - ${dimensions.footerHeight})`,
  },
  childrenWrapper: {
    display: 'flex',
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3f87b6',
    },
    success: {
      main: '#4caf50',
    },
  },
});

const AppContainer = ({ className, children, sidePanelOpen, sidePanelContent, closeSidePanel }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <Header />

        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div className={classes.childrenWrapper}>{children}</div>
        </main>
        <Notications />
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = state => ({
  sidePanelOpen: isSidePanelOpen(state),
});

const mapDispatchToProps = (dispatch, { onCloseSidePanel = noop }) => ({
  closeSidePanel: () => {
    dispatch(closeSidePanel());
    onCloseSidePanel();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
