import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { updateAccount } from 'state/accounts/actions';
import { getAccountById } from 'state/accounts/selectors';

const useStyles = makeStyles(theme => ({
  button: {
    height: 37,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
}));

const UsersSettingsForm = ({ account, updateAccount, className }) => {
  const classes = useStyles();
  const onChange = (e, value) => updateAccount({ onlyAdminInviteUsers: value });

  return (
    <div className={className}>
      <Typography variant="h6" className={classes.title}>
        Users
      </Typography>
      {account && (
        <FormControlLabel
          control={
            <Checkbox checked={account.onlyAdminInviteUsers} onChange={onChange} color="primary" />
          }
          label="Limit user invite to admin"
        />
      )}
    </div>
  );
};

const mapStateTopProps = (state, { accountId }) => ({
  account: getAccountById(accountId)(state),
});

const mapDispatchToProps = (dispatch, { accountId }) => ({
  updateAccount: ({ onlyAdminInviteUsers }) =>
    dispatch(updateAccount({ accountId, onlyAdminInviteUsers })),
});

export default connect(mapStateTopProps, mapDispatchToProps)(UsersSettingsForm);
