import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { reduxForm } from 'redux-form';
import { Events, track, getEstimateData } from 'components/analytics';
import { Button, CancelButton } from 'components/buttons';
import ApprovalsList from 'components/estimateApprovals/approvalsList';
import Modal from 'components/modal';
import UserAvatar from 'components/userAvatar';
import { resubmitEstimate, resetSubmitEstimate } from 'state/estimates/actions';
import { getEstimateById } from 'state/estimates/selectors';
import { LOADING, SUCCESS, ERROR } from 'state/resourceStatus/reducer';
import { font, colors } from 'shared/styles';
import ErrorModal from './error';
import SuccessModal from './success';
import WarningModal from './warning';

const ModalBody = styled.div`
  .title {
    margin-bottom: 20px;
  }
`;

const SelectedApproversList = styled(ApprovalsList)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const ApproverAvatar = styled(UserAvatar)`
  display: flex;
  .userName {
    color: ${colors.text};
    font-weight: ${font.weights.bold};
    text-shadow: none;
  }
`;

const ApprovalListItemRenderer = styled(({ approval, className }) => (
  <div className={className}>
    <ApproverAvatar user={approval.approver} />
  </div>
))`
  display: flex;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
`;

const ApproversHeader = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;

const ResubmitForApprovalModal = ({
  estimateId,
  onConfirmSubmit,
  cancel,
  handleSubmit,
  valid,
  message,
  missingFields,
  ...restProps
}) => {
  const buttons = (
    <>
      <CancelButton onClick={cancel}>No</CancelButton>
      <Button onClick={handleSubmit} disabled={!valid} data-test="send-approval-request-btn">
        {/* <SvgSendIcon className="icon" /> */}
        Yes
      </Button>
    </>
  );

  return (
    <Modal title="Resubmit for Approval" buttons={buttons} {...restProps} onRequestClose={cancel}>
      <ModalBody>
        <ApproversHeader>Resubmit to approvers:</ApproversHeader>
        <SelectedApproversList
          estimateId={estimateId}
          ItemRenderer={ApprovalListItemRenderer}
          onlyNonApprovedClientApprovers={true}
        />
        <form onSubmit={handleSubmit}></form>
      </ModalBody>
    </Modal>
  );
};

const EstimateSubmittedModal = ({ close, ...props }) => {
  const header = (
    <>
      Great!
      <br />
      Your estimate has been successfully re-submitted for Approval
    </>
  );
  const description = 'A notification has been sent to all pending approvers';
  return (
    <SuccessModal
      completed={true}
      header={header}
      description={description}
      {...props}
      onRequestClose={close}
    />
  );
};

const EstimateSubmittingModal = props => (
  <SuccessModal header={'Your estimate is being resubmitted for Approval'} {...props} />
);

const EstimateAlreadyResubmittedModal = ({ close, ...props }) => {
  const header = <>You’ve already sent a reminder to all Pending Approval approvers.</>;
  return <WarningModal completed={true} header={header} {...props} onRequestClose={close} />;
};

const EstimateNotSubmittedModal = ({ close, ...props }) => {
  const header = <>You need first to submit the estimate for approval.</>;
  return <WarningModal completed={true} header={header} {...props} onRequestClose={close} />;
};

const EstimateErrorModal = ({ close, ...props }) => (
  <ErrorModal
    header="An error occured while re-submitting the estimate"
    description="Try again"
    {...props}
    onRequestClose={close}
  />
);

const SubmitForApproval = props => {
  const { estimateResubmit, alreadySubmitted, requestSentToAllApprovers } = props;

  if (estimateResubmit === LOADING) {
    return EstimateSubmittingModal(props);
  }

  if (estimateResubmit === ERROR) {
    return EstimateErrorModal(props);
  }

  if (!requestSentToAllApprovers) {
    return EstimateNotSubmittedModal(props);
  }

  if (alreadySubmitted) {
    return EstimateAlreadyResubmittedModal(props);
  }

  if (estimateResubmit === SUCCESS) {
    return EstimateSubmittedModal(props);
  }
  return ResubmitForApprovalModal(props);
};

const mapDispatchToProps = (dispatch, { estimateId, onRequestClose, estimate, pageId }) => ({
  resubmitEstimate: async ({ message }) => {
    await dispatch(resubmitEstimate(estimateId, message, pageId));
  },
  close: async () => {
    await dispatch(resetSubmitEstimate());
    onRequestClose();
  },
});

const mapStateToProps = (state, { estimateId }) => {
  return {
    estimateResubmit: state.resourceStatus.estimateResubmit,
    estimate: getEstimateById(estimateId)(state),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withHandlers({
    cancel:
      ({ close, estimate }) =>
      () => {
        track(Events.ESTIMATE_SUBMIT_CANCEL, { ...getEstimateData(estimate) });
        close();
      },
    onSubmit:
      ({ estimate, resubmitEstimate, pageId }) =>
      ({ message }) => {
        resubmitEstimate({ message });
        track(Events.ESTIMATE_RESUBMIT_SUCCESS, {
          ...getEstimateData(estimate),
          message: Boolean(message),
          messageLength: message ? message.length : 0,
        });
      },
  }),
  reduxForm({ form: 'submitForApprovadl' })
)(SubmitForApproval);
