import React from 'react';
import { compose, lifecycle, withProps } from 'recompose';
import scrollToElement from 'scroll-to-element';

export default compose(
  withProps(() => {
    const elementRef = React.createRef();
    return {
      elementRef,
      scrollIntoView: () =>
        elementRef.current &&
        scrollToElement(elementRef.current, { ease: 'in-out-quart', duration: 600, offset: 50 }),
    };
  }),
  lifecycle({
    componentDidMount() {
      this.props.scrollOnMount && this.props.scrollIntoView();
    },
  }),
  Component => ({ elementRef, ...props }) => (
    <div ref={elementRef}>
      <Component {...props} />
    </div>
  )
);
