import React from 'react';
import PropTypes from 'prop-types';
import { ifProp } from 'styled-tools';
import { compose, getContext, branch } from 'recompose';
import styled, { css } from 'styled-components';
import withDeleteConfirmationDialog from 'components/estimateEdit/withDeleteConfirmation';
import withEditConfirmationDialog from 'components/estimateEdit/withEditConfirmationDialog';
import withEditability from 'components/withEditability';
import SvgTrashIcon from 'icons/TrashIcon';
import { font, colors, shadows } from 'shared/styles';

const costCategoryLineItemNameWidth = 280;
const expanderWidth = 25;
const extraPaddingAtEndOfRow = 10;

export const TableScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  width: 100%;
  margin: 10px;
  padding-bottom: 20px;
`;

export const Table = styled.div`
  display: inline-flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  min-width: fit-content;
  color: ${colors.text};
  font-size: ${font.sizes.largish};
`;

export const TableSection = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: white;
  overflow: visible;
  font-weight: ${font.weights.bold};
  padding-left: ${extraPaddingAtEndOfRow}px;
  padding-right: ${extraPaddingAtEndOfRow}px;

  .metadata {
    font-style: italic;
  }
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  padding-left: ${extraPaddingAtEndOfRow}px;
  padding-right: ${extraPaddingAtEndOfRow}px;

  .row {
    padding-left: ${costCategoryLineItemNameWidth + expanderWidth}px;
  }

  .headerRow {
    /*padding-left: 195px;*/
    padding-left: 0;
  }
`;

export const TableFooter = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  padding-left: ${extraPaddingAtEndOfRow}px;
  padding-right: ${extraPaddingAtEndOfRow}px;
  .row {
    padding-left: ${expanderWidth}px;
  }
`;

export const Cell = styled.div`
  display: flex;
  flex: 1;
  min-width: 100px;
  max-width: 250px;
  justify-content: flex-end;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${colors.text};
  margin-left: 1px;
  margin-right: 1px;

  &.selected {
    background-color: ${colors.recommendedColumn};
  }

  ${({ readOnly }) =>
    !readOnly &&
    css`
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    `}
`;

export const DropdownCell = styled.div`
  display: flex;
  flex: 1;
  min-width: 100px;
  max-width: 250px;
  justify-content: flex-end;
  align-items: center;

  white-space: nowrap;
  color: ${colors.text};
  margin-left: 1px;
  margin-right: 1px;

  &.selected {
    background-color: ${colors.recommendedColumn};
  }

  ${({ readOnly }) =>
    !readOnly &&
    css`
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    `}
`;

export const ValueContainer = styled.div`
  padding: 12px;
`;

export const FullWidthCell = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 12px;
  color: ${colors.text};
  ${({ readOnly }) => {
    return (
      !readOnly &&
      css`
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      `
    );
  }}
`;

export const TableHeaderCell = styled(Cell)`
  border-radius: 5px 5px 0 0;
  font-weight: ${font.weights.bold};
  padding: 8px 12px;

  .actions {
    visibility: hidden;
  }
  ${ifProp(
    { readOnly: false },
    css`
      &:hover,
      &.hover {
        text-decoration: none;
        cursor: initial;
        .actions {
          visibility: visible;
        }
      }
    `
  )}
`;

export const HeaderCellActions = styled(({ className, ...props }) => (
  <div className={`${className} actions`} {...props} />
))`
  display: flex;
  align-items: center;
  margin-right: auto;
`;

export const TableFooterCell = styled(Cell)`
  display: flex;
  font-weight: ${font.weights.bold};
  justify-content: flex-end;
  padding: 12px;
  &.last {
    border-radius: 0 0 5px 5px;
  }
`;

export const RowsContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  ${ifProp(
    'enabled',
    css`
      cursor: pointer;
    `
  )}
`;

export const SubRowsContainer = styled(RowsContainer)`
  border-radius: 0 0 3px 3px;
  margin-bottom: 5px;
  align-items: flex-end;
  font-weight: ${font.weights.regular};
  box-shadow: ${shadows.inputBoxShadow};

  :last-child {
    margin-bottom: 10px;
  }

  .row-title {
    width: ${costCategoryLineItemNameWidth + expanderWidth}px;
    justify-content: flex-end;
    text-align: right;
  }

  .row:nth-child(odd) {
    background-color: ${colors.alternatingTableRow};
  }
`;

const DeleteButton = styled(({ className, ...props }) => (
  <button className={`${className} delete-row`} onClick={props.onClick}>
    <SvgTrashIcon className="icon" />
  </button>
))`
  margin: auto;
  opacity: 0.4;
  cursor: pointer;
  background: transparent;
  border: 0;

  :hover {
    opacity: 1;
    .icon {
      color: ${colors.alert};
    }
  }

  &:active,
  &:focus {
    outline: none;
  }

  .icon {
    color: ${colors.cancelHover};
    width: 20px;
  }
`;

const DeleteRowButton = compose(
  withEditability,
  getContext({ estimate: PropTypes.object }),
  branch(
    ({ withDeleteConfirmation }) => withDeleteConfirmation,
    withDeleteConfirmationDialog('onClick')
  ),
  withEditConfirmationDialog('onClick')
)(DeleteButton);

const RowContainer = styled.div`
  display: inline-flex;
  min-width: 100%;

  .delete-row {
    visibility: hidden;
  }
  :hover {
    .delete-row {
      visibility: visible;
    }
  }
`;

export const Row = ({
  children,
  onDelete,
  forwardedRef,
  draggableProps,
  dragHandleProps,
  withDeleteConfirmation,
  className,
  canBeDeleted = true,
  ...props
}) => {
  return (
    <RowContainer
      className={`row ${className}`}
      {...draggableProps}
      {...dragHandleProps}
      ref={forwardedRef}
      data-test={props['data-test']}
    >
      {children}
      {onDelete && canBeDeleted && (
        <DeleteRowButton onClick={onDelete} withDeleteConfirmation={withDeleteConfirmation} />
      )}
    </RowContainer>
  );
};

export const Expander = styled.div`
  display: flex;
  width: ${expanderWidth}px;
  font-size: ${font.sizes.veryLarge};
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

export const RowTitle = styled(({ className, ...props }) => (
  <div className={`${className} row-title`} {...props} />
))`
  display: flex;
  width: ${costCategoryLineItemNameWidth}px;
  padding: 12px 8px;
  flex-shrink: 0;
  align-items: center;
`;
