import React, { useState } from 'react';
import { connect } from 'react-redux';
import { map, reject, get } from 'lodash/fp';
import { getFormValues } from 'redux-form';
import { makeStyles, fade, lighten } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  IconButton,
  TextField,
  DialogActions,
  DialogContent,
  ListItemSecondaryAction,
  ListItemText,
  ListItem,
  List,
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { getSidePanelData } from 'state/ui/selectors';
import { closeSidePanel } from 'state/ui/actions';
import { inviteUsers } from 'state/users/actions';
import { showErrorNotification } from 'state/notifications/actions';

const useStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(4, 3),
    position: 'relative',
  },
  menuButton: {
    position: 'absolute',
    right: 2,
    top: 10,
  },
  sidePanelTitle: {
    marginBottom: theme.spacing(4),
  },

  listContainer: {
    margin: theme.spacing(3, 0),
  },
  emptyListMessage: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2, 1),
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[300],
  },

  flexGrow: { flexGrow: 1 },
  listTitle: {
    marginBottom: theme.spacing(1),
  },
  listItem: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
  listItemButton: {
    color: theme.palette.grey[400],
  },
  listButton: {
    marginTop: theme.spacing(1),
  },
  listItemBorder: {
    border: `1px solid ${lighten(fade(theme.palette.divider, 1), 0.88)}`,
    marginBottom: -1,
  },
  userListItem: {
    padding: theme.spacing(1),
    paddingLeft: 0,
  },

  submitButtonContainer: {
    textAlign: 'right',
    marginTop: theme.spacing(4),
  },

  userInvitationSummary: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  usersContainer: {
    width: '50%',
  },
  summaryArrowIcon: {
    margin: `1.5rem ${theme.spacing(2)}px 0`,
    color: theme.palette.grey[500],
  },
  summaryListContainerRight: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '50%',
  },
  teamsContainer: {
    flexGrow: 1,
  },
  emailForm: {
    display: 'flex',
    alignItems: 'center',
  },
  emailTextField: {
    flexGrow: 1,
  },
}));

const UserEmails = ({ emails = [], addTeam, removeEmail }) => {
  const classes = useStyles();
  if (!emails.length) {
    return null;
  }
  return (
    <div className={classes.listContainer}>
      <Typography variant="h6" className={classes.listTitle}>
        Emails
      </Typography>
      <List>
        {map(
          email => (
            <ListItem key={email} className={classes.listItemBorder}>
              <ListItemText primary={email} />
              {removeEmail && (
                <ListItemSecondaryAction onClick={() => removeEmail(email)}>
                  <IconButton edge="end" aria-label="delete" className={classes.listItemButton}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ),
          emails
        )}
      </List>
    </div>
  );
};

const InviteConfirmationModal = ({ data, onClose, open }) => {
  const classes = useStyles();
  const { userEmails } = data;
  const handleClose = confirmed => () => onClose(confirmed);

  return (
    <Dialog onClose={handleClose} aria-labelledby="add-team-dialog" open={open}>
      <DialogTitle id="simple-dialog-title">Do you want to invite these new users?</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          The following users will be invited to create an account on Control Room
        </Typography>

        <div className={classes.userInvitationSummary}>
          <div className={classes.flexGrow}>
            <UserEmails emails={userEmails} />
          </div>
        </div>

        <Typography gutterBottom>
          We will send an invitation email with a link for the new users to complete signup. Once
          signup is completed the users can start using the platform.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose(false)} variant="contained">
          Cancel
        </Button>
        <Button autoFocus onClick={handleClose(true)} color="primary" variant="contained">
          Invite Users
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UserDetails = ({
  sidePanelData,
  closeSidePanel,
  teams,
  inviteUsers,
  showErrorNotification,
  selectedAccount,
}) => {
  const classes = useStyles();
  const [modal, setModal] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [userEmails, setUserEmails] = useState([]);
  const [newEmail, setNewEmail] = useState('');

  const addEmail = (e, ...props) => {
    e.preventDefault();
    userEmails.push(newEmail);
    setUserEmails(userEmails);
    setNewEmail('');
  };

  const removeEmail = email => {
    setUserEmails(reject(item => item === email, userEmails));
  };

  const onEmailChange = event => {
    setNewEmail(event.target.value);
  };

  const onInviteUsers = () => {
    setModal('CONFIRMATION_INVITE_USERS');
    setOpenModal(true);
    setModalData({
      userEmails,
    });
  };

  const handleICClose = async confirmed => {
    if (confirmed) {
      const success = await inviteUsers(userEmails, selectedAccount, { teams });
      if (success) {
        closeSidePanel(success);
      }
    }
    setOpenModal(false);
    setModal();
  };

  return (
    <div className={classes.body}>
      <Typography variant="h4" className={classes.sidePanelTitle}>
        Invite new users
      </Typography>
      <IconButton onClick={closeSidePanel} className={classes.menuButton}>
        <CloseIcon />
      </IconButton>

      {modal === 'CONFIRMATION_INVITE_USERS' && (
        <InviteConfirmationModal
          open={openModal}
          onClose={handleICClose}
          data={modalData}
          title={modalData.title}
          message={modalData.message}
          actionLabel={modalData.actionLabel}
        />
      )}

      <div>
        <form autoComplete="off" onSubmit={addEmail} className={classes.emailForm}>
          <TextField
            id="outlined-basic"
            label="User Email"
            variant="outlined"
            type="email"
            className={classes.emailTextField}
            onChange={onEmailChange}
            value={newEmail}
          />
          <IconButton onClick={addEmail}>
            <AddIcon />
          </IconButton>
        </form>

        <UserEmails emails={userEmails} removeEmail={removeEmail} />

        <div className={classes.submitButtonContainer}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={onInviteUsers}
            disabled={!userEmails.length}
          >
            Invite Users
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const filters = getFormValues('userFilters')(state) || {};
  return {
    selectedAccount: get('accountId', filters),
    sidePanelData: getSidePanelData(state),
  };
};

const mapDispatchToProps = (dispatch, state) => ({
  closeSidePanel: () => dispatch(closeSidePanel()),
  inviteUsers: async (emails, accountId, data) => {
    try {
      await dispatch(inviteUsers(emails, accountId, data, true));
      return true;
    } catch (e) {
      return false;
    }
  },
  showErrorNotification: data => dispatch(showErrorNotification(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
