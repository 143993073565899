import { set, keyBy, flow, map, unset, getOr } from 'lodash/fp';
import {
  FETCH_USERS_SUCCESS,
  CREATE_USER_SUCCESS,
  TOOGLE_USER_STATUS_SUCCESS,
  USER_BLOCK_SUCCESS,
  USER_DELETE_SUCCESS,
  USER_BLOCK_ERROR,
  USER_DELETE_ERROR,
  USER_BLOCK_REQUEST,
  USER_DELETE_REQUEST,
  USER_INVITE_REQUEST,
  USER_INVITE_ERROR,
  USER_UPDATE_SUCCESS,
} from './actions';

const defaultState = {
  users: {},
  pagination: { totalResults: 10, page: 0 },
  error: null,
};

const setError = (state, action, defaultMessage) => {
  const errorMessage = getOr(defaultMessage, 'message', action.error) || defaultMessage;
  return set('error', errorMessage, state);
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        error: null,
        users: flow(
          map(user => set('workspaceIds', map('id', user.workspaces), user)),
          keyBy('id')
        )(action.users),
        pagination: action.pagination,
      };

    case CREATE_USER_SUCCESS:
      return { ...state, users: set(action.user.id, action.user, state) };

    case USER_UPDATE_SUCCESS:
      return set(`users.${action.userId}`, action.user, state);

    case TOOGLE_USER_STATUS_SUCCESS:
      const { user } = action;
      user.workspaceIds = map('id', user.workspaces);
      return {
        ...state,
        [action.user.id]: action.user,
      };

    case USER_BLOCK_REQUEST:
    case USER_DELETE_REQUEST:
    case USER_INVITE_REQUEST:
      return set('error', null, state);

    case USER_BLOCK_SUCCESS:
      return set(`users.${action.userId}`, action.user, state);

    case USER_DELETE_SUCCESS:
      return unset(`users.${action.userId}`, state);

    case USER_BLOCK_ERROR:
    case USER_DELETE_ERROR:
      return setError(state, action, 'An error occurred while applying the action on the user');

    case USER_INVITE_ERROR:
      return setError(state, action, 'An error occurred while inviting new users');

    default:
      return state;
  }
};
