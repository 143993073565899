import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  textField: {
    minWidth: 250,
  },
}));

export default ({ open, handleClose, title, message, actionLabel, value, valueLabel }) => {
  const classes = useStyles();
  const [newVal, setNewValue] = useState(value);
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id="confirm-modal"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleClose(newVal);
          }}
        >
          <TextField
            label={valueLabel}
            variant="outlined"
            required
            value={newVal}
            onChange={e => setNewValue(e.target.value)}
            className={classes.textField}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary" data-test="confirm-modal-cancel">
          Cancel
        </Button>
        <Button
          onClick={() => handleClose(newVal)}
          color="primary"
          data-test="confirm-modal-confirm"
        >
          {actionLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
