import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getFormValues } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { compact, get, find, differenceBy } from 'lodash/fp';
import { formatUserName } from 'cr-core/userUtils';
import Menu from 'components/common/menu';
import actionWrapper from 'state/actionWrapper';
import { getSidePanelData } from 'state/ui/selectors';
import { fetchUsers } from 'state/users/actions';
import { getAccountById } from 'state/accounts/selectors';
import { getUser as getLoggedUser } from 'state/authentication/selectors';
import Users from './users';
import Workspaces from './workspaces';
import {
  fetchTeam,
  fetchTeams,
  deleteTeam,
  addUser,
  removeUser,
  editTeam,
} from 'state/teams/actions';
import { getTeamById } from 'state/teams/selectors';
import { getUsers } from 'state/users/selectors';
import { isResourceLoading } from 'state/resourceStatus/selectors';
import ListSelectorModal from './listSelectorModal';
import ConfirmationModal from 'components/modals/confirmationModal';
import EditValueModal from 'components/modals/setValueModal';

const ADD_USER_ACTION = 'ADD_USER_ACTION';
const EDIT_TEAM_NAME_ACTION = 'EDIT_TEAM_NAME_ACTION';
const REMOVE_USER_ACTION = 'REMOVE_USER_ACTION';

const useStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(4, 3),
    position: 'relative',
  },
  sidePanelTitleWithAction: { marginRight: 30 },
  menuButton: {
    position: 'absolute',
    right: 2,
    top: 10,
  },
  listContainer: {
    margin: theme.spacing(2, 0),
  },
  emptyListMessage: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2, 1),
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[300],
  },

  list: {
    height: '100%',
    overflowY: 'scroll',
  },

  listSelectorModal: {
    minWidth: 400,
  },
}));

const Details = ({
  loggedUser,
  deleteTeam,
  addUser,
  removeUser,
  fetchUsers,
  fetchTeams,
  editTeamName,
  team,
  users,
  isUsersLoading,
  selectedAccount,
  filters,
}) => {
  const classes = useStyles();
  const [modal, setModal] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const menuItems = compact([
    {
      label: 'Edit Team Name',
      onClick: () => showEditTeamNameModal(),
    },
    {
      label: 'Delete Team',
      onClick: async () => {
        const success = await deleteTeam(team);
        if (success) {
          fetchTeams(filters);
        }
      },
    },
  ]);

  useEffect(() => {
    if (selectedAccount && !isUsersLoading) {
      fetchUsers({ accountId: selectedAccount.id });
    }
  }, [selectedAccount]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if (!users.length && selectedAccount && !isUsersLoading) {
    //   fetchUsers({ accountId: selectedAccount.id });
    // }
    if (users && showModal && modal === ADD_USER_ACTION) {
      setModalData({ ...modalData, list: differenceBy('id', users, team.members) });
    }
  }, [users]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!team) {
    return null;
  }

  const openModal = options => {
    setModal(options.action);
    setShowModal(true);
    setModalData(options);
  };

  const handleCloseModal = action => item => {
    if (item) {
      action(item);
    }
    setShowModal(false);
    setModal();
    setModalData({});
    fetchUsers({ accountId: selectedAccount.id });
  };

  const showAddUserModal = () =>
    openModal({
      action: ADD_USER_ACTION,
      list: differenceBy('id', users, team.members),
      title: 'Select the user you want to add',
      actionLabel: 'Select User',
    });
  const handleAddUserClose = handleCloseModal(user => addUser(team, user, selectedAccount.id));

  const showEditTeamNameModal = () =>
    openModal({
      action: EDIT_TEAM_NAME_ACTION,
      team,
      title: 'Edit team name',
      actionLabel: 'Save',
    });
  const handleEditTeamNameClose = handleCloseModal(name => editTeamName(team, name));

  const removeUserWithConfirmation = userId => {
    const user = find({ id: userId }, users);
    openModal({
      action: REMOVE_USER_ACTION,
      user,
      title: 'Do you want to remove the user?',
      message: `"${formatUserName(
        user
      )}" will be removed from the team and won't be able to access to the team data anymore.`,
      actionLabel: 'Remove',
    });
  };
  const handleRemoveUserClose = handleCloseModal(() =>
    removeUser(team, modalData.user, selectedAccount.id)
  );

  return (
    <div className={classes.body}>
      {loggedUser.isAdmin && <Menu items={menuItems} className={classes.menuButton} />}
      <Grid container spacing={2}>
        <Typography variant="h5" className={classes.sidePanelTitleWithAction}>
          {team.name}
        </Typography>

        <Users
          selectedUsers={team.members}
          users={users}
          addUser={showAddUserModal}
          removeUser={removeUserWithConfirmation}
        />

        <Workspaces selectedWorkspaces={team.workspaces} />

        {modal === ADD_USER_ACTION && (
          <ListSelectorModal
            open={showModal}
            onClose={handleAddUserClose}
            list={modalData.list}
            title={modalData.title}
            message={modalData.message}
            actionLabel={modalData.actionLabel}
            onSearch={search => fetchUsers({ accountId: selectedAccount.id, search })}
            isLoading={isUsersLoading}
            className={classes.listSelectorModal}
          />
        )}
        {modal === REMOVE_USER_ACTION && (
          <ConfirmationModal
            open={showModal}
            handleClose={handleRemoveUserClose}
            title={modalData.title}
            message={modalData.message}
            actionLabel={modalData.actionLabel}
          />
        )}
        {modal === EDIT_TEAM_NAME_ACTION && (
          <EditValueModal
            open={showModal}
            handleClose={handleEditTeamNameClose}
            title={modalData.title}
            message={modalData.message}
            actionLabel={modalData.actionLabel}
            value={team.name}
            valueLabel="Name"
          />
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  const accountId = get('accountId', getFormValues('teamFilters')(state));
  return {
    loggedUser: getLoggedUser(state),
    team: getTeamById(get('team.id', getSidePanelData(state)))(state),
    users: getUsers(state),
    isUsersLoading: isResourceLoading('users')(state),
    selectedAccount: getAccountById(accountId)(state),
    filters: getFormValues('teamFilters')(state) || {},
  };
};

const mapDispatchToProps = dispatch => ({
  deleteTeam: team =>
    actionWrapper(dispatch, deleteTeam(team.id), `Team "${team.name}" deleted`, true),
  fetchTeams: filters => dispatch(fetchTeams(filters)),
  fetchUsers: filters => dispatch(fetchUsers(filters)),
  editTeamName: (team, name) => dispatch(editTeam(team.id, { name })),
  addUser: async (team, user, accountId) => {
    await actionWrapper(
      dispatch,
      addUser(team.id, user.id),
      `User "${formatUserName(user)}" added to the team`
    );
    dispatch(fetchTeam(team.id, accountId));
  },
  removeUser: async (team, user, accountId) => {
    await actionWrapper(
      dispatch,
      removeUser(team.id, user.id),
      `User "${formatUserName(user)}" removed from the team`
    );
    dispatch(fetchTeam(team.id, accountId));
  },
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Details);
