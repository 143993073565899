import { compose, withContext, withState, withProps } from 'recompose';
import PropTypes from 'prop-types';

export default compose(
  withState('isExpanded', 'setExpanded'),
  withState('oldExpanded', 'setOldExpanded'),
  withProps(props => {
    const { expanded, oldExpanded, setOldExpanded, setExpanded } = props;
    if (expanded !== oldExpanded && (typeof oldExpanded === 'undefined' || expanded)) {
      setOldExpanded(expanded);
      setExpanded(expanded);
    }
    return props;
  }),
  withContext(
    {
      toggleExpanded: PropTypes.func,
      isExpanded: PropTypes.bool,
    },
    ({ expanded, isExpanded, setExpanded, setOldExpanded }) => ({
      toggleExpanded: () => {
        setExpanded(!isExpanded);
      },
      isExpanded,
    })
  )
);
