import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Events, track, getEstimateData } from 'components/analytics';
import ConfirmationModal from 'components/modals/confirmation';
import { getDisplayCurrency } from 'state/authentication/selectors';
import { getEstimateById, getCosts } from 'state/estimates/selectors';
import { getEstimateInvoices } from 'state/estimateInvoices/selectors';
import { closeActualisation } from 'state/estimates/actions';

const CloseActualisationModal = ({
  isOpen,
  onRequestClose,
  closeActualisation,
  estimate,
  invoices,
  displayCurrency,
}) => {
  return (
    <ConfirmationModal
      onConfirm={() => {
        const { estimated, actualised } = getCosts(estimate, displayCurrency);
        const savings = estimated ? estimated - actualised : null;
        track(Events.ESTIMATE_CLOSE_ACTUALISATION_SUCCESS, {
          ...getEstimateData(estimate),
          invoicesCount: invoices.length,
          savings,
        });
        return closeActualisation();
      }}
      onRequestClose={onRequestClose}
      onCancel={() =>
        track(Events.ESTIMATE_CLOSE_ACTUALISATION_CANCEL, {
          ...getEstimateData(estimate),
          invoicesCount: invoices.length,
        })
      }
      isOpen={isOpen}
      title="Close Actualisation"
    >
      Do you want to close actualisation for this estimate?
      <br />
      When you close actualisation you are confirming that no more invoices will be added and the
      actualised amount is the final amount.
    </ConfirmationModal>
  );
};

const mapStateToProps = (state, { estimateId }) => ({
  estimate: getEstimateById(estimateId)(state),
  invoices: getEstimateInvoices(state),
  displayCurrency: getDisplayCurrency(state),
});

const mapDispatchToProps = (dispatch, { estimateId, pageId }) => ({
  closeActualisation: () => dispatch(closeActualisation(estimateId, pageId)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(CloseActualisationModal);
