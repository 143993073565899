import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useField } from 'formik';

const useStyles = makeStyles(theme => ({
  clearIndicator: {
    visibility: 'hidden !important',
  },
  clearIndicatorDirty: {
    visibility: 'hidden !important',
  },
}));

const FormikAutocomplete = ({
  index,
  options,
  textFieldProps,
  field,
  form,
  className,
  autocompleteProps,
  ...props
}) => {
  const classes = useStyles();
  const helpers = useField(field.name)[2];

  return (
    <Autocomplete
      freeSolo
      selectOnFocus={true}
      className={className}
      classes={{
        clearIndicator: classes.clearIndicator,
        clearIndicatorDirty: classes.clearIndicatorDirty,
      }}
      disabled={form.isSubmitting}
      options={options}
      {...field}
      onChange={(event, value) => helpers.setValue(value)}
      onBlur={() => helpers.setTouched(true)}
      renderInput={params => (
        <TextField
          autoFocus={field.value === '' && index !== 0}
          {...params}
          inputProps={{ ...params.inputProps }}
          className={classes.formControl}
          {...textFieldProps}
          onChange={event => helpers.setValue(event.target.value)}
          fullWidth
        />
      )}
      {...autocompleteProps}
      {...props}
    />
  );
};

export default FormikAutocomplete;
