import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { get } from 'lodash/fp';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import EstimateCard, { EstimateCardLoading } from 'components/estimateCard';
import { getEstimates, getEstimatesPaginations } from 'state/estimates/selectors';
import { fetchEstimates, resetEstimates, defaultLimit } from 'state/estimates/actions';
import { LOADING } from 'state/resourceStatus/reducer';
import { canCreateEstimate } from 'state/workspaces/selectors';
import { fetchCampaigns } from 'state/campaigns/actions';
import { isFiltered } from 'components/filtersSidebar';
import { CircularProgress, Box } from '@material-ui/core';

const limit = defaultLimit;

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0px;
  margin: 0 auto;
  height: calc(100vh + 20px);

  .message {
    padding: 20px;
  }
`;

const EmptyListContainer = styled(Container)`
  padding: 30px;
`;

const CreateYourFirstEstimate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .button {
    width: auto;
  }
`;

const CreateYourFirstEstimateText = styled.div`
  display: flex;
  margin-top: 30px;
  font-size: 16px;
`;

const CardWrapper = styled.div`
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1500px;
`;

const EmptyFilteredList = () => (
  <Container>
    <div className="message" data-test="noEstimatesText">
      No estimate for the selected filters.
    </div>
  </Container>
);

const EmptyList = ({ canCreateEstimate }) => (
  <EmptyListContainer>
    {canCreateEstimate && (
      <CreateYourFirstEstimate data-test="createYourFirstEstimate">
        <CreateYourFirstEstimateText data-test="createYourFirstEstimateText">
          To create an estimate, first you need to create a Campaign
        </CreateYourFirstEstimateText>
      </CreateYourFirstEstimate>
    )}
    {!canCreateEstimate && (
      <CreateYourFirstEstimate data-test="createYourFirstEstimate">
        You have to be in a workspace to view and create estimates
      </CreateYourFirstEstimate>
    )}
  </EmptyListContainer>
);

const isItemLoaded = data => index => {
  return Boolean(get('id', data[index]));
};

const Row = ({ index, style, data, isLoading, pagination = { page: 0 } }) => (
  <CardWrapper
    className="ListItem"
    style={{
      ...style,
      top: `${parseFloat(style.top) + 20}px`,
      bottom: `${parseFloat(style.bottom) + 40}px`,
    }}
  >
    {isItemLoaded(data)(index) ? <EstimateCard estimate={data[index]} /> : <EstimateCardLoading />}
  </CardWrapper>
);

const EstimateList = ({
  estimates,
  trackScroll,
  pagination = { totalResults: limit, page: 0 }, // totalResults = limit is to make InfiniteLoader triggers the first loadMoreItems call
  isLoading,
  fetchEstimates,
  loadData,
  filters,
}) => {
  const itemCount =
    (pagination.totalResults || 0) + (isLoading && !pagination.totalResults ? 10 : 0);
  const itemData = isLoading ? estimates.concat(Array(10).fill({})) : estimates;

  useEffect(() => {
    loadData();
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadMoreItems = () => {
    return isLoading ? Promise.resolve() : fetchEstimates({ page: pagination.page + 1 });
  };

  if (!isLoading && !pagination.totalResults) {
    return isFiltered(filters) ? (
      <EmptyFilteredList />
    ) : (
      <EmptyList canCreateEstimate={canCreateEstimate} />
    );
  }
  if (isLoading && !pagination.page) {
    return (
      <Box display="flex" height="60vh" alignItems="center" justifyContent="center">
        <CircularProgress color="primary" size="5rem" />
      </Box>
    );
  }

  return (
    <Container>
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded(estimates)}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            minimumBatchSize={limit}
          >
            {({ onItemsRendered, ref }) => (
              <List
                className="List"
                height={height}
                itemCount={itemCount}
                itemSize={200}
                onItemsRendered={onItemsRendered}
                ref={ref}
                width={width}
                itemData={itemData}
              >
                {props => Row({ ...props, isLoading, pagination })}
              </List>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </Container>
  );
};

const mapStateToProps = state => ({
  estimates: getEstimates(state),
  canCreateEstimate: canCreateEstimate(state),
  pagination: getEstimatesPaginations(state),
  isLoading: state.resourceStatus.estimates === LOADING,
});

const mapDispatchToProps = (dispatch, { pageId, filters }) => ({
  loadData: () => {
    dispatch(resetEstimates());
    dispatch(fetchCampaigns(filters));
    return dispatch(fetchEstimates({ defaultLimit, page: 1, ...filters }, pageId));
  },
  fetchEstimates: async (query = {}) => {
    if (query.page === 1) {
      dispatch(resetEstimates());
    }
    return dispatch(fetchEstimates({ defaultLimit, ...filters, ...query }, pageId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EstimateList);
