interface Options {
  raw?: boolean;
}

const StorageService = {
  setItem: (key: string, value: string, options?: Options) => {
    try {
      window.localStorage.setItem(key, options?.raw ? value : JSON.stringify(value));
      return true;
    } catch {
      return false;
    }
  },

  getItem: <T = unknown>(key: string, options?: Options) => {
    try {
      const value = window.localStorage.getItem(key);

      if (value === null) {
        return null;
      }

      return options?.raw ? value : (JSON.parse(value) as T);
    } catch {
      return null;
    }
  },

  removeItem: (key: string) => {
    try {
      window.localStorage.removeItem(key);

      return true;
    } catch {
      return false;
    }
  },
};

export default StorageService;
