import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Events, track, getCampaignData } from 'components/analytics';
import ConfirmationModal from 'components/modals/confirmation';
import { openCampaign } from 'state/campaigns/actions';
import { getCampaignById } from 'state/campaigns/selectors';

const OpenCampaignModal = ({ isOpen, onRequestClose, openCampaign, campaign, campaignId }) => {
  return (
    <ConfirmationModal
      onConfirm={async () => {
        track(Events.CAMPAIGN_OPEN_SUCCESS, getCampaignData(campaign));
        await openCampaign();
      }}
      onRequestClose={onRequestClose}
      onCancel={() => track(Events.CAMPAIGN_OPEN_CANCEL, getCampaignData(campaign))}
      isOpen={isOpen}
      title="Re-open Campaign"
    >
      Do you want to re-open this campaign?
      <br />
      Re-opening a campaign allows to add more estimates to it.
    </ConfirmationModal>
  );
};

const mapStateToProps = (state, { campaignId }) => ({
  campaign: getCampaignById(campaignId)(state),
});

const mapDispatchToProps = (dispatch, { campaignId, campaign }) => ({
  openCampaign: () => dispatch(openCampaign(campaignId)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(OpenCampaignModal);
