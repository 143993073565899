import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { flow, first } from 'lodash/fp';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { EstimateStatuses } from 'cr-core/constants';
import { formatAsCurrency } from 'cr-core/currencyUtils';
import { convertUpperCaseToPretty } from 'cr-core/stringUtils';
import EstimateStatus, { ApprovalColors } from 'components/estimateStatus';
import EstimateTitle from 'components/estimateDetails/estimateTitle';
import LoadingBlock from 'components/loadingBlock';
import Team from 'components/team';
import SvgTargetIcon from 'icons/target';
import SvgCampaignIcon from 'icons/CampaignIcon';
import SvgCalendarIcon from 'icons/CalendarIcon';
import { formatDateAsQuarter } from 'shared/dateUtils';
import { font, shadows, colors } from 'shared/styles';
import {
  getProductsForEstimate,
  getApprovalsForEstimate,
  getTeamForEstimate,
  getRevision,
} from 'state/estimates/selectors';
import { getDisplayCurrency } from 'state/authentication/selectors';

const listItemStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  background-color: white;
  color: ${colors.text};
  padding: 20px;
  margin-bottom: 10px;
  box-shadow: ${shadows.text};
  min-width: 600px;
  text-decoration: none;
  border-radius: 5px;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;

  border-left: 7px solid ${({ status }) => ApprovalColors[status] || colors.loading};
  border-right: 7px solid ${({ status }) => ApprovalColors[status] || colors.loading};
`;

const EstimateListItemLink = styled(NavLink)`
  ${listItemStyles}

  &:hover {
    background-color: ${colors.itemHover};
  }
`;

const EstimateListItem = styled.div`
  ${listItemStyles}
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  .icon {
    display: block;
    margin-right: 10px;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  align-items: center;
  .icon {
    display: block;
    flex-shrink: 0;
    margin-right: 10px;
  }
`;

const Cost = styled.div`
  flex-shrink: 0;
  margin-left: auto;
  margin-right: 0px;
  font-weight: ${font.weights.bold};
  font-size: ${font.sizes.veryLarge};
  align-items: flex-end;
`;

const Status = styled(EstimateStatus)`
  margin-left: auto;
  box-sizing: border-box;
`;

const BoldEstimateTitle = styled(EstimateTitle)`
  font-size: ${font.sizes.large};
  font-weight: ${font.weights.bold};
`;

const TeamContainer = styled(Details)`
  margin-left: auto;
  margin-right: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  .userPicture,
  .initials {
    margin-right: 0px;
    margin-left: -10px;
  }
`;

const Metadata = styled.div`
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  display: block;
  min-width: 0;
`;

const StatusInformation = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Text = styled.div`
  display: block;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 20px;
`;

const EstimateIDTitle = styled.span`
  color: rgba(0, 0, 0, 0.5);
  padding-bottom: 4px;
  font-size: 1rem;
`;

const MainContent = styled.div`
  display: flex;
  flexdirection: row;
`;

const CampaignTitle = ({ humanId, name }) => `#${humanId} - ${name}`;

const EstimateCard = ({
  id,
  humanId,
  name,
  campaign,
  campaignId,
  mediaType,
  leadMarket,
  date,
  status,
  revision,
  brandName,
  brandCategory,
  productName,
  approvals,
  team,
  className,
  readOnly,
  Container,
  cost,
  target,
  displayCurrency,
}) => (
  <Container
    className={clsx('estimateCard', className)}
    to={`/estimates/${id}`}
    key={id}
    status={status}
    data-test="estimateListItem"
    readOnly={readOnly}
    target={target}
  >
    <EstimateIDTitle>#{humanId}</EstimateIDTitle>
    <MainContent>
      <Metadata>
        <Header>
          <BoldEstimateTitle mediaType={mediaType} name={name} />
        </Header>
        <Details>
          <SvgCampaignIcon className="icon" title="Campaign" />
          <Text data-test="campaign-name">
            {campaignId ? campaign ? <CampaignTitle {...campaign} /> : '...' : <i>No Campaign</i>}
          </Text>
        </Details>
        <Details>
          <SvgCalendarIcon className="icon" />
          <Text data-test="reporting-quarter">
            {date ? formatDateAsQuarter(date) : <i>not set</i>}
          </Text>

          {leadMarket && (
            <>
              <SvgTargetIcon className="icon" title="Target Market" />
              <Text data-test="lead-market">{convertUpperCaseToPretty(leadMarket)}</Text>
            </>
          )}

          {Boolean(revision) && (
            <>
              <Text data-test="revision">Rev. {revision}</Text>
            </>
          )}

          {brandName && (
            <Text data-test="products">
              {brandName} {productName} | {brandCategory}
            </Text>
          )}
        </Details>
      </Metadata>

      <StatusInformation>
        <Status status={status} approvals={approvals} />
        {team && (
          <TeamContainer data-test="estimate-team">
            <Team team={team} />
          </TeamContainer>
        )}
        <Cost data-test="estimate-cost">{formatAsCurrency(displayCurrency, cost)}</Cost>
      </StatusInformation>
    </MainContent>
  </Container>
);

const mapStateToProps = (state, { estimate, readOnly }) => {
  const {
    id,
    humanId,
    name,
    campaign,
    campaignId,
    mediaType,
    leadMarket,
    date,
    status,
    recommendedBidTotal,
    actualisedTotal,
  } = estimate;
  const { brandName, brandCategory, productName } =
    flow(getProductsForEstimate, first)(estimate) || {};

  const cost = status === EstimateStatuses.ACTUALISED ? actualisedTotal : recommendedBidTotal;

  return {
    id,
    humanId,
    name,
    campaign,
    campaignId,
    mediaType,
    leadMarket,
    date,
    status,
    approvals: getApprovalsForEstimate(estimate),
    brandName,
    brandCategory,
    productName,
    team: getTeamForEstimate(estimate),
    Container: readOnly ? EstimateListItem : EstimateListItemLink,
    cost,
    revision: getRevision(estimate),
    displayCurrency: getDisplayCurrency(state),
  };
};

export default connect(mapStateToProps)(EstimateCard);

export const LoadingBlockLong = styled(LoadingBlock)`
  width: 50%;
`;

export const LoadingIcon = styled(LoadingBlock)`
  height: 30px;
  width: 30px;
  margin-right: 10px;
`;

export const EstimateCardLoading = props => (
  <EstimateListItem className="estimateCard" data-test="estimateListItemLoading" {...props}>
    <Metadata>
      <Header>
        <LoadingBlockLong />
      </Header>
      <Details>
        <LoadingIcon />
        <LoadingBlock />
      </Details>
      <Details>
        <LoadingIcon />
        <LoadingBlock />
        <LoadingIcon />
        <LoadingBlock />
      </Details>
    </Metadata>
  </EstimateListItem>
);
