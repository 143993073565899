import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { keys, get } from 'lodash/fp';
import { Column, Table } from 'react-virtualized';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, TableCell } from '@material-ui/core';
import tableStyles from 'components/tables/styles';

const useStyles = makeStyles(theme => ({
  ...tableStyles(theme),
  nameCell: {
    display: 'flex',
  },
  userEmail: {
    fontSize: theme.typography.body2.fontSize,
  },
  userName: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
}));

const headerHeight = 48;
const rowHeight = 45 + 2 * 16;

const headerRenderer = ({
  label,
  columnIndex,
  classes,
  selectedAll,
  someSelected,
  onSelectAllItemsClick,
  columns,
  flexGrow,
}) => (
  <TableCell
    component="div"
    variant="head"
    className={clsx(classes.tableCell, classes.flexContainer, classes.noClick, {
      [classes.flexGrow]: columnIndex === 1 || flexGrow,
      [classes.flexCenter]: columns[columnIndex].align === 'center',
    })}
    style={{ height: headerHeight }}
  >
    {columnIndex === 0 ? (
      <Checkbox
        className={classes.checkbox}
        checked={someSelected}
        indeterminate={someSelected && !selectedAll}
        onChange={() => onSelectAllItemsClick()}
      />
    ) : (
      label
    )}
  </TableCell>
);

const getRowClassName = classes => ({ index }) =>
  clsx(classes.tableRow, classes.flexContainer, {
    [classes.tableRowHover]: index !== -1, // && onRowClick != null,
  });

const cellRenderer = ({ classes, columns, selectedItems, selectItem, align }) => ({
  dataKey,
  cellData,
  rowData,
  columnIndex,
}) => {
  let content = '';
  let columnClass;
  if (align === 'center') {
    columnClass = classes.flexCenter;
  }
  switch (dataKey) {
    case 'select':
      content = (
        <Checkbox
          className={classes.checkbox}
          checked={Boolean(selectedItems[rowData.id])}
          onChange={(e, val) => selectItem(rowData.id, val)}
          onClick={(e, val) => e.stopPropagation()}
          value={rowData.id}
        />
      );
      break;
    case 'name':
      content = <div className={classes.nameCell}>{rowData.name}</div>;
      break;
    case 'membersCount':
      content = rowData.members.length;
      break;
    case 'workspaces':
      content = get('workspaces.length', rowData);
      break;
    case 'createdAt':
      content = moment(rowData.createdAt).format('YYYY-MM-DD');
      break;
    default:
      content = cellData;
  }
  return (
    <TableCell
      component="div"
      variant="body"
      className={clsx(classes.tableCell, classes.flexContainer, classes.noClick, columnClass)}
      style={{ height: rowHeight }}
      align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
    >
      {content}
    </TableCell>
  );
};

const noRowsRenderer = ({ classes, alertMessage, noDataMessage }) => () => {
  return <div className={classes.noDataContainer}>{alertMessage || noDataMessage}</div>;
};

export default ({
  height,
  width,
  onRowClick,
  listRef,
  columns,
  data,
  selectItem,
  selectedAll,
  onSelectAllItemsClick,
  selectedItems,
  actions = {},
  alertMessage,
  noDataMessage,
}) => {
  const classes = useStyles();

  return (
    <Table
      ref={listRef}
      height={height}
      width={width}
      rowHeight={rowHeight}
      gridStyle={{ direction: 'inherit' }}
      headerHeight={headerHeight}
      headerClassName={classes.tableHeader}
      className={classes.table}
      rowCount={data.length}
      rowGetter={({ index }) => data[index]}
      rowClassName={getRowClassName(classes, columns)}
      noRowsRenderer={noRowsRenderer({ classes, alertMessage, noDataMessage })}
      onRowClick={onRowClick}
    >
      {columns.map(({ dataKey, width, label, flexGrow = 0, flexShrink = 0, align }, index) => {
        return (
          <Column
            key={dataKey}
            dataKey={dataKey}
            width={width}
            cellRenderer={cellRenderer({
              classes,
              actions,
              columns,
              selectedItems,
              selectItem,
              align,
            })}
            headerRenderer={headerProps =>
              headerRenderer({
                columnIndex: index,
                classes,
                label,
                selectedAll,
                onSelectAllItemsClick,
                someSelected: keys(selectedItems).length > 0,
                columns,
                align,
                flexGrow,
              })
            }
            className={clsx(classes.flexContainer, {
              [classes.flexGrow]: index === 1 || flexGrow,
            })}
            flexGrow={flexGrow}
            flexShrink={flexShrink}
          />
        );
      })}
    </Table>
  );
};
