var _objectSpread = require("/home/circleci/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread2");

var GenericConstants = require('./generic');

var DeliverablesContstants = require('./deliverables');

var HistoryContstants = require('./history');

var AccountSetting = require('./account-setting');

module.exports = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, DeliverablesContstants), GenericConstants), HistoryContstants), {}, {
  AccountSetting: AccountSetting
});