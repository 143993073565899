import React from 'react';

const SvgMailIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 28 23" {...props}>
    <defs>
      <filter
        id="mail-icon_svg__a"
        width="130.8%"
        height="138.1%"
        x="-15.4%"
        y="-19%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      d="M23.4 0H2.6C1.17 0 .013 1.176.013 2.613L0 18.289a2.614 2.614 0 0 0 2.6 2.613h20.8c1.43 0 2.6-1.176 2.6-2.613V2.613A2.614 2.614 0 0 0 23.4 0zm.051 18.353H2.549V5.098L13 11.725l10.451-6.627v13.255zM13 9.176L2.549 2.55h20.902L13 9.176z"
      filter="url(#mail-icon_svg__a)"
      transform="translate(1 1)"
    />
  </svg>
);

export default SvgMailIcon;
