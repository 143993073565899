import React from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import { Field, Formik } from 'formik';
import { connect } from 'react-redux';
import { createProduct, updateProduct } from 'state/products/actions';
import {
  getBrandNamesFilteredByAccount,
  getProductCategoriesFilteredByAccount,
} from 'state/products/selectors';
import * as Yup from 'yup';
import FormikAutocomplete from 'components/filters/formik.autocomplete';
import { TextField } from 'formik-material-ui';
import { TextField as TextInput } from '@material-ui/core';

const validationSchema = Yup.object().shape({
  productCategory: Yup.string().required('Required'),
  productBrand: Yup.string().required('Required'),
  productName: Yup.string().required('Required'),
});

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
  },

  field: {
    '&:first-child': {
      marginLeft: 0,
    },
    display: 'inline-flex',
    marginLeft: theme.spacing(2),
  },

  button: {
    marginLeft: theme.spacing(2),
    height: '37px',
  },

  formControl: {
    minWidth: 200,
    marginLeft: theme.spacing(2),
    background: 'white',
    '&:first-child': {
      marginLeft: 0,
    },
  },

  label: {
    background: 'white',
    padding: '0 4px',
  },
}));

const emptyProduct = {
  productCategory: '',
  productBrand: '',
  productName: '',
};

const CreateProductForm = ({
  categories,
  brands,
  accountId,
  createProduct,
  updateProduct,
  product,
  onCancel,
  onSuccess,
}) => {
  const styles = useStyles();

  const isEditing = typeof product !== 'undefined';

  const initialValues = isEditing
    ? {
        productCategory:
          {
            productCategoryName: product.productCategory.name,
            productCategoryId: product.productCategory.id,
          } || '',
        productBrand:
          {
            productBrandName: product.productBrand.name,
            productBrandId: product.productBrand.id,
          } || '',
        productName: product.productName || '',
      }
    : emptyProduct;

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const data = { accountId, ...values };
    const success = isEditing ? await updateProduct(product.id, data) : await createProduct(data);

    setSubmitting(false);

    if (success) {
      onSuccess ? onSuccess() : resetForm(initialValues);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Box display="flex" flexDirection={isEditing ? 'column' : 'row'} mb={isEditing ? 1 : 0}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={isEditing ? 'space-between' : undefined}
            >
              <Field name="productCategory">
                {({ field, meta, form }) => (
                  <FormikAutocomplete
                    field={field}
                    key="productCategoryKey"
                    form={form}
                    options={categories || null}
                    className={styles.field}
                    autocompleteProps={{
                      getOptionLabel: x => x.productCategoryName || x || '',
                      getOptionSelected: (option, value) =>
                        option.productCategoryName === value.productCategoryName ||
                        option.productCategoryName === value,
                      renderInput: params => {
                        return (
                          <TextInput
                            {...params}
                            {...field}
                            variant="outlined"
                            fullWidth
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                            label="Segment Name"
                            className={styles.formControl}
                            size="small"
                            margin="dense"
                          />
                        );
                      },
                    }}
                  />
                )}
              </Field>
              <Field name="productBrand">
                {({ field, meta, form }) => (
                  <FormikAutocomplete
                    field={field}
                    key="productBrandKey"
                    form={form}
                    options={brands}
                    className={styles.field}
                    autocompleteProps={{
                      getOptionLabel: x => x.productBrandName || x || '',
                      getOptionSelected: (option, value) =>
                        option.productBrandName === value.productBrandName ||
                        option.productBrandName === value,
                      renderInput: params => {
                        return (
                          <TextInput
                            {...params}
                            {...field}
                            variant="outlined"
                            fullWidth
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                            label="Brand Name"
                            className={styles.formControl}
                            size="small"
                            margin="dense"
                          />
                        );
                      },
                    }}
                  />
                )}
              </Field>
              <Field
                label="Product Name *"
                name="productName"
                size="small"
                variant="outlined"
                margin="dense"
                fullWidth={false}
                className={styles.field}
                component={TextField}
              />
            </Box>
            <Box
              ml={isEditing ? 'auto' : 0}
              display="flex"
              alignItems="center"
              mt={isEditing ? 2 : 0}
            >
              {isEditing && (
                <Button color="primary" className={styles.button} onClick={onCancel}>
                  Cancel
                </Button>
              )}
              <Button
                data-test="saveNewProduct"
                variant="contained"
                color="primary"
                type="submit"
                className={styles.button}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state, { accountId }) => ({
  brands: getBrandNamesFilteredByAccount(accountId)(state),
  categories: getProductCategoriesFilteredByAccount(accountId)(state),
});

const mapDispatchToProps = dispatch => ({
  createProduct: data => dispatch(createProduct(data)),
  updateProduct: (productId, data) => dispatch(updateProduct(productId, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateProductForm);
