import React from 'react';
import styled from 'styled-components';
import {
  lowerCase,
  upperFirst,
  words,
  flow,
  join,
  map,
  camelCase,
  get,
  values,
  sum,
} from 'lodash/fp';
import { colors, font, borderRadius } from 'shared/styles';
import { EstimateStatuses, EstimateApprovalStatuses } from 'cr-core/constants';
import SvgDraftIcon from 'icons/DraftIcon';
import SvgPendingIcon from 'icons/PendingIcon';
import SvgRequestChangesIcon from 'icons/RequestChangesIcon';
import SvgApproveIcon from 'icons/ApproveIcon';
import SvgCancelIcon from 'icons/CancelIcon';
import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

const ApprovalIcons = {
  [EstimateStatuses.DRAFT]: SvgDraftIcon,
  [EstimateStatuses.PENDING_APPROVAL]: SvgPendingIcon,
  [EstimateStatuses.APPROVED]: SvgApproveIcon,
  [EstimateStatuses.ACTUALISED]: SvgApproveIcon,
  [EstimateApprovalStatuses.CHANGES_REQUESTED]: SvgRequestChangesIcon,
  [EstimateStatuses.CANCELLED]: SvgCancelIcon,
};

export const ApprovalColors = {
  [EstimateApprovalStatuses.APPROVED]: colors.approve,
  [EstimateStatuses.ACTUALISED]: colors.actualised,
  [EstimateApprovalStatuses.PENDING_APPROVAL]: colors.pendingApproval,
  [EstimateApprovalStatuses.CHANGES_REQUESTED]: colors.requestChanges,
  [EstimateStatuses.DRAFT]: colors.draft,
  [EstimateStatuses.CANCELLED]: colors.cancelled,
};

const Container = styled.div`
  position: relative;
  color: ${colors.text};
  display: flex;
  flex-shrink: 0;
  border: 1px solid #9e9e9e;
  border-radius: ${borderRadius};

  .statusText {
    font-size: ${font.sizes.large};
    display: inline-block;
    line-height: 25px;
  }
  .icon {
    margin-right: 10px;
  }

  &.loading {
    background-color: transparent;
    border: none;
    border-radius: none;
  }
`;

const ContentsContainer = styled.div`
  position: relative;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.compact {
    padding: 5px;
  }

  .icon {
    font-size: 25px;
  }
`;

const StatusSegment = styled.div`
  width: ${({ percentage }) => `${100 * percentage}%`};
  height: 100%;
  background-color: ${({ color }) => color};
  display: inline-block;
`;

const formatSummaryText = flow(lowerCase, words, map(upperFirst), join(' '));

const ColouredBarContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const EstimateStatus = ({ status, approvals = {}, compact, className }) => {
  const Icon = ApprovalIcons[status] || {};
  const total = flow(values, sum)(approvals);
  const isLoading = status === EstimateApprovalStatuses.LOADING;

  const percentageApproved = get(EstimateApprovalStatuses.APPROVED, approvals) / total;
  const percentagePending = get(EstimateApprovalStatuses.PENDING_APPROVAL, approvals) / total;
  const percentageChangesRequested =
    get(EstimateApprovalStatuses.CHANGES_REQUESTED, approvals) / total;

  return (
    <Container
      className={clsx(className, camelCase(status), { loading: isLoading })}
      data-test="estimate-status"
    >
      <ColouredBarContainer>
        {status === EstimateStatuses.DRAFT && (
          <StatusSegment percentage={1} color={`${ApprovalColors[EstimateStatuses.DRAFT]}44`} />
        )}
        {status === EstimateStatuses.CANCELLED && (
          <StatusSegment percentage={1} color={`${ApprovalColors[EstimateStatuses.CANCELLED]}44`} />
        )}
        {status === EstimateStatuses.ACTUALISED && (
          <StatusSegment
            percentage={1}
            color={`${ApprovalColors[EstimateStatuses.ACTUALISED]}44`}
          />
        )}
        {[EstimateStatuses.PENDING_APPROVAL, EstimateStatuses.APPROVED].includes(status) && (
          <>
            {percentageApproved > 0 && (
              <StatusSegment
                percentage={percentageApproved}
                color={`${ApprovalColors[EstimateApprovalStatuses.APPROVED]}44`}
              />
            )}
            {percentagePending > 0 && (
              <StatusSegment
                percentage={percentagePending}
                color={`${ApprovalColors[EstimateApprovalStatuses.PENDING_APPROVAL]}44`}
              />
            )}
            {percentageChangesRequested > 0 && (
              <StatusSegment
                percentage={percentageChangesRequested}
                color={`${ApprovalColors[EstimateApprovalStatuses.CHANGES_REQUESTED]}44`}
              />
            )}
          </>
        )}
      </ColouredBarContainer>
      {!isLoading && (
        <ContentsContainer className={compact ? 'compact' : ''}>
          <Icon className="icon" />
          {!compact && <div className="statusText">{formatSummaryText(status)}</div>}
        </ContentsContainer>
      )}
      {isLoading && <CircularProgress />}
    </Container>
  );
};

export default EstimateStatus;
