import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { getFormValues } from 'redux-form';
import { AutoSizer } from 'react-virtualized';
import { keys, unset, flow, map, fromPairs, set, filter } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { getUser } from 'state/authentication/selectors';
import { openSidePanel } from 'state/ui/actions';
import { isSidePanelOpen } from 'state/ui/selectors';
import { fetchTeams, deleteTeam, resetTeams } from 'state/teams/actions';
import { getTeams, getTeamsPagination } from 'state/teams/selectors';
import { isResourceLoading } from 'state/resourceStatus/selectors';
import ConfirmationModal from 'components/modals/confirmationModal';
import TeamsTable from './teamsListTable';

export const formName = 'teamFilters';

const DELETE_TEAM_ACTION = 'DELETE_TEAM_ACTION';

const columns = [
  {
    label: '',
    dataKey: 'select',
    width: 40,
    compact: true,
  },
  {
    label: 'Name',
    dataKey: 'name',
    flexGrow: 1,
    compact: true,
  },
  {
    label: 'Users',
    dataKey: 'membersCount',
    width: 120,
    compact: true,
    align: 'center',
  },
  {
    label: 'Workspaces',
    dataKey: 'workspaces',
    width: 120,
    compact: true,
    align: 'center',
  },
  {
    label: 'Created At',
    dataKey: 'createdAt',
    width: 120,
    align: 'center',
  },
];

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    height: `calc(100% - ${theme.typography.h6.fontSize} - ${theme.spacing(2)}px)`,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const listRef = React.createRef();

const TeamsList = ({
  user,
  teams,
  fetchTeams,
  resetTeams,
  deleteTeam,
  openDetails,
  openSidePanel,
  sidePanelOpened,
  history,
  filters,
  isLoading,
}) => {
  const classes = useStyles();
  const userColumns = sidePanelOpened ? filter({ compact: true }, columns) : columns;
  const [openModal, setOpenModal] = useState(false);
  const [modalData] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const selectedAll = keys(selectedItems).length === teams.length;
  const selectItem = (item, val) => {
    if (val) {
      setSelectedItems(set(item, true, selectedItems));
    } else {
      setSelectedItems(unset(item, selectedItems));
    }
  };

  useEffect(() => {
    resetTeams();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filters.accountId) {
      fetchTeams(filters);
    }
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseModal = val => {
    setOpenModal(false);
    if (val) {
      handleConfirmModal();
    }
  };

  const handleConfirmModal = async () => {
    if (modalData.action === DELETE_TEAM_ACTION) {
      await deleteTeam(modalData.team.id, filters.accountId);
      await fetchTeams(filters.accountId);
    }
  };

  const onSelectAllItemsClick = () => {
    if (!keys(selectedItems).length) {
      setSelectedItems(
        flow(
          map(({ id }) => [id, true]),
          fromPairs
        )(teams)
      );
    } else {
      setSelectedItems({});
    }
  };

  const onRowClick = ({ rowData }) => {
    openSidePanel({ view: 'TEAM_DETAILS', team: rowData });
    // history.push(`/users/${rowData.id}`);
  };

  return (
    <Paper className={classes.paper}>
      <AutoSizer>
        {({ height, width }) => (
          <TeamsTable
            height={height}
            width={width}
            listRef={listRef}
            columns={userColumns}
            data={filters.accountId ? teams : []}
            onRowClick={onRowClick}
            selectItem={selectItem}
            selectedAll={selectedAll}
            onSelectAllItemsClick={onSelectAllItemsClick}
            selectedItems={selectedItems}
            alertMessage={
              Boolean(filters.accountId)
                ? isLoading
                  ? 'Loading...'
                  : null
                : 'Please select an account'
            }
            noDataMessage="No results for the selected filters"
          />
        )}
      </AutoSizer>

      <ConfirmationModal
        open={openModal}
        handleClose={handleCloseModal}
        title={modalData.title}
        message={modalData.message}
        actionLabel={modalData.actionLabel}
      />
    </Paper>
  );
};

const mapStateToProps = state => {
  const filters = getFormValues('teamFilters')(state) || {};
  return {
    user: getUser(state),
    teams: getTeams(state),
    pagination: getTeamsPagination(state),
    sidePanelOpened: isSidePanelOpen(state),
    isLoading: isResourceLoading('teams')(state),
    filters,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchTeams: accountId => dispatch(fetchTeams(accountId)),
  resetTeams: () => dispatch(resetTeams()),
  deleteTeam: (team, accountId) => dispatch(deleteTeam(team.id, accountId)),
  openSidePanel: data => dispatch(openSidePanel(data)),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TeamsList);
