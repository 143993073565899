import React, { useState, useEffect, useCallback, useRef } from 'react';
import clsx from 'clsx';
import { get, noop, debounce } from 'lodash/fp';
import { Field } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment, CircularProgress } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  search: {
    width: '100%',
    backgroundColor: 'white',
    marginBottom: 0,
    marginTop: 0,
  },
  icon: {
    opacity: 0.6,
  },
  progressIcon: {
    color: theme.palette.grey[400],
  },
}));

export const SearchField = ({ input, className, isLoading, onChange = noop }) => {
  const [inputSearch, setInputSearch] = useState('');
  const onChangeCallback = useRef(onChange);

  useEffect(() => {
    onChangeCallback.current = onChange;
  }, [onChange]);

  const debounceFn = useCallback(
    debounce(500, inputValue => {
      if (get('onChange', input)) {
        input.onChange(inputValue);
      }
      onChangeCallback.current(inputValue);
    }),
    []
  );

  const handleChange = e => {
    setInputSearch(e.target.value);
    debounceFn(e.target.value);
  };

  const classes = useStyles();
  return (
    <TextField
      className={clsx(classes.search, className)}
      label="Search"
      type="search"
      margin="dense"
      variant="outlined"
      name="search"
      id="search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className={classes.icon} />
          </InputAdornment>
        ),
        endAdornment: isLoading && (
          <InputAdornment position="start">
            <CircularProgress className={classes.progressIcon} size={20} />
          </InputAdornment>
        ),
      }}
      {...input}
      value={inputSearch}
      onChange={e => handleChange(e)}
      onBlur={noop}
    />
  );
};

export default ({ className, onChange }) => {
  return <Field name="search" component={SearchField} className={className} onChange={onChange} />;
};
