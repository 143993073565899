import React from 'react';
import { Box } from '@material-ui/core';
import { Formik } from 'formik';
import ReportFiltersForm, { EMPTY_FILTERS } from './ReportFiltersForm';
import { noop } from 'utils';
import { ReportHeader } from '../components';

const initialValues = {
  ...EMPTY_FILTERS,
};

const TopLineCustomerReport = () => {
  return (
    <Box p={2}>
      <Box mb={3}>
        <ReportHeader title="Top Line Custom Report" />
      </Box>
      <Formik initialValues={initialValues} onSubmit={noop}>
        <ReportFiltersForm />
      </Formik>
    </Box>
  );
};

export default TopLineCustomerReport;
