import { get, flow, map } from 'lodash/fp';
import { createSelector } from 'reselect';
import { getCampaignsById } from 'state/campaigns/selectors';
import { setRegion, setCampaign } from 'state/estimates/selectors';

export const getCostCategoriesReport = createSelector(
  get('reports.costCategories'),
  getCampaignsById,
  (estimates, campaigns) =>
    flow(map(estimate => flow(setRegion, setCampaign(campaigns))(estimate)))(estimates)
);

export const getDashboardData = get('reports.dashboard');
