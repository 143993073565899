import { fetch } from 'state/fetchMiddleware';
import { showSuccessNotification, showErrorNotification } from 'state/notifications/actions';

export const FETCH_ACCOUNTS_REQUEST = 'accounts/FETCH_ACCOUNTS_REQUEST';
export const FETCH_ACCOUNTS_SUCCESS = 'accounts/FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_ERROR = 'accounts/FETCH_ACCOUNTS_ERROR';

export const FETCH_AGENCIES_REQUEST = 'accounts/FETCH_AGENCIES_REQUEST';
export const FETCH_AGENCIES_SUCCESS = 'accounts/FETCH_AGENCIES_SUCCESS';
export const FETCH_AGENCIES_ERROR = 'accounts/FETCH_AGENCIES_ERROR';

export const ACCOUNT_UPDATE_REQUEST = 'account/ACCOUNT_UPDATE_REQUEST';
export const ACCOUNT_UPDATE_SUCCESS = 'account/ACCOUNT_UPDATE_SUCCESS';
export const ACCOUNT_UPDATE_ERROR = 'account/ACCOUNT_UPDATE_ERROR';

export const fetchAccounts = filters => async dispatch => {
  dispatch({ type: FETCH_ACCOUNTS_REQUEST });
  try {
    const { data: accounts } = await dispatch(fetch('/v2/accounts', { params: { ...filters } }));
    dispatch({ type: FETCH_ACCOUNTS_SUCCESS, accounts });
  } catch (error) {
    dispatch({ type: FETCH_ACCOUNTS_ERROR, error });
  }
};

export const fetchAgencies = () => async dispatch => {
  dispatch({ type: FETCH_AGENCIES_REQUEST });
  try {
    const { data: agencies } = await dispatch(fetch('/v2/accounts', { params: { agency: true } }));
    dispatch({ type: FETCH_AGENCIES_SUCCESS, agencies });
  } catch (error) {
    dispatch({ type: FETCH_AGENCIES_ERROR, error });
  }
};

export const updateAccount = ({
  accountId,
  onlyAdminInviteUsers,
  usersCreateNewLineItems,
}) => async dispatch => {
  dispatch({
    type: ACCOUNT_UPDATE_REQUEST,
    id: accountId,
    onlyAdminInviteUsers,
    usersCreateNewLineItems,
  });
  try {
    const { data: account } = await dispatch(
      fetch(`/v2/accounts/${accountId}`, {
        method: 'PUT',
        data: { onlyAdminInviteUsers, usersCreateNewLineItems },
      })
    );
    dispatch({ type: ACCOUNT_UPDATE_SUCCESS, account });
    dispatch(fetchAccounts());
  } catch (err) {
    console.log(err);
    dispatch({
      type: ACCOUNT_UPDATE_ERROR,
      id: accountId,
      onlyAdminInviteUsers,
      usersCreateNewLineItems,
    });
  }
};

export const createAccount = ({ name, agency }) => async dispatch => {
  try {
    const account = await dispatch(
      fetch('/v2/accounts', {
        method: 'POST',
        data: { name, agency },
      })
    );
    dispatch(fetchAccounts());
    dispatch(showSuccessNotification({ message: `Account "${name}" created` }));
    return account;
  } catch (error) {
    dispatch(showErrorNotification({ message: error.toString() }));
  }
};
