import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { find, keys, unset, flow, map, fromPairs, set, reject, filter } from 'lodash/fp';
import { getUserName } from 'cr-core/userUtils';
import { getUser } from 'state/authentication/selectors';
import { fetchUsers, blockUser, deleteUser } from 'state/users/actions';
import { getFilteredUsers, getUsersPagination } from 'state/users/selectors';
import { getFormValues } from 'redux-form';
import { AutoSizer } from 'react-virtualized';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { UserStatuses } from 'cr-core/constants';
import { openSidePanel } from 'state/ui/actions';
import { isSidePanelOpen } from 'state/ui/selectors';
import { isResourceLoading } from 'state/resourceStatus/selectors';
import UsersTable from './usersListTable';
import ConfirmationModal from 'components/modals/confirmationModal';

export const formName = 'userFilters';

const DELETE_USER_ACTION = 'DELETE_USER_ACTION';
const BLOCK_USER_ACTION = 'BLOCK_USER_ACTION';

const columns = [
  {
    label: '',
    dataKey: 'select',
    width: 40,
    compact: true,
  },
  {
    label: 'Name',
    dataKey: 'name',
    flexGrow: 1,
    // width: 'auto',
    compact: true,
  },
  {
    label: 'Workspaces',
    dataKey: 'workspaces',
    width: 120,
    admin: true,
    compact: true,
    align: 'center',
  },
  {
    label: 'Teams',
    dataKey: 'teams',
    width: 120,
    admin: true,
    compact: true,
    align: 'center',
  },
  {
    label: 'Role',
    dataKey: 'isAdmin',
    width: 120,
    align: 'center',
  },
  {
    label: 'Status',
    dataKey: 'status',
    width: 120,
    align: 'center',
  },
  {
    label: 'Created At',
    dataKey: 'createdAt',
    width: 120,
    align: 'center',
  },
  {
    label: 'Actions',
    dataKey: 'actions',
    width: 120,
    align: 'center',
    admin: true,
  },
];

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    height: `calc(100% - ${theme.typography.h6.fontSize} - ${theme.spacing(2)}px)`,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const listRef = React.createRef();

const UserList = ({
  user,
  users,
  fetchUsers,
  blockUser,
  deleteUser,
  openDetails,
  openSidePanel,
  sidePanelOpened,
  filters,
  history,
  isLoading,
}) => {
  const classes = useStyles();
  let userColumns = user.isAdmin ? columns : reject({ admin: true }, columns);
  userColumns = sidePanelOpened ? filter({ compact: true }, columns) : columns;
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const selectedAll = keys(selectedItems).length === users.length;
  const selectItem = (item, val) => {
    if (val) {
      setSelectedItems(set(item, true, selectedItems));
    } else {
      setSelectedItems(unset(item, selectedItems));
    }
  };

  useEffect(() => {
    if (filters.accountId) {
      fetchUsers(filters);
    }
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseModal = val => {
    setOpenModal(false);
    if (val) {
      handleConfirmModal();
    }
  };

  const handleConfirmModal = async () => {
    if (modalData.action === DELETE_USER_ACTION) {
      await deleteUser(modalData.user.id);
      await fetchUsers(filters);
    }
    if (modalData.action === BLOCK_USER_ACTION) {
      const isBlocked = modalData.user.status === UserStatuses.BLOCKED;
      await blockUser(modalData.user.id, !isBlocked);
    }
  };

  const onSelectAllItemsClick = () => {
    if (!keys(selectedItems).length) {
      setSelectedItems(
        flow(
          map(({ id }) => [id, true]),
          fromPairs
        )(users)
      );
    } else {
      setSelectedItems({});
    }
  };

  const deleteUserWithConfirmation = userId => {
    const user = find({ id: userId }, users);
    setOpenModal(true);
    setModalData({
      action: DELETE_USER_ACTION,
      user,
      title: 'Do you want to delete the user?',
      message: `${
        getUserName(user) || user.email
      } user will be permanentely deleted from the system. All actions performed by the user will be still shown in the history (eg. estimates approved)`,
      actionLabel: 'delete',
    });
  };

  const blockUserWithConfirmation = userId => {
    const user = find({ id: userId }, users);
    setOpenModal(true);
    const isBlocked = user.status === UserStatuses.BLOCKED;
    const title = `Do you want to ${isBlocked ? 'unblock' : 'block'} the user?`;
    const message = isBlocked
      ? `${getUserName(user) || user.email} user will be blocked. The user won't be able to login.`
      : `${getUserName(user) || user.email} user will be blocked. The user won't be able to login.`;

    setModalData({
      action: BLOCK_USER_ACTION,
      user,
      title,
      message,
      actionLabel: isBlocked ? 'unblock' : 'block',
    });
  };

  const onRowClick = ({ rowData }) => {
    openSidePanel({ view: 'USER_DETAILS', user: rowData });
    // history.push(`/users/${rowData.id}`);
  };

  return (
    <Paper className={classes.paper}>
      <AutoSizer>
        {({ height, width }) => (
          <UsersTable
            height={height}
            width={width}
            listRef={listRef}
            columns={userColumns}
            users={users}
            onRowClick={onRowClick}
            selectItem={selectItem}
            selectedAll={selectedAll}
            onSelectAllItemsClick={onSelectAllItemsClick}
            selectedItems={selectedItems}
            blockUser={blockUserWithConfirmation}
            deleteUser={deleteUserWithConfirmation}
            alertMessage={
              Boolean(filters.accountId)
                ? isLoading
                  ? 'Loading...'
                  : null
                : 'Please select an account'
            }
            noDataMessage="No results for the selected filters"
          />
        )}
      </AutoSizer>

      <ConfirmationModal
        open={openModal}
        handleClose={handleCloseModal}
        title={modalData.title}
        message={modalData.message}
        actionLabel={modalData.actionLabel}
      />
    </Paper>
  );
};

const mapStateToProps = state => ({
  user: getUser(state),
  users: getFilteredUsers(state),
  filters: getFormValues('userFilters')(state) || {},
  pagination: getUsersPagination(state),
  sidePanelOpened: isSidePanelOpen(state),
  isLoading: isResourceLoading('users')(state),
});

const mapDispatchToProps = dispatch => ({
  fetchUsers: accountId => dispatch(fetchUsers(accountId)),
  blockUser: async (userId, block) => {
    await dispatch(blockUser(userId, block));
    listRef.current.forceUpdate();
  },
  deleteUser: userId => dispatch(deleteUser(userId)),
  openSidePanel: data => dispatch(openSidePanel(data)),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(UserList);
