import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flexGrow: 1,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  submitButton: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  info: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  passwordGuidelineLink: theme.typography.body2,
}));

const checkPasswordStrength = password => {
  const strongRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$£%^&*(),.?"\':{}|<>+-_.:,;])(?=.{8,})'
  );
  return strongRegex.test(password);
};

const SignupForm = ({ onSubmit }) => {
  const classes = useStyles();
  const initialValues = {
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirmation: '',
  };
  const validate = ({ password, passwordConfirmation }) => {
    const errors = {};
    if (password !== passwordConfirmation) {
      errors.passwordConfirmation = "Password don't match";
    }
    if (!checkPasswordStrength(password)) {
      errors.password =
        'Password too weak. It should be at least 8 characters long and contains upper case and lower case characters, a number and a symbol';
    }
    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ values, isSubmitting, handleChange, handleBlur }) => (
        <Form className={classes.form}>
          <Field
            label="First Name"
            variant="outlined"
            type="text"
            name="firstName"
            className={classes.textField}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            required
            component={TextField}
          />
          <Field
            label="Last Name"
            variant="outlined"
            type="text"
            name="lastName"
            className={classes.textField}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            required
            component={TextField}
          />
          <NavLink to="/password-setting-guidelines" className={classes.passwordGuidelineLink}>
            Password setting guidelines
          </NavLink>
          <Field
            label="Password"
            variant="outlined"
            type="password"
            name="password"
            className={classes.textField}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            required
            component={TextField}
          />
          <Field
            label="Confirm Password"
            variant="outlined"
            type="password"
            name="passwordConfirmation"
            className={classes.textField}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.passwordConfirmation}
            required
            component={TextField}
          />
          <Button
            type="submit"
            variant="contained"
            className={classes.submitButton}
            size="large"
            color="primary"
            disabled={isSubmitting}
          >
            Sign up
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default SignupForm;
