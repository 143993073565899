import { useMutation, useQueryClient } from 'react-query';
import SupplierService from 'services/SupplierService';

const useCreateSupplier = () => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(SupplierService.createSupplier, {
    onSuccess: (_data, { accountId }) => {
      queryClient.invalidateQueries(['account', accountId, 'suppliers'], { exact: true });
    },
  });

  return { createSupplier: mutate, ...rest };
};

export default useCreateSupplier;
