import React from 'react';
import clsx from 'clsx';
import { map, get } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography, Grid } from '@material-ui/core';
import {
  AdditionalDeliverableLabels,
  AdditionalDeliverablesGroups,
} from 'cr-core/constants/marsDeliverables';

const useStyles = makeStyles(theme => ({
  header: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    ...theme.typography.body2,
  },
  group: {
    marginBottom: theme.spacing(2),
  },
  groupHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(0.5),
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
  },
  groupHeaderTitle: {
    lineHeight: 1,
    marginLeft: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
  },
  subGroup: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  summary: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    // '&:hover': {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
  rowSelected: {
    backgroundColor: theme.palette.action.hover,
  },
  rowTitle: {
    marginLeft: theme.spacing(2),
  },
  inputLabel: {
    fontSize: theme.typography.caption.fontSize,
    width: 40 + theme.spacing(1),
    textAlign: 'center',
  },
  inputContainer: {
    width: 40,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button,&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  input: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    fontSize: theme.typography.body2.fontSize,
    textAlign: 'right',
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button,&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

const Group = ({ name, deliverables, setDeliverable, additionalDeliverablesObj, key }) => {
  const classes = useStyles();
  return (
    <div className={classes.group} key={name}>
      <div className={classes.groupHeader}>
        <div className={classes.inputLabel}>#</div>
        <div className={classes.groupHeaderTitle}>{name}</div>
      </div>
      {map(
        type => (
          <div
            className={clsx(classes.row, {
              [classes.rowSelected]: get(type, additionalDeliverablesObj),
            })}
            key={type}
          >
            <div>
              <TextField
                className={classes.inputContainer}
                inputProps={{ className: classes.input }}
                name={type.toLowerCase()}
                type="number"
                min={0}
                max={999}
                variant="outlined"
                size="small"
                value={get(type, additionalDeliverablesObj)}
                onChange={setDeliverable(type)}
              />
            </div>
            <div className={classes.rowTitle}>{AdditionalDeliverableLabels[type]}</div>
          </div>
        ),
        deliverables
      )}
    </div>
  );
};

export default ({ additionalDeliverablesObj, setDeliverable }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.header}>
        <Typography variant="h6">Additional Deliverables</Typography>
        All other assets which you are delivering as part of this estimate - additionally to your
        originals, revisions, adaptations and cutdown versions captured above.
      </div>
      <Grid container spacing={10}>
        <Grid item xs={4}>
          <Group
            {...AdditionalDeliverablesGroups[0]}
            additionalDeliverablesObj={additionalDeliverablesObj}
            setDeliverable={setDeliverable}
          />
        </Grid>
        <Grid item xs={4}>
          <Group
            {...AdditionalDeliverablesGroups[1]}
            additionalDeliverablesObj={additionalDeliverablesObj}
            setDeliverable={setDeliverable}
          />
          <Group
            {...AdditionalDeliverablesGroups[3]}
            additionalDeliverablesObj={additionalDeliverablesObj}
            setDeliverable={setDeliverable}
          />
        </Grid>
        <Grid item xs={4}>
          <Group
            {...AdditionalDeliverablesGroups[2]}
            additionalDeliverablesObj={additionalDeliverablesObj}
            setDeliverable={setDeliverable}
          />
          <Group
            {...AdditionalDeliverablesGroups[4]}
            additionalDeliverablesObj={additionalDeliverablesObj}
            setDeliverable={setDeliverable}
          />
        </Grid>
      </Grid>
    </div>
  );
};
