import React from 'react';
import { connect } from 'react-redux';
import { flow, get, map, reject, flatten } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { getDisplayCurrency } from 'state/authentication/selectors';
import { getCostCategoriesReport } from 'state/reports/selectors';
import { Message, ReportHeader } from '../components';
import Table from './table';
import Chart from './chart';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
  estimateCard: {
    marginBottom: 0,
    borderRadius: 0,
  },
}));

const Report = ({ estimates, displayCurrency }) => {
  const classes = useStyles();
  const title = 'Cost Categories Report';
  const data = flow(
    reject(estimate => !get('lineItems.length', estimate)),
    map(estimate =>
      map(
        ({
          lineItemName,
          costCategoryId,
          costCategoryName,
          amount,
          hours,
          value,
          replacedBy,
          replacedByName,
          type,
          text,
        }) => ({
          lineItemName: lineItemName,
          replacedBy: replacedBy,
          replacedByName: replacedByName,
          costCategoryId,
          costCategoryName,
          amount,
          hours,
          type,
          value,
          text,
          costs: { highest: amount, lowest: amount },
          ...estimate,
        }),
        estimate.lineItems
      )
    ),
    flatten
  )(estimates);

  return (
    <div className={classes.container}>
      <ReportHeader title={title} />
      {estimates && !estimates.length && <Message>No data for the selected filters</Message>}
      {estimates && Boolean(estimates.length) && (
        <>
          <Chart data={data} />
          <Table data={data} title={title} />
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  estimates: getCostCategoriesReport(state),
  displayCurrency: getDisplayCurrency(state),
});

export default connect(mapStateToProps)(Report);
