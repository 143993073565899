import { useMemo } from 'react';

const useEmptyLineItemsFilter = (estimate, lineItemIds) => {
  const ids = useMemo(
    () =>
      (estimate || {}).status === 'DRAFT'
        ? lineItemIds
        : (lineItemIds || []).filter(lineItemId => {
            const values = ((estimate || {}).bidValues || []).filter(
              ({ lineItemNameId }) => lineItemNameId === lineItemId
            );

            const isEmpty =
              !values.length ||
              values.every(({ value, type }) => (type === 'cost' ? !value.amount : !value));

            return !isEmpty;
          }),
    [estimate, lineItemIds]
  );

  return ids;
};

export default useEmptyLineItemsFilter;
