import { LOCATION_CHANGE } from 'connected-react-router';
import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  AUTH_CHECK_COMPLETE,
  LOGIN_REQUEST,
  AUTH_ERROR,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
  VERIFY_SIGNUP_CODE_SUCCESS,
} from './actions';
import { USER_UPDATE_SUCCESS } from 'state/users/actions';
import { flow, set } from 'lodash/fp';

const defaultState = {
  isAuthenticated: false,
  authChecked: false,
  isLoggingIn: false,
  hasAttemptedLogin: false,
  resetPasswordSent: false,
  user: {},
  signupCodeVerified: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case AUTH_CHECK_COMPLETE:
      return set('authChecked', true, state);
    case LOGIN_SUCCESS:
      return flow(
        set('isAuthenticated', true),
        set('authChecked', true),
        set('isLoggingIn', false),
        set('user', action.profile)
      )(state);
    case LOGOUT_SUCCESS:
      return set('authChecked', true, defaultState);
    case LOGIN_REQUEST:
      return set('isLoggingIn', true, state);
    case AUTH_ERROR:
      return flow(
        set('isLoggingIn', false),
        set('hasAttemptedLogin', true)
      )(state);
    case PASSWORD_RESET_SUCCESS:
    case PASSWORD_RESET_ERROR:
      return set('resetPasswordSent', true, state);

    case USER_UPDATE_SUCCESS:
      if (action.user.id === state.user.id) {
        return set('user', action.user, state);
      }
      return state;

    case VERIFY_SIGNUP_CODE_SUCCESS:
      return set('signupCodeVerified', true, state);

    case LOCATION_CHANGE:
      return {
        ...state,
        resetPasswordSent: false,
        signupCodeVerified: false,
      };
    default:
      return state;
  }
};
