import { compose, withState, withHandlers, withProps, lifecycle } from 'recompose';

const withEditableness = compose(
  withState('_isEditing', 'setIsEditing', ({ isEditing = false }) => isEditing),
  withHandlers({
    edit: ({ setIsEditing }) => () => setIsEditing(true),
    save: ({ setIsEditing }) => () => setIsEditing(false),
    cancel: ({ setIsEditing }) => () => setIsEditing(false),
    onKeyUp: ({ setIsEditing }) => ({ key }) => setIsEditing(key !== 'Escape'),
  }),
  lifecycle({
    componentDidUpdate: ({ isEditing, _isEditing, edit }) => {
      if (isEditing && !_isEditing) {
        edit();
      }
    },
  }),
  withProps(({ _isEditing }) => {
    return {
      isEditing: _isEditing,
    };
  }),
);

export default withEditableness;
