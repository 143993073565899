var _require = require('lodash/fp'),
    update = _require.update,
    flow = _require.flow,
    get = _require.get,
    findIndex = _require.findIndex;

var _require2 = require('./utils'),
    move = _require2.move;

var _require3 = require('./mediaAdvertisingTypes'),
    mediaAdvertisingTypes = _require3.mediaAdvertisingTypes;

var updateLineItemOrderOnEstimate = function updateLineItemOrderOnEstimate(costCategoryId, lineItemNameId, position) {
  return function (estimate) {
    var costCategoryIndex = flow(get('lineItemGroups'), findIndex({
      id: costCategoryId
    }))(estimate);
    var lineItemIndex = flow(get("lineItemGroups[".concat(costCategoryIndex, "].lineItemNames")), findIndex({
      id: lineItemNameId
    }))(estimate);
    return update("lineItemGroups[".concat(costCategoryIndex, "].lineItemNames"), move(lineItemIndex, position), estimate);
  };
};

var updateCostCategoryOrderOnEstimate = function updateCostCategoryOrderOnEstimate(costCategoryId, position) {
  return function (estimate) {
    var costCategoryIndex = flow(get('lineItemGroups'), findIndex({
      id: costCategoryId
    }))(estimate);
    return update('lineItemGroups', move(costCategoryIndex, position), estimate);
  };
};

var getMediaAdvertisingType = function getMediaAdvertisingType(mediaType) {
  var estimateType = mediaAdvertisingTypes.find(function (mediaAdvertisingType) {
    return mediaAdvertisingType.typeList.includes(mediaType);
  });
  return estimateType ? estimateType.type : 'N/A';
};

module.exports = {
  updateLineItemOrderOnEstimate: updateLineItemOrderOnEstimate,
  updateCostCategoryOrderOnEstimate: updateCostCategoryOrderOnEstimate,
  getMediaAdvertisingType: getMediaAdvertisingType
};