import styled from 'styled-components';
import { colors, font } from 'shared/styles';

const TextField = styled.input`
  width: 100%;
  line-height: 36px;
  padding: 0 18px;
  appearance: none;
  outline: none;
  border: 1px solid lightgrey;
  border-radius: 18px;
  background-color: white;
  font-size: ${font.sizes.formInput};
  color: ${colors.text}
  letter-spacing: -0.4px;
  box-sizing: border-box;
  box-shadow: none;

  ::placeholder {
    color: ${colors.textInputPlaceholder};
  }
`;

export default TextField;
