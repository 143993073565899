import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { keys, flow, map, get, flatten, uniqBy } from 'lodash/fp';
import { getUserName } from 'cr-core/userUtils';
import { Column, Table } from 'react-virtualized';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Checkbox, IconButton, TableCell, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { UserStatuses } from 'cr-core/constants';
import tableStyles from 'components/tables/styles';

const useStyles = makeStyles(theme => ({
  ...tableStyles(theme),
  nameCell: {
    display: 'flex',
  },
  userEmail: {
    fontSize: theme.typography.body2.fontSize,
  },
  userName: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
}));

const headerHeight = 48;
const rowHeight = 45 + 2 * 16;

const headerRenderer = ({
  label,
  columnIndex,
  classes,
  selectedAll,
  someSelected,
  onSelectAllItemsClick,
  columns,
}) => (
  <TableCell
    component="div"
    variant="head"
    className={clsx(classes.tableCell, classes.flexContainer, classes.noClick, {
      [classes.flexGrow]: columnIndex === 1,
      [classes.flexCenter]: columns[columnIndex].align === 'center',
    })}
    style={{ height: headerHeight }}
  >
    {columnIndex === 0 ? (
      <Checkbox
        className={classes.checkbox}
        checked={someSelected}
        indeterminate={someSelected && !selectedAll}
        onChange={() => onSelectAllItemsClick()}
      />
    ) : (
      label
    )}
  </TableCell>
);

const getRowClassName = classes => ({ index }) =>
  clsx(classes.tableRow, classes.flexContainer, {
    [classes.tableRowHover]: index !== -1, // && onRowClick != null,
  });

const cellRenderer = ({ classes, columns, selectedItems, selectItem, blockUser, deleteUser }) => ({
  dataKey,
  cellData,
  rowData,
  columnIndex,
}) => {
  let content = '';
  let columnClass;
  switch (dataKey) {
    case 'select':
      content = (
        <Checkbox
          className={classes.checkbox}
          checked={Boolean(selectedItems[rowData.id])}
          onChange={(e, val) => selectItem(rowData.id, val)}
          onClick={(e, val) => e.stopPropagation()}
          value={rowData.id}
        />
      );
      break;
    case 'name':
      content = (
        <div className={classes.nameCell}>
          <Avatar alt={rowData.firstName} src={rowData.picture} className={classes.avatar} />
          <div>
            <div className={classes.userName}>{getUserName(rowData) || rowData.email}</div>
            {getUserName(rowData) && <div className={classes.userEmail}>{rowData.email}</div>}
          </div>
        </div>
      );
      break;
    case 'teams':
      columnClass = classes.flexCenter;
      content = <div className={classes.nameCell}>{get('length', rowData.teams)}</div>;
      break;
    case 'workspaces':
      columnClass = classes.flexCenter;
      content = (
        <div className={classes.nameCell}>
          {flow(map('workspaces'), flatten, uniqBy('id'))(rowData.teams).length}
        </div>
      );
      break;
    case 'isAdmin':
      columnClass = classes.flexCenter;
      content = <Typography variant="overline">{rowData.isAdmin ? 'admin' : 'user'}</Typography>;
      break;
    case 'createdAt':
      columnClass = classes.flexCenter;
      content = moment(rowData.createdAt).format('YYYY-MM-DD');
      break;
    case 'status':
      columnClass = classes.flexCenter;
      switch (rowData.status) {
        case UserStatuses.ACTIVE:
          content = <Typography variant="overline">active</Typography>;
          break;
        case UserStatuses.CREATED:
          content = <Typography variant="overline">invited</Typography>;
          break;
        case UserStatuses.BLOCKED:
          content = (
            <Typography variant="overline" color="error">
              blocked
            </Typography>
          );
          break;
        default:
      }
      break;
    case 'actions':
      columnClass = classes.flexCenter;
      const isBlocked = rowData.status === UserStatuses.BLOCKED;
      content = (
        <>
          <IconButton
            aria-label="block user"
            className={clsx(classes.margin, isBlocked ? classes.successHover : classes.errorHover)}
            onClick={e => {
              e.stopPropagation();
              blockUser(rowData.id);
            }}
          >
            {!isBlocked && <BlockIcon fontSize="small" />}
            {isBlocked && <CheckCircleOutlineIcon fontSize="small" />}
          </IconButton>
          <IconButton
            aria-label="delete user"
            className={clsx(classes.margin, classes.errorHover)}
            onClick={e => {
              e.stopPropagation();
              deleteUser(rowData.id);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </>
      );
      break;
    default:
      content = cellData;
  }
  return (
    <TableCell
      component="div"
      variant="body"
      className={clsx(classes.tableCell, classes.flexContainer, classes.noClick, columnClass)}
      style={{ height: rowHeight }}
      align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
    >
      {content}
    </TableCell>
  );
};

const noRowsRenderer = ({ classes, alertMessage, noDataMessage }) => () => {
  return <div className={classes.noDataContainer}>{alertMessage || noDataMessage}</div>;
};

export default ({
  height,
  width,
  onRowClick,
  listRef,
  columns,
  users,
  selectItem,
  selectedAll,
  onSelectAllItemsClick,
  selectedItems,
  blockUser,
  deleteUser,
  alertMessage,
  noDataMessage,
}) => {
  const classes = useStyles();

  return (
    <Table
      ref={listRef}
      height={height}
      width={width}
      rowHeight={rowHeight}
      gridStyle={{ direction: 'inherit' }}
      headerHeight={headerHeight}
      headerClassName={classes.tableHeader}
      className={classes.table}
      rowCount={users.length}
      rowGetter={({ index }) => users[index]}
      rowClassName={getRowClassName(classes, columns)}
      noRowsRenderer={noRowsRenderer({ classes, alertMessage, noDataMessage })}
      onRowClick={onRowClick}
    >
      {columns.map(({ dataKey, width, label, flexGrow = 0, flexShrink = 0 }, index) => {
        return (
          <Column
            key={dataKey}
            dataKey={dataKey}
            width={width}
            cellRenderer={cellRenderer({
              classes,
              blockUser,
              deleteUser,
              columns,
              selectedItems,
              selectItem,
            })}
            headerRenderer={headerProps =>
              headerRenderer({
                columnIndex: index,
                classes,
                label,
                selectedAll,
                onSelectAllItemsClick,
                someSelected: keys(selectedItems).length > 0,
                columns,
              })
            }
            className={clsx(classes.flexContainer, {
              [classes.flexGrow]: index === 1,
            })}
            flexGrow={flexGrow}
            flexShrink={flexShrink}
          />
        );
      })}
    </Table>
  );
};
