var HistoryTypes = {
  ESTIMATE_CREATED: 'ESTIMATE_CREATED',
  ESTIMATE_EDITED: 'ESTIMATE_EDITED',
  ESTIMATE_DELETED: 'ESTIMATE_DELETED',
  ESTIMATE_CANCELLED: 'ESTIMATE_CANCELLED',
  BID_VALUE_EDITED: 'BID_VALUE_EDITED',
  BID_ADDED: 'BID_ADDED',
  BID_DELETED: 'BID_DELETED',
  CHANGES_REQUESTED: 'CHANGES_REQUESTED',
  SUBMITTED_FOR_APPROVAL: 'SUBMITTED_FOR_APPROVAL',
  APPROVED: 'APPROVED',
  ATTACHMENT_ADDED: 'ATTACHMENT_ADDED',
  ATTACHMENT_REMOVED: 'ATTACHMENT_REMOVED',
  APPROVER_REMOVED: 'APPROVER_REMOVED',
  APPROVER_ADDED: 'APPROVER_ADDED',
  COST_CATEGORY_ADDED: 'COST_CATEGORY_ADDED',
  COST_CATEGORY_DELETED: 'COST_CATEGORY_DELETED',
  LINE_ITEM_ADDED: 'LINE_ITEM_ADDED',
  LINE_ITEM_DELETED: 'LINE_ITEM_DELETED',
  LINE_ITEMS_REORDERED: 'LINE_ITEMS_REORDERED',
  COST_CATEGORIES_REORDERED: 'COST_CATEGORIES_REORDERED',
  CAMPAIGN_CREATED: 'CAMPAIGN_CREATED',
  CAMPAIGN_EDITED: 'CAMPAIGN_EDITED',
  CAMPAIGN_DELETED: 'CAMPAIGN_DELETED',
  CAMPAIGN_CLOSED: 'CAMPAIGN_CLOSED',
  CAMPAIGN_OPENED: 'CAMPAIGN_OPENED',
  CAMPAIGN_ACTUALISED: 'CAMPAIGN_ACTUALISED',
  ESTIMATE_INVOICE_CREATED: 'ESTIMATE_INVOICE_CREATED',
  ESTIMATE_INVOICE_DELETED: 'ESTIMATE_INVOICE_DELETED',
  ESTIMATE_OPEN_ACTUALISATION: 'ESTIMATE_OPEN_ACTUALISATION',
  ESTIMATE_CLOSE_ACTUALISATION: 'ESTIMATE_CLOSE_ACTUALISATION'
};
module.exports = {
  HistoryTypes: HistoryTypes
};