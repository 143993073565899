import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  onRemove: () => void;
}

const RemoveConfirmationModal = ({ open, onClose, onRemove }: Props) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        Are you sure you want to remove this supplier? The supplier will be archived and will no
        longer be available for future use.
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={onRemove}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveConfirmationModal;
