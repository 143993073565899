import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flexGrow: 1,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  submitButton: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  info: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  resetPassword: {
    ...theme.typography.body2,
    textAlign: 'center',
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  alertMessage: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default ({ onSubmit }) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState();
  const initialValues = {
    email: '',
    password: '',
    error: false,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        const errorMessage = await onSubmit(values);
        if (errorMessage) {
          setErrorMessage(errorMessage);
        }
        setSubmitting(false);
      }}
    >
      {({ values, isSubmitting, handleChange, handleBlur }) => (
        <Form className={classes.form}>
          {errorMessage && (
            <Alert
              severity="error"
              className={classes.alert}
              data-test="login-error"
              classes={{ message: classes.alertMessage }}
            >
              {errorMessage}
            </Alert>
          )}
          <Field
            label="Email"
            variant="outlined"
            type="email"
            name="email"
            className={classes.textField}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            component={TextField}
          />
          <Field
            label="Password"
            variant="outlined"
            type="password"
            name="password"
            className={classes.textField}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            component={TextField}
          />
          <Button
            type="submit"
            variant="contained"
            className={classes.submitButton}
            size="large"
            color="primary"
            disabled={isSubmitting}
            data-test="loginButton"
          >
            Login
          </Button>
          <NavLink to="/password-reset" className={classes.resetPassword}>
            Forgot Password?
          </NavLink>
        </Form>
      )}
    </Formik>
  );
};
