import React from 'react';
import { map, differenceBy } from 'lodash/fp';
import { makeStyles, fade, lighten } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
  ListItem,
  List,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  listContainer: {
    margin: theme.spacing(3, 0),
    width: '100%',
  },
  emptyListMessage: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2, 1),
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[300],
  },
  listItemBorder: {
    border: `1px solid ${lighten(fade(theme.palette.divider, 1), 0.88)}`,
    marginBottom: -1,
  },
  listItemButton: {
    color: theme.palette.grey[400],
  },
  listButton: {
    marginTop: theme.spacing(1),
  },
}));

export default ({
  selectedWorkspaces = [],
  workspaces = [],
  addWorkspace,
  removeWorkspace,
  showAccount = true,
}) => {
  const classes = useStyles();
  const availableWorkspaces = differenceBy('id', workspaces, selectedWorkspaces);

  return (
    <div className={classes.listContainer}>
      <Typography variant="h6">Workspaces</Typography>
      {Boolean(selectedWorkspaces.length) && (
        <List>
          {map(
            ({ id, name, account }) => (
              <ListItem key={id} className={classes.listItemBorder}>
                <ListItemText
                  primary={name}
                  secondary={showAccount && account.name.toUpperCase()}
                />
                {removeWorkspace && (
                  <ListItemSecondaryAction onClick={() => removeWorkspace(id)}>
                    <IconButton edge="end" aria-label="delete" className={classes.listItemButton}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ),
            selectedWorkspaces
          )}
        </List>
      )}

      {!selectedWorkspaces.length && (
        <div className={classes.emptyListMessage}>No team selected</div>
      )}

      {Boolean(availableWorkspaces.length && addWorkspace) && (
        <Button
          variant="contained"
          className={classes.listButton}
          startIcon={<AddIcon />}
          onClick={addWorkspace}
        >
          Add Workspace
        </Button>
      )}

      {Boolean(!availableWorkspaces.length && addWorkspace) && (
        <Button variant="contained" className={classes.listButton} startIcon={<AddIcon />} disabled>
          Add Workspace
        </Button>
      )}
    </div>
  );
};
