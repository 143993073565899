import React, { useMemo } from 'react';
import styled from 'styled-components';
import ApprovalsList from 'components/estimateApprovals/approvalsList';
import { font, colors } from 'shared/styles';
import { useQuery } from 'react-query';
import WorkspaceService from 'services/WorkspaceService';
import { getEstimateById } from 'state/estimates/selectors';
import { connect } from 'react-redux';
import { getCampaignById } from 'state/campaigns/selectors';
import { getUser } from 'state/authentication/selectors';
import { EstimateApprovalStatuses } from 'cr-core/constants';
import { Events, getEstimateData, track } from 'components/analytics';
import { get, reject } from 'lodash/fp';
import { createApproval } from 'state/estimateApprovals/actions';
import { ApprovalType, AccountSetting } from 'cr-core/constants';
import UserSelect from 'components/userSelect';
import { Box } from '@material-ui/core';
import { getEstimateApprovalsFor } from 'state/estimateApprovals/selectors';
import { showErrorNotification } from 'state/notifications/actions';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 20px 20px 90px;
  color: ${colors.text};
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 5px;
`;

const Title = styled.div`
  font-size: ${font.sizes.large};
  margin-bottom: 20px;
`;

const AddApproverContainer = styled.div`
  width: 500px;
  margin-bottom: 20px;
`;

const ApprovalsListContainer = styled.div`
  display: flex;
`;

const createEphemeralApproval = (estimateId, approver) => {
  const now = Date.now().toString();

  return {
    id: now,
    nonce: now,
    approver,
    approverId: approver.id,
    status: EstimateApprovalStatuses.LOADING,
    updatedAt: now,
    createdAt: now,
    estimateId,
    requestApprovalEmailSent: false,
    approvalEmailSent: false,
    approvalType: approver.approvalType || ApprovalType.CLIENT,
  };
};

const EstimateApprovals = ({
  match,
  pageId,
  estimate,
  campaign,
  user,
  createApproval,
  clientApprovers,
  showError,
}) => {
  const estimateId = match.params.id;
  const workspaceId = campaign?.workspace.id;
  const accountId = campaign?.workspace.accountId;
  const estimateApprovals = estimate?.approvals;
  const agencyApproversEnabled = user?.client?.accountSettings?.[AccountSetting.AgencyApprovers];

  const { data: workspaceUsers = [] } = useQuery(
    ['workspace', workspaceId, 'users'],
    async () => {
      if (typeof workspaceId === 'undefined') {
        return Promise.reject();
      }

      const { data } = await WorkspaceService.getWorkspaceUsers(workspaceId);

      return data.data;
    },
    {
      enabled: typeof workspaceId !== 'undefined',
    }
  );

  const [clientUsers, agencyUsers] = useMemo(() => {
    return workspaceUsers
      .filter(user => !estimateApprovals?.find(approval => approval.approver.id === user.id))
      .reduce(
        (acc, cur) => {
          const isClientUser =
            !!cur.accounts.find(account => account.id === accountId) || cur.isConsultant;

          isClientUser ? acc[0].push(cur) : acc[1].push(cur);

          return acc;
        },
        [[], []]
      );
  }, [workspaceUsers, accountId, estimateApprovals]);

  const [clientApprovals, agencyApprovals] = (estimateApprovals ?? []).reduce(
    (acc, cur) => {
      cur.approvalType === ApprovalType.CLIENT ? acc[0].push(cur) : acc[1].push(cur);
      return acc;
    },
    [[], []]
  );

  const addApprover = (userId, approvalType) => {
    if (agencyApproversEnabled) {
      const everyClientUserApproved =
        clientApprovals.length &&
        clientApprovals.every(({ status }) => status === EstimateApprovalStatuses.APPROVED);

      const everyAgencyUserApproved =
        agencyApprovals.length &&
        agencyApprovals.every(({ status }) => status === EstimateApprovalStatuses.APPROVED);

      if (everyClientUserApproved && everyAgencyUserApproved) {
        showError("Can't add new approvers when all approvers already approved");
        return;
      }
    }

    const approver = workspaceUsers.find(member => member.id === userId);
    const approval = createEphemeralApproval(estimateId, { ...approver, approvalType });

    createApproval(estimateId, userId, approval);

    track(Events.ESTIMATE_ADD_APPROVER, {
      approverUserId: userId,
      approverUserEmail: get('email', approver),
      approverIsConsultant: Boolean(get('isConsultant', approver)),
      ...getEstimateData(estimate),
    });
  };

  const addClientApprover = userId => {
    if (agencyApproversEnabled) {
      const agencyApproval = agencyApprovals.find(
        ({ status }) => status !== EstimateApprovalStatuses.APPROVED
      );

      if (
        agencyApproval &&
        clientApprovals.every(({ status }) => status === EstimateApprovalStatuses.APPROVED)
      ) {
        showError("Can't add client approver while agency approver(s) is pending for approval");
        return;
      }
    }

    addApprover(userId, ApprovalType.CLIENT);
  };

  return (
    <Container>
      <Title>Include people who need to approve this estimate.</Title>
      <AddApproverContainer>
        <UserSelect
          value={null}
          id="add-approver-list"
          users={clientUsers}
          onChange={({ value: id }) => addClientApprover(id)}
          placeholder={agencyApproversEnabled ? `Add Client Approver...` : 'Add Approver...'}
        />
      </AddApproverContainer>
      <ApprovalsListContainer>
        <ApprovalsList approvalType={ApprovalType.CLIENT} estimateId={estimateId} pageId={pageId} />
      </ApprovalsListContainer>
      {agencyApproversEnabled && !!clientApprovers?.length && (
        <Box mt={4}>
          <AddApproverContainer>
            <UserSelect
              value={null}
              users={agencyUsers}
              onChange={({ value: id }) => addApprover(id, ApprovalType.AGENCY)}
              placeholder="Add Agency Approver..."
            />
          </AddApproverContainer>
          <ApprovalsListContainer>
            <ApprovalsList
              approvalType={ApprovalType.AGENCY}
              estimateId={estimateId}
              pageId={pageId}
            />
          </ApprovalsListContainer>
        </Box>
      )}
    </Container>
  );
};

const mapStateToProps = (state, { match }) => {
  const estimate = getEstimateById(match.params.id)(state);
  const campaign = getCampaignById(estimate?.campaignId)(state);
  const user = getUser(state);
  const clientApprovers = getEstimateApprovalsFor(estimate?.id, ApprovalType.CLIENT)(state);

  return {
    estimate,
    campaign,
    user,
    clientApprovers: reject('nonce', clientApprovers || []),
  };
};

const mapDispatchToProps = (dispatch, { pageId }) => ({
  createApproval: (estimateId, userId, approval) =>
    dispatch(createApproval(estimateId, userId, pageId, approval)),

  showError: message => dispatch(showErrorNotification({ message })),
});

export default connect(mapStateToProps, mapDispatchToProps)(EstimateApprovals);
