import { flow, reject, getOr, filter, differenceBy, find, map, get } from 'lodash/fp';
import { getWorkspaceMembers } from 'state/workspaces/selectors';
import { getEstimateById } from 'state/estimates/selectors';
import { getClientSettings } from 'state/authentication/selectors';
import { AccountSetting, ApprovalType, EstimateApprovalStatuses } from 'cr-core/constants';

export const getCampaignById = id => get(`campaigns.byId.${id}`); // HACK FOR NOW to stop cyclic imports

export const getEstimateApprovalsFor = (estimateId, approvalType) => state => {
  const approvals = flow(getEstimateById(estimateId), getOr([], 'approvals'))(state);
  const clientSettings = getClientSettings(state);
  const agencyApproversEnabled = clientSettings[AccountSetting.AgencyApprovers];

  if (!agencyApproversEnabled) {
    approvalType = ApprovalType.CLIENT;
  }

  if (!approvalType) {
    return approvals;
  }

  return filter({ approvalType }, approvals);
};

export const getUnsubmittedEstimateApprovalsFor = (estimateId, approvalType) => state => {
  const approvals = flow(
    getEstimateApprovalsFor(estimateId),
    reject('requestApprovalEmailSent'),
    reject('submitted'),
    reject('nonce')
  )(state);

  const clientSettings = getClientSettings(state);
  const agencyApproversEnabled = clientSettings[AccountSetting.AgencyApprovers];

  if (!agencyApproversEnabled) {
    approvalType = ApprovalType.CLIENT;
  }

  if (!approvalType) {
    return approvals;
  }

  return filter({ approvalType }, approvals);
};

export const getNonApprovedClientApproversForEstimate =
  (estimateId, approvalType = ApprovalType.CLIENT) =>
  state => {
    let approvals = flow(getEstimateApprovalsFor(estimateId))(state);

    approvals = approvals.filter(
      approver =>
        approver.status === EstimateApprovalStatuses.PENDING_APPROVAL ||
        approver.status === EstimateApprovalStatuses.CHANGES_REQUESTED
    );

    return filter({ approvalType }, approvals);
  };

export const getPickableApproversForEstimate = estimateId => state => {
  const estimate = getEstimateById(estimateId)(state);
  const campaign = getCampaignById(estimate.campaignId)(state);
  const accountId = campaign.workspace.accountId;
  const workspaceId = campaign.workspace.id;
  const workspaceMembers = getWorkspaceMembers(workspaceId)(state);

  return flow(
    map('approver'),
    differenceBy('id', workspaceMembers),
    filter(user => find({ id: accountId }, user.accounts) || user.isConsultant)
  )(estimate.approvals);
};
