import React from 'react';
import { connect } from 'react-redux';
import { compose, branch, renderNothing } from 'recompose';
import { Link } from 'react-router-dom';
import { Button } from 'components/buttons';
import { Events, track } from 'components/analytics';
import SvgDraftIcon from 'icons/DraftIcon';
import { canCreateEstimate } from 'state/workspaces/selectors';

const CreateButton = () => (
  <Button
    Component={Link}
    to="/estimates/new"
    data-test="createEstimate"
    onClick={() => track(Events.ESTIMATE_CREATE_CLICK, { origin: 'page_estimates' })}
  >
    <SvgDraftIcon className="icon" />
    Create Estimate
  </Button>
);

const mapStateToProps = state => ({
  canCreateEstimate: canCreateEstimate(state),
});

export default compose(
  connect(mapStateToProps),
  branch(({ canCreateEstimate }) => !canCreateEstimate, renderNothing)
)(CreateButton);
