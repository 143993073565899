import AuthService from 'services/AuthService';
import queryString from 'query-string';

const download = (url: string) => {
  const token = AuthService.getApiToken();

  window.location.href = queryString.stringifyUrl({ url, query: { token } });
};

export default download;
