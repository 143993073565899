import React, { useState } from 'react';
import ReactS3Uploader from 'react-s3-uploader';
import S3Upload from 'react-s3-uploader/s3upload';
import Dropzone from 'react-dropzone';
import { withProps } from 'recompose';
import { compose, last, noop } from 'lodash/fp';
import { Button, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
// import config from 'config';
import { getAccessToken } from 'shared/authUtils';
import EstimateService from 'services/EstimateService';
import FileType from 'file-type/browser';

const allowedFileExtension = [
  'doc',
  'docx',
  'csv',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'pdf',
  'eml',
  'jpg',
  'jpeg',
  'png',
  'zip',
  '7z',
];

const useStyles = makeStyles(theme => {
  return {
    uploadInput: {
      display: 'none',
    },
    progress: {
      marginRight: theme.spacing(),
    },
    alert: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    dropzone: {
      width: '100%',
      padding: theme.spacing(5),
      border: `3px dashed ${theme.palette.grey[300]}`,
      // borderRadius: theme.spacing(1),
      background: theme.palette.background.paper,
      cursor: 'pointer',

      '&:focus, &:active': {
        outline: 'none',
      },
    },
    dropzoneButton: {
      marginRight: theme.spacing(1),
    },
  };
});

const FilePicker = ({
  estimateId,
  className,
  uploader,
  onFinish = noop,
  onError = noop,
  multiple,
  preSignedUrlUploadParams,
  dragndrop,
  ...props
}) => {
  const classes = useStyles();
  const headers = {
    Authorization: `Bearer ${getAccessToken()}`,
  };
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState();
  const onErrorHandler = (...args) => {
    setIsLoading(false);
    onError(...args);
    setErrorMessage('An error occurred');
    uploader.current.clear();
  };
  const onFinishHanlder = (...args) => {
    setIsLoading(false);
    onFinish(...args);
  };
  const onProgress = () => {
    setIsLoading(true);
    setErrorMessage();
  };

  const onUploadStart = (file, next) => {
    const extension = last(file.name.split('.'));

    if (allowedFileExtension.includes(extension)) {
      next(file);
    } else {
      setErrorMessage(
        <div>
          <b>The file wasn't uploaded because the file type is not supported.</b>
          <br />
          Only the following file types are supported: {allowedFileExtension.join(', ')}
        </div>
      );
    }
  };
  const getSignedUrl = async (file, callback) => {
    try {
      const params = {
        contentType: file.type || (await FileType.fromBlob(file))?.mime,
        objectName: file.name,
        path: `estimates/${estimateId}/`,
      };

      EstimateService.getUploadSignature(estimateId, params)
        .then(({ data }) => {
          callback(data);
        })
        .catch(error => {
          setIsLoading(false);
          setErrorMessage('An error occurred');
        });
    } catch (e) {
      setIsLoading(false);
      setErrorMessage('An error occurred');
    }
  };

  const options = {
    id: 'upload-input',

    // signingUrlMethod: 'GET',
    // signingUrl: `/estimates/${estimateId}/uploadSignature`,
    getSignedUrl: getSignedUrl,
    accept: '*/*',
    // s3path: `estimates/${estimateId}/`,
    preprocess: onUploadStart,
    // onSignedUrl:tonSignedUrl,
    onProgress: onProgress,
    onError: onErrorHandler,
    onFinish: onFinishHanlder,
    onFinishS3Put: onFinishHanlder,
    signingUrlHeaders: headers,
    scrubFilename: filename => filename.replace(/[^\w\d_\-.]+/gi, ''),
    // server: config.apiEndpoint,
    autoUpload: true,
    ref: uploader,
  };
  if (multiple) {
    options.multiple = true;
  }

  return (
    <div className={className}>
      <ReactS3Uploader {...options} uploadRequestHeaders={{}} className={classes.uploadInput} />
      {dragndrop ? (
        <Dropzone
          onDrop={acceptedFiles => {
            new S3Upload({ ...options, files: acceptedFiles });
          }}
        >
          {({ getRootProps, getInputProps }) => {
            return (
              <section className={classes.dropzone} {...getRootProps()}>
                <div>
                  <input {...getInputProps()} />
                  <p>
                    <Button
                      variant="contained"
                      onClick={e => {
                        e.stopPropagation();
                        uploader.current && uploader.current.clear();
                        document.getElementById('upload-input').click();
                      }}
                      disabled={isLoading}
                      className={classes.dropzoneButton}
                    >
                      {isLoading && <CircularProgress className={classes.progress} size={16} />}
                      Upload File
                    </Button>
                    or drag 'n' drop some files here
                  </p>
                </div>
              </section>
            );
          }}
        </Dropzone>
      ) : (
        <Button
          variant="contained"
          onClick={e => {
            e.stopPropagation();
            uploader.current && uploader.current.clear();
            document.getElementById('upload-input').click();
          }}
          disabled={isLoading}
          className={className}
        >
          {isLoading && <CircularProgress className={classes.progress} size={16} />}
          Upload File
        </Button>
      )}

      {errorMessage && (
        <Alert severity="error" className={classes.alert}>
          {errorMessage}
        </Alert>
      )}
    </div>
  );
};

export default compose(
  withProps(({ currency, amount, initialValues }) => ({
    uploader: React.createRef(),
  }))
)(FilePicker);
