import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchWorkspaceMembers } from 'state/workspaces/actions';
import { getWorkspaceById, getWorkspaceMembers } from 'state/workspaces/selectors';
import MembersModal from 'components/modals/membersModal';

const WorkspaceMembers = ({ onClose, open, fetchMembers, workspace, members }) => {
  useEffect(() => {
    fetchMembers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MembersModal
      members={members}
      accounts={[workspace.account, ...workspace.externalAccounts]}
      title="Workspace Members"
      onClose={onClose}
      open={open}
    />
  );
};

const mapStateToProps = (state, { workspaceId }) => {
  return {
    members: getWorkspaceMembers(workspaceId)(state),
    workspace: getWorkspaceById(workspaceId)(state),
  };
};

const mapDispatchToProps = (dispatch, { workspaceId }) => ({
  fetchMembers: () => dispatch(fetchWorkspaceMembers(workspaceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceMembers);
