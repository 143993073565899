const config = {
  AUTH0_CLIENT_ID: '7FSyk8rXk47tj8mY1UaF382g70dAsTq4',
  AUTH0_DOMAIN: 'login.murphycobb.com',
  REDIRECT_URL: `${window.location.origin}`,
  apiEndpoint: process.env.REACT_APP_API_URL,
  REACT_APP_SEGMENT_WRITE_KEY: process.env.REACT_APP_SEGMENT_WRITE_KEY,
  REACT_APP_SENTRY_URL: process.env.REACT_APP_SENTRY_URL,
  REACT_APP_VERSION: process.env.REACT_APP_VERSION,
  REACT_APP_ENV: process.env.REACT_APP_ENV,
};

export default config;
