import { flow, get, filter, find } from 'lodash/fp';

export const getHistoryByEstimateId = estimateId =>
  flow(
    get('estimateHistory'),
    filter({ estimateId })
  );

export const getEstimateFromHistoryItem = historyId =>
  flow(
    get('estimateHistory'),
    find({ id: historyId }),
    get('data.estimate')
  );
