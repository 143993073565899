import React from 'react';

const SvgShoppingIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="shopping-icon_svg__b"
        d="M7.2 19.2a2.397 2.397 0 0 0-2.388 2.4c0 1.32 1.068 2.4 2.388 2.4 1.32 0 2.4-1.08 2.4-2.4 0-1.32-1.08-2.4-2.4-2.4zM0 0v2.4h2.4l4.32 9.108-1.62 2.94a2.32 2.32 0 0 0-.3 1.152c0 1.32 1.08 2.4 2.4 2.4h14.4v-2.4H7.704a.297.297 0 0 1-.3-.3l.036-.144L8.52 13.2h8.94c.9 0 1.692-.492 2.1-1.236l4.296-7.788A1.204 1.204 0 0 0 22.8 2.4H5.052L3.924 0H0zm19.2 19.2a2.397 2.397 0 0 0-2.388 2.4c0 1.32 1.068 2.4 2.388 2.4 1.32 0 2.4-1.08 2.4-2.4 0-1.32-1.08-2.4-2.4-2.4z"
      />
      <filter
        id="shopping-icon_svg__a"
        width="116.7%"
        height="116.7%"
        x="-8.3%"
        y="-4.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
      </filter>
    </defs>
    <g fill="none" transform="translate(3 3)">
      <use fill="#000" filter="url(#shopping-icon_svg__a)" xlinkHref="#shopping-icon_svg__b" />
      <use fill="currentColor" xlinkHref="#shopping-icon_svg__b" />
    </g>
  </svg>
);

export default SvgShoppingIcon;
