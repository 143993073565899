import React from 'react';

const SvgDownloadIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="download-icon_svg__a"
        width="133.3%"
        height="133.3%"
        x="-16.7%"
        y="-16.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      d="M21.333 12v9.333H2.667V12H0v9.333C0 22.8 1.2 24 2.667 24h18.666C22.8 24 24 22.8 24 21.333V12h-2.667zm-8 .893l3.454-3.44 1.88 1.88L12 18l-6.667-6.667 1.88-1.88 3.454 3.44V0h2.666v12.893z"
      filter="url(#download-icon_svg__a)"
      transform="translate(3 3)"
    />
  </svg>
);

export default SvgDownloadIcon;
