import { ESTIMATE_HISTORY_FETCH_SUCCESS } from './actions';

const defaultState = [];

export default (state = defaultState, action) => {
  switch (action.type) {
    case ESTIMATE_HISTORY_FETCH_SUCCESS:
      return action.estimateHistory;
    default:
      return state;
  }
};
