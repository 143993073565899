import { Field } from 'redux-form';
import { connect } from 'react-redux';
import React from 'react';
import { get, map, flow, toPairs, groupBy, filter, reject } from 'lodash/fp';
import { FieldContainer, InputContainer, Label, SubLabel, Select } from 'components/forms';
import { getProducts } from 'state/products/selectors';
import { getUserWorkspaceById } from 'state/workspaces/selectors';
import { getCampaignById } from 'state/campaigns/selectors';

const productToProductOption = ({ id, productName, brandName }, nameOnly = false) => ({
  label: nameOnly ? productName : `${brandName} ${productName}`,
  value: id,
  brandName,
});

const formatGroupLabel = data => (
  <div>
    <span>{data.label}</span>
    <span> ({data.options.length})</span>
  </div>
);

const getProductOptions = (workspaceId, campaignId) => state => {
  const workspace = getUserWorkspaceById(workspaceId)(state);
  const accountId = workspace.accountId;
  const settings = workspace.account.accountSettings;
  const campaign = getCampaignById(campaignId)(state);

  return flow(
    getProducts,
    filter(product => {
      if (!campaign || !campaign.productCategories || !campaign.productCategories.length) {
        return product?.accountId === accountId && product;
      }

      const existInCampaignCategories = campaign.productCategories?.some(
        productCategory => productCategory.id === product.productCategory.id
      );

      return product.accountId === accountId && existInCampaignCategories && product;
    }),
    reject(get('deletedAt')),
    map(product => productToProductOption(product, settings.productNameOnly)),
    groupBy('brandName'),
    toPairs,
    map(([label, options]) => ({
      label,
      options,
    }))
  )(state);
};

const mapStateToProps = (state, { workspaceId, campaignId }) => ({
  productOptions: getProductOptions(workspaceId, campaignId)(state),
});

export default connect(mapStateToProps)(({ productOptions }) => {
  return (
    <FieldContainer>
      <Label htmlFor="products">
        Product(s)<SubLabel>Which product(s) is this production for?</SubLabel>
      </Label>
      <InputContainer>
        <Field
          name="products"
          component={({ input }) => (
            <Select
              isMulti
              id="select-product"
              placeholder="Select products..."
              className="reactSelect__multi"
              options={productOptions}
              formatGroupLabel={formatGroupLabel}
              onChange={input.onChange}
              value={input.value}
              styles={{
                option: provided => ({
                  ...provided,
                  paddingLeft: 20,
                }),
              }}
            />
          )}
        />
      </InputContainer>
    </FieldContainer>
  );
});
