import React from 'react';

const SvgApproveIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="approve-icon_svg__a"
        width="132%"
        height="142.1%"
        x="-16%"
        y="-21.1%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      d="M21.937.2a.702.702 0 0 1 .98 0l1.879 1.841a.677.677 0 0 1 0 .97L8.697 18.8a.702.702 0 0 1-.98 0L.204 11.432a.677.677 0 0 1 0-.97l1.879-1.841a.702.702 0 0 1 .98 0l5.144 5.045L21.937.2z"
      filter="url(#approve-icon_svg__a)"
      transform="translate(3 6)"
    />
  </svg>
);

export default SvgApproveIcon;
