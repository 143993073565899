import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import { compose, getContext } from 'recompose';
import { saveCostCategoryOrder } from 'state/costCategories/actions';
import { saveLineItemOrder } from 'state/lineItems/actions';
import { getLastLineItemIdAddedCategory } from 'state/lineItems/selectors';
import withEditConfirmationDialog from 'components/estimateEdit/withEditConfirmationDialog';
import { getCostCategoryBeingAdded } from 'state/bids/selectors';
import AddBidButton from './addBidButton';
import AddSupplementaryCostsButton from './addSupplementaryCostsButton';
import AddCostCategoryButton from './addCostCategoryButton';
import AccountSetting from 'cr-core/constants/account-setting';
import { DemoAdditionalTypes } from 'cr-core/constants';
import { Alert } from '@material-ui/lab';

import {
  TableScrollWrapper,
  Table,
  TableFooter,
  TableHeader,
  Row,
  RowsContainer,
} from './tableComponents';
import {
  BidHeaderCell,
  BidFooterRows,
  DraggableCostCategories,
  EditableCostCategorySectionWithScrollTo,
  StaticCostCategories,
  NotesSection,
  HeaderActionContainer,
} from './components';
import { getUserWorkspaceById } from 'state/workspaces/selectors';
import Covid19Language from './Covid19Language';
import { getClientSettings, getUser } from 'state/authentication/selectors';
import { EstimateStatuses } from 'cr-core/constants';
import { Box } from '@material-ui/core';

const BidTable = ({
  costCategoryIds,
  bidIds,
  costCategoryBeingAdded,
  onDragEnd,
  readOnly,
  className,
  estimate,
  lastLineItemIdAddedCategory,
  displayCovid19Language,
  pageId,
  user,
  clientSettings,
}) => {
  const expanded = Boolean(get('bidValues.length', estimate));
  const userId = user && user.id;

  const canEdit =
    userId === (estimate && estimate.creator && estimate.creator.id) &&
    estimate &&
    estimate.status !== EstimateStatuses.APPROVED;

  const hideBids =
    clientSettings[AccountSetting.DemoAdditionalTypes] &&
    DemoAdditionalTypes.map(({ value }) => value).includes(estimate?.mediaType);

  return (
    <>
      {hideBids && (
        <Box px={1} py={2} width="100%">
          <Alert severity="warning" data-test="error-message">
            This estimate does not support bids.
          </Alert>
        </Box>
      )}
      {!hideBids && (
        <TableScrollWrapper className={className}>
          <Table>
            <TableHeader>
              <RowsContainer>
                <Row className="row headerRow">
                  <HeaderActionContainer>
                    <AddSupplementaryCostsButton
                      estimateId={estimate.id}
                      style={{ visibility: readOnly ? 'hidden' : 'inherit' }}
                      pageId={pageId}
                    />
                    <AddBidButton
                      estimateId={estimate.id}
                      style={{ visibility: readOnly ? 'hidden' : 'inherit' }}
                      pageId={pageId}
                    />
                  </HeaderActionContainer>
                  {bidIds.map((bidId, index) => (
                    <BidHeaderCell
                      key={bidId}
                      bidId={bidId}
                      estimateId={estimate.id}
                      deletable={bidIds.length > 1}
                      pageId={pageId}
                      index={index}
                    />
                  ))}
                </Row>
              </RowsContainer>
            </TableHeader>
            {readOnly ? (
              <StaticCostCategories
                costCategoryIds={costCategoryIds}
                expanded={expanded}
                pageId={pageId}
              />
            ) : (
              <>
                <DraggableCostCategories
                  costCategoryIds={costCategoryIds}
                  onDragEnd={onDragEnd}
                  expanded={expanded}
                  lastLineItemIdAddedCategory={lastLineItemIdAddedCategory}
                  pageId={pageId}
                />
                {!costCategoryBeingAdded && <AddCostCategoryButton pageId={pageId} />}
              </>
            )}
            {costCategoryBeingAdded && (
              <EditableCostCategorySectionWithScrollTo scrollOnMount={true} pageId={pageId} />
            )}
            <TableFooter>
              <BidFooterRows />
            </TableFooter>
          </Table>
          <Table>
            <NotesSection expanded={true} />
          </Table>
          {displayCovid19Language && (
            <Covid19Language
              estimateId={estimate.id}
              pageId={pageId}
              canEdit={canEdit}
              initialValue={
                estimate && estimate.customData && estimate.customData.acceptCovid19RelatedIssues
              }
            />
          )}
        </TableScrollWrapper>
      )}
    </>
  );
};

const shouldDisplayCovid19Language = state => estimate => {
  const workspace =
    estimate && estimate.campaign && getUserWorkspaceById(estimate.campaign.workspaceId)(state);
  const accountSettings = workspace && workspace.account && workspace.account.accountSettings;
  const workspaceName = workspace && workspace.name;
  const includeCovid19Language =
    estimate && estimate.customData && estimate.customData.includeCovid19Language;

  // shitty way to do it, but it is what it is, fix later
  return Boolean(
    accountSettings &&
      accountSettings[AccountSetting.Covid19Language] &&
      includeCovid19Language &&
      workspaceName &&
      workspaceName.includes('WPP')
  );
};

const mapStateToProps = (state, { estimate }) => ({
  lastLineItemIdAddedCategory: getLastLineItemIdAddedCategory(state),
  costCategoryBeingAdded: getCostCategoryBeingAdded(state),
  displayCovid19Language: shouldDisplayCovid19Language(state)(estimate),
  user: getUser(state),
  clientSettings: getClientSettings(state),
});

const mapDispatchToProps = (dispatch, { estimate }) => ({
  onDragEnd: ({ destination, draggableId, type }) => {
    if (destination) {
      if (type === 'costCategory') {
        dispatch(
          saveCostCategoryOrder({
            estimateId: estimate.id,
            costCategoryId: draggableId,
            position: destination.index,
          })
        );
      } else {
        dispatch(
          saveLineItemOrder({
            estimateId: estimate.id,
            costCategoryId: destination.droppableId,
            lineItemNameId: draggableId,
            position: destination.index,
          })
        );
      }
    }
  },
});

export default compose(
  getContext({ estimate: PropTypes.object, readOnly: PropTypes.bool }),
  connect(mapStateToProps, mapDispatchToProps),
  withEditConfirmationDialog('onDragEnd')
)(BidTable);
