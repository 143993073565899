import { set, reject, update, concat, flow, pick } from 'lodash/fp';
import {
  updateLineItemOrderOnEstimate,
  updateCostCategoryOrderOnEstimate,
} from 'cr-core/estimateUtils';
import { ESTIMATE_FETCH_SUCCESS } from 'state/estimates/actions';
import {
  ADD_BID_SUCCESS,
  REMOVE_BID_REQUEST,
  LINE_ITEM_EDIT_REQUEST,
  BIDS_RESET,
} from 'state/bids/actions';
import {
  ADD_COST_CATEGORY_SUCCESS,
  ADD_EMPTY_COST_CATEGORY,
  SAVE_COST_CATEGORY_ORDER_REQUEST,
} from 'state/costCategories/actions';
import {
  ADD_COST_LINE_ITEM_SUCCESS,
  SAVE_LINE_ITEM_ORDER_REQUEST,
  ADD_EMPTY_LINE_ITEM_TO_COST_CATEGORY,
  REMOVE_EMPTY_LINE_ITEM_TO_COST_CATEGORY,
} from 'state/lineItems/actions';

const defaultState = {
  estimateId: null,
  bidValues: [],
  bids: [],
  recommendedBid: null,
  lineItemGroups: [],
  lineItemBeingAdded: {},
  costCategoryBeingAdded: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case BIDS_RESET:
      return defaultState;

    case ESTIMATE_FETCH_SUCCESS: {
      const bidValues = pick(
        ['bidValues', 'bids', 'lineItemGroups', 'recommendedBid'],
        action.estimate
      );
      return state.estimateId === action.estimate.id
        ? { ...state, ...bidValues }
        : {
            ...defaultState,
            estimateId: action.estimate.id,
            ...bidValues,
          };
    }

    case LINE_ITEM_EDIT_REQUEST:
      const shouldRemove = action.valueType === 'cost' && action.value && !action.value.amount;

      return update(
        'bidValues',
        flow(
          reject({ lineItemNameId: action.lineItemNameId, bidId: action.bidId }),
          shouldRemove
            ? reject({ bidId: action.bidId, lineItemNameId: action.lineItemNameId })
            : concat([
                {
                  type: action.valueType,
                  lineItemNameId: action.lineItemNameId,
                  bidId: action.bidId,
                  value: action.value,
                  lineItemName: action.lineItemName,
                  mandatory: action.mandatory,
                  optional: action.optional,
                  costCategoryId: action.costCategoryId,
                },
              ])
        ),
        state
      );

    case ADD_BID_SUCCESS:
      return { ...state, bids: [...state.bids, action.bid] };

    case REMOVE_BID_REQUEST:
      return update('bids', reject({ id: action.bidId }), state);

    case ADD_EMPTY_COST_CATEGORY:
      return { ...state, costCategoryBeingAdded: true };

    case ADD_COST_CATEGORY_SUCCESS:
      return set('costCategoryBeingAdded', false, state);

    case ADD_COST_LINE_ITEM_SUCCESS:
      return set('lineItemBeingAdded', { [action.costCategoryId]: false }, state);
    case ADD_EMPTY_LINE_ITEM_TO_COST_CATEGORY:
      return set('lineItemBeingAdded', { [action.costCategoryId]: true }, state);

    case REMOVE_EMPTY_LINE_ITEM_TO_COST_CATEGORY:
      return set(`lineItemBeingAdded.${action.costCategoryId}`, false, state);

    case SAVE_LINE_ITEM_ORDER_REQUEST: {
      const lineItemGroups = updateLineItemOrderOnEstimate(
        action.costCategoryId,
        action.lineItemNameId,
        action.position
      )({ lineItemGroups: state.lineItemGroups }).lineItemGroups;
      return { ...state, lineItemGroups };
    }

    case SAVE_COST_CATEGORY_ORDER_REQUEST: {
      const lineItemGroups = updateCostCategoryOrderOnEstimate(
        action.costCategoryId,
        action.position
      )({
        lineItemGroups: state.lineItemGroups,
      }).lineItemGroups;
      return { ...state, lineItemGroups };
    }

    default:
      return state;
  }
};
