import { PRODUCTS_FETCH_SUCCESS, PRODUCT_CREATE_SUCCESS, PRODUCT_UPDATE_SUCCESS } from './actions';

const defaultState = [];

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case PRODUCTS_FETCH_SUCCESS:
      return [...action.products];

    case PRODUCT_CREATE_SUCCESS:
      return [...state, action.product];

    case PRODUCT_UPDATE_SUCCESS:
      return state.map(product => (action.payload.id === product.id ? action.payload : product));

    default:
      return state;
  }
};

export default reducer;
