import React from 'react';
import PropTypes from 'prop-types';
import { EstimateStatuses } from 'cr-core/constants';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import styled from 'styled-components';
import Pluralize from 'react-pluralize';
import { compose, withHandlers, withState, withContext } from 'recompose';
import { track, getEstimateData } from 'components/analytics';
import ConfirmationModal from 'components/modals/confirmation';
import WarningBox from 'components/warningBox';
import {
  getEstimateApprovedApprovalsCount,
  getEstimateStatus,
  getEstimateById,
} from 'state/estimates/selectors';
import { font } from 'shared/styles';

const ConfirmEditModal = styled(ConfirmationModal)`
  .bodyText {
    margin-bottom: 20px;
  }

  .bold {
    font-weight: ${font.weights.bold};
  }

  .warning-message {
    font-size: 14px;
  }
`;

const NotifyOnlyBody = () => (
  <>
    <div className="bodyText bold">Would you like to make these changes?</div>
    <WarningBox className="warning-message">
      This estimate has already been submitted so watchers and approvers will be notified about the
      change.
    </WarningBox>
  </>
);

const BackToDraftBody = ({ approvedApprovalsCount, estimate }) => (
  <>
    <div className="bodyText bold">Would you like to make these changes?</div>
    <WarningBox className="warning-message">
      {estimate.status === EstimateStatuses.CANCELLED && (
        <div>
          <div className="bodyText">This estimate has been marked as cancelled.</div>
          <div className="bodyText">
            This estimate will be set back to <span className="bold">DRAFT</span> status and can be
            re-submitted as necessary.
          </div>
        </div>
      )}

      {estimate.status !== EstimateStatuses.CANCELLED && (
        <div>
          {approvedApprovalsCount > 0 && (
            <div className="bodyText">
              This estimate has already been approved by{' '}
              <Pluralize singular={'approver'} count={approvedApprovalsCount} className="bold" />.
            </div>
          )}
          <div className="bodyText">
            This estimate will be set back to <span className="bold">DRAFT</span> status and will
            need to be re-submitted.
          </div>
        </div>
      )}
    </WarningBox>
  </>
);

const withEditConfirmationDialog =
  (propToIntercept, options = {}) =>
  WrappedComponent => {
    const { notifyOnly, trackEvent } = options;

    const component = props => {
      const {
        approvedApprovalsCount,
        shouldShowConfirmationOnSubmit,
        handleSubmitOrConfirm,
        shouldShowEditConfirmation,
        onCancel,
        onConfirm,
        onRequestClose,
        estimate,
      } = props;
      const extraProps = {
        [propToIntercept]: handleSubmitOrConfirm,
      };
      return (
        <>
          {shouldShowConfirmationOnSubmit && (
            <ConfirmEditModal
              onConfirm={onConfirm}
              onRequestClose={onRequestClose}
              onCancel={onCancel}
              isOpen={shouldShowEditConfirmation}
              title="Confirm Edit"
            >
              {notifyOnly ? (
                <NotifyOnlyBody />
              ) : (
                <BackToDraftBody
                  approvedApprovalsCount={approvedApprovalsCount}
                  estimate={estimate}
                />
              )}
            </ConfirmEditModal>
          )}

          <WrappedComponent
            {...props}
            {...extraProps}
            disableOnClickOutside={shouldShowConfirmationOnSubmit && shouldShowEditConfirmation}
          />
        </>
      );
    };

    const mapStateToProps = (state, { estimateId, estimate }) => {
      const id = estimateId || (estimate && estimate.id);
      if (!id) {
        throw new Error('`estimate` or `estimateId` required');
      }
      const estimateStatus = getEstimateStatus(id)(state);
      return {
        estimateStatus,
        approvedApprovalsCount: getEstimateApprovedApprovalsCount(id)(state),
        shouldShowConfirmationOnSubmit: estimateStatus !== EstimateStatuses.DRAFT,
        estimate: estimate || getEstimateById(id),
      };
    };

    return compose(
      connect(mapStateToProps),
      withState('shouldShowEditConfirmation', 'setShowEditConfirmation', false),
      withState('args', 'setArgs', {}),
      withHandlers({
        toggleShowEditConfirmation:
          ({ setShowEditConfirmation }) =>
          show =>
            setShowEditConfirmation(show),
      }),
      withContext(
        { isWithEditConfirmationDialogOpen: PropTypes.bool },
        ({ shouldShowEditConfirmation }) => ({
          isWithEditConfirmationDialogOpen: Boolean(shouldShowEditConfirmation),
        })
      ),
      withHandlers({
        handleSubmitOrConfirm:
          props =>
          (...args) => {
            const { shouldShowConfirmationOnSubmit, toggleShowEditConfirmation, setArgs } = props;

            if (shouldShowConfirmationOnSubmit) {
              toggleShowEditConfirmation(true);
              setArgs(args);
              return;
            }

            return props[propToIntercept](...args);
          },
        onConfirm: props => () => {
          props[propToIntercept](...props.args);
          if (get('success', trackEvent)) {
            track(trackEvent.success, getEstimateData(props.estimate));
          }
        },
        onCancel:
          ({ estimate }) =>
          (...args) => {
            if (get('cancel', trackEvent)) {
              track(trackEvent.cancel, getEstimateData(estimate));
            }
          },
        onRequestClose:
          ({ toggleShowEditConfirmation }) =>
          () => {
            toggleShowEditConfirmation(false);
          },
      })
    )(component);
  };

export default withEditConfirmationDialog;
