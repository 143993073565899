import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import { getFormValues } from 'redux-form';
import AppContainer from 'components/appContainer';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Hidden } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/Add';
import TeamsList from 'components/teams/teamsList';
import TeamDetails from 'components/teams/teamDetails';
import CreateTeam from 'components/teams/createTeam';
import Filters from 'components/teams/teamFilters';
import { getSearchFromQSParams } from 'shared/browserUtils';
import { getUser } from 'state/authentication/selectors';
import { openSidePanel, closeSidePanel } from 'state/ui/actions';
import { getSidePanelData } from 'state/ui/selectors';
import { getTeamsPagination } from 'state/teams/selectors';

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  headerRight: {
    display: 'flex',
  },
}));

const WorkspacesPage = ({
  pagination,
  sidePanelData,
  openSidePanel,
  closeSidePanel,
  match,
  history,
  user,
  accountId,
}) => {
  const classes = useStyles();
  let sidePanelContent;

  if (accountId && sidePanelData.view === 'TEAM_DETAILS') {
    sidePanelContent = <TeamDetails accountId={accountId} />;
  } else if (accountId && sidePanelData.view === 'CREATE_TEAM') {
    sidePanelContent = <CreateTeam accountId={accountId} />;
  }

  useEffect(() => {
    const { teamId } = match.params;
    if (teamId === 'new') {
      openSidePanel({ view: 'CREATE_TEAM' });
    }
    return () => closeSidePanel();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addTeam = () => {
    history.push({
      pathname: '/teams/new',
      search: getSearchFromQSParams(),
    });
    openSidePanel({ view: 'CREATE_TEAM' });
  };

  const onCloseSidePanel = () => history.push('/teams' + window.location.search);

  return (
    <AppContainer sidePanelContent={sidePanelContent} onCloseSidePanel={onCloseSidePanel}>
      <div className={classes.header}>
        <div className={classes.headerRight}>
          <Typography variant="h4">Teams</Typography>
          <Filters />
        </div>
        <div>
          {Boolean(pagination.totalResults) && (
            <Hidden smDown>{pagination.totalResults} teams</Hidden>
          )}
          {Boolean(accountId) && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<AddCircleIcon />}
              onClick={addTeam}
            >
              Add Team
            </Button>
          )}
        </div>
      </div>
      <TeamsList />
    </AppContainer>
  );
};

const mapStateToProps = state => ({
  pagination: getTeamsPagination(state),
  sidePanelData: getSidePanelData(state),
  user: getUser(state),
  accountId: get('accountId', getFormValues('teamFilters')(state)),
});

const mapDispatchToProps = dispatch => ({
  openSidePanel: data => dispatch(openSidePanel(data)),
  closeSidePanel: data => dispatch(closeSidePanel(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkspacesPage);
