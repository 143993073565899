import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, IconButton, Popover } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PieChartIcon from '@material-ui/icons/PieChart';
import TableChartIcon from '@material-ui/icons/TableChart';

const useStyles = makeStyles(theme => ({
  box: {
    width: '100%',
    height: '100%',
    minHeight: 270,
    padding: theme.spacing(1, 2, 2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 45,
    marginBottom: theme.spacing(2),
  },
  title: {},
  leftHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  toggleButton: {
    height: 30,
    padding: '0 3px 0 4px',
  },
  helpButton: {
    '&:last-child': {
      marginRight: -theme.spacing(1),
    },
  },
  popover: {
    padding: theme.spacing(1),
  },
}));

const HelpPopover = ({ Body }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <IconButton
        aria-describedby={id}
        variant="contained"
        className={classes.helpButton}
        onClick={handleClick}
      >
        <HelpOutlineIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.popover}>
          <Body />
        </div>
      </Popover>
    </>
  );
};

export default ({ Chart, Table, Help, title, unit }) => {
  const classes = useStyles();
  const [view, setView] = useState('chart');
  const handleView = (event, newView) => {
    newView && setView(newView);
  };

  return (
    <Paper className={classes.box}>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>

        <div className={classes.leftHeader}>
          {Help && <HelpPopover Body={Help} />}
          {Table && (
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={handleView}
              size="small"
              aria-label="text alignment"
            >
              <ToggleButton className={classes.toggleButton} value="chart" aria-label="chart">
                <PieChartIcon fontSize="small" />
              </ToggleButton>
              <ToggleButton className={classes.toggleButton} value="table" aria-label="table">
                <TableChartIcon fontSize="small" />
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        </div>
      </div>
      {view === 'chart' && <Chart />}
      {view === 'table' && <Table />}
    </Paper>
  );
};
