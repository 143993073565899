import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { AxiosError } from 'axios';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import useCreateSupplierColumn from 'hooks/useCreateSupplierColumn';
import React from 'react';
import { connect } from 'react-redux';
import { displayNotification } from 'state/notifications/actions';
import { Notification } from 'types/notification';
import * as Yup from 'yup';

interface Props {
  open: boolean;
  accountId: string;
  onClose: () => void;
  showNotification: (value: Notification) => void;
}

interface FormValues {
  name: string;
}

const initialValues: FormValues = {
  name: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

const ColumnModal = ({ open, onClose, showNotification, accountId }: Props) => {
  const { createSupplierColumn } = useCreateSupplierColumn();

  const handleSubmit = (
    values: FormValues,
    { resetForm, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    createSupplierColumn(
      { ...values, accountId },
      {
        onError: (error: unknown) => {
          const message =
            (error as AxiosError).response?.data.message || `Failed to add supplier column.`;

          showNotification({ type: 'error', message });
        },

        onSuccess: () => {
          showNotification({ type: 'success', message: 'Supplier column added successfully.' });
          resetForm();
        },

        onSettled: () => {
          setSubmitting(false);
        },
      }
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Add Column</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <DialogContent>
            <Box mb={2}>
              <Field name="name" label="Name" fullWidth component={TextField} autoFocus />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="contained">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

const mapDipsatchToProps = (dispatch: any) => ({
  showNotification: (value: Notification) => {
    dispatch(displayNotification(value));
  },
});

export default connect(null, mapDipsatchToProps)(ColumnModal);
