import uuid from 'uuid/v4';
import moment from 'moment';
import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';
import { map, get, flow } from 'lodash/fp';
import { withState, compose } from 'recompose';
import { Events, track, getEstimateData } from 'components/analytics';
import { countryOptionsByName } from 'components/countrySelect';
import { createSelectOptionFromString } from 'components/forms';
import { getCampaignById } from 'state/campaigns/selectors';
import { updateEstimate } from 'state/estimates/actions';
import {
  getEstimateById,
  getScriptTitlesForEstimate,
  getProductsForEstimate,
} from 'state/estimates/selectors';
import { getUserWorkspaces, getUserWorkspaceById } from 'state/workspaces/selectors';
import withEditConfirmationDialog from './withEditConfirmationDialog';
import EstimateDetailForm from './estimateDetailForm';
import { getMediaTypeOptionForMediaType } from './fields/estimateType';
import { BudgetCenter, ScaleOrNonScaleProject } from 'cr-core/constants';

const getWorkspaceOptionById = workspaceId =>
  flow(getUserWorkspaceById(workspaceId), workspace => ({
    value: get('id', workspace),
    label: get('name', workspace),
  }));

const mapStateToProps = (state, { match }) => {
  const estimate = getEstimateById(match.params.id)(state);
  const hasMultipleWorkspaces = getUserWorkspaces(state).length > 1;
  const mediaType = estimate.mediaType && getMediaTypeOptionForMediaType(estimate.mediaType);
  const selectedBudgetSource = get('customData.budgetSource', estimate);
  const budgetSource = selectedBudgetSource && createSelectOptionFromString(selectedBudgetSource);
  const selectedComplexity = get('customData.productionComplexity', estimate);
  const productionComplexity =
    selectedComplexity && createSelectOptionFromString(selectedComplexity);

  const scaleOrNonScale = estimate.scaleOrNonScale && {
    value: estimate.scaleOrNonScale,
    label: ScaleOrNonScaleProject[estimate.scaleOrNonScale],
  };

  const campaign = getCampaignById(estimate.campaignId)(state);
  const workspace = campaign && getWorkspaceOptionById(campaign.workspaceId)(state);
  const date = estimate.date && moment(estimate.date);
  const productionStartDate = estimate.productionStartDate && moment(estimate.productionStartDate);
  const deliveryDate = estimate.deliveryDate && moment(estimate.deliveryDate);
  const products = getProductsForEstimate(estimate);
  const parentWorkspace = campaign && getUserWorkspaceById(campaign.workspaceId)(state);
  const productNameOnly =
    parentWorkspace && parentWorkspace.account.accountSettings.productNameOnly;
  const budgetCenter = estimate.budgetCenter && {
    value: estimate.budgetCenter,
    label: BudgetCenter[estimate.budgetCenter],
  };
  const selectedBudgetYear = get('budgetYear', estimate);
  const budgetYear = selectedBudgetYear && createSelectOptionFromString(selectedBudgetYear);

  return {
    estimate,
    estimateId: estimate.id,
    hasMultipleWorkspaces,
    productNameOnly,
    initialValues: {
      mediaType,
      estimateName: estimate.name,
      numberOfOriginals: estimate.numberOfTVCs,
      numberOfEdits: estimate.numberOfRATVs,
      campaign: campaign && { label: campaign.name, value: campaign.id },
      scriptTitles: getScriptTitlesForEstimate(estimate.id)(state),
      products: map(({ brandName, productName, id }) => ({
        label: productNameOnly ? productName : `${brandName} ${productName}`,
        value: id,
      }))(products),
      payingCountries: flow(
        get('customData.payingCountries'),
        map(value => countryOptionsByName[value])
      )(estimate),
      leadMarket: flow(get('leadMarket'), value => countryOptionsByName[value])(estimate),
      additionalMarkets: flow(
        get('customData.additionalMarkets'),
        map(value => countryOptionsByName[value])
      )(estimate),
      budgetSource,
      budgetCenter,
      budgetYear,
      productionComplexity,
      workspace,
      date,
      poCode: estimate.poCode,
      bundle: estimate.bundle,
      marsGUID: estimate.marsGUID,
      scaleOrNonScale,
      productionStartDate,
      deliveryDate,
    },
  };
};

const mapDispatchToProps = (dispatch, { match, history, pageId }) => ({
  onSubmit: async formValues => {
    const estimate = await dispatch(updateEstimate(match.params.id, formValues, pageId));
    track(Events.ESTIMATE_EDIT_SUCCESS, getEstimateData(estimate));
    history.push(`/estimates/${match.params.id}`);
  },
  resetForm: () => {
    dispatch(reset('editEstimate'));
    history.push(`/estimates/${match.params.id}`);
  },
});

export default compose(
  withState('pageId', 'setPageId', () => uuid()),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'editEstimate' }),
  withEditConfirmationDialog('handleSubmit', { notifyOnly: true })
)(EstimateDetailForm);
