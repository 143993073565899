import { fetch } from 'state/fetchMiddleware';

export const FILE_CREATE_REQUEST = 'files/FILE_CREATE_REQUEST';
export const FILE_CREATE_SUCCESS = 'files/FILE_CREATE_SUCCESS';

export const createFile = (estimateId, filename, path) => async dispatch => {
  dispatch({ type: FILE_CREATE_REQUEST, estimateId, filename, path });
  const { data: file } = await dispatch(
    fetch(`/estimates/${estimateId}/files`, {
      method: 'POST',
      data: {
        filename,
        path,
      },
    })
  );
  dispatch({ type: FILE_CREATE_SUCCESS, estimateId, file });
};
