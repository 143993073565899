import styled from 'styled-components';
import { shadows } from 'shared/styles';

export const ActionBar = styled.div`
  position: fixed;
  z-index: 2;
  bottom: 0px;
  width: 100%;
  padding: 15px 96px 15px 10px;
  box-sizing: border-box;
  background-color: #ffffffdd;
  box-shadow: ${shadows.footerBar};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .button {
    width: auto;
  }
`;

export const LeftActions = styled.div`
  margin-right: auto;
  display: flex;
  flex-direction: row;
`;
