import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getFormValues } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button } from '@material-ui/core';
import { compact, get, filter, find, differenceBy, noop } from 'lodash/fp';
import { getSidePanelData } from 'state/ui/selectors';
import Menu from 'components/common/menu';
import ConfirmationModal from 'components/modals/confirmationModal';
import { fetchAgencies } from 'state/accounts/actions';
import { getAgencies, getAccountById } from 'state/accounts/selectors';
import { getUser as getLoggedUser } from 'state/authentication/selectors';
import actionWrapper from 'state/actionWrapper';
import Teams from 'components/teams/list';
import { fetchTeams } from 'state/teams/actions';
import { getTeams } from 'state/teams/selectors';
import {
  fetchWorkspace,
  fetchWorkspaces,
  deleteWorkspace,
  updateWorkspace,
  addTeam,
  removeTeam,
  addAgency,
  removeAccount,
} from 'state/workspaces/actions';
import { getWorkspaceById, doesUserOwnWorkspace } from 'state/workspaces/selectors';
import Agencies from './agencies';
import ListSelectorModal from './listSelectorModal';
import WorkspaceMembersModal from './workspaceMembersModal';
import EditValueModal from 'components/modals/setValueModal';

const ADD_TEAM_ACTION = 'ADD_TEAM_ACTION';
const REMOVE_TEAM_ACTION = 'REMOVE_TEAM_ACTION';
const ADD_AGENCY_ACTION = 'ADD_AGENCY_ACTION';
const REMOVE_AGENCY_ACTION = 'REMOVE_AGENCY_ACTION';
const MEMBERS_LIST = 'MEMBERS_LIST';
const EDIT_WORKSPACE_NAME_ACTION = 'EDIT_WORKSPACE_NAME_ACTION';

const useStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(4, 3),
    position: 'relative',
  },
  menuButton: {
    position: 'absolute',
    right: 2,
    top: 10,
  },
  listContainer: {
    margin: theme.spacing(2, 0),
  },
  emptyListMessage: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2, 1),
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[300],
  },

  list: {
    height: '100%',
    overflowY: 'scroll',
  },
}));

const Details = ({
  loggedUser,
  editWorkspaceName,
  deleteWorkspace,
  addTeam,
  removeTeam,
  workspace,
  teams,
  addAgency,
  removeAgency,
  removeAccount,
  agencies,
  fetchAgencies,
  fetchTeams,
  selectedAccount,
  userOwnWorkspace,
  filters,
}) => {
  const classes = useStyles();
  const [modal, setModal] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const menuItems = compact([
    userOwnWorkspace && {
      label: 'Edit Workspace Name',
      onClick: () => showEditWorkspaceNameModal(),
    },
    userOwnWorkspace && {
      label: 'Delete Workspace',
      onClick: () => deleteWorkspace(workspace, filters),
    },
  ]);

  useEffect(() => {
    if (!agencies.length) {
      fetchAgencies();
    }
    fetchTeams({ accountId: selectedAccount.id });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!workspace) {
    return null;
  }

  const openModal = options => {
    setModal(options.action);
    setShowModal(true);
    setModalData(options);
  };

  const handleCloseModal = action => item => {
    if (item) {
      action(item);
    }
    setShowModal(false);
    setModal();
    setModalData({});
  };

  const showMembersModal = () =>
    openModal({
      action: MEMBERS_LIST,
    });

  const showAddTeamModal = () =>
    openModal({
      action: ADD_TEAM_ACTION,
      list: differenceBy('id', teams, workspace.teams),
      title: 'Select the team you want to add',
      actionLabel: 'Select Team',
    });

  const handleAddTeamClose = handleCloseModal(team => addTeam(workspace, team, selectedAccount.id));

  const removeTeamWithConfirmation = teamId => {
    const team = find({ id: teamId }, teams);
    if (team) {
      openModal({
        action: REMOVE_TEAM_ACTION,
        team,
        title: 'Do you want to remove the team?',
        message: `"${team.name}" team will be removed from the workspace. All the users in the team won't be able to access to the data of the workspace anymore.`,
        actionLabel: 'Remove',
      });
    }
  };

  const handleRemoveTeamClose = handleCloseModal(() =>
    removeTeam(workspace, modalData.team, selectedAccount.id),
  );

  const showEditWorkspaceNameModal = () =>
    openModal({
      action: EDIT_WORKSPACE_NAME_ACTION,
      workspace,
      title: 'Edit team name',
      actionLabel: 'Save',
    });
  const handleEditWorkspaceNameClose = handleCloseModal(name => editWorkspaceName(workspace, name));

  const showAddAgencyModal = () =>
    openModal({
      action: ADD_AGENCY_ACTION,
      list: differenceBy('id', agencies, workspace.externalAccounts),
      title: 'Select the agency you want to add',
      actionLabel: 'Select Agency',
    });

  const handleAddAgencyClose = handleCloseModal(agency =>
    addAgency(workspace, agency, selectedAccount.id),
  );

  const removeAgencyWithConfirmation = agencyId => {
    const agency = find({ id: agencyId }, agencies);
    openModal({
      action: REMOVE_AGENCY_ACTION,
      agency,
      title: 'Do you want to remove the agency?',
      message: `"${agency.name}" team will be removed from the workspace. All the users in the agency won't be able to access to the data of the workspace anymore.`,
      actionLabel: 'Remove',
    });
  };

  const handleRemoveAgencyClose = handleCloseModal(() =>
    removeAccount(workspace, modalData.agency, selectedAccount.id),
  );

  return (
    <div className={classes.body}>
      {loggedUser.isAdmin && <Menu items={menuItems} className={classes.menuButton} />}
      <Grid container spacing={2}>
        <Typography variant="h5" className={classes.accountName}>
          {workspace.name}
        </Typography>

        <Teams
          selectedTeams={workspace.teams}
          teams={teams}
          addTeam={showAddTeamModal}
          removeTeam={removeTeamWithConfirmation}
        />
        {!get('agency', selectedAccount) && (
          <Agencies
            selectedAgencies={workspace.externalAccounts}
            agencies={agencies}
            addAgency={showAddAgencyModal}
            removeAgency={removeAgencyWithConfirmation}
          />
        )}

        <Button variant="contained" className={classes.listButton} onClick={showMembersModal}>
          Show Workspace Members
        </Button>

        {modal === MEMBERS_LIST && (
          <WorkspaceMembersModal
            open={showModal}
            onClose={handleCloseModal(noop)}
            workspaceId={workspace.id}
          />
        )}

        {modal === ADD_TEAM_ACTION && (
          <ListSelectorModal
            open={showModal}
            onClose={handleAddTeamClose}
            list={modalData.list}
            title={modalData.title}
            message={modalData.message}
            actionLabel={modalData.actionLabel}
          />
        )}
        {modal === REMOVE_TEAM_ACTION && (
          <ConfirmationModal
            open={showModal}
            handleClose={handleRemoveTeamClose}
            title={modalData.title}
            message={modalData.message}
            actionLabel={modalData.actionLabel}
          />
        )}

        {modal === ADD_AGENCY_ACTION && (
          <ListSelectorModal
            open={showModal}
            onClose={handleAddAgencyClose}
            list={modalData.list}
            title={modalData.title}
            message={modalData.message}
            actionLabel={modalData.actionLabel}
          />
        )}
        {modal === REMOVE_AGENCY_ACTION && (
          <ConfirmationModal
            open={showModal}
            handleClose={handleRemoveAgencyClose}
            title={modalData.title}
            message={modalData.message}
            actionLabel={modalData.actionLabel}
          />
        )}
        {modal === EDIT_WORKSPACE_NAME_ACTION && (
          <EditValueModal
            open={showModal}
            handleClose={handleEditWorkspaceNameClose}
            title={modalData.title}
            message={modalData.message}
            actionLabel={modalData.actionLabel}
            value={workspace.name}
            valueLabel="Name"
          />
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  const filters = getFormValues('workspaceFilters')(state) || {};
  const accountId = get('accountId', filters);
  const workspaceId = get('workspace.id', getSidePanelData(state));
  const allTeams = getTeams(state);
  const teams = filter({ accountId }, allTeams);
  const workspace = getWorkspaceById(workspaceId)(state);

  return {
    loggedUser: getLoggedUser(state),
    workspace,
    agencies: getAgencies(state),
    selectedAccount: getAccountById(accountId)(state),
    teams,
    userOwnWorkspace: doesUserOwnWorkspace(workspace)(state),
    filters: getFormValues('workspaceFilters')(state) || {},
  };
};

const mapDispatchToProps = (dispatch, ...props) => ({
  deleteWorkspace: async (workspace, filters) => {
    const success = await actionWrapper(
      dispatch,
      deleteWorkspace(workspace.id),
      `Workspace "${workspace.name}" deleted`,
      true,
    );
    if (success) {
      dispatch(fetchWorkspaces(filters));
    }
  },
  editWorkspaceName: (workspace, name) =>
    actionWrapper(
      dispatch,
      updateWorkspace(workspace.id, { name }),
      `workspace "${workspace.name}" updated`,
    ),
  fetchAgencies: () => dispatch(fetchAgencies()),
  fetchTeams: filters => dispatch(fetchTeams(filters)),
  addTeam: async (workspace, team, accountId) => {
    await actionWrapper(
      dispatch,
      addTeam(workspace.id, team.id),
      `Team "${workspace.name}" added to the workspace`,
    );
    dispatch(fetchWorkspace(workspace.id, accountId));
  },
  removeTeam: async (workspace, team, accountId) => {
    await actionWrapper(
      dispatch,
      removeTeam(workspace.id, team.id),
      `Team "${workspace.name}" removed from the workspace`,
    );
    dispatch(fetchWorkspace(workspace.id, accountId));
  },
  addAgency: async (workspace, agency, accountId) => {
    await actionWrapper(
      dispatch,
      addAgency(workspace.id, agency.id),
      `Agency "${agency.name}" added to the workspace`,
    );
    dispatch(fetchWorkspace(workspace.id, accountId));
  },
  removeAccount: async (workspace, agency, accountId) => {
    await actionWrapper(
      dispatch,
      removeAccount(workspace.id, agency.id),
      `Agency "${agency.name}" removed from the workspace`,
    );
    dispatch(fetchWorkspace(workspace.id, accountId));
  },
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Details);
