import { EstimateStatuses, EstimateApprovalStatuses } from 'cr-core/constants';
import { colors } from 'shared/styles';

export const estimateStatusOrder = {
  [EstimateStatuses.DRAFT]: 1,
  [EstimateStatuses.PENDING_APPROVAL]: 2,
  [EstimateStatuses.APPROVED]: 3,
  [EstimateStatuses.ACTUALISED]: 4,
  [EstimateStatuses.CANCELLED]: 5,
};

export const statusColor = {
  [EstimateStatuses.DRAFT]: colors.draft,
  [EstimateStatuses.PENDING_APPROVAL]: colors.pendingApproval,
  [EstimateStatuses.APPROVED]: colors.approved,
  [EstimateStatuses.ACTUALISED]: colors.actualised,
  [EstimateStatuses.CANCELLED]: colors.cancelled,
  [EstimateApprovalStatuses.CHANGES_REQUESTED]: colors.requestChanges,
};

export const sortEstimates = estimates =>
  estimates.sort((a, b) => {
    if (estimateStatusOrder[a.status] < estimateStatusOrder[b.status]) {
      return -1;
    }
    if (estimateStatusOrder[a.status] > estimateStatusOrder[b.status]) {
      return 1;
    }
    return a.updatedAt <= b.updatedAt ? a : b;
  });
