import React from 'react';

const SvgBrandedEntertainmentIcon = (props: any) => {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
      width="1em"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path d="m159.862 222.099c2.592 0 5.188-.66 7.568-1.982l47.978-26.654c4.942-2.745 8.012-7.962 8.012-13.615s-3.069-10.871-8.012-13.616l-47.977-26.654c-4.875-2.708-10.656-2.637-15.463.191-4.807 2.829-7.677 7.847-7.677 13.425v53.308c0 5.577 2.87 10.596 7.677 13.424 2.46 1.447 5.175 2.173 7.894 2.173zm-.571-68.906c0-.115 0-.33.284-.497.115-.067.213-.09.297-.09.124 0 .214.05.274.083l47.977 26.654c.111.062.296.165.296.503s-.185.442-.296.503l-47.977 26.654c-.101.056-.288.161-.572-.007-.284-.167-.284-.381-.284-.496v-53.307z" />
        <path d="m480.452 52.105h-448.904c-17.396 0-31.548 14.152-31.548 31.548v280.564c0 17.396 14.152 31.548 31.548 31.548h166.749l-12.282 49.129h-26.209c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h192.387c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-26.209l-12.282-49.129h166.749c17.396 0 31.548-14.152 31.548-31.548v-280.564c.001-17.396-14.151-31.548-31.547-31.548zm-448.904 15h448.903c9.125 0 16.548 7.423 16.548 16.548v265.048h-137.306v-48.613c0-.285.231-.516.516-.516h96.194c.285 0 .516.231.516.516v24.048c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-24.048c0-8.556-6.96-15.516-15.516-15.516h-96.194c-8.556 0-15.516 6.96-15.516 15.516v48.613h-329.693v-265.048c0-9.125 7.423-16.548 16.548-16.548zm278.975 377.79h-109.046l12.282-49.129h84.482zm169.929-64.129h-448.904c-9.125 0-16.548-7.423-16.548-16.548v-.516h482v.516c0 9.125-7.423 16.548-16.548 16.548z" />
        <path d="m55.597 267.508h256.516c8.556 0 15.516-6.961 15.516-15.517v-144.29c0-8.556-6.96-15.516-15.516-15.516h-208.416c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h208.416c.285 0 .516.231.516.516v144.29c0 .285-.231.517-.516.517h-256.516c-.285 0-.516-.232-.516-.517v-144.29c0-.285.231-.516.516-.516h16.034c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-16.034c-8.556 0-15.516 6.96-15.516 15.516v144.29c0 8.556 6.96 15.517 15.516 15.517z" />
        <path d="m360.209 171.314h96.194c8.556 0 15.516-6.96 15.516-15.516v-48.097c0-8.556-6.96-15.516-15.516-15.516h-96.194c-8.556 0-15.516 6.96-15.516 15.516v48.097c0 8.556 6.961 15.516 15.516 15.516zm-.516-63.612c0-.285.231-.516.516-.516h96.194c.285 0 .516.231.516.516v48.097c0 .285-.231.516-.516.516h-96.194c-.285 0-.516-.231-.516-.516z" />
        <path d="m360.209 267.508h96.194c8.556 0 15.516-6.961 15.516-15.517v-48.097c0-8.556-6.96-15.516-15.516-15.516h-96.194c-8.556 0-15.516 6.96-15.516 15.516v48.097c0 8.556 6.961 15.517 15.516 15.517zm-.516-63.613c0-.285.231-.516.516-.516h96.194c.285 0 .516.231.516.516v48.097c0 .285-.231.517-.516.517h-96.194c-.285 0-.516-.232-.516-.517z" />
        <path d="m47.581 299.573h272.548c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-272.548c-4.142 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5z" />
        <path d="m47.581 331.637h32.064c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-32.064c-4.142 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5z" />
        <path d="m256 316.637h-144.291c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h144.291c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
      </g>
    </svg>
  );
};

export default SvgBrandedEntertainmentIcon;
