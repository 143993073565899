import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import { compose, getContext, withHandlers, withProps, withState } from 'recompose';
import { get } from 'lodash/fp';
import { connect } from 'react-redux';
import { Events, track, getEstimateData } from 'components/analytics';
import withEditConfirmationDialog from 'components/estimateEdit/withEditConfirmationDialog';
import SaveCancelPopup from 'components/forms/saveCancelPopup';
import { shadows } from 'shared/styles';
import { updateEstimateNotes } from 'state/estimates/actions';

const EditableContainer = styled.div`
  width: 100%;
  height: 100%;
  text-decoration: none !important;
  padding: 0px;
  box-shadow: ${shadows.inputBoxShadow};
  border-radius: 0 0 3px 3px;
  overflow: visible;

  textarea {
    &:focus {
      outline: none;
    }
    cursor: initial;
    width: 100%;
    min-height: 150px;
    height: 100% !important;
    border: none;
    background-color: transparent !important;
  }
`;

const EditableNotesCell = ({
  handleSubmit,
  cancel,
  isDirty,
  value,
  onChange,
  handleFocus,
  className,
}) => (
  <EditableContainer className={className}>
    <textarea
      defaultValue={value}
      name="notes"
      autoFocus
      onChange={onChange}
      onFocus={handleFocus}
    />
    {isDirty && <SaveCancelPopup onSave={handleSubmit} onCancel={cancel} />}
  </EditableContainer>
);

const mapDispatchToProps = (dispatch, { value, estimate, save }) => ({
  handleSubmit: e => {
    dispatch(updateEstimateNotes(estimate.id, { notes: value }));
    save(e);
  },
});

export default compose(
  getContext({ estimate: PropTypes.object }),
  withProps(({ estimate }) => ({ initialValue: estimate.notes })),
  withState('value', 'setValue', get('initialValue')),
  withHandlers({
    onChange: ({ setValue }) => event => setValue(event.target.value),
  }),
  connect(
    null,
    mapDispatchToProps
  ),
  withProps(({ initialValue, value }) => ({
    handleFocus: event => event.target.select(),
    isDirty: value !== initialValue,
  })),
  withEditConfirmationDialog('handleSubmit', { notifyOnly: true }),
  withProps(({ isDirty, handleSubmit, save, value = '', estimate }) => ({
    saveValue: event => {
      if (isDirty) {
        handleSubmit();
        track(Events.ESTIMATE_EDIT_NOTES, {
          ...getEstimateData(estimate),
          notesLength: get('length', value),
          notesLines: (value.match(/\n/g) || []).length,
        });
      } else {
        save();
      }
    },
  })),
  withProps(({ saveValue }) => ({ handleClickOutside: evt => saveValue() })),
  onClickOutside
)(EditableNotesCell);
