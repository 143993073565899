import React from 'react';

const SvgRequestChangesIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="request-changes-icon_svg__a"
        width="140%"
        height="129.6%"
        x="-20%"
        y="-14.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      d="M3.37 13.5a6.43 6.43 0 0 0 .771 3.076.49.49 0 0 1-.085.583l-1.734 1.726a.5.5 0 0 1-.772-.085A9.833 9.833 0 0 1 0 13.5c0-5.332 4.209-9.682 9.503-9.94V.494c0-.44.536-.66.849-.348l4.751 4.73a.49.49 0 0 1 0 .696l-4.751 4.73a.497.497 0 0 1-.849-.35V6.918C6.077 7.171 3.37 10.025 3.37 13.5zm13.26 0a6.593 6.593 0 0 0-.775-3.082.49.49 0 0 1 .089-.577l1.734-1.726a.5.5 0 0 1 .772.085A9.833 9.833 0 0 1 20 13.5c0 5.332-4.209 9.682-9.503 9.94v3.066c0 .44-.536.66-.849.348l-4.751-4.73a.49.49 0 0 1 0-.696l4.751-4.73a.497.497 0 0 1 .849.35v3.035c3.426-.254 6.133-3.108 6.133-6.583z"
      filter="url(#request-changes-icon_svg__a)"
      transform="translate(5 2)"
    />
  </svg>
);

export default SvgRequestChangesIcon;
