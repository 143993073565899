import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  flow,
  map,
  filter,
  groupBy,
  sumBy,
  sortBy,
  mapValues,
  toPairs,
  flatten,
  min,
  max,
  forEach,
  findIndex,
  find,
  getOr,
  compact,
} from 'lodash/fp';
import { EstimateStatuses } from 'cr-core/constants';
import { getDisplayCurrency } from 'state/authentication/selectors';
import { ChartContainer } from '../components';
import { LineChart } from 'components/charts';

const generateEmptyDataPoints = (minX, maxX) => {
  const data = [];
  for (let i = moment(minX).startOf('day'); i.toDate() <= maxX; i.add(1, 'days')) {
    data.push({ x: i.toDate(), reportingDate: moment(i).format('DD-MM-YYYY'), y: 0 });
  }
  return data;
};

const fillEmptyDataPoint = (data, minX, maxX) => {
  const newData = generateEmptyDataPoints(minX, maxX);
  let prevY = 0;
  forEach(item => {
    const newItemIndex = findIndex({ reportingDate: item.reportingDate }, newData);
    newData[newItemIndex] = item;
    prevY += item.y;
    newData[newItemIndex].y = prevY;
  }, data);

  for (let i = 1; i < newData.length; i++) {
    if (newData[i].y === 0) {
      newData[i].y = newData[i - 1].y;
    }
  }

  return newData;
};

const getChartData = (data, displayCurrency) => {
  let datasets = flow(
    filter(({ date }) => date),
    map(({ id, status, date, costs }) => ({
      value: getOr(0, 'lowest', costs),
      estimateId: id,
      status: status,
      reportingDate: moment(date).format('DD-MM-YYYY'),
      sortingKey: moment(date).format('YYYY-MM-DD'),
    })),
    groupBy('status'),
    mapValues(groupBy('reportingDate')),
    mapValues(
      mapValues(items => ({
        value: sumBy('value', items),
        status: items[0].status,
        reportingDate: items[0].reportingDate,
        sortingKey: items[0].sortingKey,
        estimateIds: map('estimateId', items),

        x: moment(items[0].reportingDate, 'DD-MM-YYYY').toDate(),
        y: sumBy('value', items),
      }))
    ),
    toPairs,
    map(([label, data]) => ({
      label,
      data,
    })),
    map(({ data, label }) => ({ label, data: sortBy(['sortingKey'], data) }))
  )(data);

  const uniqX = flow(
    map('data'),
    flatten,
    map('x')
  )(datasets);
  const minX = min(uniqX);
  const maxX = max(uniqX);

  datasets = flow(
    map(({ label, data }) => ({
      label,
      data: fillEmptyDataPoint(data, minX, maxX),
    }))
  )(datasets);

  const emptyDataset = generateEmptyDataPoints(minX, maxX);
  return compact([
    find({ label: EstimateStatuses.ACTUALISED }, datasets) || {
      label: EstimateStatuses.ACTUALISED,
      data: emptyDataset,
    },
    find({ label: EstimateStatuses.APPROVED }, datasets) || {
      label: EstimateStatuses.APPROVED,
      data: emptyDataset,
    },
    find({ label: EstimateStatuses.PENDING_APPROVAL }, datasets) || {
      label: EstimateStatuses.PENDING_APPROVAL,
      data: emptyDataset,
    },
    find({ label: EstimateStatuses.DRAFT }, datasets) || {
      label: EstimateStatuses.DRAFT,
      data: emptyDataset,
    },
    find({ label: EstimateStatuses.CANCELLED }, datasets) || {
      label: EstimateStatuses.CANCELLED,
      data: emptyDataset,
    },
  ]);
};

const Table = ({ estimates = [], displayCurrency }) => {
  return (
    <ChartContainer>
      <LineChart
        data={getChartData(estimates, displayCurrency)}
        displayCurrency={displayCurrency}
        width={100}
        height={40}
        stacked={true}
      />
    </ChartContainer>
  );
};

const mapStateToProps = state => ({
  displayCurrency: getDisplayCurrency(state),
});

export default connect(mapStateToProps)(Table);
