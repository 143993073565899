import React from 'react';
import clsx from 'clsx';
import color from 'color';
import { forEach } from 'lodash/fp';
import { EstimateStatuses, EstimateStatusLabels, CampaignStatuses } from 'cr-core/constants';
import { statusColor } from '../utils';

import { makeStyles } from '@material-ui/core/styles';

import { Doughnut } from 'react-chartjs-2';

const formatValue = val => parseFloat(val.toFixed(2));

const buildChartData = (statusBreakdown = {}, totalSpend, totalBudget) => {
  let remaining = totalBudget - totalSpend;
  const data = [];
  const labels = [];
  const chartColors = [];

  forEach(([status, cost]) => {
    if (status === EstimateStatuses.CANCELLED) {
      return;
    }
    labels.push(EstimateStatusLabels[status]);
    data.push(formatValue(cost));
    chartColors.push(
      color(statusColor[status])
        .alpha(0.4)
        .string()
    );
  }, statusBreakdown);

  if (remaining > 0) {
    data.push(remaining);
    labels.push('Budget Left');
    chartColors.push('#cccccc');
  }
  return {
    labels,
    datasets: [
      {
        data,
        backgroundColor: chartColors,
        hoverBorderColor: 'white',
        hoverBackgroundColor: chartColors,
      },
    ],
  };
};

const Chart = ({ data, displayCurrency, width, height }) => (
  <Doughnut
    data={data}
    width={width}
    height={height}
    options={{
      maintainAspectRatio: true,
      cutoutPercentage: 85,
      legend: false,
      tooltips: { enabled: false },

      // disable animation
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
    }}
  />
);

const useStyles = makeStyles(theme => ({
  chartContainer: {
    position: 'relative',
    width: 110,
  },
  chartValueContainer: {
    position: 'absolute',
    fontSize: 24,
    fontWeight: 'bold',
    top: 'calc(50% - 16px)',
    left: 'calc(50% - 32px)',
    width: 70,
    textAlign: 'center',
  },
  chartValue: {
    lineHeight: '20px',
  },
  chartValueLabel: {
    fontSize: 10,
  },
  overbudget: {
    color: theme.palette.error.main,
  },
  savings: {
    color: theme.palette.success.main,
  },
  perc: {
    fontSize: 16,
    marginLeft: 2,
  },
}));

export default ({ statusBreakdown, totalSpend, budget, status, displayCurrency }) => {
  const classes = useStyles();
  const data = buildChartData(statusBreakdown, totalSpend, budget);
  let perc = Math.abs(Math.round((1 - totalSpend / budget) * 100));

  const label =
    totalSpend <= budget
      ? status === CampaignStatuses.ACTUALISED
        ? 'savings'
        : 'budget left'
      : 'overbudget';

  return (
    <div className={classes.chartContainer}>
      <Chart data={data} height={110} width={110} />
      {Boolean(budget) && (
        <div
          className={clsx(classes.chartValueContainer, {
            [classes.savings]: totalSpend < budget && status === CampaignStatuses.ACTUALISED,
            [classes.overbudget]: totalSpend > budget,
          })}
          data-test="campaign-budget-percentage"
        >
          <div className={classes.chartValue}>
            {perc}
            <span className={classes.perc}>%</span>
          </div>

          <div className={classes.chartValueLabel}>{label}</div>
        </div>
      )}
    </div>
  );
};
