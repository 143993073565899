import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';
import { map, find } from 'lodash/fp';
import { AvailableMediaTypes, MediaTypeLabels, DemoAdditionalTypes } from 'cr-core/constants';
import { InverseRoundButton } from 'components/buttons';
import { FieldContainer, InputContainer, Label, SubLabel, Select } from 'components/forms';

import { MediaTypeIcon } from 'icons';
import { font, colors } from 'shared/styles';
import { connect } from 'react-redux';
import { getEstimateTypeOptions } from 'state/estimates/selectors';

const MediaTypePlaceHolder = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.placeholder};
`;

const MediaTypeListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0px;
  }

  &.focused {
    background-color: ${colors.recommendedColumn};
  }

  &.selected {
    padding: 5px 0px;
  }

  .avatar {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    margin-right: 10px;
  }

  .icon {
    margin-right: 10px;
  }
`;

const HelpButton = styled(InverseRoundButton)`
  width: 25px;
  height: 25px;
  font-size: ${font.sizes.large};
`;

const HelpLink = styled(({ className, to }) => {
  return (
    <a
      className={className}
      href="https://help-app.murphycobb.com/en/articles/6009392-creating-estimates-mars"
      target="_blank"
      rel="noopener noreferrer"
    >
      <HelpButton>?</HelpButton>
    </a>
  );
})`
  display: flex;
  text-decoration: none;
  margin-left: 10px;
  align-items: center;
`;

const MediaTypeOption = props => {
  const { innerProps, innerRef, data, isFocused } = props;
  return (
    <div ref={innerRef} {...innerProps} className="reactSelect__option">
      <MediaTypeListItem
        className={`${isFocused ? 'focused' : ''} ${innerProps ? '' : 'selected'}`}
      >
        <MediaTypeIcon type={data.value} />
        {data.label}
      </MediaTypeListItem>
    </div>
  );
};

const mediaTypeOptions = [
  ...map(value => ({ value, label: MediaTypeLabels[value] }), AvailableMediaTypes),
  ...DemoAdditionalTypes,
];

export const getMediaTypeOptionForMediaType = mediaType => {
  if (mediaType === 'AV') {
    // legacy
    return { value: mediaType, label: MediaTypeLabels[mediaType] };
  }
  return find({ value: mediaType }, mediaTypeOptions);
};

const EstimateType = ({ estimateTypeOptions }) => {
  return (
    <FieldContainer>
      <Label htmlFor="mediaType">
        Estimate Type
        <SubLabel>What are you producing?</SubLabel>
      </Label>
      <InputContainer>
        <Field
          name="mediaType"
          component={({ input }) => {
            return (
              <Select
                id="select-estimateType"
                placeholder="Select Estimate Type..."
                options={estimateTypeOptions}
                onChange={input.onChange}
                value={input.value}
                isSearchable={false}
                components={{
                  Option: MediaTypeOption,
                  SingleValue: MediaTypeOption,
                  Placeholder: MediaTypePlaceHolder,
                }}
                maxMenuHeight={400}
              />
            );
          }}
        />
      </InputContainer>
      <HelpLink to="media-types" target="_blank" />
    </FieldContainer>
  );
};

const mapStateToProps = state => ({
  estimateTypeOptions: getEstimateTypeOptions(state),
});

export default connect(mapStateToProps)(EstimateType);
