import React from 'react';
import { noop } from 'lodash/fp';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Events, track, getCampaignData } from 'components/analytics';
import ConfirmationModal from 'components/modals/confirmationModal';
import { closeCampaign } from 'state/campaigns/actions';
import { getCampaignById } from 'state/campaigns/selectors';
import { parseQueryFilters } from 'components/filtersSidebar';

const CloseCampaignModal = ({ open, closeCampaign, campaign, onModalClose = noop }) => {
  return (
    <ConfirmationModal
      open={open}
      title="Close Campaign"
      message={
        <>
          Do you want to close this campaign?
          <br />
          When you close a campaign you are confirming that no more estimates will be added in the
          campaign.
        </>
      }
      actionLabel="Close campaign"
      handleClose={async confirm => {
        track(
          confirm ? Events.CAMPAIGN_CLOSE_SUCCESS : Events.CAMPAIGN_CLOSE_CANCE,
          getCampaignData(campaign),
        );
        if (confirm) {
          await closeCampaign(parseQueryFilters(window.location.search));
        }
        onModalClose();
      }}
    />
  );
};

const mapStateToProps = (state, { campaignId }) => ({
  campaign: getCampaignById(campaignId)(state),
});

const mapDispatchToProps = (dispatch, { campaignId }) => ({
  closeCampaign: query => dispatch(closeCampaign(campaignId, query)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(CloseCampaignModal);
