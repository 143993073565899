import { format as formatCsv } from 'fast-csv';
import { get } from 'lodash/fp';
import blobStream, { IBlobStream } from 'blob-stream';

declare global {
  interface Navigator {
      msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}
const moment = require('moment');

interface Column<T> {
  value: string | ((value: T) => string);
  label: string;
  type?: string;
}

const csvDownload = <T>(data: T[], columns: Column<T>[], fileName: string) => {
  const stream = formatCsv({
    headers: columns.map(({ label }) => label),
    writeHeaders: true,
    alwaysWriteHeaders: true,
    writeBOM: true,
  });

  data.forEach(row => {
    const record = columns.reduce((acc, { label, value, type }) => {
      const columnValue =
        typeof value === 'string'
          ? get(value, row)
          : type === 'date' && value(row)
          ? moment(value(row)).format('DD-MMM-YYYY')
          : value(row);

      acc[label] = columnValue;

      return acc;
    }, {} as Record<string, string>);

    stream.write(record);
  });

  stream.pipe(blobStream()).on('finish', function (this: IBlobStream) {
    const file = `${fileName}.csv`;

    if (navigator.msSaveBlob) {
      const blob = this.toBlob('text/csv;charset=utf-8');

      // IE 10+
      navigator.msSaveBlob(blob, file);
    } else {
      const url = this.toBlobURL('text/csv;charset=utf-8');
      const link = document.createElement('a');

      link.setAttribute('href', url);
      link.setAttribute('download', file);

      link.style.display = 'none';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  });

  stream.end();
};

export default csvDownload;
