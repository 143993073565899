import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { compose, withHandlers } from 'recompose';
import { Events, track, getEstimateData } from 'components/analytics';
import { SmallFormButton } from 'components/buttons';
import SvgAddIcon from 'icons/AddIcon';
import { addBidToEstimate } from 'state/bids/actions';
import { getEstimateById } from 'state/estimates/selectors';
import withEditConfirmationDialog from '../estimateEdit/withEditConfirmationDialog';

const Button = styled(SmallFormButton)`
  height: auto;
  width: auto;
  margin: 5px;
  padding: 15px;
  border: 0;
  opacity: 0.8;
  transition: 0.2s opacity;

  &:hover {
    opacity: 1;
  }

  .icon {
    font-size: inherit;
  }
`;

const AddBidButton = ({ onClick, trackClick, style }) => (
  <Button
    onClick={() => {
      trackClick();
      onClick();
    }}
    style={style}
    data-test="add-bid"
  >
    <SvgAddIcon className="icon" />
    Add Bid
  </Button>
);

const mapStateToProps = (state, { estimateId }) => ({
  estimate: getEstimateById(estimateId)(state),
});

const mapDispatchToProps = (dispatch, { estimateId, pageId }) => ({
  onClick: () => dispatch(addBidToEstimate({ estimateId, pageId })),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    trackClick: ({ estimate }) => () =>
      track(Events.ESTIMATE_ADD_BID_CLICK, getEstimateData(estimate)),
  }),
  withEditConfirmationDialog('onClick', {
    trackEvent: {
      success: Events.ESTIMATE_ADD_BID_SUCCESS,
      cancel: Events.ESTIMATE_ADD_BID_CANCEL,
    },
  })
)(AddBidButton);
