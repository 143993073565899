import { Field } from 'redux-form';
import { connect } from 'react-redux';
import React from 'react';
import { map } from 'lodash/fp';
import { FieldContainer, InputContainer, Label, SubLabel, Select } from 'components/forms';
import { getUserWorkspaces } from 'state/workspaces/selectors';

const convertWorkspaceToOptions = map(workspace => ({
  label: workspace.name,
  value: workspace.id,
}));

const mapStateToProps = state => ({
  workspaces: convertWorkspaceToOptions(getUserWorkspaces(state)),
});

const AssignWorkspace = ({ workspaces, disabled }) => {
  return (
    <FieldContainer>
      <Label htmlFor="workspace">
        Workspace <SubLabel>Which workspace does this estimate belong to?</SubLabel>
      </Label>
      <InputContainer>
        <Field
          name="workspace"
          component={({ input }) => (
            <Select
              id="select-workspace"
              placeholder="Select Workspace..."
              options={workspaces}
              onChange={input.onChange}
              value={input.value}
              isDisabled={disabled}
            />
          )}
        />
      </InputContainer>
    </FieldContainer>
  );
};

export default connect(mapStateToProps)(AssignWorkspace);
