import React from 'react';
import { map, differenceBy } from 'lodash/fp';
import { makeStyles, fade, lighten } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  IconButton,
  ListItemSecondaryAction,
  ListItem,
  List,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import UserAvatar from 'components/userAvatar';
import { formatUserName } from 'cr-core/userUtils';

const useStyles = makeStyles(theme => ({
  listContainer: {
    margin: theme.spacing(3, 0),
    width: '100%',
  },
  emptyListMessage: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2, 1),
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[300],
  },
  listItemBorder: {
    border: `1px solid ${lighten(fade(theme.palette.divider, 1), 0.88)}`,
    marginBottom: -1,
  },
  listItemButton: {
    color: theme.palette.grey[400],
  },
  listButton: {
    marginTop: theme.spacing(1),
  },
  username: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default ({ selectedUsers = [], users = [], addUser, removeUser }) => {
  const classes = useStyles();
  const availableUsers = differenceBy('id', users, selectedUsers);
  return (
    <div className={classes.listContainer}>
      <Typography variant="h6">Users</Typography>
      {Boolean(selectedUsers.length) && (
        <List>
          {map(
            user => (
              <ListItem key={user.id} className={classes.listItemBorder}>
                <UserAvatar key={user.id} user={user} showName={false} data-test="user-avatar" />
                <div className={classes.username}>{formatUserName(user, false)}</div>
                {removeUser && (
                  <ListItemSecondaryAction onClick={() => removeUser(user.id)}>
                    <IconButton edge="end" aria-label="delete" className={classes.listItemButton}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ),
            selectedUsers
          )}
        </List>
      )}

      {!selectedUsers.length && <div className={classes.emptyListMessage}>No user selected</div>}

      {Boolean(availableUsers.length && addUser) && (
        <Button
          variant="contained"
          className={classes.listButton}
          startIcon={<AddIcon />}
          onClick={addUser}
        >
          Add User
        </Button>
      )}

      {Boolean(!availableUsers.length && addUser) && (
        <Button variant="contained" className={classes.listButton} startIcon={<AddIcon />} disabled>
          Add User
        </Button>
      )}
    </div>
  );
};
