import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { Notification } from 'types/notification';
import { displayNotification } from 'state/notifications/actions';
import { connect } from 'react-redux';
import { AxiosError } from 'axios';
import { Alert, AlertTitle } from '@material-ui/lab';
import useSuppliersImport from 'hooks/useSuppliersImport';

interface Props {
  open: boolean;
  accountId: string;
  showNotification: (value: Notification) => void;
  onClose: () => void;
}

const Errors = {
  DUPLICATE_ID: 'Duplicate ID value',
  MISSING_NAME_AND_ID: 'Missing Name and ID value',
  SUPPLIER_NOT_FOUND: 'Supplier with given ID not found',
  SUPPLIER_EXISTS: 'Supplier with given Name already exists',
  DUPLICATE_NAME: 'Supplier with this name is already listed',
};

const ErrorMessage = ({
  checkedRows,
  invalidRows,
  errors,
}: {
  checkedRows: number;
  invalidRows: number;
  errors: Record<string, Array<number>>;
}) => {
  return (
    <>
      <Alert severity="warning">
        Checked rows: {checkedRows}, invalid rows: {invalidRows}
      </Alert>
      <Box mt={1}>
        <Alert severity="warning">
          <AlertTitle>Errors:</AlertTitle>
          <Box bgcolor="#fdbaca" p={2} width="100%">
            {Object.entries(errors).map(([error, rows], index) => (
              <Box key={index}>
                {index + 1}. {Errors[error as keyof typeof Errors]} in row(s): {rows.join(' ')}
              </Box>
            ))}
          </Box>
        </Alert>
      </Box>
    </>
  );
};

const SuppliersImportModal = ({ onClose, open, showNotification, accountId }: Props) => {
  const [files, setFiles] = useState<File[]>([]);

  const { mutate, isError, isLoading, error } = useSuppliersImport(accountId);

  const handleUpload = () => {
    if (!files.length) {
      showNotification({ type: 'error', message: 'No file was selected' });
      return;
    }

    mutate(
      { file: files[0] },
      {
        onError: error => {
          showNotification({
            type: 'error',
            message: (error as AxiosError).response?.data?.message ?? 'Failed to import suppliers.',
          });
        },
      }
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Import Suppliers</DialogTitle>
      <DialogContent>
        {isLoading && (
          <Box
            minHeight={200}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        )}
        {!isLoading && (
          <>
            <Box mb={2}>
              <Alert severity="info">File can not exceed 1MB in size.</Alert>
            </Box>
            <DropzoneArea
              filesLimit={1}
              showPreviews
              showPreviewsInDropzone={false}
              useChipsForPreview
              acceptedFiles={[
                '.csv, text/csv, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values',
              ]}
              onChange={setFiles}
              maxFileSize={1048576} // 1MB
              previewText="Selected files"
            />
            {isError &&
              (error as AxiosError).response?.status === 400 &&
              (error as AxiosError).response?.data.data && (
                <Box mt={3}>
                  <ErrorMessage {...(error as AxiosError).response?.data.data} />
                </Box>
              )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleUpload} disabled={!files.length}>
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  showNotification: (value: Notification) => {
    dispatch(displayNotification(value));
  },
});

export default connect(null, mapDispatchToProps)(SuppliersImportModal);
