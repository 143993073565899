import React from 'react';
import {
  flow,
  groupBy,
  map,
  mapValues,
  sumBy,
  toPairs,
  sortBy,
  get,
  sum,
  values,
  reject,
} from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableHead, TableRow, TableCell, TableBody, TableFooter } from '@material-ui/core';
import { EstimateStatuses, MediaTypeLabels, MediaTypeRanking } from 'cr-core/constants';
import { formatAsCurrency } from 'cr-core/currencyUtils';
import { PieChart } from 'components/charts';
import { colors } from 'shared/styles';
import Box from './box';
import Legend, { Circle, getValueWithPercentage } from './legend';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    paddingTop: 12,
  },
  legend: {
    width: 165,
  },
  chart: {
    flexGrow: 1,
    maxWidth: 'calc(100% - 165px)',
    height: 200,
    marginRight: theme.spacing(1),
  },

  tooltip: {
    position: 'relative',
  },
  tooltipColorBar: {
    top: 0,
    bottom: 0,
    width: theme.spacing(0.5),
    borderRadius: 4,
    position: 'absolute',
  },
  tooltipBody: {
    marginLeft: theme.spacing(1.5),
  },
  tooltipTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  tooltipRow: {
    whiteSpace: 'nowrap',
  },

  tableHeader: {
    '& th': {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.secondary,
    },
  },
  tableBody: {
    '& td': {
      borderBottom: 0,
    },
  },
  tableFooter: {
    '& td': {
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.secondary,
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      borderBottom: 0,
    },
  },
}));

const getDeliverablesCount = sumBy(
  ({ numberOfTVCs = 0, numberOfRATVs = 0, additionalDeliverables = {} }) =>
    numberOfTVCs + numberOfRATVs + sum(values(additionalDeliverables)),
);

const getData = ({ estimates }) => {
  return flow(
    reject({ status: EstimateStatuses.CANCELLED }),
    groupBy('mediaType'),
    mapValues(items => ({
      totalCost: sumBy(
        ({ recommendedBidTotal }) => recommendedBidTotal || 0, // actualisedTotal ||
        items,
      ),
      count: items.length,
      deliverables: getDeliverablesCount(items),
    })),
    toPairs,
    sortBy(([key]) => MediaTypeRanking[key]),
    map(([key, data]) => ({
      value: data.totalCost,
      data,
      color: colors[key],
      label: MediaTypeLabels[key],
    })),
  )(estimates);
};

export const ColorBar = ({ color }) => {
  const classes = useStyles();
  return <div className={classes.tooltipColorBar} style={{ background: color }}></div>;
};

const tooltip = ({ totalSpend, totalCount, totalDeliverables, displayCurrency }) => ({
  id,
  value,
  label,
  color,
  data,
}) => {
  const classes = useStyles();
  const { count, deliverables, totalCost } = data;

  return (
    <div className={classes.tooltip}>
      <ColorBar color={color} />
      <div className={classes.tooltipBody}>
        <div className={classes.tooltipTitle}>{label}</div>
        <div className={classes.tooltipRow}>
          Estimated Spend: {getValueWithPercentage(totalCost, totalSpend, displayCurrency)}
        </div>
        <div className={classes.tooltipRow}>
          Estimates: {getValueWithPercentage(count, totalCount)}
        </div>
        <div className={classes.tooltipRow}>
          Deliverables: {getValueWithPercentage(deliverables, totalDeliverables)}
        </div>
      </div>
    </div>
  );
};

const Chart = ({ data, displayCurrency, totalSpend, totalCount, totalDeliverables }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.chart}>
        <PieChart
          data={data}
          displayCurrency={displayCurrency}
          tooltip={tooltip({
            totalSpend,
            totalCount,
            totalDeliverables,
            displayCurrency,
          })}
          colors={get('color')}
        />
      </div>
      <Legend items={data} className={classes.legend} />
    </div>
  );
};

const ChartTable = ({ data, displayCurrency, totalSpend, totalDeliverables }) => {
  const classes = useStyles();
  return (
    <div className={classes.table}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell>Estimate Type</TableCell>
            <TableCell align="right">Est. Spend</TableCell>
            <TableCell align="right"># Deliv.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {map(
            ({ label, value, color, data }) => (
              <TableRow key={label}>
                <TableCell scope="row">
                  <Circle color={color} />
                  {label}
                </TableCell>
                <TableCell align="right">
                  {formatAsCurrency(displayCurrency, Math.round(value))}
                </TableCell>
                <TableCell align="right">{data.deliverables}</TableCell>
              </TableRow>
            ),
            data,
          )}
        </TableBody>
        <TableFooter className={classes.tableFooter}>
          <TableRow>
            <TableCell>Total</TableCell>
            <TableCell align="right">
              {formatAsCurrency(displayCurrency, Math.round(totalSpend))}
            </TableCell>
            <TableCell align="right">{totalDeliverables}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

const EstimateTypeChart = ({ estimates, displayCurrency }) => {
  const data = getData({ estimates });
  const totalSpend = flow(
    reject({ status: EstimateStatuses.CANCELLED }),
    sumBy(({ recommendedBidTotal, actualisedTotal }) => recommendedBidTotal || 0), // actualisedTotal ||
  )(estimates);
  const totalDeliverables = sumBy(
    ({ numberOfTVCs = 0, numberOfRATVs = 0, additionalDeliverables = {} }) =>
      numberOfTVCs + numberOfRATVs + sum(values(additionalDeliverables)),
    estimates,
  );

  return (
    <Box
      title="Estimate Type"
      Chart={() => (
        <Chart
          data={data}
          displayCurrency={displayCurrency}
          totalSpend={totalSpend}
          totalCount={estimates.length}
          totalDeliverables={totalDeliverables}
        />
      )}
      Table={() => (
        <ChartTable
          data={data}
          displayCurrency={displayCurrency}
          totalSpend={totalSpend}
          totalDeliverables={totalDeliverables}
        />
      )}
    />
  );
};

export default EstimateTypeChart;
