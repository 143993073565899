import auth0 from 'auth0-js';
import jwtDecode from 'jwt-decode';
import { identify, getUserData } from 'components/analytics';

import config from '../../config';
import { formatUserName } from 'cr-core/userUtils';

export const auth = new auth0.WebAuth({
  redirectUri: config.REDIRECT_URL,
  domain: config.AUTH0_DOMAIN,
  clientID: config.AUTH0_CLIENT_ID,
  responseType: 'token id_token',
  audience: 'https://api.murphycobb.com',
});

export const authSignup = options => {
  return new auth0.WebAuth({
    redirectUri: config.REDIRECT_URL + window.location.search,
    domain: config.AUTH0_DOMAIN,
    clientID: config.AUTH0_CLIENT_ID,
    responseType: 'token id_token',
    audience: 'https://api.murphycobb.com',
  }).authorize(options);
};

export const setTarget = () => localStorage.setItem('target', window.location.pathname);
export const getTarget = () => localStorage.getItem('target');
export const clearTarget = () => localStorage.removeItem('target');

export const loginWithUsername = (username, password) =>
  new Promise((resolve, reject) => {
    auth.login(
      {
        realm: 'Username-Password-Authentication',
        username,
        password,
      },
      err => {
        if (err) {
          return reject(err);
        }
        return resolve();
      }
    );
  });

export const handleAuthentication = () =>
  new Promise((resolve, reject) => {
    auth.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        window.location.hash = '';
        resolve(authResult);
      } else {
        console.log('handleAuthentication', err);
        reject(err);
      }
    });
  });

export const logout = () => {
  // Clear user token and profile data from window.localStorage
  window.localStorage.removeItem('id_token');
  window.localStorage.removeItem('access_token');
  window.localStorage.removeItem('profile');
};

export const getProfile = () => {
  // Retrieves the profile data from window.localStorage
  const profile = window.localStorage.getItem('profile');
  return profile ? JSON.parse(window.localStorage.profile) : {};
};

export const setProfile = profile => {
  // Saves profile data to window.localStorage
  window.localStorage.setItem('profile', JSON.stringify(profile));
  // Triggers profile_updated event to update the UI
};

export const setAPIToken = accessToken => window.localStorage.setItem('access_token', accessToken);

export const getAPIToken = () => window.localStorage.getItem('access_token');

export const setToken = idToken => {
  // Saves user token to window.localStorage
  window.localStorage.setItem('id_token', idToken);
};

export const identifyUserWithAnalytics = profile => {
  const userContext = {
    ...getUserData(profile),
    displayName: formatUserName(profile),
  };
  identify(profile.id, userContext, {
    Intercom: { hideDefaultLauncher: true },
  });

  window.Sentry &&
    window.Sentry.configureScope(scope => {
      scope.setUser(userContext);
    });
};

const getTokenExpirationDate = token => {
  const decoded = jwtDecode(token);
  if (!decoded.exp) {
    return null;
  }

  const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(decoded.exp);
  return date;
};

export const isTokenExpired = () => {
  const token = getAPIToken();
  if (!token) {
    return true;
  }
  const date = getTokenExpirationDate(token);
  const offsetSeconds = 0;
  if (date === null) {
    return false;
  }

  return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
};
