import React from 'react';

const SvgIntegratedIcon = (props: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M1578 4489 c-60 -31 -72 -65 -78 -213 l-5 -128 -94 -34 c-52 -18
    -131 -51 -175 -74 l-81 -40 -85 84 c-47 46 -95 89 -107 95 -30 15 -99 14 -131
    -3 -32 -18 -475 -462 -491 -493 -14 -28 -14 -98 0 -126 6 -12 49 -60 94 -107
    l84 -85 -41 -83 c-22 -45 -55 -124 -73 -175 l-33 -92 -128 -5 c-149 -6 -182
    -18 -214 -80 -19 -37 -20 -58 -20 -370 0 -306 1 -334 19 -368 32 -63 67 -76
    215 -82 l128 -5 33 -92 c18 -51 51 -130 73 -175 l41 -83 -84 -85 c-45 -47 -88
    -95 -94 -107 -15 -30 -14 -99 3 -131 18 -32 462 -475 493 -491 28 -14 98 -14
    126 0 12 6 60 49 107 95 l85 84 81 -40 c44 -23 123 -56 175 -74 l94 -34 5
    -128 c6 -148 16 -177 78 -212 38 -21 50 -22 281 -22 l241 0 0 453 0 452 -158
    1 c-171 1 -231 13 -387 73 -243 94 -482 333 -576 576 -61 158 -72 216 -73 390
    0 137 3 173 23 249 102 392 381 670 781 778 59 17 108 21 232 22 l158 1 0 453
    0 452 -242 0 c-222 -1 -246 -3 -280 -21z"
        />
        <path
          d="M2400 2560 l0 -1950 1300 0 c1284 0 1301 0 1340 20 26 13 47 34 60
    60 20 39 20 56 20 1330 l0 1290 -541 0 -541 0 -40 22 c-24 14 -48 38 -59 60
    -18 35 -19 67 -19 578 l0 540 -760 0 -760 0 0 -1950z m1140 1030 c45 -23 80
    -80 80 -130 0 -50 -35 -107 -80 -130 -37 -19 -58 -20 -381 -20 -335 0 -342 0
    -381 22 -101 57 -102 194 -2 254 39 24 39 24 382 24 324 0 345 -1 382 -20z
    m900 -600 c45 -23 80 -80 80 -130 0 -50 -35 -107 -80 -130 -38 -20 -57 -20
    -831 -20 l-791 0 -40 22 c-101 57 -102 194 -2 254 l39 24 793 0 c775 0 794 0
    832 -20z m0 -600 c45 -23 80 -80 80 -130 0 -50 -35 -107 -80 -130 -38 -20 -57
    -20 -831 -20 l-791 0 -40 22 c-101 57 -102 194 -2 254 l39 24 793 0 c775 0
    794 0 832 -20z m0 -600 c45 -23 80 -80 80 -130 0 -50 -35 -107 -80 -130 -38
    -20 -57 -20 -831 -20 l-791 0 -40 22 c-101 57 -102 194 -2 254 l39 24 793 0
    c775 0 794 0 832 -20z"
        />
        <path
          d="M4220 4015 l0 -405 407 0 408 0 -405 405 c-223 223 -406 405 -407
    405 -2 0 -3 -182 -3 -405z"
        />
        <path
          d="M1795 3293 c-236 -50 -450 -228 -539 -449 -112 -276 -52 -586 157
    -803 168 -173 406 -257 641 -225 l46 7 0 155 0 155 -32 -7 c-173 -37 -318 3
    -433 119 -178 177 -178 454 -1 630 116 116 261 156 434 119 l32 -6 0 154 0
    155 -46 7 c-75 10 -184 6 -259 -11z"
        />
        <path
          d="M1878 2689 c-43 -22 -78 -81 -78 -129 0 -74 76 -150 149 -150 75 0
    151 75 151 149 0 51 -35 108 -80 131 -49 25 -94 25 -142 -1z"
        />
      </g>
    </svg>
  );
};

export default SvgIntegratedIcon;
