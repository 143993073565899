import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import {
  map,
  reject,
  flow,
  omit,
  intersection,
  keys,
  sortBy,
  isEqual,
  pick,
  isEmpty,
  isArray,
  isString,
  omitBy,
  compact,
} from 'lodash/fp';
import { Formik, Form } from 'formik';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormikOnChange from 'components/filters/formik.onChange';
import {
  AdditionalFilters,
  MediaTypesFilter,
  EstimateStatusFilter,
  BudgetSourceFilter,
  ExcludeCancelledEstimates,
  PayingCountryFilter,
  Search,
  BudgetCenterFilter,
  WorkspaceFilter,
  LeadMarketFilter,
  AssignedToMeFilter,
  ProductionComplexityFilter,
  CampaignStatusFilter,
  ReportingDateControl,
  CategoryBrandFilter,
  BudgetYearFilter,
  ReportingYearFilter,
  CampaignIDFilter,
  CampaignClientIOFilter,
  ProductionStartDateControl,
  DeliveryDateControl,
  ScaleOrNonScaleFilter,
} from './filters';
import { stringifyFilters, parseQueryFilters } from './utils';
import { useMemo } from 'react';
import { getClientSettings } from 'state/authentication/selectors';
import { AccountSetting } from 'cr-core/constants';
export { stringifyFilters, parseQueryFilters };

const DEBOUNCE_DELAY = 500;

export const initialValues = {
  search: '',
  mediaType: [],
  productCategory: [],
  productBrand: [],
  estimateStatus: [],
  budgetSource: [],
  leadMarket: [],
  payingCountries: [],
  productionComplexity: [],
  campaignStatus: [],
  workspaceId: [],
  additionalFilters: [],
  assignedToMe: false,
  excludeCancelledEstimates: false,
  budgetCenter: [],
  budgetYear: [],
  reportingYear: [],
  campaignHumanId: [],
  clientIoNumber: [],
  scaleOrNonScale: [],
};

export const defaultFilters = {
  reportingYear: [`${new Date().getFullYear()}`],
  excludeCancelledEstimates: true,
};

export const isFiltered = filters => {
  filters = flow(
    omitBy((value, key) => key === 'assignedToMe' && !value),
    omitBy(value => (isArray(value) || isString(value)) && value.length === 0)
  )(filters);
  const defaultFiltersChanged = !isEqual(defaultFilters, pick(keys(defaultFilters), filters));
  const otherFiltersSet = !isEmpty(omit(keys(defaultFilters), filters));
  return defaultFiltersChanged || otherFiltersSet;
};

const useStyles = makeStyles(theme => {
  return {
    formControl: {
      marginBottom: theme.spacing(2), // dense 0
    },
  };
});

const getFilters = ({ isCategoriesLoading }) => {
  return {
    search: Search,
    mediaType: MediaTypesFilter,
    estimateStatus: EstimateStatusFilter,
    payingCountries: PayingCountryFilter,
    excludeCancelledEstimates: ExcludeCancelledEstimates,
    budgetSource: BudgetSourceFilter,
    assignedToMe: AssignedToMeFilter,
    leadMarket: LeadMarketFilter,
    productionComplexity: ProductionComplexityFilter,
    campaignStatus: CampaignStatusFilter,
    budgetCenter: BudgetCenterFilter,
    budgetYear: BudgetYearFilter,
    workspaceId: WorkspaceFilter,
    reportingDate: ReportingDateControl,
    reportingYear: ReportingYearFilter,
    categoryBrand: CategoryBrandFilter,
    campaignHumanId: CampaignIDFilter,
    clientIoNumber: CampaignClientIOFilter,
    productionStartDate: ProductionStartDateControl,
    deliveryDate: DeliveryDateControl,
    scaleOrNonScale: ScaleOrNonScaleFilter,
  };
};

const FiltersForm = ({
  // values
  categories,
  // loading
  isCategoriesLoading,
  onChange,
  filters: pageFilters,
  initiallyDisplayedFilters = [],
  className,
  clientSettings,
  history,
  location,
}) => {
  const filtersOptions = useMemo(
    () =>
      sortBy(
        'label',
        compact([
          { value: 'search', label: 'Search' },
          { value: 'mediaType', label: 'Estimate Type' },
          { value: 'estimateStatus', label: 'Estimate Status' },
          { value: 'payingCountries', label: 'Paying Countries' },
          { value: 'scaleOrNonScale', label: 'Scale/Non-Scale' },
          { value: 'excludeCancelledEstimates', label: 'Exclude Cancelled Estimates' },
          { value: 'budgetSource', label: 'Budget Source' },
          { value: 'assignedToMe', label: 'Assigned To Me' },
          { value: 'leadMarket', label: 'Lead Market' },
          { value: 'productionComplexity', label: 'Production Complexity' },
          { value: 'campaignStatus', label: 'Campaign Status' },
          { value: 'workspaceId', label: 'Workspace' },
          { value: 'productionStartDate', label: 'Production Start Date' },
          { value: 'deliveryDate', label: 'Delivery Date' },
          { value: 'reportingDate', label: 'Air Date' },
          { value: 'categoryBrand', label: 'Segment/Brand' },
          {
            value: 'reportingYear',
            label: 'Year',
          },
          clientSettings[AccountSetting.BudgetCenter] && {
            value: 'budgetCenter',
            label: 'Budget Center',
          },
          clientSettings[AccountSetting.BudgetYear] && {
            value: 'budgetYear',
            label: 'Budget Year',
          },
          clientSettings[AccountSetting.ExtendedCampaignDetails] && {
            value: 'campaignHumanId',
            label: 'Campaign ID #',
          },
        ])
      ),
    [clientSettings]
  );

  const classes = useStyles();

  const filters = getFilters({
    categories,
    isCategoriesLoading,
  });

  const additionalFiltersOptions = reject(
    ({ value }) => initiallyDisplayedFilters.includes(value),
    filtersOptions
  );

  const additionalFilters = flow(
    map('value'),
    intersection(keys(pageFilters))
  )(additionalFiltersOptions);

  const [formInitialValues] = useState({
    ...initialValues,
    ...defaultFilters,
    ...pageFilters,
    additionalFilters,
  });

  const [displayedFilters, setDisplayedFilters] = useState([
    ...initiallyDisplayedFilters,
    ...additionalFilters,
  ]);

  const onFormChange = async (values, prevValues) => {
    const newDisplayedFilters = [...initiallyDisplayedFilters, ...values.additionalFilters];
    if (newDisplayedFilters.length !== displayedFilters.length) {
      setDisplayedFilters(newDisplayedFilters);
    }
    // this is to handle also the on reset when additionalFilters are set
    if (!isEqual(prevValues, omit('additionalFilters', values))) {
      const cleanValues = omit('additionalFilters', values);
      onChange(cleanValues, prevValues);
      history.push({
        path: location.path,
        search: stringifyFilters(cleanValues, defaultFilters),
      });
    }
  };

  return (
    <Formik validateOnChange initialValues={formInitialValues}>
      {({ handleSubmit, resetForm, dirty, values }) => (
        <Form onSubmit={handleSubmit}>
          <FormikOnChange
            onChange={onFormChange}
            delay={DEBOUNCE_DELAY}
            updateOnChange={displayedFilters}
          />
          {displayedFilters
            .filter(filterName =>
              !clientSettings[AccountSetting.ExtendedCampaignDetails]
                ? filterName !== 'clientIoNumber' && filterName !== 'campaignHumanId'
                : filterName
            )
            .map(filterName => {
              const Filter = filters[filterName];
              return <Filter key={filterName} className={classes.formControl} />;
            })}
          <AdditionalFilters
            className={classes.formControl}
            options={reject(
              ({ value }) => values.additionalFilters.includes(value),
              additionalFiltersOptions
            )}
          />

          {isFiltered(pageFilters) && (
            <Button
              type="button"
              variant="contained"
              id="clear-filters-btn"
              fullWidth
              className={classes.clearFiltersButton}
              onClick={() => resetForm({ values: { ...initialValues, ...defaultFilters } })}
            >
              Clear Filters
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
};

export const mapStateToProps = state => ({
  clientSettings: getClientSettings(state),
});

export default compose(withRouter, connect(mapStateToProps))(FiltersForm);
