import React from 'react';
import * as Yup from 'yup';
import { map, sortBy } from 'lodash/fp';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { createAccontDomain, removeAccontDomain } from 'state/accountDomains/actions';
import { getAccountById } from 'state/accounts/selectors';

const domainNameRegex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;

const useStyles = makeStyles(theme => ({
  list: { width: 300 },
  button: {
    height: 37,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
}));

const AddDomainForm = ({ addDomain }) => {
  const classes = useStyles();
  const initialValues = { domain: '' };
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const success = await addDomain(values);
    setSubmitting(false);
    if (success) {
      resetForm(initialValues);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        domain: Yup.string()
          .matches(domainNameRegex, 'It has to be a valid domain name')
          .required('Required'),
      })}
      validateOnChange
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            label="Domain"
            name="domain"
            size="small"
            variant="outlined"
            margin="dense"
            fullWidth={false}
            className={classes.field}
            component={TextField}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
            disabled={isSubmitting}
          >
            Add
          </Button>
        </form>
      )}
    </Formik>
  );
};

const DomainsList = ({ domains = [], addDomain, removeAccontDomain }) => {
  const classes = useStyles();

  if (!domains.length) {
    return (
      <div>
        <i>No email domain configured</i>
      </div>
    );
  }

  return (
    <List className={classes.list}>
      {map(
        ({ id, domain }) => (
          <ListItem key={id}>
            <ListItemText primary={domain} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={() => removeAccontDomain(id)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ),
        domains
      )}
    </List>
  );
};

const AccountDomain = ({ account, addDomain, removeAccontDomain, className }) => {
  const classes = useStyles();

  return (
    <div className={className}>
      <Typography variant="h6" className={classes.title}>
        Email domains
      </Typography>
      <DomainsList
        domains={sortBy('domain', account.accountDomains || [])}
        removeAccontDomain={removeAccontDomain}
      />
      <AddDomainForm addDomain={addDomain} />
    </div>
  );
};

const mapStateTopProps = (state, { accountId }) => ({
  account: getAccountById(accountId)(state),
});

const mapDispatchToProps = (dispatch, { accountId }) => ({
  addDomain: ({ domain }) => dispatch(createAccontDomain({ domain, accountId })),
  removeAccontDomain: domainId => dispatch(removeAccontDomain({ domainId, accountId })),
});

export default connect(mapStateTopProps, mapDispatchToProps)(AccountDomain);
