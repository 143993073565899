import React from 'react';
import { noop } from 'lodash/fp';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Events, track, getCampaignData } from 'components/analytics';
import ConfirmationModal from 'components/modals/confirmationModal';
import { deleteCampaign } from 'state/campaigns/actions';
import { getCampaignById } from 'state/campaigns/selectors';
import { closeSidePanel } from 'state/ui/actions';
import { parseQueryFilters } from 'components/filtersSidebar';

const DeleteCampaignModal = ({ open, deleteCampaign, campaign, onModalClose = noop }) => {
  return (
    <ConfirmationModal
      open={open}
      title="Delete Campaign"
      message={<>Do you want to delete this campaign?</>}
      actionLabel="Delete campaign"
      handleClose={async confirm => {
        track(
          confirm ? Events.CAMPAIGN_DELETE_SUCCESS : Events.CAMPAIGN_DELETE_CANCEL,
          getCampaignData(campaign),
        );
        if (confirm) {
          await deleteCampaign(parseQueryFilters(window.location.search));
        }
        onModalClose();
      }}
    ></ConfirmationModal>
  );
};

const mapStateToProps = (state, { campaignId }) => ({
  campaign: getCampaignById(campaignId)(state),
});

const mapDispatchToProps = (dispatch, { campaignId, campaign }) => ({
  deleteCampaign: async query => {
    await dispatch(deleteCampaign(campaignId, query));
    await dispatch(closeSidePanel());
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(DeleteCampaignModal);
