import React from 'react';
import clsx from 'clsx';
import momemt from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { flow, map, uniqBy, sortBy, sumBy, reject } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { Hidden, Box, Tooltip, Grid } from '@material-ui/core';
import { EstimateStatuses, MediaTypeLabels } from 'cr-core/constants';
import { formatAsCurrency } from 'cr-core/currencyUtils';
import LoadingBlock from 'components/loadingBlock';
import { MediaTypeIcon } from 'icons';
import EstimateStatus from './estimateStatus';
import TeamMembers from './teamMembers';

const defaultSpace = 8;
export const estimateHeight = (1 + defaultSpace) * 2 + 34;

const useStyles = makeStyles(theme => {
  return {
    estimate: {
      width: '100%',
      padding: theme.spacing(1, 4),
      borderWidth: '0 1px 1px',
      borderStyle: 'solid',
      borderColor: theme.palette.grey[200],
      height: estimateHeight,
      color: theme.palette.text.primary,
      display: 'flex',
      alignItems: 'center',

      '&:hover': {
        background: theme.palette.action.hover,
      },
      '&:last-child': {
        borderBottom: 0,
      },

      '@media (max-width: 960px)': {
        padding: theme.spacing(1, 1.5),
      },
    },

    estimateContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    noEstimatesText: {
      color: theme.palette.text.disabled,
    },
    noClickable: {
      '&:hover': {
        background: 'transparent',
      },
    },

    innerContainerLeft: {
      display: 'flex',
      alignItems: 'center',
      flexShrink: 1,
      width: 'calc(100% - 834px)',

      '@media (max-width: 1440px)': {
        width: 'calc(100% - 418px)',
      },

      '@media (max-width: 700px)': {
        width: 'calc(100% - 258px)',
      },
    },
    innerContainerLeftSmall: {
      width: 'calc(100% - 418px)',
    },
    innerContainerRight: {
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0,
    },

    column: {
      marginRight: theme.spacing(2),
      '&:last-child': {
        marginRight: 0,
      },
      overflow: 'hidden',
      whiteSpace: 'pre',
      textOverflow: 'ellipsis',
    },
    icon: {
      fontSize: theme.spacing(3),
      marginRight: theme.spacing(1),
    },
    name: {
      fontWeight: theme.typography.fontWeightBold,
    },
    status: {
      textAlign: 'center',
    },
    cost: {
      fontWeight: theme.typography.fontWeightBold,
      textAlign: 'right',
      fontSize: '1rem',
    },

    estimatesFilteredOut: {
      height: 30,
      color: theme.palette.text.hint,
    },
    estimatesFilteredOutText: {
      ...theme.typography.body2,
      fontStyle: 'italic',
    },
    estimatesFilteredOutCost: {
      fontSize: theme.typography.body2.fontSize,
    },
  };
});

const Team = ({ estimate }) => {
  const { watchers = [], approvals = [] } = estimate;
  let members = flow(
    uniqBy('id'),
    sortBy('firstName')
  )([...map('user', watchers), ...map('approver', approvals)]);

  return <TeamMembers members={members} />;
};

export const EmptyListPlaceHolder = () => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.estimate, classes.noEstimatesText, classes.noClickable)}
      data-test="campaign-no-estimates-list"
    >
      <i>This campaign has no estimates</i>
    </div>
  );
};

export const NoFilteredResultsPlaceHolder = () => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.estimate, classes.noClickable)}>
      No estimates for the selected filters
    </div>
  );
};

export const EstimateListItemLoading = () => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.estimate, classes.noClickable)}>
      <LoadingBlock />
    </div>
  );
};

export const EstimatesFilteredOut = ({ campaign, estimates, displayCurrency }) => {
  const classes = useStyles();
  const count = campaign.estimates.length - estimates.length;
  const totalSpend =
    campaign.totalCost -
    flow(
      reject({ status: EstimateStatuses.CANCELLED }),
      sumBy(({ recommendedBidTotal, actualisedTotal }) => actualisedTotal || recommendedBidTotal)
    )(estimates);

  return (
    <div
      className={clsx(classes.estimate, classes.noClickable, classes.estimatesFilteredOut)}
      data-test="filtered-out-estimates"
    >
      <div className={classes.estimatesFilteredOutText}>
        {count} estimate{count > 1 && 's'} filtered out from the results{' '}
      </div>
      <div className={clsx(classes.column, classes.cost, classes.estimatesFilteredOutCost)}>
        {formatAsCurrency(displayCurrency, totalSpend)}
      </div>
    </div>
  );
};

const campaignListItem = ({ estimate, displayCurrency, collapsedFiltersBar }) => {
  const classes = useStyles(); // eslint-disable-line react-hooks/rules-of-hooks
  const showProductWithClosedFilterbar = {
    smDown: !collapsedFiltersBar,
    xsDown: true,
  };
  const {
    name,
    mediaType,
    leadMarket,
    date,
    products,
    status,
    approvals,
    actualisedTotal,
    recommendedBidTotal,
  } = estimate;
  const reportingDate = momemt(date).format('[Q]Q YY');
  const cost = status === EstimateStatuses.ACTUALISED ? actualisedTotal : recommendedBidTotal;

  const productsInfo = flow(
    map(
      ({ brandName, brandCategory, productName }) => `${brandCategory} ${brandName} ${productName}`
    ),
    arr => {
      if (arr.length > 2) {
        return `${arr[0]}, ${arr[1]}, +${arr.length - 2}`;
      }
      return arr.join(', ');
    }
  )(products);

  return (
    <Link
      to={`/estimates/${estimate.id}`}
      target="blank"
      data-test="estimateListItem"
      className={classes.estimate}
      key={estimate.id}
    >
      <Grid container alignItems="center" spacing={5}>
        <Grid item xs={4} sm={2} md={collapsedFiltersBar && 3}>
          <Box display="flex" flexDirection="row">
            <MediaTypeIcon type={mediaType} className={classes.icon} />
            <Box display="flex" flexDirection="column" className={classes.column}>
              <Tooltip title={`${name} ${MediaTypeLabels[mediaType]}`} placement="top">
                <div data-test="title-text" className={clsx(classes.name, classes.column)}>
                  {name}
                </div>
              </Tooltip>
              <div data-test="media-type-text" className={classes.column}>
                {MediaTypeLabels[mediaType]}
              </div>
            </Box>
          </Box>
        </Grid>
        <Hidden xsDown>
          <Grid item sm={collapsedFiltersBar ? 1 : 2}>
            <div>
              <Tooltip title={`${leadMarket}`} placement="top">
                <div data-test="lead-market" className={classes.column}>
                  {leadMarket}
                </div>
              </Tooltip>
              <Tooltip title={`${Boolean(date) && reportingDate}`} placement="top">
                <div data-test="reporting-date" className={classes.column}>
                  {Boolean(date) && reportingDate}
                </div>
              </Tooltip>
            </div>
          </Grid>
        </Hidden>
        <Hidden {...showProductWithClosedFilterbar}>
          <Grid item sm={1} md={2}>
            <Tooltip title={productsInfo} placement="top">
              <div data-test="products" className={clsx(classes.column, classes.products)}>
                {productsInfo}
              </div>
            </Tooltip>
          </Grid>
        </Hidden>
        <Hidden xsDown>
          <Grid item sm={3} md={2}>
            <div className={clsx(classes.column, classes.team)}>
              <Team estimate={estimate} />
            </div>
          </Grid>
        </Hidden>
        <Grid item xs={4} sm={3} md={2}>
          <div className={clsx(classes.column, classes.status)}>
            <EstimateStatus status={status} approvals={approvals} />
          </div>
        </Grid>
        <Grid item xs={3} sm={2}>
          <Tooltip title={formatAsCurrency(displayCurrency, cost)} placement="top">
            <div className={clsx(classes.column, classes.cost)} data-test="estimateCost">
              {formatAsCurrency(displayCurrency, cost)}
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    </Link>
  );
};
const mapStateToProps = state => ({
  collapsedFiltersBar: state.ui.collapsedFiltersBar,
});
export default connect(mapStateToProps, null)(campaignListItem);
