import React from 'react';

const SvgRadioIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 412.735 412.735" {...props}>
    <g>
      <path
        fill="currentColor"
        d="M109.714,343.997c5.468-0.595,10.781-2.189,15.673-4.702v-90.384c-5.07-2.044-10.316-3.617-15.673-4.702V343.997z"
      />
      <circle fill="currentColor" cx="342.204" cy="294.364" r="22.988" />
      <path
        fill="currentColor"
        d="M248.163,271.377c-12.696,0-22.988,10.292-22.988,22.988c-0.006,12.696,10.282,22.992,22.977,22.998
  			 c12.696,0.006,22.992-10.282,22.998-22.977c0-0.007,0-0.014,0-0.021C271.151,281.669,260.859,271.377,248.163,271.377z"
      />
      <path
        fill="currentColor"
        d="M62.694,327.279V261.45c-5.224,8.882-12.016,20.375-12.016,32.914C50.678,306.903,57.469,318.397,62.694,327.279z"
      />
      <path
        fill="currentColor"
        d="M389.224,113.075H94.041l195.396-59.559c5.282-1.963,8.218-7.609,6.792-13.061c-1.749-5.467-7.581-8.5-13.061-6.792
  			 l-250.775,76.8c-1.567,0.522-3.135,2.612-4.702,2.612h-4.18C10.359,113.635-0.012,124.466,0,137.63v219.429
  			 c-0.013,12.407,10.034,22.476,22.441,22.49c0.357,0,0.713-0.008,1.069-0.024h365.714c12.394,0.577,22.909-9.002,23.486-21.396
  			 c0.017-0.356,0.025-0.713,0.024-1.069V137.63C412.747,124.466,402.376,113.635,389.224,113.075z M138.971,349.744
  			 c-0.444,1.029-1.495,1.659-2.612,1.567c-9.909,6.077-21.291,9.329-32.914,9.404c-11.786-0.085-23.334-3.332-33.437-9.404
  			 c-1.045,0-1.567-0.522-2.612-1.567c-18.87-12.097-30.289-32.965-30.302-55.38c-0.067-22.576,11.349-43.637,30.302-55.902
  			 l2.612-1.567c10.144-5.913,21.695-8.981,33.437-8.882c11.578-0.109,22.963,2.963,32.914,8.882l2.612,1.567
  			 c18.953,12.266,30.369,33.326,30.302,55.902C169.26,316.779,157.842,337.647,138.971,349.744z M248.163,333.026
  			 c-21.352,0-38.661-17.309-38.661-38.661c0-21.352,17.309-38.661,38.661-38.661c21.352,0,38.661,17.309,38.661,38.661
  			 C286.825,315.716,269.515,333.026,248.163,333.026z M342.204,333.026c-21.352,0-38.661-17.309-38.661-38.661
  			 c0-21.352,17.309-38.661,38.661-38.661s38.661,17.309,38.661,38.661C380.865,315.716,363.556,333.026,342.204,333.026z
  				M391.837,204.503c0,4.328-3.509,7.837-7.837,7.837H28.735c-4.328,0-7.837-3.509-7.837-7.837v-62.694
  			 c0-4.328,3.509-7.837,7.837-7.837H384c4.328,0,7.837,3.509,7.837,7.837V204.503z"
      />
      <path
        fill="currentColor"
        d="M141.061,261.45v65.829c10.449-8.882,12.016-20.376,12.016-32.914C153.078,281.826,151.51,270.332,141.061,261.45z"
      />
      <path
        fill="currentColor"
        d="M36.571,196.666H256v-22.465c0-4.328,3.509-7.837,7.837-7.837c4.328,0,7.837,3.509,7.837,7.837v22.465h104.49v-47.02
  			 H36.571V196.666z"
      />
      <path
        fill="currentColor"
        d="M78.367,339.295c4.893,2.513,10.205,4.107,15.673,4.702v-99.788c-5.357,1.085-10.604,2.659-15.673,4.702V339.295z"
      />
    </g>
  </svg>
);

export default SvgRadioIcon;
