import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/node_modules/@material-ui/styles';

const useStyles = makeStyles({
  filterContainer: {
    width: '100%',

    '@media screen and (min-width: 900px)': {
      width: '25%',
    },

    '@media screen and (min-width: 1250px)': {
      width: '20%',
    },
  },
});

const ReportFilter = ({ children }: React.PropsWithChildren<unknown>) => {
  const styles = useStyles();

  return (
    <Box mb={3} px="10px" className={styles.filterContainer}>
      {children}
    </Box>
  );
};

export default ReportFilter;
