import qs from 'qs';
import { pick } from 'lodash/fp';

export const getQSParams = params => {
  const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  console.log(queryParams);
  return params ? pick(params, queryParams) : queryParams;
};

export const getSearchFromQSParams = params => `?${qs.stringify(getQSParams(params))}`;
