import React from 'react';
import { map } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, List, ListItem, ListItemText } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  list: {
    height: '100%',
    overflowY: 'scroll',
  },
}));

export default ({ list, onClose, open, title }) => {
  const classes = useStyles();
  const handleClose = () => onClose();
  const handleListItemClick = value => onClose(value);

  return (
    <Dialog onClose={handleClose} aria-labelledby="add-team-dialog" open={open}>
      <DialogTitle>{title}</DialogTitle>
      <List className={classes.list}>
        {map(
          item => (
            <ListItem button onClick={() => handleListItemClick(item)} key={item.id}>
              <ListItemText primary={item.name} />
            </ListItem>
          ),
          list
        )}
      </List>
    </Dialog>
  );
};
