import {
  REPORT_COST_CATEGORIES_FETCH_REQUEST,
  REPORT_COST_CATEGORIES_FETCH_SUCCESS,
  REPORT_DASHBOARD_REQUEST,
  REPORT_DASHBOARD_SUCCESS,
} from 'state/reports/actions';

const defaultState = {
  costCategories: [],
  dashboard: {},
  lastRequestId: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REPORT_COST_CATEGORIES_FETCH_REQUEST: {
      const newState = { ...state, costCategories: defaultState.costCategories };
      if (action.requestId) {
        newState.lastRequestId = action.requestId;
      }
      return newState;
    }
    case REPORT_COST_CATEGORIES_FETCH_SUCCESS:
      if (state.lastRequestId && state.lastRequestId !== action.requestId) {
        return state;
      }
      return { ...state, costCategories: action.data };

    case REPORT_DASHBOARD_REQUEST:
      return { ...state, dashboard: defaultState.dashboard };
    case REPORT_DASHBOARD_SUCCESS:
      return { ...state, dashboard: action.data };

    default:
      return state;
  }
};
