import qs from 'qs';
import React, { useEffect } from 'react';
import { get, filter, flow, compact } from 'lodash/fp';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { reduxForm, getFormValues, Field } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Box, Tab, Tabs, Typography } from '@material-ui/core';
import Account from 'components/accountSettings';
import AppContainer from 'components/appContainer';
import AccountSelect from 'components/filters/accountSelect';
import ProductsSettings from 'components/productSettings';
import { getClientSettings, getUser } from 'state/authentication/selectors';
import { getAccountById } from 'state/accounts/selectors';
import { AccountSetting } from 'cr-core/constants';
import Suppliers from 'components/Suppliers';

export const formName = 'settings';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },

  tabPanel: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={classes.tabPanel}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

const AccountSettings = ({ path, account, filters, history, location, user, suppliersEnabled }) => {
  const menu = compact([
    {
      label: 'Products',
      Component: ProductsSettings,
      admin: true,
      client: true,
    },
    {
      label: 'Account',
      Component: Account,
      admin: true,
    },
    (user.flags?.mcaOnly ||
      (suppliersEnabled && user.roles.map(({ name }) => name).includes('MODERATOR'))) && {
      label: 'Suppliers',
      Component: Suppliers,
    },
  ]);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const isClient = !get('agency', account) && !get('consultancy', account);
  const availableMenuItems = flow(
    filter(({ admin }) => (admin && user.isAdmin) || !admin),
    filter(({ client }) => (client && isClient) || !client)
  )(menu);

  filter(({ admin }) => (admin && user.isAdmin) || !admin, menu);

  useEffect(() => {
    history.replace({
      pathname: location.pathname,
      search: `?${qs.stringify(filters)}`,
    });
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <AppContainer>
      <div className={classes.header}>
        <Typography variant="h5">Account Settings</Typography>
        <Field
          name="accountId"
          component={({ input }) => <AccountSelect withAccessAccounts input={input} />}
        />
      </div>
      {!availableMenuItems.length && <div>You don't have access to account settings</div>}
      {availableMenuItems.length && (
        <>
          <Paper>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              data-test="tabs-selectors"
            >
              {availableMenuItems.map(({ label }) => (
                <Tab key={label} label={label} />
              ))}
            </Tabs>
          </Paper>
          {availableMenuItems.map(({ Component }, i) => (
            <TabPanel key={i} value={value} index={i}>
              <Component accountId={filters.accountId} />
            </TabPanel>
          ))}
        </>
      )}
    </AppContainer>
  );
};

const mapStateToProps = (state, { location }) => {
  const user = getUser(state);
  const initialValues = {};
  if (user.accounts.length === 1) {
    initialValues.accountId = user.accounts[0].id;
  }

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const accountId = get('accountId', queryParams);
  if (accountId) {
    initialValues.accountId = accountId;
  }

  const clientSettings = getClientSettings(state);

  return {
    initialValues,
    user: getUser(state),
    filters: getFormValues(formName)(state) || {},
    account: getAccountById(accountId)(state),
    suppliersEnabled: !!clientSettings[AccountSetting.Suppliers],
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  reduxForm({ form: formName, destroyOnUnmount: false })
)(AccountSettings);
