import { keyBy } from 'lodash/fp';
import { ERROR, LOADING } from 'state/resourceStatus/reducer';
import {
  ESTIMATE_WATCHERS_FETCH_REQUEST,
  ESTIMATE_WATCHERS_FETCH_SUCCESS,
  ESTIMATE_WATCHER_CREATE_ERROR,
  ESTIMATE_WATCHER_CREATE_REQUEST,
  ESTIMATE_WATCHER_CREATE_SUCCESS,
  ESTIMATE_WATCHER_DELETE_REQUEST,
} from './actions';

const defaultState = {};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case ESTIMATE_WATCHERS_FETCH_REQUEST:
      return {};

    case ESTIMATE_WATCHERS_FETCH_SUCCESS:
      return keyBy('id', action.watchers);

    case ESTIMATE_WATCHER_CREATE_REQUEST: {
      if (!action.watcher) {
        return state;
      }

      if (action.retry) {
        return {
          ...state,
          [action.watcher.nonce]: {
            ...state[action.watcher.nonce],
            status: LOADING,
          },
        };
      }

      return {
        [action.watcher.nonce]: { ...action.watcher, status: LOADING },
        ...state,
      };
    }

    case ESTIMATE_WATCHER_CREATE_SUCCESS: {
      if (!action.watcher || !action.watcher.nonce) {
        return state;
      }

      const { nonce, ...data } = action.watcher;

      return {
        ...state,
        [nonce]: data,
      };
    }

    case ESTIMATE_WATCHER_CREATE_ERROR:
      return {
        ...state,
        [action.watcherId]: {
          ...state[action.watcherId],
          status: ERROR,
        },
      };

    case ESTIMATE_WATCHER_DELETE_REQUEST:
      return Object.entries(state)
        .filter(([, v]) => v && v.id !== action.watcherId)
        .reduce((acc, [k, v]) => {
          acc[k] = v;
          return acc;
        }, {});

    default:
      return state;
  }
};

export default reducer;
