import qs from 'qs';
import { map, flow, flatten, get } from 'lodash/fp';
import { push } from 'connected-react-router';
import * as authService from './authService';
import { fetch } from 'state/fetchMiddleware';
import { identify, track, reset, getUserData, Events } from 'components/analytics';
import { SET_FLAGS } from 'state/flags/actions';
import { fetchProducts } from 'state/products/actions';
import { clientSubdomains } from 'cr-core/constants/generic';

export const AUTH_SUCCESS = 'auth/AUTH_SUCCESS';
export const AUTH_ERROR = 'auth/AUTH_ERROR';

export const LOGIN_REQUEST = 'auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';

export const AUTH_CHECK_COMPLETE = 'auth/AUTH_CHECK_COMPLETE';

export const VERIFY_SIGNUP_CODE_REQUEST = 'auth/VERIFY_SIGNUP_CODE_REQUEST';
export const VERIFY_SIGNUP_CODE_SUCCESS = 'auth/VERIFY_SIGNUP_CODE_SUCCESS';
export const VERIFY_SIGNUP_CODE_ERROR = 'auth/VERIFY_SIGNUP_CODE_ERROR';
export const COMPLETE_SIGNUP_REQUEST = 'auth/COMPLETE_SIGNUP_REQUEST';
export const COMPLETE_SIGNUP_SUCCESS = 'auth/COMPLETE_SIGNUP_SUCCESS';
export const COMPLETE_SIGNUP_ERROR = 'auth/COMPLETE_SIGNUP_ERROR';
export const PASSWORD_RESET_REQUEST = 'auth/PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'auth/PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'auth/PASSWORD_RESET_ERROR';

// hack for now?
const bootstrapApp = clientId => async dispatch => {
  dispatch(fetchProducts(clientId));
};

export const login = () => async dispatch => {
  dispatch(loginRequest());

  const target = authService.getTarget();
  if (!target || (target !== window.location.pathname && window.location.pathname !== '/')) {
    authService.setTarget();
  }

  try {
    const authResult = await authService.handleAuthentication();
    authService.setToken(authResult.idToken);
    authService.setAPIToken(authResult.accessToken);
    authService.setProfile(authResult.idTokenPayload);
    const data = {};
    const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if (queryParams.crbcode) {
      data.code = queryParams.crbcode;
    }
    const { data: profile } = await dispatch(fetch('/login', { method: 'POST', data }));
    authService.identifyUserWithAnalytics(profile);
    // authService.clearTarget();
    dispatch(loginSuccess(profile));
    identify(profile.id, getUserData(profile));
    track(Events.LOGIN, { type: 'provider' });
    const workspaces = flow(map('workspaces'), flatten)(profile.teams);
    if (!workspaces.length) {
      dispatch(push('/no-workspaces'));
    }
  } catch (error) {
    console.log('auth failure', error);
    dispatch(authError(error));
    // track(Events.LOGIN_FAILED, { type: 'provider' });
  }
};

export const loginWithUsername = (username, password) => async dispatch => {
  try {
    await authService.loginWithUsername(username, password);
  } catch (error) {
    console.log('auth failure', error);
    dispatch(authError(error));
    track(Events.LOGIN_FAILED, { type: 'email' });

    if (error.code === 'access_denied') {
      return error.description;
    }

    return 'Login failed. Please try again later.';
  }
};

export const logout = () => dispatch => {
  track(Events.LOGOUT);
  reset();
  authService.logout();
  dispatch(logoutSuccess());
};

const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

const loginSuccess = profile => dispatch => {
  dispatch({
    type: LOGIN_SUCCESS,
    profile,
  });

  const { flags } = profile;
  if (flags) {
    dispatch({
      type: SET_FLAGS,
      flags,
    });
  }

  // const prevSelectedAccount = localStorage.getItem('selectedAccount');
  // let selectedAccount;
  // if (find({ id: prevSelectedAccount }, profile.accounts)) {
  //   selectedAccount = prevSelectedAccount;
  // } else {
  //   selectedAccount = profile.accounts[0].id;
  // }
  // dispatch(setAccount(selectedAccount));

  dispatch(
    bootstrapApp(
      profile.client && profile.client.subdomain !== clientSubdomains.MCA && profile.client.id
    )
  );
};

const authError = error => ({
  type: AUTH_ERROR,
  error,
});

const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

const authCheckComplete = () => ({
  type: AUTH_CHECK_COMPLETE,
});

export const checkAuth = () => async dispatch => {
  if (!authService.isTokenExpired()) {
    try {
      const { data: profile } = await dispatch(fetch('/login', { method: 'POST' }));
      authService.identifyUserWithAnalytics(profile);
      dispatch(loginSuccess(profile));
      identify(profile.id, getUserData(profile));

      const workspaces = flow(get('teams'), map('workspaces'), flatten)(profile);
      if (!workspaces.length) {
        dispatch(push('/no-workspaces'));
      }
    } catch (error) {
      console.log('auth check failed', error);
      dispatch(authError(error));
    }
  }

  dispatch(authCheckComplete());
};

export const resetPassword = email => async dispatch => {
  dispatch({ type: PASSWORD_RESET_REQUEST });
  try {
    const data = { email };
    const { hostname } = window.location;
    const subdomain = hostname.includes('localhost')
      ? window.location.host
      : hostname.split('.')[0];
    if (subdomain !== 'app' && subdomain !== 'app-dev') {
      data.subdomain = subdomain;
    }
    await dispatch(fetch('/reset-password', { method: 'POST', data }));
    dispatch({ type: PASSWORD_RESET_SUCCESS });
  } catch (error) {
    dispatch({ type: PASSWORD_RESET_ERROR, error });
  }
};

export const verifySignupCode = code => async dispatch => {
  dispatch({ type: VERIFY_SIGNUP_CODE_REQUEST });
  try {
    await dispatch(fetch('/verify-signup-code', { params: { code } }));
    dispatch({ type: VERIFY_SIGNUP_CODE_SUCCESS });
  } catch (error) {
    dispatch({ type: VERIFY_SIGNUP_CODE_ERROR, error });
  }
};

export const completeSignup = (code, data) => async dispatch => {
  dispatch({ type: COMPLETE_SIGNUP_REQUEST, data });
  try {
    await dispatch(fetch(`/v2/users/${code}/complete-signup`, { method: 'POST', data }));
    dispatch({ type: COMPLETE_SIGNUP_SUCCESS, data });
  } catch (error) {
    dispatch({ type: COMPLETE_SIGNUP_ERROR, error });
  }
};
