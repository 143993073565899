import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(2),
  },
  line: {
    content: ' ',
    borderBottom: '1px solid #b3bac7',
    width: '100%',
    marginBottom: '0.55em',
  },
  text: {
    margin: `0 ${theme.spacing(2)}px`,
    whiteSpace: 'nowrap',
  },
}));

export default ({ text, className, ...props }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, className)} {...props}>
      <div className={classes.line} />
      <div className={classes.text}>{text}</div>
      <div className={classes.line} />
    </div>
  );
};
