import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { sumBy } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { getClientSettings, getDisplayCurrency, getFlags } from 'state/authentication/selectors';
import DataTable from '../dataTable';
import getColumns, { getDownloadColumns } from './columns';
import EstimateCard from 'components/estimateCard';
import { TableHeader } from '../components';
import { csvDownload } from 'utils';
import { format as formatDate } from 'date-fns';

const reportName = 'LeadMarketSpend';
const description =
  'Lead Market report shows Estimates Volume and Spend by Lead Market. The Report enables comparison by lead market. View overall spend in one region versus another, compare country by country and know for which countries you spend the most. Use the filters on the left to further customise this report.';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
  estimateCard: {
    marginBottom: 0,
    borderRadius: 0,
  },
}));

const Table = ({ estimates, displayCurrency, clientSettings, flags, title }) => {
  const classes = useStyles();

  const columns = useMemo(
    () =>
      getColumns(
        estimates,
        displayCurrency,
        {
          originals: sumBy('numberOfTVCs', estimates),
          edits: sumBy('numberOfRATVs', estimates),
          // edits: flow(
          //   map(({ numberOfRATVs, edits }) => (edits ? flow(values, sum)(edits) : numberOfRATVs)),
          //   sum
          // )(estimates),
        },
        clientSettings
      ),
    [estimates, displayCurrency, clientSettings]
  );

  const [expandAll, setExpandAll] = useState(false);

  const toggleExpandAll = () => {
    setExpandAll(!expandAll);
  };

  const download = () => {
    csvDownload(
      estimates,
      getDownloadColumns({ clientSettings }),
      `Report_${reportName}_${formatDate(new Date(), 'yyyy-MM-dd')}`
    );
  };

  return (
    <>
      <TableHeader
        flags={flags}
        expandAll={expandAll}
        toggleExpandAll={toggleExpandAll}
        download={download}
        title={title}
      />
      <DataTable
        data={estimates}
        columns={columns}
        reportDescription={description}
        reportEventName={reportName}
        expandAll={expandAll}
        initialState={{
          groupBy: ['region', 'country'],
          sortBy: ['region', 'country', 'estimate'],
        }}
        SubComponent={({ row }) => (
          <EstimateCard estimate={row.original} target="_blank" className={classes.estimateCard} />
        )}
      />
    </>
  );
};

const mapStateToProps = state => ({
  displayCurrency: getDisplayCurrency(state),
  flags: getFlags(state),
  clientSettings: getClientSettings(state),
});

export default connect(mapStateToProps)(Table);
