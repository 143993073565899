import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { lifecycle, compose, renderNothing, branch } from 'recompose';
import { Route, NavLink, Link, Redirect, withRouter } from 'react-router-dom';
import { find, first, map, isEmpty } from 'lodash/fp';
import { colors, font } from 'shared/styles';
import { fetchEstimateHistory } from 'state/estimateHistory/actions';
import { getHistoryByEstimateId } from 'state/estimateHistory/selectors';
import { HistoryIcon } from 'icons';
import { EstimateStatuses, HistoryTypes, toPretty } from 'cr-core/constants';
import { Events, track, getEstimateData } from 'components/analytics';
import UserAvatar from 'components/userAvatar';
import WhatWhenBy from 'components/whatWhenBy';
import SvgAttachmentsIcon from 'icons/AttachmentsIcon';
import EstimateBidsTable from 'components/estimateBidsTable';
import { EstimateSummaryBarContainer } from 'components/estimateSummaryBar';
import { FlatButton } from 'components/buttons';
import SvgBackIcon from 'icons/BackIcon';
import EstimateCard from 'components/estimateCard';

const Container = styled.div`
  width: 100%;
  padding: 0px;
  border-top: 1px solid lightgray;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: available;
  box-sizing: border-box;
  overflow: hidden;
  height: calc(100vh - 165px);
`;

const EstimateHistoryList = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px 0 10px 10px;
  border-left: 1px solid lightgray;
  overflow: scroll;
`;

const EstimateHistoryItem = styled(NavLink)`
  background-color: white;
  text-decoration: none;
  color: ${colors.text};
  margin-bottom: 10px;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgray;
  border-right: none;
  border-radius: 5px 0 0 5px;
  display: block;

  &.active,
  &:hover {
    background-color: ${colors.recommendedColumn};
  }

  .title {
    font-weight: ${font.weights.bold};
  }

  .whenByWho {
    font-size: ${font.sizes.small};
  }

  .byName {
    font-weight: ${font.weights.bold};
  }
`;

const HistoryItemDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    margin-right: 10px;
  }
`;

const MainContent = styled.div`
  flex: 1;
  overflow: auto;
`;

const SubmitForApprovalMessage = styled.div`
  padding: 20px;
  margin: 20px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 5px;
  color: ${colors.text};
  .title {
    font-weight: ${font.weights.bold};
    margin-bottom: 10px;
  }
`;

const Approver = styled(UserAvatar)`
  margin: 10px;
`;

const ApproversContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const WhatWhenByStyled = styled(WhatWhenBy)`
  text-align: right;
`;

const UserList = branch(
  ({ users }) => isEmpty(users),
  renderNothing,
)(({ users }) => (
  <SubmitForApprovalMessage>
    <div className="title">Approvers</div>
    <ApproversContainer>
      {map(
        user => (
          <Approver key={user.id} user={user} />
        ),
        users,
      )}
    </ApproversContainer>
  </SubmitForApprovalMessage>
));

const FileName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const BackButton = styled(FlatButton)`
  flex: 0;
  padding-right: 20px;
`;

// hack for now
const StyledBidsTable = styled(EstimateBidsTable)`
  width: unset;
`;

const StyledEstimateCard = styled(EstimateCard)`
  margin: 20px;
`;

const FileList = branch(
  ({ files }) => isEmpty(files),
  renderNothing,
)(({ files }) => (
  <SubmitForApprovalMessage>
    <div className="title">Attachments</div>
    <div>
      {map(
        ({ id, filename }) => (
          <FileName key={id}>
            <SvgAttachmentsIcon className="icon" />
            {filename}
          </FileName>
        ),
        files,
      )}
    </div>
  </SubmitForApprovalMessage>
));

const EstimateHistory = ({ estimateHistory, estimateId, match, path }) => {
  if (!estimateHistory.length) {
    return null;
  }

  return (
    <>
      <EstimateSummaryBarContainer>
        <BackButton Component={Link} to={`/estimates/${estimateId}`} data-test="back-button">
          <SvgBackIcon className="icon" /> Back
        </BackButton>
      </EstimateSummaryBarContainer>
      <Container>
        <Route
          exact
          path={path}
          render={() => <Redirect to={`${match.url}/${first(estimateHistory).id}`} />}
        />
        <Columns>
          <MainContent>
            <Route
              path={`${match.url}/:historyId`}
              render={({ match }) => {
                const selectedItem = find({ id: match.params.historyId }, estimateHistory);

                const estimate = selectedItem.data.estimate
                  ? {
                      ...selectedItem.data.estimate,
                      status:
                        selectedItem.eventType === HistoryTypes.ESTIMATE_CLOSE_ACTUALISATION
                          ? EstimateStatuses.ACTUALISED
                          : selectedItem.data.estimate.status,
                    }
                  : null;

                return (
                  <>
                    {estimate && <StyledEstimateCard estimate={estimate} readOnly />}
                    {selectedItem.data.message && (
                      <SubmitForApprovalMessage>
                        <div className="title">Message</div>
                        {selectedItem.data.message}
                      </SubmitForApprovalMessage>
                    )}
                    {selectedItem.data.approvers && (
                      <UserList users={selectedItem.data.approvers} />
                    )}
                    {selectedItem.data.files && <FileList files={selectedItem.data.files} />}
                    {selectedItem.data.estimate && <StyledBidsTable readOnly />}
                  </>
                );
              }}
            />
          </MainContent>

          <EstimateHistoryList data-test="history-items">
            <Route
              path={`${match.url}`}
              render={({ match }) => {
                return estimateHistory.map(({ id, eventType, createdAt, user, data }, index) => (
                  <EstimateHistoryItem
                    key={id}
                    to={`${match.url}/${id}`}
                    className={match.params.historyId === id ? 'active' : ''}
                    onClick={() => {
                      track(Events.ESTIMATE_HISTORY_ITEM_CLICK, {
                        ...(data.estimate ? getEstimateData(data.estimate) : {}),
                        index,
                        eventType,
                      });
                    }}
                  >
                    <HistoryItemDetails>
                      <HistoryIcon type={eventType} />
                      <div className="title">{toPretty(eventType)}</div>
                    </HistoryItemDetails>
                    <WhatWhenByStyled when={createdAt} by={user} />
                  </EstimateHistoryItem>
                ));
              }}
            />
          </EstimateHistoryList>
        </Columns>
      </Container>
    </>
  );
};

const mapStateToProps = (state, { match }) => ({
  estimateHistory: getHistoryByEstimateId(match.params.id)(state),
  selectedItemId: match.params.historyId,
  estimateId: match.params.id,
});

const mapDispatchToProps = (dispatch, { match }) => ({
  fetchHistory: () => dispatch(fetchEstimateHistory(match.params.id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.fetchHistory();
    },
  }),
  withRouter,
)(EstimateHistory);
