import React from 'react';
import { get, pick, omitBy, flow } from 'lodash/fp';
import { compose, withHandlers, withState } from 'recompose';
import styled from 'styled-components';
import { track, getEstimateData } from 'components/analytics';
import ConfirmationModal from 'components/modals/confirmation';
import { font } from 'shared/styles';

const ConfirmEditModal = styled(ConfirmationModal)`
  .bodyText {
    margin-bottom: 20px;
  }

  .bold {
    font-weight: ${font.weights.bold};
  }
`;

const withDeleteConfirmationDialog = (propToIntercept, options = {}) => WrappedComponent => {
  const { trackEvent = {} } = options;
  const { props: eventPropsName = {} } = trackEvent;

  class withDeleteConfirmationDialog extends React.Component {
    handleSubmitOrConfirm = event => {
      if (event) {
        event.stopPropagation();
      }
      this.props.toggleShowEditConfirmation(true);
    };

    render() {
      const { estimate } = this.props;
      const extraProps = {
        [propToIntercept]: this.handleSubmitOrConfirm,
      };
      const eventProps = flow(
        pick(eventPropsName),
        omitBy(v => typeof v === 'undefined')
      )(this.props);

      return (
        <>
          <ConfirmEditModal
            onConfirm={() => {
              this.props[propToIntercept]();
              if (get('success', trackEvent)) {
                const estimateData = estimate ? getEstimateData(estimate) : {};
                track(trackEvent.success, { ...estimateData, ...eventProps });
              }
            }}
            onCancel={() => {
              if (get('cancel', trackEvent)) {
                const estimateData = estimate ? getEstimateData(estimate) : {};
                track(trackEvent.cancel, { ...estimateData, ...eventProps });
              }
            }}
            onRequestClose={() => this.props.toggleShowEditConfirmation(false)}
            isOpen={this.props.shouldShowEditConfirmation}
            title="Confirm Delete"
          >
            <div className="bodyText bold">Are you sure you want to remove this?</div>
          </ConfirmEditModal>

          <WrappedComponent {...this.props} {...extraProps} />
        </>
      );
    }
  }

  return compose(
    withState('shouldShowEditConfirmation', 'setShowEditConfirmation', false),
    withHandlers({
      toggleShowEditConfirmation: ({ setShowEditConfirmation }) => show =>
        setShowEditConfirmation(show),
    })
  )(withDeleteConfirmationDialog);
};

export default withDeleteConfirmationDialog;
