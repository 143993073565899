import React from 'react';
import styled from 'styled-components';
import { find } from 'lodash/fp';
import { Select } from 'components/forms';
import { updatedCurrencies } from 'cr-core/currencies';

const Container = styled.div`
  display: flex;
  width: 150px;

  .reactSelect__control {
    cursor: pointer;
  }
`;

export default ({ selectedCurrency, onChange, className = '', ...props }) => (
  <Container className={`currency-selector ${className}`}>
    <Select
      options={updatedCurrencies}
      onChange={onChange}
      defaultValue={updatedCurrencies[0]}
      value={find({ value: selectedCurrency }, updatedCurrencies)}
      {...props}
    />
  </Container>
);

export const field = ({ defaultValue, className = '', input, styles, ...props }) => {
  if (defaultValue && !input.value) {
    input.onChange(find({ value: defaultValue }, updatedCurrencies));
  }
  return (
    <Container className={`currency-selector ${className}`}>
      <Select
        value={input.value}
        options={updatedCurrencies}
        onChange={input.onChange}
        classNamePrefix="reactSelect"
        styles={{
          valueContainer: () => ({
            padding: '0 8px',
          }),
          dropdownIndicator: () => ({
            padding: '0 8px',
            height: '20px',
          }),
          ...styles,
        }}
        {...props}
      />
    </Container>
  );
};
