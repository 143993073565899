import { findIndex } from 'lodash/fp';
import {
  TEAM_FETCH_SUCCESS,
  TEAMS_FETCH_REQUEST,
  TEAMS_FETCH_SUCCESS,
  TEAMS_FETCH_ERROR,
  TEAMS_RESET,
} from './actions';

const defaultState = {
  list: [],
  isLoading: false,
  error: null,
  pagination: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case TEAMS_FETCH_REQUEST:
      return { ...state, list: [], isLoading: true, error: null };

    case TEAMS_FETCH_SUCCESS:
      return {
        ...state,
        list: action.teams,
        pagination: action.pagination,
        isLoading: false,
        error: null,
      };

    case TEAM_FETCH_SUCCESS:
      const { team } = action;
      const teams = state.list;
      const index = findIndex({ id: team.id }, teams);
      teams[index] = team;
      return { ...state, error: null, list: teams };

    case TEAMS_FETCH_ERROR:
      return { ...state, list: [], isLoading: false, error: action.error };

    case TEAMS_RESET:
      return { ...state, list: [], isLoading: false, error: null };
    default:
      return state;
  }
};
