import React from 'react';
import clsx from 'clsx';
import { sumBy, map } from 'lodash/fp';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Paper, Typography, Tooltip } from '@material-ui/core';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import GetAppIcon from '@material-ui/icons/GetApp';
// import PrintIcon from '@material-ui/icons/Print';
// import ShareIcon from '@material-ui/icons/Share';
import { EstimateStatuses } from 'cr-core/constants';
import { formatAsCurrency } from 'cr-core/currencyUtils';
import { Events, track } from 'components/analytics';
import ReportsDropdownMenu from './titleMenuDropdown';

const useStyles = makeStyles(theme => {
  return {
    title: {
      display: 'flex',
      alignItems: 'center',
      height: 45,
    },
    alignLeft: {
      textAlign: 'left',
    },
    tableHeader: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(1),
    },
    tableHeaderWithTitle: {
      justifyContent: 'space-between',
    },
    headerContainer: {
      marginBottom: theme.spacing(1),
    },
    tableActions: {
      marginTop: theme.spacing(1),
    },
    chartContainer: {
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },

    popover: {
      padding: theme.spacing(1),
    },

    tableLegend: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    tableLegendDescription: {},
    tableLegendListContainer: {
      marginTop: theme.spacing(1),
    },
    tableLegendList: {
      listStyle: 'none',
      paddingLeft: theme.spacing(1),
      marginTop: theme.spacing(0.5),
    },
    tableLegendItemLabel: {
      fontWeight: 600,
    },
  };
});

export const ChartContainer = ({ children }) => (
  <Paper className={useStyles().chartContainer}>{children}</Paper>
);

export const NotSet = <i>Not Set</i>;

export const AlignLeft = val => {
  const classes = useStyles();
  return <div className={classes.alignLeft}>{val}</div>;
};

export const ReportTitle = ({ title }) => {
  const classes = useStyles();
  return (
    <Typography variant="h5" className={classes.title}>
      {title}
      <ReportsDropdownMenu />
    </Typography>
  );
};

export const Message = styled.div`
  text-align: center;
  padding: 30px;
  opacity: 0.8;
  background: transparent;
  border: 0;
`;

export const formatFinanceValue = (value, displayCurrency) => {
  if (typeof value === 'undefined') {
    return '-';
  }
  let negative = value < 0;
  let formatted = formatAsCurrency(displayCurrency, Math.abs(value));
  return negative ? `(${formatted})` : formatted;
};

export const CurrencyValueCell =
  displayCurrency =>
  ({ cell: { value } }) =>
    formatFinanceValue(value, displayCurrency);

export const FooterValueCell = formatFinanceValue;

export const FooterAverage =
  displayCurrency =>
  ({ data = [] }) =>
    formatFinanceValue(
      sumBy(
        ({ recommendedBidTotal, actualisedTotal }) => actualisedTotal || recommendedBidTotal || 0,
        data
      ) / data.length,
      displayCurrency
    );

export const FooterTotal =
  (displayCurrency, type) =>
  ({ data = [] }) =>
    formatFinanceValue(
      sumBy(({ recommendedBidTotal, actualisedTotal, status }) => {
        switch (type) {
          case 'estimated':
            return recommendedBidTotal || 0;
          case 'actualised':
            return status === EstimateStatuses.ACTUALISED && actualisedTotal ? actualisedTotal : 0;
          default:
            return actualisedTotal || recommendedBidTotal || 0;
        }
      }, data),
      displayCurrency
    );

export const ReportHeader = ({ title }) => {
  const classes = useStyles();
  return (
    <div className={classes.headerContainer}>
      <Typography variant="h5" className={classes.title}>
        {title}
        <ReportsDropdownMenu />
      </Typography>
    </div>
  );
};

// const PopoverWrapper = ({ text, children }) => {
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const handleClick = event => setAnchorEl(event.currentTarget);
//   const handleClose = () => setAnchorEl(null);
//   const open = Boolean(anchorEl);
//   const id = open ? 'simple-popover' : undefined;
//   const classes = useStyles();

//   return (
//     <>
//       {children({ handleClick })}
//       <Popover
//         id={id}
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'center',
//         }}
//         transformOrigin={{
//           vertical: 'bottom',
//           horizontal: 'center',
//         }}
//       >
//         <Typography className={classes.popover}>{text}</Typography>
//       </Popover>
//     </>
//   );
// };

const TableActions = ({ flags = {}, expandAll, toggleExpandAll, download, reportName }) => {
  const classes = useStyles();
  // if (!flags['mcaOnly']) {
  //   return null;
  // }

  const wrapEventWithAnalitics =
    (handler, event, eventProps = {}) =>
    (...props) => {
      track(event, { report: reportName, ...eventProps });
      handler(...props);
    };

  const expandTooltipText = expandAll ? 'Collapse all' : 'Expand all';

  return (
    <div className={classes.tableActions}>
      <Tooltip placement="top" title={expandTooltipText} aria-label={expandTooltipText}>
        <IconButton
          aria-label="expand/group"
          size="medium"
          onClick={wrapEventWithAnalitics(toggleExpandAll, Events.REPORT_ACTION_EXPAND_CLICK, {
            expand: !expandAll,
          })}
        >
          {expandAll ? (
            <UnfoldLessIcon fontSize="inherit" />
          ) : (
            <UnfoldMoreIcon fontSize="inherit" />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip placement="top" title="Download" aria-label="Download">
        <IconButton
          aria-label="download"
          size="medium"
          onClick={wrapEventWithAnalitics(download, Events.REPORT_ACTION_DOWNLOAD_CLICK)}
        >
          <GetAppIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      {/* <PopoverWrapper text="Coming soon">
        {({ handleClick }) => (
          <IconButton
            aria-label="share"
            size="medium"
            onClick={wrapEventWithAnalitics(handleClick, Events.REPORT_ACTION_SHARE_CLICK)}
          >
            <ShareIcon fontSize="inherit" />
          </IconButton>
        )}
      </PopoverWrapper> */}
      {/* <PopoverWrapper text="Coming soon">
        {({ handleClick }) => (
          <IconButton
            aria-label="print"
            size="medium"
            onClick={wrapEventWithAnalitics(handleClick, Events.REPORT_ACTION_PRINT_CLICK)}
          >
            <PrintIcon fontSize="inherit" />
          </IconButton>
        )}
      </PopoverWrapper> */}
    </div>
  );
};

export const TableHeaderWithTitle = ({ title, flags, expandAll, toggleExpandAll, download }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.tableHeader, classes.tableHeaderWithTitle)}>
      <Typography variant="h5" className={classes.title}>
        {title}
        <ReportsDropdownMenu />
      </Typography>
      <TableActions
        flags={flags}
        expandAll={expandAll}
        toggleExpandAll={toggleExpandAll}
        download={download}
        reportName={title}
      />
    </div>
  );
};

export const TableHeader = ({ title, flags, expandAll, toggleExpandAll, download }) => {
  const classes = useStyles();
  return (
    <div className={classes.tableHeader}>
      <TableActions
        flags={flags}
        expandAll={expandAll}
        toggleExpandAll={toggleExpandAll}
        download={download}
        reportName={title}
      />
    </div>
  );
};

export const TableLegend = ({ description, afterLegend, columns }) => {
  const classes = useStyles();
  return (
    <div className={classes.tableLegend}>
      <div className={classes.tableLegendDescription}>{description}</div>

      <div className={classes.tableLegendListContainer}>
        <b>Legend</b>
        <ul className={classes.tableLegendList}>
          {map(
            ({ Header, description }) => (
              <li key={Header}>
                <span className={classes.tableLegendItemLabel}>{Header}</span>: {description}
              </li>
            ),
            columns
          )}
        </ul>
      </div>
      <div className={classes.tableLegendDescription}>{afterLegend}</div>
    </div>
  );
};
