import { isEmpty, get, mapValues, map } from 'lodash/fp';
import { Events, track } from 'components/analytics';
import { SUBMIT_ESTIMATE_SUCCESS } from './estimates/actions';
import { APPROVAL_DELETE_SUCCESS, APPROVE_ESTIMATE_SUCCESS } from './estimateApprovals/actions';
import { getWorkspaceById } from './workspaces/selectors';
import { getEstimateById } from './estimates/selectors';
import { SET_DISPLAY_CURRENCY } from './users/actions';

const REDUX_CHANGE = '@@redux-form/CHANGE';

export default ({ getState }) => next => action => {
  const state = getState();
  const event = getAnalyticsEventFromAction(action, state);

  if (event) {
    const { type, ...props } = event;
    track(type, props);
  }
  return next(action);
};

const getAnalyticsEventFromAction = (action, state) => {
  const eventFactory = actionEventMap[action.type];
  return eventFactory && eventFactory(action)(state);
};

const decorateWithEstimateInfo = (obj, estimate, state) => {
  const { id, mediaType, workspaceId, leadMarket, subBrand, externalUrl } = estimate;
  const workspace = getWorkspaceById(workspaceId)(state);
  return Object.assign({}, obj, {
    estimateId: id,
    mediaType,
    workspaceName: get('name', workspace),
    leadMarket,
    subBrand,
    externalUrl,
  });
};

const getValue = value => (Array.isArray(value) ? map('value', value) : value);

const getFormValues = (formValues = {}, field, value) =>
  mapValues(getValue, { ...formValues, [field]: value });

const actionEventMap = {
  [SUBMIT_ESTIMATE_SUCCESS]: ({ estimate, approvers, message }) => state => {
    return decorateWithEstimateInfo(
      {
        type: 'Estimate Submitted for Approval',
        approvers,
        includesMessage: !isEmpty(message),
      },
      estimate,
      state
    );
  },
  [APPROVE_ESTIMATE_SUCCESS]: ({ estimateId, message }) => state => {
    const estimate = getEstimateById(estimateId)(state);

    return decorateWithEstimateInfo(
      {
        type: 'Estimate Approved by Approver',
        includesMessage: !isEmpty(message),
      },
      estimate,
      state
    );
  },
  [SET_DISPLAY_CURRENCY]: ({ currency }) => () => ({
    type: Events.CHANGE_DISPLAY_CURRENCY,
    currency,
  }),
  [APPROVAL_DELETE_SUCCESS]: ({ approvalId, estimateId }) => state => {
    const estimate = getEstimateById(estimateId)(state);
    return decorateWithEstimateInfo(
      {
        type: 'Estimate Approver Removed',
        approvalId,
      },
      estimate,
      state
    );
  },
  [REDUX_CHANGE]: ({ payload, meta }) => state => {
    const { form, field } = meta;
    const value = getValue(payload);

    switch (form) {
      case 'estimateFilter':
        const values = getFormValues(get(`form.${form}.values`, state), field, payload);
        return {
          type: Events.ESTIMATES_FILTER_CHANGED,
          field,
          value,
          values,
        };
      default:
    }
  },
};
