import { InfiniteData, useMutation, useQueryClient } from 'react-query';
import SupplierService, {
  SupplierPaginatedData,
  UpdateSupplierForm,
} from 'services/SupplierService';

const useUpdateSupplier = (supplierId: string, accountId: string) => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(
    (data: UpdateSupplierForm) => SupplierService.updateSupplier(supplierId, data),
    {
      onMutate: async data => {
        await queryClient.cancelQueries(['account', accountId, 'suppliers'], { exact: true });

        const oldData = queryClient.getQueryData<InfiniteData<SupplierPaginatedData>>(
          ['account', accountId, 'suppliers'],
          {
            exact: true,
          }
        );

        queryClient.setQueryData<InfiniteData<SupplierPaginatedData>>(
          ['account', accountId, 'suppliers'],
          (old = { pageParams: [], pages: [] }) => ({
            ...old,
            pages:
              old.pages.map(page => ({
                ...page,
                data: page.data.map(supplier =>
                  supplier.id === supplierId ? { ...supplier, ...data } : supplier
                ),
              })) ?? [],
          })
        );

        return { oldData };
      },

      onError: (_error, _variabled, context: any) => {
        queryClient.setQueryData(['account', accountId, 'suppliers'], context?.oldData);
      },

      onSettled: () => {
        queryClient.invalidateQueries(['account', accountId, 'suppliers'], { exact: true });
      },
    }
  );

  return { updateSupplier: mutate, ...rest };
};

export default useUpdateSupplier;
