import { Box, makeStyles, Typography } from '@material-ui/core';
import AppContainer from 'components/appContainer';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUser } from 'state/authentication/selectors';

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    marginBottom: 50,
    '& main': {
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  welcome: {
    fontWeight: 'bold',
    fontSize: 24,
    textAlign: 'center',
  },
  intro: {
    marginTop: 10,
    fontSize: 16,
    textAlign: 'center',
  },
  featureContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media screen and (min-width: 850px)': {
      flexDirection: 'row',
    },
  },
  featureBox: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(6),
    '@media screen and (min-width: 1000px)': {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
    },
    '@media screen and (min-width: 850px)': {
      marginBottom: 0,
    },
  },
  featureTitle: {
    textTransform: 'uppercase',
    fontSize: 18,
    textAlign: 'center',
  },
  featureText: {
    marginTop: 10,
    fontSize: 16,
    textAlign: 'center',
  },
  footer: {
    backgroundColor: '#3f87b6',
  },
  footerText: {
    textAlign: 'center',
    color: '#fff',
    fontWeight: 600,
  },
  // this along with explicitly setting height on image containers is
  // to prevent content being all jumpy while images are loading
  // deal with this better later on to include width as well
  image: {
    height: '100%',
    maxWidth: '100%',
  },
}));

const Home = ({ user }: { user: any }) => {
  const name = user?.firstName?.trim();
  const styles = useStyles();

  const items = [
    {
      icon: 'rocket.png',
      title: 'Campaigns',
      text: 'All your Campaigns are in one place',
      href: '/campaigns',
    },
    {
      icon: 'lightbulb.png',
      title: 'Estimates',
      text: 'View or create detailed estimates in a few easy steps',
      href: '/estimates',
    },
    {
      icon: 'graph.png',
      title: 'Reports',
      text: 'Powerful reporting and insights',
      href: '/reports',
    },
  ];

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      {/* @ts-ignore */}
      <AppContainer className={styles.container}>
        <Typography component="h1" className={styles.welcome}>
          {name ? `Welcome ${name}` : 'Welcome'},
        </Typography>
        <Typography className={styles.intro}>
          Control Room Invest provides you with detailed management of your advertising production
          budgets.
        </Typography>
        <Box display="flex" className={styles.featureContainer} mt={12} justifyContent="center">
          {items.map(({ href, title, text, icon }, index) => (
            <Box key={index} className={styles.featureBox}>
              <Box textAlign="center" height={120}>
                <Link to={href}>
                  <img src={`/images/${icon}`} className={styles.image} alt={title} />
                </Link>
              </Box>
              <Typography className={styles.featureTitle}>{title}</Typography>
              <Typography className={styles.featureText}>{text}</Typography>
            </Box>
          ))}
        </Box>
      </AppContainer>
      <Box component="footer">
        <Box textAlign="center" mb={2} height={24}>
          <a href="https://murphycobb.com" target="_blank" rel="noreferrer">
            <img src="/images/mca-logo.svg" alt="MCA" className={styles.image} />
          </a>
        </Box>
        <Box py={1.5} className={styles.footer}>
          {/*<Typography className={styles.footerText}>*/}
          {/*  &copy; {year} MurphyCobb &amp; Associates Ltd.*/}
          {/*</Typography>*/}
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  user: getUser(state),
});

export default connect(mapStateToProps)(Home);
