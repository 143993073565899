import React from 'react';
import styled from 'styled-components';
import Modal from 'components/modal';
import { Button } from 'components/buttons';
import { font, colors } from 'shared/styles';

// Define vars we'll be using
const color = colors.primary;
const signFontSize = 4;

const SubmitForApprovalModal = styled(Modal)`
  .modalWindow {
    width: 400px;
  }

  .header {
    background: white;
    text-shadow: none;
    line-height: 0;
  }

  .footer {
    border: none;
    padding-bottom: 30px;
  }

  .textContainer {
    text-align: center;
  }

  .triangle-container {
    margin: 0 auto;
    display: flex;
    padding-bottom: 30px;
  }

  .triangle {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    width: 0px;
    height: 0px;
    left: 10px;
    position: relative;

    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-bottom: 120px solid ${color};

    &::after {
      width: 0;
      height: 0;
      border-left: 55px solid transparent;
      border-right: 55px solid transparent;
      border-bottom: 110px solid white;
      top: 37px;
      position: absolute;
      display: flex;
      content: '';
    }
  }

  .sign {
    font-size: ${signFontSize}em;
    color: ${color};
    z-index: 2;
    position: absolute;
    top: 70px;
    margin: auto;
  }
`;

const ModalBody = styled.div`
  .title {
    margin-bottom: 20px;
  }
`;

const CloseButton = styled(Button)`
  margin: 0 auto;
  background: ${colors.cancel};
  border: 0;
  padding: 0 30px;

  :hover {
    background-color: ${colors.cancelHover};
  }
`;

const TextHeader = styled.div`
  font-weight: 600;
  margin-bottom: 5px;
`;

const TextDescription = styled.div`
  font-weight: 200;
  font-size: ${font.sizes.largish};
`;

const ErrorModal = ({
  onRequestClose,
  addApprovers,
  completed,
  showCompleted,
  header,
  description,
  buttons,
  ...restProps
}) => {
  const defaultButtons = <CloseButton onClick={onRequestClose}>Close</CloseButton>;
  return (
    <SubmitForApprovalModal
      buttons={buttons || defaultButtons}
      {...restProps}
      onRequestClose={onRequestClose}
    >
      <ModalBody>
        <div className="triangle-container">
          <div className="triangle">
            <div className="sign">!</div>
          </div>
        </div>
        <div className="textContainer">
          <TextHeader>{header || 'Warning'}</TextHeader>
          <TextDescription>{description}</TextDescription>
        </div>
      </ModalBody>
    </SubmitForApprovalModal>
  );
};

export default ErrorModal;
