import React from 'react';

const SvgCameraIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="camera-icon_svg__a"
        width="130.8%"
        height="140%"
        x="-15.4%"
        y="-20%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      d="M25.081.944c.34-.333.919-.097.919.375v17.362c0 .472-.58.708-.919.375l-5.507-5.41v4.245c0 1.162-.965 2.109-2.145 2.109H2.145C.965 20 0 19.053 0 17.891V2.11C0 .947.964 0 2.145 0h15.284c1.18 0 2.145.947 2.145 2.109v4.246L25.08.944z"
      filter="url(#camera-icon_svg__a)"
      transform="translate(2 5)"
    />
  </svg>
);

export default SvgCameraIcon;
