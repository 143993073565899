import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import HeaderSimple from 'components/header/index.simple';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(2),
      height: `calc(100vh - ${
        theme.mixins.toolbar[`@media (min-width:${theme.breakpoints.values.sm}px)`].minHeight
      }px)`,
      position: 'relative',
    },
  };
});

const AppContainer = ({ className, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <HeaderSimple />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

export default AppContainer;
