import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, IconButton, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { getSidePanelData } from 'state/ui/selectors';
import { closeSidePanel } from 'state/ui/actions';
import { createTeam } from 'state/teams/actions';
import { showErrorNotification, showSuccessNotification } from 'state/notifications/actions';

const useStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(4, 3),
    position: 'relative',
  },
  menuButton: {
    position: 'absolute',
    right: 2,
    top: 10,
  },
  nameField: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

const CreateTeam = ({ sidePanelData, closeSidePanel, createTeam, accountId }) => {
  const classes = useStyles();
  const [name, setName] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    createTeam({ name, accountId });
  };

  return (
    <div className={classes.body}>
      <Typography variant="h4">Create new team</Typography>
      <IconButton onClick={closeSidePanel} className={classes.menuButton}>
        <CloseIcon />
      </IconButton>

      <div>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <TextField
            label="Team Name"
            variant="outlined"
            type="text"
            fullWidth
            onChange={event => setName(event.target.value)}
            value={name}
            className={classes.nameField}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={!name || name.length < 4}
            className={classes.button}
          >
            Create Team
          </Button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  sidePanelData: getSidePanelData(state),
});

const mapDispatchToProps = (dispatch, state) => ({
  closeSidePanel: () => dispatch(closeSidePanel()),
  createTeam: async ({ name, accountId }) => {
    try {
      await dispatch(createTeam({ name, accountId }));
      dispatch(showSuccessNotification({ message: `Team "${name}" created` }));
      dispatch(closeSidePanel());
      // dispatch(openSidePanel({ view: 'TEAM_DETAILS', team: rowData }));
    } catch (e) {
      dispatch(showErrorNotification(e));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeam);
