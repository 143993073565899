import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import uuid from 'uuid/v4';
import { withProps, compose, withState, withHandlers } from 'recompose';
import { Events, track, getEstimateData } from 'components/analytics';
import EstimateStatus from 'components/estimateStatus';
import { font, shadows, colors } from 'shared/styles';
import { MediaTypeIcon } from 'icons';
import SvgTargetIcon from 'icons/TargetIcon';
import SvgCalendarIcon from 'icons/CalendarIcon';
import { formatDateAsQuarter } from 'shared/dateUtils';
import {
  getEstimateById,
  canEdit,
  getApprovalsForEstimate,
  getRevision,
} from 'state/estimates/selectors';
import SvgSendIcon from 'icons/SendIcon';
import { Button } from 'components/buttons';
import ResubmitForApprovalModal from 'components/modals/resend';
import { EstimateStatuses, ApprovalType } from 'cr-core/constants';
import { getLastPathSegment } from 'utils';

const EstimateApprovalsLink = styled(NavLink)`
  display: flex;
  text-decoration: none;
`;

const ResubmitButton = styled(Button)`
  display: flex;
  height: 37px;
  width: 210px;
  font-size: ${font.sizes.large};
`;

export const EstimateSummaryBarContainer = styled.div`
  width: 100%;
  height: 65px;
  background-color: white;
  box-shadow: ${shadows.text};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  color: ${colors.text};
  box-sizing: border-box;
`;

const EstimateSummaryItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;

  .icon {
    margin-right: 5px;
  }
`;

const EstimateSummaryText = styled.div`
  font-size: ${font.sizes.large};
  display: inline-block;
  line-height: 30px;

  &.nowrap {
    white-space: nowrap;
  }
`;

const EstimateStatusDetails = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
`;

const EstimateModals = ({
  shouldShowSubmitModal,
  alreadySubmitted,
  requestSentToAllApprovers,
  hideSubmitModal,
  estimate,
  pageId,
}) => (
  <>
    {shouldShowSubmitModal && (
      <ResubmitForApprovalModal
        isOpen={shouldShowSubmitModal}
        onRequestClose={hideSubmitModal}
        estimateId={estimate.id}
        pageId={pageId}
        alreadySubmitted={alreadySubmitted}
        requestSentToAllApprovers={requestSentToAllApprovers}
      />
    )}
  </>
);

const EstimateSummaryBar = ({
  className,
  estimate,
  path,
  pathName,
  approvals,
  showSubmitModal,
  estimateResubmit,
  canEdit,
  user,
  ...props
}) => {
  const showResubmitButton = useMemo(
    () =>
      estimate.status !== EstimateStatuses.APPROVED &&
      estimate.status !== EstimateStatuses.DRAFT &&
      estimate.approvals.some(approval => approval.status === EstimateStatuses.PENDING_APPROVAL) &&
      canEdit &&
      getLastPathSegment(pathName) === 'approvals' &&
      estimate.approvals
        .filter(approval => approval.approvalType === ApprovalType.CLIENT)
        .some(approval => approval.status !== EstimateStatuses.APPROVED),
    [estimate, canEdit, pathName]
  );

  if (!estimate) {
    return null;
  }
  const { mediaType, name, leadMarket, date } = estimate;
  const revision = getRevision(estimate);
  return (
    <EstimateSummaryBarContainer className={className} data-test="estimate-summary-bar">
      <EstimateModals estimate={estimate} {...props} />

      <EstimateSummaryItem>
        <MediaTypeIcon type={mediaType} />
        <EstimateSummaryText>{name}</EstimateSummaryText>
      </EstimateSummaryItem>
      {leadMarket && (
        <EstimateSummaryItem title="Target Market">
          <SvgTargetIcon className="icon" />
          <EstimateSummaryText>{leadMarket}</EstimateSummaryText>
        </EstimateSummaryItem>
      )}
      {date && (
        <EstimateSummaryItem title="Air Date">
          <SvgCalendarIcon className="icon" />
          <EstimateSummaryText className="nowrap">{formatDateAsQuarter(date)}</EstimateSummaryText>
        </EstimateSummaryItem>
      )}
      {Boolean(revision) && (
        <EstimateSummaryItem title="Revision">
          <EstimateSummaryText className="nowrap" data-test="revision">
            Rev. {revision}
          </EstimateSummaryText>
        </EstimateSummaryItem>
      )}
      <EstimateStatusDetails>
        {showResubmitButton && (
          <ResubmitButton onClick={showSubmitModal}>
            <SvgSendIcon className="icon" />
            <span>Resend Approval</span>
          </ResubmitButton>
        )}

        <EstimateApprovalsLink
          to={`${path}/approvals`}
          onClick={event => {
            event.stopPropagation();
          }}
          data-test="estimate-status"
        >
          <EstimateStatus status={estimate.status} approvals={approvals} />
        </EstimateApprovalsLink>
      </EstimateStatusDetails>
    </EstimateSummaryBarContainer>
  );
};

const mapStateToProps = (state, { estimateId }) => ({
  estimateResubmit: state.resourceStatus.estimateResubmit,
  estimate: getEstimateById(estimateId)(state),
  canEdit: canEdit(estimateId)(state),
});

export default compose(
  connect(mapStateToProps),
  withProps(({ estimate, estimateResubmit }) => ({
    approvals: getApprovalsForEstimate(estimate),
  })),
  withState('pageId', 'setPageId', () => uuid()),
  withState('shouldShowSubmitModal', 'setShowSubmitModal', false),
  withState('alreadySubmitted', 'setAlreadySubmitted', false),
  withState('requestSentToAllApprovers', 'setRequestSentToAllApprovers', false),
  withHandlers({
    showSubmitModal:
      ({
        setShowSubmitModal,
        estimate,
        estimateResubmit,
        setAlreadySubmitted,
        setRequestSentToAllApprovers,
      }) =>
      () => {
        track(Events.ESTIMATE_SUBMIT_CLICK, getEstimateData(estimate));
        setShowSubmitModal(true);

        const isRequestSentToAllApprovers = estimate.approvals
          .filter(approval => approval.approvalType === ApprovalType.CLIENT)
          .every(approval => approval.requestApprovalEmailSent);

        setRequestSentToAllApprovers(isRequestSentToAllApprovers);

        estimateResubmit === 'SUCCESS' && setAlreadySubmitted(true);
      },

    hideSubmitModal:
      ({ estimate, setShowSubmitModal, setRequestSentToAllApprovers, setAlreadySubmitted }) =>
      () => {
        setShowSubmitModal(false);
        setAlreadySubmitted(false);

        estimate.approvals
          .filter(approval => approval.approvalType === ApprovalType.CLIENT)
          .some(approval => !approval.requestApprovalEmailSent) &&
          setRequestSentToAllApprovers(false);
      },
  })
)(EstimateSummaryBar);
