import React from 'react';

const SvgNewspaperIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="newspaper-icon_svg__a"
        width="127.6%"
        height="140%"
        x="-13.8%"
        y="-20%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g fill="currentColor" filter="url(#newspaper-icon_svg__a)" transform="translate(1 5)">
      <path d="M23.424 0L29 5.234V17.43c0 1.068-.924 1.94-2.054 1.94H15l.006-1.94L15.01 0h8.414zm-.587 5.96h4.769l-4.77-4.505V5.96zm4.598 6.644v-1.32H16.723v1.32h10.712zm-10.712 1.028v1.32h10.712v-1.32H16.723zm7.19 3.668v-1.32h-7.19v1.32h7.19zM14 0l-.007 7.567v9.866l-.004 1.94H2.044C.914 19.374 0 18.5 0 17.434L.01 1.941C.01.873.924 0 2.054 0H14zM6.737 12.606v-1.32h-4.95v1.32h4.95zm0-2.333v-1.32h-4.95v1.32h4.95zm5.657 2.404V6.619H7.798v6.058h4.596zM6.737 7.94V6.619h-4.95v1.32h4.95zm5.762-2.334v-1.32H1.787v1.32H12.5zm0-2.262V2.023H1.787v1.32H12.5zM1.787 13.634v1.32H12.5v-1.32H1.787zm7.19 3.669v-1.32h-7.19v1.32h7.19z" />
    </g>
  </svg>
);

export default SvgNewspaperIcon;
