import React from 'react';
import { CancelButton, Button } from 'components/buttons';
import SvgBackIcon from 'icons/BackIcon';
import Modal from 'components/modal';
import styled from 'styled-components';
import WarningBox from 'components/warningBox';
import { withRouter } from 'react-router';

const ModalBody = styled.div`
  .title {
    margin-bottom: 20px;
  }
`;

const List = styled('ul')`
  margin-top: 10px;
  margin-bottom: 0;
  padding-left: 25px;
`;

const PoCodeErrorModal = ({
  estimateId,
  history,
  cancel,
  noEstimatePoCode,
  noCampaignPoCode,
  canEdit,
  ...props
}) => {
  const bothMissing = noEstimatePoCode && noCampaignPoCode;

  const addPoNumber = () => {
    history.push(bothMissing || noEstimatePoCode ? `/estimates/${estimateId}/edit` : '/campaigns');
    cancel();
  };

  const buttons = (
    <>
      <CancelButton onClick={cancel}>
        <SvgBackIcon className="icon" />
        Cancel
      </CancelButton>
      {canEdit && (
        <Button onClick={addPoNumber}>
          {bothMissing || noEstimatePoCode ? 'Add PO number' : 'Go to campaigns'}
        </Button>
      )}
    </>
  );

  return (
    <Modal title="Missing Required Fields" buttons={buttons} {...props} onRequestClose={cancel}>
      <ModalBody>
        <WarningBox>
          {bothMissing || noEstimatePoCode ? (
            <>
              To submit an estimate please add a PO number of:
              <List>
                {noCampaignPoCode && <li>Campaign</li>}
                {noEstimatePoCode && <li>Estimate</li>}
              </List>
            </>
          ) : (
            'Please go to the Campaigns page and add the PO number for the particular Campaign to submit this Estimate'
          )}
        </WarningBox>
        <br />
        Would you like to add PO number?
      </ModalBody>
    </Modal>
  );
};

export default withRouter(PoCodeErrorModal);
