import React from 'react';
import { Grid, Typography, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Footer from 'components/common/footer';
import AppContainer from 'components/appContainerSimple';
import { history } from 'state/store';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(6),
  },
  body: {
    padding: theme.spacing(3),
  },
  backButton: {
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
  },
}));
const StrongPasswordGuidelines = () => {
  const classes = useStyles();

  return (
    <AppContainer className="">
      <div className={classes.backButton}>
        <Button color="primary" variant="contained" onClick={() => history.goBack()}>
          Back
        </Button>
      </div>
      <Grid container justify="center" alignItems="center" className={classes.container}>
        <Grid xs={12} spacing={3}>
          <Typography variant="h2">Password setting guidelines</Typography>
          <Paper square className={classes.body}>
            <Typography variant="h5">1. We recommend that you use a password manager</Typography>
            <p>
              It is important that you don’t use the same password for different websites or
              services. A password manager is a bit of software that can help by securely creating
              and storing unique passwords for each site or service on your behalf. It can even
              enter passwords for you and sync them between your devices. There are lots of options
              out there, but whichever you choose, be sure to set a strong ‘master’ password and
              enable two factor authentication.
            </p>
            <Typography variant="h5">
              2.Use three random words to create a strong password
            </Typography>
            <p>
              A good way to create a strong and memorable password is to use three random words.
              Numbers and symbols can still be used if needed, for example, "3redhousemonkeys27!".
              Be creative and use words memorable to you, so that people can’t guess your password.
              Your social media accounts can give away vital clues about yourself so don’t use words
              such as your child’s name or favourite sports team, which are easy for people to
              guess. Cyber criminals are very smart and know many of the simple substitutions we use
              such as ‘Pa55word!” which utilises symbols to replace letters.
            </p>
            <Typography variant="h5">
              3. Never use the following personal details for your password:
            </Typography>
            <p>
              <ul>
                <li>Current partner’s name</li>
                <li> Child’s name</li>
                <li>Other family members’ name</li>
                <li>Pet’s name</li>
                <li>Place of birth</li>
                <li>Favorite holiday</li>
                <li>Something related to your favorite sports team</li>
              </ul>
            </p>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </AppContainer>
  );
};

export default StrongPasswordGuidelines;
