import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { map, get, omit } from 'lodash/fp';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Box } from '@material-ui/core';
import { Events, track, getCampaignData } from 'components/analytics';
import { isFiltered } from 'components/filtersSidebar';
import { getDisplayCurrency } from 'state/authentication/selectors';
import { getCampaigns, getCampaignsPagination } from 'state/campaigns/selectors';
import { fetchCampaigns, resetCampaigns, defaultLimit } from 'state/campaigns/actions';
import { fetchEstimates, resetEstimates } from 'state/estimates/actions';
import { getEstimates, getEstimatesById } from 'state/estimates/selectors';
import { LOADING } from 'state/resourceStatus/reducer';
import { openSidePanel } from 'state/ui/actions';
import { canCreateEstimate } from 'state/workspaces/selectors';
import CampaignListItem, {
  getItemSize,
  estimatedItemSize,
  CampaignLoading,
} from './campaignListItem';
import CampaignModals from './modals';

const limit = defaultLimit;

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    padding: 0,
    margin: '0 auto',
    height: `calc(100vh - ${38 + 16 + 16 + 64}px)`,
  },
  emptyListContainer: {
    padding: theme.spacing(4),
  },
  message: {
    padding: theme.spacing(2),
  },

  createEstimate: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  createEstimateText: {
    display: 'flex',
    fontSize: 16,
  },

  cardWrapper: {
    paddingRight: theme.spacing(2),
  },
}));

const isItemLoaded = (data, pagination) => index => index < data.length;

const EmptyFilteredList = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.message} data-test="noEstimatesText">
        No campaign for the selected filters.
      </div>
    </div>
  );
};

const EmptyList = ({ canCreateEstimate }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.conatiner, classes.emptyListContainer)}>
      {canCreateEstimate ? (
        <div className={classes.createEstimate} data-test="createYourFirstEstimate">
          <div className={classes.createEstimateText} data-test="createYourFirstEstimateText">
            Create your first campaign
          </div>
        </div>
      ) : (
        <div className={classes.createEstimate}>
          You have to be in a workspace to view and create campaigns
        </div>
      )}
    </div>
  );
};

const Row = ({
  index,
  style,
  data,
  isLoading,
  displayCurrency,
  pagination = { page: 0 },
  setModal,
  estimates,
  areEstimatesLoading,
  key,
  history,
  editCampaign,
  closeCampaign,
  deleteCampaign,
  openCampaignDetails,
}) => {
  const classes = useStyles();
  if (isItemLoaded(data, pagination)(index)) {
    return (
      <div className={clsx('ListItem', classes.cardWrapper)} style={style} key={key}>
        <CampaignListItem
          campaign={data[index]}
          displayCurrency={displayCurrency}
          setModal={setModal}
          estimates={estimates}
          areEstimatesLoading={areEstimatesLoading}
          history={history}
          index={index}
          editCampaign={editCampaign}
          closeCampaign={closeCampaign}
          deleteCampaign={deleteCampaign}
          openCampaignDetails={openCampaignDetails}
        />
      </div>
    );
  }

  return (
    <div className={clsx('ListItem', classes.cardWrapper)} style={style} key={key}>
      <CampaignLoading />
    </div>
  );
};

const listRef = React.createRef();
const outerRef = React.createRef();
const CampaignListComponent = ({
  campaigns,
  pagination = { totalResults: limit, page: 0 },
  isLoading,
  areEstimatesLoading,
  fetchCampaigns,
  history,
  displayCurrency,
  estimates,
  resetCampaigns,
  campaignsPageStatus,
  openSidePanel,
  filters,
}) => {
  const classes = useStyles();
  const [modalName, setModalName] = useState();
  const [modalProps, setModalProps] = useState({});
  const [shouldShowModal, setShowModal] = useState(false);
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    resetCampaigns();
  }, [resetCampaigns]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0, true);
    }
    if (!campaignsPageStatus.campaigns.length) {
      setRequests([]);
    }
  }, [campaignsPageStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(campaignsPageStatus.lastIndex, true);
    }
  }, [estimates]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchCampaigns(1);
  }, [fetchCampaigns, filters]);

  const hideModal = () => {
    setShowModal(false);
    setModalName();
  };

  const setModal = (name, props) => {
    setModalName(name);
    setModalProps(props);
    setShowModal(true);
  };

  const loadMoreItems = (startIndex, endIndex) => {
    const page = pagination.page + 1;
    if (requests[page] || pagination.page >= pagination.totalPages) {
      return Promise.resolve();
    }
    requests[page] = true;
    setRequests(requests);
    return fetchCampaigns(page);
  };

  const editCampaign = origin => campaign => {
    track(Events.CAMPAIGN_EDIT_CLICK, { ...getCampaignData({ campaign }), origin });
    setModal('updateCampaign', { campaignId: campaign.id });
  };

  const closeCampaign = origin => campaign => {
    track(Events.CAMPAIGN_CLOSE_CLICK, { ...getCampaignData({ campaign }), origin });
    setModal('closeCampaign', { campaignId: campaign.id });
  };

  const deleteCampaign = origin => campaign => {
    track(Events.CAMPAIGN_DELETE_CLICK, { ...getCampaignData({ campaign }), origin });
    setModal('deleteCampaign', { campaignId: campaign.id });
  };

  const openCampaignDetails = campaign => {
    track(Events.CAMPAIGN_OPEN_DETAILS_CLICK, {
      ...getCampaignData({ campaign }),
      origin: 'page_campaigns',
    });
    openSidePanel({
      view: 'CAMPAIGN_DETAILS',
      campaign,
      closeCampaign,
      editCampaign,
      deleteCampaign,
    });
  };

  //?if this doesn't work on production remove && pagination.page
  if (!isLoading && isFiltered(filters) && !pagination.totalResults && pagination.page) {
    return <EmptyFilteredList />;
  }

  if (!isLoading && !isFiltered(filters) && !pagination.totalResults && pagination.page) {
    return <EmptyList canCreateEstimate={canCreateEstimate} />;
  }

  if (isLoading && !pagination.page) {
    return (
      <Box display="flex" height="60vh" alignItems="center" justifyContent="center">
        <CircularProgress color="primary" size="5rem" />
      </Box>
    );
  }
  let itemCount = campaigns.length;
  if (pagination.page !== pagination.totalPages) {
    itemCount += limit;
    if (itemCount > pagination.totalResults) {
      itemCount = pagination.totalResults;
    }
  }

  return (
    <div className={classes.container}>
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded(campaigns, pagination)}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            minimumBatchSize={limit}
          >
            {({ onItemsRendered }) => (
              <List
                className="List"
                height={height}
                itemCount={itemCount}
                estimatedItemSize={estimatedItemSize}
                itemSize={getItemSize(
                  campaigns,
                  estimates,
                  areEstimatesLoading,
                  campaignsPageStatus
                )}
                onItemsRendered={onItemsRendered}
                width={width}
                itemData={campaigns}
                ref={listRef}
                outerRef={outerRef}
              >
                {props =>
                  Row({
                    ...props,
                    estimates,
                    isLoading,
                    displayCurrency,
                    areEstimatesLoading,
                    pagination,
                    setModal,
                    history,
                    editCampaign,
                    closeCampaign,
                    deleteCampaign,
                    openCampaignDetails,
                  })
                }
              </List>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
      <CampaignModals
        modalName={modalName}
        shouldShowModal={shouldShowModal}
        hideModal={hideModal}
        modalProps={modalProps}
        history={history}
        fetchCampaigns={fetchCampaigns}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  campaigns: getCampaigns(state),
  pagination: getCampaignsPagination(state),
  estimatesList: getEstimates(state),
  estimates: getEstimatesById(state),
  areEstimatesLoading: state.resourceStatus.estimates === LOADING,
  isLoading: state.resourceStatus.campaigns === LOADING,
  canCreateEstimate: canCreateEstimate(state),
  displayCurrency: getDisplayCurrency(state),
  campaignsPageStatus: get('resourceStatus.campaignsPage', state),
});

const mapDispatchToProps = (dispatch, { filters, pageId }) => ({
  resetCampaigns: () => {
    dispatch(resetCampaigns());
    dispatch(resetEstimates());
  },
  fetchCampaigns: async page => {
    if (page === 1) {
      dispatch(resetEstimates());
    }

    const campaigns = await dispatch(fetchCampaigns({ ...filters, page, limit: defaultLimit }));
    if (get('length', campaigns)) {
      const campaignId = map('id', campaigns);
      const estimateFilters = omit(['search'], filters);
      const isOnCampaignPage = window.location.pathname === '/campaign';
      dispatch(
        fetchEstimates(
          { ...estimateFilters, campaignId: isOnCampaignPage ? campaignId : [] },
          pageId
        )
      );
    }
  },
  openSidePanel: data => dispatch(openSidePanel(data)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(CampaignListComponent);
