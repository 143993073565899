import React from 'react';
import clsx from 'clsx';
import color from 'color';
import { forEach, camelCase, find, toPairs } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { EstimateStatusLabels, EstimateApprovalStatuses } from 'cr-core/constants';
import { statusColor } from './utils';

const getStatusClassName = camelCase;

const useStyles = makeStyles(theme => {
  const circle = {};
  const container = {};

  forEach(([status, rgb]) => {
    circle[`&.${getStatusClassName(status)}`] = { background: rgb };
    container[`&.${getStatusClassName(status)}`] = {
      background: color(rgb).alpha(0.4).string(),
    };
  }, toPairs(statusColor));

  return {
    container: {
      borderRadius: theme.spacing(0.5),
      padding: '2px 6px',
      width: 'auto',
      display: 'inline-block',
      ...container,
    },
    circle: {
      borderRadius: '50%',
      height: theme.spacing(1),
      width: theme.spacing(1),
      marginRight: theme.spacing(0.5),
      display: 'inline-block',
      content: "' '",
      ...circle,
    },
  };
});

export default ({ status, approvals = [] }) => {
  const classes = useStyles();

  if (find({ status: EstimateApprovalStatuses.CHANGES_REQUESTED }, approvals)) {
    status = EstimateApprovalStatuses.CHANGES_REQUESTED;
  }

  return (
    <div
      className={clsx(classes.container, getStatusClassName(status))}
      data-test="estimate-status"
    >
      <span className={clsx(classes.circle, getStatusClassName(status))} />
      <span className={classes.status}>{EstimateStatusLabels[status]}</span>
    </div>
  );
};
