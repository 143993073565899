import ApiService from './ApiService';

interface Supplier {
  accountId: string;
  createdAt: string;
  deletedAt: string | null;
  id: string;
  name: string;
  status: string;
  updatedAt: string;
  userAdded: boolean;
}

const BidService = {
  addLineItemSuppliers: ({
    bidId,
    lineItemNameId,
    supplierIds,
  }: {
    bidId: string;
    lineItemNameId: string;
    supplierIds: string[];
  }) =>
    ApiService.put<Supplier[]>(`/bids/${bidId}/lineItems/${lineItemNameId}/suppliers`, {
      supplierIds,
    }),
};

export default BidService;
