import uuid from 'uuid/v4';
import { forEach, keys } from 'lodash/fp';
import { Events, track } from 'components/analytics';
import { getDisplayCurrency } from 'state/authentication/selectors';
import { fetch } from 'state/fetchMiddleware';
import { getQueryFromFilters } from 'state/estimates/actions';
import { getCampaignsFilterValues } from 'state/campaigns/selectors';
import { showSuccessNotification, showErrorNotification } from 'state/notifications/actions';

export const defaultLimit = 10;

export const CAMPAIGNS_RESET = 'campaigns/CAMPAIGNS_RESET';
export const CAMPAIGNS_FETCH_REQUEST = 'campaigns/FETCH_REQUEST';
export const CAMPAIGNS_FETCH_SUCCESS = 'campaigns/FETCH_SUCCESS';
export const CAMPAIGNS_FILTER_VALUES_FETCH_SUCCESS =
  'campaign/CAMPAIGNS_FILTER_VALUES_FETCH_SUCCESS';

export const CAMPAIGN_FETCH_REQUEST = 'campaign/CAMPAIGN_FETCH_REQUEST';
export const CAMPAIGN_FETCH_SUCCESS = 'campaign/CAMPAIGN_FETCH_SUCCESS';
export const CAMPAIGN_FETCH_ERROR = 'campaign/CAMPAIGN_FETCH_ERROR';
export const CAMPAIGN_CREATE_REQUEST = 'campaign/CREATE_REQUEST';
export const CAMPAIGN_CREATE_SUCCESS = 'campaign/CREATE_SUCCESS';
export const CAMPAIGN_CREATE_ERROR = 'campaign/CREATE_FAILED';
export const CAMPAIGN_UPDATE_REQUEST = 'campaign/UPDATE_REQUEST';
export const CAMPAIGN_UPDATE_SUCCESS = 'campaign/UPDATE_SUCCESS';
export const CAMPAIGN_UPDATE_ERROR = 'campaign/UPDATE_ERROR';
export const CAMPAIGN_DELETE_REQUEST = 'campaign/CAMPAIGN_DELETE_REQUEST';
export const CAMPAIGN_DELETE_SUCCESS = 'campaign/CAMPAIGN_DELETE_SUCCESS';
export const CAMPAIGN_DELETE_ERROR = 'campaign/CAMPAIGN_DELETE_ERROR';
export const CAMPAIGN_CLOSE_REQUEST = 'campaign/CAMPAIGN_CLOSE_REQUEST';
export const CAMPAIGN_CLOSE_SUCCESS = 'campaign/CAMPAIGN_CLOSE_SUCCESS';
export const CAMPAIGN_CLOSE_ERROR = 'campaign/CAMPAIGN_CLOSE_ERROR';
export const CAMPAIGN_OPEN_REQUEST = 'campaign/CAMPAIGN_OPEN_REQUEST';
export const CAMPAIGN_OPEN_SUCCESS = 'campaign/CAMPAIGN_OPEN_SUCCESS';
export const CAMPAIGN_OPEN_ERROR = 'campaign/CAMPAIGN_OPEN_ERROR';
export const CAMPAIGN_HUMAN_ID_CLIENT_IO_REQUEST = 'campaign/CAMPAIGN_HUMAN_ID_CLIENT_IO_REQUEST';
export const CAMPAIGN_HUMAN_ID_CLIENT_IO_SUCCESS = 'campaign/CAMPAIGN_HUMAN_ID_CLIENT_IO_SUCCESS';
export const CAMPAIGN_HUMAN_ID_CLIENT_IO_ERROR = 'campaign/CAMPAIGN_HUMAN_ID_CLIENT_IO_ERROR';
export const CAMPAIGNS_BY_WORKSPACE_FETCH_REQUEST = 'campaign/CAMPAIGNS_BY_WORKSPACE_FETCH_REQUEST';
export const CAMPAIGNS_BY_WORKSPACE_FETCH_SUCCESS = 'campaign/CAMPAIGNS_BY_WORKSPACE_FETCH_SUCCESS';
export const CAMPAIGNS_BY_WORKSPACE_FETCH_ERROR = 'campaign/CAMPAIGNS_BY_WORKSPACE_FETCH_ERROR';

export const resetCampaigns = () => async dispatch => dispatch({ type: CAMPAIGNS_RESET });

export const fetchCampaigns =
  (query = {}, force) =>
  async (dispatch, getState) => {
    const displayCurrency = getDisplayCurrency(getState());
    const filters = getQueryFromFilters(query);
    const requestId = (query.page || force) && uuid();
    dispatch({ type: CAMPAIGNS_FETCH_REQUEST, query, requestId });
    let t = Date.now();
    try {
      const {
        data: { data: campaigns = [], pagination },
      } = await dispatch(
        fetch('/campaigns', {
          params: {
            limit: query.limit,
            ...filters,
            displayCurrency,
            page: query.page,
          },
        })
      );
      track(Events.PERFORMANCE, {
        metric: 'fetch_campaigns',
        t: Date.now() - t,
        items: campaigns.length,
      });
      dispatch({ type: CAMPAIGNS_FETCH_SUCCESS, campaigns, pagination, query, requestId });
      return campaigns;
    } catch (error) {
      console.log(error);
      dispatch(
        showErrorNotification({ message: 'An error occurred while fetching the campaigns list' })
      );
    }
  };

export const fetchCampaignsFilterValues = force => async (dispatch, getState) => {
  if (!force && getCampaignsFilterValues(getState()).length) {
    return;
  }
  const {
    data: { data: campaigns = [] },
  } = await dispatch(fetch('/campaigns', { params: { filterValues: true } }));
  dispatch({ type: CAMPAIGNS_FILTER_VALUES_FETCH_SUCCESS, campaigns });
};

export const fetchCampaignsByWorkspace = workspaceId => async dispatch => {
  dispatch({ type: CAMPAIGNS_BY_WORKSPACE_FETCH_REQUEST });
  try {
    const { data: campaignsByWorkspace } = await dispatch(
      fetch(`/campaignsByWorkspace/${workspaceId}`)
    );
    dispatch({ type: CAMPAIGNS_BY_WORKSPACE_FETCH_SUCCESS, campaignsByWorkspace });
    return campaignsByWorkspace;
  } catch (error) {
    dispatch({ type: CAMPAIGNS_BY_WORKSPACE_FETCH_ERROR, error });
  }
};

export const fetchCampaign = campaignId => async (dispatch, getState) => {
  const displayCurrency = getDisplayCurrency(getState());
  dispatch({ type: CAMPAIGN_FETCH_REQUEST });
  try {
    const { data: campaign } = await dispatch(
      fetch(`/campaigns/${campaignId}`, {
        params: {
          displayCurrency,
        },
      })
    );
    dispatch({ type: CAMPAIGN_FETCH_SUCCESS, campaign });
    return campaign;
  } catch (error) {
    dispatch({ type: CAMPAIGN_FETCH_ERROR, error });
  }
};

export const createCampaign = (data, query) => async dispatch => {
  dispatch({ type: CAMPAIGN_CREATE_REQUEST });
  try {
    const { data: campaign } = await dispatch(
      fetch('/campaigns', {
        method: 'POST',
        data,
      })
    );
    dispatch({ type: CAMPAIGN_CREATE_SUCCESS, id: campaign.id, campaign });
    dispatch(fetchCampaignsFilterValues(true));
    dispatch(fetchCampaigns({ ...query, page: 1 }));
    dispatch(showSuccessNotification({ message: `Campaign "${campaign.name}" updated` }));
    return campaign;
  } catch (e) {
    dispatch(
      showErrorNotification({ message: 'An error occurred while creating the new campaign' })
    );
    dispatch({ type: CAMPAIGN_CREATE_ERROR });
  }
};

export const updateCampaign = (id, data, query) => async dispatch => {
  dispatch({ type: CAMPAIGN_UPDATE_REQUEST });

  try {
    forEach(key => {
      //FIX FOR REMOVING CLIENT  IO AND AGENCY JOB
      if (!data['clientIoNumber']) delete data['clientIoNumber'];
      if (!data['agencyJobNumber']) delete data['agencyJobNumber'];

      if (data[key] === null) {
        delete data[key];
      }
    }, keys(data));

    const { data: campaign } = await dispatch(fetch(`/campaigns/${id}`, { method: 'PATCH', data }));

    dispatch({ type: CAMPAIGN_UPDATE_SUCCESS, id: campaign.id, campaign });
    dispatch(fetchCampaignsFilterValues(true));
    dispatch(fetchCampaigns({ ...query, page: 1 }));
    dispatch(showSuccessNotification({ message: `Campaign "${campaign.name}" updated` }));
    return campaign;
  } catch (error) {
    dispatch({ type: CAMPAIGN_UPDATE_ERROR, error });
    dispatch(
      showErrorNotification({
        message:
          error.toString() !== 'Server Error'
            ? error.toString()
            : 'An error occurred while editing the campaign. Please try again.',
      })
    );
  }
};

export const deleteCampaign = (id, query) => async dispatch => {
  dispatch({ type: CAMPAIGN_DELETE_REQUEST });
  try {
    await dispatch(fetch(`/campaigns/${id}`, { method: 'DELETE' }));
    dispatch({ type: CAMPAIGN_DELETE_SUCCESS, id });
    dispatch(showSuccessNotification({ message: 'Campaign deleted' }));
    dispatch(fetchCampaignsFilterValues(true));
    dispatch(fetchCampaigns({ ...query, page: 1 }));
  } catch (e) {
    dispatch(showErrorNotification({ message: 'An error occurred while deleting the campaign' }));
    dispatch({ type: CAMPAIGN_DELETE_ERROR });
  }
};

export const closeCampaign = (id, query) => async dispatch => {
  dispatch({ type: CAMPAIGN_CLOSE_REQUEST });
  try {
    await dispatch(fetch(`/campaigns/${id}/close`, { method: 'POST' }));
    dispatch({ type: CAMPAIGN_CLOSE_SUCCESS, id });
    dispatch(showSuccessNotification({ message: 'Campaign closed' }));
    dispatch(fetchCampaigns({ ...query, page: 1 }));
  } catch (e) {
    dispatch(showErrorNotification({ message: 'An error occurred while closing the campaign' }));
    dispatch({ type: CAMPAIGN_CLOSE_ERROR });
  }
};

export const openCampaign = id => async dispatch => {
  dispatch({ type: CAMPAIGN_OPEN_REQUEST });
  try {
    await dispatch(fetch(`/campaigns/${id}/open`, { method: 'POST' }));
    dispatch({ type: CAMPAIGN_OPEN_SUCCESS, id });
    dispatch(fetchCampaigns({ page: 1 }));
  } catch (e) {
    dispatch(showErrorNotification({ message: 'An error occurred while re-opening the campaign' }));
    dispatch({ type: CAMPAIGN_OPEN_ERROR });
  }
};

export const fetchCampaignHumanIdAndClientIo = () => async dispatch => {
  dispatch({ type: CAMPAIGN_HUMAN_ID_CLIENT_IO_REQUEST });
  try {
    const { data: campaignHumanIdsAndClientIo } = await dispatch(
      fetch('/campaigns/humanIdsAndclientIoNumbers')
    );
    dispatch({ type: CAMPAIGN_HUMAN_ID_CLIENT_IO_SUCCESS, campaignHumanIdsAndClientIo });
  } catch (e) {
    dispatch(showErrorNotification({ message: 'An error occurred while re-opening the campaign' }));
    dispatch({ type: CAMPAIGN_HUMAN_ID_CLIENT_IO_ERROR });
  }
};
