import {
  get,
  differenceBy,
  flow,
  values,
  orderBy,
  toLower,
  filter,
  map,
  sortBy,
  minBy,
} from 'lodash/fp';
import Fuse from 'fuse.js';
import { createSelector } from 'reselect';
import { AccountSetting } from 'cr-core/constants';
import {
  getAllLineItems as getEstimateLineItems,
  getLineItemGroups,
  getLineItemsForCostCategory as getLineItemsForCostCategoryOnEstimate,
} from 'state/bids/selectors';
import { getClientSettings } from 'state/authentication/selectors';

const orderByName = orderBy([flow(get('name'), toLower)], ['asc']);

export const getCostCategories = get('costCategories');

export const getCostCategoryById = id => get(`costCategories.${id}`);

export const getLineItemsForCostCategory = costCategoryId =>
  createSelector(
    flow(getCostCategoryById(costCategoryId), get('lineItemNames')),
    getCostCategoryById(costCategoryId),
    (lineItems, costCategory) => costCategory && !costCategory.deprecatedAt && lineItems
  );

export const getCostCategoriesAvailableForEstimate = () => state => {
  const lineItemGroups = getLineItemGroups(state);
  return flow(
    getCostCategories,
    values,
    filter(el => !el.deprecatedAt),
    orderByName,
    costCategories => differenceBy('id', costCategories, lineItemGroups)
  )(state);
};

export const getPickableLineItemsForCostCategoryOnEstimate = (
  costCategoryId,
  estimateIsNew = false
) =>
  createSelector(
    getCostCategoryById(costCategoryId),
    getLineItemsForCostCategory(costCategoryId),
    getLineItemsForCostCategoryOnEstimate(costCategoryId),
    getClientSettings,
    (costCategory, lineItems, estimateCostCategoryLineItems, clientSettings) => {
      const suppliersEnabled = clientSettings[AccountSetting.Suppliers];
      const newLineItems = [...new Set((lineItems || []).map(({ replacedBy }) => replacedBy))];

      return flow(
        differenceBy(
          'id',
          suppliersEnabled && estimateIsNew
            ? (lineItems || []).filter(({ replacedBy }) => !replacedBy)
            : (lineItems || []).filter(({ id }) => !newLineItems.includes(id))
        ),
        filter(({ deprecatedAt }) => !deprecatedAt),
        map(item => ({ ...item, costCategoryName: costCategory.name }))
      )(estimateCostCategoryLineItems);
    }
  );

const options = {
  shouldSort: true,
  findAllMatches: true,
  includeScore: true,
  threshold: 0.4,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['name'],
};

const search = searchStr => list => {
  const fuse = new Fuse(list, options);
  return map(({ item, score }) => ({ ...item, score }), fuse.search(searchStr));
};

export const searchForLineItem =
  (costCategoryId, state, estimateIsNew = false) =>
  searchStr => {
    const estimateLineItems = getEstimateLineItems()(state);
    const clientSettings = getClientSettings(state);
    const filterLineItems = clientSettings[AccountSetting.Suppliers] && estimateIsNew;

    const allLineItems = flow(
      getCostCategories,
      filter(({ deprecatedAt }) => !deprecatedAt),
      map(category => {
        const costCategoryLineItems = filter(
          ({ deprecatedAt }) => !deprecatedAt,
          filterLineItems
            ? (category.lineItemNames || []).filter(({ replacedBy }) => !replacedBy)
            : category.lineItemNames
        );

        if (costCategoryId === category.id) {
          return {
            ...category,
            lineItemNames: flow(
              differenceBy('id', costCategoryLineItems),
              search(searchStr),
              sortBy('score')
            )(estimateLineItems),
          };
        } else {
          return {
            ...category,
            lineItemNames: flow(search(searchStr), sortBy('score'))(costCategoryLineItems),
          };
        }
      }),
      filter(({ lineItemNames }) => lineItemNames.length),
      map(category => ({
        id: category.id,
        label: category.name,
        options: map(
          ({ id, name }) => ({
            value: id,
            label: name,
            costCategoryId: category.id,
            costCategoryName: category.name,
          }),
          category.lineItemNames
        ),
        topScore: minBy('score', category.lineItemNames),
      })),
      sortBy([({ id }) => (id === costCategoryId ? -1 : 1), 'topScore', 'name'])
    )(state);

    return Promise.resolve(allLineItems);
  };
