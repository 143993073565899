import React from 'react';
import clsx from 'clsx';
import { noop } from 'lodash/fp';
import { connect } from 'react-redux';
import { Drawer, CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Notications from 'components/common/notifications';
import Header from 'components/header';
import { closeSidePanel } from 'state/ui/actions';
import { isSidePanelOpen } from 'state/ui/selectors';

const DRAWER_WIDTH = 400;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: 0,
    // padding: theme.spacing(0, 3, 3, 0),
    // paddingTop: theme.spacing(2),
    width: '100%',
    height: `calc(100vh - ${
      theme.mixins.toolbar[`@media (min-width:${theme.breakpoints.values.sm}px)`].minHeight
    }px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -DRAWER_WIDTH,
    position: 'relative',
  },
  contentShift: {
    // transition: theme.transitions.create('margin', {
    //   easing: theme.transitions.easing.easeOut,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
    // marginRight: 0,
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  overlay: {
    background: '#000000',
    opacity: 0.32,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'fixed',
    zIndex: theme.zIndex.drawer,
  },
  bodyContainer: {
    display: 'flex',
  },
}));

const AppContainer = ({
  className,
  children,
  sidePanelOpen,
  sidePanelContent,
  closeSidePanel,
  pageId,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header />
      {sidePanelOpen && (
        <div
          role="button"
          className={classes.overlay}
          onClick={closeSidePanel}
          tabIndex={-1}
          onKeyPress={noop}
        />
      )}
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: sidePanelOpen,
        })}
      >
        <div className={classes.toolbar} />
        <div className={classes.bodyContainer}>{children}</div>
      </main>
      <Drawer
        anchor="right"
        className={classes.drawer}
        variant="persistent"
        open={sidePanelOpen}
        classes={{ paper: classes.drawerPaper }}
        elevation={7}
      >
        <div className={classes.toolbar} />
        {sidePanelContent}
      </Drawer>
      <Notications />
    </div>
  );
};

const mapStateToProps = state => ({
  sidePanelOpen: isSidePanelOpen(state),
});

const mapDispatchToProps = (dispatch, { onCloseSidePanel = noop }) => ({
  closeSidePanel: () => {
    dispatch(closeSidePanel());
    onCloseSidePanel();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
