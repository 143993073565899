import React from 'react';

const SvgCopyIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M17.45 3.667H8.49c-1.657 0-2.998 1.4-2.998 3.11L5.48 24h-.015C4.102 24 3 22.85 3 21.444l.012-17.888C3.012 2.15 4.114 1 5.476 1h9.374l2.6 2.667z" />
      <path d="M19.493 6l6.721 6.894v13.55C26.214 27.85 25.1 29 23.738 29H10.107c-1.362 0-2.464-1.15-2.464-2.556l.012-17.888C7.655 7.15 8.757 6 10.12 6h9.374zm-.707 7.85h5.748l-5.748-5.933v5.932z" />
    </g>
  </svg>
);

export default SvgCopyIcon;
