import React from 'react';
import styled from 'styled-components';
import { compact, get, find } from 'lodash/fp';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Typography, Grid, IconButton } from '@material-ui/core';
import { UserStatuses } from 'cr-core/constants';
import { getUserName } from 'cr-core/userUtils';
import { getSidePanelData } from 'state/ui/selectors';
import { getAccountById } from 'state/accounts/selectors';
import {
  getClient,
  getClientSettings,
  getUser as getLoggedUser,
} from 'state/authentication/selectors';
import {
  updateUser,
  blockUser,
  deleteUser,
  inviteUsers,
  setModerator,
  removeModerator,
} from 'state/users/actions';
import { getUserById } from 'state/users/selectors';
import Label from 'components/common/label';
import Menu from 'components/common/menu';
import { closeSidePanel } from 'state/ui/actions';
import { getFormValues } from 'redux-form';
import Teams from 'components/teams/list';
import CloseIcon from '@material-ui/icons/Close';
import { AccountSetting } from 'cr-core/constants';

const useStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(4, 3),
    position: 'relative',
  },
  buttonsContainer: {
    position: 'absolute',
    right: 2,
    top: 10,
    display: 'flex',
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  noAvatar: {
    borderRadius: '50%',
    backgroundColor: theme.palette.grey[400],
  },
  accountName: {
    textTrasform: 'uppercase',
  },
  email: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    display: 'block',
  },

  listContainer: {
    margin: theme.spacing(2, 0),
  },
  listTitle: {
    marginBottom: theme.spacing(2),
  },
  emptyListMessage: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2, 1),
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[300],
  },
  listItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: -1,
    border: '1px solid silver',
    ...theme.typography.body1,
    display: 'block',
    fontSize: '1rem',
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.text.hint,
    },
    transition: theme.transitions.create(),
  },
}));

const Square = styled.div`
  width: 100%;
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const UserDetails = ({
  loggedUser,
  setUserAsAdmin,
  setUserAsConsultant,
  blockUser,
  deleteUser,
  sendVerificationEmail,
  selectedAccount,
  user,
  setModerator,
  removeModerator,
  closeSidePanel,
  clientId,
  clientSettings,
}) => {
  const { flags = {} } = loggedUser;
  const canManageModerators = flags.mcaOnly && clientSettings[AccountSetting.ManageModerators];
  const classes = useStyles();
  const isBlocked = user.status === UserStatuses.BLOCKED;
  const isCreated = user.status === UserStatuses.CREATED;
  const isModerator = user.roles.includes('MODERATOR');
  const labels = [
    user.isAdmin && <Label color="primary" text="admin" key="admin" />,
    isBlocked && <Label color="error" text="blocked" key="blocked" />,
    isCreated && <Label color="warning" text="invited" key="invited" />,
    user.isConsultant && <Label color="primary" text="consultant" key="consultant" />,
  ];
  let account;
  if (user.accounts.length === 1) {
    account = user.accounts[0];
  } else {
    account = { name: 'Mulitple accounts' };
  }

  const canSetUserAsConsultant =
    get('consultancy', selectedAccount) && find({ id: selectedAccount.id }, user.accounts);

  const menuItems = compact([
    user.status === UserStatuses.CREATED && {
      label: 'Send Verification Email',
      onClick: () => sendVerificationEmail(user.email, selectedAccount.id),
    },
    {
      label: user.isAdmin ? 'Unset as Admin' : 'Set as Admin',
      onClick: () => setUserAsAdmin(user.id, !user.isAdmin),
    },
    canManageModerators && {
      label: isModerator ? 'Unset as Moderator' : 'Set as Moderator',
      onClick: () => {
        if (!clientId) {
          return;
        }

        isModerator ? removeModerator(clientId, user.id) : setModerator(clientId, user.id);
      },
    },
    canSetUserAsConsultant && {
      label: user.isConsultant ? 'Unset as Consultant' : 'Set as Consultant',
      onClick: () => setUserAsConsultant(user.id, !user.isConsultant),
    },
    {
      label: isBlocked ? 'Unblock User' : 'Block User',
      onClick: () => blockUser(user.id, !isBlocked),
    },
    { label: 'Delete User', onClick: () => deleteUser(user) },
  ]);

  return (
    <>
      <div className={classes.body}>
        <Grid container spacing={2}>
          <div className={classes.buttonsContainer}>
            <IconButton aria-label="close" onClick={() => closeSidePanel()}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
            {loggedUser.isAdmin && <Menu items={menuItems} />}
          </div>

          <Grid item xs={3}>
            <Square>
              <Avatar alt={user.firstName} src={user.picture} className={classes.avatar} />
            </Square>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="subtitle1" className={classes.accountName}>
              {account.name}
            </Typography>
            <Typography variant="h5">{getUserName(user)}</Typography>
            <Typography variant="body1" className={classes.email}>
              {user.email}
            </Typography>
            <div>{labels}</div>
          </Grid>
        </Grid>

        <Teams selectedTeams={user.teams} showAccount={false} />
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const filters = getFormValues('userFilters')(state) || {};
  const accountId = get('accountId', filters);
  const clientId = getClient(state).id;

  return {
    loggedUser: getLoggedUser(state),
    selectedAccount: getAccountById(accountId)(state),
    user: getUserById(get('user.id', getSidePanelData(state)))(state),
    clientSettings: getClientSettings(state),
    clientId,
  };
};

const mapDispatchToProps = dispatch => ({
  setUserAsAdmin: (userId, isAdmin) => dispatch(updateUser(userId, { isAdmin })),
  setUserAsConsultant: (userId, isConsultant) => dispatch(updateUser(userId, { isConsultant })),
  blockUser: (userId, block) => dispatch(blockUser(userId, block)),
  sendVerificationEmail: (email, accountId) => dispatch(inviteUsers([email], accountId)),
  deleteUser: async user => {
    dispatch(closeSidePanel());
    await dispatch(deleteUser(user.id));
  },
  closeSidePanel: () => dispatch(closeSidePanel()),
  setModerator: (accountId, userId) => dispatch(setModerator(accountId, userId)),
  removeModerator: (accountId, userId) => dispatch(removeModerator(accountId, userId)),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(UserDetails);
