import { useQuery } from 'react-query';
import SupplierService from 'services/SupplierService';

const useSupplierColumns = (accountId: string) => {
  return useQuery(
    ['account', accountId, 'suppliers', 'columns'],
    () => SupplierService.getSupplierColumns(accountId).then(({ data }) => data),
    {
      // should never need to be refeched unless invalidated
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export default useSupplierColumns;
