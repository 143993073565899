import React from 'react';
import clsx from 'clsx';
import { map, flow, fromPairs } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const supportedColors = ['primary', 'secondary', 'error', 'warning'];

const useStyles = makeStyles(theme => ({
  label: {
    color: theme.palette.common.white,
    padding: '4px 8px',
    borderRadius: 2,
    marginRight: theme.spacing(1) / 2,
  },
  ...flow(
    map(color => [color, { backgroundColor: theme.palette[color].main }]),
    fromPairs
  )(supportedColors),
}));

export default ({ color, text }) => {
  const classes = useStyles();
  return (
    <Typography variant="overline" className={clsx(classes.label, classes[color])}>
      {text}
    </Typography>
  );
};
