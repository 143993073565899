import React, { useState } from 'react';
import qs from 'qs';
import { get } from 'lodash/fp';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GetAppIcon from '@material-ui/icons/GetApp';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupIcon from '@material-ui/icons/Group';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { getUserName } from 'cr-core/userUtils';
import { Events, track } from 'components/analytics';
import { colors } from 'shared/styles';
import { logout } from 'state/authentication/actions';
import { getUser } from 'state/authentication/selectors';
import CurrencySelector from './currencySelector';
import ExportLineItemsModal from './modals/exportLineItemsModal';
import ExportEstimatesModal from './modals/exportEstimatesModal';

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  divider: {
    borderBottom: `1px solid ${colors.containerBorder}`,
    padding: 0,
  },
  menuItem: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  menuItemIcon: {
    marginRight: theme.spacing(1),
  },
  profileMenu: {
    color: 'white',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  selectCurrencyMenuItem: {
    borderTop: `1px solid ${colors.containerBorder}`,
    borderBottom: `1px solid ${colors.containerBorder}`,
  },
}));

const ProfileMenu = ({ user, logout, location, onClick }) => {
  const classes = useStyles();
  const { flags = {} } = user;
  const openIntercom = () => window.Intercom && window.Intercom('show');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const selectedAccountId = get('accountId', queryParams);
  const userManagementQueryStr = selectedAccountId ? `?accountId=${selectedAccountId}` : '';
  const [lineItemsModalOpen, setExportLineItemsModalOpen] = useState(false);
  const [estimatesModalOpen, setExportEstimatesModalOpen] = useState(false);
  const handleExportLineItems = e => {
    onClick && onClick(e);
    setExportLineItemsModalOpen(true);
  };
  const handleExportEstimates = e => {
    onClick && onClick(e);
    setExportEstimatesModalOpen(true);
  };

  return (
    <>
      <Button
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.profileMenu}
      >
        <Avatar
          alt={user.firstName}
          src={user.picture}
          className={classes.avatar}
          data-test="headerAvatar"
        />
        <ExpandMoreIcon />
      </Button>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        getContentAnchorEl={null}
      >
        <MenuItem>
          <Avatar alt={user.firstName} src={user.picture} className={classes.avatar} />{' '}
          <span data-test="profileName">{getUserName(user) || user.email}</span>
        </MenuItem>
        <MenuItem className={classes.selectCurrencyMenuItem}>
          <CurrencySelector />
        </MenuItem>
        {window.Intercom && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              track(Events.CONTACT_US_CLICK);
              openIntercom();
            }}
          >
            <ChatBubbleIcon className={classes.menuItemIcon} />
            Contact Us
          </MenuItem>
        )}

        {(user.isAdmin || user.roles.map(({ name }) => name).includes('MODERATOR')) && (
          <MenuItem
            className={classes.menuItem}
            component={Link}
            to="/account-settings"
            data-test="accountSettingsLink"
            onClick={() => track(Events.SETTINGS_CLICK)}
          >
            <SettingsIcon className={classes.menuItemIcon} />
            Account Settings
          </MenuItem>
        )}

        <MenuItem className={classes.divider} />
        {user.isAdmin && (
          <MenuItem
            className={classes.menuItem}
            component={Link}
            to={`/users${userManagementQueryStr}`}
            data-test="usersLink"
            onClick={() => track(Events.USERS_CLICK)}
          >
            <GroupIcon className={classes.menuItemIcon} />
            Users
          </MenuItem>
        )}
        {user.isAdmin && (
          <MenuItem
            className={classes.menuItem}
            component={Link}
            to={`/teams${userManagementQueryStr}`}
            data-test="teamsLink"
            onClick={() => track(Events.TEAMS_CLICK)}
          >
            <GroupIcon className={classes.menuItemIcon} />
            Teams
          </MenuItem>
        )}
        {user.isAdmin && (
          <MenuItem
            className={classes.menuItem}
            component={Link}
            to={`/workspaces${userManagementQueryStr}`}
            data-test="workspacesLink"
            onClick={() => track(Events.WORKSPACES_CLICK)}
          >
            <GroupIcon className={classes.menuItemIcon} />
            Workspaces
          </MenuItem>
        )}
        <MenuItem className={classes.divider} />

        <MenuItem
          className={classes.menuItem}
          data-test="exportEstimates"
          onClick={handleExportEstimates}
        >
          <GetAppIcon className={classes.menuItemIcon} />
          Export Estimates
        </MenuItem>
        {flags.lineItemsExport && (
          <MenuItem
            className={classes.menuItem}
            data-test="exportEstimates"
            onClick={handleExportLineItems}
          >
            <GetAppIcon className={classes.menuItemIcon} />
            Export Line Items
          </MenuItem>
        )}

        {user.isAdmin && flags.mcaOnly && (
          <MenuItem className={classes.menuItem} component={Link} to={'/admin'} data-test="admin">
            <SettingsIcon className={classes.menuItemIcon} />
            Admin
          </MenuItem>
        )}

        <MenuItem className={classes.divider} />
        <MenuItem className={classes.menuItem} onClick={() => logout()} data-test="logout">
          <ExitToAppIcon className={classes.menuItemIcon} />
          Logout
        </MenuItem>
      </Menu>
      <ExportLineItemsModal
        open={lineItemsModalOpen}
        handleClose={() => setExportLineItemsModalOpen(false)}
      />
      <ExportEstimatesModal
        open={estimatesModalOpen}
        handleClose={() => setExportEstimatesModalOpen(false)}
      />
    </>
  );
};

const mapStateToProps = state => ({
  user: getUser(state),
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileMenu));
