import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { colors, font } from 'shared/styles';
import { getEstimateWatchers } from 'state/estimateWatchers/selectors';
import UserAvatar from 'components/userAvatar';
import RemoveWatcherButton from './removeWatcher';
import { ListItem, Body, Actions } from 'components/listItemWithActions';
import WhatWhenBy from 'components/whatWhenBy';
import { get, flow, isEmpty, compose } from 'lodash/fp';
import { renderComponent, branch } from 'recompose';
import { Box, CircularProgress, Link, makeStyles, Typography } from '@material-ui/core';
import { ERROR, LOADING } from 'state/resourceStatus/reducer';
import { retryCreateWatcher } from 'state/estimateWatchers/actions';

const EstimateWatchersListContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-shrink: 0;
`;

const ItemHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const WatcherAvatar = styled(UserAvatar)`
  display: flex;
  .userName {
    color: ${colors.text};
    font-weight: ${font.weights.bold};
    text-shadow: none;
  }
`;

const useStyles = makeStyles({
  tryAgain: {
    display: 'inline',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  body: {
    width: 'auto',
  },
});

const EmptyState = ({ className }) => (
  <EstimateWatchersListContainer className={className}>
    <ListItem data-test="approvals-empty-message">
      This estimate doesn't have any watchers assigned.
    </ListItem>
  </EstimateWatchersListContainer>
);

const Bottom = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
`;

const StyledWhatWhenBy = styled(WhatWhenBy)`
  margin-top: 0px;
`;

const WatcherItemRenderer = ({ watcher, estimateId, pageId, retry }) => {
  const { id, createdAt, creator, user, nonce, status } = watcher;
  const isEmphemeral = Boolean(nonce);
  const styles = useStyles();

  return (
    <ListItem key={id} data-test="watcher-block">
      <Body className={styles.body}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <ItemHeader>
              <WatcherAvatar user={user} />
            </ItemHeader>
            <Bottom>
              <StyledWhatWhenBy what="Added" when={createdAt} by={creator} />
            </Bottom>
          </Box>
          {status === LOADING && <CircularProgress color="primary" />}
          {status === ERROR && (
            <Box ml="10px" alignSelf="center">
              <Typography>Failed to add watcher.</Typography>
              <Link href="#" className={styles.tryAgain} color="error" onClick={() => retry(id)}>
                Try again
              </Link>
            </Box>
          )}
        </Box>
      </Body>
      <Actions>
        {status !== LOADING && (
          <RemoveWatcherButton
            estimateId={estimateId}
            watcherId={id}
            pageId={pageId}
            isEmphemeral={isEmphemeral}
          />
        )}
      </Actions>
    </ListItem>
  );
};

const ApprovalsList = ({
  estimateWatchers,
  estimateId,
  className,
  ItemRenderer = WatcherItemRenderer,
  pageId,
  retryAddWatcher,
}) => {
  console.log(estimateWatchers);
  return (
    <EstimateWatchersListContainer className={className}>
      {estimateWatchers.map(watcher => (
        <ItemRenderer
          watcher={watcher}
          key={watcher.id}
          estimateId={estimateId}
          retry={retryAddWatcher}
          pageId={pageId}
        />
      ))}
    </EstimateWatchersListContainer>
  );
};

const mapStateToProps = (state, { estimateId }) => {
  return {
    estimateWatchers: getEstimateWatchers(state),
  };
};

const mapDispatchToProps = (dispatch, { estimateId }) => ({
  retryAddWatcher: watcherId => {
    dispatch(retryCreateWatcher(estimateId, watcherId));
  },
});

const isWatchersEmpty = flow(get('estimateWatchers'), isEmpty);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(isWatchersEmpty, renderComponent(EmptyState)),
)(ApprovalsList);
