import { Box } from '@material-ui/core';
import { Formik } from 'formik';
import { noop } from 'lodash/fp';
import React from 'react';
import { ReportHeader } from '../components';
import DeliverablesFilters, { EMPTY_FILTERS } from './DeliverablesFilters';

const DeliverablesReport = () => {
  return (
    <Box p={2}>
      <Box mb={3}>
        <ReportHeader title="Additional Deliverables Report" />
      </Box>
      <Formik initialValues={EMPTY_FILTERS} onSubmit={noop}>
        <DeliverablesFilters />
      </Formik>
    </Box>
  );
};

export default DeliverablesReport;
