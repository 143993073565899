import React from 'react';
import { NavLink } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { font } from 'shared/styles';

const useStyles = makeStyles(theme => ({
  title: {
    width: 75,
    margin: 'auto 0',
    marginRight: theme.spacing(5),
    textAlign: 'right',
    color: 'white',
    lineHeight: '22px',
    letterSpacing: -1.3,
    fontWeight: font.weights.bold,
    fontSize: font.sizes.veryLarge,
    fontFamily: 'Montserrat,sans-serif',
    textDecoration: 'none',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    color: 'white',
  },
  leftContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  divider: {
    width: 2,
    height: 40,
    backgroundColor: 'white',
    alignSelf: 'flex-start',
    flexShrink: 0,
  },
}));

const Header = ({
  user,
  isLoading,
  logout,
  changeAccount,
  selectedAccount,
  selectedCurrency,
  changeCurrency,
}) => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <div className={classes.leftContainer}>
          <NavLink to="/" className={classes.title}>
            Control Room
          </NavLink>
          <div className={classes.divider} />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
