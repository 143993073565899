import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { last } from 'lodash/fp';
import styled from 'styled-components';
import { Events, track, getEstimateData } from 'components/analytics';
import { RoundButton } from 'components/buttons';
import { ListItem, Body, Actions } from 'components/listItemWithActions';
import WhatWhenBy from 'components/whatWhenBy';
import SvgAttachmentsIcon from 'icons/AttachmentsIcon';
import SvgDownloadIcon from 'icons/DownloadIcon';
import SvgTrashIcon from 'icons/TrashIcon';
import { removeFileFromEstimate } from 'state/estimates/actions';
import { getEstimateById } from 'state/estimates/selectors';
import { getDownloadUrl } from 'state/files/selectors';
import { getUser } from 'state/authentication/selectors';

const FileName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const File = ({ uploader, filename, createdAt, downloadUrl, handleRemoveFile, estimate }) => (
  <ListItem>
    <Body>
      <FileName>
        <SvgAttachmentsIcon className="icon" />
        {filename}
      </FileName>
      <WhatWhenBy what="Uploaded" when={createdAt} by={uploader} />
    </Body>
    <Actions>
      <a
        href={downloadUrl}
        download
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          const fileExtension = last(filename.split('.'));
          track(Events.ESTIMATE_DOWNLOAD_ATTACHMENT, {
            ...getEstimateData(estimate),
            fileExtension,
          });
        }}
      >
        <RoundButton data-test="download">
          <SvgDownloadIcon className="icon" />
        </RoundButton>
      </a>
      <RoundButton data-test="remove-file" onClick={handleRemoveFile}>
        <SvgTrashIcon className="icon" />
      </RoundButton>
    </Actions>
  </ListItem>
);

const mapStateToProps = (state, { id, estimateId }) => ({
  estimate: getEstimateById(estimateId)(state),
  downloadUrl: getDownloadUrl(id, getUser(state).client.id),
});

const mapDispatchToProps = (dispatch, { id, estimateId }) => ({
  removeFile: () => dispatch(removeFileFromEstimate(id, estimateId)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handleRemoveFile: ({ removeFile, estimate, filename }) => () => {
      removeFile();
      const fileExtension = last(filename.split('.'));
      track(Events.ESTIMATE_DELETE_ATTACHMENT, { ...getEstimateData(estimate), fileExtension });
    },
  })
)(File);
