import React, { useEffect, useState } from 'react';
import uuid from 'uuid/v4';
import { get, omit } from 'lodash/fp';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { track, getFiltersChangedData, Events } from 'components/analytics';
import { MainContent } from 'components/page';
import { fetchEstimates, resetEstimates } from 'state/estimates/actions';
import { fetchCampaigns } from 'state/campaigns/actions';
import { LOADING } from 'state/resourceStatus/reducer';
import { fetchCostCategoriesReport } from 'state/reports/actions';
import CostCategoriesSpend from 'components/reports/costCategories';
import ComplexityByRegion from 'components/reports/leadMarketSpend';
import CumulativeSpend from 'components/reports/cumulativeSpend';
import CampaignsByBudgetReport from 'components/reports/campaignsByDivision';
import CampaignsByCategoryReport from 'components/reports/campaignsByCategory';
import ReportsDescriptions from 'components/reports/reports';
import { Message } from 'components/reports/components';
import AppContainer from 'components/listPageContainer';
import Filters, { defaultFilters, parseQueryFilters } from 'components/filtersSidebar';
import TopLineCustomReport from 'components/reports/TopLineCustomReport';
import { getClientSettings, getUser } from 'state/authentication/selectors';
import { Redirect } from 'react-router';
import { AccountSetting } from 'cr-core/constants';
import DeliverablesReport from 'components/reports/DeliverablesReport/DeliverablesReport';

const reports = {
  'campaigns-by-region': filters => <CampaignsByBudgetReport filters={filters} />,
  'campaigns-by-segment': filters => <CampaignsByCategoryReport filters={filters} />,
  'cost-categories': <CostCategoriesSpend />,
  'cumulative-spend': <CumulativeSpend />,
  'lead-market': <ComplexityByRegion />,
  'top-line-custom': <TopLineCustomReport />,
  deliverables: <DeliverablesReport />,
};

export const initiallyDisplayedFilters = [
  'search',
  'campaignHumanId',
  'clientIoNumber',
  'mediaType',
  'categoryBrand',
  'estimateStatus',
  'reportingYear',
  'payingCountries',
  'excludeCancelledEstimates',
  'budgetSource',
];

const useStyles = makeStyles(theme => ({
  filtersContainer: {
    maxWidth: 310,
  },
  mainContent: {
    // minWidth: 710,
  },
}));

const isCustomReport = report => {
  return ['top-line-custom', 'deliverables'].includes(report);
};

const Reports = ({
  match,
  showLoader,
  loadData,
  location,
  loadingCostCategoriesReport,
  clientSettings,
  user,
}) => {
  const classes = useStyles();
  const reportId = get('params.reportId', match);
  const [filters, setFilters] = useState({
    ...defaultFilters,
    ...parseQueryFilters(location.search),
  });
  const selectedReport =
    reports[reportId] instanceof Function ? reports[reportId](filters) : reports[reportId];
  const [pageId] = useState(uuid());
  const fetchingCostCategoriesReport =
    reportId === 'cost-categories' && loadingCostCategoriesReport;

  const isLoading = !isCustomReport(reportId) && showLoader;
  const topLineCustomReportEnabled = clientSettings[AccountSetting.TopLineReport];
  const deliverablesReportEnabled = clientSettings[AccountSetting.DeliverablesReport];

  const onChangeFilters = (values, prevValues) => {
    track(Events.FILTERS_CHANGED, {
      page: `reports_${reportId || 'landing'}`,
      ...getFiltersChangedData(values, prevValues),
    });

    setFilters(values);
  };

  useEffect(() => {
    loadData(filters);
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    (reportId === 'top-line-custom' && !topLineCustomReportEnabled) ||
    (reportId === 'deliverables' && !deliverablesReportEnabled)
  ) {
    return <Redirect to="/reports" />;
  }

  return (
    <AppContainer>
      {!isCustomReport(reportId) && (
        <div className={classes.filtersContainer}>
          <Filters
            pageId={pageId}
            onChange={onChangeFilters}
            filters={filters}
            initiallyDisplayedFilters={initiallyDisplayedFilters}
          />
        </div>
      )}
      <MainContent className={classes.mainContent}>
        {!isLoading && !fetchingCostCategoriesReport && selectedReport}
        {selectedReport && (isLoading || fetchingCostCategoriesReport) && (
          <Message>Loading...</Message>
        )}
        {!selectedReport && <ReportsDescriptions />}
      </MainContent>
    </AppContainer>
  );
};

const mapStateToProps = state => ({
  showLoader:
    state.resourceStatus.estimates === LOADING || state.resourceStatus.campaigns === LOADING,
  loadingCostCategoriesReport: state.resourceStatus.costCategoriesReport === LOADING,
  user: getUser(state),
  clientSettings: getClientSettings(state),
});

const mapDispatchToProps = (dispatch, { pageId }) => ({
  loadData: async filters => {
    pageId = pageId || uuid();
    dispatch(resetEstimates());
    const omitFilters = omit(['clientIoNumber'], filters);

    await Promise.all([
      dispatch(fetchEstimates({ page: 1, limit: 5000, ...filters }, pageId)),
      dispatch(fetchCampaigns({ page: 1, limit: 5000, ...filters }, pageId)),
    ]);
    await dispatch(fetchCostCategoriesReport(omitFilters, pageId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
