import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, withHandlers } from 'recompose';
import { find, get } from 'lodash/fp';
import { Events, track, getEstimateData } from 'components/analytics';
import UserSelect from 'components/userSelect';
import { getPickableWorkspaceMembers } from 'state/workspaces/selectors';
import { getEstimateById } from 'state/estimates/selectors';
import { createWatcher } from 'state/estimateWatchers/actions';
import { fetchWorkspaceMembers } from 'state/workspaces/actions';
import { getCampaignById } from 'state/campaigns/selectors';
import { getUser } from 'state/authentication/selectors';
import { EntityTypes } from 'cr-core/constants';

const createEphemeralWatcher = (estimateId, creator, user) => {
  const now = Date.now().toString();

  return {
    id: now,
    nonce: now,
    createdAt: new Date(),
    creator,
    user,
    userId: user && user.id,
    entityId: estimateId,
    entityType: EntityTypes.ESTIMATE,
  };
};

const mapStateToProps = (state, { estimateId }) => {
  const estimate = getEstimateById(estimateId)(state);
  const campaign = getCampaignById(estimate.campaignId)(state);
  const estimateCreator = estimate.creator;
  const workspaceMembers = getPickableWorkspaceMembers(estimateId)(campaign.workspaceId)(
    state
  ).filter(member => member.id !== estimateCreator?.id);

  return {
    workspaceId: campaign.workspaceId,
    workspaceMembers,
    estimate,
    creator: getUser(state),
  };
};

const addNewWatcher = (estimateId, userId, pageId, user) => {
  return (dispatch, getState) => {
    const creator = getUser(getState());
    const watcher = createEphemeralWatcher(estimateId, creator, user);
    dispatch(createWatcher(estimateId, userId, pageId, watcher));
  };
};

const mapDispatchToProps = (dispatch, { estimateId, pageId, ...rest }) => ({
  addWatcher: (userId, user) => {
    dispatch(addNewWatcher(estimateId, userId, pageId, user));
  },
  fetchWorkspaceMembers: workspaceId => dispatch(fetchWorkspaceMembers(workspaceId)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.fetchWorkspaceMembers(this.props.workspaceId);
    },
  }),
  withHandlers({
    addWatcherHandler: ({ addWatcher, estimate, workspaceMembers }) => userId => {
      const watcher = find({ id: userId }, workspaceMembers);
      addWatcher(userId, watcher);
      track(Events.ESTIMATE_ADD_WATCHER, {
        watcherUserId: userId,
        watcherUseremail: get('email', watcher),
        watcherIsConsultant: Boolean(get('isConsultant', watcher)),
        ...getEstimateData(estimate),
      });
    },
  })
)(({ workspaceMembers, addWatcherHandler, ...props }) => (
  <UserSelect
    users={workspaceMembers}
    onChange={async ({ value: userId }) => addWatcherHandler(userId)}
    value={null}
    {...props}
  />
));
