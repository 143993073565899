import uuid from 'uuid/v4';

export const NOTIFICATION_TIMER = 6000;

export const ERROR_NOTIFICATION = 'ERROR_NOTIFICATION';
export const SUCCESS_NOTIFICATION = 'SUCCESS_NOTIFICATION';

export const NOTIFICATION_ADD = 'notification/ADD';
export const NOTIFICATION_CLOSE = 'notification/CLOSE';

export const closeNotification = notificationId => dispatch =>
  dispatch({ type: NOTIFICATION_CLOSE, notificationId });

const showNotification =
  type =>
  ({ message }) =>
  async dispatch => {
    const id = uuid();
    dispatch({
      type: NOTIFICATION_ADD,
      notification: {
        id,
        message,
        type: type,
      },
    });
    setTimeout(() => dispatch(closeNotification(id)), NOTIFICATION_TIMER);
  };

export const showErrorNotification = showNotification(ERROR_NOTIFICATION);

export const showSuccessNotification = showNotification(SUCCESS_NOTIFICATION);

const types = {
  error: ERROR_NOTIFICATION,
  success: SUCCESS_NOTIFICATION,
};

export const displayNotification =
  ({ type, message }) =>
  async dispatch => {
    const id = uuid();
    console.log('aasasa');
    dispatch({
      type: NOTIFICATION_ADD,
      notification: {
        id,
        message,
        type: types[type],
      },
    });

    setTimeout(() => dispatch(closeNotification(id)), NOTIFICATION_TIMER);
  };
