import React, { useState, useEffect } from 'react';
import { Select } from 'components/forms';
import { connect } from 'react-redux';
import { map, toLower } from 'lodash/fp';
import UserAvatar from 'components/userAvatar';
import { formatUserName } from 'cr-core/userUtils';
import styled from 'styled-components';
import { colors } from '../../shared/styles';

const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0px;
  }

  &.focused {
    background-color: ${colors.recommendedColumn};
  }

  .avatar {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    margin-right: 10px;
  }
`;

const UserListOption = ({ innerProps, innerRef, data, isFocused }) => (
  <div ref={innerRef} {...innerProps}>
    <Option className={`${isFocused ? 'focused' : ''}`}>
      <UserAvatar user={data.user} />
    </Option>
  </div>
);

const convertUsersToOptions = map(user => ({
  value: user.id,
  label: formatUserName(user),
  user,
}));

const mapStateToProps = (state, { users }) => ({
  userOptions: convertUsersToOptions(users),
});

const styles = {
  menu: provided => ({
    ...provided,
    zIndex: 2,
  }),
};

export default connect(mapStateToProps)(({ userOptions, ...props }) => {
  const [options, setOptions] = useState(userOptions);
  useEffect(() => {
    setOptions(userOptions);
  }, [userOptions]);

  return (
    <Select
      components={{ Option: UserListOption }}
      options={options}
      onInputChange={inputValue => {
        const searchTerm = toLower(inputValue);
        setOptions(
          searchTerm
            ? userOptions
                .filter(
                  item =>
                    item.user.email.includes(searchTerm) ||
                    (item.user.firstName && toLower(item.user.firstName).includes(searchTerm)) ||
                    (item.user.lastName && toLower(item.user.lastName).includes(searchTerm))
                )
                .sort(
                  (a, b) =>
                    b.user.email.startsWith(searchTerm) - a.user.email.startsWith(searchTerm)
                )
            : userOptions
        );
      }}
      styles={styles}
      {...props}
    />
  );
});
