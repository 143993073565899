import React from 'react';

const SvgPendingIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="pending-icon_svg__a"
        width="133.3%"
        height="133.3%"
        x="-16.7%"
        y="-16.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g fill="currentColor" filter="url(#pending-icon_svg__a)" transform="translate(3 3)">
      <path d="M11.988 0C18.622 0 24 5.374 24 12s-5.378 12-12.012 12C5.366 24 0 18.626 0 12S5.366 0 11.988 0zM12 21.531A9.529 9.529 0 0 0 21.531 12 9.529 9.529 0 0 0 12 2.469 9.529 9.529 0 0 0 2.469 12 9.529 9.529 0 0 0 12 21.531z" />
      <path d="M18.857 15.3l-1.452 2.414-7.12-4.332v-8.24h2.882v6.734z" />
    </g>
  </svg>
);

export default SvgPendingIcon;
