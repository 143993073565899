import {
  flow,
  get,
  find,
  map,
  filter,
  flatMap,
  includes,
  groupBy,
  mapValues,
  sumBy,
  toPairs,
  uniq,
  flatten,
  getOr,
} from 'lodash/fp';
import { createSelector } from 'reselect';

const sumCurrenciesForBid = bidId =>
  flow(
    filter({ bidId }),
    groupBy('value.currency'),
    mapValues(sumBy('value.amount')),
    toPairs,
    map(([currency, amount]) => ({ currency, amount }))
  );

export const getLineItemById = lineItemNameId =>
  flow(getLineItemGroups, flatMap('lineItemNames'), find({ id: lineItemNameId }));

export const getLineItemGroups = get('bids.lineItemGroups');

export const getBids = get('bids.bids');

export const getBidValues = get('bids.bidValues');

export const getCostCategoryBeingAdded = get('bids.costCategoryBeingAdded');
export const getLineItemBeingAddedToCostCategory = costCategoryId =>
  getOr(false, `bids.lineItemBeingAdded.${costCategoryId}`);

// Estimate

export const getCurrenciesForEstimate = flow(
  getBidValues,
  filter({ type: 'cost' }),
  map('value.currency'),
  uniq
);

export const getValueCurrenciesForEstimate = flow(
  getBidValues,
  filter(
    ({ type, value }) =>
      (type === 'cost' ||
        type === 'costSupplier' ||
        type === 'costSupplierHours' ||
        type === 'costText') &&
      value &&
      value.amount > 0
  ),
  map('value.currency'),
  uniq
);

// Bids

export const getBidIdsForEstimate = flow(getBids, map('id'));

export const getBidLabelById = bidId => flow(getBids, find({ id: bidId }), get('label'));

export const getBidTotal = (currency, bidId) =>
  flow(getBidValues, filter({ type: 'cost' }), sumCurrenciesForBid(bidId), bids =>
    currency ? filter({ currency }, bids) : bids
  );

export const getEstimateBidTotal = (estimate, currency, bidId) =>
  flow(
    get('bidValues'),
    filter(
      ({ type }) =>
        type === 'cost' ||
        type === 'costSupplier' ||
        type === 'costSupplierHours' ||
        type === 'costText'
    ),
    sumCurrenciesForBid(bidId),
    bids => (currency ? filter({ currency }, bids) : bids)
  )(estimate);

export const isBidEmpty = bidId =>
  flow(getBidValues, filter({ bidId }), values => Boolean(values.length));

// Cost Categories

export const getCostCategoryIds = flow(getLineItemGroups, map('id'));

export const getCostCategoryTotalForBid = (costCategoryId, bidId) => state =>
  flow(
    getBidValues,
    filter({ type: 'cost' }),
    filter(({ lineItemNameId }) =>
      includes(lineItemNameId, getLineItemIdsForCostCategory(costCategoryId)(state))
    ),
    sumCurrenciesForBid(bidId)
  )(state);

export const getCostCategoryName = costCategoryId =>
  flow(getLineItemGroups, find({ id: costCategoryId }), get('name'));

export const isCostCategoryEmpty = costCategoryId =>
  createSelector(getLineItemsForCostCategory, getBidValues, (lineItems, bidValues) =>
    flow(
      map('id'),
      map(id => filter({ lineItemNameId: id }, bidValues)),
      flatten,
      values => Boolean(values.length)
    )(lineItems)
  );

// Line Items

export const getAllLineItems = () => flow(getLineItemGroups, map('lineItemNames'), flatten);

export const getLineItemsForCostCategory = costCategoryId =>
  flow(getLineItemGroups, find({ id: costCategoryId }), get('lineItemNames'));

export const getLineItemIdsForCostCategory = costCategoryId =>
  flow(getLineItemsForCostCategory(costCategoryId), map('id'));

export const getCostLineItemIdsForCostCategory = costCategoryId =>
  flow(getLineItemsForCostCategory(costCategoryId), filter({ type: 'cost' }), map('id'));

export const getMetadataLineItemIdsForCostCategory = costCategoryId =>
  flow(
    getLineItemsForCostCategory(costCategoryId),
    filter(({ type }) => type === 'string' || type === 'number'),
    map('id')
  );

export const getLineItemName = lineItemNameId => flow(getLineItemById(lineItemNameId), get('name'));

export const getLineItemType = lineItemNameId => flow(getLineItemById(lineItemNameId), get('type'));

export const getBidValueForLineItemId = (bidId, lineItemNameId) =>
  flow(getBidValues, find({ lineItemNameId, bidId }), get('value'));

export const isLineItemLineEmpty = lineItemNameId =>
  flow(getBidValues, filter({ lineItemNameId }), values => Boolean(values.length));
