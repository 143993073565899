import React from 'react';
import { connect } from 'react-redux';
import { branch, compose, withHandlers } from 'recompose';
import { RoundButton } from 'components/buttons';
import SvgTrashIcon from 'icons/TrashIcon';
import { Events, track, getEstimateData } from 'components/analytics';
import { removeBidFromEstimate } from 'state/bids/actions';
import { getEstimateById } from 'state/estimates/selectors';
import { isBidEmpty } from 'state/bids/selectors';
import withEditConfirmationDialog from '../estimateEdit/withEditConfirmationDialog';
import withDeleteConfirmationDialog from '../estimateEdit/withDeleteConfirmation';

const DeleteBidButton = props => {
  const { onClick, trackClick } = props;
  return (
    <RoundButton
      onClick={() => {
        trackClick();
        onClick();
      }}
      data-test="delete-bid"
    >
      <SvgTrashIcon className="icon" />
    </RoundButton>
  );
};

const mapDispatchToProps = (dispatch, { estimateId, bidId, pageId }) => ({
  onClick: () => dispatch(removeBidFromEstimate({ estimateId, bidId, pageId })),
});

const mapStateToProps = (state, { estimateId, bidId }) => ({
  shouldAskDeleteConfirmation: isBidEmpty(bidId)(state),
  estimate: getEstimateById(estimateId)(state),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    trackClick: ({ estimate }) => () =>
      track(Events.ESTIMATE_DELETE_BID_CLICK, getEstimateData(estimate)),
  }),
  branch(
    ({ shouldAskDeleteConfirmation, bidId }) => shouldAskDeleteConfirmation,
    withDeleteConfirmationDialog('onClick', {
      trackEvent: {
        success: Events.ESTIMATE_DELETE_BID_SUCCESS,
        cancel: Events.ESTIMATE_DELETE_BID_CANCEL,
        props: ['bidId'],
      },
    })
  ),
  withEditConfirmationDialog('onClick', {
    trackEvent: {
      success: Events.ESTIMATE_DELETE_BID_SUCCESS,
      cancel: Events.ESTIMATE_DELETE_BID_CANCEL,
    },
  })
)(DeleteBidButton);
