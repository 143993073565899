import React from 'react';

const SvgIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 21" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path
        d="M15,0.013368984 C8.18181818,0.013368984 2.31818182,4.24064171 0,10.2406417 C2.31818182,16.2406417 8.18181818,20.4679144 15,20.4679144 C21.8181818,20.4679144 27.6818182,16.2406417 30,10.2406417 C27.6818182,4.24064171 21.8181818,0.013368984 15,0.013368984 Z M15,17.0588235 C11.1818182,17.0588235 8.18181818,14.0588235 8.18181818,10.2406417 C8.18181818,6.42245989 11.1818182,3.42245989 15,3.42245989 C18.8181818,3.42245989 21.8181818,6.42245989 21.8181818,10.2406417 C21.8181818,14.0588235 18.8181818,17.0588235 15,17.0588235 Z M15,6.14973262 C12.6818182,6.14973262 10.9090909,7.92245989 10.9090909,10.2406417 C10.9090909,12.5588235 12.6818182,14.3315508 15,14.3315508 C17.3181818,14.3315508 19.0909091,12.5588235 19.0909091,10.2406417 C19.0909091,7.92245989 17.3181818,6.14973262 15,6.14973262 Z"
        id="Shape"
      />
    </g>
  </svg>
);

export default SvgIcon;
