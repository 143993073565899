import { flow, get, map, set, findIndex, flatten, uniqBy } from 'lodash/fp';
import {
  WORKSPACES_FETCH_SUCCESS,
  WORKSPACES_FETCH_REQUEST,
  WORKSPACES_FETCH_ERROR,
  WORKSPACE_CREATE_SUCCESS,
  WORKSPACE_FETCH_SUCCESS,
  FETCH_WORKSPACE_MEMBERS_SUCCESS,
} from './actions';
import { LOGIN_SUCCESS } from 'state/authentication/actions';

const defaultState = {
  list: [],
  userWorkspaces: [],
  error: null,
  pagination: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case WORKSPACES_FETCH_REQUEST:
      return { ...state, list: [], loading: true };

    case WORKSPACES_FETCH_ERROR:
      return { ...state, list: [], loading: false };

    case WORKSPACES_FETCH_SUCCESS: {
      const { workspaces, pagination } = action;
      return { ...state, error: null, list: workspaces, pagination, loading: false };
    }

    case WORKSPACE_FETCH_SUCCESS: {
      const { workspace } = action;
      const workspaces = state.list;
      const index = findIndex({ id: workspace.id }, workspaces);
      workspaces[index] = workspace;
      return { ...state, error: null, list: workspaces };
    }

    case FETCH_WORKSPACE_MEMBERS_SUCCESS:
      return set(`members.${action.workspaceId}`, action.members, state);

    case WORKSPACE_CREATE_SUCCESS: {
      const { workspace } = action;
      return {
        [workspace.id]: workspace,
        ...state,
      };
    }

    case LOGIN_SUCCESS: {
      const workspaces = flow(
        get('profile.teams'),
        map('workspaces'),
        flatten,
        uniqBy('id')
      )(action);

      return {
        ...state,
        userWorkspaces: workspaces.map(workspace => ({
          ...workspace,
          account: {
            ...workspace.account,
            accountSettings: workspace.account.accountSettings.reduce((acc, cur) => {
              acc[cur.setting] = cur.enabled;
              return acc;
            }, {}),
          },
        })),
      };
    }

    default:
      return state;
  }
};
