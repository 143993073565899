import React from 'react';
import clsx from 'clsx';
import { map } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { formatAsCurrency } from 'cr-core/currencyUtils';

const useStyles = makeStyles(theme => ({
  legendVertical: {
    flexShrink: 0,
    width: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
  },
  legendHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginBottom: -theme.spacing(1),
  },
  legendItem: {
    display: 'flex',
  },
  legendItemHorizontal: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
  },
  legendCircle: {
    borderRadius: '50%',
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    minWidth: theme.spacing(1.5),
    minHeight: theme.spacing(1.5),
    marginRight: theme.spacing(0.5),
    display: 'inline-block',
  },
}));

export const getValueWithPercentage = (value, total, displayCurrency) =>
  `${displayCurrency ? formatAsCurrency(displayCurrency, value) : value}${
    total ? ` - ${Math.round((value / total) * 100)}%` : ''
  }`;

export const Circle = ({ color }) => {
  const classes = useStyles();
  return <div className={classes.legendCircle} style={{ background: color }}></div>;
};

const Legend = ({ items, className, direction = 'vertical' }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        direction === 'vertical' ? classes.legendVertical : classes.legendHorizontal,
        className,
      )}
    >
      {map(
        ({ label, color }) => (
          <div
            className={clsx(classes.legendItem, {
              [classes.legendItemHorizontal]: direction === 'horizontal',
            })}
            key={label}
          >
            <Circle color={color} />
            {label}
          </div>
        ),
        items,
      )}
    </div>
  );
};

export default Legend;
