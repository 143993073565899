import React from 'react';

const SvgCancelIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="cancel-icon_svg__a"
        width="140%"
        height="140%"
        x="-20%"
        y="-20%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      d="M17.233.146a.5.5 0 0 1 .707 0l1.914 1.914a.5.5 0 0 1 0 .707L12.62 10l7.233 7.233a.5.5 0 0 1 0 .707l-1.914 1.914a.5.5 0 0 1-.707 0L10 12.62l-7.233 7.233a.5.5 0 0 1-.707 0L.146 17.94a.5.5 0 0 1 0-.707L7.38 10 .146 2.767a.5.5 0 0 1 0-.707L2.06.146a.5.5 0 0 1 .707 0L10 7.38 17.233.146z"
      filter="url(#cancel-icon_svg__a)"
      transform="translate(5 5)"
    />
  </svg>
);

export default SvgCancelIcon;
