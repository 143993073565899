import React from 'react';
import styled from 'styled-components';
import { colors, font } from 'shared/styles';
import { getUserName, getInitialsForUser, getUserPicture } from 'cr-core/userUtils';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Initials = styled.div`
  width: 36px;
  height: 36px;
  background-color: white;
  border-radius: 18px;
  font-weight: ${font.weights.bold};
  letter-spacing: -0.4px;
  text-align: center;
  color: ${colors.primary};
  margin-right: 10px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #ccc;
`;

const UserPicture = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  margin-right: 10px;
  flex-shrink: 0;
`;

const UserName = styled.div`
  font-size: 14px;
  font-weight: ${font.weights.bold};
  letter-spacing: -0.3px;
  margin-right: 20px;
`;

const Email = styled.div`
  font-size: ${font.sizes.small};
`;

const Avatar = ({ user = {}, className, showName = true, ...props }) => {
  const picture = getUserPicture(user);
  const userName = getUserName(user);
  return (
    <Container className={className} title={userName || user.email} {...props}>
      {!picture && <Initials className="initials">{getInitialsForUser(user)}</Initials>}
      {picture && <UserPicture className="userPicture" src={picture} />}
      {showName && (
        <div>
          <UserName className="userName" data-test="userAvatar:userName">
            {userName || user.email}
          </UserName>
          {userName && <Email>{user.email}</Email>}
        </div>
      )}
    </Container>
  );
};

export default Avatar;
