import React from 'react';
import { get, forEach, keys } from 'lodash/fp';
import {
  useTable,
  useGroupBy,
  useExpanded,
  useSortBy,
  useRowState,
  useFlexLayout,
} from 'react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Paper,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { addTrackReportNewTableClick } from 'components/analytics';
import { TableLegend } from './components';

const useStyles = makeStyles(theme => ({
  container: {
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  header: { fontWeight: 600 },
  footer: { ...theme.typography.body2, color: theme.palette.text.primary, fontWeight: 600 },
  expanderIcon: {
    height: '20px',
    width: '20px',
    marginTop: -6,
    marginBottom: -6,
    marginLeft: -6,
  },
  footerRow: {
    display: 'flex',
    flex: '1 0 auto',
    minWidth: 0,
  },
}));

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const customPrepareProps = (column, props) => {
  const customProps = { style: {}, ...props };
  if (column.align) {
    customProps.align = column.align;
  }
  if (column.maxWidth < 1000) {
    column.width = column.maxWidth;
    customProps.style.width = `${column.width}px`;
    customProps.style.maxWidth = `${column.width}px`;
  }
  customProps.style.boxSizing = 'border-box';
  customProps.style.flex = `${column.width} 0 auto`;
  return customProps;
};

const Head = ({ headerGroups }) => {
  const classes = useStyles();
  return (
    <TableHead>
      {headerGroups.map(headerGroup => (
        <TableRow {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => {
            const headerProps = customPrepareProps(column, column.getHeaderProps());
            return (
              <TableCell className={classes.header} {...headerProps}>
                {column.render('Header')}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </TableHead>
  );
};

const DataTable = ({
  data,
  columns,
  reportEventName,
  expandAll,
  initialState,
  SubComponent,
  reportDescription,
  afterLegend,
}) => {
  const classes = useStyles();
  const { groupBy, sortBy } = initialState;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        groupBy,
        sortBy: sortBy.map(id => ({ id, desc: false })),
      },
      useControlledState: React.useMemo(() => {
        if (expandAll) {
          return state => {
            forEach(index => {
              // to fix a library bug
              if (state.rowState[index].showSubComponent) {
                state.rowState[index].showSubComponent = false;
              }
            }, keys(state.rowState));

            return { ...state, groupBy: [] };
          };
        }
        return state => state;
      }, [expandAll]), // eslint-disable-line react-hooks/exhaustive-deps
    },
    useRowState,
    useGroupBy,
    useSortBy,
    useExpanded,
    useFlexLayout,
  );
  const ArrowDown = <ArrowDropDownIcon className={classes.expanderIcon} />;
  const ArrowRight = <ArrowRightIcon className={classes.expanderIcon} />;

  return (
    <>
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} {...getTableProps()}>
          <Head headerGroups={headerGroups} />
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              const rowProps = row.getRowProps();

              return (
                <>
                  <StyledTableRow {...rowProps} key={`${rowProps.key}-row`}>
                    {row.cells.map(cell => {
                      let tdProps = customPrepareProps(cell.column, cell.getCellProps());
                      let pre = '';
                      const expandable =
                        cell.isGrouped && (!cell.column.expandable || cell.column.expandable(row));

                      if (cell.isGrouped && expandable) {
                        const style = tdProps.style;
                        tdProps = { ...tdProps, ...row.getToggleRowExpandedProps() };
                        tdProps.style = { ...tdProps.style, ...style };
                        addTrackReportNewTableClick(reportEventName, tdProps, cell);
                      }

                      const hasSubcomponent =
                        cell.column.SubComponent &&
                        !cell.isAggregated &&
                        !expandAll &&
                        (!cell.column.hasSubcomponent || cell.column.hasSubcomponent(row));
                      if (hasSubcomponent) {
                        pre = row.state.showSubComponent ? ArrowDown : ArrowRight;
                        tdProps.style.cursor = 'pointer';
                        tdProps.onClick = (...props) => {
                          row.setState({ showSubComponent: !Boolean(row.state.showSubComponent) });
                        };
                      } else if (cell.isGrouped && expandable) {
                        pre = cell.row.isExpanded ? ArrowDown : ArrowRight;
                      }

                      return (
                        <TableCell {...tdProps}>
                          {pre}
                          {cell.isGrouped
                            ? cell.render('Cell')
                            : cell.isAggregated
                            ? cell.column.Aggregated
                              ? cell.render('Aggregated')
                              : cell.render('Cell')
                            : cell.isPlaceholder
                            ? null
                            : cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                  {SubComponent && get('state.showSubComponent', row) && (
                    <StyledTableRow {...rowProps} key={`${rowProps.key}-sub-component`}>
                      <TableCell colSpan={row.cells.length} style={{ width: '100%' }}>
                        <SubComponent row={row} />
                      </TableCell>
                    </StyledTableRow>
                  )}
                </>
              );
            })}
          </TableBody>
          <TableFooter>
            {footerGroups.map(group => (
              <TableRow {...group.getFooterGroupProps()} className={classes.footerRow}>
                {group.headers.map(column => {
                  const footerProps = customPrepareProps(column, column.getFooterProps());
                  return (
                    <TableCell className={classes.footer} {...footerProps}>
                      {column.render('Footer')}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableFooter>
        </Table>
      </TableContainer>
      <TableLegend description={reportDescription} afterLegend={afterLegend} columns={columns} />
    </>
  );
};

export default DataTable;
