import React from 'react';
import { flow, isEqual, sumBy } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { formatAsCurrency } from 'cr-core/currencyUtils';
import Metrics from './metrics';
import { useQuery } from 'react-query';
import CampaignService from 'services/CampaignService';
import { defaultFilters } from 'components/filtersSidebar';
import { getClientSettings } from 'state/authentication/selectors';
import { AccountSetting } from 'cr-core/constants';
import { connect } from 'react-redux';
import { getPopulatedFilters } from 'components/reports/utils';
import queryString from 'query-string';

const useStyles = makeStyles(theme => ({
  overbudget: {
    color: theme.palette.error.main,
  },
  savings: {
    color: theme.palette.success.main,
  },
}));

const DefaultMetrics = ({
  campaigns,
  estimates,
  displayCurrency,
  filters = defaultFilters,
  clientSettings,
}) => {
  const classes = useStyles();

  const populatedFilters = getPopulatedFilters(filters, queryString.parse(window.location.search));

  const areFiltersDefault = isEqual(defaultFilters, {
    reportingYear: populatedFilters.reportingYear,
    excludeCancelledEstimates: populatedFilters.excludeCancelledEstimates,
  });

  const query = {
    reportingYear: filters.reportingYear,
    excludeCancelledEstimates: filters.excludeCancelledEstimates,
    displayCurrency,
  };
  const { isLoading, data } = useQuery(
    ['campaigns', 'overallBudget', query],
    async () => {
      if (areFiltersDefault || !clientSettings[AccountSetting.OverallBudget]) {
        return Promise.reject();
      }

      const { data = { totalBudget: 0, campaignsCount: 0 } } = clientSettings[
        AccountSetting.OverallBudget
      ]
        ? await CampaignService.getOverallBudget(query)
        : {};

      return data;
    },
    {
      enabled: clientSettings[AccountSetting.OverallBudget],
      initialData: areFiltersDefault
        ? {
            totalBudget: sumBy('budget', campaigns),
            campaignsCount: campaigns.length,
          }
        : undefined,
    }
  );

  const totalBudget = sumBy('budget', campaigns);
  const originalBudget = sumBy('budgetValueConverted', campaigns);
  const estimatedSpend = flow(sumBy('recommendedBidTotal'))(estimates);
  const budgetLeft = totalBudget - estimatedSpend;

  const budgetLeftPercentage = Math.round((budgetLeft / originalBudget) * 100);

  const metrics = [
    {
      title: 'Total Budget',
      value: formatAsCurrency(displayCurrency, totalBudget),
      subtitle: `${campaigns.length} campaigns`,
    },
    {
      title: 'Estimated Spend',
      value: formatAsCurrency(displayCurrency, estimatedSpend),
      subtitle: `${formatAsCurrency(
        displayCurrency,
        estimatedSpend / campaigns.length
      )} avg campaign cost`,
    },
  ];

  if (budgetLeft >= 0) {
    metrics.push({
      title: 'Budget Left',
      value: formatAsCurrency(displayCurrency, budgetLeft),
      subtitle: totalBudget ? `${budgetLeftPercentage}% of total budget` : null,
      classes: {
        subtitle: budgetLeftPercentage >= 95 ? classes.error : null,
      },
    });
  } else {
    metrics.push({
      title: 'Overbudget',
      value: formatAsCurrency(displayCurrency, -budgetLeft),
      subtitle: `${-budgetLeftPercentage}% over the total budget`,
      classes: {
        value: classes.overbudget,
      },
    });
  }

  clientSettings[AccountSetting.OverallBudget] &&
    metrics.unshift({
      title: 'Overall Total Budget',
      value: formatAsCurrency(displayCurrency, !isLoading && data.totalBudget),
      subtitle: `${!isLoading && data.campaignsCount} campaigns`,
    });

  return <Metrics metrics={metrics} isLoading={isLoading} />;
};

const mapStateToProps = (state, { estimateId }) => ({
  clientSettings: getClientSettings(state),
});

export default connect(mapStateToProps)(DefaultMetrics);
