import React from 'react';

const SvgIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g fill="currentColor" fill-rule="nonzero" id="sad">
        <path
          d="M20.1923077,13.2692308 C21.4692548,13.2692308 22.5,12.2384856 22.5,10.9615385 C22.5,9.68459135 21.4692548,8.65384615 20.1923077,8.65384615 C18.9153606,8.65384615 17.8846154,9.68459135 17.8846154,10.9615385 C17.8846154,12.2384856 18.9153606,13.2692308 20.1923077,13.2692308 Z"
          id="Path"
        />
        <path
          d="M9.80769231,13.2692308 C11.0846394,13.2692308 12.1153846,12.2384856 12.1153846,10.9615385 C12.1153846,9.68459135 11.0846394,8.65384615 9.80769231,8.65384615 C8.53074519,8.65384615 7.5,9.68459135 7.5,10.9615385 C7.5,12.2384856 8.53074519,13.2692308 9.80769231,13.2692308 Z"
          id="Path"
        />
        <path
          d="M14.7115385,17.8846154 C11.1567527,17.8846154 8.14361989,20.0431222 6.92307692,23.0769231 L22.5,23.0769231 C21.279457,20.0431222 18.2663243,17.8846154 14.7115385,17.8846154 Z"
          id="Path"
        />
        <path
          d="M14.9925117,0 C6.70499476,0 0,6.71253393 0,15 C0,23.2874661 6.70499476,30 14.9925117,30 C23.2800287,30 30,23.2874661 30,15 C30,6.71253393 23.2799584,0 14.9925117,0 Z M14.9999648,27.0000422 C8.36999346,27.0000422 2.99995078,21.6300572 2.99995078,15 C2.99995078,8.36994277 8.36999346,3.00002813 14.9999648,3.00002813 C21.6299362,3.00002813 26.9999789,8.37001308 26.9999789,15 C26.9999789,21.6299869 21.6300065,27.0000422 14.9999648,27.0000422 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

export default SvgIcon;
