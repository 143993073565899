import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { compose, withState, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import Modal from 'components/modal';
import { Button, CancelButton } from 'components/buttons';
import { colors } from 'shared/styles';
import SVGCopyIcon from 'icons/CopyIcon';
import { getWorkspaceName } from 'state/workspaces/selectors';
import { getWorkspaceIdForEstimate } from 'state/estimates/selectors';
import { Events, track } from 'components/analytics';

const SharingModal = styled(Modal)`
  .header {
    background-color: ${colors.button};
  }
`;

const ModalBody = styled.div`
  .title {
    margin-bottom: 20px;
  }

  input {
    width: 100%;
    margin: 16px 0;
  }

  button {
    width: auto;
  }
`;

const CancelButtonStyled = styled(CancelButton)`
  padding-left: 30px !important;
  padding-right: 30px !important;
`;

const Blink = styled.p`
  color: ${colors.approve};
  opacity: ${({ shouldBlink }) => (shouldBlink ? 1 : 0)};
  transform: translateY(${({ shouldBlink }) => (shouldBlink ? '0' : '5px')});
  transition: all 0.5s ease-in-out;
`;

const BlinkContainer = styled.div`
  display: flex;
`;

const ButtonWithBlink = compose(
  withState('shouldBlink', 'setBlink', false),
  withHandlers({
    startBlink: ({ setBlink }) => () => setBlink(true),
    endBlink: ({ setBlink }) => () => setBlink(false),
  }),
  withProps(({ startBlink, endBlink, estimateId }) => ({
    copyToClipboard: () => {
      document.execCommand('copy');
      startBlink();

      track(Events.ESTIMATE_SHARE_COPY_CLICK, { estimateId });
      setTimeout(endBlink, 1000);
    },
  }))
)(({ shouldBlink, copyToClipboard }) => {
  return (
    <BlinkContainer>
      <Button onClick={copyToClipboard}>
        <SVGCopyIcon className="icon" />
        Copy to Clipboard
      </Button>
      <Blink shouldBlink={shouldBlink}>Copied</Blink>
    </BlinkContainer>
  );
});

const Buttons = ({ onRequestClose }) => (
  <CancelButtonStyled onClick={onRequestClose}>Close</CancelButtonStyled>
);

const WorkspaceAlert = ({ workspaceName }) => (
  <p>
    <b>Note:</b> It will only be accessible to people in the workspace: <b>{workspaceName}</b>
  </p>
);

const ShareEstimateModal = ({
  onRequestClose,
  location,
  workspaceName,
  estimateId,
  ...restProps
}) => (
  <SharingModal
    title={'Share Estimate'}
    buttons={<Buttons onRequestClose={onRequestClose} />}
    {...restProps}
  >
    <ModalBody>
      <p>Copy the URL below and send it to people you’d like to share it with.</p>
      <WorkspaceAlert workspaceName={workspaceName} />
      <form>
        <input
          autoFocus
          onFocus={e => e.target.select()}
          onBlur={e => e.target.focus()}
          readOnly
          value={`${window.location.protocol}//${window.location.host}${location.pathname}`}
        />
      </form>
      <ButtonWithBlink estimateId={estimateId} />
    </ModalBody>
  </SharingModal>
);

const mapStateToProps = (state, { estimateId }) => {
  const workspaceId = getWorkspaceIdForEstimate(estimateId)(state);
  return {
    workspaceName: getWorkspaceName(workspaceId)(state),
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter
)(ShareEstimateModal);
