import React from 'react';
import { map, differenceBy, sortBy } from 'lodash/fp';
import { makeStyles, fade, lighten } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
  ListItem,
  List,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  listContainer: {
    margin: theme.spacing(3, 0),
    width: '100%',
  },
  emptyListMessage: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2, 1),
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[300],
  },
  listItemBorder: {
    border: `1px solid ${lighten(fade(theme.palette.divider, 1), 0.88)}`,
    marginBottom: -1,
  },
  listItemButton: {
    color: theme.palette.grey[400],
  },
  listButton: {
    marginTop: theme.spacing(1),
  },
}));

export default ({ selectedTeams = [], teams = [], addTeam, removeTeam, showAccount = true }) => {
  const classes = useStyles();
  const availableTeams = differenceBy('id', teams, selectedTeams);

  return (
    <div className={classes.listContainer}>
      <Typography variant="h6">Teams</Typography>
      {Boolean(selectedTeams.length) && (
        <List>
          {map(
            ({ id, name, account }) => (
              <ListItem key={id} className={classes.listItemBorder}>
                <ListItemText
                  primary={name}
                  secondary={showAccount && account.name.toUpperCase()}
                />
                {removeTeam && (
                  <ListItemSecondaryAction onClick={() => removeTeam(id)}>
                    <IconButton edge="end" aria-label="delete" className={classes.listItemButton}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ),
            sortBy('name', selectedTeams)
          )}
        </List>
      )}

      {!selectedTeams.length && <div className={classes.emptyListMessage}>No team selected</div>}

      {Boolean(availableTeams.length && addTeam) && (
        <Button
          variant="contained"
          className={classes.listButton}
          startIcon={<AddIcon />}
          onClick={addTeam}
        >
          Add Team
        </Button>
      )}

      {Boolean(!availableTeams.length && addTeam) && (
        <Button variant="contained" className={classes.listButton} startIcon={<AddIcon />} disabled>
          Add Team
        </Button>
      )}
    </div>
  );
};
