import React, { useMemo } from 'react';
import {
  flow,
  groupBy,
  map,
  mapValues,
  values,
  reject,
  sortBy,
  find,
  flatten,
  sumBy,
  forEach,
  filter,
  keys,
  max,
  camelCase,
  concat,
} from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { EstimateStatuses, EstimateStatusLabels, EstimateStatusRanking } from 'cr-core/constants';
import { formatAsCurrency } from 'cr-core/currencyUtils';
import { BarChart2 as BarChart } from 'components/charts';
import { colors } from 'shared/styles';
import Box from './box';
import Legend, { Circle } from './legend';

const defaultHeight = 200;
const filteredOutDescription =
  "Estimates excluded from the results because they don't satisfy the filters";
const statusDescription = {
  [EstimateStatuses.ACTUALISED]:
    'Estimates approved where the agency team have also saved the actual invoiced amounts',
  [EstimateStatuses.APPROVED]: 'Estimates approved by all assigned approvers',
  [EstimateStatuses.PENDING_APPROVAL]: 'Estimates submitted for approval, but not approved yet',
  [EstimateStatuses.DRAFT]: 'Estimates still in progress',
  [EstimateStatuses.CANCELLED]: 'Estimates for productions which are not moving forward',
};

const useStyles = makeStyles(theme => ({
  chart: {
    height: defaultHeight,
    width: '100%',
  },
  tooltipTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  tooltipRow: {
    whiteSpace: 'nowrap',
  },
  tooltipDivider: {
    width: '100%',
    height: theme.spacing(1),
  },

  help: {
    flexShrink: 0,
    width: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  helpItem: {
    display: 'flex',
  },
  helpLabel: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  helpTitle: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
}));

const getData = ({ estimates, campaigns, indexPropGetFn }) => {
  return flow(
    concat(
      // add empty campaigns
      flow(
        filter(({ estimates }) => !estimates.length),
        map(({ id }) => ({ campaignId: id })),
      )(campaigns),
    ),
    groupBy('campaignId'),
    mapValues(estimates => {
      const campaign = find({ id: estimates[0].campaignId }, campaigns) || {};
      return {
        estimates,
        campaign,
        indexProp: indexPropGetFn(campaign.estimates),
      };
    }),
    values,
    groupBy('indexProp'),
    mapValues(items => {
      const budget = sumBy('campaign.budget', items);
      const totalCost = sumBy('campaign.totalCost', items);
      const reference = max([budget, totalCost]);
      const estSum = flow(
        map('estimates'),
        flatten,
        reject({ status: EstimateStatuses.CANCELLED }),
        sumBy(({ recommendedBidTotal, actualisedTotal, status }) =>
          status === EstimateStatuses.ACTUALISED && actualisedTotal
            ? actualisedTotal
            : recommendedBidTotal || 0,
        ),
      )(items);
      const filteredOut = totalCost - estSum;
      const data = {
        indexProp: items[0].indexProp,
        budget: budget,
        totalCost: totalCost,
        remainingBudget: budget > totalCost ? budget - totalCost : 0,
        overbudget: budget < totalCost ? totalCost - budget : 0,
        'Budget Left': reference
          ? ((budget > totalCost ? budget - totalCost : 0) / reference) * 100
          : 0,
        'Budget LeftColor': colors.budgetLeft,
        'Filtered Out': filteredOut > 0 && reference ? (filteredOut / reference) * 100 : 0,
        'Filtered OutColor': colors.filteredOut,
      };

      flow(
        keys,
        forEach(status => {
          const total = flow(
            map('estimates'),
            flatten,
            filter({ status }),
            sumBy(({ recommendedBidTotal, actualisedTotal, status }) =>
              status === EstimateStatuses.ACTUALISED && actualisedTotal
                ? actualisedTotal
                : recommendedBidTotal || 0,
            ),
          )(items);
          data[status] = total;
          data[`${EstimateStatusLabels[status]}Color`] = colors[camelCase(status)];
          data[EstimateStatusLabels[status]] = reference ? (total / reference) * 100 : 0;
        }),
      )(EstimateStatuses);
      return data;
    }),
    values,
    sortBy(({ budget, totalCost }) => -max([budget, totalCost])),
  )(estimates);
};

const tooltip = ({ totalSpend, totalCount, totalDeliverables, displayCurrency }) => ({
  id,
  value,
  label,
  color,
  data,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.tooltip}>
      <div className={classes.tooltipTitle}>{data.indexProp}</div>
      <div className={classes.tooltipDivider} />
      {map(status => {
        if (!data[status]) {
          return;
        }
        return (
          <div className={classes.tooltipRow} key={status}>
            <Circle color={colors[camelCase(status)]} />
            {EstimateStatusLabels[status]}: {formatAsCurrency(displayCurrency, data[status])} -{' '}
            {Math.round(data[EstimateStatusLabels[status]])}%
          </div>
        );
      }, keys(EstimateStatuses))}
      {!filter(status => data[status], keys(EstimateStatuses)).length && (
        <div className={classes.tooltipRow}>
          <i>No estimates</i>
        </div>
      )}
    </div>
  );
};

const Help = () => {
  const classes = useStyles();
  const items = flow(
    sortBy(status => EstimateStatusRanking[status]),
    map(status => ({
      color: colors[camelCase(status)],
      label: EstimateStatusLabels[status],
      description: statusDescription[status],
    })),
  )(EstimateStatuses);
  return (
    <div className={classes.help}>
      <div className={classes.helpItem}>
        <Circle color={colors.filteredOut} />
        <div>
          <span className={classes.helpLabel}>Filtered Out</span>: {filteredOutDescription}
        </div>
      </div>
      <div className={classes.helpTitle}>Estimate Statuses</div>
      {map(
        ({ color, label, description }) => (
          <div className={classes.helpItem} key={label}>
            <Circle color={color} />
            <div>
              <span className={classes.helpLabel}>{label}</span>: {description}
            </div>
          </div>
        ),
        items,
      )}
    </div>
  );
};

const EstimateStatusChart = ({ estimates, campaigns, indexPropGetFn, displayCurrency, title }) => {
  const classes = useStyles();
  const data = useMemo(() => getData({ estimates, campaigns, indexPropGetFn }), [
    estimates,
    campaigns,
    indexPropGetFn,
  ]);
  const height = data.length > 6 ? defaultHeight + (data.length - 6) * 30 : defaultHeight;
  const legend = filter(({ label }) => sumBy(label, data) > 0.1, [
    ...map(status => ({ label: EstimateStatusLabels[status], color: colors[camelCase(status)] }), [
      EstimateStatuses.ACTUALISED,
      EstimateStatuses.APPROVED,
      EstimateStatuses.PENDING_APPROVAL,
      EstimateStatuses.DRAFT,
    ]),
    {
      label: 'Budget Left',
      color: colors.budgetLeft,
    },
    {
      label: 'Filtered Out',
      color: colors.filteredOut,
    },
  ]);

  return (
    <Box
      title={title}
      Help={Help}
      Chart={() => (
        <div>
          <div className={classes.chart} style={{ height }}>
            <BarChart
              data={data}
              displayCurrency={displayCurrency}
              tooltip={tooltip({ displayCurrency })}
              indexBy="indexProp"
              keys={[
                EstimateStatusLabels.ACTUALISED,
                EstimateStatusLabels.APPROVED,
                EstimateStatusLabels.PENDING_APPROVAL,
                EstimateStatusLabels.DRAFT,
                'Budget Left',
                'Filtered Out',
              ]}
              colors={({ id, data }) => data[`${id}Color`]}
            />
          </div>
          <Legend items={legend} direction="horizontal" />
        </div>
      )}
    />
  );
};

export default EstimateStatusChart;
