import {
  flow,
  get,
  sumBy,
  map,
  orderBy,
  groupBy,
  set,
  toPairs,
  find,
  filter,
  compact,
  every,
  reject,
} from 'lodash/fp';
import { createSelector } from 'reselect';
import {
  MediaTypeRanking,
  StatusRanking,
  EstimateStatuses,
  CampaignStatuses,
} from 'cr-core/constants';
import {
  getEstimates,
  getEstimateById,
  getEstimatesById,
  getEstimateCost,
  getEstimatesByCampaignId,
} from 'state/estimates/selectors';
import { getDisplayCurrency } from 'state/authentication/selectors';

export const getCampaignsById = get('campaigns.byId');

export const getCampaigns = get('campaigns.list');
export const getCampaignsPagination = get('campaigns.pagination');
export const getCampaignsFilterValues = get('campaigns.campaignFilterValues');
export const getCampaignHumanIdAndClientIo = get('campaigns.campaignHumanIdsAndClientIo');
export const getCampaignsByWorkspace = get('campaigns.byWorkspace');

const orderByMediaTypeAndStatus = flow(
  map(estimate =>
    flow(
      set('mediaTypeRank', get(estimate.mediaType, MediaTypeRanking)),
      set('statusRank', get(estimate.status, StatusRanking))
    )(estimate)
  ),
  orderBy(['mediaTypeRank', 'statusRank'], ['asc', 'asc'])
);

export const getCampaignById = campaignId => get(`campaigns.byId.${campaignId}`);

export const getCampaignByEstimateId = estimateId => state =>
  flow(getEstimateById(estimateId), get('campaignId'), id => getCampaignById(id)(state))(state);

export const getCampaignCreationInProgress = get('campaigns.campaignCreationInProgress');

export const getFilteredCampaignCostById = campaignId =>
  createSelector(getEstimates, getDisplayCurrency, (estimates, displayCurrency) => {
    return flow(
      reject({ status: EstimateStatuses.CANCELLED }),
      campaignId ? filter({ campaignId }) : filter(({ campaignId }) => !campaignId),
      map(estimate => getEstimateCost(estimate)(displayCurrency)),
      estimateCosts => ({
        lowest: sumBy('lowest', estimateCosts),
        highest: sumBy('highest', estimateCosts),
        quoteCurrency: displayCurrency,
      })
    )(estimates);
  });

export const getCampaignsByIdWithEstimates = createSelector(
  getEstimates,
  getCampaignsById,
  (estimates, campaigns) => {
    return flow(
      groupBy('campaignId'),
      toPairs,
      map(([campaignId, estimates]) =>
        flow(get(campaignId), set('estimates', orderByMediaTypeAndStatus(estimates)))(campaigns)
      ),
      orderBy(['createdAt'], ['desc'])
    )(estimates);
  }
);

export const getCampaignCostById = campaignId =>
  createSelector(getCampaignsByIdWithEstimates, getDisplayCurrency, (campaigns, displayCurrency) =>
    flow(
      campaignId ? find({ id: campaignId }) : find(({ id }) => !id),
      get('estimates'),
      map(estimate => getEstimateCost(estimate)(displayCurrency)),
      estimateCosts => ({
        lowest: sumBy('lowest', estimateCosts),
        highest: sumBy('highest', estimateCosts),
        quoteCurrency: displayCurrency,
      })
    )(campaigns)
  );

export const getCampaignBudgetById = campaignId =>
  createSelector(getCampaignById(campaignId), campaign => ({
    currency: get('budgetCurrency', campaign),
    total: get('budgetValue', campaign),
  }));

export const canBeClosed = campaignId =>
  createSelector(getCampaignById(campaignId), getEstimatesById, (campaign, allEstimates) => {
    if (!campaign || campaign.status !== CampaignStatuses.OPEN || !campaign.budgetValue) {
      return false;
    }
    if (!campaign.estimates.length) {
      return true;
    }
    const statuses = flow(
      map(({ id }) => get(id, allEstimates)),
      compact,
      map(({ status }) => status)
    )(campaign.estimates);

    return flow(
      every(status =>
        [
          EstimateStatuses.ACTUALISED,
          EstimateStatuses.APPROVED,
          EstimateStatuses.CANCELLED,
        ].includes(status)
      )
    )(statuses);
  });

export const canBeDeleted = campaignId =>
  createSelector(
    getCampaignById(campaignId),
    getEstimatesByCampaignId(campaignId),
    (campaign, estimates) => {
      if (!campaign || campaign.status !== CampaignStatuses.OPEN) {
        return false;
      }
      if (!campaign.estimates.length) {
        return true;
      }

      const nonCancelledEstimates = filter(
        ({ status }) => status !== EstimateStatuses.CANCELLED,
        estimates
      );
      return !nonCancelledEstimates.length;
    }
  );
