import { connect } from 'react-redux';
import { withProps, compose } from 'recompose';
import { map, set, flow, sumBy, reject, join, compact, sortBy, groupBy, filter } from 'lodash/fp';
import { converter, formatAsCurrency } from 'cr-core/currencyUtils';
import { getDisplayCurrency } from 'state/authentication/selectors';

const withDisplayCurrency = connect(state => ({ displayCurrency: getDisplayCurrency(state) }));

const withAlternativeCurrencies = compose(
  withDisplayCurrency,
  withProps(({ estimate, values, displayCurrency }) => {
    const convert = converter(estimate.exchangeRates);
    const valuesWithDisplayAmount = flow(
      compact,
      sortBy('currency'),
      map(value =>
        set('displayAmount', convert(value.amount, value.currency).to(displayCurrency), value),
      ),
    )(values);

    const total = sumBy('displayAmount', valuesWithDisplayAmount);

    const alternativeCurrencies = flow(
      compact,
      reject({ currency: displayCurrency }),
      groupBy('currency'),
      map(vals => ({
        currency: vals[0].currency,
        amount: sumBy('amount', vals),
      })),
      sortBy('currency'),
      filter(({ amount }) => amount),
      map(({ currency, amount }) => formatAsCurrency(currency, amount)),
      join(', '),
    )(values);

    return {
      total,
      alternativeCurrencies,
    };
  }),
);

export default withAlternativeCurrencies;
