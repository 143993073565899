import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListItemIcon,
  ListItemText,
  ListItem,
  List,
  Paper,
  Fade,
  ClickAwayListener,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import clsx from 'clsx';

const ITEM_HEIGHT = 48;

const useStyles = makeStyles(theme => ({
  icon: {
    minWidth: 20,
    marginRight: theme.spacing(1),
  },
  container: {
    position: 'relative',
  },
  menuWrapper: {
    width: 200,
    position: 'absolute',
    top: '100%',
    right: 0,
    zIndex: 1,
  },
}));

const Menu = ({ items = [], className }) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);

  if (!items.length) {
    return null;
  }

  return (
    <div className={clsx(classes.container, className)}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        data-test="dropdown-menu-button"
        onClick={() => setOpen(true)}
      >
        <MoreVertIcon />
      </IconButton>
      <Fade in={isOpen} mountOnEnter unmountOnExit>
        <div className={classes.menuWrapper}>
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Paper
              elevation={8}
              style={{
                maxHeight: ITEM_HEIGHT * 4.5,
              }}
            >
              <List id="long-menu">
                {items.map(({ onClick, label, Icon, dataTest }) => (
                  <ListItem
                    button
                    key={label}
                    data-test={dataTest}
                    onClick={() => {
                      onClick();
                      setOpen(false);
                    }}
                  >
                    {Icon && (
                      <ListItemIcon className={classes.icon}>
                        <Icon fontSize="small" />
                      </ListItemIcon>
                    )}
                    <ListItemText primary={label} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </ClickAwayListener>
        </div>
      </Fade>
    </div>
  );
};

export default Menu;
