import React, { useState } from 'react';
import clsx from 'clsx';
import { map } from 'lodash/fp';
import { withRouter, NavLink } from 'react-router-dom';
import { Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { font } from 'shared/styles';

const useStyles = makeStyles(theme => ({
  navLink: {
    fontWeight: font.weights.thin,
    fontSize: font.sizes.veryLarge,
    color: 'white',
    margin: 'auto 20px',
    textDecoration: 'none',
    paddingBottom: 5,
    borderBottom: '2px solid transparent',
    transition: '0.6s ease-out',
    cursor: 'pointer',

    '&.active, &:hover, &:focus': {
      outline: 'none',
      color: 'white',
      borderBottomColor: 'white',
    },
  },
  navLinkActive: {
    color: 'white',
    borderBottomColor: 'white',
  },

  dropdownMenu: {
    marginTop: theme.spacing(2),
  },
  dropdownPaper: {
    borderRadius: 0,
  },
  menuList: {
    background: theme.palette.primary.main,
  },
  dropdownLink: {
    color: 'white',
    fontWeight: 200,
    fontSize: theme.typography.h6.fontSize,
    width: '100%',
    '&:hover': {
      color: 'white',
    },
  },
}));

const MenuItemWithDropdown = ({ id, label, path, items, location }) => {
  const classes = useStyles();
  const [active, setActive] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setActive(true);
  };
  const handleClose = e => {
    e.stopPropagation();
    setAnchorEl(null);
    setActive(false);
  };
  return (
    <>
      <div
        className={clsx(classes.navLink, { [classes.navLinkActive]: active })}
        data-test={`header:${id}Link`}
        onClick={handleClick}
        onKeyPress={handleClick}
        role="button"
        tabIndex="0"
      >
        {label}
      </div>
      <Menu
        className={classes.dropdownMenu}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        anchorPosition={{ top: 70 }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        MenuListProps={{
          className: classes.menuList,
        }}
        classes={{ paper: classes.dropdownPaper }}
      >
        {map(
          child => (
            <MenuItem key={child.label}>
              <NavLink
                to={{ pathname: `${path}${child.path}`, search: location.search }}
                className={classes.dropdownLink}
                data-test={`header:${id}:${child.id}Link`}
                onClick={() => setAnchorEl(null)}
              >
                {child.label}
              </NavLink>
            </MenuItem>
          ),
          items
        )}
      </Menu>
    </>
  );
};

export default withRouter(MenuItemWithDropdown);
