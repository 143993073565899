import moment from 'moment';
import { sortByEnum } from '../utils';
import {
  estimateStatus,
  estimateName,
  estimateLatestEstimate,
  estimateActualised,
  estimateAverage,
  estimateBundle,
} from '../utils.columns';
import * as donwloadColumnsUtils from '../utils.downloadColumns';
import { AccountSetting } from 'cr-core/constants';
import { compact } from 'lodash/fp';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export default (data, displayCurrency, clientSettings) => {
  const columns = [
    estimateStatus(),
    {
      id: 'month',
      Header: 'Month',
      description: 'Air month of an estimate.',
      maxWidth: 200,
      accessor: ({ date }) => (date ? moment(date).format('MMMM') : 'Not Set'),
      PivotValue: ({ value }) => (value === 'Not Set' ? 'Not Set' : value),
      Aggregated: () => '',
      sortType: sortByEnum(months),
    },
    estimateName(),
    estimateLatestEstimate({ displayCurrency }),
    estimateActualised({ displayCurrency }),
    estimateAverage({ displayCurrency }),
  ];

  clientSettings[AccountSetting.EstimateBundle] && columns.splice(3, 0, estimateBundle());

  return columns;
};

export const getDownloadColumns = ({ clientSettings = {} }) =>
  compact([
    ...donwloadColumnsUtils.estimateInfo(clientSettings),
    clientSettings[AccountSetting.EstimateBundle] && donwloadColumnsUtils.estimateBundle,
    donwloadColumnsUtils.estimateHistorical,
  ]);
