import React, { useEffect } from 'react';
import clsx from 'clsx';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Typography, withWidth } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Collapsed from './collapsed';
import FiltersForm, { initialValues, defaultFilters, isFiltered } from './form';
import { stringifyFilters, parseQueryFilters, getCleanFilters } from './utils';
import { setCollapsedFilterBar } from 'state/ui/actions';
export {
  stringifyFilters,
  parseQueryFilters,
  initialValues,
  defaultFilters,
  isFiltered,
  getCleanFilters,
};

const useStyles = makeStyles(theme => {
  // console.log(theme);
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      width: 310,
      padding: 20,
      backgroundColor: 'white',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderLeft: 0,
      borderRadius: `0 0 ${theme.shape.borderRadius}px 0`,
      marginBottom: 90,
      transition: '2s ease-out',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
    },
    hideFiltersButton: {
      minWidth: 'auto',
    },
    hideFiltersButtonIcon: {
      marginRight: '-0.7rem',
      color: theme.palette.text.secondary,
    },
  };
});

const Header = ({ collapsed, setCollapsed }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Typography variant="h6" className={classes.headerTitle}>
        Filter by
      </Typography>
      <Button
        className={classes.hideFiltersButton}
        variant="outlined"
        onClick={() => setCollapsed(!collapsed)}
      >
        <ArrowBackIosIcon className={classes.hideFiltersButtonIcon} />
      </Button>
    </div>
  );
};

const FilterComponent = ({
  onChange,
  filters,
  initiallyDisplayedFilters,
  className,
  history,
  location,
  width,
  collapsedFiltersBar,
  setCollapsedFilterBar,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (width === 'xs') {
      setCollapsedFilterBar(true);
    }
  }, [width, setCollapsedFilterBar]);

  if (collapsedFiltersBar) {
    return <Collapsed collapsed={collapsedFiltersBar} setCollapsed={setCollapsedFilterBar} />;
  }

  return (
    <div
      className={clsx(classes.container, className)}
      style={collapsedFiltersBar ? { display: 'none' } : {}}
    >
      <Header collapsed={collapsedFiltersBar} setCollapsed={setCollapsedFilterBar} />
      <FiltersForm
        onChange={onChange}
        filters={filters}
        initiallyDisplayedFilters={initiallyDisplayedFilters}
        history={history}
        location={location}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  collapsedFiltersBar: state.ui.collapsedFiltersBar,
});
const mapDispatchToProps = dispatch => ({
  setCollapsedFilterBar: payload => dispatch(setCollapsedFilterBar(payload)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withWidth()
)(FilterComponent);
