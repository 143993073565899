import React, { useState } from 'react';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

interface Props {
  options: any[];
  selected: any[];
  removeOption: (option: any) => void;
  brandCategories: any;
}

const MAX_DISPLAYED = 3;

const useStyles = makeStyles({
  showMore: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

const SelectedItems = ({ selected = [], removeOption, options, brandCategories }: Props) => {
  const styles = useStyles();
  const arr = [];
  const selectedItems = [];

  if (brandCategories) {
    for (const [key, value] of Object.entries(brandCategories)) {
      const keyValues = options.filter(option => option.group === key);

      const typedValue: any = value;
      if (typedValue.length === keyValues.length) {
        selectedItems.push(key);
      }
      if (typedValue.length !== keyValues.length && typedValue.length) {
        selectedItems.push(...typedValue);
      }

      if (typedValue.length) {
        arr.push({ [key]: value });
      }
    }
  }

  const sliced = (brandCategories ? selectedItems : selected).slice(0, MAX_DISPLAYED);
  const hasMore = (brandCategories ? selectedItems : selected).length > MAX_DISPLAYED;
  const [showAll, setShowAll] = useState(hasMore);

  return (
    <Box>
      {brandCategories
        ? arr.length > 0 &&
          (arr || []).map(value => {
            const key = Object.keys(value)[0];
            const vals: any = Object.values(value)[0];

            const keyValues = options.filter(option => option.group === key);

            return keyValues.length === vals.length ? (
              <Box display="flex" alignItems="center">
                <IconButton
                  color="primary"
                  onClick={() => removeOption({ value: key, group: key })}
                >
                  <Close />
                </IconButton>
                {key ? key : null}
              </Box>
            ) : (
              (vals.length >= MAX_DISPLAYED && !showAll ? vals.slice(0, MAX_DISPLAYED) : vals).map(
                (val: any) => {
                  return (
                    <Box display="flex" alignItems="center">
                      <IconButton
                        color="primary"
                        onClick={() => removeOption({ value: val, group: key })}
                      >
                        <Close />
                      </IconButton>
                      {val ? val : null}
                    </Box>
                  );
                }
              )
            );
          })
        : ((hasMore && !showAll ? sliced : selected) || []).map(value => {
            const selectedOption = options.find(option => option.value === value);

            return (
              <Box display="flex" alignItems="center" key={value}>
                <IconButton color="primary" onClick={() => removeOption(selectedOption)}>
                  <Close />
                </IconButton>
                {selectedOption ? selectedOption.label : null}
              </Box>
            );
          })}
      {hasMore && (
        <Box ml={2}>
          {!showAll && <>+{(brandCategories ? selectedItems : selected).length - sliced.length} </>}
          <Typography
            component="span"
            className={styles.showMore}
            onClick={() => setShowAll(!showAll)}
            role="button"
            tabIndex={1}
            color="primary"
          >
            {showAll ? 'Show less' : 'Show more'}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SelectedItems;
