import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  Typography,
  // MenuItem,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
// import {
//   AdditionalDeliverableLabels,
//   AdditionalDeliverablesGroups,
//   MediaTypes,
//   EditTypes,
//   EditTypeLables,
// } from 'cr-core/constants';
import { Events, track, getEstimateData } from 'components/analytics';
import { colors } from 'shared/styles';
import AdditionalDeliverables from './edit.additionalDeliverables';
import ConfirmationModal from 'components/modals/confirmationModal';
import { compose } from 'recompose';
import withEditConfirmationDialog from 'components/estimateEdit/withEditConfirmationDialog';
import isEqual from 'react-fast-compare';
import { AccountSetting, DeliverableOriginalTypes } from 'cr-core/constants';
import { getClientSettings, getSettings } from 'state/authentication/selectors';
import { connect } from 'react-redux';

// const estimateTypeDeliverablesGroups = {
//   [MediaTypes.AV]: ['Video'],
//   [MediaTypes.TVC]: ['Video'],
//   [MediaTypes.DIGITAL_VIDEO]: ['Video'],
//   [MediaTypes.AUDIO]: ['Audio'],
//   [MediaTypes.DIGITAL]: ['Video', 'Print'],
//   [MediaTypes.PRINT]: ['Print'],
//   [MediaTypes.RIGHTS]: [],
//   [MediaTypes.MOBILE_AND_WEB_APPS]: ['Digital Content'],
//   [MediaTypes.OPERATIONAL]: [],
//   [MediaTypes.ANIMATICS]: ['Video', 'Print'],
//   [MediaTypes.SHOPPER]: [],
// };

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px',
    color: colors.text,
    backgroundColor: 'white',
    border: '1px solid lightgray',
    borderRadius: '5px',
    padding: '10px',

    paddingTop: theme.spacing(2),
  },
  flexContainer: {
    display: 'flex',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  body: {
    padding: theme.spacing(2),
  },
  group: {
    marginBottom: theme.spacing(2),
  },
  groupHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(0.5),
  },
  groupHeaderTitle: {
    lineHeight: 1,
    marginLeft: theme.spacing(1),
  },
  subGroup: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    // '&:hover': {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
  rowSelected: {
    backgroundColor: theme.palette.action.hover,
  },
  rowTitle: {
    marginLeft: theme.spacing(2),
  },
  inputLabel: {
    fontSize: theme.typography.caption.fontSize,
    width: 40 + theme.spacing(1),
    textAlign: 'center',
  },
  inputContainer: {
    width: 40,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button,&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  input: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    fontSize: theme.typography.body2.fontSize,
    textAlign: 'right',
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button,&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },

  label: {
    width: 300,
    display: 'inline-block',
  },

  select: {
    width: 280,
    marginRight: 20,
    marginTop: 0,
  },
  rootLabel: {
    transform: 'translate(14px, 10px) scale(1)',
  },

  originalsDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(0.5),
  },

  originalsRow: {
    alignItems: 'flex-start',
    marginTop: theme.spacing(0.5),
  },
  originalsTextfield: {
    padding: theme.spacing(1.25),
  },

  editsListContainer: {
    paddingLeft: theme.spacing(0.5),
  },

  alert: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },

  modalDetails: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  description: {
    ...theme.typography.body2,
  },
}));

// const Originals = ({
//   estimate,
//   originals,
//   setOriginals,
//   originalsType,
//   setOriginalsType,
//   clone,
//   setClone,
//   errors,
// }) => {
//   const classes = useStyles();
//   const availableOriginalTypes = flow(
//     map(name => find({ name }, AdditionalDeliverablesGroups)),
//     compact,
//     map('deliverables'),
//     flatten
//   )(estimateTypeDeliverablesGroups[estimate.mediaType]);
//
//   return (
//     <>
//       <Typography variant="h6">Originals</Typography>
//       <div className={clsx(classes.row, classes.originalsRow)}>
//         {Boolean(!availableOriginalTypes.length || !originals) && (
//           <div className={classes.editsListContainer}>
//             <label className={classes.label} htmlFor="orignals-input">
//               Originals
//             </label>
//           </div>
//         )}
//         {Boolean(availableOriginalTypes.length && originals) && (
//           <div className={classes.originalsDetailsContainer}>
//             <TextField
//               id="original-type-select"
//               select
//               label="Select Original Type"
//               variant="outlined"
//               margin="dense"
//               name="originalsType"
//               className={classes.select}
//               FormLabelProps={{ root: classes.rootLabel }}
//               value={originalsType}
//               onChange={e => setOriginalsType(e.target.value)}
//               error={errors.originalsType}
//               helperText={errors.originalsType}
//             >
//               {map(
//                 deliverable => (
//                   <MenuItem value={deliverable}>
//                     {AdditionalDeliverableLabels[deliverable]}
//                   </MenuItem>
//                 ),
//                 availableOriginalTypes
//               )}
//             </TextField>
//
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={clone}
//                   onChange={e => setClone(e.target.checked)}
//                   name="clone"
//                   color="primary"
//                 />
//               }
//               label="Clone"
//             />
//           </div>
//         )}
//         <TextField
//           className={classes.inputContainer}
//           inputProps={{ className: clsx(classes.input, classes.originalsTextfield) }}
//           id="orignals-input"
//           name="originals"
//           type="number"
//           min={0}
//           max={999}
//           variant="outlined"
//           size="small"
//           value={originals}
//           onChange={e => setOriginals(parseInt(e.target.value, 10))}
//         />
//       </div>
//     </>
//   );
// };

const mapStateToProps = state => ({
  clientSettings: getClientSettings(state),
  settings: getSettings(state),
});

const Originals = ({
  estimate,
  originals,
  setOriginals,
  originalsType,
  setOriginalsType,
  clone,
  setClone,
  setOriginalType,
  errors,
  clientSettings,
  settings,
  setErrors,
}) => {
  const classes = useStyles();

  const handleOriginalTypeChange = event => {
    setOriginalType(event.target.value);
    setErrors({});
  };
  const handleOriginalsChange = event => {
    setOriginals(parseInt(event.target.value, 10));
    setErrors({});
  };
  return (
    <>
      <Typography variant="h6">Originals</Typography>
      <div className={classes.description}>
        Deliverables created from scratch are considered as originals. This can also be clone
        originals or elements shot to incorporate in a previous film if a shoot is involved.
      </div>
      <div className={clsx(classes.row, classes.originalsRow)}>
        <div className={classes.editsListContainer}>
          <label className={classes.label} htmlFor="orignals-input">
            Originals
          </label>
          {Boolean(originals) && (
            <div>
              {clientSettings[AccountSetting.DeliverablesOriginalTypes] ||
              settings?.[estimate.campaign.workspace.accountId]?.[
                AccountSetting.DeliverablesOriginalTypes
              ] ? (
                <RadioGroup
                  onChange={handleOriginalTypeChange}
                  defaultValue={estimate.deliverableOriginalsType}
                >
                  {Object.entries(DeliverableOriginalTypes).map(([key, val]) => (
                    <FormControlLabel
                      key={key}
                      value={key}
                      className={classes.radio}
                      control={<Radio color="primary" />}
                      label={val}
                    />
                  ))}
                </RadioGroup>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={clone}
                      onChange={e => setClone(e.target.checked)}
                      name="clone"
                      color="primary"
                    />
                  }
                  label="Clone"
                />
              )}
            </div>
          )}
        </div>

        <TextField
          className={classes.inputContainer}
          inputProps={{ className: clsx(classes.input, classes.originalsTextfield) }}
          id="orignals-input"
          name="originals"
          type="number"
          min={0}
          max={999}
          variant="outlined"
          size="small"
          value={originals}
          onChange={handleOriginalsChange}
        />
      </div>
    </>
  );
};

// const Edits = ({ edits, setEdits }) => {
//   const classes = useStyles();
//   return (
//     <>
//       <Typography variant="h6">Edits</Typography>
//       <div className={classes.editsListContainer}>
//         {map(
//           type => (
//             <div className={classes.row} key={type}>
//               <label className={classes.label}>{EditTypeLables[type]}</label>
//               <TextField
//                 className={classes.inputContainer}
//                 inputProps={{ className: classes.input }}
//                 name={type.toLowerCase()}
//                 id={`${type.toLowerCase()}-input`}
//                 type="number"
//                 variant="outlined"
//                 size="small"
//                 value={getOr('', type, edits)}
//                 onChange={setEdits(type)}
//               />
//             </div>
//           ),
//           keys(EditTypes)
//         )}
//       </div>
//     </>
//   );
// };

const Edits = ({ edits, setEdits, setErrors }) => {
  const classes = useStyles();
  const handleEditsChange = event => {
    setEdits(parseInt(event.target.value, 10));
    setErrors({});
  };

  return (
    <>
      <Typography variant="h6">Edits</Typography>
      <div className={classes.description}>
        Deliverables like revisions, adaptations and cut-down versions are considered as Edits.
      </div>
      <div className={classes.editsListContainer}>
        <div className={classes.row}>
          <label className={classes.label} htmlFor="edits-input">
            Edits
          </label>
          <TextField
            className={classes.inputContainer}
            inputProps={{ className: classes.input }}
            name="edits"
            id="edits-input"
            type="number"
            variant="outlined"
            size="small"
            value={edits}
            onChange={handleEditsChange}
          />
        </div>
      </div>
    </>
  );
};

export default compose(
  connect(mapStateToProps),
  withEditConfirmationDialog('onSave', { notifyOnly: true })
)(({ estimate, clientSettings, settings, onSave }) => {
  const classes = useStyles();
  const [originals, setOriginals] = useState(estimate.numberOfTVCs || 0);
  // const [originalsType, setOriginalsType] = useState(estimate.originalsType || '');
  const [clone, setClone] = useState(estimate.clone || false);
  const [deliverableOriginalsType, setOriginalType] = useState(
    estimate.deliverableOriginalsType || false
  );
  // const [editsObj, setEditsObj] = useState(estimate.edits || {});
  const [edits, setEdits] = useState(estimate.numberOfRATVs || 0);
  const [errors, setErrors] = useState({});
  const [additionalDeliverablesObj, setDeliverableObj] = useState(
    estimate.additionalDeliverables || {}
  );
  const [openModal, setOpenModal] = useState(false);

  const prevData = useMemo(
    () => ({
      originals: estimate.numberOfTVCs || 0,
      clone: estimate.clone || false,
      edits: estimate.numberOfRATVs || 0,
      additionalDeliverablesObj: estimate.additionalDeliverables || {},
      deliverableOriginalsType: estimate.deliverableOriginalsType || null,
    }),
    [estimate]
  );

  const isDirty = useMemo(
    () =>
      !isEqual(prevData, {
        originals,
        clone,
        edits,
        additionalDeliverablesObj,
        deliverableOriginalsType,
      }),
    [prevData, originals, clone, edits, additionalDeliverablesObj, deliverableOriginalsType]
  );

  const setDeliverable = type => e => {
    setDeliverableObj({
      ...additionalDeliverablesObj,
      [type]: parseInt(e.target.value, 10),
    });
  };

  // const setEdits = type => e => {
  //   setEditsObj({
  //     ...editsObj,
  //     [type]: parseInt(e.target.value, 10),
  //   });
  // };

  const validate = () => {
    const newErrors = {};
    // if (originals && !originalsType) {
    //   newErrors.originalsType = 'Original type is required when the estimate has originals';
    // }
    // if (!(originals + flow(values, sum)(editsObj))) {
    //   newErrors.generic = 'You have to specify at least originals or edits';
    // }
    const deliverables = (originals || 0) + (edits || 0);

    if (!deliverables) {
      newErrors.generic = 'You have to specify at least originals or edits';
    }

    const originalTypesSettingEnabled =
      clientSettings[AccountSetting.DeliverablesOriginalTypes] ||
      settings?.[estimate.campaign.workspace.accountId]?.[AccountSetting.DeliverablesOriginalTypes];

    if (originalTypesSettingEnabled && originals && originals !== 0 && !deliverableOriginalsType) {
      newErrors.generic = 'Please specify the original type in order to save deliverables';
    }

    setErrors(newErrors);
    if (isEmpty(newErrors)) {
      track(
        Events.ESTIMATE_DELIVERABLES_SAVE_ERROR,
        getEstimateData({ ...estimate, ...getData(), errors: newErrors })
      );
    }
    return isEmpty(newErrors);
  };

  const getData = () => ({
    numberOfOriginals: originals || 0,
    numberOfEdits: edits || 0,
    // originalsType,
    // edits: editsObj,
    additionalDeliverables: additionalDeliverablesObj,
    clone,
    deliverableOriginalsType,
  });

  const save = () => {
    const data = getData();
    onSave(data);
    track(Events.ESTIMATE_DELIVERABLES_SAVE_SUCCESS, getEstimateData({ ...estimate, ...data }));
  };

  const onSaveClick = e => {
    e.preventDefault();

    if (!isDirty) {
      return;
    }

    track(Events.ESTIMATE_DELIVERABLES_SAVE_CLICK, getEstimateData({ ...estimate, ...getData() }));

    if (validate()) {
      // if (estimate.numberOfRATVs && estimate.numberOfRATVs !== flow(values, sum)(editsObj)) {
      //   // show confirmation
      //   setOpenModal(true);
      // } else {
      save();
      // }
    }
  };

  const handleCloseModal = confirm => {
    setOpenModal(false);
    if (confirm) {
      save();
    }
  };

  return (
    <div className={classes.container}>
      <form className={classes.listContainer} noValidate autoComplete="off">
        <div className={classes.flexContainer}>
          <div>
            <Originals
              estimate={estimate}
              originals={originals}
              setOriginals={setOriginals}
              clone={clone}
              setClone={setClone}
              setOriginalType={setOriginalType}
              errors={errors}
              clientSettings={clientSettings}
              settings={settings}
              setErrors={setErrors}
            />
            <Edits edits={edits} setEdits={setEdits} setErrors={setErrors} />
          </div>
          <div>
            {errors.generic && (
              <Alert severity="error" className={classes.alert} data-test="error-message">
                {errors.generic}
              </Alert>
            )}
          </div>
        </div>

        <AdditionalDeliverables
          additionalDeliverablesObj={additionalDeliverablesObj}
          setDeliverable={setDeliverable}
        />
      </form>
      <div className={classes.actionsContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onSaveClick}
          disabled={!isDirty}
          data-test="save-deliverables"
        >
          Save
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          component={Link}
          to={`/estimates/${estimate.id}/deliverables`}
          onClick={() => track(Events.ESTIMATE_DELIVERABLES_CANCEL, getEstimateData(estimate))}
          data-test="cancel-deliverables"
        >
          Cancel
        </Button>
      </div>

      <ConfirmationModal
        open={openModal}
        handleClose={handleCloseModal}
        actionLabel="Confirm"
        title="Confirm number of Edits"
        message={
          <div>
            The new number of edits specified differs from the number of edits previously specified
            <br />
            Do you wan't to confirm the new number of edits?
            <div className={classes.modalDetails}>
              Previous <i>Number of Edits</i>: {estimate.numberOfRATVs}
              <br />
              Now <i>Edits</i>: {edits}
              {/*flow(values, sum)(editsObj)*/}
            </div>
          </div>
        }
      />
    </div>
  );
});
