import React from 'react';
import styled from 'styled-components';
import Modal from 'components/modal';
import { Button } from 'components/buttons';
import { font, colors } from 'shared/styles';

// Define vars we'll be using
const color = colors.error;
const loaderSize = 7;
const signFontSize = 4;

const SubmitForApprovalModal = styled(Modal)`
  .modalWindow {
    width: 400px;
  }

  .header {
    background: white;
    text-shadow: none;
    line-height: 0;
  }

  .footer {
    border: none;
    padding-bottom: 30px;
  }

  .textContainer {
    text-align: center;
  }

  .circle-container {
    margin: 0 auto;
    display: flex;
    padding-bottom: 30px;
  }

  .circle {
    border: 2px solid ${color};
    position: relative;
    vertical-align: top;
    border-radius: 50%;
    width: ${loaderSize}em;
    height: ${loaderSize}em;
    margin: 0 auto;
    border-color: ${color};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sign {
    font-size: ${signFontSize}em;
    color: ${color};
  }
`;

const ModalBody = styled.div`
  .title {
    margin-bottom: 20px;
  }
`;

const CloseButton = styled(Button)`
  margin: 0 auto;
  background: ${colors.cancel};
  border: 0;
  padding: 0 30px;

  :hover {
    background-color: ${colors.cancelHover};
  }
`;

const TextHeader = styled.div`
  font-weight: 600;
  margin-bottom: 5px;
`;

const TextDescription = styled.div`
  font-weight: 200;
  font-size: ${font.sizes.largish};
`;

const WarningModal = ({
  onRequestClose,
  addApprovers,
  completed,
  showCompleted,
  header,
  description,
  ...restProps
}) => {
  const buttons = <CloseButton onClick={onRequestClose}>Close</CloseButton>;
  return (
    <SubmitForApprovalModal buttons={buttons} {...restProps} onRequestClose={onRequestClose}>
      <ModalBody>
        <div className="circle-container">
          <div className="circle">
            <div className="sign">!</div>
          </div>
        </div>
        <div className="textContainer">
          <TextHeader>{header || 'An error occured'}</TextHeader>
          <TextDescription>{description}</TextDescription>
        </div>
      </ModalBody>
    </SubmitForApprovalModal>
  );
};

export default WarningModal;
