import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import '@nivo/colors';

export default ({ data, tooltip, indexBy, keys, ...props }) => {
  return (
    <ResponsiveBar
      data={data}
      indexBy={indexBy}
      keys={keys}
      colors={{ scheme: 'nivo' }}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      layout={'horizontal'}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      enableLabel={false}
      margin={{ bottom: 30, left: 40 + 65, right: 10, top: 5 }}
      padding={0.3}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      tooltip={tooltip}
      {...props}
    />
  );
};
