import React from 'react';
import { ActionBar } from 'components/actionBar';
import CreateEstimateButton from 'components/buttons/estimateCreate';

export default () => {
  return (
    <ActionBar>
      <CreateEstimateButton />
    </ActionBar>
  );
};
