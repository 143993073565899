import { isEmpty } from 'lodash/fp';

const hasEmptyValues = (obj: object) => {
  for (const value of Object.values(obj)) {
    if (!isEmpty(value)) {
      return false;
    }
  }

  return true;
};

export default hasEmptyValues;
