var _toConsumableArray = require("/home/circleci/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

var wait = function wait(seconds) {
  return new Promise(function (resolve) {
    return setTimeout(resolve, seconds * 1000);
  });
};

var waitMs = function waitMs(ms) {
  return new Promise(function (resolve) {
    return setTimeout(resolve, ms);
  });
};

var someIncludeSubstring = function someIncludeSubstring(collection, subString) {
  return collection.some(function (value) {
    return value.includes(subString);
  });
};

var allIncludeSubstring = function allIncludeSubstring(collection, subString) {
  return collection.every(function (value) {
    return value.includes(subString);
  });
};

var move = function move(fromIndex, toIndex) {
  return function (array) {
    var arr = _toConsumableArray(array);

    arr.splice(toIndex, 0, arr.splice(fromIndex, 1)[0]);
    return arr;
  };
};

module.exports = {
  wait: wait,
  waitMs: waitMs,
  someIncludeSubstring: someIncludeSubstring,
  allIncludeSubstring: allIncludeSubstring,
  move: move
};