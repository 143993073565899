import React from 'react';

const SvgCalendarIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="calendar-icon_svg__a"
        width="136.4%"
        height="133.3%"
        x="-18.2%"
        y="-16.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      d="M19.8 2.182c1.21 0 2.2.982 2.2 2.182v17.454c0 1.2-.99 2.182-2.2 2.182H2.2C.99 24 0 23.018 0 21.818V4.364c0-1.2.99-2.182 2.2-2.182h1.1V0h2.2v2.182h11V0h2.2v2.182h1.1zm0 19.636V7.636H2.2v14.182h17.6z"
      filter="url(#calendar-icon_svg__a)"
      transform="translate(4 3)"
    />
  </svg>
);

export default SvgCalendarIcon;
