import { createStaticRanges } from 'react-date-range';
import {
  startOfYear,
  endOfYear,
  addYears,
  startOfQuarter,
  endOfQuarter,
  addQuarters,
  startOfMonth,
  endOfMonth,
  addMonths,
} from 'date-fns';

const staticRanges = createStaticRanges([
  {
    label: 'This Month',
    range: () => ({
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
    }),
  },
  {
    label: 'Last Month',
    range: () => ({
      startDate: startOfMonth(addMonths(new Date(), -1)),
      endDate: endOfMonth(addMonths(new Date(), -1)),
    }),
  },
  {
    label: 'This Quarter',
    range: () => ({
      startDate: startOfQuarter(new Date()),
      endDate: endOfQuarter(new Date()),
    }),
  },
  {
    label: 'Last Quarter',
    range: () => ({
      startDate: startOfQuarter(addQuarters(new Date(), -1)),
      endDate: endOfQuarter(addQuarters(new Date(), -1)),
    }),
  },
  {
    label: 'This Year',
    range: () => ({
      startDate: startOfYear(new Date()),
      endDate: endOfYear(new Date()),
    }),
  },
  {
    label: 'Last Year',
    range: () => ({
      startDate: startOfYear(addYears(new Date(), -1)),
      endDate: endOfYear(addYears(new Date(), -1)),
    }),
  },
  {
    label: 'Next Year',
    range: () => ({
      startDate: startOfYear(addYears(new Date(), 1)),
      endDate: endOfYear(addYears(new Date(), 1)),
    }),
  },
]);

export default staticRanges;
