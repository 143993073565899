import uuid from 'uuid/v4';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose, sumBy, getOr } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Button,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { EstimateStatuses } from 'cr-core/constants';
import { updatedCurrencies } from 'cr-core/currencies';
import { createConverter, formatAsCurrency } from 'cr-core/currencyUtils';
import { Events, track, getEstimateData } from 'components/analytics';
import FilePicker from 'components/filePicker';
import CloseActualisationModal from 'components/modals/closeActualisation';
import { colors } from 'shared/styles';
import { getDisplayCurrency, getUser } from 'state/authentication/selectors';
import {
  createEstimateInvoice,
  fetchEstimateInvoices,
  deleteEstimateInvoice,
} from 'state/estimateInvoices/actions';
import { getEstimateInvoices } from 'state/estimateInvoices/selectors';
import { getEstimateById, getCosts } from 'state/estimates/selectors';
import { isResourceLoading } from 'state/resourceStatus/selectors';
import { getDownloadUrl } from 'state/files/selectors';

const analyticsInvoiceData = invoice => ({
  invoiceAmount: invoice.amount,
  invoicePO: invoice.poCode,
  invoiceNumber: invoice.code,
  invoiceFile: Boolean(invoice.file),
});

const useStyles = makeStyles(theme => {
  return {
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: '20px',
      color: colors.text,
      backgroundColor: 'white',
      border: '1px solid lightgray',
      borderRadius: '5px',
      padding: '10px',
    },
    currencySelector: {
      marginTop: '1px',
    },
    actions: {
      width: 42,
    },
    file: {
      textDecoration: 'underline',
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
    },
    totals: {
      display: 'flex',
      flexDirection: 'row',
    },
    totalLine: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),

      fontWeight: 'bold',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    cancelButton: {
      marginLeft: theme.spacing(2),
    },
    overbudget: {
      color: theme.palette.error.main,
    },
    savings: {
      color: theme.palette.success.main,
    },
  };
});

const ActualisationTable = ({
  estimate,
  invoices,
  isLoading,
  displayCurrency,
  fetchInvoices,
  saveInvoice,
  deleteInvoice,
  closeActualisation,
  user,
}) => {
  const classes = useStyles();
  const converter = createConverter(estimate.exchangeRates, displayCurrency);
  const actualToDate = sumBy(({ amount, currency }) => converter(amount, currency), invoices);
  const estimateTotal = getOr(0, 'recommendedBidTotal', estimate);
  const toBeActualised = estimateTotal - actualToDate;
  const [newInvoice, setNewInvoice] = useState();
  const [shouldShowModal, showModal] = useState();
  const setNewInvoiceField = field => e =>
    setNewInvoice({ ...newInvoice, [field]: e.target.value });
  const [pageId] = useState(uuid());
  const isOverbudget = estimate.status === EstimateStatuses.ACTUALISED && toBeActualised < 0;
  const isSavings = estimate.status === EstimateStatuses.ACTUALISED && toBeActualised >= 0;

  const addInvoice = () => {
    setNewInvoice({ currency: displayCurrency });
    track(Events.ESTIMATE_ADD_INVOICE_CLICK, getEstimateData(estimate));
  };

  const cancelInvoice = () => {
    track(Events.ESTIMATE_CANCEL_INVOICE_CLICK, {
      ...getEstimateData(estimate),
      ...analyticsInvoiceData(newInvoice),
    });
    setNewInvoice();
  };

  const onSaveInvoice = async () => {
    if (!newInvoice.amount) {
      return;
    }
    track(Events.ESTIMATE_SAVE_INVOICE_CLICK, {
      ...getEstimateData(estimate),
      ...analyticsInvoiceData(newInvoice),
    });
    await saveInvoice(estimate.id, newInvoice, pageId);
    setNewInvoice(null);
  };

  const onDeleteInvoiceClick = (estimate, row) => () => {
    deleteInvoice(estimate.id, row.id, pageId);
    track(Events.ESTIMATE_DELETE_INVOICE_CLICK, {
      ...getEstimateData(estimate),
      ...analyticsInvoiceData(row),
    });
  };

  const closeActualisationWithConfirmation = () => {
    setNewInvoice(null);
    showModal(true);
    const { estimated, actualised } = getCosts(estimate, displayCurrency);
    const savings = estimated ? estimated - actualised : null;
    track(Events.ESTIMATE_CLOSE_ACTUALISATION_CLICK, {
      ...getEstimateData(estimate),
      invoicesCount: invoices.length,
      savings,
    });
  };

  const onRequestClose = () => showModal(false);

  useEffect(() => {
    if (!isLoading && estimate) {
      fetchInvoices(estimate.id);
    }
  }, [estimate]); // eslint-disable-line react-hooks/exhaustive-deps

  const addFile = file => setNewInvoice({ ...newInvoice, file });

  return (
    <div className={classes.container}>
      <form className={classes.root} noValidate autoComplete="off">
        <Table className={classes.table} aria-label="simple table" data-test="invoices-table">
          <TableHead>
            <TableRow>
              <TableCell>Invoice #</TableCell>
              <TableCell>PO #</TableCell>
              <TableCell>Document</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell className={classes.actions}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.code || '-'}</TableCell>
                <TableCell>{row.poCode || '-'}</TableCell>
                <TableCell>
                  {row.file ? (
                    <a
                      href={`${getDownloadUrl(row.fileId, user.client.id)}`}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.file}
                      onClick={() => {
                        track(Events.ESTIMATE_DOWNLOAD_INVOICE_CLICK, {
                          ...getEstimateData(estimate),
                          ...analyticsInvoiceData(row),
                        });
                      }}
                    >
                      {row.file.filename}
                    </a>
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell align="right">{formatAsCurrency(row.currency, row.amount, 2)}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="delete user"
                    className={clsx(classes.margin, classes.errorHover)}
                    onClick={onDeleteInvoiceClick(estimate, row)}
                    data-test="remove-invoice"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {newInvoice && (
              <TableRow>
                <TableCell>
                  <TextField
                    name="code"
                    placeholder="invoice #"
                    value={newInvoice.code || ''}
                    onChange={setNewInvoiceField('code')}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    name="poCode"
                    placeholder="PO #"
                    value={newInvoice.poCode || ''}
                    onChange={setNewInvoiceField('poCode')}
                  />
                </TableCell>
                <TableCell>
                  {newInvoice.file && newInvoice.file.filename}
                  {!newInvoice.file && <FilePicker estimateId={estimate.id} onFinish={addFile} />}
                </TableCell>
                <TableCell align="right">
                  <TextField
                    id="select-currency"
                    select
                    name="currency"
                    className={classes.currencySelector}
                    value={newInvoice.currency}
                    onChange={setNewInvoiceField('currency')}
                  >
                    {updatedCurrencies.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    name="amount"
                    placeholder="Amount"
                    type="number"
                    value={newInvoice.amount || ''}
                    onChange={setNewInvoiceField('amount')}
                    onKeyPress={({ key }) => key === 'Enter' && onSaveInvoice()}
                  />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </form>
      <div className={classes.footer}>
        <div className={classes.buttonsContainer}>
          {newInvoice && (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={onSaveInvoice}
                data-test="save-invoice"
              >
                Save Invoice
              </Button>
              <Button
                variant="contained"
                onClick={cancelInvoice}
                data-test="cancel-new-invoice"
                className={classes.cancelButton}
              >
                Cancel
              </Button>
            </div>
          )}
          {!newInvoice && (
            <Button variant="contained" onClick={addInvoice} data-test="add-invoice">
              Add Invoice
            </Button>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={closeActualisationWithConfirmation}
            disabled={estimate.status !== EstimateStatuses.APPROVED || !Boolean(invoices.length)}
            data-test="close-actualisation"
          >
            Close Actualisation
          </Button>
        </div>
        <div className={classes.totals}>
          <div>
            <div className={classes.totalLine}>Actual to Date</div>
            <div className={classes.totalLine}>Estimate total</div>
            <div
              className={clsx(classes.totalLine, {
                [classes.overbudget]: isOverbudget,
                [classes.savings]: isSavings,
              })}
            >
              {!isOverbudget && !isSavings && 'To be actualised'}
            </div>
          </div>
          <div>
            <div align="right" className={classes.totalLine} data-test="actual-to-date">
              {formatAsCurrency(displayCurrency, actualToDate)}
            </div>
            <div align="right" className={classes.totalLine} data-test="estimate-total">
              {formatAsCurrency(displayCurrency, estimateTotal)}
            </div>
            <div
              align="right"
              className={clsx(classes.totalLine, {
                [classes.overbudget]: isOverbudget,
                [classes.savings]: isSavings,
              })}
              data-test="to-be-actualised"
            >
              {formatAsCurrency(
                displayCurrency,
                estimate.status === EstimateStatuses.ACTUALISED
                  ? Math.abs(toBeActualised)
                  : toBeActualised
              )}
            </div>
          </div>
        </div>
      </div>
      <CloseActualisationModal
        isOpen={shouldShowModal}
        onRequestClose={onRequestClose}
        estimateId={estimate.id}
        pageId={pageId}
      />
    </div>
  );
};

const mapStateToProps = (state, { match }) => ({
  estimate: getEstimateById(match.params.id)(state),
  invoices: getEstimateInvoices(state),
  isLoading: isResourceLoading('estimateInvoices')(state),
  displayCurrency: getDisplayCurrency(state),
  user: getUser(state),
});

const mapDispatchToProps = (dispatch, state) => ({
  fetchInvoices: estimateId => dispatch(fetchEstimateInvoices(estimateId)),
  saveInvoice: (estimateId, data, pageId) => {
    data.amount = parseFloat(data.amount);
    dispatch(createEstimateInvoice(estimateId, data, pageId));
  },
  deleteInvoice: (estimateId, invoiceId, pageId) =>
    dispatch(deleteEstimateInvoice(estimateId, invoiceId, pageId)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(ActualisationTable);
