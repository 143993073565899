var _require = require('lodash/fp'),
    trim = _require.trim,
    flow = _require.flow,
    split = _require.split,
    map = _require.map,
    join = _require.join,
    first = _require.first,
    get = _require.get;

var getUserName = function getUserName(user) {
  var firstName = get('firstName', user);
  var lastName = get('lastName', user);

  if (firstName || lastName) {
    return trim("".concat(firstName, " ").concat(lastName));
  }

  return null;
};

var formatUserName = function formatUserName(user) {
  var showEmail = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

  if (!user) {
    return null;
  }

  var name = getUserName(user);
  return name ? "".concat(name, " ").concat(showEmail ? "(".concat(user.email, ")") : '') : user.email;
};

var getFirstNameOrEmail = function getFirstNameOrEmail(user) {
  return user.firstName || user.email;
};

var getUserInitialsFromName = function getUserInitialsFromName(name) {
  return flow(split(' '), map(first), join(''))(name);
};

var getInitialsForUser = flow(getUserName, getUserInitialsFromName);
var getUserPicture = get('picture');
module.exports = {
  getUserName: getUserName,
  formatUserName: formatUserName,
  getUserInitialsFromName: getUserInitialsFromName,
  getInitialsForUser: getInitialsForUser,
  getUserPicture: getUserPicture,
  getFirstNameOrEmail: getFirstNameOrEmail
};