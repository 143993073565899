import React from 'react';

const SvgIntegratedIcon = (props: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M4125 5005 c-23 -22 -25 -31 -25 -125 l0 -100 -1800 0 c-1922 0
-1846 2 -1864 -45 -3 -9 -6 -68 -6 -130 0 -96 3 -116 19 -136 l19 -24 1814 -5
1813 -5 3 -102 c3 -101 3 -102 34 -128 26 -22 39 -26 66 -21 19 3 42 11 51 19
9 8 93 76 186 152 232 188 255 211 255 252 0 44 -17 60 -259 257 -186 152
-206 166 -242 166 -29 0 -47 -7 -64 -25z"
        />
        <path
          d="M2590 3927 c-157 -46 -298 -91 -315 -99 -36 -20 -91 -76 -270 -278
-167 -187 -175 -200 -175 -266 0 -92 71 -164 162 -164 61 0 54 -4 382 202 149
93 289 179 311 191 59 30 175 35 240 9 52 -20 1244 -743 1305 -791 19 -15 49
-51 65 -80 30 -51 30 -52 70 -46 22 3 43 9 46 13 4 4 -22 219 -57 477 -35 259
-64 476 -64 484 0 11 -40 13 -247 7 -196 -6 -259 -4 -299 7 -30 8 -196 96
-397 211 -334 191 -348 198 -409 202 -56 4 -95 -5 -348 -79z"
        />
        <path
          d="M1935 3938 c-42 -15 -70 -36 -230 -169 -222 -185 -236 -189 -622
-189 -139 0 -253 -1 -253 -2 0 -2 -29 -223 -65 -492 -36 -269 -63 -491 -61
-494 3 -2 51 -33 108 -68 l103 -65 15 23 c78 115 115 159 157 187 102 68 227
78 337 28 60 -28 136 -98 155 -145 l12 -28 62 30 c55 28 71 31 152 31 78 0 97
-4 146 -28 118 -58 182 -159 186 -297 l2 -85 50 -7 c71 -11 135 -43 186 -94
53 -53 82 -112 96 -193 l11 -61 54 0 c156 0 299 -131 319 -294 9 -68 -15 -163
-56 -228 -17 -26 -28 -51 -25 -56 15 -24 191 -125 226 -129 117 -16 220 96
190 207 -14 51 -39 79 -125 137 -38 27 -76 60 -84 74 -28 51 12 119 72 119 24
0 63 -21 152 -81 171 -114 187 -122 255 -117 69 5 115 35 141 92 24 52 23 90
0 143 -17 37 -43 57 -214 172 -107 71 -197 137 -201 146 -15 39 -6 83 20 104
50 39 67 31 338 -149 154 -102 270 -172 291 -176 108 -20 205 55 205 158 0 94
-11 105 -288 290 -260 174 -279 191 -266 244 8 32 50 64 85 64 18 0 85 -38
203 -116 151 -100 183 -118 224 -121 135 -12 226 133 159 252 -24 42 -37 51
-537 355 -124 75 -342 208 -485 295 -227 138 -266 159 -310 163 -50 4 -50 4
-350 -184 -379 -237 -381 -238 -493 -232 -64 3 -99 10 -132 27 -61 31 -126 95
-158 156 -24 45 -27 61 -27 150 0 89 3 105 27 150 25 47 248 306 380 441 57
58 58 62 36 67 -36 10 -139 7 -173 -5z"
        />
        <path
          d="M199 3641 l-29 -29 0 -497 0 -497 29 -29 29 -29 146 0 c143 0 146 0
151 23 2 12 34 245 70 517 36 272 68 512 71 533 l6 37 -222 0 -222 0 -29 -29z"
        />
        <path
          d="M4454 3633 c3 -21 35 -261 71 -533 36 -272 68 -505 70 -517 5 -23 8
-23 153 -23 153 1 168 4 190 47 9 16 12 159 12 513 l0 492 -29 29 -29 29 -222
0 -222 0 6 -37z"
        />
        <path
          d="M1202 2538 c-39 -19 -60 -45 -180 -227 -74 -113 -138 -220 -142 -238
-10 -41 2 -98 27 -132 24 -33 98 -71 138 -71 17 0 51 10 75 23 40 19 61 46
182 227 76 113 141 219 144 237 10 51 -4 100 -39 140 -57 65 -129 79 -205 41z"
        />
        <path
          d="M1717 2400 c-31 -17 -71 -71 -217 -290 -98 -148 -184 -287 -191 -310
-24 -80 33 -176 119 -199 53 -15 118 1 157 37 16 14 107 146 203 292 193 292
203 317 167 394 -41 85 -154 121 -238 76z"
        />
        <path
          d="M2065 1988 c-43 -24 -73 -60 -196 -245 -77 -117 -131 -207 -135 -229
-8 -42 8 -101 36 -136 54 -70 181 -78 247 -16 37 35 275 405 282 441 9 36 -6
102 -29 134 -40 54 -150 82 -205 51z"
        />
        <path
          d="M2468 1647 c-53 -17 -78 -42 -156 -159 -91 -136 -102 -159 -102 -213
0 -107 94 -181 204 -161 52 10 89 46 168 164 113 168 125 211 82 294 -31 61
-126 97 -196 75z"
        />
        <path
          d="M895 930 c-19 -8 -367 -291 -432 -351 -33 -30 -42 -65 -27 -104 4 -9
108 -99 231 -200 208 -171 228 -185 264 -185 29 0 47 7 64 25 23 22 25 31 25
125 l0 100 1800 0 c1922 0 1846 -2 1864 45 3 9 6 68 6 130 0 96 -3 116 -19
136 l-19 24 -1814 5 -1813 5 -3 102 c-3 100 -3 102 -33 127 -31 27 -59 31 -94
16z"
        />
      </g>
    </svg>
  );
};

export default SvgIntegratedIcon;
