import React from 'react';
import { map } from 'lodash/fp';
import { ResponsivePie } from '@nivo/pie';
import '@nivo/colors';

export default ({ data, tooltip, ...props }) => {
  return (
    <ResponsivePie
      data={map(({ value, label, ...data }) => ({ value, label, id: label, ...data }), data)}
      innerRadius={0.5}
      padAngle={0}
      colors={{ scheme: 'nivo' }}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      enableRadialLabels={false}
      enableSlicesLabels={false}
      tooltip={tooltip}
      theme={{ tooltip: { padding: 0 } }}
      {...props}
    />
  );
};
