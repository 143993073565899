import { Field, FieldArray } from 'redux-form';
import React from 'react';
import styled from 'styled-components';
import { FieldContainer, Label, SubLabel } from 'components/forms';
import { SmallFormButton } from 'components/buttons';
import SvgTrashIcon from 'icons/TrashIcon';
import { colors, font, inputHeight } from 'shared/styles';

const Optional = styled(({ className }) => <span className={className}>Optional</span>)`
  color: ${colors.placeholder};
  font-style: italic;
  font-size: ${font.sizes.largish};

  &.withBtn {
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ScriptTitlesContainer = styled(FieldContainer)`
  align-items: flex-start;

  .labelCotainer {
    margin-top: 7px;
  }
`;

const ScriptTitlesList = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScriptTitleItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  input {
    width: 100%;
  }
`;

const AddScriptTitleButton = styled(props => (
  <SmallFormButton {...props}>+ Add Script Title</SmallFormButton>
))`
  width: auto;
  height: ${inputHeight};
  margin: 0;
  margin-right: 12px;
`;

const RemoveScriptTitleButton = styled(props => (
  <div {...props}>
    <SvgTrashIcon />
  </div>
))`
  cursor: pointer;
  padding: 10px;
  font-size: ${font.sizes.veryLarge};
  opacity: 0.5;
  transition: 0.4s color ease-out;

  &:hover {
    opacity: 1;
    color: ${colors.alertHover};
  }
`;

export default () => {
  const renderTitles = ({ fields, meta: { error } }) => {
    return (
      <div>
        <ScriptTitlesList>
          {fields.map((scriptTitle, index) => {
            return (
              <ScriptTitleItem key={index}>
                <Field
                  data-test="script-title-input"
                  name={scriptTitle}
                  type="text"
                  component="input"
                  label={`Script Title #${index + 1}`}
                  value={scriptTitle}
                  className="textInput"
                />
                <RemoveScriptTitleButton
                  title="Remove Script Title"
                  onClick={() => fields.remove(index)}
                >
                  -
                </RemoveScriptTitleButton>
                <Optional />
              </ScriptTitleItem>
            );
          })}
          {error && <div className="error">{error}</div>}
        </ScriptTitlesList>
        <ButtonContainer>
          <AddScriptTitleButton onClick={() => fields.push()} data-test="add-script-title-btn" />
          {!fields.length && <Optional />}
        </ButtonContainer>
      </div>
    );
  };
  return (
    <ScriptTitlesContainer>
      <Label className="labelCotainer" htmlFor="scriptTitles">
        Script Title(s)
        <SubLabel>What are the Script Titles for this shoot?</SubLabel>
      </Label>
      <FieldArray name="scriptTitles" component={renderTitles} />
    </ScriptTitlesContainer>
  );
};
