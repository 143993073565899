import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose, get } from 'lodash/fp';
import onClickOutside from 'react-onclickoutside';
import { withState, withHandlers, withProps } from 'recompose';
import withEditConfirmationDialog from 'components/estimateEdit/withEditConfirmationDialog';
import SaveCancelPopup from 'components/forms/saveCancelPopup';
import { saveLineItemValue } from 'state/bids/actions';
import { getBidValueForLineItemId, getLineItemById } from 'state/bids/selectors';
import { shadows } from 'shared/styles';
import { Cell } from './tableComponents';

const EditableContainer = styled(Cell)`
  padding: 0;
  min-height: 100%;
  width: 100%;
  border: 1px solid #2684ff;
  position: relative;
  overflow: visible;
`;

export const PseudoInput = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: ${shadows.inputBoxShadow};

  border: 1px solid lightgray;
  background-color: white;

  input {
    height: 100% !important;
    width: 100% !important;
    min-width: 80px;
    text-align: right;
    line-height: 32px !important;
    border: 0 !important;
    border-radius: 0px !important;
    padding: 0 8px !important;
    background: transparent !important;

    &:active,
    &:focus {
      box-shadow: 0 0 0 1px #2684ff;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
`;

const EditableCell = ({
  handleSubmit,
  cancel,
  isDirty,
  onValueChange,
  value,
  handleFocus,
  handleKeyPress,
  type = 'text',
  className,
}) => {
  return (
    <EditableContainer className={className}>
      <PseudoInput>
        <input
          defaultValue={value}
          name="value"
          autoFocus
          type={type}
          onChange={onValueChange}
          onFocus={handleFocus}
          onKeyPress={handleKeyPress}
        />
      </PseudoInput>
      {isDirty && <SaveCancelPopup onSave={handleSubmit} onCancel={cancel} />}
    </EditableContainer>
  );
};

export const EditableField = compose(
  withState('value', 'setValue', get('initialValue')),
  withHandlers({
    onValueChange:
      ({ setValue }) =>
      e =>
        setValue(e.target.value),
  }),
  withProps(({ value, initialValue }) => {
    return {
      handleFocus: event => event.target.select(),
      isDirty: value !== initialValue,
    };
  }),
  withProps(({ action, value, isDirty }) => ({ handleSubmit: () => action(value, isDirty) })),
  withEditConfirmationDialog('handleSubmit'),
  withProps(({ isDirty, handleSubmit, save }) => ({
    saveValue: event => {
      isDirty && handleSubmit();
      !isDirty && save();
    },
  })),
  withProps(({ saveValue }) => ({
    handleClickOutside: evt => saveValue(),
    handleKeyPress: event => {
      if (event.key === 'Enter') {
        saveValue();
      }
    },
  })),
  onClickOutside
)(EditableCell);

const mapStateToProps = (state, { bidId, lineItemNameId, mandatory, costCategoryId }) => {
  const cellValue = getBidValueForLineItemId(bidId, lineItemNameId)(state);
  const lineItemName = getLineItemById(lineItemNameId)(state);
  return {
    initialValue: cellValue,
    mandatory: lineItemName.mandatory,
    costCategoryId: lineItemName.costCategoryId,
  };
};

const mapDispatchToProps = (
  dispatch,
  { estimateId, bidId, lineItemNameId, save, pageId, mandatory, costCategoryId, ...props }
) => ({
  action: (value, isDirty) => {
    isDirty &&
      dispatch(
        saveLineItemValue({
          estimateId,
          bidId,
          lineItemNameId,
          value,
          pageId,
          lineItemName: {},
          mandatory,
          costCategoryId,
        })
      );
    save();
  },
});

export default compose(connect(mapStateToProps), connect(null, mapDispatchToProps))(EditableField);
