import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppContainer from 'components/appContainer';
import { getUserWorkspaces } from 'state/workspaces/selectors';

const useStyles = makeStyles(theme => {
  console.log(theme);
  return {
    description: {
      marginTop: theme.spacing(1),
    },
    errorBox: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
      backgroundColor: theme.palette.error.light,
      // color: theme.palette.error.dark,
      borderLeft: `4px solid ${theme.palette.error.dark}`,
      display: 'inline-block',
      ...theme.typography.body1,
    },
  };
});

const NoWorkspaces = ({ workspaces = [], history }) => {
  const classes = useStyles();
  useEffect(() => {
    if (workspaces.length) {
      history.push('/');
    } else {
      window.Intercom && window.Intercom('show');
    }
  }, [workspaces, history]);

  return (
    <AppContainer>
      <Typography variant="h4">No workspace found</Typography>
      <div className={classes.description}>
        A workspace is an area in which users can collaborate creating campaigns, submitting and
        approving estimates.
      </div>

      {!workspaces.length && (
        <div className={classes.errorBox} data-test="noWorkspaceHelpBox">
          Your user is not associated to any workspace. Please contact your admin.
        </div>
      )}
    </AppContainer>
  );
};

const mapStateToProps = state => ({
  workspaces: getUserWorkspaces(state),
});

export default withRouter(connect(mapStateToProps)(NoWorkspaces));
