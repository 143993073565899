import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getDisplayCurrency, getFlags, getClientSettings } from 'state/authentication/selectors';
import DataTable from '../dataTable';
import getColumns, { getDownloadColumns } from './columns';
import EstimateCard from 'components/estimateCard';
import { TableHeader } from '../components';
import { format as formatDate } from 'date-fns';
import { csvDownload } from 'utils';

const reportName = 'CumulativeSpend';
const description =
  "Cumulative report shows Progress on Estimate Volume and Spend by Air date. The Report enables forecasting by giving users a state of play on what's been committed. View all estimates by status, compare month by month and know your busiest times, when a production can be scheduled and so forth. Use the filters on the left to further customise this report.";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
  topBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  estimateCard: {
    marginBottom: 0,
    borderRadius: 0,
  },
}));

const Table = ({ estimates, displayCurrency, flags, title, clientSettings }) => {
  const classes = useStyles();

  const columns = useMemo(() => getColumns(estimates, displayCurrency, clientSettings), [
    estimates,
    displayCurrency,
    clientSettings,
  ]);
  const [expandAll, setExpandAll] = useState(false);
  const toggleExpandAll = () => setExpandAll(!expandAll);

  const download = () => {
    csvDownload(
      estimates,
      getDownloadColumns({ clientSettings }),
      `Report_${reportName}_${formatDate(new Date(), 'yyyy-MM-dd')}`
    );
  };

  return (
    Boolean(estimates && estimates.length) && (
      <>
        <TableHeader
          flags={flags}
          expandAll={expandAll}
          toggleExpandAll={toggleExpandAll}
          download={download}
          title={title}
        />
        <DataTable
          data={estimates}
          columns={columns}
          reportDescription={description}
          reportEventName={reportName}
          expandAll={expandAll}
          initialState={{
            groupBy: ['status', 'month'],
            sortBy: ['status', 'month', 'estimates'],
          }}
          SubComponent={({ row }) => (
            <EstimateCard
              estimate={row.original}
              target="_blank"
              className={classes.estimateCard}
            />
          )}
        />
      </>
    )
  );
};

const mapStateToProps = state => ({
  displayCurrency: getDisplayCurrency(state),
  flags: getFlags(state),
  clientSettings: getClientSettings(state),
});

export default connect(mapStateToProps)(Table);
