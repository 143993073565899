import { useRef, useState, useCallback } from 'react';
import { debounce } from 'lodash/fp';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useFormikContext } from 'formik';

const FormikOnChange = ({ delay = 0, onChange, updateOnChange = null }) => {
  const { values } = useFormikContext();
  const isFirstRun = useRef(true);
  const [prevValues, setValues] = useState(values);
  const debouncedOnChange = useCallback(debounce(delay, onChange), [updateOnChange]);

  useDeepCompareEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (delay > 0) {
      debouncedOnChange(values, prevValues);
      setValues(values);
    } else {
      onChange(values, prevValues);
      setValues(values);
    }
  }, [values]);

  return null;
};

export default FormikOnChange;
