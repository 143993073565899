import {
  keyBy,
  set,
  flow,
  get,
  merge,
  update,
  map,
  compact,
  castArray,
  forEach,
  findIndex,
} from 'lodash/fp';
import {
  CAMPAIGN_FETCH_SUCCESS,
  CAMPAIGNS_RESET,
  CAMPAIGNS_FETCH_REQUEST,
  CAMPAIGNS_FETCH_SUCCESS,
  CAMPAIGNS_FILTER_VALUES_FETCH_SUCCESS,
  CAMPAIGN_CREATE_REQUEST,
  CAMPAIGN_CREATE_SUCCESS,
  CAMPAIGN_UPDATE_SUCCESS,
  CAMPAIGN_HUMAN_ID_CLIENT_IO_SUCCESS,
  CAMPAIGNS_BY_WORKSPACE_FETCH_SUCCESS,
} from 'state/campaigns/actions';
import { ESTIMATE_FETCH_SUCCESS, ESTIMATE_UPDATE_SUCCESS } from 'state/estimates/actions';

const defaultState = {
  byId: {},
  list: [],
  pagination: { totalResults: 10, page: 0 },
  campaignCreationInProgress: false,
  campaignFilterValues: [],
  lastRequestId: null,
  campaignHumanIdsAndClientIo: [],
  byWorkspace: [],
};

const updateEstimates = update('estimates', map('id'));

export default (state = defaultState, action) => {
  switch (action.type) {
    case CAMPAIGNS_FILTER_VALUES_FETCH_SUCCESS:
      return { ...state, campaignFilterValues: action.campaigns };

    case CAMPAIGNS_RESET:
      return { ...defaultState, campaignFilterValues: state.campaignFilterValues };

    case CAMPAIGNS_FETCH_REQUEST:
      const newState = { ...state };
      if (action.requestId) {
        newState.lastRequestId = action.requestId;
      }

      if (action.query.page === 1) {
        newState.list = [];
        newState.pagination = {};
        newState.byId = {};
      }
      if (!action.query.page) {
        newState.pagination = {};
      }
      return newState;
    case CAMPAIGNS_FETCH_SUCCESS:
      if (state.lastRequestId && state.lastRequestId !== action.requestId) {
        return state;
      }
      const campaignsById = keyBy('id', action.campaigns);
      let list;
      if (action.pagination.page === 1) {
        list = action.campaigns;
        return { ...state, byId: campaignsById, list, pagination: action.pagination };
      }

      list = [...state.list];
      forEach(el => list.push(el), action.campaigns);

      return {
        ...state,
        byId: { ...state.byId, ...campaignsById },
        list,
        pagination: action.pagination,
        // lastRequestId: null,
      };

    case CAMPAIGN_FETCH_SUCCESS:
      const { campaign } = action;
      const index = findIndex({ id: campaign.id }, state.list);
      const newList = state.list;
      newList[index] = campaign;
      return {
        ...state,
        list: newList,
        byId: { ...state.byId, [campaign.id]: campaign },
      };

    case CAMPAIGN_CREATE_REQUEST:
      return set('campaignCreationInProgress', true, state);
    case CAMPAIGN_CREATE_SUCCESS:
      return flow(
        set('campaignCreationInProgress', false),
        set(`byId.${action.campaign.id}`, updateEstimates(action.campaign))
      )(state);

    case ESTIMATE_FETCH_SUCCESS:
    case ESTIMATE_UPDATE_SUCCESS:
      return update(
        'byId',
        flow(get('estimate.campaign'), castArray, compact, keyBy('id'), merge)(action),
        state
      );

    case CAMPAIGN_UPDATE_SUCCESS:
      return update(`byId.${action.id}`, campaign => merge(campaign, action.campaign), state);
    case CAMPAIGN_HUMAN_ID_CLIENT_IO_SUCCESS:
      return { ...state, campaignHumanIdsAndClientIo: action.campaignHumanIdsAndClientIo };
    case CAMPAIGNS_BY_WORKSPACE_FETCH_SUCCESS:
      return { ...state, byWorkspace: action.campaignsByWorkspace };

    default:
      return state;
  }
};
