import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose, getContext, withHandlers } from 'recompose';
import styled from 'styled-components';
import { Events, track, getEstimateData } from 'components/analytics';
import { SmallFormButton } from 'components/buttons';
import withEditability from 'components/withEditability';
import SvgAddIcon from 'icons/AddIcon';
import { colors } from 'shared/styles';
import { addEmptyLineItemToCostCategory } from 'state/lineItems/actions';

const Button = styled(SmallFormButton)`
  .icon {
    font-size: inherit;
  }
  width: auto;
  align-self: flex-start;
  width: 100%;
  margin: 0;
  background: white;
  color: ${colors.text};
  border-radius: 0 0 4px 4px;
  opacity: 0.5;
  text-shadow: none;

  border: solid 1px rgba(0, 0, 0, 0.14);

  &:hover,
  &:active {
    background-color: ${colors.secondary};
    opacity: 1;
    text-shadow: none;
  }
`;

const AddLineItemButton = ({ onClick }) => (
  <Button data-test="add-cost-lineitem" onClick={onClick}>
    <SvgAddIcon className="icon" />
    Add Line Item
  </Button>
);

const mapDispatchToProps = (dispatch, { estimate, costCategoryId }) => {
  return {
    addLIneItem: () => dispatch(addEmptyLineItemToCostCategory(estimate.id, costCategoryId, -1)),
  };
};

export default compose(
  withEditability,
  getContext({
    estimate: PropTypes.object,
  }),
  connect(null, mapDispatchToProps),
  withHandlers({
    onClick: ({ addLIneItem, estimate, costCategoryId, costCategoryName }) => () => {
      addLIneItem();
      track(Events.ESTIMATE_ADD_LINE_ITEM_CLICK, {
        ...getEstimateData(estimate),
        costCategoryId,
        costCategoryName,
      });
    },
  }),
)(AddLineItemButton);
