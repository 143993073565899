import { get, getOr } from 'lodash/fp';
import { formatUserName, getUserInitialsFromName, getUserPicture } from 'cr-core/userUtils';
import { createSelector } from 'reselect';

export const getUser = get('auth.user');

export const getDisplayCurrency = get('auth.user.currency');

export const getFlags = getOr({}, 'auth.user.flags');

export const getName = createSelector(getUser, formatUserName);

export const getInitials = createSelector(getName, getUserInitialsFromName);

export const getUserId = get('auth.user.id');

export const getPicture = createSelector(getUser, getUserPicture);

export const getWorkspaceMembership = createSelector(getUser, get('workspaces'));

export const getResetPasswordSent = get('auth.resetPasswordSent');

export const getSignupCodeVerified = get('auth.signupCodeVerified');

export const getClientSettings = getOr({}, 'auth.user.client.accountSettings');

export const getClient = getOr({}, 'auth.user.client');

export const getSettings = getOr({}, 'auth.user.settings');
