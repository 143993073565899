import React from 'react';
import { map, flow, flatten, groupBy, mapValues, filter, sortBy } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, Typography, Avatar, DialogContent, Grid } from '@material-ui/core';
import { formatUserName, getInitialsForUser } from 'cr-core/userUtils';

const useStyles = makeStyles(theme => ({
  account: {
    marginBottom: theme.spacing(4),
  },
  accountName: {
    marginBottom: theme.spacing(1),
  },
  userAvatar: {
    marginRight: theme.spacing(1),
  },
  name: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  user: {
    display: 'flex',
    alignItems: 'center',
  },
  listContainer: {
    minWidth: 400,
  },
}));

const User = ({ user, key }) => {
  const classes = useStyles();
  const name = formatUserName(user, false);
  const picture =
    user.picture || name.includes('@') ? (
      <Avatar alt={name} className={classes.userAvatar} src={user.picture} />
    ) : (
      <Avatar alt={name} className={classes.userAvatar}>
        {getInitialsForUser(user)}
      </Avatar>
    );
  return (
    <>
      {picture}
      <div className={classes.name}>{name}</div>
    </>
  );
};

export default ({ title, members, accounts, onClose, open }) => {
  const classes = useStyles();
  const accountsId = map('id', accounts);

  const data = flow(
    map(user => map(account => ({ ...user, account }), user.accounts)),
    flatten,
    filter(({ account }) => accountsId.includes(account.id)),
    groupBy('account.id'),
    mapValues(users => ({ account: users[0].account, users })),
    sortBy('account.name')
  )(members);

  return (
    <Dialog onClose={onClose} aria-labelledby="workspace-members-dialog" open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {map(
          ({ account, users }) => (
            <div className={classes.account} key={account.id}>
              <Typography variant="h6" className={classes.accountName}>
                {account.name}
              </Typography>
              <div className={classes.listContainer}>
                <Grid container spacing={2}>
                  {map(
                    user => (
                      <Grid
                        item
                        xs={users.length > 1 ? 6 : 12}
                        key={user.id}
                        className={classes.user}
                      >
                        <User user={user} />
                      </Grid>
                    ),
                    users
                  )}
                </Grid>
              </div>
            </div>
          ),
          data
        )}
      </DialogContent>
    </Dialog>
  );
};
