import React from 'react';
import { Bar } from 'react-chartjs-2';
import { map } from 'lodash/fp';
import { getColorPalette, formatBigNumberCurrency, tooltipsCallbacksLabel } from './utils';

const buildChartData = (data = []) => {
  const colorPalette = getColorPalette(data.length);

  return {
    labels: map('label', data),
    datasets: [
      {
        data: map('value', data),

        backgroundColor: colorPalette.map(el => el.alpha(0.3).css()),
        hoverBackgroundColor: colorPalette.map(el => el.alpha(0.5).css()),
        borderColor: colorPalette,
        borderWidth: 1,
        hoverBorderColor: colorPalette,
      },
    ],
  };
};

export default ({ data, displayCurrency, width, height }) => (
  <Bar
    data={buildChartData(data)}
    width={width}
    height={height}
    options={{
      maintainAspectRatio: true,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              callback: (value, index, values) => formatBigNumberCurrency(value, displayCurrency),
              min: 0,
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: tooltipsCallbacksLabel(displayCurrency),
        },
      },
    }}
  />
);
