const events = {
  LOGIN: 'login',
  LOGIN_FAILED: 'login_failed',
  LOGOUT: 'logout',
  FILTERS_CHANGED: 'filters_changed',
  EXPORTS_ALL_ESTIMATE_DATA: 'exports_all_estimate_data',
  CHANGE_DISPLAY_CURRENCY: 'change_display_currency',
  HELP_CLICK: 'help_click',
  SETTINGS_CLICK: 'settings_click',
  CONTACT_US_CLICK: 'concat_us_click',

  ESTIMATE_CREATE_CLICK: 'estimate_create_click',
  ESTIMATE_CREATE_SUCCESS: 'estimate_create_success',
  ESTIMATE_CREATE_CANCEL: 'estimate_create_cancel',
  ESTIMATE_EDIT_CLICK: 'estimate_edit_click',
  ESTIMATE_EDIT_SUCCESS: 'estimate_edit_success',
  ESTIMATE_EDIT_CANCEL: 'estimate_edit_cancel',
  ESTIMATE_DELETE_CLICK: 'estimate_delete_click',
  ESTIMATE_DELETE_SUCCESS: 'estimate_delete_success',
  ESTIMATE_DELETE_CANCEL: 'estimate_delete_cancel',
  ESTIMATE_CANCEL_CLICK: 'estimate_cancel_click',
  ESTIMATE_CANCEL_SUCCESS: 'estimate_cancel_success',
  ESTIMATE_CANCEL_CANCEL: 'estimate_cancel_cancel',

  ESTIMATE_HISTORY_ITEM_CLICK: 'estimate_history_item_click',
  ESTIMATE_ADD_APPROVER: 'estimate_add_approver',
  ESTIMATE_DELETE_APPROVER: 'estimate_delete_approver',
  ESTIMATE_ADD_WATCHER: 'estimate_add_wacther',
  ESTIMATE_DELETE_WACTHER: 'estimate_delete_wacther',
  ESTIMATE_ADD_ATTACHMENT: 'estimate_add_attachment',
  ESTIMATE_DELETE_ATTACHMENT: 'estimate_delete_attachment',
  ESTIMATE_DOWNLOAD_ATTACHMENT: 'estimate_download_attachment',
  ESTIMATE_SUBMIT_SUCCESS: 'estimate_submit_success',
  ESTIMATE_RESUBMIT_SUCCESS: 'estimate_resubmit_success',
  ESTIMATE_SUBMIT_CANCEL: 'estimate_submit_cancel',
  ESTIMATE_SUBMIT_MISSING_APPROVERS: 'estimate_submit_missing_approvers',
  ESTIMATE_SUBMIT_MISSING_APPROVERS_ADD_APPROVERS_CLICK:
    'estimate_submit_missing_approvers_add_approvers_click',
  ESTIMATE_SUBMIT_MISSING_REQUIRED_FIELDS: 'estimate_submit_missing_required_fields',
  ESTIMATE_SUBMIT_MISSING_REQUIRED_FIELDS_EDIT_CLICK:
    'estimate_submit_missing_required_fields_edit_click',
  ESTIMATE_SUBMIT_MISSING_DELIVERABLES_ADD_DELIVERABLES_CLICK:
    'estimate_submit_missing_deliverables_add_deliverables_click',
  ESTIMATE_REQUEST_CHANGES_CLICK: 'estimate_request_changes_click',
  ESTIMATE_REQUEST_CHANGES_SUCCESS: 'estimate_request_changes_success',
  ESTIMATE_REQUEST_CHANGES_CANCEL: 'estimate_request_changes_cancel',
  ESTIMATE_APPROVE_CLICK: 'estimate_approve_click',
  ESTIMATE_APPROVE_SUCCESS: 'estimate_approve_success',
  ESTIMATE_APPROVE_CANCEL: 'estimate_approve_cancel',
  ESTIMATES_LIST_SCROLL: 'estimates_list_scroll',
  ESTIMATE_ADD_BID_CLICK: 'estimate_add_bid_click',
  ESTIMATE_ADD_BID_SUCCESS: 'estimate_add_bid_success',
  ESTIMATE_ADD_BID_CANCEL: 'estimate_add_bid_cancel',
  ESTIMATE_DELETE_BID_CLICK: 'estimate_delete_bid_click',
  ESTIMATE_DELETE_BID_SUCCESS: 'estimate_delete_bid_success',
  ESTIMATE_DELETE_BID_CANCEL: 'estimate_delete_bid_cancel',
  ESTIMATE_SELECT_PREFERRED_BID_CLICK: 'estimate_select_preferred_bid_click',
  ESTIMATE_ADD_SUPPLEMENTARY_COSTS_CLICK: 'estimate_add_supplementary_costs_click',
  ESTIMATE_ADD_SUPPLEMENTARY_COSTS_SUCCESS: 'estimate_add_supplementary_costs_success',
  ESTIMATE_ADD_SUPPLEMENTARY_COSTS_CANCEL: 'estimate_add_supplementary_costs_cancel',
  ESTIMATE_TOGGLE_COST_CATEGORY: 'estimate_toggle_cost_category',
  ESTIMATE_DELETE_COST_CATEGORY_CLICK: 'estimate_delete_cost_category_click',
  ESTIMATE_DELETE_COST_CATEGORY_SUCCESS: 'estimate_delete_cost_category_success',
  ESTIMATE_DELETE_COST_CATEGORY_CANCEL: 'estimate_delete_cost_category_cancel',
  ESTIMATE_ADD_COST_CATEGORY_CLICK: 'estimate_add_cost_category_click',
  ESTIMATE_ADD_COST_CATEGORY_SUCCESS: 'estimate_add_cost_category_sucess',
  ESTIMATE_ADD_COST_CATEGORY_CANCEL: 'estimate_add_cost_category_cancel',
  ESTIMATE_ADD_LINE_ITEM_CLICK: 'estimate_add_line_item_click',
  ESTIMATE_ADD_LINE_ITEM_SUCCESS: 'estimate_add_line_item_success',
  ESTIMATE_ADD_LINE_ITEM_CANCEL: 'estimate_add_line_item_cancel',
  ESTIMATE_CREATE_NEW_LINE_ITEM_CLICK: 'estimate_create_new_line_item_click',
  ESTIMATE_CREATE_NEW_LINE_ITEM_SUCCESS: 'estimate_create_new_line_item_success',
  ESTIMATE_CREATE_NEW_LINE_ITEM_CANCEL: 'estimate_create_new_line_item_cancel',
  ESTIMATE_EDIT_NOTES: 'estimate_edit_notes',
  ESTIMATE_PRINT_CLICK: 'estimate_print_click',
  ESTIMATE_PRINT_SUCCESS: 'estimate_print_success',
  ESTIMATE_PRINT_CANCEL: 'estimate_print_cancel',
  ESTIMATE_SHARE_CLICK: 'estimate_share_click',
  ESTIMATE_SHARE_COPY_CLICK: 'estimate_share_copy_click',
  ESTIMATE_SUBMIT_CLICK: 'estimate_submit_click',
  ESTIMATE_CLOSE_ACTUALISATION_CLICK: 'estimate_close_actualisation_click',
  ESTIMATE_CLOSE_ACTUALISATION_SUCCESS: 'estimate_close_actualisation_success',
  ESTIMATE_CLOSE_ACTUALISATION_CANCEL: 'estimate_close_actualisation_cancel',
  ESTIMATE_DOWNLOAD_INVOICE_CLICK: 'estimate_download_invoice_click',
  ESTIMATE_ADD_INVOICE_CLICK: 'estimate_add_invoice_click',
  ESTIMATE_SAVE_INVOICE_CLICK: 'estimate_save_invoice_click',
  ESTIMATE_CANCEL_INVOICE_CLICK: 'estimate_cancel_invoice_click',
  ESTIMATE_DELETE_INVOICE_CLICK: 'estimate_delete_invoice_click',
  ESTIMATE_DELIVERABLES_EDIT: 'estimate_deliverables_edit',
  ESTIMATE_DELIVERABLES_SAVE_CLICK: 'estimate_deliverables_save_click',
  ESTIMATE_DELIVERABLES_SAVE_SUCCESS: 'estimate_deliverables_save_success',
  ESTIMATE_DELIVERABLES_SAVE_ERROR: 'estimate_deliverables_save_error',
  ESTIMATE_DELIVERABLES_CANCEL: 'estimate_deliverables_cancel',

  CAMPAIGN_CREATE_CLICK: 'create_campaign_click',
  CAMPAIGN_CREATE_SUCCESS: 'create_campaign_success',
  CAMPAIGN_CREATE_CANCEL: 'create_campaign_cancel',
  CAMPAIGN_EDIT_CLICK: 'edit_campaign_click',
  CAMPAIGN_EDIT_SUCCESS: 'edit_campaign_success',
  CAMPAIGN_EDIT_CANCEL: 'edit_campaign_cancel',
  CAMPAIGN_DELETE_CLICK: 'delete_campaign_click',
  CAMPAIGN_DELETE_SUCCESS: 'delete_campaign_success',
  CAMPAIGN_DELETE_CANCEL: 'delete_campaign_cancel',
  CAMPAIGN_CLOSE_CLICK: 'campaign_close_click',
  CAMPAIGN_CLOSE_SUCCESS: 'campaign_close_success',
  CAMPAIGN_CLOSE_CANCEL: 'campaign_close_cancel',
  CAMPAIGN_OPEN_DETAILS_CLICK: 'campaign_open_details_click',

  REPORTS_TABLE_CLICK: 'reports_table_click',
  REPORT_ACTION_EXPAND_CLICK: 'report_action_expand_click',
  REPORT_ACTION_COMPACT_CLICK: 'report_action_compact_click',
  REPORT_ACTION_DOWNLOAD_CLICK: 'report_action_download_click',
  REPORT_ACTION_PRINT_CLICK: 'report_action_print_click',
  REPORT_ACTION_SHARE_CLICK: 'report_action_share_click',

  NAV_CLICK: 'nav_click',

  PERFORMANCE: 'performance',
};

export default events;
