import { getFormValues } from 'redux-form';
import { omitBy, isUndefined } from 'lodash/fp';
import { fetch } from 'state/fetchMiddleware';
import { showSuccessNotification, showErrorNotification } from 'state/notifications/actions';
import queryString from 'query-string';

export const FETCH_USERS_REQUEST = 'users/FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'users/FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'users/FETCH_USERS_ERROR';
export const CREATE_USER_REQUEST = 'users/CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'users/CREATE_USER_SUCCESS';

export const USER_UPDATE_REQUEST = 'users/USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'users/USER_UPDATE_SUCCESS';
export const USER_UPDATE_ERROR = 'users/USER_UPDATE_ERROR';
export const USER_BLOCK_REQUEST = 'users/USER_BLOCK_REQUEST';
export const USER_BLOCK_SUCCESS = 'users/USER_BLOCK_SUCCESS';
export const USER_BLOCK_ERROR = 'users/USER_BLOCK_ERROR';
export const USER_DELETE_REQUEST = 'users/USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'users/USER_DELETE_SUCCESS';
export const USER_DELETE_ERROR = 'users/USER_DELETE_ERROR';
export const USER_INVITE_REQUEST = 'users/USER_INVITE_REQUEST';
export const USER_INVITE_SUCCESS = 'users/USER_INVITE_SUCCESS';
export const USER_INVITE_ERROR = 'users/USER_INVITE_ERROR';

export const TOOGLE_USER_STATUS_REQUEST = 'users/TOOGLE_USER_STATUS_REQUEST';
export const TOOGLE_USER_STATUS_SUCCESS = 'users/TOOGLE_USER_STATUS_SUCCESS';
export const SET_DISPLAY_CURRENCY = 'users/SET_DISPLAY_CURRENCY';

export const fetchUsers = filters => async (dispatch, getState) => {
  dispatch({ type: FETCH_USERS_REQUEST });
  const cleanedFilters = omitBy(v => isUndefined(v) || v === '', filters);
  try {
    const {
      data: { data: users, pagination },
    } = await dispatch(
      fetch('/v2/users', {
        params: { ...cleanedFilters, limit: 1000 },
      })
    );
    dispatch({ type: FETCH_USERS_SUCCESS, users, pagination });
  } catch (error) {
    dispatch({ type: FETCH_USERS_ERROR, error });
  }
};

export const setDisplayCurrency = currency => async dispatch => {
  await dispatch(updateUser('me', { currency }));
  window.location.reload();
};

export const blockUser = (userId, block) => async dispatch => {
  dispatch({ type: USER_BLOCK_REQUEST, userId, block });
  try {
    const { data: user } = await dispatch(
      fetch(`/v2/users/${userId}/${block ? 'block' : 'unblock'}`, { method: 'POST' })
    );
    dispatch({ type: USER_BLOCK_SUCCESS, userId, block, user });
    dispatch(showSuccessNotification({ message: `User ${block ? 'blocked' : 'unblocked'}` }));
  } catch (error) {
    dispatch({ type: USER_BLOCK_ERROR, userId, block, error });
    dispatch(showErrorNotification({ message: error.toString() }));
  }
};

export const deleteUser = userId => async dispatch => {
  dispatch({ type: USER_DELETE_REQUEST, userId });
  try {
    await dispatch(fetch(`/v2/users/${userId}`, { method: 'DELETE' }));
    dispatch({ type: USER_DELETE_SUCCESS, userId });
    dispatch(showSuccessNotification({ message: 'User deleted' }));
  } catch (error) {
    console.log(error);
    dispatch({ type: USER_DELETE_ERROR, userId, error });
    dispatch(showErrorNotification({ message: error.toString() }));
  }
};

export const updateUser = (userId, data) => async dispatch => {
  dispatch({ type: USER_UPDATE_REQUEST, userId, data });
  try {
    await dispatch(fetch(`/v2/users/${userId}`, { method: 'PUT', data }));
    const { data: user } = await dispatch(fetch(`/v2/users/${userId}`, { params: {} }));
    dispatch({ type: USER_UPDATE_SUCCESS, userId, data, user });
  } catch (error) {
    dispatch({ type: USER_UPDATE_ERROR, userId, data, error });
  }
};

export const setModerator = (accountId, userId) => async dispatch => {
  dispatch({ type: USER_UPDATE_REQUEST, userId });

  try {
    await dispatch(
      fetch('/admin/role', { method: 'POST', data: { accountId, userId, role: 'MODERATOR' } })
    );

    const { data: user } = await dispatch(fetch(`/v2/users/${userId}`, { params: {} }));

    dispatch({ type: USER_UPDATE_SUCCESS, userId, user });
  } catch (error) {
    dispatch({ type: USER_UPDATE_ERROR, userId, error });
  }
};

export const removeModerator = (accountId, userId) => async dispatch => {
  dispatch({ type: USER_UPDATE_REQUEST, userId });

  try {
    await dispatch(
      fetch(
        queryString.stringifyUrl({
          url: '/admin/role',
          query: { accountId, userId, role: 'MODERATOR' },
        }),
        { method: 'DELETE' }
      )
    );

    const { data: user } = await dispatch(fetch(`/v2/users/${userId}`, { params: {} }));

    dispatch({ type: USER_UPDATE_SUCCESS, userId, user });
  } catch (error) {
    dispatch({ type: USER_UPDATE_ERROR, userId, error });
  }
};

export const inviteUsers =
  (emails, accountId, data, updateUserList) => async (dispatch, getState) => {
    dispatch({ type: USER_INVITE_REQUEST, userEmails: emails, data });
    try {
      const data = {
        emails,
        // ...data,
        accountId,
      };
      const { hostname } = window.location;
      let subdomain = hostname.includes('localhost')
        ? window.location.host
        : hostname.split('.')[0];
      if (subdomain !== 'app') {
        data.subdomain = subdomain;
      }
      await dispatch(
        fetch('/v2/users', {
          method: 'POST',
          data,
        })
      );
      dispatch({ type: USER_INVITE_SUCCESS, userEmails: emails, data });
      dispatch(showSuccessNotification({ message: 'Users invited' }));
      if (updateUserList) {
        const filters = getFormValues('userFilters')(getState()) || {};
        dispatch(fetchUsers(filters));
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: USER_INVITE_ERROR, error });
      dispatch(showErrorNotification({ message: error.toString() }));
      throw error;
    }
  };
