import { flow, get, orderBy, find } from 'lodash/fp';

export const getAccounts = flow(
  get('accounts.accounts'),
  orderBy(['name'], ['asc'])
);

export const getAccountById = id =>
  flow(
    get('accounts.accounts'),
    find({ id })
  );

export const getAgencies = flow(
  get('accounts.agencies'),
  orderBy(['name'], ['asc'])
);
