import React from 'react';

const SvgTrashIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="trash-icon_svg__a"
        width="142.1%"
        height="133.3%"
        x="-21.1%"
        y="-16.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      d="M1.337 21.333v-16h16.048v16A2.679 2.679 0 0 1 14.711 24H4.012a2.679 2.679 0 0 1-2.675-2.667zM9.424 8.42a.754.754 0 0 0-.754.754v10.932a.754.754 0 1 0 1.508 0V9.173a.754.754 0 0 0-.754-.754zm3.77 0a.754.754 0 0 0-.754.754v10.932a.754.754 0 1 0 1.508 0V9.173a.754.754 0 0 0-.754-.754zm-7.54 0a.754.754 0 0 0-.753.754v10.932a.754.754 0 1 0 1.507 0V9.173a.754.754 0 0 0-.754-.754zm13.069-7.086h-4.681L12.705 0H6.018L4.681 1.333H0V4h18.723V1.333z"
      filter="url(#trash-icon_svg__a)"
      transform="translate(6 3)"
    />
  </svg>
);

export default SvgTrashIcon;
