import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { getFormValues } from 'redux-form';
import { AutoSizer } from 'react-virtualized';
import { keys, unset, flow, map, fromPairs, set, reject, filter, get } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { getAccountById } from 'state/accounts/selectors';
import { getUser } from 'state/authentication/selectors';
import { openSidePanel } from 'state/ui/actions';
import { isSidePanelOpen } from 'state/ui/selectors';
import { fetchWorkspaces } from 'state/workspaces/actions';
import { getFilteredWorkspaces, getWorkspacesPagination } from 'state/workspaces/selectors';
import { isResourceLoading } from 'state/resourceStatus/selectors';
import WorkspacesTable from './workspacesListTable';
import ConfirmationModal from 'components/modals/confirmationModal';

export const formName = 'userFilters';

const DELETE_USER_ACTION = 'DELETE_USER_ACTION';
const BLOCK_USER_ACTION = 'BLOCK_USER_ACTION';

const columns = [
  {
    label: '',
    dataKey: 'select',
    width: 40,
    compact: true,
  },
  {
    label: 'Name',
    dataKey: 'name',
    flexGrow: 1,
    compact: true,
  },
  {
    label: 'Client',
    dataKey: 'client',
    client: false,
    width: 180,
    compact: true,
  },
  {
    label: 'Users',
    dataKey: 'membersCount',
    width: 120,
    compact: true,
    align: 'center',
  },
  {
    label: 'Teams',
    dataKey: 'teams',
    width: 120,
    compact: true,
    align: 'center',
  },
  {
    label: 'Created At',
    dataKey: 'createdAt',
    width: 120,
    align: 'center',
  },
];

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    height: `calc(100% - ${theme.typography.h6.fontSize} - ${theme.spacing(2)}px)`,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const listRef = React.createRef();

const UserList = ({
  user,
  workspaces,
  fetchWorkspaces,
  selectedAccount,
  blockUser,
  deleteUser,
  openDetails,
  openSidePanel,
  sidePanelOpened,
  history,
  filters,
  isLoading,
}) => {
  const classes = useStyles();
  let userColumns = user.isAdmin ? columns : reject({ admin: true }, columns);
  if (get('client', selectedAccount)) {
    userColumns = reject({ consultancy: false }, columns);
  }
  userColumns = sidePanelOpened ? filter({ compact: true }, userColumns) : userColumns;
  const [openModal, setOpenModal] = useState(false);
  const [modalData] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const selectedAll = keys(selectedItems).length === workspaces.length;
  const selectItem = (item, val) => {
    if (val) {
      setSelectedItems(set(item, true, selectedItems));
    } else {
      setSelectedItems(unset(item, selectedItems));
    }
  };

  useEffect(() => {
    if (filters.accountId) {
      fetchWorkspaces(filters);
    }
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseModal = val => {
    setOpenModal(false);
    if (val) {
      handleConfirmModal();
    }
  };

  const handleConfirmModal = async () => {
    if (modalData.action === DELETE_USER_ACTION) {
      await deleteUser(modalData.user.id);
      await fetchWorkspaces(filters);
    }
    if (modalData.action === BLOCK_USER_ACTION) {
      await blockUser(modalData.user.id);
    }
  };

  const onSelectAllItemsClick = () => {
    if (!keys(selectedItems).length) {
      setSelectedItems(
        flow(
          map(({ id }) => [id, true]),
          fromPairs
        )(workspaces)
      );
    } else {
      setSelectedItems({});
    }
  };

  const onRowClick = ({ rowData }) => {
    openSidePanel({ view: 'WORKSPACE_DETAILS', workspace: rowData });
    // history.push(`/teams/users/${rowData.id}`);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <AutoSizer>
          {({ height, width }) => (
            <WorkspacesTable
              height={height}
              width={width}
              listRef={listRef}
              columns={userColumns}
              data={workspaces}
              onRowClick={onRowClick}
              selectItem={selectItem}
              selectedAll={selectedAll}
              onSelectAllItemsClick={onSelectAllItemsClick}
              selectedItems={selectedItems}
              alertMessage={
                Boolean(selectedAccount)
                  ? isLoading
                    ? 'Loading..'
                    : null
                  : 'Please select an account'
              }
              noDataMessage="No results for the selected filters"
            />
          )}
        </AutoSizer>

        <ConfirmationModal
          open={openModal}
          handleClose={handleCloseModal}
          title={modalData.title}
          message={modalData.message}
          actionLabel={modalData.actionLabel}
        />
      </Paper>
    </>
  );
};

const mapStateToProps = state => {
  const accountId = get('accountId', getFormValues('workspaceFilters')(state));
  return {
    user: getUser(state),
    workspaces: getFilteredWorkspaces(state),
    selectedAccount: getAccountById(accountId)(state),
    pagination: getWorkspacesPagination(state),
    sidePanelOpened: isSidePanelOpen(state),
    filters: getFormValues('workspaceFilters')(state) || {},
    isLoading: isResourceLoading('workspaces')(state),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchWorkspaces: filters => dispatch(fetchWorkspaces(filters)),
  openSidePanel: data => dispatch(openSidePanel(data)),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(UserList);
