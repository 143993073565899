import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { sumBy, flow, groupBy, mapValues, toPairs, map } from 'lodash/fp';
import { MediaTypeLabels } from 'cr-core/constants';
import { formatAsCurrency } from 'cr-core/currencyUtils';
import { MediaTypeIcon } from 'icons';
import { getCost, widgetStyles } from './utils';

const useStyles = makeStyles(theme => ({
  ...widgetStyles(theme),

  mediaTypeBreakdownRow: {
    marginBottom: theme.spacing(1),
  },
  mediaTypeBreakdownBar: {
    height: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.light,
    borderRadius: '0 2px 2px 0',
    marginTop: theme.spacing(0.5),
  },
  mediaTypeBreakdownRowLeft: {
    flexGrow: 1,
    marginRight: theme.spacing(4),
  },
  mediaTypeBreakdownLeftLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  mediaTypeBreakdownIcon: {
    fontSize: 20,
    marginRight: theme.spacing(0.5),
  },
}));

export default ({ estimates, displayCurrency }) => {
  const classes = useStyles();
  const totalSpend = sumBy(getCost, estimates);
  const splitByMediaType = flow(
    groupBy('mediaType'),
    mapValues(sumBy(getCost)),
    toPairs
  )(estimates);

  if (!splitByMediaType.length) {
    return null;
  }

  return (
    <div className={classes.widget} data-test="campaign-media-type-breakdown-widget">
      <div className={classes.widgetTitle}>Estimate Type Breakdown</div>
      {map(
        ([mediaType, cost]) => (
          <div className={clsx(classes.row, classes.mediaTypeBreakdownRow)} key={mediaType}>
            <div className={classes.mediaTypeBreakdownRowLeft}>
              <div className={classes.mediaTypeBreakdownLeftLabel}>
                <MediaTypeIcon type={mediaType} className={classes.mediaTypeBreakdownIcon} />
                {MediaTypeLabels[mediaType]}
              </div>
              <div
                className={classes.mediaTypeBreakdownBar}
                style={{ width: `${(cost / totalSpend) * 100}%` }}
              ></div>
            </div>
            <div className={classes.statusBreakdownFigure}>
              {formatAsCurrency(displayCurrency, cost)}
            </div>
          </div>
        ),
        splitByMediaType
      )}
    </div>
  );
};
