import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { EstimateStatuses } from 'cr-core/constants';
import { Events, track, getEstimateData } from 'components/analytics';
import { Button } from 'components/buttons';
import SvgEditIcon from 'icons/EditIcon';
import { colors, font } from 'shared/styles';
import { canEdit, getEstimateById } from 'state/estimates/selectors';
import DeleteEstimateButton from './deleteEstimateButton';
import CancelEstimateButton from './cancelEstimateButton';
import EstimateDetailItems from './estimateDetailItems';
import { getUser } from 'state/authentication/selectors';

const Container = styled.div`
  width: 100%;
  margin: 20px;
  color: ${colors.text};
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 5px;
`;

const ButtonsContainer = styled.div`
  margin: 30px;
  margin-left: auto;
  display: table;

  .button {
    width: auto;
    margin-bottom: 10px;
    padding: 10px;
    text-align: center;
    background: transparent;
    text-decoration: underline;
    color: grey;
    border: 0;
    font-size: ${font.sizes.button};
    line-height: ${font.sizes.button};
    height: auto;
    display: inline-block;
    text-align: right;

    &.edit:hover {
      background: ${colors.buttonHover};
      color: white;
    }

    &.delete:hover {
      background: ${colors.alertHover};
      color: white;
    }

    .icon {
      font-size: ${font.sizes.large};
      margin-right: 5px;
      margin-bottom: -2px;
    }
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 30px;
  align-self: flex-start;
`;

const EstimateDetails = ({ estimate, canEdit }) => {
  if (!estimate) {
    return null;
  }

  return (
    <Container>
      <DetailsContainer>
        <EstimateDetailItems estimateId={estimate.id} />
      </DetailsContainer>
      <ButtonsContainer>
        {canEdit && estimate.status === EstimateStatuses.DRAFT && (
          <DeleteEstimateButton estimate={estimate} className="delete" />
        )}
        {(estimate.status === EstimateStatuses.PENDING_APPROVAL ||
          estimate.status === EstimateStatuses.APPROVED) && (
          <CancelEstimateButton estimate={estimate} className="delete" />
        )}
        {canEdit && estimate.status !== EstimateStatuses.CANCELLED && (
          <Button
            className="edit"
            Component={Link}
            to={`/estimates/${estimate.id}/edit`}
            onClick={() => track(Events.ESTIMATE_EDIT_CLICK, getEstimateData(estimate))}
            data-test="editEstimate"
          >
            <SvgEditIcon className="icon" />
            Edit
          </Button>
        )}
      </ButtonsContainer>
    </Container>
  );
};

const mapStateToProps = (state, { match }) => ({
  estimate: getEstimateById(match.params.id)(state),
  user: getUser(state),
  canEdit: canEdit(match.params.id)(state),
});

export default connect(mapStateToProps)(EstimateDetails);
