import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  flow,
  map,
  filter,
  sumBy,
  find,
  groupBy,
  forEach,
  uniq,
  flatten,
  reject,
  values,
  compact,
} from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { EstimateStatuses, CampaignStatuses, AccountSetting } from 'cr-core/constants';
import { getClientSettings, getFlags } from 'state/authentication/selectors';
import EstimateCard from 'components/estimateCard';
import { sortWithMultipleValues } from '../utils';
import { TableHeader } from '../components';
import DataTable from '../dataTable';
import getColumns, { downloadColumns, getDetailedDownloadColumns } from './columns';
import { COLUMNS as legendUtils } from '../utils.legend';
import { indexPropGetFnDivision, indexPropGetFnCountry } from './utils';
import { csvDownload } from 'utils';
import { format as formatDate } from 'date-fns';

const reportName = 'Campaigns by Region';
const description = (
  <div>
    Campaigns by Region report shows you at a glance Total Spend per Campaign Versus Budget. The
    Report offers clarity on the remaining budget and actual savings by region. Know what you have
    spent and what you have left means you can maximise what you have available and avoid unspent
    budgets disappearing. Use the filters on the left to further customise this report.
    <br />
    {legendUtils.estimateStatusNote}
  </div>
);
const afterLegend = (
  <div>
    <div>{legendUtils.notSet}</div>
    <div>{legendUtils.multipleCategories}</div>
    <div>{legendUtils.multipleDivisions}</div>
  </div>
);

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
  estimateCard: {
    borderRadius: 0,

    '&:last-child': {
      marginBottom: 0,
    },
  },
}));

const getData = (estimates, campaigns, displayCurrency, isExportData = false) => {
  const campaignEstimates = groupBy('campaignId', estimates);
  let res = flow(
    groupBy('campaignId'),
    values,
    map(estimates => {
      const campaign = find({ id: estimates[0].campaignId }, campaigns);
      return campaign
        ? {
            ...campaign,
            estimates,
            allEstimates: campaign.estimates,
          }
        : null;
    }),
    compact
  )(estimates);

  const cid = map('id', res);
  const nonIncludedCampaigns = flow(
    reject(({ id }) => cid.includes(id)),
    map(c => ({ ...c, estimates: [] || [], allEstimates: c.estimates || [] }))
  )(campaigns);
  res = [...res, ...nonIncludedCampaigns];

  forEach(campaign => {
    const { estimates } = campaign;
    campaign.totalEstimated = sumBy('recommendedBidTotal', estimates);
    campaign.totalActualToDate = sumBy('actualisedTotal', estimates);
    campaign.totalFinal = flow(
      filter({ status: EstimateStatuses.ACTUALISED }),
      sumBy('actualisedTotal')
    )(estimates);
    campaign.variance = (campaign.budget || 0) - (campaign.totalEstimated || 0);
    campaign.finalSaving =
      campaign.status === CampaignStatuses.ACTUALISED ? campaign.budget - campaign.totalFinal : 0;

    campaign.country = indexPropGetFnCountry(campaign.allEstimates);
    campaign.division = indexPropGetFnDivision(campaign.allEstimates);

    campaign.category = flow(
      map('products'),
      map(values => (values.length ? values : [{ brandCategory: 'Not Set' }])),
      flatten,
      map('brandCategory'),
      uniq,
      categories =>
        categories.length === 0
          ? 'Not Set'
          : categories.length === 1
          ? categories[0]
          : 'Multiple Regions'
    )(campaign.allEstimates);

    campaign.category = flow(map('products'), flatten, map('brandCategory'), uniq, categories => {
      if (isExportData) {
        return categories.join(', ');
      } else {
        return categories.length === 0
          ? 'Not Set'
          : categories.length === 1
          ? categories[0]
          : 'Multiple Segments';
      }
    })(campaignEstimates[campaign.id]);

    campaign.brand = flow(
      map('products'),
      map(values => (values.length ? values : [{ brandName: 'Not Set' }])),
      flatten,
      map('brandName'),
      uniq,
      brands =>
        brands.length === 0 ? 'Not Set' : brands.length === 1 ? brands[0] : 'Multiple Brands'
    )(campaign.allEstimates);

    campaign.brand = flow(map('products'), flatten, map('brandName'), uniq, brands => {
      if (isExportData) {
        return brands.join(', ');
      } else {
        return brands.length === 0
          ? 'Not Set'
          : brands.length === 1
          ? brands[0]
          : 'Multiple Brands';
      }
    })(campaignEstimates[campaign.id]);
  }, res);

  res.sort((a, b) => sortWithMultipleValues({ values: a }, { values: b }, 'name'));
  res.sort((a, b) => sortWithMultipleValues({ values: a }, { values: b }, 'category'));
  return res;
};

const Table = ({ estimates, campaigns, displayCurrency, flags, title, clientSettings }) => {
  const classes = useStyles();
  const data = useMemo(
    () => campaigns.length && getData(estimates, campaigns, displayCurrency),
    [estimates, campaigns, displayCurrency]
  );
  const exportData = useMemo(
    () => (campaigns.length ? getData(estimates, campaigns, displayCurrency, true) : []),
    [estimates, campaigns, displayCurrency]
  );
  const columns = useMemo(() => getColumns(data, displayCurrency), [data, displayCurrency]);
  const [expandAll, setExpandAll] = useState(false);
  const toggleExpandAll = () => setExpandAll(!expandAll);

  const download = () => {
    csvDownload(
      exportData,
      clientSettings[AccountSetting.DetailedExportReports]
        ? getDetailedDownloadColumns()
        : downloadColumns,
      `Report_${reportName}_${formatDate(new Date(), 'yyyy-MM-dd')}`
    );
  };

  return (
    <>
      <TableHeader
        flags={flags}
        expandAll={expandAll}
        toggleExpandAll={toggleExpandAll}
        download={download}
        title={title}
      />
      <DataTable
        data={data}
        columns={columns}
        reportEventName={reportName}
        expandAll={expandAll}
        reportDescription={description}
        afterLegend={afterLegend}
        initialState={{
          groupBy: ['division', 'country', 'category'],
          sortBy: ['division', 'country', 'category', 'campaign'],
        }}
        SubComponent={({ row }) => {
          const { estimates = [] } = row.original;
          return map(
            estimate => (
              <EstimateCard estimate={estimate} target="_blank" className={classes.estimateCard} />
            ),
            estimates
          );
        }}
      />
    </>
  );
};

const mapStateToProps = state => ({
  flags: getFlags(state),
  clientSettings: getClientSettings(state),
});

export default connect(mapStateToProps)(Table);
