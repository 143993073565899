import React, { useEffect, useMemo, useState } from 'react';
import { useField } from 'formik';
import {
  get,
  isUndefined,
  difference,
  map,
  filter,
  flow,
  find,
  uniq,
  without,
  concat,
} from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, List, ListItemSecondaryAction, Collapse } from '@material-ui/core';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  SelectAllOption,
  SimpleOption,
  NoResultsOptions,
  BaseComponent,
} from './formik.autocompleteMultiSelect';

import MarsRegions from 'components/forms/marsRegions';
import SelectedItems from './SelectedItems';

let countriesOptions = [];
for (let i = 0; i < MarsRegions.length; i++) {
  countriesOptions = [
    ...countriesOptions,
    {
      label: MarsRegions[i].name,
      value: MarsRegions[i].name,
      region: MarsRegions[i].name,
    },
    ...map(
      ({ name, flag }) => ({ label: name, value: name, img: flag, region: MarsRegions[i].name }),
      MarsRegions[i].countries
    ),
  ];
}

const regionsOptions = MarsRegions.map(region => ({
  label: region.name,
  value: region.name,
  region: region.name,
}));

const useStyles = makeStyles(theme => {
  return {
    nested: {
      paddingLeft: theme.spacing(4),
    },
  };
});

const GroupOption = ({
  group,
  options,
  index,
  isSelected,
  optionProps,
  getInputProps,
  openNestedList,
  handleToggleNestedList,
  collapsed,
  showNestedOptions,
}) => {
  const classes = useStyles();
  const regionOption = options[0];
  options = options.slice(1); // then use +1 on the options index

  return (
    <>
      <SimpleOption
        option={regionOption}
        index={index}
        optionProps={optionProps}
        isSelected={isSelected}
        SecondaryAction={
          Boolean(options.length) &&
          showNestedOptions && (
            <ListItemSecondaryAction onClick={handleToggleNestedList(group)}>
              <IconButton edge="end" aria-label="delete">
                {collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </ListItemSecondaryAction>
          )
        }
      />
      {Boolean(options.length) && showNestedOptions && (
        <Collapse in={collapsed} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {options.map((option, secondaryIndex) => (
              <SimpleOption
                option={option}
                key={index + secondaryIndex + 1}
                index={index + secondaryIndex + 1}
                optionProps={optionProps}
                isSelected={isSelected}
                className={classes.nested}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

const MultiSearchAutocomplete = ({
  id,
  options,
  label,
  displayStackedOptions = false,
  showNestedOptions = true,
  setValue,
  values = [],
  className,
  margin,
}) => {
  const classes = useStyles();
  const [openNestedList, setOpenNestedList] = useState({});
  const {
    getRootProps,
    // getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    anchorEl,
    setAnchorEl,
  } = useAutocomplete({
    id,
    options,
    multiple: true,
    getOptionLabel: get('label'),
    groupBy: get('region'),
  });

  const selectedCount = values.length;
  const isSelectedAll = useMemo(
    () => Boolean(values.length) && difference(uniq(map('region', options)), values).length === 0,
    [values, options]
  );

  const isSelected = value => {
    const region = find({ value }, options).region;
    return values.includes(value) || values.includes(region);
  };

  const handleItemClick = ({ value, region }, index) => {
    if (value === 'select-all') {
      return setValue(isSelectedAll ? [] : flow(map('region'), uniq)(options));
    }

    const regionValues = flow(filter({ region }), map('value'), without([region]))(options);

    if (value === region) {
      if (values.includes(region)) {
        return setValue(without([region], values));
      }
      return setValue([...without(regionValues, values), region]);
    }

    if (values.includes(region)) {
      // deselect single country
      return setValue(flow(concat(regionValues), without([region, value]))(values));
    }

    if (difference(regionValues, [...values, value]).length === 0) {
      // if all the countries of a region are selected -> select the region
      return setValue([...difference(values, regionValues), region]);
    }

    if (values.includes(value)) {
      return setValue(without([value], values));
    }

    setValue([...values, value]);
  };

  const optionProps = ({ option, index }) => {
    const props = getOptionProps({ option, index });
    props.onClick = () => handleItemClick(option, index);
    if (isUndefined(props.key)) {
      props.key = option;
    }
    props['aria-selected'] = values.includes(option);
    if (props['aria-selected']) {
      props.className = classes.optionSelected;
    }
    return props;
  };

  const handleToggleNestedList = group => event => {
    event.stopPropagation();
    setOpenNestedList({ ...openNestedList, [group]: !openNestedList[group] });
  };

  return (
    <>
      <BaseComponent
        label={label}
        values={values}
        options={options}
        getInputProps={getInputProps}
        selectedCount={selectedCount}
        isSelectedAll={isSelectedAll}
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
        getRootProps={getRootProps}
        disableValue={displayStackedOptions}
        getListboxProps={getListboxProps}
        className={className}
        margin={margin}
      >
        {groupedOptions.length > 0 ? (
          <>
            {!(groupedOptions.length > 0 && getInputProps().value) && (
              <SelectAllOption
                optionProps={optionProps}
                isSelectedAll={isSelectedAll}
                selectedCount={selectedCount}
              />
            )}
            {groupedOptions.map(({ group, options, index }) => (
              <GroupOption
                key={index}
                group={group}
                options={options}
                index={index}
                isSelected={isSelected}
                optionProps={optionProps}
                getInputProps={getInputProps}
                openNestedList={openNestedList}
                showNestedOptions={showNestedOptions}
                handleToggleNestedList={handleToggleNestedList}
                collapsed={openNestedList[group] || Boolean(getInputProps().value)}
              />
            ))}
          </>
        ) : (
          <NoResultsOptions />
        )}
      </BaseComponent>
      {displayStackedOptions && (
        <SelectedItems selected={values} options={options} removeOption={handleItemClick} />
      )}
    </>
  );
};

const AutocompleteMultiSelect = ({
  displayStackedOptions,
  showNestedOptions = true,
  updateDivisions = false,
  selectedWorkspaces = false,
  ...props
}) => {
  const id = get('id', props);
  const label = get('label', props);
  const className = get('className', props);
  const margin = get('margin', props);
  const options = showNestedOptions ? countriesOptions : regionsOptions;
  const [field, , helpers] = useField(get('field.name', props));
  const [selectedValues, setSelectedValues] = useState(field.value || []);
  const setSelectedValuesWrapped = values => {
    setSelectedValues(values);
    helpers.setValue(values);
    const clearSelectedWorkspaces = selectedWorkspaces ? true : false;
    updateDivisions && updateDivisions(values, clearSelectedWorkspaces);
  };

  useEffect(() => {
    if (
      field.value.length !== selectedValues.length ||
      difference(field.value, selectedValues).length > 0
    ) {
      setSelectedValues(field.value);
    }
  }, [field.value, selectedValues]);

  return (
    <MultiSearchAutocomplete
      id={id}
      label={label}
      values={selectedValues}
      setValue={setSelectedValuesWrapped}
      options={options}
      className={className}
      displayStackedOptions={displayStackedOptions}
      showNestedOptions={showNestedOptions}
      margin={margin}
    />
  );
};

export default AutocompleteMultiSelect;
