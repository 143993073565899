import React from 'react';
import styled from 'styled-components';
import { compose, withHandlers } from 'recompose';
import { get, last } from 'lodash/fp';
import { connect } from 'react-redux';
import FilePicker from 'components/filePicker/index';
import { Events, track, getEstimateData } from 'components/analytics';
import { createFile } from 'state/files/actions';
import { getEstimateById } from 'state/estimates/selectors';
import FileList from './fileList';

const Container = styled.div`
  padding: 10px;
`;

const mapStateToProps = (state, { match }) => {
  const estimateId = match.params.id;
  return {
    files: get('files', getEstimateById(estimateId)(state)),
    estimateId,
    estimate: getEstimateById(estimateId)(state),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchCreateFile: (estimateId, filename, path) =>
    dispatch(createFile(estimateId, filename, path)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    addFile: ({ dispatchCreateFile, estimate }) => estimateId => ({ filename, path }) => {
      dispatchCreateFile(estimateId, filename, path);
      const fileExtension = last(filename.split('.'));
      track(Events.ESTIMATE_ADD_ATTACHMENT, { ...getEstimateData(estimate), fileExtension });
    },
  }),
)(({ files, addFile, estimateId }) => (
  <Container>
    <FilePicker
      estimateId={estimateId}
      onFinish={addFile(estimateId)}
      multiple={true}
      dragndrop={true}
    />
    <FileList files={files} estimateId={estimateId} />
  </Container>
));
