import queryString from 'query-string';

import ApiService from './ApiService';

interface Query {
  accountId: string;
  page: number;
}

export type SupplierMetadata = Record<string, { id: string; value: string }>;

export interface Supplier {
  id: string;
  name: string;
  accountId: string;
  userAdded: boolean;
  metadata: SupplierMetadata;
  status: 'PENDING_APPROVAL' | 'APPROVED' | 'DECLINED';
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export interface SupplierColumn {
  id: string;
  name: string;
  accountId: string;
}

export interface SupplierPaginatedData {
  data: Supplier[];
  nextPage: number | null;
}

interface CreateSupplierForm {
  accountId: string;
  name: string;
}

interface CreateSupplierColumnForm {
  accountId: string;
  name: string;
}

export interface UpdateSupplierForm {
  name: string;
}

const SupplierService = {
  getSuppliers: (query: Query) =>
    ApiService.get<SupplierPaginatedData>(
      queryString.stringifyUrl({ url: '/suppliers', query: { ...query } })
    ),

  createSupplier: (data: CreateSupplierForm) => ApiService.post('/suppliers', data),

  getSupplier: (supplierId: string) => ApiService.get<Supplier>(`/suppliers/${supplierId}`),

  removeSupplier: (supplierId: string) => ApiService.delete(`/suppliers/${supplierId}`),

  updateSupplier: (supplierId: string, data: UpdateSupplierForm) =>
    ApiService.put(`/suppliers/${supplierId}`, data),

  getSupplierColumns: (accountId: string) =>
    ApiService.get<SupplierColumn[]>(
      queryString.stringifyUrl({ url: '/suppliers/columns', query: { accountId } })
    ),

  createSupplierColumn: (data: CreateSupplierColumnForm) =>
    ApiService.post<SupplierColumn>(`/suppliers/columns`, data),

  importSuppliers: (accountId: string, file: File) => {
    const formData = new FormData();

    formData.append('accountId', accountId);
    formData.append('file', file);

    return ApiService.post('/suppliers/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  approveSupplier: (supplierId: string) => ApiService.get(`/suppliers/${supplierId}/approve`),

  rejectSupplier: (supplierId: string) => ApiService.get(`/suppliers/${supplierId}/reject`),
};

export default SupplierService;
