import React from 'react';

export default ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="26"
    viewBox="0 0 30 26"
    className={className}
  >
    <defs>
      <filter
        id="a"
        width="132.7%"
        height="133.3%"
        x="-17.9%"
        y="-16.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5" />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g fill="currentColor" fillRule="evenodd" filter="url(#a)" transform="translate(1)">
      <path
        fillRule="nonzero"
        d="M17.757 3.746C12.164 3.746 7.63 8.28 7.63 13.873A10.127 10.127 0 1 0 17.757 3.746zm-.07 2.08a8.116 8.116 0 1 1 0 16.232 8.116 8.116 0 0 1 0-16.231zm.07 1.943a6.104 6.104 0 1 0 0 12.208 6.104 6.104 0 0 0 0-12.208zm0 2.08a4.023 4.023 0 1 1 0 8.047 4.023 4.023 0 0 1 0-8.046zm-.07 2.082a2.012 2.012 0 1 0 0 4.023 2.012 2.012 0 0 0 0-4.023z"
      />
      <path d="M9.003 6.685l9.316 6.283L17 14.923 7.684 8.64z" />
      <path
        fillRule="nonzero"
        d="M7.054 3.736a.224.224 0 0 1 .176-.262l1.625-.323a.223.223 0 0 1 .263.176l1.021 5.277a.224.224 0 0 1-.177.262L4.692 9.89a.223.223 0 0 1-.262-.176l-.314-1.621a.224.224 0 0 1 .177-.263l3.424-.668-.663-3.425z"
      />
      <path
        fillRule="nonzero"
        d="M3.766 1.47a.224.224 0 0 1 .176-.262L5.567.885a.223.223 0 0 1 .263.176L6.85 6.338a.224.224 0 0 1-.177.262l-5.27 1.023a.223.223 0 0 1-.262-.176L.828 5.826a.224.224 0 0 1 .177-.263l3.424-.668-.663-3.425z"
      />
    </g>
  </svg>
);
