import React, { useState, useMemo, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import clsx from 'clsx';
import { Field, useFormikContext } from 'formik';
import { entries, flow, keys, map, sortBy, times, values, isEmpty } from 'lodash/fp';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import {
  EstimateStatuses,
  EstimateStatusLabels,
  BudgetSources,
  MarsProductionComplexity,
  CampaignStatusLabels,
  BudgetCenter,
  YearField,
  ScaleOrNonScaleProject,
} from 'cr-core/constants';
import AutocompleteMultiSelect from 'components/filters/formik.autocompleteMultiSelect';
import DoubleFieldGroupAutocomplete from 'components/filters/formik.doubleFieldGroupAutocomplete';
import CountrySelect from 'components/filters/formik.countrySelect';
import DateRangePicker from 'components/filters/formik.dateRangePicker';
import { connect } from 'react-redux';
import { getEstimateTypeOptions } from 'state/estimates/selectors';
import { getUserWorkspaces } from 'state/workspaces/selectors';
import { isResourceLoading } from 'state/resourceStatus/selectors';
import { getProductBrand } from './utils';
import { getCampaignHumanIdAndClientIo, getCampaigns } from 'state/campaigns/selectors';
import { fetchCampaignHumanIdAndClientIo } from 'state/campaigns/actions';
import { useQuery } from 'react-query';
import EstimateService from 'services/EstimateService';

const createOptionsFromValues = flow(
  values,
  map(label => ({ value: label, label }))
);
const createOptionsFromEntries = flow(
  entries,
  map(([value, label]) => ({ value, label }))
);

const estimateStatusOptions = flow(
  keys,
  map(type => ({ value: type, label: EstimateStatusLabels[type] })),
  sortBy('label')
)(EstimateStatuses);
const budgetSourceOptions = flow(createOptionsFromValues, sortBy('label'))(BudgetSources);
const campaignStatusOptions = [
  ...createOptionsFromEntries(CampaignStatusLabels),
  { value: 'EMPTY', label: 'Empty' },
];
const productionComplexityOptions = createOptionsFromValues(MarsProductionComplexity);

const startYear = 2019;
const budgetYears = [
  startYear,
  ...times(i => startYear + i + 1, new Date().getFullYear() - startYear + 1),
];
const budgetYearOptions = map(year => ({ value: `${year}`, label: `${year}` }), budgetYears);

const margin = 'none'; //'dense';

const useStyles = makeStyles(theme => {
  return {
    controlMarginDense: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      padding: '0 14px',
    },
    checkboxControl: {
      padding: '6px 14px',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.text.secondary,
    },
  };
});

/* Checkbox */

export const CheckboxControl = ({ name, id, label, ...props }) => {
  const classes = useStyles();
  return (
    <FormControl fullWidth className={classes.formControl} {...props}>
      <div
        className={clsx(classes.checkboxControl, {
          [classes.controlMarginDense]: margin === 'dense',
        })}
      >
        <Field
          name={name}
          id={id}
          type="checkbox"
          component={CheckboxWithLabel}
          size={margin === 'dense' ? 'small' : 'medium'}
          color="primary"
          Label={{ label }}
        />
      </div>
    </FormControl>
  );
};

export const ExcludeCancelledEstimates = ({ ...props }) => (
  <CheckboxControl
    name="excludeCancelledEstimates"
    id="exclude-cancelled-estimates-checkbox"
    label="Exclude Cancelled Estimates"
    {...props}
  />
);

export const AssignedToMeFilter = ({ ...props }) => (
  <CheckboxControl
    name="assignedToMe"
    id="assigned-to-me-checkbox"
    label="Assigned To Me"
    {...props}
  />
);

/* TextField */

export const Search = ({ ...props }) => {
  return (
    <FormControl fullWidth {...props}>
      <Field
        name="search"
        id="search-input"
        variant="outlined"
        margin={margin}
        component={TextField}
        label="Search"
        inputProps={{
          id: 'search-input',
          notched: 'true',
        }}
      />
    </FormControl>
  );
};

/* Dropdown */

export const ReportingYearFilter = ({ displayStackedOptions, ...props }) => {
  return (
    <Field
      name="reportingYear"
      label="Year"
      variant="outlined"
      id="reporting-year-select"
      margin={margin}
      multiple
      showMultipleValues={true}
      options={budgetYearOptions}
      disableClearable={true}
      displayStackedOptions={displayStackedOptions}
      component={AutocompleteMultiSelect}
      {...props}
    />
  );
};

export const BudgetSourceFilter = ({ displayStackedOptions, ...props }) => {
  return (
    <Field
      name="budgetSource"
      label="Budget Source"
      variant="outlined"
      id="budget-source-select"
      margin={margin}
      multiple
      displayStackedOptions={displayStackedOptions}
      options={budgetSourceOptions}
      disableClearable={true}
      component={AutocompleteMultiSelect}
      {...props}
    />
  );
};

export const CategoryFilter = ({ categories, isLoading, ...props }) => {
  return (
    <Field
      name="productCategory"
      label="Segment"
      variant="outlined"
      id="category-select"
      margin={margin}
      multiple
      isLoading={isLoading}
      options={map(name => ({ value: name, label: name }), categories)}
      disableClearable={true}
      component={AutocompleteMultiSelect}
      {...props}
    />
  );
};

export const CategoryBrandFilter = compose(
  withRouter,
  connect(state => {
    return {
      brands: getProductBrand(state),
      isBrandsLoading: isResourceLoading('products')(state),
    };
  })
)(({ brands, isBrandsLoading, displayStackedOptions, location, ...props }) => {
  const [brandCategories, setBrandCategories] = useState(null);

  const { values } = useFormikContext();

  useEffect(() => {
    if (isEmpty(values.brandCategories)) {
      const groups = [...new Set(brands.map(brand => brand.brandCategory))];
      let brandCategories = {};
      groups.forEach(item => {
        brandCategories[item] = [];
      });
      setBrandCategories(brandCategories);
    }

    if (
      !location.state &&
      ['/campaigns', '/estimates', 'reports'].includes(location.pathname) &&
      !isEmpty(values.brandCategories)
    ) {
      setBrandCategories(values.brandCategories);
    }

    if (
      location.state &&
      !isEmpty(values.brandCategories) &&
      location.state.prevPath &&
      ['/campaigns', '/estimates', '/reports'].includes(location.pathname) &&
      location.state.prevPath !== location.pathname
    ) {
      setBrandCategories(
        typeof values.brandCategories !== 'object'
          ? JSON.parse(values.brandCategories)
          : values.brandCategories
      );
    }
  }, [brands, values.brandCategories, location]);

  return (
    <DoubleFieldGroupAutocomplete
      id="category-brand-select"
      options={map(
        ({ brandCategory, brandName }) => ({
          value: brandName,
          label: brandName,
          group: brandCategory,
        }),
        brands
      )}
      isLoading={isBrandsLoading}
      groupedBy="brandCategory"
      groupName="productCategory"
      itemName="productBrand"
      displayStackedOptions={displayStackedOptions}
      groupLabel="Segment"
      itemLabel="Brand"
      margin={margin}
      brandCategories={
        props.filterName === 'categoryBrand' && props.brandCategories
          ? props.brandCategories
          : brandCategories
      }
      setBrandCategories={
        props.filterName === 'categoryBrand' && props.setBrandCategories
          ? props.setBrandCategories
          : setBrandCategories
      }
      {...props}
    />
  );
});

export const CampaignStatusFilter = ({ displayStackedOptions, ...props }) => {
  return (
    <Field
      name="campaignStatus"
      label="Campaign Status"
      variant="outlined"
      id="campaign-status-select"
      margin={margin}
      multiple
      displayStackedOptions={displayStackedOptions}
      options={campaignStatusOptions}
      disableClearable={true}
      component={AutocompleteMultiSelect}
      {...props}
    />
  );
};

export const EstimateStatusFilter = ({ displayStackedOptions, ...props }) => {
  return (
    <Field
      name="estimateStatus"
      label="Estimate Status"
      variant="outlined"
      id="estimate-status-select"
      margin={margin}
      multiple
      options={estimateStatusOptions}
      disableClearable={true}
      displayStackedOptions={displayStackedOptions}
      component={AutocompleteMultiSelect}
      {...props}
    />
  );
};

const MediaTypesFilterBase = ({ displayStackedOptions, estimateTypeOptions, ...props }) => {
  return (
    <Field
      name="mediaType"
      label="Estimate Type"
      variant="outlined"
      id="media-type-select"
      margin={margin}
      multiple
      displayStackedOptions={displayStackedOptions}
      options={sortBy('label', estimateTypeOptions)}
      disableClearable={true}
      component={AutocompleteMultiSelect}
      {...props}
    />
  );
};

const mapStateToProps = state => ({
  estimateTypeOptions: getEstimateTypeOptions(state),
});

export const MediaTypesFilter = connect(mapStateToProps)(MediaTypesFilterBase);

export const ProductionComplexityFilter = ({ displayStackedOptions, ...props }) => {
  return (
    <Field
      name="productionComplexity"
      label="Production Complexity"
      variant="outlined"
      id="production-complexity-select"
      margin={margin}
      multiple
      displayStackedOptions={displayStackedOptions}
      options={productionComplexityOptions}
      disableClearable={true}
      component={AutocompleteMultiSelect}
      {...props}
    />
  );
};

export const WorkspaceFilter = connect(state => {
  return {
    workspaces: getUserWorkspaces(state),
    isWorkspacesLoading: isResourceLoading('workspaces')(state),
  };
})(({ workspaces, isWorkspacesLoading, displayStackedOptions, ...props }) => {
  return (
    <Field
      name="workspaceId"
      label="Workspace"
      variant="outlined"
      id="workspace-select"
      margin={margin}
      multiple
      options={map(({ id, name }) => ({ label: name, value: id }), workspaces)}
      disableClearable={true}
      displayStackedOptions={displayStackedOptions}
      component={AutocompleteMultiSelect}
      {...props}
    />
  );
});

export const PayingCountryFilter = ({ displayStackedOptions, ...props }) => {
  return (
    <Field
      name="payingCountries"
      label="Paying Countries"
      variant="outlined"
      id="paying-countries-select"
      margin={margin}
      multiple
      displayStackedOptions={displayStackedOptions}
      disableClearable={true}
      component={CountrySelect}
      {...props}
    />
  );
};

export const BudgetCenterFilter = ({ displayStackedOptions, ...props }) => {
  return (
    <Field
      name="budgetCenter"
      label="Budget Center"
      variant="outlined"
      margin={margin}
      multiple
      displayStackedOptions={displayStackedOptions}
      options={Object.entries(BudgetCenter).map(([value, label]) => ({
        value,
        label,
      }))}
      disableClearable
      component={AutocompleteMultiSelect}
      {...props}
    />
  );
};

export const BudgetYearFilter = ({ displayStackedOptions, ...props }) => {
  return (
    <Field
      name="budgetYear"
      label="Budget Year"
      variant="outlined"
      id="budget-year-select"
      margin={margin}
      multiple
      showMultipleValues={true}
      options={map(
        year => ({ label: String(year), value: String(year) }),
        Array.from({ length: YearField.length }, (v, k) => k + YearField.startYear)
      )}
      disableClearable={true}
      displayStackedOptions={displayStackedOptions}
      component={AutocompleteMultiSelect}
      {...props}
    />
  );
};

export const LeadMarketFilter = ({ displayStackedOptions, ...props }) => {
  return (
    <Field
      name="leadMarket"
      label="Lead Market"
      variant="outlined"
      id="lead-market-select"
      margin={margin}
      displayStackedOptions={displayStackedOptions}
      multiple
      disableClearable={true}
      component={CountrySelect}
      {...props}
    />
  );
};

export const DivisionFilter = ({ displayStackedOptions, ...props }) => {
  return (
    <Field
      name="division"
      label="Region"
      variant="outlined"
      id="division-market-select"
      margin={margin}
      displayStackedOptions={displayStackedOptions}
      showNestedOptions={false}
      multiple
      disableClearable={true}
      component={CountrySelect}
      {...props}
    />
  );
};

export const AdditionalFilters = ({ options, ...props }) => {
  return (
    <Field
      name="additionalFilters"
      label="Add Filter"
      variant="outlined"
      id="additional-filters-select"
      margin={margin}
      multiple
      options={options}
      disableClearable={true}
      disableSelectAll={true}
      withCheckbox={false}
      filterSelectedOptions={true}
      noRenderValues={true}
      component={AutocompleteMultiSelect}
      {...props}
    />
  );
};

export const DateRangeFilter = props => {
  return (
    <Field
      variant="outlined"
      margin={margin}
      multiple
      disableClearable={true}
      component={DateRangePicker}
      {...props}
    />
  );
};

/* Date Range Picker */
export const ReportingDateControl = props => {
  const { name, id, label, ...rest } = props;

  return (
    <Field
      name="reportingDate"
      label="Air Date"
      variant="outlined"
      id="reporting-date-range-picker"
      margin={margin}
      multiple
      disableClearable={true}
      component={DateRangePicker}
      {...rest}
    />
  );
};

export const ProductionStartDateControl = props => {
  const { name, id, label, ...rest } = props;

  return (
    <Field
      name="productionStartDate"
      label="Production Start Date"
      variant="outlined"
      id="production-start-date-range-picker"
      margin={margin}
      multiple
      disableClearable={true}
      component={DateRangePicker}
      {...rest}
    />
  );
};

export const DeliveryDateControl = props => {
  const { name, id, label, ...rest } = props;

  return (
    <Field
      name="deliveryDate"
      label="Delivery Date"
      variant="outlined"
      id="delivery-date-range-picker"
      margin={margin}
      multiple
      disableClearable={true}
      component={DateRangePicker}
      {...rest}
    />
  );
};

export const CampaignAgencyJobFilter = ({ displayStackedOptions, agencyJobNumbers, ...props }) => {
  return (
    <Field
      name="agencyJobNumber"
      label="Agency Job #"
      variant="outlined"
      id="campaign-agency-job-select"
      margin={margin}
      multiple
      showMultipleValues={true}
      options={agencyJobNumbers}
      disableClearable={true}
      displayStackedOptions={displayStackedOptions}
      component={AutocompleteMultiSelect}
      {...props}
    />
  );
};

export const CampaignIDFilter = connect(
  state => {
    return {
      campaings: getCampaigns(state),
      campaignHumanIdsAndClientIo: getCampaignHumanIdAndClientIo(state),
    };
  },
  dispatch => ({
    fetchCampaignHumanIdAndClientIo: () => dispatch(fetchCampaignHumanIdAndClientIo()),
  })
)(
  ({
    displayStackedOptions,
    campaignHumanIdsAndClientIo,
    fetchCampaignHumanIdAndClientIo,
    campaings,
    ...props
  }) => {
    useEffect(() => {
      fetchCampaignHumanIdAndClientIo();
    }, [fetchCampaignHumanIdAndClientIo, campaings]);

    return (
      <Field
        name="campaignHumanId"
        label="Campaign ID #"
        variant="outlined"
        id="campaign-human-id-select"
        margin={margin}
        multiple
        showMultipleValues={true}
        options={map(
          ({ humanId }) => ({ label: humanId.toString(), value: humanId.toString() }),
          campaignHumanIdsAndClientIo
        )}
        disableClearable={true}
        displayStackedOptions={displayStackedOptions}
        component={AutocompleteMultiSelect}
        {...props}
      />
    );
  }
);

export const EstimateIDFilter = ({
  displayStackedOptions,
  campaignHumanIdsAndClientIo,
  fetchCampaignHumanIdAndClientIo,
  campaings,
  ...props
}) => {
  const { data = [] } = useQuery(['estimates', 'ids'], () =>
    EstimateService.getIds().then(({ data }) => data)
  );

  const options = data.map(item => {
    const value = item.toString();
    return { value, label: value };
  });

  return (
    <Field
      name="estimateHumanId"
      label="Estimate ID #"
      variant="outlined"
      id="estimate-human-id-select"
      margin={margin}
      multiple
      showMultipleValues={true}
      options={options}
      disableClearable={true}
      displayStackedOptions={displayStackedOptions}
      component={AutocompleteMultiSelect}
      {...props}
    />
  );
};

export const CampaignClientIOFilter = connect(
  state => {
    return {
      campaings: getCampaigns(state),
      campaignHumanIdsAndClientIo: getCampaignHumanIdAndClientIo(state),
    };
  },
  dispatch => ({
    fetchCampaignHumanIdAndClientIo: () => dispatch(fetchCampaignHumanIdAndClientIo()),
  })
)(
  ({
    displayStackedOptions,
    campaignHumanIdsAndClientIo,
    fetchCampaignHumanIdAndClientIo,
    campaings,
    ...props
  }) => {
    useEffect(() => {
      fetchCampaignHumanIdAndClientIo();
    }, [fetchCampaignHumanIdAndClientIo, campaings]);
    const [clientIONumbers] = useMemo(
      () =>
        (campaignHumanIdsAndClientIo || []).reduce(
          (acc, cur) => {
            if (!!cur.clientIoNumber && !acc[0].includes(cur.clientIoNumber)) {
              acc[0].push(cur?.clientIoNumber);
            }

            return acc;
          },
          [[]]
        ),
      [campaignHumanIdsAndClientIo]
    );

    return (
      <Field
        name="clientIoNumber"
        label="Global Campaign ID"
        variant="outlined"
        id="client-io-number-select"
        margin={margin}
        multiple
        showMultipleValues={true}
        options={map(
          clientIoNumber => ({ label: clientIoNumber, value: clientIoNumber }),
          clientIONumbers
        )}
        disableClearable={false}
        displayStackedOptions={displayStackedOptions}
        component={AutocompleteMultiSelect}
        {...props}
      />
    );
  }
);

export const ScaleOrNonScaleFilter = ({ displayStackedOptions, ...props }) => {
  return (
    <Field
      name="scaleOrNonScale"
      label="Scale/Non-Scale"
      variant="outlined"
      id="scale-or-non-scale-select"
      margin={margin}
      multiple
      displayStackedOptions={displayStackedOptions}
      disableClearable={true}
      component={AutocompleteMultiSelect}
      options={Object.entries(ScaleOrNonScaleProject).map(([value, label]) => ({
        value,
        label,
      }))}
      {...props}
    />
  );
};
