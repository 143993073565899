import { useMutation, useQueryClient } from 'react-query';
import SupplierService from 'services/SupplierService';

const useSuppliersImport = (accountId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ file }: { file: File }) =>
      SupplierService.importSuppliers(accountId, file).then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['account', accountId, 'suppliers'], { exact: true });
      },
    }
  );
};

export default useSuppliersImport;
