import React from 'react';

const SvgDraftIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="draft-icon_svg__a"
        width="138.1%"
        height="133.3%"
        x="-19%"
        y="-16.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      d="M13.4 0L21 7.193v14.14C21 22.8 19.74 24 18.2 24H2.786C1.246 24 0 22.8 0 21.333L.014 2.667C.014 1.2 1.26 0 2.8 0h10.6zm-.8 8.19h6.5L12.6 2v6.19zm5.4 5.928v-1.815H3.4v1.815H18zM3.4 15.529v1.816H18v-1.816H3.4zm9.8 5.042v-1.815H3.4v1.815h9.8z"
      filter="url(#draft-icon_svg__a)"
      transform="translate(5 3)"
    />
  </svg>
);

export default SvgDraftIcon;
