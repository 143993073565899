import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { Events, track, getEstimateData } from 'components/analytics';
import { SendRequestForChangesButton, CancelButton } from 'components/buttons';
import Modal from 'components/modal';
import SvgBackIcon from 'icons/BackIcon';
import SvgRequestChangesIcon from 'icons/RequestChangesIcon';
import { sendRequestForChanges } from 'state/estimateApprovals/actions';
import { getEstimateById } from 'state/estimates/selectors';
import { AdditionalMessage } from './approval';

const mapStateToProps = (state, { estimateId }) => ({
  estimate: getEstimateById(estimateId)(state),
});

const mapDispatchToProps = (dispatch, { estimateId, pageId }) => ({
  requestChanges: form => dispatch(sendRequestForChanges(estimateId, form, pageId)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    onSubmit: ({ estimate, requestChanges, onRequestClose }) => ({ message }) => {
      requestChanges({ message });
      track(Events.ESTIMATE_REQUEST_CHANGES_SUCCESS, {
        ...getEstimateData(estimate),
        message: Boolean(message),
        messageLength: message ? message.length : 0,
      });
      onRequestClose();
    },
    cancel: ({ estimate, onRequestClose }) => () => {
      track(Events.ESTIMATE_REQUEST_CHANGES_CANCEL, getEstimateData(estimate));
      onRequestClose();
    },
  }),
  reduxForm({ form: 'requestChanges' })
)(({ handleSubmit, cancel, ...restProps }) => {
  const buttons = (
    <>
      <CancelButton onClick={cancel}>
        <SvgBackIcon className="icon" />
        Cancel
      </CancelButton>
      <SendRequestForChangesButton onClick={handleSubmit}>
        <SvgRequestChangesIcon className="icon" />
        Send Request for Changes
      </SendRequestForChangesButton>
    </>
  );
  return (
    <Modal title="Request Changes" buttons={buttons} {...restProps} onRequestClose={cancel}>
      <div>
        <div className="title">
          Please let Approvers and Watchers know what changes you would like to have made...
        </div>
        <form onSubmit={handleSubmit}>
          <AdditionalMessage>
            <Field className="messageInput" name="message" component="textarea" />
          </AdditionalMessage>
        </form>
      </div>
    </Modal>
  );
});
