import React from 'react';
import { connect } from 'react-redux';
import { get, map, find } from 'lodash/fp';
import { withRouter } from 'react-router-dom';
import { MenuItem, Select } from '@material-ui/core';
import { Events, track } from 'components/analytics';
import { getDisplayCurrency } from 'state/authentication/selectors';
import { setDisplayCurrency } from 'state/users/actions';
import { updatedCurrencies } from 'cr-core/currencies';

const CurrencySelector = ({ selectedCurrency, changeCurrency }) => (
  <Select
    value={selectedCurrency || ''}
    renderValue={value => `Currency: ${get('label', find({ value }, updatedCurrencies))}`}
    onChange={changeCurrency}
    disableUnderline={true}
  >
    {map(
      ({ value, label }) => (
        <MenuItem value={value} key={value}>
          {label}
        </MenuItem>
      ),
      updatedCurrencies
    )}
  </Select>
);

const mapStateToProps = state => ({
  selectedCurrency: getDisplayCurrency(state),
});

const mapDispatchToProps = dispatch => ({
  changeCurrency: event => {
    const currency = event.target.value;
    dispatch(setDisplayCurrency(currency));
    track(Events.CHANGE_DISPLAY_CURRENCY, { currency });
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CurrencySelector));
