var _require = require('lodash/fp'),
    getOr = _require.getOr,
    isNumber = _require.isNumber;

var locale = 'en-US';

var formatAsCurrency = function formatAsCurrency() {
  var currency = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'usd';
  var value = arguments.length > 1 ? arguments[1] : undefined;
  var numberOfDecimalPlaces = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

  if (!isNumber(value)) {
    return '';
  }

  return "".concat(value.toLocaleString(locale, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: numberOfDecimalPlaces,
    maximumFractionDigits: numberOfDecimalPlaces
  }));
};

var converter = function converter(exchangeRates) {
  return function (amount, baseCurrency) {
    var convertToQuoteCurrency = function convertToQuoteCurrency(quoteCurrency) {
      var defaultQuoteValue = getOr(0, baseCurrency, exchangeRates.baseCurrencies) * amount;

      if (quoteCurrency === exchangeRates.quoteCurrency) {
        return defaultQuoteValue;
      }

      return defaultQuoteValue * (1 / getOr(0, quoteCurrency, exchangeRates.baseCurrencies));
    };

    return {
      to: convertToQuoteCurrency
    };
  };
};

var createConverter = function createConverter(exchangeRates, baseCurrency) {
  return function (amount, currency) {
    return parseFloat(exchangeRates.baseCurrencies[currency]) / parseFloat(exchangeRates.baseCurrencies[baseCurrency]) * amount;
  };
};

module.exports = {
  formatAsCurrency: formatAsCurrency,
  converter: converter,
  createConverter: createConverter
};