export default [{
  value: 'usd',
  label: 'USD ($)'
}, {
  value: 'gbp',
  label: 'GBP (£)'
}, {
  value: 'eur',
  label: 'EUR (€)'
}, {
  value: 'cny',
  label: 'CNY (¥)'
}, {
  value: 'thb',
  label: 'THB (฿)'
}, {
  value: 'aud',
  label: 'AUD'
}, {
  value: 'brl',
  label: 'BRL'
}, {
  value: 'cad',
  label: 'CAD'
}, {
  value: 'chf',
  label: 'CHF'
}, {
  value: 'czk',
  label: 'CZK'
}, {
  value: 'huf',
  label: 'HUF'
}, {
  value: 'idr',
  label: 'IDR'
}, {
  value: 'mxn',
  label: 'MXN'
}, {
  value: 'nzd',
  label: 'NZD'
}, {
  value: 'pln',
  label: 'PLN'
}, {
  value: 'rub',
  label: 'RUB'
}];
export var updatedCurrencies = [{
  value: 'usd',
  label: 'USD ($)'
}, {
  value: 'gbp',
  label: 'GBP (£)'
}, {
  value: 'eur',
  label: 'EUR (€)'
}, {
  value: 'cny',
  label: 'CNY (¥)'
}, {
  value: 'thb',
  label: 'THB (฿)'
}, {
  value: 'aud',
  label: 'AUD'
}, {
  value: 'brl',
  label: 'BRL'
}, {
  value: 'cad',
  label: 'CAD'
}, {
  value: 'chf',
  label: 'CHF'
}, {
  value: 'czk',
  label: 'CZK'
}, {
  value: 'huf',
  label: 'HUF'
}, {
  value: 'idr',
  label: 'IDR'
}, {
  value: 'mxn',
  label: 'MXN'
}, {
  value: 'nzd',
  label: 'NZD'
}, {
  value: 'pln',
  label: 'PLN'
}, {
  value: 'rub',
  label: 'RUB'
}];