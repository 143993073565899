import { createSelector } from 'reselect';
import { flow, get, getOr, differenceBy, concat, map, find } from 'lodash/fp';
import { getApprovers } from 'state/estimates/selectors';
import { getEstimateWatchers } from 'state/estimateWatchers/selectors';
import { getUser } from 'state/authentication/selectors';

export const getWorkspacesById = get('workspaces');

export const getWorkspacesError = get('workspaces.error');
export const getWorkspacesPagination = get('workspaces.pagination');

export const getWorkspaces = get('workspaces.list');
export const getUserWorkspaces = get('workspaces.userWorkspaces');

export const getFilteredWorkspaces = getWorkspaces;

export const getWorkspaceById = workspaceId => flow(getWorkspaces, find({ id: workspaceId }));

export const getUserWorkspaceById = workspaceId =>
  flow(getUserWorkspaces, find({ id: workspaceId }));

export const getWorkspaceMembers = workspaceId => getOr([], `workspaces.members[${workspaceId}]`);

export const getPickableWorkspaceMembers = estimateId => workspaceId =>
  createSelector(
    getWorkspaceMembers(workspaceId),
    getApprovers(estimateId),
    getEstimateWatchers,
    (workspaceMembers, approvers, watchers) =>
      flow(concat(map('user', watchers)), differenceBy('id', workspaceMembers))(approvers),
  );

export const getWorkspaceName = workspaceId => get(`workspaces.${workspaceId}.name`);

export const canCreateEstimate = createSelector(
  getUserWorkspaces,
  workspaces => workspaces.length > 0,
);

export const doesUserOwnWorkspace = workspace =>
  createSelector(
    getUser,
    user => workspace && map('id', user.accounts).includes(workspace.accountId),
  );
