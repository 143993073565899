import {
  keyBy,
  sortBy,
  map,
  flow,
  get,
  identity,
  merge,
  fromPairs,
  mapValues,
  flatten,
} from 'lodash/fp';
import WorldCountries from 'world-countries';
import {
  worldCountries,
  africa,
  caribbean,
  asiaPacific,
  europe,
  centralAmerica,
  northAmerica,
  southAmerica,
  middleEast,
  AFRICA,
  CARIBBEAN,
  ASIA_PACIFIC,
  EUROPE,
  CENTRAL_AMERICA,
  NORTH_AMERICA,
  SOUTH_AMERICA,
  MIDDLE_EAST,
  GLOBAL,
} from 'cr-core/marsRegions';

const missingCountriesMap = {
  'Czech Republic': {
    name: 'Czech Republic',
    flag: require('world-countries/data/cze.svg'),
    countryCode: 'CZE',
  },
  Macedonia: {
    name: 'Macedonia',
    flag: require('world-countries/data/mkd.svg'),
    countryCode: 'MKD',
  },
  'Democratic Republic of Congo': {
    name: 'Democratic Republic of Congo',
    flag: require('world-countries/data/cog.svg'),
    countryCode: 'COG',
  },
  "Côte D'Ivoire": {
    name: "Côte D'Ivoire",
    flag: require('world-countries/data/civ.svg'),
    countryCode: 'CIV',
  },

  'Saint Helena': {
    name: 'Saint Helena',
    flag: require('world-countries/data/shn.svg'),
    countryCode: 'SHN',
  },
  'Sao Tome and Principe': {
    name: 'Sao Tome and Principe',
    flag: require('world-countries/data/stp.svg'),
    countryCode: 'STP',
  },

  Swaziland: {
    name: 'Swaziland',
    flag: require('world-countries/data/swz.svg'),
    countryCode: 'SWZ',
  },

  'Saint Barthelemy': {
    name: 'Saint Barthelemy',
    flag: require('world-countries/data/blm.svg'),
    countryCode: 'BLM',
  },
  'Virgin Islands, British': {
    name: 'Virgin Islands, British',
    flag: require('world-countries/data/vgb.svg'),
    countryCode: 'VGB',
  },
  'Virgin Islands, U.S.': {
    name: 'Virgin Islands, U.S.',
    flag: require('world-countries/data/vir.svg'),
    countryCode: 'VIR',
  },
  'Brunei Darussalam': {
    name: 'Brunei Darussalam',
    flag: require('world-countries/data/brn.svg'),
    countryCode: 'BRN',
  },
  "Lao People's Democratic Republic": {
    name: "Lao People's Democratic Republic",
    flag: require('world-countries/data/lao.svg'),
    countryCode: 'LAO',
  },
  'Micronesia, Federated States of': {
    name: 'Micronesia, Federated States of',
    flag: require('world-countries/data/fsm.svg'),
    countryCode: 'FSM',
  },
  Reunion: {
    name: 'Reunion',
    flag: require('world-countries/data/reu.svg'),
    countryCode: 'REU',
  },
  'Russian Federation': {
    name: 'Russian Federation',
    flag: require('world-countries/data/rus.svg'),
    countryCode: 'RUS',
  },
  'Falkland Islands (Malvinas)': {
    name: 'Falkland Islands (Malvinas)',
    flag: require('world-countries/data/flk.svg'),
    countryCode: 'FLK',
  },
  'Palestinian Territory': {
    name: 'Palestinian Territory',
    flag: require('world-countries/data/pse.svg'),
    countryCode: 'PSE',
  },
};

const allCountriesByName = flow(
  map(({ name, cca3: countryCode }) => {
    return {
      name: name.common,
      flag: require(`world-countries/data/${countryCode.toLowerCase()}.svg`),
      countryCode,
    };
  }),
  merge(missingCountriesMap),
  keyBy('name')
)(WorldCountries);

const decorateWithInfo = flow(
  sortBy(identity),
  map(country => get(country, allCountriesByName))
);

const decoratedRegions = [
  { name: 'Africa', code: AFRICA, countries: decorateWithInfo(africa) },
  { name: 'Caribbean', code: CARIBBEAN, countries: decorateWithInfo(caribbean) },
  { name: 'Asia Pacific', code: ASIA_PACIFIC, countries: decorateWithInfo(asiaPacific) },
  { name: 'Europe', code: EUROPE, countries: decorateWithInfo(europe) },
  { name: 'Central America', code: CENTRAL_AMERICA, countries: decorateWithInfo(centralAmerica) },
  { name: 'North America', code: NORTH_AMERICA, countries: decorateWithInfo(northAmerica) },
  { name: 'South America', code: SOUTH_AMERICA, countries: decorateWithInfo(southAmerica) },
  { name: 'Middle East', code: MIDDLE_EAST, countries: decorateWithInfo(middleEast) },
  { name: 'Global', code: GLOBAL },
];

export const decoratedMarsCountries = decorateWithInfo(worldCountries);

export default decoratedRegions;

export const regionsByCode = flow(keyBy('code'), mapValues('name'))(decoratedRegions);

export const countries = flow(map('countries'), flatten, sortBy('name'))(decoratedRegions);

export const countriesByName = flow(keyBy('name'), mapValues('name'))(countries);

const createRegionCountryPairs = regionName => map(countryName => [countryName, regionName]);

export const countryToRegion = fromPairs([
  ...createRegionCountryPairs(AFRICA)(africa),
  ...createRegionCountryPairs(CARIBBEAN)(caribbean),
  ...createRegionCountryPairs(ASIA_PACIFIC)(asiaPacific),
  ...createRegionCountryPairs(EUROPE)(europe),
  ...createRegionCountryPairs(CENTRAL_AMERICA)(centralAmerica),
  ...createRegionCountryPairs(NORTH_AMERICA)(northAmerica),
  ...createRegionCountryPairs(SOUTH_AMERICA)(southAmerica),
  ...createRegionCountryPairs(MIDDLE_EAST)(middleEast),
  ['Africa', AFRICA],
  ['Caribbean', CARIBBEAN],
  ['Asia Pacific', ASIA_PACIFIC],
  ['Europe', EUROPE],
  ['Central America', CENTRAL_AMERICA],
  ['North America', NORTH_AMERICA],
  ['South America', SOUTH_AMERICA],
  ['Middle East', MIDDLE_EAST],
  ['Global', GLOBAL],
]);
