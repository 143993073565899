import React, { useMemo } from 'react';
import { useField, Field } from 'formik';
import { flow, map, sortBy, uniq, concat } from 'lodash/fp';
import { Select } from 'formik-material-ui';
import { MultiSearchGroupAutocomplete } from 'components/filters/formik.groupSelect';

const DoubleFieldGroupAutocomplete = ({
  id,
  className,
  margin,
  options = [],
  isLoading,
  groupedBy,
  displayStackedOptions,
  groupName,
  itemName,
  groupLabel,
  itemLabel,
  brandCategories,
  setBrandCategories,
  ...props
}) => {
  const [fieldGroups, , helpersGroup] = useField(groupName);
  const [fieldValues, , helpersItem] = useField(itemName);
  const [, , helpersBrandCategories] = useField('brandCategories');
  const selectedValues = fieldValues.value || [];
  const selectedGroup = !fieldGroups.value
    ? []
    : Array.isArray(fieldGroups.value)
    ? fieldGroups.value
    : [fieldGroups.value];

  const internalOptions = useMemo(
    () =>
      flow(
        map('group'),
        uniq,
        map(value => ({ value, label: value, group: value })),
        concat(options),
        sortBy(['group', ({ label, group }) => (label === group ? -1 : 1), 'label'])
      )(options),
    [options]
  );

  return (
    <>
      <MultiSearchGroupAutocomplete
        id={id}
        label={`${groupLabel} / ${itemLabel}`}
        isLoading={isLoading}
        options={internalOptions}
        setValues={itemValues => {
          helpersItem.setValue(itemValues);
        }}
        setGroups={groupValues => {
          helpersGroup.setValue(groupValues);
        }}
        setBrandCategoriesValues={brandCategoriesVal => {
          helpersBrandCategories.setValue(brandCategoriesVal);
        }}
        values={selectedValues}
        groups={selectedGroup}
        displayStackedOptions={displayStackedOptions}
        className={className}
        margin={margin}
        brandCategories={brandCategories}
        setBrandCategories={setBrandCategories}
      />
      <Field
        name={groupName}
        label={groupLabel}
        variant="outlined"
        id="category-select"
        margin={margin}
        multiple
        component={Select}
        style={{ display: 'none' }}
        {...props}
      />
      <Field
        name={itemName}
        label={itemLabel}
        variant="outlined"
        id="brand-select"
        margin={margin}
        multiple
        component={Select}
        style={{ display: 'none' }}
        {...props}
      />
    </>
  );
};

export default DoubleFieldGroupAutocomplete;
