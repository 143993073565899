import React from 'react';

const SvgIntegratedIcon = (props: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path d="M2480 4920 l0 -120 80 0 80 0 0 120 0 120 -80 0 -80 0 0 -120z" />
        <path
          d="M1820 4735 l-54 -55 84 -85 84 -85 58 57 58 57 -82 83 c-46 46 -85
83 -88 83 -3 0 -30 -25 -60 -55z"
        />
        <path
          d="M3152 4707 l-82 -83 58 -57 58 -57 84 85 84 85 -54 55 c-30 30 -57
55 -60 55 -3 0 -42 -37 -88 -83z"
        />
        <path
          d="M2415 4704 c-214 -48 -400 -194 -496 -389 -49 -100 -69 -175 -76
-290 -13 -208 59 -399 213 -564 39 -42 77 -87 85 -101 9 -16 15 -77 19 -175 6
-135 9 -155 33 -201 38 -75 69 -107 139 -145 l63 -34 165 0 165 0 63 34 c70
38 101 70 139 145 24 46 27 64 30 181 5 192 3 184 96 284 45 49 97 114 116
144 248 415 58 941 -394 1093 -102 34 -255 42 -360 18z m327 -177 c185 -67
319 -223 364 -424 30 -138 5 -289 -71 -419 -16 -27 -61 -83 -100 -124 -40 -42
-77 -85 -84 -98 -11 -20 -19 -22 -111 -22 l-100 0 0 94 c0 75 -3 98 -17 114
-10 11 -82 68 -161 127 l-143 106 3 51 c4 76 24 83 83 29 55 -50 100 -71 155
-71 55 0 100 21 155 71 58 54 79 47 83 -29 l3 -51 -64 -48 -65 -49 44 -58 c24
-33 46 -61 49 -64 2 -3 38 20 79 51 93 70 116 113 116 215 0 112 -35 176 -115
213 -79 36 -152 20 -228 -50 -24 -23 -50 -41 -57 -41 -7 0 -33 18 -57 41 -74
68 -144 85 -221 54 -84 -36 -121 -101 -122 -214 0 -119 16 -142 181 -267 l139
-105 0 -59 0 -60 -100 0 c-92 0 -100 2 -111 22 -6 12 -47 59 -90 105 -113 120
-165 232 -176 379 -20 272 168 531 433 599 85 21 221 13 306 -18z m58 -1287
l0 -40 -240 0 -240 0 0 40 0 40 240 0 240 0 0 -40z m-30 -209 c0 -6 -17 -23
-37 -38 -36 -28 -39 -28 -173 -28 -134 0 -137 0 -173 28 -20 15 -37 32 -37 38
0 5 84 9 210 9 126 0 210 -4 210 -9z"
        />
        <path d="M1520 4000 l0 -80 120 0 120 0 0 80 0 80 -120 0 -120 0 0 -80z" />
        <path d="M3360 4000 l0 -80 120 0 120 0 0 80 0 80 -120 0 -120 0 0 -80z" />
        <path
          d="M1327 3076 l-649 -123 -259 -259 c-227 -228 -259 -264 -259 -291 0
-61 20 -72 210 -114 96 -21 178 -38 183 -39 4 0 7 -333 7 -739 0 -690 1 -740
18 -761 14 -18 177 -61 997 -267 l980 -245 333 82 c280 69 332 85 328 99 -5
17 -9 32 -25 97 l-11 42 -270 -68 -270 -68 0 962 c0 912 1 960 18 939 109
-137 341 -395 359 -399 13 -4 323 59 696 140 370 80 676 146 680 146 4 0 7
-83 7 -185 l0 -185 80 0 80 0 0 204 0 204 78 17 c272 59 286 63 304 85 10 12
18 36 18 53 0 28 -31 63 -262 293 l-263 261 -635 117 c-349 65 -642 120 -651
122 -13 4 -19 -10 -28 -72 -7 -41 -11 -78 -8 -80 2 -3 189 -39 415 -82 226
-42 410 -78 408 -79 -2 -2 -311 -57 -689 -122 l-685 -120 -688 120 c-379 66
-689 121 -690 122 -1 1 182 37 408 79 225 42 412 79 415 82 7 6 -18 156 -26
155 -3 0 -298 -56 -654 -123z m257 -427 c446 -78 813 -143 815 -145 3 -2 -5
-14 -16 -27 -20 -22 -324 -379 -329 -385 -2 -4 -1637 349 -1644 355 -7 7 334
343 349 343 7 0 379 -63 825 -141z m2961 -29 c93 -93 167 -171 165 -173 -3 -2
-373 -84 -823 -181 -719 -156 -820 -176 -830 -163 -7 9 -84 99 -172 202 -88
102 -161 190 -163 194 -2 7 1572 287 1628 290 20 1 61 -34 195 -169z m-2065
-1239 l0 -959 -880 220 -880 220 0 674 c0 371 3 674 8 674 4 0 310 -66 680
-146 370 -80 683 -143 695 -140 13 3 95 90 197 210 96 113 176 206 178 206 1
0 2 -431 2 -959z"
        />
        <path
          d="M3884 1816 c-24 -20 -28 -32 -32 -91 l-4 -67 -45 -19 c-25 -10 -49
-19 -55 -19 -6 0 -33 20 -59 45 -74 70 -81 67 -231 -83 -150 -150 -153 -157
-83 -231 25 -26 45 -53 45 -59 0 -6 -9 -30 -19 -55 l-19 -44 -68 -6 c-58 -4
-72 -9 -91 -32 -22 -25 -23 -34 -23 -195 0 -161 1 -170 23 -195 19 -23 33 -28
91 -32 l68 -6 19 -44 c10 -25 19 -49 19 -55 0 -6 -20 -33 -45 -59 -70 -74 -67
-81 83 -231 150 -150 157 -153 231 -83 26 25 53 45 59 45 6 0 30 -9 55 -19
l44 -19 6 -68 c4 -58 9 -72 32 -91 25 -22 34 -23 195 -23 161 0 170 1 195 23
23 19 28 33 32 91 l6 68 44 19 c25 10 49 19 55 19 6 0 33 -20 59 -45 74 -69
81 -66 226 78 152 151 156 163 89 235 -25 27 -46 54 -46 60 0 6 9 30 19 55
l19 44 68 6 c58 4 72 9 91 32 22 25 23 34 23 195 0 161 -1 170 -23 195 -19 23
-33 28 -91 32 l-68 6 -19 44 c-10 25 -19 49 -19 55 0 6 20 33 45 59 70 74 67
81 -83 231 -150 150 -157 153 -231 83 -26 -25 -53 -45 -59 -45 -6 0 -30 9 -55
19 l-44 19 -6 68 c-4 58 -9 72 -32 91 -25 22 -34 23 -195 23 -163 0 -170 -1
-196 -24z m266 -173 c0 -44 28 -96 62 -115 34 -19 128 -58 167 -70 45 -13 94
-3 132 29 l31 26 45 -46 46 -45 -26 -31 c-32 -38 -42 -87 -29 -132 12 -39 51
-133 70 -167 19 -34 71 -62 115 -62 l37 0 0 -70 0 -70 -37 0 c-44 0 -96 -28
-115 -62 -19 -34 -58 -128 -70 -167 -13 -45 -3 -94 29 -132 l26 -31 -46 -45
-45 -46 -31 26 c-38 32 -87 42 -132 29 -39 -12 -133 -51 -167 -70 -34 -19 -62
-71 -62 -114 l0 -38 -70 0 -70 0 0 38 c0 43 -28 95 -62 114 -34 19 -128 58
-167 70 -45 13 -94 3 -132 -29 l-31 -26 -45 46 -46 45 25 31 c30 35 43 93 29
136 -12 37 -52 132 -69 163 -19 34 -71 62 -114 62 l-38 0 0 70 0 70 35 0 c45
0 88 22 112 57 24 35 83 185 83 212 0 30 -19 73 -43 99 l-21 23 46 46 46 46
31 -26 c38 -32 87 -42 132 -29 39 12 133 51 167 70 34 19 62 71 62 115 l0 37
70 0 70 0 0 -37z"
        />
        <path
          d="M3970 1386 c-230 -65 -368 -288 -319 -518 37 -174 180 -309 357 -340
308 -53 575 235 497 535 -22 84 -58 149 -116 207 -108 108 -278 155 -419 116z
m200 -162 c75 -22 149 -98 174 -178 88 -280 -253 -487 -461 -280 -111 111
-111 277 2 389 78 79 174 102 285 69z"
        />
      </g>
    </svg>
  );
};

export default SvgIntegratedIcon;
