import { InfiniteData, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import SupplierService, { Supplier, SupplierPaginatedData } from 'services/SupplierService';

const useSupplier = <TData extends unknown = Supplier>(
  supplierId: string,
  accountId: string,
  options?: UseQueryOptions<Supplier, unknown, TData>
) => {
  const queryClent = useQueryClient();

  return useQuery(
    ['suppliers', supplierId],
    () => SupplierService.getSupplier(supplierId).then(({ data }) => data),
    {
      initialData: () => {
        const data = queryClent.getQueryData<InfiniteData<SupplierPaginatedData>>(
          ['account', accountId, 'suppliers'],
          { exact: true }
        );

        const suppliers =
          data?.pages.reduce<Supplier[]>((acc, cur) => acc.concat(cur.data), []) ?? [];

        return suppliers.find(supplier => supplier.id === supplierId);
      },
      ...options,
    }
  );
};

export default useSupplier;
