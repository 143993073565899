import React from 'react';

const SvgIntegratedIcon = (props: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M413 4565 c-175 -47 -325 -189 -385 -363 l-23 -67 0 -1575 0 -1575
23 -67 c62 -178 210 -316 389 -362 54 -14 192 -16 1152 -16 1078 0 1091 0
1111 20 27 27 26 81 -1 106 -20 18 -57 19 -1128 24 l-1106 5 -60 29 c-73 36
-163 124 -193 189 -97 207 -12 443 196 544 51 25 68 27 163 28 105 0 107 0
190 -42 94 -48 116 -52 150 -24 l24 19 0 580 c0 556 -1 581 -19 601 -25 27
-86 29 -109 3 -15 -17 -17 -68 -17 -521 0 -475 -1 -503 -17 -496 -184 71 -412
32 -556 -95 l-47 -42 2 1329 3 1329 38 76 c43 88 107 152 195 195 51 25 67 28
162 28 93 0 111 -3 163 -27 l57 -27 0 -749 c0 -713 1 -750 18 -765 27 -24 85
-22 108 4 18 20 19 42 22 320 l3 299 1504 0 1505 0 0 -966 0 -966 166 -326
c92 -180 174 -333 182 -340 25 -20 76 -15 99 10 11 13 93 167 182 344 l161
321 0 532 0 532 -25 24 c-13 14 -36 25 -50 25 -14 0 -37 -11 -50 -25 l-25 -24
0 -486 0 -485 -245 0 -245 0 0 1115 0 1115 245 0 245 0 0 -485 0 -486 25 -24
c31 -32 69 -32 100 0 l25 24 0 286 0 285 125 0 125 0 0 -1395 0 -1395 -1009 0
-1010 0 -27 -23 c-35 -31 -38 -82 -5 -109 22 -17 68 -18 1091 -18 l1069 0 20
26 21 27 0 1492 c0 1454 0 1493 -19 1516 -19 23 -24 24 -200 27 l-180 3 -3
377 -3 377 -30 54 c-36 64 -83 105 -147 127 -39 12 -84 15 -235 12 -184 -3
-189 -4 -235 -30 -25 -16 -60 -45 -77 -65 -59 -71 -61 -90 -61 -490 l0 -363
-1505 0 -1504 0 -3 404 -3 404 -25 27 c-30 32 -128 81 -200 100 -69 18 -209
18 -277 0z m4098 -152 c50 -30 59 -64 59 -232 l0 -151 -245 0 -245 0 0 155 c0
239 5 244 243 245 132 0 164 -3 188 -17z m9 -2918 c0 -7 -130 -265 -168 -334
l-30 -54 -96 191 c-53 106 -96 194 -96 197 0 3 88 5 195 5 107 0 195 -2 195
-5z"
        />
        <path
          d="M4293 3620 c-12 -5 -26 -18 -32 -29 -8 -13 -11 -275 -11 -828 0 -879
-2 -843 55 -857 29 -7 79 15 89 39 3 9 6 380 6 825 0 755 -1 809 -17 828 -21
25 -60 35 -90 22z"
        />
        <path
          d="M1187 3222 c-15 -17 -17 -65 -17 -484 0 -438 1 -466 18 -481 17 -15
65 -17 485 -17 l466 0 15 22 c14 20 16 82 16 478 0 396 -2 458 -16 478 l-15
22 -468 0 c-441 0 -469 -1 -484 -18z m833 -482 l0 -350 -350 0 -350 0 0 350 0
350 350 0 350 0 0 -350z"
        />
        <path
          d="M1830 2933 c-8 -3 -61 -51 -117 -106 l-102 -101 -45 42 c-56 52 -103
57 -135 16 -12 -15 -21 -36 -21 -47 0 -11 35 -55 84 -105 118 -120 111 -122
286 54 81 81 142 150 145 165 11 44 -51 97 -95 82z"
        />
        <path
          d="M2451 3144 c-34 -43 -26 -86 20 -109 23 -13 111 -15 547 -15 510 0
520 0 546 21 20 16 26 29 26 59 0 73 23 70 -581 70 l-538 0 -20 -26z"
        />
        <path
          d="M2456 2794 c-32 -32 -28 -92 8 -116 6 -4 254 -8 552 -8 511 0 542 1
557 18 23 25 22 85 -1 105 -17 15 -70 17 -560 17 -478 0 -542 -2 -556 -16z"
        />
        <path
          d="M2475 2449 c-49 -28 -58 -70 -24 -113 l20 -26 330 0 c316 0 330 1
349 20 27 27 26 81 -1 106 -20 18 -42 19 -338 21 -218 2 -322 -1 -336 -8z"
        />
        <path
          d="M1734 1978 c-39 -18 -44 -49 -44 -273 l0 -215 -270 0 c-176 0 -277
-4 -293 -11 -43 -20 -47 -45 -47 -309 0 -249 0 -249 24 -277 l24 -28 1252 0
1252 0 24 28 c24 28 24 28 24 277 0 264 -4 289 -47 309 -16 7 -117 11 -293 11
l-268 0 -4 126 c-4 177 21 164 -328 164 l-277 0 -5 82 c-4 73 -8 84 -32 105
l-27 23 -322 -1 c-200 0 -330 -4 -343 -11z m571 -553 l0 -410 -230 0 -230 0
-3 413 -2 412 232 -2 233 -3 0 -410z m613 -102 l-3 -308 -227 -3 -228 -2 0
310 0 310 230 0 230 0 -2 -307z m-1228 -148 l0 -165 -227 2 -228 3 -3 163 -2
162 230 0 230 0 0 -165z m1838 3 l-3 -163 -227 -3 -228 -2 0 165 0 165 230 0
230 0 -2 -162z"
        />
      </g>
    </svg>
  );
};

export default SvgIntegratedIcon;
