import { InfiniteData, useMutation, useQueryClient } from 'react-query';
import SupplierService, { SupplierPaginatedData } from 'services/SupplierService';

interface Options {
  onSuccess?: () => void;
  onError?: () => void;
}

const useUpdateSupplierStatus = (supplierId: string, accountId: string, modal: string | null,  options?: Options) => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(() => modal === 'reject' 
    ? SupplierService.rejectSupplier(supplierId) 
    : SupplierService.approveSupplier(supplierId), {
    onMutate: async () => {
      await queryClient.cancelQueries(['account', accountId, 'suppliers'], { exact: true });

      const oldData = queryClient.getQueryData<InfiniteData<SupplierPaginatedData>>(
        ['account', accountId, 'suppliers'],
        {
          exact: true,
        }
      );

      queryClient.setQueryData<InfiniteData<SupplierPaginatedData>>(
        ['account', accountId, 'suppliers'],
        (old = { pageParams: [], pages: [] }) => ({
          ...old,
          pages: old.pages.map(page => ({
            ...page,
            data: page.data.map(supplier =>
              supplier.id === supplierId
                ? { ...supplier, deletedAt: new Date().toISOString() }
                : supplier
            ),
          })),
        })
      );

      return { oldData };
    },

    onError: (_error, _variables, context) => {
      queryClient.setQueryData(['account', accountId, 'suppliers'], context?.oldData);
      options?.onError?.();
    },

    onSuccess: options?.onSuccess,

    onSettled: () => {
      queryClient.invalidateQueries(['account', accountId, 'suppliers'], { exact: true });
    },
  });

  return { updateSupplierStatus: mutate, ...rest };
};

export default useUpdateSupplierStatus;
