export const SIDEPANEL_SET_DATA = 'ui/SIDEPANEL_SET_DATA';
export const SIDEPANEL_RESET_DATA = 'ui/SIDEPANEL_RESET_DATA';
export const SIDEPANEL_OPEN = 'ui/SIDEPANEL_OPEN';
export const SIDEPANEL_CLOSE = 'ui/SIDEPANEL_CLOSE';

export const SHOWEP_LOGIN = 'ui/SHOWEP_LOGIN';
export const COLLAPSED_FILTER = 'ui/COLLAPSED_FILTER';
export const setSidePanelData = data => async dispatch => {
  dispatch({ type: SIDEPANEL_SET_DATA, data });
};

export const resetSidePanelData = data => async dispatch => {
  dispatch({ type: SIDEPANEL_RESET_DATA });
};

export const openSidePanel = data => async dispatch => {
  if (data) {
    dispatch(setSidePanelData(data));
  }
  dispatch({ type: SIDEPANEL_OPEN });
};

export const closeSidePanel = () => async dispatch => {
  dispatch(resetSidePanelData());
  dispatch({ type: SIDEPANEL_CLOSE });
};

export const setShowEPLogin = payload => async dispatch => {
  dispatch({ type: SHOWEP_LOGIN, payload });
};

export const setCollapsedFilterBar = payload => {
  return { type: COLLAPSED_FILTER, payload };
};
