import React from 'react';
import styled from 'styled-components';
import { colors } from 'shared/styles';

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  &:hover,
  &.hover {
    .actions {
      visibility: visible;
    }
  }
  .actions {
    visibility: hidden;
  }
`;

export const Body = styled.div`
  width: 100%;
  background-color: white;
  text-decoration: none;
  color: ${colors.text};

  padding: 15px;
  min-width: 600px;
  box-sizing: border-box;
  border: 1px solid lightgray;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`;

const ActionsContainer = styled.div`
  margin-left: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const Actions = ({ children }) => (
  <ActionsContainer className="actions">{children}</ActionsContainer>
);
