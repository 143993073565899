import React from 'react';

const SvgCampaignIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="campaign-icon_svg__a"
        width="144.4%"
        height="133.3%"
        x="-22.2%"
        y="-16.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      fill="currentColor"
      fillRule="evenodd"
      filter="url(#campaign-icon_svg__a)"
      transform="translate(6 3)"
    >
      <path
        fillRule="nonzero"
        d="M16 2c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h4.18C6.6.84 7.7 0 9 0c1.3 0 2.4.84 2.82 2H16zM9 2c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm2.193 4.378h-7.93c-.965 0-1.422.423-1.422 1.337L1.832 21.07c0 .913.457 1.336 1.422 1.336h11.7c.964 0 1.43-.423 1.43-1.336v-9.78l-5.19-4.913z"
      />
      <path d="M10.662 12h4.506l-4.506-4.268zm2.253 2.81H3.772v-1.513h9.143zm-9.143 1.406h9.143v1.514H3.772zm6.137 4.324H3.772v-1.513H9.91z" />
    </g>
  </svg>
);

export default SvgCampaignIcon;
