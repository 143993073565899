import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { updateAccount } from 'state/accounts/actions';
import { getAccountById } from 'state/accounts/selectors';

const useStyles = makeStyles(theme => ({
  button: {
    height: 37,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
}));

const EstimateSettingsForm = ({ account, updateAccount, className }) => {
  const classes = useStyles();
  const onChange = (e, value) => updateAccount({ usersCreateNewLineItems: value });
  if (account.agency || account.consultancy) {
    return null;
  }

  return (
    <div className={className}>
      <Typography variant="h6" className={classes.title}>
        Estimates
      </Typography>
      {account && (
        <FormControlLabel
          control={
            <Checkbox
              checked={account.usersCreateNewLineItems}
              onChange={onChange}
              color="primary"
            />
          }
          label="Users can create new line items"
        />
      )}
    </div>
  );
};

const mapStateTopProps = (state, { accountId }) => ({
  account: getAccountById(accountId)(state),
});

const mapDispatchToProps = (dispatch, { accountId }) => ({
  updateAccount: ({ usersCreateNewLineItems }) =>
    dispatch(updateAccount({ accountId, usersCreateNewLineItems })),
});

export default connect(mapStateTopProps, mapDispatchToProps)(EstimateSettingsForm);
