import React from 'react';
import clsx from 'clsx';
import { find, get, sortBy, findIndex } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import {
  InputAdornment,
  IconButton,
  InputLabel,
  OutlinedInput,
  FormControl,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => {
  return {
    input: {
      cursor: 'pointer',
      paddingRight: 0,
    },
    inputAdornment: {
      color: theme.palette.text.secondary,
    },
    endAdornment: {
      padding: '26px 14px 26px 8px',
      marginLeft: 0,
    },
  };
});

export const InputComponent = ({
  label,
  name,
  values,
  options,
  getInputProps,
  getInputLabelProps = () => ({}),
  isMenuOpen,
  isSelectedAll,
  selectedCount,
  setAnchorEl,
  margin,
  noRenderValues,
  showMultipleValues,
  disableValue = false,
}) => {
  const classes = useStyles();

  const clearSearch = () => getInputProps().onChange({ target: { value: '' } });

  const renderValue = values => {
    if (!selectedCount || noRenderValues || !get('length', options)) {
      return '';
    }
    if (selectedCount === 1) {
      const selectedOption = find({ value: values[0] }, options);
      return get('label', selectedOption);
    }
    if (showMultipleValues) {
      return sortBy(value => findIndex({ value }, options), values).join(', ');
    }
    return isSelectedAll ? 'All' : 'Multiple Values';
  };

  const inputProps = getInputProps();
  inputProps.value = isMenuOpen ? getInputProps().value : disableValue ? '' : renderValue(values);

  return (
    <FormControl variant="outlined" fullWidth className={classes.formControl}>
      <InputLabel {...getInputLabelProps()}>{label}</InputLabel>
      <OutlinedInput
        margin={margin}
        fullWidth
        name={name}
        label={label}
        inputProps={inputProps}
        placeholder={isMenuOpen ? 'Search' : ''}
        className={classes.input}
        ref={setAnchorEl}
        startAdornment={
          isMenuOpen ? (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <SearchIcon />
            </InputAdornment>
          ) : null
        }
        endAdornment={[
          getInputProps().value ? (
            <InputAdornment position="end" className={classes.inputAdornment} key="close">
              <IconButton aria-label="toggle password visibility" onClick={clearSearch}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ) : null,
          <InputAdornment
            position="end"
            className={clsx(classes.inputAdornment, classes.endAdornment)}
            key="toggle-list"
            onClick={inputProps.onMouseDown}
          >
            {isMenuOpen ? (
              <ArrowDropUpIcon fontSize="small" />
            ) : (
              <ArrowDropDownIcon fontSize="small" />
            )}
          </InputAdornment>,
        ]}
      />
    </FormControl>
  );
};
