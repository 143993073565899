import React from 'react';

const SvgEditIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="edit-icon_svg__a"
        width="133.3%"
        height="133.3%"
        x="-16.7%"
        y="-16.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      d="M0 19v5h5L19.743 9.255l-5-4.999L0 19.001zM23.61 5.39c.52-.52.52-1.36 0-1.88L20.49.39c-.52-.52-1.36-.52-1.88 0l-2.439 2.44 5 4.999 2.44-2.44z"
      filter="url(#edit-icon_svg__a)"
      transform="translate(3 3)"
    />
  </svg>
);

export default SvgEditIcon;
