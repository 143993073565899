import { Events, track } from 'components/analytics';
import { fetch } from 'state/fetchMiddleware';
import { fetchEstimate } from 'state/estimates/actions';
import { fetchWatchers } from 'state/estimateWatchers/actions';

export const ESTIMATE_INOVICE_FETCH_REQUEST = 'estimateInovice/ESTIMATE_INOVICE_FETCH_REQUEST';
export const ESTIMATE_INOVICE_FETCH_SUCCESS = 'estimateInovice/ESTIMATE_INOVICE_FETCH_SUCCESS';
export const ESTIMATE_INOVICE_FETCH_ERROR = 'estimateInovice/ESTIMATE_INOVICE_FETCH_ERROR';

export const ESTIMATE_INVOICE_CREATE_REQUEST = 'estimateInovice/ESTIMATE_INVOICE_CREATE_REQUEST';
export const ESTIMATE_INVOICE_CREATE_SUCCESS = 'estimateInovice/ESTIMATE_INVOICE_CREATE_SUCCESS';
export const ESTIMATE_INVOICE_CREATE_ERROR = 'estimateInovice/ESTIMATE_INVOICE_CREATE_ERROR';

export const ESTIMATE_INVOICE_DELETE_REQUEST = 'estimateInovice/ESTIMATE_INVOICE_DELETE_REQUEST';
export const ESTIMATE_INVOICE_DELETE_SUCCESS = 'estimateInovice/ESTIMATE_INVOICE_DELETE_SUCCESS';
export const ESTIMATE_INVOICE_DELETE_ERROR = 'estimateInovice/ESTIMATE_INVOICE_DELETE_ERROR';

export const fetchEstimateInvoices = estimateId => async dispatch => {
  dispatch({ type: ESTIMATE_INOVICE_FETCH_REQUEST, estimateId });
  const t = Date.now();
  try {
    const { data: estimateInvoices } = await dispatch(fetch(`/estimates/${estimateId}/invoices`));
    track(Events.PERFORMANCE, { metric: 'fetch_estimate_invoices', t: Date.now() - t });
    dispatch({ type: ESTIMATE_INOVICE_FETCH_SUCCESS, estimateInvoices });
  } catch (e) {
    dispatch({ type: ESTIMATE_INOVICE_FETCH_ERROR, estimateId, error: e });
  }
};

export const createEstimateInvoice = (estimateId, data, pageId) => async dispatch => {
  dispatch({ type: ESTIMATE_INVOICE_CREATE_REQUEST, data });
  try {
    const { data: estimateInvoice } = await dispatch(
      fetch(`/estimates/${estimateId}/invoices`, { method: 'POST', data })
    );

    dispatch({ type: ESTIMATE_INVOICE_CREATE_SUCCESS, id: estimateInvoice.id, estimateInvoice });
    dispatch(fetchEstimate(estimateId, pageId));
    dispatch(fetchWatchers(estimateId));
  } catch (error) {
    dispatch({ type: ESTIMATE_INVOICE_CREATE_ERROR, data, error });
  }
};

export const deleteEstimateInvoice = (estimateId, invoiceId, pageId) => async dispatch => {
  dispatch({ type: ESTIMATE_INVOICE_DELETE_REQUEST });
  try {
    await dispatch(fetch(`/estimates/${estimateId}/invoices/${invoiceId}`, { method: 'DELETE' }));

    dispatch({ type: ESTIMATE_INVOICE_DELETE_SUCCESS, id: invoiceId });
    dispatch(fetchEstimate(estimateId, pageId));
    dispatch(fetchWatchers(estimateId));
  } catch (error) {
    dispatch({ type: ESTIMATE_INVOICE_DELETE_ERROR, error });
  }
};
