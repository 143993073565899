import React from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import { colors, shadows, font, inputStyles, textareaStyles } from 'shared/styles';

ReactModal.defaultStyles.overlay.position = 'absolute';
ReactModal.defaultStyles.overlay.zIndex = 100;

const Modal = styled(ReactModal)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: scroll;

  @media print {
    position: absolute;
    overflow: visible;
  }

  input {
    ${inputStyles}
  }

  textarea {
    ${textareaStyles}
  }
`;

const ModalWindow = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 0;
  border-radius: 7px;
  width: 60%;
  min-width: 700px;
  max-width: 1200px;
  margin: auto;

  box-shadow: ${shadows.modal};
`;

const ModalHeader = styled.div`
  background-color: ${colors.primary};
  color: white;
  text-shadow: ${shadows.text};
  font-size: ${font.sizes.large};
  font-weight: ${font.weights.bold};
  padding: 20px;
  border-radius: 7px 7px 0 0;
  display: flex;
  flex-shrink: 0;
`;

const ModalBody = styled.div`
  padding: 20px;
  color: ${colors.text};
  display: flex;
  flex-direction: column;
  border: 1px solid lightgrey;
  border-top: 0;
  border-bottom: 0;
  background: white;
`;

const ModalFooter = styled.div`
  display: flex;
  flex-shrink: 0;
  padding: 15px 10px;
  border-radius: 0 0 7px 7px;
  border: 1px solid lightgrey;
  background: white;
  display: flex;
  align-self: flex-end;
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-end;
  .button {
    width: auto;
  }
`;

Modal.setAppElement('#root');
export default ({ title, buttons, children, ...restProps }) => {
  return (
    <Modal
      {...restProps}
      style={{
        overlay: {
          position: 'fixed',
        },
      }}
    >
      <ModalWindow className="modalWindow">
        <ModalHeader className="header">{title}</ModalHeader>
        <ModalBody className="body">{children}</ModalBody>
        <ModalFooter className="footer">{buttons}</ModalFooter>
      </ModalWindow>
    </Modal>
  );
};
