import React from 'react';
import uuid from 'uuid/v4';
import { last } from 'lodash/fp';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Route, Switch, Redirect, Link } from 'react-router-dom';
import {
  withState,
  withHandlers,
  compose,
  lifecycle,
  branch,
  renderNothing,
  renderComponent,
} from 'recompose';
import Pluralize from 'react-pluralize';
import { makeStyles } from '@material-ui/core/styles';
import { EstimateStatuses } from 'cr-core/constants';
import { ActionBar, LeftActions } from 'components/actionBar';
import { Events, track, getEstimateData } from 'components/analytics';
import Attachments from 'components/estimateAttachments';
import { ApproveButton, Button, FlatButton, WarningButton } from 'components/buttons';
import EditEstimate from 'components/estimateEdit';
import EstimateApprovals from 'components/estimateApprovals';
import EstimateActualisation from 'components/actualisation/actualisation.table';
import EstimateDeliverables from 'components/estimateDeliverables';
import EstimateTable from 'components/estimateBidsTable';
import EstimateDetails from 'components/estimateDetails';
import {
  EstimateNavigationList,
  EstimateNavigationItem,
  EstimateNavigationItemDisabled,
} from 'components/estimateNavigation';
import EstimateSummaryBar from 'components/estimateSummaryBar';
import EstimateWatchers from 'components/estimateWatchers';
import ApprovalModal from 'components/modals/approval';
import RequestChangesModal from 'components/modals/requestChanges';
import ShareModal from 'components/modals/shareEstimate';
import SubmitForApprovalModal from 'components/modals/submitForApproval';
import PrintModal from 'components/modals/print';
import SvgApproveIcon from 'icons/ApproveIcon';
import SvgAttachmentsIcon from 'icons/AttachmentsIcon';
import SvgBidsIcon from 'icons/BidsIcon';
import SvgDraftIcon from 'icons/DraftIcon';
import SvgEyeIcon from 'icons/EyeIcon';
import SvgHistoryIcon from 'icons/HistoryIcon';
import SvgPrintIcon from 'icons/PrintIcon';
import SvgRequestChangesIcon from 'icons/RequestChangesIcon';
import SvgSendIcon from 'icons/SendIcon';
import SvgShareIcon from 'icons/ShareIcon';
import BallotIcon from '@material-ui/icons/Ballot';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { dimensions } from 'shared/styles';
import { fetchEstimate } from 'state/estimates/actions';
import {
  getEstimateById,
  canUserApproveEstimate,
  canUserSubmitEstimate,
  canEdit,
  canRequestChanges,
} from 'state/estimates/selectors';
import { getUnsubmittedEstimateApprovalsFor } from 'state/estimateApprovals/selectors';
import { approveEstimate } from 'state/estimateApprovals/actions';
import { fetchWatchers as fetchEstimateWatchers } from 'state/estimateWatchers/actions';
import { ERROR } from 'state/resourceStatus/reducer';
import NotFoundPage from 'pages/404';
import AppContainer from 'components/appContainerLegacy';

const useStyles = makeStyles(theme => ({
  bodyContainer: {
    width: '100%',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '1rem',
  },
}));

const NavigationContainer = styled.div`
  width: 200px;
  padding: 15px;
  flex-shrink: 0;

  .button {
    margin-bottom: 10px;
  }

  .icon {
    font-size 21px;
  }
`;

const EstimateDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 80px;
  min-height: calc(100% - ${dimensions.footerHeight});
`;

const RequestChangesButton = ({ onClick }) => (
  <WarningButton onClick={onClick}>
    <SvgRequestChangesIcon className="icon" />
    Request Changes
  </WarningButton>
);

const EstimateMenu = ({ match, estimate }) => (
  <NavigationContainer>
    <EstimateNavigationList>
      <li>
        <EstimateNavigationItem to={`${match.url}/details`} data-test="detail-nav-details">
          <SvgDraftIcon className="icon" />
          Details
        </EstimateNavigationItem>
      </li>
      <li>
        <EstimateNavigationItem
          to={`${match.url}/deliverables`}
          data-test="detail-nav-deliverables"
        >
          <LocalShippingIcon className="icon" />
          Deliverables
        </EstimateNavigationItem>
      </li>
      <li>
        <EstimateNavigationItem to={`${match.url}/bids`} data-test="detail-nav-bids">
          <SvgBidsIcon className="icon" />
          Bids
        </EstimateNavigationItem>
      </li>
      <li>
        <EstimateNavigationItem to={`${match.url}/approvals`} data-test="detail-nav-approvals">
          <SvgApproveIcon className="icon" />
          Approvers
        </EstimateNavigationItem>
      </li>
      <li>
        <EstimateNavigationItem to={`${match.url}/watchers`} data-test="detail-nav-watchers">
          <SvgEyeIcon className="icon" />
          Watchers
        </EstimateNavigationItem>
      </li>
      <li>
        {[EstimateStatuses.APPROVED, EstimateStatuses.ACTUALISED].includes(estimate.status) ? (
          <EstimateNavigationItem
            to={`${match.url}/actualisation`}
            data-test="detail-nav-actualisation"
          >
            <BallotIcon className="icon" />
            Actualisation
          </EstimateNavigationItem>
        ) : (
          <EstimateNavigationItemDisabled data-test="detail-nav-actualisation">
            <BallotIcon className="icon" />
            Actualisation
          </EstimateNavigationItemDisabled>
        )}
      </li>
      <li>
        <EstimateNavigationItem to={`${match.url}/attachments`} data-test="detail-nav-attachments">
          <SvgAttachmentsIcon className="icon" />
          Attachments
        </EstimateNavigationItem>
      </li>
    </EstimateNavigationList>
  </NavigationContainer>
);

const EstimateActionBar = ({
  match,
  showPrintModal,
  showShareModal,
  canSubmitEstimate,
  canEdit,
  showSubmitModal,
  unsubmittedApprovalCount,
  canApproveEstimate,
  canRequestChanges,
  showRequestChangesModal,
  showApprovalModal,
  estimate,
  location,
}) => {
  const showSubmitButton =
    canSubmitEstimate && (unsubmittedApprovalCount || !estimate.approvals.length) && canEdit;

  if (last(location.pathname.split('/')) === 'edit') {
    return <></>;
  }
  return (
    <ActionBar>
      <LeftActions>
        <FlatButton onClick={showPrintModal}>
          <SvgPrintIcon className="icon" />
          Print
        </FlatButton>

        <FlatButton onClick={showShareModal} data-test="share-in-workspace-button">
          <SvgShareIcon className="icon" />
          <span>Share</span>
        </FlatButton>

        <FlatButton
          data-test="estimate-history-button"
          Component={Link}
          to={`${match.url}/history`}
        >
          <SvgHistoryIcon className="icon" />
          <span>History</span>
        </FlatButton>
      </LeftActions>

      {showSubmitButton && (
        <Button onClick={showSubmitModal} data-test="submit-to-approvers-button">
          <SvgSendIcon className="icon" />
          <span>Submit</span>
          {unsubmittedApprovalCount > 0 && (
            <>
              <span>&nbsp;To&nbsp;</span>{' '}
              <Pluralize singular={'Approver'} count={unsubmittedApprovalCount} />
            </>
          )}
        </Button>
      )}
      {canRequestChanges && <RequestChangesButton onClick={showRequestChangesModal} />}
      {canApproveEstimate && (
        <ApproveButton onClick={showApprovalModal}>
          <SvgApproveIcon className="icon" />
          Approve
        </ApproveButton>
      )}
    </ActionBar>
  );
};

const EstimateModals = ({
  match,
  shouldShowApprovalModal,
  hideApprovalModal,
  shouldShowSubmitModal,
  hideSubmitModal,
  shouldShowShareModal,
  hideShareModal,
  shouldShowRequestChangesModal,
  hideRequestChangesModal,
  shouldShowPrintModal,
  hidePrintModal,
  approveEstimate,
  estimate,
  shouldShowSuccessModal = true,
  pageId,
}) => (
  <>
    <ApprovalModal
      isOpen={shouldShowApprovalModal}
      onRequestClose={hideApprovalModal}
      onConfirmApproval={approveEstimate}
      estimateId={estimate.id}
      pageId={pageId}
    />
    {shouldShowSubmitModal && (
      <SubmitForApprovalModal
        isOpen={shouldShowSubmitModal}
        onRequestClose={hideSubmitModal}
        estimateId={estimate.id}
        pageId={pageId}
      />
    )}
    <RequestChangesModal
      isOpen={shouldShowRequestChangesModal}
      onRequestClose={hideRequestChangesModal}
      estimateId={match.params.id}
      pageId={pageId}
    />
    <PrintModal
      isOpen={shouldShowPrintModal}
      onRequestClose={hidePrintModal}
      estimateId={match.params.id}
    />
    <ShareModal
      isOpen={shouldShowShareModal}
      onRequestClose={hideShareModal}
      onSubmitToShare={false}
      estimateId={estimate.id}
    />
  </>
);

const EstimateDetail = props => {
  const { match, path, showSubmitModal, estimate, canEdit } = props;
  const classes = useStyles();
  const estimateId = match.params.id;

  return (
    <AppContainer>
      <EstimateModals {...props} />
      <div className={classes.bodyContainer}>
        <EstimateSummaryBar
          estimateId={match.params.id}
          path={match.url}
          pathName={window.location.pathname}
        />
        <EstimateDetailContainer>
          <EstimateMenu {...props} />
          <EstimateActionBar {...props} />
          <Switch>
            <Route path={`${path}/details`} component={EstimateDetails} />
            <Route
              path={`${path}/edit`}
              render={props =>
                canEdit ? (
                  <EditEstimate {...props} />
                ) : (
                  <Redirect to={`/estimates/${estimateId}/details`} />
                )
              }
            />
            <Route path={`${path}/deliverables`} component={EstimateDeliverables} />
            <Route
              path={`${path}/deliverables/edit`}
              render={props =>
                canEdit ? (
                  <EstimateDeliverables {...props} />
                ) : (
                  <Redirect to={`/estimates/${estimateId}/deliverables`} />
                )
              }
            />
            <Route path={`${path}/bids`} render={() => <EstimateTable {...props} />} />
            <Route
              path={`${path}/approvals`}
              render={() => <EstimateApprovals showSubmitModal={showSubmitModal} {...props} />}
            />
            <Route
              path={`${path}/watchers`}
              render={() => <EstimateWatchers showSubmitModal={showSubmitModal} {...props} />}
            />
            {[EstimateStatuses.APPROVED, EstimateStatuses.ACTUALISED].includes(estimate.status) && (
              <Route path={`${path}/actualisation`} component={EstimateActualisation} />
            )}
            <Route path={`${path}/attachments`} component={Attachments} />
            <Redirect from={`${path}`} to={`${path}/details`} />
          </Switch>
        </EstimateDetailContainer>
      </div>
    </AppContainer>
  );
};

const mapStateToProps = (state, { match }) => {
  const estimateId = match.params.id;

  return {
    estimate: getEstimateById(estimateId)(state),
    canApproveEstimate: canUserApproveEstimate(estimateId)(state),
    canSubmitEstimate: canUserSubmitEstimate(estimateId)(state),
    unsubmittedApprovalCount: getUnsubmittedEstimateApprovalsFor(estimateId)(state).length,
    loadingStatus: state.resourceStatus.estimate,
    canEdit: canEdit(estimateId)(state),
    canRequestChanges: canRequestChanges(estimateId)(state),
  };
};

const mapDispatchToProps = (dispatch, { match, pageId }) => ({
  fetchThisEstimate: () => dispatch(fetchEstimate(match.params.id, pageId)),
  fetchEstimateWatchers: () => dispatch(fetchEstimateWatchers(match.params.id)),
  approveEstimate: message => dispatch(approveEstimate(match.params.id, message, pageId)),
});

export default compose(
  withState('pageId', 'setPageId', () => uuid()),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.fetchThisEstimate();
      this.props.fetchEstimateWatchers();
    },
  }),
  branch(({ loadingStatus }) => loadingStatus === ERROR, renderComponent(NotFoundPage)),
  branch(({ estimate }) => !estimate, renderNothing),
  withState('shouldShowApprovalModal', 'setShowApprovalModal', false),
  withState('shouldShowSubmitModal', 'setShowSubmitModal', false),
  withState('shouldShowRequestChangesModal', 'setShowRequestChangesModal', false),
  withState('shouldShowPrintModal', 'setShowPrintModal', false),
  withState('shouldShowShareModal', 'setShowShareModal', false),
  withHandlers({
    showApprovalModal:
      ({ setShowApprovalModal, estimate }) =>
      () => {
        setShowApprovalModal(true);
        track(Events.ESTIMATE_APPROVE_CLICK, getEstimateData(estimate));
      },
    hideApprovalModal:
      ({ setShowApprovalModal }) =>
      () =>
        setShowApprovalModal(false),
    showSubmitModal:
      ({ setShowSubmitModal, estimate }) =>
      () => {
        track(Events.ESTIMATE_SUBMIT_CLICK, getEstimateData(estimate));
        setShowSubmitModal(true);
      },
    hideSubmitModal:
      ({ setShowSubmitModal }) =>
      () =>
        setShowSubmitModal(false),
    showShareModal:
      ({ setShowShareModal, trackShareButtonClick, estimate }) =>
      () => {
        setShowShareModal(true);
        track(Events.ESTIMATE_SHARE_CLICK, getEstimateData(estimate));
      },
    hideShareModal:
      ({ setShowShareModal }) =>
      () =>
        setShowShareModal(false),
    showRequestChangesModal:
      ({ setShowRequestChangesModal, estimate }) =>
      () => {
        setShowRequestChangesModal(true);
        track(Events.ESTIMATE_REQUEST_CHANGES_CLICK, getEstimateData(estimate));
      },
    hideRequestChangesModal:
      ({ setShowRequestChangesModal }) =>
      () =>
        setShowRequestChangesModal(false),
    showPrintModal:
      ({ setShowPrintModal, trackPrintButtonClick, estimate }) =>
      () => {
        setShowPrintModal(true);
        track(Events.ESTIMATE_PRINT_CLICK, getEstimateData(estimate));
      },
    hidePrintModal:
      ({ setShowPrintModal }) =>
      () =>
        setShowPrintModal(false),
  })
)(EstimateDetail);
