import { getFormValues } from 'redux-form';
import { fetch } from 'state/fetchMiddleware';

export const WORKSPACE_CREATE_REQUEST = 'workspace/WORKSPACE_CREATE_REQUEST';
export const WORKSPACE_CREATE_SUCCESS = 'workspace/WORKSPACE_CREATE_SUCCESS';
export const WORKSPACE_CREATE_ERROR = 'workspace/WORKSPACE_CREATE_ERROR';
export const WORKSPACE_UPDATE_REQUEST = 'workspace/WORKSPACE_UPDATE_REQUEST';
export const WORKSPACE_UPDATE_SUCCESS = 'workspace/WORKSPACE_UPDATE_SUCCESS';
export const WORKSPACE_UPDATE_ERROR = 'workspace/WORKSPACE_UPDATE_ERROR';
export const WORKSPACE_DELETE_REQUEST = 'workspace/WORKSPACE_DELETE_REQUEST';
export const WORKSPACE_DELETE_SUCCESS = 'workspace/WORKSPACE_DELETE_SUCCESS';
export const WORKSPACE_DELETE_ERROR = 'workspace/WORKSPACE_DELETE_ERROR';
export const WORKSPACE_FETCH_REQUEST = 'workspace/WORKSPACE_FETCH_REQUEST';
export const WORKSPACE_FETCH_SUCCESS = 'workspace/WORKSPACE_FETCH_SUCCESS';
export const WORKSPACE_FETCH_ERROR = 'workspace/WORKSPACE_FETCH_ERROR';

export const WORKSPACE_ADD_TEAM_REQUEST = 'workspaces/WORKSPACE_ADD_TEAM_REQUEST';
export const WORKSPACE_ADD_TEAM_SUCCESS = 'workspaces/WORKSPACE_ADD_TEAM_SUCCESS';
export const WORKSPACE_ADD_TEAM_ERROR = 'workspaces/WORKSPACE_REMOVE_TEAM_ERROR';
export const WORKSPACE_REMOVE_TEAM_REQUEST = 'workspaces/WORKSPACE_REMOVE_TEAM_REQUEST';
export const WORKSPACE_REMOVE_TEAM_SUCCESS = 'workspaces/WORKSPACE_ADD_TEAM_SUCCESS';
export const WORKSPACE_REMOVE_TEAM_ERROR = 'workspaces/WORKSPACE_REMOVE_TEAM_ERROR';

export const WORKSPACE_ADD_AGENCY_REQUEST = 'workspaces/WORKSPACE_ADD_AGENCY_REQUEST';
export const WORKSPACE_ADD_AGENCY_SUCCESS = 'workspaces/WORKSPACE_ADD_AGENCY_SUCCESS';
export const WORKSPACE_ADD_AGENCY_ERROR = 'workspaces/WORKSPACE_ADD_AGENCY_ERROR';

export const WORKSPACE_REMOVE_ACCOUNT_REQUEST = 'WORKSPACE_REMOVE_ACCOUNT_REQUEST';
export const WORKSPACE_REMOVE_ACCOUNT_SUCCESS = 'WORKSPACE_REMOVE_ACCOUNT_SUCCESS';
export const WORKSPACE_REMOVE_ACCOUNT_ERROR = 'WORKSPACE_REMOVE_ACCOUNT_ERROR';

export const WORKSPACES_FETCH_REQUEST = 'workspace/WORKSPACES_FETCH_REQUEST';
export const WORKSPACES_FETCH_SUCCESS = 'workspace/WORKSPACES_FETCH_SUCCESS';
export const WORKSPACES_FETCH_ERROR = 'workspace/WORKSPACES_FETCH_ERROR';

export const FETCH_WORKSPACE_MEMBERS_REQUEST = 'workspaces/FETCH_WORKSPACE_MEMBERS_REQUEST';
export const FETCH_WORKSPACE_MEMBERS_SUCCESS = 'workspaces/FETCH_WORKSPACE_MEMBERS_SUCCESS';
export const FETCH_WORKSPACE_MEMBERS_ERROR = 'workspaces/FETCH_WORKSPACE_MEMBERS_ERROR';

export const fetchWorkspace = (workspaceId, accountId) => async dispatch => {
  dispatch({ type: WORKSPACE_FETCH_REQUEST });
  try {
    const { data: workspace } = await dispatch(
      fetch(`/workspaces/${workspaceId}`, { params: { accountId } })
    );
    dispatch({ type: WORKSPACE_FETCH_SUCCESS, workspace });
  } catch (error) {
    dispatch({ type: WORKSPACE_FETCH_ERROR, error });
  }
};

export const fetchWorkspaces = filters => async dispatch => {
  dispatch({ type: WORKSPACES_FETCH_REQUEST });
  try {
    const {
      data: { data: workspaces, pagination },
    } = await dispatch(fetch('/workspaces', { params: { ...filters } }));
    dispatch({ type: WORKSPACES_FETCH_SUCCESS, workspaces, pagination });
  } catch (error) {
    dispatch({ type: WORKSPACES_FETCH_ERROR, error });
  }
};

export const deleteWorkspace = workspaceId => async dispatch => {
  dispatch({ type: WORKSPACE_DELETE_REQUEST });
  try {
    await dispatch(fetch(`/workspaces/${workspaceId}`, { method: 'DELETE' }));
    dispatch({ type: WORKSPACE_DELETE_SUCCESS, workspaceId });
  } catch (error) {
    dispatch({ type: WORKSPACE_DELETE_ERROR, workspaceId, error });
    throw error;
  }
};

export const createWorkspace = ({ name, accountId }) => async dispatch => {
  dispatch({ type: WORKSPACE_CREATE_REQUEST });
  try {
    const { data: workspace } = await dispatch(
      fetch('/workspaces', {
        method: 'POST',
        data: { name, accountId },
      })
    );
    dispatch({ type: WORKSPACE_CREATE_SUCCESS, workspace });
  } catch (error) {
    dispatch({ type: WORKSPACE_CREATE_ERROR, error });
    throw new Error(error.message || 'An error occurred while creating a new workspace.');
  }
};

export const updateWorkspace = (workspaceId, { name }) => async (dispatch, getState) => {
  dispatch({ type: WORKSPACE_UPDATE_REQUEST });
  try {
    const { data: team } = await dispatch(
      fetch(`/workspaces/${workspaceId}`, {
        method: 'PUT',
        data: { name },
      })
    );
    dispatch({ type: WORKSPACE_UPDATE_SUCCESS, team });

    const filters = getFormValues('workspaceFilters')(getState()) || {};
    dispatch(fetchWorkspaces(filters));
  } catch (error) {
    dispatch({ type: WORKSPACE_UPDATE_ERROR, error });
    throw new Error(error.message || 'An error occurred while editing the workspace.');
  }
};

export const addTeam = (workspaceId, teamId) => async dispatch => {
  dispatch({ type: WORKSPACE_ADD_TEAM_REQUEST, workspaceId, teamId });
  try {
    await dispatch(
      fetch(`/workspaces/${workspaceId}/teams`, {
        method: 'POST',
        data: { teamId },
      })
    );
    dispatch({ type: WORKSPACE_ADD_TEAM_SUCCESS, workspaceId, teamId });
  } catch (error) {
    dispatch({ type: WORKSPACE_ADD_TEAM_ERROR, error });
    throw new Error(error.message || 'An error occurred while adding the team to the workspace.');
  }
};

export const removeTeam = (workspaceId, teamId) => async dispatch => {
  dispatch({ type: WORKSPACE_REMOVE_TEAM_REQUEST, workspaceId, teamId });
  try {
    await dispatch(
      fetch(`/workspaces/${workspaceId}/teams/${teamId}`, {
        method: 'DELETE',
      })
    );
    dispatch({ type: WORKSPACE_REMOVE_TEAM_SUCCESS, workspaceId, teamId });
  } catch (error) {
    dispatch({ type: WORKSPACE_REMOVE_TEAM_ERROR, error });
    throw new Error(error.message || 'An error occurred while remove the team from the workspace.');
  }
};

export const addAgency = (workspaceId, agencyId) => async dispatch => {
  dispatch({ type: WORKSPACE_ADD_AGENCY_REQUEST, workspaceId, agencyId });
  try {
    await dispatch(
      fetch(`/workspaces/${workspaceId}/agencies`, {
        method: 'POST',
        data: { agencyId },
      })
    );
    dispatch({ type: WORKSPACE_ADD_AGENCY_SUCCESS, workspaceId, agencyId });
  } catch (error) {
    dispatch({ type: WORKSPACE_ADD_AGENCY_ERROR, error });
    throw new Error(error.message || 'An error occurred while adding the agency to the workspace.');
  }
};

export const removeAccount = (workspaceId, accountId) => async dispatch => {
  dispatch({ type: WORKSPACE_REMOVE_ACCOUNT_REQUEST, workspaceId, accountId });
  try {
    await dispatch(
      fetch(`/workspaces/${workspaceId}/accounts/${accountId}`, {
        method: 'DELETE',
      })
    );
    dispatch({ type: WORKSPACE_REMOVE_ACCOUNT_SUCCESS, workspaceId, accountId });
  } catch (error) {
    dispatch({ type: WORKSPACE_REMOVE_ACCOUNT_ERROR, error });
    throw new Error(
      error.message || 'An error occurred while removing the account from the workspace.'
    );
  }
};

export const fetchWorkspaceMembers = workspaceId => async dispatch => {
  dispatch({ type: FETCH_WORKSPACE_MEMBERS_REQUEST, workspaceId });
  try {
    const { data } = await dispatch(fetch(`/workspaces/${workspaceId}/users`));
    dispatch({ type: FETCH_WORKSPACE_MEMBERS_SUCCESS, workspaceId, members: data.data });
  } catch (error) {
    dispatch({ type: FETCH_WORKSPACE_MEMBERS_ERROR, workspaceId, error });
  }
};
