import { sortBy, set } from 'lodash/fp';
import { FETCH_ACCOUNTS_SUCCESS, FETCH_AGENCIES_SUCCESS } from './actions';
import { LOGIN_SUCCESS } from 'state/authentication/actions';

const defaultState = {
  accounts: [],
  agencies: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ACCOUNTS_SUCCESS:
      return set('accounts', sortBy('name', [...action.accounts]), state);

    case FETCH_AGENCIES_SUCCESS:
      return set('agencies', sortBy('name', [...action.agencies]), state);

    case LOGIN_SUCCESS:
      return set('accounts', action.profile.accounts, state);

    default:
      return state;
  }
};
