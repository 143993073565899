import styled from 'styled-components';
import { font, colors } from 'shared/styles';

export default styled.div`
  font-size: ${font.sizes.normal};
  background: ${colors.lightBackground};
  font-size: 12px;
  border-left: 4px solid lightgray;
  padding: 10px;
`;
