import { useQuery } from 'react-query';
import WorkspaceService from 'services/WorkspaceService';

const useDivisionsWorkspaces = (clientId: string, divisions: string[], initialData:any[] = []) => {
  return useQuery(
    ['client', clientId, 'divisions', divisions, 'workspaces'],
    () => WorkspaceService.getDivisonWorkspaces({ divisions }).then(({ data }) => data.data),
    {
        initialData: initialData,
        enabled: !!divisions.length,
    }
  );
};

export default useDivisionsWorkspaces;
