import React from 'react';
import styled from 'styled-components';
import EstimateDetailItems from 'components/estimateDetails/estimateDetailItems';

const EstimateDetailItemsForPrint = styled(EstimateDetailItems)`
  margin: 20px;
  flex-shrink: 0;
`;

export default ({ estimateId }) => <EstimateDetailItemsForPrint estimateId={estimateId} />;
