import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { find, get, last, map, reject } from 'lodash/fp';
import { withRouter } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { menuItems } from 'components/header';
import { getFilteredMenuItems } from 'components/header/utils';
import { getUser, getFlags, getClientSettings } from 'state/authentication/selectors';
import { AccountSetting } from 'cr-core/constants';

const useStyles = makeStyles(theme => ({
  reportSelector: {
    marginLeft: theme.spacing(1),
  },
}));

const ReportsDropdownMenu = ({ match, history, location, flags, isAdmin, clientSettings }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = path => () => {
    setAnchorEl(null);
    if (path) {
      history.push(`/reports${path}${location.search}`);
    }
  };

  const topLineCustomReportEnabled = clientSettings[AccountSetting.TopLineReport];
  const deliverablesReportEnabled = clientSettings[AccountSetting.DeliverablesReport];

  const availableReports = reject(
    { path: `/${last(match.url.split('/'))}` },
    getFilteredMenuItems(find({ id: 'reports' }, menuItems).children, { flags, isAdmin })
  ).filter(report => {
    if (report.id === 'top-line-custom' && !topLineCustomReportEnabled) {
      return false;
    }

    if (report.id === 'deliverables' && !deliverablesReportEnabled) {
      return false;
    }
    return true;
  });

  return (
    <>
      <IconButton
        aria-label="select report"
        size="small"
        className={classes.reportSelector}
        onClick={handleClick}
      >
        <ArrowDropDownIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose()}
      >
        {map(
          ({ label, path }) => (
            <MenuItem key={label} onClick={handleClose(path)}>
              {label} Report
            </MenuItem>
          ),
          availableReports
        )}
      </Menu>
    </>
  );
};

const mapStateToProps = state => ({
  isAdmin: get('isAdmin', getUser(state)),
  flags: getFlags(state),
  clientSettings: getClientSettings(state),
});

export default compose(withRouter, connect(mapStateToProps))(ReportsDropdownMenu);
