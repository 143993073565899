import React from 'react';

const SvgPrintIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="print-icon_svg__a"
        width="129.6%"
        height="133.3%"
        x="-14.8%"
        y="-16.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      d="M22.95 6.667H4.05c-2.241 0-4.05 1.786-4.05 4v8h5.4V24h16.2v-5.333H27v-8c0-2.214-1.809-4-4.05-4zM18.9 21.333H8.1v-6.666h10.8v6.666zM22.95 12c-.742 0-1.35-.6-1.35-1.333 0-.734.607-1.334 1.35-1.334.742 0 1.35.6 1.35 1.334 0 .733-.608 1.333-1.35 1.333zM21.6 0H5.4v5.333h16.2V0z"
      filter="url(#print-icon_svg__a)"
      transform="translate(2 3)"
    />
  </svg>
);

export default SvgPrintIcon;
