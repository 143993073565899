import React from 'react';
import { get } from 'lodash/fp';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { compose, withHandlers } from 'recompose';
import styled from 'styled-components';
import { Events, track, getEstimateData } from 'components/analytics';
import { ApproveButton, CancelButton } from 'components/buttons';
import { FieldContainer, InputContainer, Label, SubLabel } from 'components/forms';
import WarningBox from 'components/warningBox';
import Modal from 'components/modal';
import SvgApproveIcon from 'icons/ApproveIcon';
import SvgBackIcon from 'icons/BackIcon';
import { colors } from 'shared/styles';
import { createLineItem } from 'state/lineItems/actions';
import { LOADING } from 'state/resourceStatus/reducer';
import { getCostCategories, getCostCategoryById } from 'state/costCategories/selectors';
import { getCampaignById } from 'state/campaigns/selectors';

const formName = 'createLineItem';

const requiredFields = ['categoryId', 'name'];

const CreateLineItemModal = styled(Modal)`
  .modalWindow {
    width: 700px;
  }

  .body {
    padding: 20px 20px 0 20px;
    .title {
      margin-bottom: 20px;
    }
  }

  form {
    margin-top: 20px;
  }

  form[disabled] {
    opacity: 0.5;
  }

  .footer {
    justify-content: space-between;
    align-items: center;
  }

  .savingMessage {
    color: lightgrey;
    padding-left: 10px;
  }

  input.textInput {
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid lightgrey;
    line-height: 34px;
    padding: 0 10px;
  }

  input.error {
    border-color: ${colors.error};
  }

  .errorMessage,
  .warningMessage {
    padding: 2px 10px;
    font-size: 12px;
    font-style: italic;
    color: ${colors.error};
    margin-bottom: -19px;
  }
  .errorMessage {
    color: ${colors.error};
  }

  .currencyField {
    margin-bottom: 60px;
  }

  .warning-message {
    font-size: 14px;
  }
`;

const FooterBlockContainer = styled.div`
  display: flex;
`;

const getFieldMessage = ({ meta: { touched, error, warning } }) => (
  <>
    {touched &&
      ((error && <div className="errorMessage">{error}</div>) ||
        (warning && <div className="warningMessage">{warning}</div>))}
  </>
);

const getFieldClassName = ({ meta, className }) => {
  const { touched, error, warning } = meta;
  let componentClassName = className || '';
  if (touched && error) {
    componentClassName += ' error';
  }
  if (touched && warning) {
    componentClassName += ' warning';
  }
  return componentClassName;
};

const createWrappedComponent = component => field => {
  const { input, ...props } = field;
  const message = getFieldMessage(field);
  const componentClassName = getFieldClassName(field);
  if (typeof component === 'string') {
    return (
      <>
        <input {...input} {...props} className={componentClassName} />
        {message}
      </>
    );
  }

  return (
    <>
      {component({ input, ...props, className: componentClassName })}
      {message}
    </>
  );
};

const inputWithMessage = createWrappedComponent('input');

const validate = values => {
  const errors = {};
  requiredFields.forEach(fieldName => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Required';
    }
  });
  return errors;
};

const CreateLineItem = ({
  close,
  handleSubmit,
  reset,
  costCategories,
  costCategory,
  costCategoryId,
  saving,
  name = '',
  workspaceId,
  ...restProps
}) => {
  const buttons = (
    <>
      <FooterBlockContainer className="savingMessage">
        {saving && 'Creating line item...'}
      </FooterBlockContainer>
      <FooterBlockContainer>
        <CancelButton onClick={close} disabled={saving} data-test="cancelNewLineItemBtn">
          <SvgBackIcon className="icon" />
          Cancel
        </CancelButton>
        <ApproveButton onClick={handleSubmit} disabled={saving} data-test="createNewLineItemBtn">
          <SvgApproveIcon className="icon" />
          Save
        </ApproveButton>
      </FooterBlockContainer>
    </>
  );

  return (
    <CreateLineItemModal
      title="Create Line Item"
      buttons={buttons}
      {...restProps}
      onRequestClose={close}
    >
      <WarningBox className="warning-message">
        You are kindly advised to double check for spelling mistakes and avoid abbreviations when
        creating a new line item.
        <br />
        Thank you for helping us keep Estimates and Reports consistent & accurate for everyone.
      </WarningBox>

      <form onSubmit={handleSubmit} disabled={saving}>
        <FieldContainer>
          <Label htmlFor="name">Cost Category</Label>
          <InputContainer>
            <input id="costCategoryName" value={costCategory.name} disabled />
          </InputContainer>
        </FieldContainer>
        <FieldContainer>
          <Label htmlFor="name">
            Name
            <SubLabel>What's the name of your new line item?</SubLabel>
          </Label>

          <InputContainer>
            <Field
              id="lineItemName"
              name="name"
              component={inputWithMessage}
              type="text"
              placeholder="Line Item Name..."
            />
          </InputContainer>
        </FieldContainer>
      </form>
    </CreateLineItemModal>
  );
};

const mapStateToProps = (state, { estimate, costCategoryId, name }) => {
  const campaign = getCampaignById(estimate.campaignId)(state);
  console.log(campaign, get('workspace.accountId', campaign));
  return {
    costCategories: getCostCategories(state),
    costCategory: getCostCategoryById(costCategoryId)(state),
    saving: state.resourceStatus.lineItemName === LOADING,
    initialValues: { estimate, costCategoryId, name },
    accountId: get('workspace.accountId', campaign),
  };
};

const mapDispatchToProps = dispatch => ({
  createLineItem: async (name, costCategoryId, accountId) =>
    dispatch(createLineItem(name, costCategoryId, accountId)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onSubmit: ({
      onRequestClose,
      createLineItem,
      estimate,
      costCategory,
      accountId,
    }) => async formValues => {
      const { name, costCategoryId } = formValues;
      const lineItem = await createLineItem(name, costCategoryId, accountId);
      track(Events.ESTIMATE_CREATE_NEW_LINE_ITEM_SUCCESS, {
        ...getEstimateData(estimate),
        costCategoryId,
        costCategoryName: costCategory.name,
        lineItemName: name,
      });
      onRequestClose(lineItem);
    },
  }),
  reduxForm({ form: formName, enableReinitialize: true, destroyOnUnmount: true, validate }),
  withHandlers({
    close: ({ reset, onRequestClose, costCategoryId, costCategory, name, estimate }) => () => {
      reset();
      track(Events.ESTIMATE_CREATE_NEW_LINE_ITEM_CANCEL, {
        ...getEstimateData(estimate),
        costCategoryId,
        costCategoryName: costCategory.name,
        lineItemName: name,
      });
      onRequestClose();
    },
  })
)(CreateLineItem);
