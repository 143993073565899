import qs from 'qs';
import axios from 'axios';
import { get, noop, omit } from 'lodash/fp';
import { logout } from 'state/authentication/actions';
import { getAPIToken } from 'state/authentication/authService';
import { showErrorNotification } from './notifications/actions';
import config from 'config';
const { apiEndpoint } = config;

const log = console.log; // process.env.NODE_ENV === 'development' ? console.log : noop;

const FETCH = 'middleware/FETCH';

export default ({ onError = noop } = {}) => ({ dispatch }) => {
  return next => async action => {
    if (action.type !== FETCH) {
      return next(action);
    }
    const accessToken = getAPIToken();
    const authParams = {
      method: 'get',
      ...(accessToken && {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    };

    const endPoint = `${apiEndpoint}${action.url}`;

    log('fetching', `${endPoint}`, authParams, action.params);

    const params = {
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...authParams,
      ...action.params,
    };

    return axios(endPoint, params)
      .then(response => log(action.url, response) || response)
      .catch(error => {
        const status = get('response.status', error);
        const description = get('response.data.message', error);
        const messageId = Math.round(Math.random() * 100000);
        const message = `API failure: id: ${messageId} ${endPoint} (error ${status}, ${
          error.message
        }) params: ${JSON.stringify(omit(['headers'], params))}`;

        onError(message);
        if (status === 401) {
          dispatch(showErrorNotification({ message: description }));
          return dispatch(logout());
        }
        throw new Error(description);
      });
  };
};

export const fetch = (url, params = {}) => ({
  type: FETCH,
  url,
  params,
});
