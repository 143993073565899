import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { Select } from 'formik-material-ui';
import { filter, map, flow, reject, find } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControl, InputLabel, MenuItem, Paper, Typography } from '@material-ui/core';
import { fetchAccounts } from 'state/accounts/actions';
import { getAccounts } from 'state/accounts/selectors';
import { fetchWorkspaces, addTeam } from 'state/workspaces/actions';
import { getWorkspaces } from 'state/workspaces/selectors';
import { fetchTeams, resetTeams } from 'state/teams/actions';
import { getTeams } from 'state/teams/selectors';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
  formControl: {
    marginRight: theme.spacing(2),
    minWidth: 200,
  },
  label: {
    background: 'white',
    padding: '0 4px',
  },
  button: {
    height: 52,
  },
}));

const LinkSchema = Yup.object().shape({
  workspaceId: Yup.string().required('Required'),
  teamId: Yup.string().required('Required'),
});

const Settings = ({
  accountId,
  agencies,
  workspaces,
  teams,
  fetchAccounts,
  fetchWorkspaces,
  fetchTeams,
  resetTeams,
  addTeam,
}) => {
  const classes = useStyles();

  useEffect(() => {
    fetchAccounts();
  }, [accountId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (accountId) {
      fetchWorkspaces();
      resetTeams();
    }
  }, [accountId]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const success = await addTeam(values.workspaceId, values.teamId);
    setSubmitting(false);
    if (success) {
      resetForm({});
    }
  };

  if (accountId) {
    return (
      <Paper className={classes.container}>
        <Typography variant="h6" className={classes.title}>
          Link client workspaces with agency and consultancy teams
        </Typography>

        <Formik
          initialValues={{}}
          validationSchema={LinkSchema}
          validateOnChange
          onSubmit={onSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
            const selectedWorkspace = find({ id: values.workspaceId }, workspaces);
            const availableAgencies = selectedWorkspace
              ? flow(reject(({ id }) => find({ id }, selectedWorkspace.externalAccounts)))(agencies)
              : [];
            return (
              <form onSubmit={handleSubmit}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="workspace-id" className={classes.label}>
                    Client Workspace
                  </InputLabel>
                  <Field
                    component={Select}
                    name="workspaceId"
                    variant="outlined"
                    inputProps={{
                      id: 'workspace-id',
                    }}
                  >
                    {map(
                      ({ id, name }) => (
                        <MenuItem value={id}>{name}</MenuItem>
                      ),
                      workspaces
                    )}
                  </Field>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="team-account-id" className={classes.label}>
                    Agency / Consultancy
                  </InputLabel>
                  <Field
                    component={Select}
                    name="teamAccountId"
                    variant="outlined"
                    autoWidth={true}
                    inputProps={{
                      id: 'team-account-id',
                      onChange: e => {
                        console.log('change', e);
                        fetchTeams(e.target.value);
                        return e;
                      },
                    }}
                  >
                    {map(
                      ({ id, name }) => (
                        <MenuItem value={id}>{name}</MenuItem>
                      ),
                      availableAgencies
                    )}
                  </Field>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="team-account-id" className={classes.label}>
                    Team
                  </InputLabel>
                  <Field
                    component={Select}
                    name="teamId"
                    variant="outlined"
                    autoWidth={true}
                    inputProps={{ id: 'team-account-id' }}
                  >
                    {map(
                      ({ id, name }) => (
                        <MenuItem value={id}>{name}</MenuItem>
                      ),
                      teams
                    )}
                  </Field>
                </FormControl>

                <Button
                  data-test="saveNewProduct"
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  disabled={isSubmitting}
                  className={classes.button}
                >
                  Save
                </Button>
              </form>
            );
          }}
        </Formik>
      </Paper>
    );
  }

  return <div>Select an account</div>;
};

const mapStateToProps = state => ({
  workspaces: getWorkspaces(state),
  agencies: filter(({ agency, consultancy }) => agency || consultancy, getAccounts(state)),
  teams: getTeams(state),
});

const mapDispatchToProps = (dispatch, { accountId }) => ({
  fetchAccounts: () => dispatch(fetchAccounts()),
  fetchWorkspaces: () => dispatch(fetchWorkspaces({ accountId })),
  fetchTeams: accountId => dispatch(fetchTeams({ accountId })),
  resetTeams: () => dispatch(resetTeams()),
  addTeam: (workspaceId, teamId) => dispatch(addTeam(workspaceId, teamId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
