import { keyBy, update, concat } from 'lodash/fp';
import { FETCH_SUCCESS } from './actions';
import { CREATE_LINE_ITEM_SUCCESS } from 'state/lineItems/actions';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return keyBy('id', action.costCategories);
    case CREATE_LINE_ITEM_SUCCESS:
      return update(`${action.costCategoryId}.lineItemNames`, items =>
        concat(items, [action.lineItem])
      )(state);
    default:
      return state;
  }
};
