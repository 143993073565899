import React from 'react';
import styled from 'styled-components';
import AddWatcher from './addWatcher';
import WatchersList from './watchersList';
import { font, colors } from 'shared/styles';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 20px;
  color: ${colors.text};
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 5px;
`;

const Title = styled.div`
  font-size: ${font.sizes.large};
  margin-bottom: 20px;
`;

const AddWatcherContainer = styled.div`
  width: 500px;
  margin-bottom: 20px;
`;

const WatchersListContainer = styled.div`
  display: flex;
`;

export default ({ match, pageId }) => {
  const estimateId = match.params.id;
  return (
    <Container>
      <Title>Include people who you'd like to be notified about this estimate.</Title>
      <AddWatcherContainer>
        <AddWatcher
          id="add-watcher-list"
          placeholder="Add Watcher..."
          estimateId={estimateId}
          pageId={pageId}
        />
      </AddWatcherContainer>
      <WatchersListContainer>
        <WatchersList estimateId={estimateId} pageId={pageId} />
      </WatchersListContainer>
    </Container>
  );
};
