import React from 'react';
import styled from 'styled-components';
import Spinner from 'react-spinkit';
import { colors } from 'shared/styles';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(150, 150, 150, 0.2);
`;

export const InlineLoader = props => <Spinner name="ball-beat" color={colors.primary} />;

export const FullScreenLoader = () => (
  <Container>
    <InlineLoader />
  </Container>
);
