import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-daterange-picker/dist/css/react-calendar.css';
import { compose, lifecycle, withState, withHandlers } from 'recompose';
import React from 'react';
import onClickOutside from 'react-onclickoutside';
import styled from 'styled-components';
import moment from 'moment';
import { DateRangePicker as DateRangePickerComponent, createStaticRanges } from 'react-date-range';
import {
  startOfYear,
  endOfYear,
  addYears,
  startOfQuarter,
  endOfQuarter,
  addQuarters,
  startOfMonth,
  endOfMonth,
  addMonths,
} from 'date-fns';
import CalendarIconSvg from 'icons/CalendarIcon';
import { colors, borderRadius } from 'shared/styles';

const staticRanges = createStaticRanges([
  {
    label: 'This Month',
    range: () => ({
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
    }),
  },
  {
    label: 'Last Month',
    range: () => ({
      startDate: startOfMonth(addMonths(new Date(), -1)),
      endDate: endOfMonth(addMonths(new Date(), -1)),
    }),
  },
  {
    label: 'This Quarter',
    range: () => ({
      startDate: startOfQuarter(new Date()),
      endDate: endOfQuarter(new Date()),
    }),
  },
  {
    label: 'Last Quarter',
    range: () => ({
      startDate: startOfQuarter(addQuarters(new Date(), -1)),
      endDate: endOfQuarter(addQuarters(new Date(), -1)),
    }),
  },
  {
    label: 'This Year',
    range: () => ({
      startDate: startOfYear(new Date()),
      endDate: endOfYear(new Date()),
    }),
  },
  {
    label: 'Last Year',
    range: () => ({
      startDate: startOfYear(addYears(new Date(), -1)),
      endDate: endOfYear(addYears(new Date(), -1)),
    }),
  },
  {
    label: 'Next Year',
    range: () => ({
      startDate: startOfYear(addYears(new Date(), 1)),
      endDate: endOfYear(addYears(new Date(), 1)),
    }),
  },
]);

const DateRangePickerComponentStyled = styled(DateRangePickerComponent)`
  border-radius: ${borderRadius};
  border: 1px solid ${colors.border};
  overflow: hidden;
  z-index: 1;

  .rdrInputRanges {
    display: none;
  }
`;

const DateRangePickerWrapped = compose(
  withHandlers({
    handleClickOutside: ({ onClickOutside }) => onClickOutside,
    handleSelect: ({ input, setDatePickerVisible }) => value => {
      const { endDate, startDate } = value.selection;
      input.onChange({
        start: startDate,
        end: endDate,
      });
      if (startDate.getTime() !== endDate.getTime()) {
        setDatePickerVisible(false);
        return;
      }
    },
  }),
  onClickOutside,
)(({ datePickerVisible, input, handleSelect }) => {
  const { start, end } = input.value;
  const selectionRange = {
    startDate: start || new Date(),
    endDate: end || new Date(),
    key: 'selection',
  };
  return datePickerVisible ? (
    <DateRangePickerComponentStyled
      onChange={handleSelect}
      ranges={[selectionRange]}
      staticRanges={staticRanges}
      showDateDisplay={false}
    />
  ) : null;
});

const DateRangeFieldContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  input.textInput {
    width: 100%;
    box-shadow: none;
    border: 1px solid ${colors.border};
    padding: 0 10px 0 12px;
    text-align: left;
    cursor: pointer;
  }

  .calendarIcon {
    font-size: 18px;
    color: ${colors.border};
  }
`;

const CompositeDateRangePicker = ({
  datePickerVisible,
  setDatePickerVisible,
  input,
  displayValue,
  handleSelect,
}) => {
  const { start, end } = input.value;
  const startValue = (start && moment(start).format('D MMM YY')) || '';
  const endValue = (end && moment(end).format('D MMM YY')) || '';
  // const shownValue = startValue ? `${startValue}  -  ${endValue}` : '';
  const shownValue = (end && moment(end).format('YYYY')) || '';
  return (
    <DateRangeFieldContainer>
      <input
        className="input textInput"
        type="text"
        readOnly
        placeholder="Select Date Range"
        value={shownValue}
        onFocus={() => setDatePickerVisible(true)}
        onClick={() => setDatePickerVisible(true)}
        data-test="startDateInput"
      />

      <CalendarIconSvg className="calendarIcon" onClick={() => setDatePickerVisible(true)} />

      <input type="text" readOnly hidden value={startValue} data-test="startDateInput" />
      <input type="text" readOnly hidden value={endValue} data-test="endDateInput" />

      <DateRangePickerWrapped
        input={input}
        onClickOutside={() => setDatePickerVisible(false)}
        setDatePickerVisible={setDatePickerVisible}
        datePickerVisible={datePickerVisible}
      />
    </DateRangeFieldContainer>
  );
};

export default compose(
  withState('datePickerVisible', 'setDatePickerVisible', false),
  withHandlers({
    keyupHanlder: ({ setDatePickerVisible }) => ({ key }) => {
      if (key === 'Escape') {
        setDatePickerVisible(false);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      window.addEventListener('keyup', this.props.keyupHanlder);
    },
    componentDidUpdate(prevProps) {
      if (prevProps.visible !== this.props.visible) {
        this.props.setDatePickerVisible(this.props.visible);
      }
    },
    componentWillUnmount() {
      window.removeEventListener('keyup', this.props.keyupHanlder);
    },
  }),
)(CompositeDateRangePicker);
