import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper } from '@material-ui/core';
import CreateProductForm from './CreateProductForm';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
  },
}));

const AddProduct = ({ createProduct, brandNames, productCategories, accountId }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <Typography variant="h6" className={classes.title}>
        Create New Segment
      </Typography>
      <CreateProductForm accountId={accountId} />
    </Paper>
  );
};

export default AddProduct;
