import { fetch } from 'state/fetchMiddleware';
import { fetchEstimate } from 'state/estimates/actions';

export const FETCH_SUCCESS = 'costCategories/FETCH_SUCCESS';
export const ADD_EMPTY_COST_CATEGORY = 'costCategories/ADD_EMPTY_COST_CATEGORY';
export const REMOVE_EMPTY_COST_CATEGORY = 'costCategories/REMOVE_EMPTY_COST_CATEGORY';
export const ADD_COST_CATEGORY_SUCCESS = 'costCategories/ADD_SUCCESS';
export const REMOVE_COST_CATEGORY_SUCCESS = 'costCategories/REMOVE_SUCCESS';
export const SAVE_COST_CATEGORY_ORDER_REQUEST = 'estimate/SAVE_COST_CATEGORY_ORDER_REQUEST';
export const SAVE_COST_CATEGORY_ORDER_SUCCESS = 'estimate/SAVE_COST_CATEGORY_ORDER_SUCCESS';

export const fetchCostCategories = accountId => async dispatch => {
  const { data: costCategories } = await dispatch(
    fetch('/costCategories', { params: { accountId } }),
  );

  dispatch({ type: FETCH_SUCCESS, costCategories });
};

export const addEmptyCostCategory = (estimateId, insertionIndex) => ({
  type: ADD_EMPTY_COST_CATEGORY,
  estimateId,
  insertionIndex,
});

export const removeEmptyCostCategory = (estimateId, insertionIndex) => ({
  type: REMOVE_EMPTY_COST_CATEGORY,
  estimateId,
  insertionIndex,
});

export const addCostCategory = (
  estimateId,
  costCategoryId,
  position = 99,
  pageId,
) => async dispatch => {
  await dispatch(
    fetch(`/estimates/${estimateId}/costCategories/${costCategoryId}`, {
      method: 'POST',
      data: {
        position,
      },
    }),
  );

  dispatch({ type: ADD_COST_CATEGORY_SUCCESS, estimateId, costCategoryId, position });

  // for now refresh estimate:
  console.log('pageId', pageId);
  dispatch(fetchEstimate(estimateId, pageId));
};

export const removeCostCategory = (estimateId, costCategoryId, pageId) => async dispatch => {
  try {
    await dispatch(
      fetch(`/estimates/${estimateId}/costCategories/${costCategoryId}`, {
        method: 'DELETE',
      }),
    );

    dispatch({ type: REMOVE_COST_CATEGORY_SUCCESS, estimateId, costCategoryId });

    // for now refresh estimate:
  } catch (error) {
    console.log(error);
  }
  dispatch(fetchEstimate(estimateId, pageId));
};

export const saveCostCategoryOrder = ({
  estimateId,
  costCategoryId,
  position,
  pageId,
}) => async dispatch => {
  dispatch({
    type: SAVE_COST_CATEGORY_ORDER_REQUEST,
    estimateId,
    costCategoryId,
    position,
  });

  await dispatch(
    fetch(`/estimates/${estimateId}/costCategories/${costCategoryId}`, {
      method: 'PUT',
      data: {
        position,
      },
    }),
  );

  dispatch({
    type: SAVE_COST_CATEGORY_ORDER_SUCCESS,
    estimateId,
    costCategoryId,
    position,
  });

  // for now refresh estimate:
  dispatch(fetchEstimate(estimateId, pageId));
};
