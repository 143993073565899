import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { Events, track, getEstimateData } from 'components/analytics';
import { getEstimateById } from 'state/estimates/selectors';
import { deleteWatcher } from 'state/estimateWatchers/actions';
import { RoundButton } from 'components/buttons';
import SvgTrashIcon from 'icons/TrashIcon';

const Button = ({ handleRemoveWatcher }) => (
  <RoundButton onClick={handleRemoveWatcher} data-test="remove-watcher">
    <SvgTrashIcon className="icon" />
  </RoundButton>
);

const mapStateToProps = (state, { estimateId }) => ({
  estimate: getEstimateById(estimateId)(state),
});

const mapDispatchToProps = (dispatch, { estimateId, watcherId, pageId }) => ({
  removeWatcher: isEmphemeral =>
    dispatch(deleteWatcher(estimateId, watcherId, pageId, isEmphemeral)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handleRemoveWatcher: ({ removeWatcher, estimate, watcherId, isEmphemeral }) => () => {
      removeWatcher(isEmphemeral);
      track(Events.ESTIMATE_DELETE_WACTHER, {
        ...getEstimateData(estimate),
        watcherId,
      });
    },
  }),
)(Button);
