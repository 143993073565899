import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import useRemoveSupplier from 'hooks/useRemoveSupplier';
import useUpdateSupplierStatus from 'hooks/useUpdateSupplierStatus';
import { useState } from 'react';
import { connect } from 'react-redux';
import { displayNotification } from 'state/notifications/actions';
import SupplierModal from './SupplierModal';
import RemoveConfirmationModal from './RemoveConfirmationModal';
import ApproveConfirmationModal from './ApproveConfirmationModal';
// @ts-ignore
import { SupplierApprovalStatus } from 'cr-core/constants';

interface Notification {
  type: 'error' | 'success';
  message: string;
}

interface Props {
  supplierId: string;
  supplierStatus: string;
  accountId: string;
  showNotification: (value: Notification) => void;
}

const SupplierActions = ({ supplierId, supplierStatus, accountId, showNotification }: Props) => {
  const [modal, setModal] = useState<'edit' | 'delete' | 'approve' | 'reject' | null>(null);

  const { removeSupplier } = useRemoveSupplier(supplierId, accountId, {
    onSuccess: () => {
      showNotification({ type: 'success', message: 'Supplier removed successfully.' });
    },

    onError: () => {
      showNotification({ type: 'error', message: 'Failed to remove supplier.' });
    },
  });

  const { updateSupplierStatus } = useUpdateSupplierStatus(supplierId, accountId, modal, {
    onSuccess: () => {
      showNotification({
        type: 'success',
        message:
          modal === 'approve'
            ? 'Supplier approved successfully.'
            : 'Supplier rejected successfully.',
      });
    },

    onError: () => {
      showNotification({ type: 'error', message: 'Failed to approve supplier.' });
    },
  });

  const closeModal = () => {
    setModal(null);
  };

  const handleRemove = () => {
    removeSupplier();
    closeModal();
  };

  const handleUpdateStatus = () => {
    updateSupplierStatus();
    closeModal();
  };

  return (
    <>
      <Tooltip title="Edit" placement="top">
        <IconButton onClick={() => setModal('edit')}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      {supplierStatus === SupplierApprovalStatus.PENDING_APPROVAL ? (
        <>
          <Tooltip title="Approve" placement="top">
            <IconButton onClick={() => setModal('approve')}>
              <ThumbUpIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reject" placement="top">
            <IconButton onClick={() => setModal('reject')}>
              <ThumbDownIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <Tooltip title="Delete" placement="top">
          <IconButton onClick={() => setModal('delete')}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      <SupplierModal
        supplierId={supplierId}
        accountId={accountId}
        open={modal === 'edit'}
        onClose={closeModal}
      />

      <RemoveConfirmationModal
        open={modal === 'delete'}
        onClose={closeModal}
        onRemove={handleRemove}
      />

      <ApproveConfirmationModal
        open={modal === 'approve' || modal === 'reject'}
        onClose={closeModal}
        onUpdate={handleUpdateStatus}
        action={modal}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  showNotification: (value: Notification) => {
    dispatch(displayNotification(value));
  },
});

export default connect(null, mapDispatchToProps)(SupplierActions);
