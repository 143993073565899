import React from 'react';
import { connect } from 'react-redux';
import { flow, map, groupBy, sumBy, sortBy, reject } from 'lodash/fp';
import { BarChart } from 'components/charts';
import { getDisplayCurrency } from 'state/authentication/selectors';
import { ChartContainer } from '../components';

const getChartData = (data, displayCurrency) =>
  flow(
    reject(({ value }) => value),
    groupBy('costCategoryId'),
    map(items => ({
      value: sumBy('amount', items),
      label: items[0].costCategoryName,
    })),
    sortBy(({ value }) => -value)
  )(data);

const Chart = ({ data, displayCurrency }) => (
  <ChartContainer>
    <BarChart
      data={getChartData(data, displayCurrency)}
      displayCurrency={displayCurrency}
      width={100}
      height={40}
    />
  </ChartContainer>
);

const mapStateToProps = state => ({
  displayCurrency: getDisplayCurrency(state),
});

export default connect(mapStateToProps)(Chart);
