import { flow, get, map, uniq, join, getOr } from 'lodash/fp';
import {
  CampaignStatuses,
  BudgetCenter,
  AccountSetting,
  ScaleOrNonScaleProject,
  MediaTypeLabels,
} from 'cr-core/constants';
import { regionsByCode } from 'components/forms/marsRegions';
import { getDivision, roundValue, roundNumber, roundHoursValue } from './utils';

// estimate
export const estimateStatus = { label: 'Estimate Status', value: getOr('', 'status') };
export const estimateReportingDate = {
  label: 'Air date',
  value: getOr('', 'date'),
  type: 'date',
};
export const estimateHistorical = {
  label: 'Historical (2019, 2020, 2021)',
  value: ({ historical }) => {
    return historical ? 'Yes' : 'No';
  },
};
export const campaignEstimateHistorical = {
  label: 'Historical (2019, 2020, 2021)',
  value: ({ estimates = [], name }) => {
    if (estimates.length === 0) {
      return 'No';
    }

    if (estimates.every(x => x.historical)) {
      return 'Yes';
    }

    if (estimates.every(x => !x.historical)) {
      return 'No';
    }

    return 'Yes,No';
  },
};
export const estimateType = {
  label: 'Estimate type',
  value: ({ mediaType }) => (mediaType ? MediaTypeLabels[mediaType] : ''),
};
export const mediaAdvertisingType = {
  label: 'Traditional?',
  value: getOr('', 'mediaAdvertisingType'),
};
export const estimateProductCategory = {
  label: 'Segment',
  value: flow(get('products'), map('brandCategory'), uniq, join(',')),
};
export const estimateId = { label: 'Estimate ID', value: getOr('', 'id') };
export const estimateName = {
  label: 'Estimate Name',
  value: ({ name }) => (!name || name === '' ? 'EMPTY CAMPAIGN' : name),
};
export const estimateNumber = {
  label: 'Estimate ID #',
  value: getOr('', 'humanId'),
};
export const estimateRegion = { label: 'Region', value: ({ region }) => regionsByCode[region] };
export const estimateLeadMarket = { label: 'Lead Market', value: getOr('', 'leadMarket') };
export const estimatePayingCountry = {
  label: 'Paying Country',
  value: row => getOr([], 'customData.payingCountries', row).join(', '),
};
export const latestEstimate = {
  label: 'Estimated Spend',
  value: roundValue('recommendedBidTotal'),
};
export const estimateActual = { label: 'Actualised Spend', value: roundValue('actualisedTotal') };
export const estimateOriginals = { label: 'Originals', value: getOr(0, 'numberOfTVCs') };
export const estimateEdits = { label: 'Edits', value: getOr(0, 'numberOfRATVs') };
export const estimateLink = {
  label: 'Link',
  value: ({ id }) => (id ? `${window.location.origin}/estimates/${id}/details` : ''),
};

export const estimateCampaignName = { label: 'Campaign', value: 'campaign.name' };
export const estimateCampaignId = { label: 'Campaign ID', value: 'campaign.id' };
export const estimateCampaignNumber = { label: 'Campaign ID #', value: 'campaign.humanId' };
export const estimateCampaignStatus = { label: 'Campaign Status', value: 'campaign.status' };
export const estimateCampaignProductCategory = { label: 'Segment', value: 'campaign.category' };
export const estimateCampaignProductBrand = { label: 'Brand', value: 'campaign.brand' };
export const estimateCampaignBudget = {
  label: 'Campaign Budget',
  value: roundValue('campaign.budget'),
};
export const estimateCampaignLatestEstimate = {
  label: 'Campaign Estimated Spend',
  value: roundValue('campaign.totalCost'),
};
export const estimateCampaignRemaingBudget = {
  label: 'Remaining Budget',
  value: ({ campaign: { budget, totalCost } }) => roundNumber(budget - totalCost),
};
export const estimateCampaignActualised = {
  label: 'Campaign Actualised Spend',
  value: 'campaign.actualised',
};
export const estimateCampaignSavings = {
  label: 'Savings',
  value: ({ campaign: { status, budget, actualised } }) =>
    status === CampaignStatuses.ACTUALISED ? roundNumber(budget - actualised) : '',
};

export const estimateBudgetYear = {
  label: 'Budget Year',
  value: ({ budgetYear }) => budgetYear || null,
};

export const estimateBudgetCenter = {
  label: 'Budget Center',
  value: ({ budgetCenter }) => (budgetCenter ? BudgetCenter[budgetCenter] : null),
};

export const estimateDivision = {
  label: 'Region',
  value: ({ customData }) =>
    [...new Set(((customData && customData.payingCountries) || []).map(getDivision))].join(', '),
};

export const estimateBudgetSource = {
  label: 'Budget Source',
  value: getOr('', 'customData.budgetSource'),
};

export const estimateApprovedAt = {
  label: 'Approval Date',
  value: ({ approvedAt }) => approvedAt,
};

export const estimateBundle = {
  label: 'Bundle',
  value: ({ bundle }) => (bundle ? 'Yes' : bundle === false ? 'No' : 'Not Set'),
};

export const workspaceName = {
  label: 'Workspace Name',
  value: 'campaign.workspace.name',
};

// line items
export const costCategoryName = { label: 'Cost Category', value: 'costCategoryName' };
export const lineItemName = { label: 'Line Item', value: 'lineItemName' };
export const lineItemCost = {
  label: 'Line Item Cost',
  value: roundValue('amount'),
};

export const lineItemHours = {
  label: 'Hours Account Mgt.',
  value: roundHoursValue('hours'),
};

export const lineItemText = {
  label: 'Note',
  value: value => {
    const text = getOr('', 'text', value);
    return value.type !== 'costText' ? 'N/A' : text === '' ? '' : text ?? '';
  },
};

export const lineItemValue = clientSettings => ({
  label: 'Line Item Value',
  value: row => {
    const lineItem = row.lineItems.find(
      lineItem =>
        lineItem.lineItemName === row.lineItemName &&
        lineItem.costCategoryName === row.costCategoryName
    );

    return lineItem && lineItem.suppliers?.length && clientSettings[AccountSetting.Suppliers]
      ? lineItem.suppliers.map(supplier => supplier?.supplier?.name).join(', ')
      : getOr('', 'value')(row);
  },
});

// campaign
export const campaignDivision = { label: 'Region', value: 'division' };

export const estimateCampaignClientIoNumber = {
  label: 'Global Campaign ID',
  value: 'campaign.clientIoNumber',
};

export const campaignClientIoNumber = {
  label: 'Global Campaign ID',
  value: 'clientIoNumber',
};

export const campaignBudgetCenter = {
  label: 'Budget Center',
  value: ({ estimates = [] }) => {
    const filtered = estimates.map(({ budgetCenter }) => budgetCenter).filter(value => value);

    return uniq(filtered).join(', ');
  },
};

export const campaignBudgetYear = {
  label: 'Budget Year',
  value: ({ estimates = [] }) => {
    const filtered = estimates.map(({ budgetYear }) => budgetYear).filter(value => value);

    return uniq(filtered).join(', ');
  },
};

export const campaignOriginalsTotal = {
  label: '# Originals per Campaign',
  value: ({ estimates = [] }) =>
    estimates.map(({ numberOfTVCs }) => numberOfTVCs || 0).reduce((acc, cur) => acc + cur, 0),
};
export const campaignEditsTotal = {
  label: '# Edits per Campaign',
  value: ({ estimates = [] }) =>
    estimates.map(({ numberOfRATVs }) => numberOfRATVs || 0).reduce((acc, cur) => acc + cur, 0),
};
export const campaignAdditionalDeliverablesTotal = {
  label: '# Additional Deliverables per Campaign',
  value: ({ estimates = [] }) =>
    estimates
      .map(({ additionalDeliverables }) =>
        Object.values(additionalDeliverables || {}).reduce((acc, cur) => acc + cur, 0)
      )
      .reduce((acc, cur) => acc + cur, 0),
};
export const campaignPayingCountry = { label: 'Paying Country', value: 'country' };

export const campaignCountry = {
  label: 'Paying Country',
  value: ({ estimates = [] }) => {
    const countries = estimates.reduce(
      (acc, { customData }) => acc.concat(customData?.payingCountries || []),
      []
    );

    return uniq(countries).join(', ');
  },
};

export const marsGUID = {
  label: 'Global Project ID',
  value: getOr('', 'marsGUID'),
};

export const productionStartDate = {
  label: 'Production Start Date',
  value: getOr('', 'productionStartDate'),
  type: 'date',
};

export const deliveryDate = {
  label: 'Delivery Date',
  value: getOr('', 'deliveryDate'),
  type: 'date',
};

export const estimateScaleOrNonScale = {
  label: 'Scale/Non-Scale',
  value: ({ scaleOrNonScale }) => (scaleOrNonScale ? ScaleOrNonScaleProject[scaleOrNonScale] : ''),
};

export const campaignProductCategory = { label: 'Segment', value: 'category' };
export const campaignProductBrand = { label: 'Brand', value: 'brand' };
export const campaignId = { label: 'Campaign ID', value: 'id' };
export const campaignName = { label: 'Campaign', value: 'name' };
export const campaignNumber = { label: 'Campaign ID #', value: 'humanId' };
export const campaignStatus = { label: 'Campaign Status', value: 'status' };
export const campaignBudget = { label: 'Campaign Budget', value: roundValue('budget') };
export const campaignLatestEstimate = { label: 'Estimated Spend', value: 'totalEstimated' };
export const campaignVariance = { label: 'Remaining Budget', value: roundValue('variance') };
export const campaignActual = { label: 'Actualised Spend', value: 'totalFinal' };
export const campaignSavings = { label: 'Savings', value: 'finalSaving' };
export const campaignWorkspaceName = { label: 'Workspace Name', value: 'workspace.name' };

export const estimateInfo = (clientSettings = {}, addMediaAdvertisingType = false) => {
  return [
    estimateRegion,
    estimatePayingCountry,
    productionStartDate,
    deliveryDate,
    estimateReportingDate,
    estimateScaleOrNonScale,
    estimateLeadMarket,
    estimateStatus,
    estimateType,
    clientSettings[AccountSetting.MediaAdvertisingType] &&
      addMediaAdvertisingType &&
      mediaAdvertisingType,
    workspaceName,
    estimateCampaignName,
    estimateCampaignNumber,
    estimateName,
    marsGUID,
    latestEstimate,
    estimateActual,
    estimateId,
    estimateLink,
  ];
};
