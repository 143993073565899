import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { get, map, find } from 'lodash/fp';
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getAccounts } from 'state/accounts/selectors';
import { getUser } from 'state/authentication/selectors';
import { unionBy } from 'lodash/fp';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    marginLeft: theme.spacing(2),
    background: 'white',
  },
  label: {
    background: 'white',
    padding: '0 4px',
  },
  hidden: {
    display: 'none',
  },
}));

const AccountSelect = ({ accounts, input, className }) => {
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      className={clsx(classes.formControl, className, {
        [classes.hidden]: accounts.length <= 1,
      })}
    >
      <InputLabel id="account-select-label" margin="dense" className={classes.label}>
        Account
      </InputLabel>
      <Select
        name="account"
        labelId="account-select-label"
        className={classes.selectAccount}
        variant="outlined"
        margin="dense"
        value={input.value}
        renderValue={id => get('name', find({ id }, accounts))}
        onChange={val => input.onChange(val)}
        inputProps={{
          name: 'account',
          classes: {
            icon: classes.selectAccountIcon,
            selectMenu: classes.selectAccountSelectMenu,
          },
        }}
      >
        {map(
          account => (
            <MenuItem value={account.id} key={account.id}>
              {account.name}
            </MenuItem>
          ),
          accounts
        )}
      </Select>
    </FormControl>
  );
};

const mapStateToProps = (state, { withAccessAccounts = false }) => ({
  accounts: !withAccessAccounts
    ? getAccounts(state)
    : unionBy(
        'id',
        getAccounts(state),
        getUser(state).roles.flatMap(({ accounts }) => accounts)
      ),
});

export default connect(mapStateToProps)(AccountSelect);
