export default theme => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },

  table: {
    outline: 'none',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
    fontSize: theme.typography.body1.fontSize,
  },
  noClick: {
    cursor: 'initial',
  },

  flexGrow: {
    flexGrow: 1,
  },
  flexCenter: {
    justifyContent: 'center',
  },
  tableHeader: {
    fontSize: theme.typography.body1.fontSize,
    '&:nth-child(2)': {
      flexGrow: 1,
    },
  },
  nameCell: {
    display: 'flex',
  },
  userEmail: {
    fontSize: theme.typography.body2.fontSize,
  },
  userName: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  checkbox: {
    padding: 0,
    borderRadius: 2,
  },

  errorHover: {
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  successHover: {
    '&:hover': {
      color: theme.palette.success.main,
    },
  },

  noDataContainer: {
    padding: theme.spacing(2),
    textAlign: 'center',
    fontSize: '1rem',
  },
});
