import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  flow,
  toPairs,
  map,
  isNull,
  isUndefined,
  reject,
  get,
  isEmpty,
  isObject,
  getOr,
  sortBy,
  identity,
  values,
  sum,
} from 'lodash/fp';
import { colors, font } from 'shared/styles';
import { convertCamelToPretty, convertUpperCaseToPretty } from 'cr-core/stringUtils';
import {
  MediaTypeLabels,
  MediaTypes,
  BudgetCenter,
  AccountSetting,
  DemoAdditionalTypes,
  ScaleOrNonScaleProject,
} from 'cr-core/constants';
import { HorizontalLine } from 'components/horizontalLine';
import { MediaTypeIcon } from 'icons';
import { getCampaignByEstimateId } from 'state/campaigns/selectors';
import { formatDateAsAuditTime } from 'shared/dateUtils';
import { getProductsForEstimate, getEstimateById } from 'state/estimates/selectors';
import ContributorsInfo from './contributorsInfo';
import { getUserWorkspaceById } from 'state/workspaces/selectors';
import { getClientSettings } from 'state/authentication/selectors';

const estimateTypesWithTVCDetails = [
  MediaTypes.AV,
  MediaTypes.TVC,
  MediaTypes.DIGITAL_VIDEO,
  MediaTypes.AUDIO,
  MediaTypes.ANIMATICS,
  MediaTypes.DIGITAL,
  MediaTypes.MOBILE_AND_WEB_APPS,
  MediaTypes.PRINT,
];

export const DetailItem = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 5px;
  height: 30px;
  :last-child {
    margin-bottom: 0px;
  }
  .label {
    font-weight: ${font.weights.bold};
    width: 200px;
    text-align: right;
    margin-right: 10px;
  }

  .icon {
    margin-right: 10px;
  }

  .by {
    font-size: ${font.sizes.normal};
    font-weight: ${font.weights.bold};
    margin: 0px 10px;
  }

  .creatorAccount {
    font-weight: ${font.weights.thin};
    font-size: ${font.sizes.normal};
    line-height: ${font.sizes.small};
    margin-left: 10px;
  }

  .date {
    font-weight: ${font.weights.thin};
  }

  .notSet {
    color: ${colors.textInputPlaceholder};
  }
`;

const Value = ({ value }) => {
  if (
    isNull(value) ||
    isUndefined(value) ||
    (isObject(value) && isEmpty(value)) ||
    (Array.isArray(value) && !value.length) ||
    value === ''
  ) {
    return (
      <span className="value notSet">
        <i>not set</i>
      </span>
    );
  }

  let str;
  if (Array.isArray(value)) {
    if (value.length > 3) {
      str = `${value.slice(0, 2).join(', ')} and ${value.length - 3} more`;
    } else {
      str = value.join(', ');
    }
  } else {
    str = value;
  }

  return <span className="value">{str}</span>;
};

const externalMetadataToExclude = ['payingCountries', 'contentTypes'];
const getExternalMetadataItems = estimate => {
  const { externalMetadata } = estimate;
  //TODO: Filter by mediaType
  return flow(
    toPairs,
    reject(([key]) => externalMetadataToExclude.includes(key)),
    map(([key, value]) => {
      const itemValue = Array.isArray(value) ? value.join(', ') : value;
      return (
        <DetailItem id={key} key={key}>
          <div className="label">{convertCamelToPretty(key)}</div> <Value value={itemValue} />
        </DetailItem>
      );
    })
  )(externalMetadata);
};

const mediaTypeLabels = {
  ...MediaTypeLabels,
  ...Object.fromEntries(DemoAdditionalTypes.map(({ value, label }) => [value, label])),
};

const EstimateDetailItems = ({
  estimate,
  className,
  workspace,
  clientSetttings,
  campaign,
  products,
}) => {
  const budgetSource = get('customData.budgetSource', estimate);
  const productionComplexity = get('customData.productionComplexity', estimate);
  const payingCountries = getOr([], 'customData.payingCountries', estimate);
  const additionalMarkets = getOr([], 'customData.additionalMarkets', estimate);

  return (
    <>
      <DetailItem data-test="workspace">
        <div className="label">Workspace</div> <Value value={get('name', workspace)} />
      </DetailItem>
      <DetailItem data-test="estimate-type">
        <div className="label">Estimate Type</div> <MediaTypeIcon type={estimate.mediaType} />
        <Value value={mediaTypeLabels[estimate.mediaType]} />
      </DetailItem>
      <DetailItem data-test="name">
        <div className="label">Estimate Name</div> <Value value={estimate.name} />
      </DetailItem>
      <DetailItem data-test="marsGUID">
        <div className="label">Global Project ID</div> <Value value={estimate.marsGUID} />
      </DetailItem>

      <DetailItem data-test="production-start-date">
        <div className="label">Production Start Date</div>
        <Value
          value={
            estimate.productionStartDate && formatDateAsAuditTime(estimate.productionStartDate)
          }
        />
      </DetailItem>
      <DetailItem data-test="delivery-date">
        <div className="label">Delivery Date</div>
        <Value value={estimate.deliveryDate && formatDateAsAuditTime(estimate.deliveryDate)} />
      </DetailItem>
      <DetailItem data-test="reporting-date">
        <div className="label">Air Date</div>
        <Value value={estimate.date && formatDateAsAuditTime(estimate.date)} />
      </DetailItem>
      <DetailItem data-test="campaign">
        <div className="label">Campaign</div>
        <Value value={campaign && `${campaign.humanId} - ${campaign.name}`} />
      </DetailItem>
      <DetailItem data-test="products">
        <span className="label">Product(s)</span>
        <Value value={products} />
      </DetailItem>

      {estimateTypesWithTVCDetails.includes(estimate.mediaType) && (
        <>
          <DetailItem data-test="number-of-originals">
            <span className="label">Number of Originals</span>
            <Value value={estimate.numberOfTVCs} />
          </DetailItem>
          <DetailItem data-test="number-of-edits">
            <span className="label">Number of Edits</span>
            <Value
              value={estimate.edits ? flow(values, sum)(estimate.edits) : estimate.numberOfRATVs}
            />
          </DetailItem>
          <DetailItem data-test="number-of-additional-deliverables">
            <span className="label">Number of Additional Deliverables</span>
            <Value
              value={
                estimate.additionalDeliverables
                  ? flow(values, sum)(estimate.additionalDeliverables)
                  : null
              }
            />
          </DetailItem>
        </>
      )}

      {estimate.externalMetadata && getExternalMetadataItems(estimate)}

      <HorizontalLine />

      <DetailItem data-test="lead-market">
        <div className="label">Lead Market</div>
        <Value value={estimate.leadMarket && convertUpperCaseToPretty(estimate.leadMarket)} />
      </DetailItem>
      <DetailItem data-test="additional-markets">
        <span className="label">Additional Markets</span>
        <Value value={additionalMarkets} />
      </DetailItem>
      <DetailItem data-test="paying-countries">
        <span className="label">Paying Countries</span>
        <Value value={payingCountries} />
      </DetailItem>
      <DetailItem data-test="scale-or-non-scale-project">
        <div className="label">Scale or Non-Scale Project</div>{' '}
        <Value
          value={
            estimate && estimate.scaleOrNonScale && ScaleOrNonScaleProject[estimate.scaleOrNonScale]
          }
        />
      </DetailItem>
      {clientSetttings[AccountSetting.BudgetCenter] && estimate && estimate.requireBudgetChecks && (
        <DetailItem>
          <span className="label">Budget Center</span>
          <Value value={estimate && estimate.budgetCenter && BudgetCenter[estimate.budgetCenter]} />
        </DetailItem>
      )}
      {clientSetttings[AccountSetting.BudgetYear] && estimate && estimate.requireBudgetChecks && (
        <DetailItem data-test="budget-year">
          <span className="label">Budget Year</span>
          <Value value={estimate && estimate.budgetYear} />
        </DetailItem>
      )}

      <DetailItem data-test="budget-source">
        <span className="label">Budget Source</span>
        <Value value={budgetSource} />
      </DetailItem>
      <DetailItem data-test="production-complexity">
        <div className="label">Production Complexity</div> <Value value={productionComplexity} />
      </DetailItem>
      <DetailItem data-test="poCode">
        <div className="label">Purchase Order n.</div> <Value value={estimate.poCode} />
      </DetailItem>
      {clientSetttings[AccountSetting.EstimateBundle] && (
        <DetailItem data-test="bundle">
          <span className="label">Bundle</span>
          <Value
            value={
              estimate && estimate.bundle ? 'Yes' : estimate.bundle === false ? 'No' : undefined
            }
          />
        </DetailItem>
      )}
      <HorizontalLine />
      <ContributorsInfo estimateId={estimate.id} />
    </>
  );
};

const mapStateToProps = (state, { estimateId }) => {
  const estimate = getEstimateById(estimateId)(state);
  const workspace =
    estimate && estimate.campaign && getUserWorkspaceById(estimate.campaign.workspaceId)(state);
  const productNameOnly = workspace && workspace.account.accountSettings.productNameOnly;

  const products = flow(
    getProductsForEstimate,
    map(({ brandName, productName }) =>
      productNameOnly ? productName : `${brandName} ${productName}`
    ),
    sortBy(identity)
  )(estimate);
  const campaign = getCampaignByEstimateId(estimateId)(state);
  return {
    products,
    estimate,
    campaign,
    clientSetttings: getClientSettings(state),
    workspace: get('workspace', campaign),
  };
};

export default connect(mapStateToProps)(EstimateDetailItems);
