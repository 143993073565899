import React from 'react';
import { map } from 'lodash/fp';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GoogleIcon from 'icons/external/GoogleIcon';
import { auth, authSignup } from 'state/authentication/authService';

const useStyles = makeStyles(theme => ({
  oauthButton: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    flexGrow: 1,
    backgroundColor: theme.palette.grey[200],
  },
  oauthButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
  },
  info: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
}));

const SSOConfig = {
  google: {
    name: 'Google',
    connection: 'google-oauth2',
    icon: <GoogleIcon className="icon" />,
  },
};

const clientSSOs = {
  mars: ['google'],
  mca: ['google'],
};

const getClient = () => {
  const hostnameParts = window.location.hostname.split('.');
  return hostnameParts.length === 4 ? hostnameParts[0] : undefined;
};

export default ({ signup }) => {
  const classes = useStyles();
  const action = `Sign ${signup ? 'Up' : 'In'}`;
  const onClick = connection => {
    if (signup) {
      return authSignup({ connection, state: window.location.search });
    }
    auth.authorize({ connection });
  };

  const client = getClient();

  return (
    <div className={classes.oauthButtonsContainer}>
      {map(
        provider => (
          <Button
            variant="contained"
            size="large"
            className={classes.oauthButton}
            startIcon={SSOConfig[provider].icon}
            onClick={() => onClick(SSOConfig[provider].connection)}
          >
            {action} with {SSOConfig[provider].name}
          </Button>
        ),
        clientSSOs[client]
      )}
    </div>
  );
};
