import React from 'react';
import { flow, map, uniqBy, sortBy, flatten } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import TeamMembers from '../teamMembers';
import { widgetStyles } from './utils';

const useStyles = makeStyles(theme => ({
  ...widgetStyles(theme),

  teamBody: {
    display: 'flex',
  },
  teamButton: {
    margin: `auto ${theme.spacing(2)}px`,
  },
}));

export default ({ estimates }) => {
  const classes = useStyles();
  const members = flow(
    map(({ watchers = [], approvals = [] }) =>
      flow(
        uniqBy('id'),
        sortBy('firstName')
      )([...map('user', watchers), ...map('approver', approvals)])
    ),
    flatten,
    uniqBy('id')
  )(estimates);

  if (!members.length) {
    return null;
  }

  return (
    <div className={classes.widget}>
      <div className={classes.widgetTitle}>Users</div>
      <div className={classes.teamBody}>
        <TeamMembers members={members} maxUsers={9} size={36} />
        {/* <div className={classes.teamButton}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              //open member modal
            }}
          >
            Show users
          </Button>
        </div>*/}
      </div>
    </div>
  );
};
