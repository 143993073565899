import React, { useEffect } from 'react';
import qs from 'qs';
import { get, pick } from 'lodash/fp';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, Field } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import { getUser } from 'state/authentication/selectors';
import AccountSelect from 'components/filters/accountSelect';
import SearchField from 'components/filters/searchTextInput';

export const formName = 'userFilters';

const useStyles = makeStyles(theme => {
  return {
    container: {
      display: 'flex',
    },
    search: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
  };
});

const UsersFiltersComponents = ({ filters, history, location }) => {
  const classes = useStyles();

  useEffect(() => {
    history.replace({
      pathname: location.pathname,
      search: `?${qs.stringify(pick('accountId', filters))}`,
    });
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.container}>
      <SearchField className={classes.search} />
      <Field name="accountId" component={({ input }) => <AccountSelect input={input} />} />
    </div>
  );
};

const mapStateToProps = (state, { location }) => {
  const user = getUser(state);
  const initialValues = {};
  if (user.accounts.length === 1) {
    initialValues.accountId = user.accounts[0].id;
  }

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const search = get('search', queryParams);
  const accountId = get('accountId', queryParams);
  if (search) {
    initialValues.search = search;
  }
  if (accountId) {
    initialValues.accountId = accountId;
  }

  return {
    initialValues,
    user: getUser(state),
    filters: getFormValues(formName)(state) || {},
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  reduxForm({ form: formName })
)(UsersFiltersComponents);
