import React from 'react';
import { flow, groupBy, mapValues, values, sortBy, sumBy, max, map, filter } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { formatAsCurrency } from 'cr-core/currencyUtils';
import { BarChart2 as BarChart } from 'components/charts';
import { colors } from 'shared/styles';
import Box from './box';
import Legend, { getValueWithPercentage, Circle } from './legend';

const defaultHeight = 200;
const description = {
  totalBudget: 'The sum of all the campaigns budget',
  estimatedSpend: 'The sum of all the campaigns spend',
  budgetLeft: 'The difference between Total Budget and Estimated Spend',
  overbudget: 'The sum of spend that went over the Total Budget',
};
const legend = [
  {
    label: 'Estimated Spend',
    color: colors.estimatedSpend,
  },
  {
    label: 'Budget Left',
    color: colors.budgetLeft,
  },
  {
    label: 'Overbudget',
    color: colors.overbudget,
  },
];

const useStyles = makeStyles(theme => ({
  chart: {
    height: defaultHeight,
    width: '100%',
  },
  tooltipTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  tooltipRow: {
    whiteSpace: 'nowrap',
  },
  tooltipDivider: {
    width: '100%',
    height: theme.spacing(1),
  },

  help: {
    flexShrink: 0,
    width: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  helpItem: {
    display: 'flex',
  },
  helpLabel: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  helpTitle: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
}));

const formatAxis = value => {
  let r = value;
  let suffix = '';
  if (r > 100) {
    suffix = 'k';
    r /= 1000;
    if (r > 100) {
      suffix = 'm';
      r /= 1000;
    }
  }
  r = Math.round(r * 10) / 10;
  r = r.toFixed(r % 1 === 0 ? 0 : 1);
  return `${r}${suffix}`;
};

const getData = ({ estimates, campaigns, indexPropGetFn }) => {
  return flow(
    map(campaign => ({
      ...campaign,
      indexProp: indexPropGetFn(campaign.estimates),
    })),
    groupBy('indexProp'),
    mapValues(campaigns => {
      const budget = sumBy('budget', campaigns);
      // let totalCost = sumBy('totalCost', campaigns);
      const campaignIds = map('id', campaigns);
      const totalCost = flow(
        filter(({ campaignId }) => campaignIds.includes(campaignId)),
        sumBy('recommendedBidTotal')
      )(estimates);
      let remainingBudget = budget - totalCost;

      return {
        indexProp: campaigns[0].indexProp,
        'Estimated Spend': remainingBudget >= 0 ? totalCost : budget,
        'Estimated SpendColor': colors.estimatedSpend,
        'Budget Left': remainingBudget >= 0 ? remainingBudget : 0,
        'Budget LeftColor': colors.budgetLeft,
        Overbudget: remainingBudget >= 0 ? 0 : Math.abs(remainingBudget),
        OverbudgetColor: colors.overbudget,
        data: {
          count: campaigns.length,
          totalCost: totalCost,
          remainingBudget: remainingBudget >= 0 ? remainingBudget : 0,
          budget,
          overbudget: remainingBudget >= 0 ? 0 : -remainingBudget,
        },
      };
    }),
    values,
    sortBy(({ data }) => -max([data.budget, data.totalCost]))
  )(campaigns);
};

const tooltip = ({ totalSpend, totalCount, totalDeliverables, displayCurrency }) => ({
  id,
  value,
  label,
  color,
  data,
}) => {
  const classes = useStyles();
  const { budget, totalCost, remainingBudget, overbudget } = data.data;
  return (
    <div className={classes.tooltip}>
      <div className={classes.tooltipTitle}>{data.indexProp}</div>
      <div className={classes.tooltipRow}>
        Total Budget: {formatAsCurrency(displayCurrency, budget)}
      </div>
      <div className={classes.tooltipDivider} />
      {Boolean(overbudget) && (
        <div className={classes.tooltipRow}>
          <Circle color={colors.overbudget} />
          Overbudget: {getValueWithPercentage(overbudget, budget, displayCurrency)}
        </div>
      )}
      {!overbudget && (
        <div className={classes.tooltipRow}>
          <Circle color={colors.budgetLeft} />
          Budget Left: {getValueWithPercentage(remainingBudget, budget, displayCurrency)}
        </div>
      )}
      <div className={classes.tooltipRow}>
        <Circle color={colors.estimatedSpend} />
        Estimated Spend: {getValueWithPercentage(totalCost, budget, displayCurrency)}
      </div>
    </div>
  );
};

const Help = () => {
  const classes = useStyles();
  const items = [
    {
      color: colors.estimatedSpend,
      label: 'Estimated Spend',
      description: description.estimatedSpend,
    },
    {
      color: colors.budgetLeft,
      label: 'Budget Left',
      description: description.budgetLeft,
    },
    {
      color: colors.overbudget,
      label: 'Overbudget',
      description: description.overbudget,
    },
  ];

  return (
    <div className={classes.help}>
      <div>
        <span className={classes.helpLabel}>Total Budget</span>: {description.totalBudget}
      </div>
      <div className={classes.tooltipDivider} />
      {map(
        ({ color, label, description }) => (
          <div className={classes.helpItem} key={label}>
            <Circle color={color} />
            <div>
              <span className={classes.helpLabel}>{label}</span>: {description}
            </div>
          </div>
        ),
        items
      )}
    </div>
  );
};

export default ({ estimates, campaigns, indexPropGetFn, displayCurrency, title }) => {
  const classes = useStyles();
  const data = getData({ estimates, campaigns, indexPropGetFn });
  const height = data.length > 6 ? defaultHeight + (data.length - 6) * 30 : defaultHeight;

  return (
    <Box
      title={title}
      Help={Help}
      Chart={() => (
        <div>
          <div className={classes.chart} style={{ height }}>
            <BarChart
              data={data}
              displayCurrency={displayCurrency}
              tooltip={tooltip({ displayCurrency })}
              indexBy="indexProp"
              keys={['Estimated Spend', 'Budget Left', 'Overbudget']}
              colors={({ id, data }) => data[`${id}Color`]}
              axisBottom={{ format: formatAxis }}
            />
          </div>
          <Legend items={legend} direction="horizontal" />
        </div>
      )}
    />
  );
};
