import React from 'react';
import { connect } from 'react-redux';
import { compose, withState, lifecycle } from 'recompose';
import styled from 'styled-components';
import Modal from 'components/modal';
import { Button } from 'components/buttons';
import { submitEstimate } from 'state/estimates/actions';
import { font, colors } from 'shared/styles';

// Define vars we'll be using
const brandSuccess = colors.approve;
const loaderSize = 7;
const checkHeight = loaderSize / 2;
const checkWidth = loaderSize / 4;
const checkLeft = loaderSize / 5;
const checkThickness = '4px';
const checkColor = brandSuccess;

const animationStyles = `
  .circle-loader-container {
    margin: 0 auto;
    display: flex;
    padding-bottom: 30px;
  }

  .circle-loader {
    margin-bottom: ${loaderSize / 2}em;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-left-color: ${checkColor};
    animation: loader-spin 1.2s infinite linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: ${loaderSize}em;
    height: ${loaderSize}em;
    margin: 0 auto;
  }

  .load-complete {
    -webkit-animation: none;
    animation: none;
    border-color: ${checkColor};
    transition: border 500ms ease-out;

    .checkmark {
      display: block;
    }
  }

  .checkmark {
    display: none;

    &.draw:after {
      animation-duration: 800ms;
      animation-timing-function: ease;
      animation-name: checkmark;
      transform: scaleX(-1) rotate(135deg);
    }

    &:after {
      opacity: 1;
      height: ${checkHeight}em;
      width: ${checkWidth}em;
      transform-origin: left top;
      border-right: ${checkThickness} solid ${checkColor};
      border-top: ${checkThickness} solid ${checkColor};
      content: '';
      left: ${checkLeft}em;
      top: ${checkHeight}em;
      position: absolute;
    }
  }

  @keyframes loader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: ${checkWidth}em;
      opacity: 1;
    }
    40% {
      height: ${checkHeight}em;
      width: ${checkWidth}em;
      opacity: 1;
    }
    100% {
      height: ${checkHeight}em;
      width: ${checkWidth}em;
      opacity: 1;
    }
  }
`;

const SubmitForApprovalModal = styled(Modal)`
  .modalWindow {
    width: 400px;
  }

  .header {
    background: white;
    text-shadow: none;
    line-height: 0;
  }

  .footer {
    border: none;
    padding-bottom: 30px;
  }

  .textContainer {
    text-align: center;
  }

  ${animationStyles}
`;

const ModalBody = styled.div`
  .title {
    margin-bottom: 20px;
  }
`;

const CloseButton = styled(Button)`
  margin: 0 auto;
  background: ${colors.approve};
  border: 0;
  padding: 0 30px;

  :hover {
    background-color: ${colors.approveHover};
  }
`;

const TextHeader = styled.div`
  font-weight: 600;
  margin-bottom: 5px;
`;

const TextDescription = styled.div`
  font-weight: 200;
  font-size: ${font.sizes.largish};
`;

const SuccessModal = ({
  onRequestClose,
  addApprovers,
  completed,
  showCompleted,
  header,
  description,
  ...restProps
}) => {
  const buttons =
    completed || showCompleted ? (
      <CloseButton onClick={onRequestClose} data-test="success-modal-close-btn">
        Close
      </CloseButton>
    ) : (
      ''
    );
  return (
    <SubmitForApprovalModal buttons={buttons} {...restProps} onRequestClose={onRequestClose}>
      <ModalBody>
        <div className="circle-loader-container">
          <div className={`circle-loader ${completed || showCompleted ? 'load-complete' : ''}`}>
            <div className="checkmark draw " />
          </div>
        </div>
        <div className="textContainer">
          <TextHeader>{header}</TextHeader>
          <TextDescription>{description}</TextDescription>
        </div>
      </ModalBody>
    </SubmitForApprovalModal>
  );
};

const mapDispatchToProps = (dispatch, { estimateId, onRequestClose, history }) => ({
  onSubmit: async ({ message }) => {
    await dispatch(submitEstimate(estimateId, message));
    onRequestClose();
  },
});

export default compose(
  connect(null, mapDispatchToProps),
  withState('showCompleted', 'setShowCompleted'),
  lifecycle({
    componentDidMount() {
      if (!this.props.completed) {
        setTimeout(() => this.props.setShowCompleted(true), 2000);
      }
    },
  })
)(SuccessModal);
