import { set } from 'lodash/fp';
import {
  SIDEPANEL_SET_DATA,
  SIDEPANEL_RESET_DATA,
  SIDEPANEL_OPEN,
  SIDEPANEL_CLOSE,
  SHOWEP_LOGIN,
  COLLAPSED_FILTER,
} from './actions';

const defaultState = {
  sidePanel: {
    data: {},
    open: false,
  },
  showEPLogin: window.location.host.includes('pepsico'),
  collapsedFiltersBar: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SIDEPANEL_SET_DATA:
      return set('sidePanel.data', action.data, state);

    case SIDEPANEL_RESET_DATA:
      return set('sidePanel.data', {}, state);

    case SIDEPANEL_OPEN:
      return set('sidePanel.open', true, state);

    case SIDEPANEL_CLOSE:
      return set('sidePanel.open', false, state);
    case SHOWEP_LOGIN:
      return { ...state, showEPLogin: action.payload };
    case COLLAPSED_FILTER:
      return { ...state, collapsedFiltersBar: action.payload };

    default:
      return state;
  }
};
