import { fetch } from 'state/fetchMiddleware';
import { showSuccessNotification, showErrorNotification } from 'state/notifications/actions';

export const PRODUCTS_FETCH_REQUEST = 'products/FETCH_REQUEST';
export const PRODUCTS_FETCH_SUCCESS = 'products/FETCH_SUCCESS';

export const PRODUCT_CREATE_REQUEST = 'product/CREATE_REQUEST';
export const PRODUCT_CREATE_SUCCESS = 'product/CREATE_SUCCESS';
export const PRODUCT_CREATE_ERROR = 'product/CREATE_ERROR';
export const PRODUCT_DELETE_REQUEST = 'product/PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'product/PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_ERROR = 'product/PRODUCT_DELETE_ERROR';

export const PRODUCT_UPDATE_REQUEST = 'product/PRODUCT_UPDATE_REQUEST';
export const PRODUCT_UPDATE_SUCCESS = 'product/PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_ERROR = 'product/PRODUCT_UPDATE_ERROR';

export const CATEGORYANDBRAND_DELETE_REQUEST = 'product/CATEGORYANDBRAND_DELETE_REQUEST';
export const CATEGORYANDBRAND_DELETE_SUCCESS = 'product/CATEGORYANDBRAND_DELETE_SUCCESS';
export const CATEGORYANDBRAND_DELETE_ERROR = 'product/CATEGORYANDBRAND_DELETE_ERROR';

export const fetchProducts =
  (clientId, filters = {}) =>
  async dispatch => {
    const { accountId } = filters;
    dispatch({ type: PRODUCTS_FETCH_REQUEST });
    const url =
      '/products' +
      (accountId ? `?accountId=${accountId}` : clientId ? `?accountId=${clientId}` : '');
    const { data: products } = await dispatch(fetch(url));
    dispatch({ type: PRODUCTS_FETCH_SUCCESS, products });
  };

export const updateProduct = (productId, data) => async dispatch => {
  try {
    dispatch({ type: PRODUCT_UPDATE_REQUEST });

    const { data: product } = await dispatch(
      fetch(`/products/${productId}`, {
        method: 'PUT',
        data,
      })
    );

    dispatch({ type: PRODUCT_UPDATE_SUCCESS, payload: product });
    dispatch(showSuccessNotification({ message: 'Product updated successfully' }));

    return product;
  } catch {
    dispatch({ type: PRODUCT_UPDATE_ERROR });
    dispatch(showErrorNotification({ message: 'Failed to update product. Please try again.' }));
  }
};

export const createProduct =
  ({ productBrand, productCategory, productName, accountId }) =>
  async dispatch => {
    dispatch({ type: PRODUCT_CREATE_REQUEST });
    try {
      const { data: product } = await dispatch(
        fetch('/products', {
          method: 'POST',
          data: {
            productBrand,
            productCategory,
            productName,
            accountId,
          },
        })
      );
      dispatch({ type: PRODUCT_CREATE_SUCCESS, product });
      // dispatch(fetchProducts());
      dispatch(
        showSuccessNotification({
          message: `Product ${
            productBrand.productBrandName || productBrand
          } ${productName} created`,
        })
      );
      return product;
    } catch (error) {
      dispatch({ type: PRODUCT_CREATE_ERROR, error });
      dispatch(showErrorNotification({ message: error.toString() }));
      // throw error;
    }
  };

export const deleteProduct = productId => async dispatch => {
  dispatch({ type: PRODUCT_DELETE_REQUEST });
  const url = `/products/${productId}`;
  try {
    const response = await dispatch(fetch(url, { method: 'DELETE' }));
    dispatch({ type: PRODUCT_DELETE_SUCCESS, productId });
    dispatch(showSuccessNotification({ message: formatResponseMessage(response.data) }));
    dispatch(fetchProducts());
  } catch (error) {
    dispatch({ type: PRODUCT_DELETE_ERROR, productId, error });
    dispatch(showErrorNotification({ message: error.toString() }));
  }
};

export const deleteCategoryAndBrand = data => async dispatch => {
  dispatch({ type: CATEGORYANDBRAND_DELETE_REQUEST });
  const url = `/products/categoryAndBrand`;
  try {
    const response = await dispatch(fetch(url, { method: 'DELETE', data }));
    dispatch({ type: CATEGORYANDBRAND_DELETE_SUCCESS });
    dispatch(showSuccessNotification({ message: formatResponseMessage(response.data) }));
    dispatch(fetchProducts());
  } catch (error) {
    dispatch({ type: CATEGORYANDBRAND_DELETE_ERROR, error });
    dispatch(showErrorNotification({ message: error.toString() }));
  }
};

const formatResponseMessage = arr => {
  const [deletedItems, nonDeletedItems] = arr.reduce(
    (acc, cur) => {
      if (cur.deleted) {
        acc[0].push(cur);
      } else {
        acc[1].push(cur);
      }

      return acc;
    },
    [[], []]
  );

  let deletedMessage = formatItems(deletedItems);
  if (deletedMessage) {
    deletedMessage = deletedMessage + ' deleted.';
  }

  let nonDeletedMessage = formatItems(nonDeletedItems);
  if (nonDeletedMessage) {
    const auxVerb = nonDeletedItems.length > 1 ? 'are' : 'is';
    nonDeletedMessage =
      nonDeletedMessage +
      ` can't be deleted because ${auxVerb} linked to already created campaign or estimate.`;
  }

  return deletedMessage.concat(' ', nonDeletedMessage);
};

const formatItems = arr => {
  let res = '';
  if (arr.length) {
    arr = arr.map(item => item.name[0].toUpperCase() + item.name.slice(1).toLowerCase());
    res = arr.join(', ');
    if (arr.length > 1) {
      let lastField = ', ' + arr[arr.length - 1];
      let andField = ' and ' + arr[arr.length - 1];
      res = res.replace(lastField, andField);
    }
  }
  return res;
};
