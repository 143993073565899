import React from 'react';
import styled from 'styled-components';
import { formatUserName } from 'cr-core/userUtils';
import moment from 'moment';
import { font } from 'shared/styles';

const Container = styled.div`
  margin-top: 20px;
  font-size: ${font.sizes.small};
`;

const User = styled.span`
  font-weight: ${font.weights.bold};
`;

const Account = styled.span`
  font-weight: ${font.weights.bold};
`;

export default ({ className, what, when, by, account }) => {
  return (
    <Container className={className}>
      {what} {moment(when).fromNow()} by <User>{formatUserName(by)}</User>
      {by.account && <Account>{` ${by.account.name}`}</Account>}
    </Container>
  );
};
