import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { flow, max, map } from 'lodash/fp';
import { EstimateStatuses } from 'cr-core/constants';
import Team from 'components/team';
import UserAvatar from 'components/userAvatar';
import { formatDateAsAuditTime } from 'shared/dateUtils';
import { getEstimateById } from 'state/estimates/selectors';
import { DetailItem } from './estimateDetailItems';

const Container = styled.div`
  .detailItem {
    height: auto;

    .date {
      width: 255px;
    }
  }
`;

const TeamContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  .icon {
    display: block;
    flex-shrink: 0;
    margin-right: 10px;
  }
  .userPicture,
  .initials {
    margin-right: 0px;
    margin-left: -10px;
  }
`;

const StyledUserAvatar = styled(UserAvatar)`
  border: 1px solid lightgrey;
  padding: 8px;
`;

const ContributorInfo = ({ estimate }) => {
  const approvedAt = flow(
    map('updatedAt'),
    map(date => new Date(date)),
    max
  )(estimate.approvals);
  const showApprovers =
    (estimate.status === EstimateStatuses.APPROVED ||
      estimate.status === EstimateStatuses.ACTUALISED) &&
    approvedAt;

  return (
    <Container>
      <DetailItem className="detailItem">
        <div className="label">Created</div>
        <div className="date">{formatDateAsAuditTime(estimate.createdAt)}</div>
        <div className="by">by</div>
        <StyledUserAvatar user={estimate.creator} />
      </DetailItem>

      {showApprovers && (
        <DetailItem className="detailItem">
          <div className="label">Approved</div>
          <div className="date">{formatDateAsAuditTime(approvedAt)}</div>
          <div className="by">by</div>
          {estimate.approvals.length === 1 && (
            <StyledUserAvatar user={estimate.approvals[0].approver} />
          )}
          {estimate.approvals.length > 1 && (
            <TeamContainer data-test="estimate-team">
              <Team team={map('approver', estimate.approvals)} />
            </TeamContainer>
          )}
        </DetailItem>
      )}
    </Container>
  );
};

const mapStateToProps = (state, { estimateId }) => {
  return {
    estimate: getEstimateById(estimateId)(state),
  };
};

export default connect(mapStateToProps)(ContributorInfo);
