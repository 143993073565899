import React from 'react';
import styled from 'styled-components';
import { noop } from 'lodash/fp';
import { CancelButton, Button } from 'components/buttons';
import Modal from 'components/modal';
import { colors } from 'shared/styles';

const ConfirmationModal = styled(Modal)`
  .header {
    background-color: ${colors.button};
  }
`;

const Cancel = styled(CancelButton)`
  min-width: 150px;
  justify-content: center;
`;

const Ok = styled(Button)`
  min-width: 150px;
  justify-content: center;
`;

export default ({
  title,
  message,
  onRequestClose,
  onCancel = noop,
  onConfirm,
  children,
  confirmButton = Ok,
  ...props
}) => {
  const ConfirmButton = confirmButton;
  const buttons = (
    <>
      <Cancel
        onClick={async () => {
          await onCancel();
          onRequestClose(false);
        }}
      >
        Cancel
      </Cancel>
      <ConfirmButton
        onClick={async () => {
          await onConfirm();
          onRequestClose(true);
        }}
        data-test="confirmation-ok"
      >
        OK
      </ConfirmButton>
    </>
  );
  return (
    <ConfirmationModal
      title={title}
      onRequestClose={async () => {
        await onCancel();
        onRequestClose(false);
      }}
      buttons={buttons}
      {...props}
    >
      {children}
    </ConfirmationModal>
  );
};
