import React from 'react';
import styled from 'styled-components';
import { ContentPageTitle } from 'components/text';
import SadFaceIconSvg from 'icons/SadFaceIcon';
import AppContainer from 'components/appContainer';

const Container = styled.div`
  padding: 60px 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
`;

const Icon = styled(SadFaceIconSvg)`
  color: #4a4a4a;
  font-size: 150px;
`;

const TextContainer = styled.div`
  margin-left: 70px;
  font-size: 14px;
  text-align: center;
`;

const Title = styled(ContentPageTitle)`
  font-size: 120px;
  line-height: 100px;
  margin-bottom: 20px;
`;

const SubTitle = styled(ContentPageTitle)`
  font-size: 24px;
  margin-bottom: 5px;
`;

const NotFoundPage = ({ path, user }) => {
  return (
    <AppContainer>
      <Container>
        <Icon alt="Sad free icon" title="Sad free icon" />
        <TextContainer>
          <Title>404</Title>
          <SubTitle>Something's missing.</SubTitle>
          <div>This page is missing or the link is incorrect.</div>
        </TextContainer>
      </Container>
    </AppContainer>
  );
};

export default NotFoundPage;
