import {
  get,
  getOr,
  sum,
  values,
  flow,
  map,
  sumBy,
  uniq,
  join,
  compact,
  uniqBy,
  filter,
  groupBy,
} from 'lodash/fp';
import {
  EstimateStatusLabels,
  EstimateStatuses,
  CampaignStatuses,
  CampaignStatusLabels,
} from 'cr-core/constants';
import {
  sortByEnum,
  estimateCostAccessor,
  aggregateAverage,
  aggregateSum,
  sortWithMultipleValues,
} from '../utils';
import { formatFinanceValue, CurrencyValueCell, FooterAverage, FooterTotal } from '../components';
import { regionsByCode, countriesByName } from 'components/forms/marsRegions';
import { COLUMNS } from '../utils.legend';

const statuses = [
  EstimateStatuses.ACTUALISED,
  EstimateStatuses.APPROVED,
  EstimateStatuses.PENDING_APPROVAL,
  EstimateStatuses.DRAFT,
  EstimateStatuses.CANCELLED,
];

export const estimateStatus = () => ({
  id: 'status',
  Header: 'Estimate Status',
  description: COLUMNS.estimateStatus,
  accessor: 'status',
  maxWidth: 200,
  Cell: ({ value }) => EstimateStatusLabels[value],
  Footer: () => 'Total',
  sortType: sortByEnum(statuses),
});

export const estimateName = () => ({
  id: 'estimates',
  Header: 'Estimates',
  description: COLUMNS.estimateName,
  accessor: 'name',
  aggregate: (values = []) => {
    return values.filter(value => value).length;
  },
  Cell: ({ row }) => {
    const name = get('original.name', row);
    return name;
  },
  Aggregated: ({ value, data = [] }) => value,
  Footer: ({ data = [] }) => filter(({ id }) => id, data).length,
  SubComponent: true,
});

export const estimateRegion = () => ({
  id: 'region',
  Header: 'Region',
  description: COLUMNS.estimateRegion,
  maxWidth: 200,
  accessor: ({ region }) => regionsByCode[region] || 'Not Set',
  PivotValue: ({ value }) => getOr('Not Set', value, regionsByCode),
  Footer: () => 'Total',
});

export const estimateLeadMarket = () => ({
  id: 'country',
  Header: 'Lead Market',
  description: COLUMNS.estimateLeadMarket,
  maxWidth: 200,
  accessor: row => (row.region === 'UNKNOWN' ? 'Not Set' : row.leadMarket),
  aggregate: values => get('length', values),
  PivotValue: ({ value }) =>
    getOr(values(regionsByCode).includes(value) ? value : 'Not Set', value, countriesByName),
  Aggregated: ({ value }) => value,
});

export const estimateOriginals = ({ originals }) => ({
  Header: '# Originals',
  description: COLUMNS.estimateOriginals,
  accessor: 'numberOfTVCs',
  aggregate: sum,
  maxWidth: 100,
  Cell: ({ value }) => value || 0,
  Aggregated: ({ value }) => value || 0,
  Footer: () => originals || 0,
  align: 'right',
});

export const estimateEdits = ({ edits }) => ({
  Header: '# Edits',
  description: COLUMNS.estimateEdits,
  // accessor: ({ numberOfRATVs, edits }) => (edits ? flow(values, sum)(edits) : numberOfRATVs),
  accessor: 'numberOfRATVs',
  aggregate: sum,
  maxWidth: 100,
  Cell: ({ value }) => value || 0,
  Aggregated: ({ value }) => value || 0,
  Footer: () => edits || 0,
  align: 'right',
});

export const estimateProductCategory = () => ({
  id: 'productCategory',
  Header: 'Segment',
  description: COLUMNS.estimateProductCategory,
  maxWidth: 200,
  accessor: ({ products }) =>
    get('length', products) ? flow(map('brandCategory'), uniq, join(','))(products) : 'Not Set',
  // PivotValue: ({ value }) => value,
  sortType: sortWithMultipleValues,
});

export const estimateCampaignProductCategory = () => ({
  id: 'productCategory',
  Header: 'Segment',
  description: COLUMNS.campaignProductCategory,
  maxWidth: 200,
  accessor: 'campaign.category',
  // PivotValue: ({ value }) => value,
  sortType: sortWithMultipleValues,
});

export const estimateCampaignDivision = () => ({
  id: 'division',
  Header: 'Region',
  description: COLUMNS.campaignDivision,
  maxWidth: 130,
  accessor: 'campaign.division',
  // PivotValue: ({ value }) => value,
  sortType: sortWithMultipleValues,
});

export const estimateCampaignPayingCountry = () => ({
  id: 'country',
  Header: 'Paying Country',
  description: COLUMNS.campaignPayingCountry,
  maxWidth: 200,
  accessor: 'campaign.payingCountry',
  // PivotValue: ({ value }) => value,
  sortType: sortWithMultipleValues,
});

export const estimateCampaignName = () => ({
  id: 'campaign',
  Header: 'Campaign',
  description: COLUMNS.campaignName,
  maxWidth: 200,
  accessor: 'campaign.id',
  aggregate: (values = []) => {
    return values.length;
  },
  Cell: ({ row, value }) => {
    const rows = (row || {}).subRows || [];
    const campaign = rows
      .map(({ original }) => original.campaign)
      .find(campaign => campaign && campaign.id === value);

    return campaign ? campaign.name || '' : null;
  },
  Footer: ({ data = [] }) => flow(map('campaignId'), uniq, arr => arr.length)(data),
  expandable: row => {
    return row.subRows.length > 1 || (row.subRows.length === 1 && row.subRows[0].original.id);
  },
});

export const estimateCampaignStatus = () => ({
  id: 'campaignStatus',
  Header: 'Campaign Status',
  description: COLUMNS.campaignStatus,
  accessor: 'campaign.status',
  Cell: ({ cell: { value } }) => CampaignStatusLabels[value],
  Aggregated: () => '',
  maxWidth: 80,
});

export const estimateCampaignBudget = ({ displayCurrency }) => ({
  id: 'campaignBudget',
  Header: 'Total Budget',
  description: COLUMNS.campaignBudget,
  accessor: ({ campaign }) => (campaign ? { id: campaign.id, value: campaign.budget } : null),
  aggregate: flow(compact, uniqBy('id'), sumBy('value')),
  maxWidth: 130,
  Cell: '-',
  Aggregated: CurrencyValueCell(displayCurrency),
  Footer: ({ data = [] }) => {
    const budget = flow(uniqBy('campaignId'), sumBy('campaign.budget'))(data);
    return formatFinanceValue(budget, displayCurrency);
  },
  align: 'right',
});

export const estimateCampaignVariance = ({ displayCurrency }) => ({
  id: 'campaignVariance',
  Header: 'Budget Left',
  description: COLUMNS.campaignVariance,
  accessor: ({ campaign, recommendedBidTotal }) => {
    return campaign
      ? {
          id: campaign.id,
          budget: campaign.budget,
          cost: recommendedBidTotal || 0,
        }
      : null;
  },
  aggregate: flow(
    compact,
    groupBy('id'),
    values,
    map(estimates => estimates[0].budget - sumBy('cost', estimates)),
    sum
  ),
  maxWidth: 130,
  Cell: '-',
  Aggregated: CurrencyValueCell(displayCurrency),
  Footer: ({ data = [] }) => {
    const budget = flow(uniqBy('campaignId'), sumBy('campaign.budget'))(data);
    const cost = sumBy('recommendedBidTotal', data);
    return formatFinanceValue(budget - cost, displayCurrency);
  },
  align: 'right',
});

export const estimateCampaignSavings = ({ displayCurrency }) => ({
  id: 'campaignSavings',
  Header: 'Savings',
  description: COLUMNS.campaignActualSavings,
  accessor: ({ campaign, actualisedTotal }) => {
    return get('status', campaign) === CampaignStatuses.ACTUALISED
      ? {
          id: campaign.id,
          budget: campaign.budget,
          cost: actualisedTotal,
        }
      : null;
  },
  aggregate: data =>
    flow(
      compact,
      groupBy('id'),
      values,
      map(estimates => estimates[0].budget - (sumBy('cost', estimates) || 0)),
      sum
    )(data),
  maxWidth: 130,
  Cell: '-',
  Aggregated: CurrencyValueCell(displayCurrency),
  Footer: ({ data = [] }) => {
    const actualisedData = filter(
      ({ campaign }) => get('status', campaign) === CampaignStatuses.ACTUALISED,
      data
    );
    const budget = flow(uniqBy('campaignId'), sumBy('campaign.budget'))(actualisedData) || 0;
    const cost = sumBy('actualisedTotal', actualisedData) || 0;
    return formatFinanceValue(budget - cost, displayCurrency);
  },
  align: 'right',
});

export const estimateLatestEstimate = ({ displayCurrency }) => ({
  id: 'estimated',
  Header: 'Estimated Spend',
  accessor: 'recommendedBidTotal',
  description: COLUMNS.estimateLatestEstimate,
  aggregate: aggregateSum,
  maxWidth: 130,
  Cell: CurrencyValueCell(displayCurrency),
  Aggregated: CurrencyValueCell(displayCurrency),
  Footer: FooterTotal(displayCurrency, 'estimated'),
  align: 'right',
});

export const estimateActualised = ({ displayCurrency }) => ({
  id: 'actualised',
  Header: 'Actualised Spend',
  description: COLUMNS.estimateActualised,
  accessor: ({ status, actualisedTotal }) =>
    status === EstimateStatuses.ACTUALISED ? actualisedTotal : 0,
  aggregate: aggregateSum,
  maxWidth: 130,
  Cell: CurrencyValueCell(displayCurrency),
  Aggregated: CurrencyValueCell(displayCurrency),
  Footer: FooterTotal(displayCurrency, 'actualised'),
  align: 'right',
});

export const estimateAverage = ({ displayCurrency }) => ({
  id: 'average',
  Header: 'Average',
  description: COLUMNS.estimateAverage,
  accessor: estimateCostAccessor,
  aggregate: aggregateAverage,
  maxWidth: 130,
  Cell: CurrencyValueCell(displayCurrency),
  Aggregated: CurrencyValueCell(displayCurrency),
  Footer: FooterAverage(displayCurrency),
  align: 'right',
});

export const estimateBundle = () => ({
  id: 'bundle',
  Header: 'Bundle',
  description: 'Indicator if project is a bundle.',
  accessor: 'bundle',
  Cell: ({ row }) =>
    row.original.bundle ? 'Yes' : row.original.bundle === false ? 'No' : 'Not Set',
  Aggregated: ({ value, data = [] }) => value,
});

export const estimateSuppliers = () => ({
  id: 'suppliers',
  Header: 'Suppliers',
  description: 'Indicator if project is a bundle.',
  accessor: 'lineItems',
  Cell: ({ row, value }) => {
    const lineItem = row?.original?.lineItems.find(
      x =>
        x.lineItemName === row.original.lineItemName &&
        x.costCategoryId === row.original.costCategoryId
    );

    return lineItem?.suppliers?.map(({ supplier: { name } }) => name).join(', ') ?? '';
  },
  Aggregated: ({ value, data = [] }) => {
    return value;
  },
});
