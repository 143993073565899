import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, lifecycle } from 'recompose';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Field, reduxForm } from 'redux-form';
import { Events, track, getEstimateData } from 'components/analytics';
import { Button, CancelButton } from 'components/buttons';
import ApprovalsList from 'components/estimateApprovals/approvalsList';
import InfoBox from 'components/infoBox';
import Modal from 'components/modal';
import UserAvatar from 'components/userAvatar';
import WarningBox from 'components/warningBox';
import SvgBackIcon from 'icons/BackIcon';
import SvgSendIcon from 'icons/SendIcon';
import SvgEditIcon from 'icons/EditIcon';
import { getUnsubmittedEstimateApprovalsFor } from 'state/estimateApprovals/selectors';
import { submitEstimate, resetSubmitEstimate } from 'state/estimates/actions';
import {
  getEstimateFieldsError,
  getEstimateById,
  getEstimateDeliverablesError,
  getPoCodeError,
  canEdit,
  hasLineItemsErrors,
} from 'state/estimates/selectors';
import { LOADING, SUCCESS, ERROR } from 'state/resourceStatus/reducer';
import { font, colors } from 'shared/styles';
import ErrorModal from './error';
import SuccessModal from './success';
import PoCodeErrorModal from './PoCodeErrorModal';
import { Box } from '@material-ui/core';

const ModalBody = styled.div`
  .title {
    margin-bottom: 20px;
  }
`;

const AdditionalMessage = styled.div`
  margin: 20px 0;

  .messageTitle {
    font-size: ${font.sizes.largish};
    margin-bottom: 5px;
  }

  .messageSubitle {
    font-size: ${font.sizes.normal};
    margin-bottom: 10px;
  }

  .messageInput {
    width: 100%;
    height: 150px;
  }
`;

const SelectedApproversList = styled(ApprovalsList)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const ApproverAvatar = styled(UserAvatar)`
  display: flex;
  .userName {
    color: ${colors.text};
    font-weight: ${font.weights.bold};
    text-shadow: none;
  }
`;

const ApprovalListItemRenderer = styled(({ approval, className }) => (
  <div className={className}>
    <ApproverAvatar user={approval.approver} />
  </div>
))`
  display: flex;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
`;

const ApproversHeader = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;

const SubmitForApprovalModal = ({
  estimateId,
  onConfirmSubmit,
  cancel,
  handleSubmit,
  valid,
  message,
  missingFields,
  ...restProps
}) => {
  const buttons = (
    <>
      <CancelButton onClick={cancel}>
        <SvgBackIcon className="icon" />
        Cancel
      </CancelButton>
      <Button onClick={handleSubmit} disabled={!valid} data-test="send-approval-request-btn">
        <SvgSendIcon className="icon" />
        Send to Approvers
      </Button>
    </>
  );

  return (
    <Modal title="Submit for Approval" buttons={buttons} {...restProps} onRequestClose={cancel}>
      <ModalBody>
        <ApproversHeader>Approvers</ApproversHeader>
        <SelectedApproversList
          estimateId={estimateId}
          ItemRenderer={ApprovalListItemRenderer}
          onlyUnsent={true}
        />
        <form onSubmit={handleSubmit}>
          <AdditionalMessage>
            <div className="messageTitle">Comments (Optional)</div>
            <div className="messageSubitle">You can add comments for the approvers</div>
            <Field className="messageInput" name="message" component="textarea" />
          </AdditionalMessage>
          <InfoBox>
            An email will be sent to approvers and watchers with estimate information and your
            comment.
          </InfoBox>
        </form>
      </ModalBody>
    </Modal>
  );
};

const AddApproversWarningModal = ({ cancel, addApprovers, ...restProps }) => {
  const buttons = (
    <>
      <CancelButton onClick={cancel}>
        <SvgBackIcon className="icon" />
        Cancel
      </CancelButton>
      <Button onClick={addApprovers} data-test="send-approval-request-btn">
        Add Approvers
      </Button>
    </>
  );

  return (
    <Modal title="Submit for Approval" buttons={buttons} {...restProps} onRequestClose={cancel}>
      <ModalBody>
        <WarningBox>You don't have any Approvers assigned for this estimate yet.</WarningBox>
        <br />
        Would you like to add more Approvers?
      </ModalBody>
    </Modal>
  );
};

const EstimateSubmittedModal = ({ close, ...props }) => {
  const header = (
    <>
      Great!
      <br />
      Your estimate has been successfully submitted for Approval
    </>
  );
  const description = 'A notification has been sent to all approvers and watchers';
  return (
    <SuccessModal
      completed={true}
      header={header}
      description={description}
      {...props}
      onRequestClose={close}
    />
  );
};

const EstimateSubmittingModal = props => (
  <SuccessModal header={'Your estimate is being submitted for Approval'} {...props} />
);

const EstimateErrorModal = ({ close, ...props }) => (
  <ErrorModal
    header="An error occured while submitting the estimate"
    description="Try again"
    {...props}
    onRequestClose={close}
  />
);

const MissingRequiredFieldsErrorModal = ({
  missingFields,
  onRequestClose,
  cancel,
  editEstimate,
  canEdit,
  hasLineItemErrors,
  ...restProps
}) => {
  const buttons = (
    <>
      <CancelButton onClick={cancel}>
        <SvgBackIcon className="icon" />
        Cancel
      </CancelButton>
      {canEdit && (
        <Button onClick={editEstimate} data-test="edit-estimate-btn">
          <SvgEditIcon className="icon" />
          Edit Estimate
        </Button>
      )}
    </>
  );

  return (
    <Modal
      title="Missing Required Fields"
      buttons={buttons}
      {...restProps}
      onRequestClose={onRequestClose}
    >
      <ModalBody>
        <ApproversHeader>
          To submit an estimate all the required metadata need to be set.
          <br />
          Please fill all the required metadata fields of the estimate.
        </ApproversHeader>

        <WarningBox>
          The following required fields are missing:
          <ul>
            {missingFields.map(field => (
              <li key={field}>{field}</li>
            ))}
          </ul>
          {hasLineItemErrors && (
            <Box mt={1}>Some of the line items are not valid, please check their errors.</Box>
          )}
        </WarningBox>
      </ModalBody>
    </Modal>
  );
};

const MissingRequiredLineItemsErrorModal = ({
  missingFields,
  onRequestClose,
  cancel,
  editEstimate,
  canEdit,
  hasLineItemErrors,
  editLineItems,
  ...restProps
}) => {
  const buttons = (
    <>
      <CancelButton onClick={cancel}>
        <SvgBackIcon className="icon" />
        Cancel
      </CancelButton>
      {canEdit && (
        <Button onClick={editLineItems} data-test="edit-estimate-btn">
          <SvgEditIcon className="icon" />
          Edit Estimate
        </Button>
      )}
    </>
  );

  return (
    <Modal
      title="Missing Required Line Items"
      buttons={buttons}
      {...restProps}
      onRequestClose={onRequestClose}
    >
      <ModalBody>
        <ApproversHeader>
          To submit an estimate all the required line items need to be set.
          <br />
          Please fill all the required line items of the estimate.
        </ApproversHeader>

        <WarningBox>
          {hasLineItemErrors && (
            <Box mt={1}>Some of the line items are not valid, please check their errors.</Box>
          )}
        </WarningBox>
      </ModalBody>
    </Modal>
  );
};

const DeliverablesErrorModal = ({ cancel, addDeliverables, canEdit, ...restProps }) => {
  const buttons = (
    <>
      <CancelButton onClick={cancel}>
        <SvgBackIcon className="icon" />
        Cancel
      </CancelButton>
      {canEdit && (
        <Button onClick={addDeliverables} data-test="add-deliverables-btn">
          Add Deliverables
        </Button>
      )}
    </>
  );

  return (
    <Modal title="Missing Required Fields" buttons={buttons} {...restProps} onRequestClose={cancel}>
      <ModalBody>
        <WarningBox>To submit an estimate deliverables need to be specified</WarningBox>
        <br />
        Would you like to add deliverables?
      </ModalBody>
    </Modal>
  );
};

const SubmitForApproval = props => {
  const { estimateSubmit, unsubmittedApprovalCount, poCodeError, estimateId, hasLineItemErrors } =
    props;

  if (props.missingFields.length > 0) {
    if (props.missingFields.length > 1) {
      return MissingRequiredFieldsErrorModal(props);
    }
    if (props.deliverablesError) {
      return DeliverablesErrorModal(props);
    }
    return MissingRequiredFieldsErrorModal(props);
  }

  if (poCodeError.hasError) {
    return (
      <PoCodeErrorModal
        estimateId={estimateId}
        noEstimatePoCode={poCodeError.noEstimatePoCode}
        noCampaignPoCode={poCodeError.noCampaignPoCode}
        {...props}
      />
    );
  }

  if (estimateSubmit === LOADING) {
    return EstimateSubmittingModal(props);
  }

  if (hasLineItemErrors) {
    return MissingRequiredLineItemsErrorModal(props);
  }

  if (unsubmittedApprovalCount && !estimateSubmit) {
    return SubmitForApprovalModal(props);
  }

  if (estimateSubmit === ERROR) {
    return EstimateErrorModal(props);
  }

  if (estimateSubmit === SUCCESS) {
    return EstimateSubmittedModal(props);
  }

  return AddApproversWarningModal(props);
};

const mapDispatchToProps = (dispatch, { estimateId, onRequestClose, estimate, pageId }) => ({
  submitEstimate: async ({ message }) => {
    await dispatch(submitEstimate(estimateId, message, pageId));
  },
  close: async () => {
    await dispatch(resetSubmitEstimate());
    onRequestClose();
  },
});

const mapStateToProps = (state, { estimateId }) => {
  return {
    unsubmittedApprovalCount: getUnsubmittedEstimateApprovalsFor(estimateId)(state).length,
    estimateSubmit: state.resourceStatus.estimateSubmit,
    missingFields: getEstimateFieldsError(estimateId)(state),
    hasLineItemErrors: hasLineItemsErrors(estimateId)(state),
    deliverablesError: getEstimateDeliverablesError(estimateId)(state),
    poCodeError: getPoCodeError(estimateId)(state),
    estimate: getEstimateById(estimateId)(state),
    canEdit: canEdit(estimateId)(state),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withHandlers({
    editEstimate:
      ({ estimateId, estimate, missingFields, onRequestClose, history }) =>
      () => {
        track(Events.ESTIMATE_SUBMIT_MISSING_REQUIRED_FIELDS_EDIT_CLICK, {
          ...getEstimateData(estimate),
          missingFields,
        });
        onRequestClose();
        history.push(`/estimates/${estimateId}/edit`);
      },
    editLineItems:
      ({ estimateId, estimate, missingFields, onRequestClose, history }) =>
      () => {
        track(Events.ESTIMATE_SUBMIT_MISSING_REQUIRED_FIELDS_EDIT_CLICK, {
          ...getEstimateData(estimate),
          missingFields,
        });
        onRequestClose();
        history.push(`/estimates/${estimateId}/bids`);
      },
    addApprovers:
      ({ estimateId, history, onRequestClose, estimate }) =>
      () => {
        history.push(`/estimates/${estimateId}/approvals`);
        track(Events.ESTIMATE_SUBMIT_MISSING_APPROVERS_ADD_APPROVERS_CLICK, {
          ...getEstimateData(estimate),
        });
        onRequestClose();
      },
    addDeliverables:
      ({ estimateId, history, onRequestClose, estimate }) =>
      () => {
        history.push(`/estimates/${estimateId}/deliverables/edit`);
        track(Events.ESTIMATE_SUBMIT_MISSING_DELIVERABLES_ADD_DELIVERABLES_CLICK, {
          ...getEstimateData(estimate),
        });
        onRequestClose();
      },
    addPoCode:
      ({ estimateId, history, onRequestClose }) =>
      () => {
        history.push(`/estimates/${estimateId}/edit`);
        onRequestClose();
      },
    cancel:
      ({ close, estimate }) =>
      () => {
        track(Events.ESTIMATE_SUBMIT_CANCEL, { ...getEstimateData(estimate) });
        close();
      },
    onSubmit:
      ({ estimate, submitEstimate, pageId }) =>
      ({ message }) => {
        submitEstimate({ message });
        track(Events.ESTIMATE_SUBMIT_SUCCESS, {
          ...getEstimateData(estimate),
          message: Boolean(message),
          messageLength: message ? message.length : 0,
        });
      },
  }),
  reduxForm({ form: 'submitForApproval' }),
  lifecycle({
    componentDidMount() {
      if (this.props.isOpen) {
        const { unsubmittedApprovalCount, estimate, missingFields, deliverablesError } = this.props;

        if (deliverablesError) {
          return track(Events.ESTIMATE_SUBMIT_MISSING_DELIVERABLES, {
            ...getEstimateData(estimate),
            missingFields,
          });
        }

        if (missingFields.length > 0) {
          return track(Events.ESTIMATE_SUBMIT_MISSING_REQUIRED_FIELDS, {
            ...getEstimateData(estimate),
            missingFields,
          });
        }
        if (!unsubmittedApprovalCount) {
          return track(Events.ESTIMATE_SUBMIT_MISSING_APPROVERS, { ...getEstimateData(estimate) });
        }
      }
    },
  })
)(SubmitForApproval);
