import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle } from '@material-ui/core';
import { AutoSizer, List as VirtualList } from 'react-virtualized';
import { formatUserName } from 'cr-core/userUtils';
import UserAvatar from 'components/userAvatar';
import { SearchField } from 'components/filters/searchTextInput';

const listRowHeight = 52;

const useStyles = makeStyles(theme => ({
  list: {
    minHeight: '300px',
    // overflowY: 'scroll',

    overflow: 'hidden',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  searchContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  paperScrollPaper: {
    // height: 'calc(100% - 64px)',
    overflow: 'hidden',
  },
  noResult: {
    padding: theme.spacing(1, 2),
  },
  // username: {
  //   whiteSpace: 'nowrap',
  //   overflow: 'hidden',
  //   textOverflow: 'ellipsis',
  // },
}));

const rowRenderer = (list, handleListItemClick, classes) => ({
  index,
  isScrolling,
  style,
  key,
}) => {
  const user = list[index];
  return (
    <div
      role="button"
      key={key}
      tabIndex={index}
      style={style}
      onClick={() => handleListItemClick(user)}
      onKeyPress={() => handleListItemClick(user)}
      className={classes.listItem}
    >
      <UserAvatar user={user} showName={false} data-test="user-avatar" />
      <div className={classes.username}>{formatUserName(user, false)}</div>
    </div>
  );
};

const UsersList = ({ width, height, list, onRowClick }) => {
  const classes = useStyles();
  const noRowsRenderer = () => (
    <div className={classes.noResult}>No user found. Please change filter.</div>
  );
  return (
    <VirtualList
      width={width}
      height={height}
      overscanRowCount={5}
      noRowsRenderer={noRowsRenderer}
      rowCount={list.length}
      rowHeight={listRowHeight}
      rowRenderer={rowRenderer(list, onRowClick, classes)}
    />
  );
};

// const noRowsRenderer = () => {
//   const classes = useStyles();
//   return <div className={classes.noResult}>No user. Please change filter.</div>;
// };

const ListSelectorModal = ({
  list,
  onClose,
  open,
  title,
  filters,
  onSearch,
  isLoading,
  className,
}) => {
  const classes = useStyles();
  const handleClose = () => onClose();
  const handleListItemClick = value => onClose(value);
  const [search, setSearch] = useState('');

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="add-team-dialog"
      open={open}
      classes={{ paperScrollPaper: clsx(classes.paperScrollPaper, className) }}
    >
      <DialogTitle>{title}</DialogTitle>
      <div className={classes.searchContainer}>
        <SearchField
          value={search}
          onChange={val => {
            setSearch(val);
            onSearch(val);
          }}
          isLoading={isLoading}
        />
      </div>
      <div className={classes.list}>
        <AutoSizer>
          {({ width, height }) => (
            <UsersList width={width} height={height} list={list} onRowClick={handleListItemClick} />
          )}
        </AutoSizer>
      </div>
    </Dialog>
  );
};

export default ListSelectorModal;
