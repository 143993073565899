import { showErrorNotification, showSuccessNotification } from 'state/notifications/actions';
import { closeSidePanel } from 'state/ui/actions';

export default async (dispatch, action, successMessage, closePanel) => {
  try {
    await dispatch(action);
    dispatch(showSuccessNotification({ message: successMessage }));
    if (closePanel) {
      dispatch(closeSidePanel());
    }
    return true;
  } catch (e) {
    dispatch(showErrorNotification(e));
    return false;
  }
};
