import distinctColors from 'distinct-colors';
import { formatAsCurrency } from 'cr-core/currencyUtils';

export const getColorPalette = count =>
  distinctColors({
    count,
    hueMin: 0,
    hueMax: 360,
    chromaMin: 30,
    chromaMax: 80,
    lightMin: 35,
    lightMax: 80,
  });

export const formatBigNumberCurrency = (value, displayCurrency) => {
  let newValue;
  let simbol = '';
  let decimals = 0;
  if (value >= 1000000) {
    newValue = value / 1000000;
    decimals = value % 1000000 ? 1 : 0;
    simbol = 'M';
  } else if (value >= 1000) {
    newValue = value / 1000;
    decimals = value % 1000 ? 1 : 0;
    simbol = 'K';
  }

  return formatAsCurrency(displayCurrency, newValue, decimals) + simbol;
};

export const tooltipsCallbacksLabel = displayCurrency => (tooltipItem, data) => {
  const label = data.datasets[tooltipItem.datasetIndex].label;
  const value = formatAsCurrency(displayCurrency, tooltipItem.yLabel);
  return `${label ? label + ': ' : ''}${value}`;
};
