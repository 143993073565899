import React from 'react';

const SvgExpandIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="expand-icon_svg__a"
        width="161.5%"
        height="136.4%"
        x="-30.8%"
        y="-18.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      d="M6.5 4.51L2.515 8.366a.368.368 0 0 1-.509 0L.107 6.543a.344.344 0 0 1 0-.499L6.244.103a.368.368 0 0 1 .51 0l6.125 5.94a.344.344 0 0 1 0 .498l-1.886 1.825a.368.368 0 0 1-.509 0L6.5 4.51zm3.985 9.124a.368.368 0 0 1 .509 0l1.899 1.824a.344.344 0 0 1 0 .499l-6.138 5.94a.368.368 0 0 1-.51 0L.12 15.957a.344.344 0 0 1 0-.498l1.886-1.825a.368.368 0 0 1 .509 0L6.5 17.49l3.985-3.856z"
      filter="url(#expand-icon_svg__a)"
      transform="translate(9 4)"
    />
  </svg>
);

export default SvgExpandIcon;
