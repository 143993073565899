import React from 'react';

const SvgCollapseIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="collapse-icon_svg__a"
        width="157.1%"
        height="136.4%"
        x="-28.6%"
        y="-18.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      d="M2.71 21.885a.387.387 0 0 1-.55.001L.115 19.84a.393.393 0 0 1-.001-.554l6.61-6.66a.387.387 0 0 1 .551 0l6.597 6.66c.152.153.151.4 0 .553l-2.031 2.046a.387.387 0 0 1-.55 0L7 17.562l-4.29 4.323zM7 4.438L11.29.115a.387.387 0 0 1 .55-.001l2.045 2.046c.153.152.153.4.001.554l-6.61 6.66a.387.387 0 0 1-.551 0L.114 2.714a.393.393 0 0 1 0-.554L2.16.114a.387.387 0 0 1 .55 0L7 4.439z"
      filter="url(#collapse-icon_svg__a)"
      transform="translate(8 4)"
    />
  </svg>
);

export default SvgCollapseIcon;
