import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.grey[400],
  },
  link: {
    color: theme.palette.grey[400],

    '&:hover': {
      color: theme.palette.grey[600],
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const year = new Date().getFullYear();
  return (
    <div className={classes.container}>
      &copy; {year} MurphyCobb & Associates Ltd -{' '}
      <NavLink to="/terms-and-conditions" className={classes.link}>
        Terms & Conditions
      </NavLink>
    </div>
  );
};

export default Footer;
