import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core';
import {
  BudgetCenterFilter,
  BudgetSourceFilter,
  CampaignStatusFilter,
  CategoryBrandFilter,
  DateRangeFilter,
  EstimateStatusFilter,
  LeadMarketFilter,
  MediaTypesFilter,
  PayingCountryFilter,
  ProductionComplexityFilter,
  WorkspaceFilter,
  BudgetYearFilter,
  DivisionFilter,
  CampaignClientIOFilter,
  CampaignAgencyJobFilter,
  CampaignIDFilter,
  ReportingDateControl,
  ProductionStartDateControl,
  DeliveryDateControl,
  ScaleOrNonScaleFilter,
} from 'components/filtersSidebar/filters';
import { getProductBrand } from 'components/filtersSidebar/utils';
import config from 'config';
import { format } from 'date-fns';
import { Form, useFormikContext } from 'formik';
import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { isResourceLoading } from 'state/resourceStatus/selectors';
import { getUserWorkspaces } from 'state/workspaces/selectors';
import { download, stripEmpty } from 'utils';
import queryString from 'query-string';
import { getDisplayCurrency } from 'state/authentication/selectors';
import { compact, isEmpty } from 'lodash/fp';
import { getUser } from 'state/authentication/selectors';
import InfoBox from 'components/infoBox';
// @ts-ignore
import { AccountSetting } from 'cr-core/constants';
import useDivisionsWorkspaces from 'hooks/useDivisionsWorkspaces';
import { getCampaigns } from 'state/campaigns/selectors';

interface Props {
  isBrandsLoading: boolean;
  isWorkspacesLoading: boolean;
  workspaces: any[];
  brands: any[];
  displayCurrency: string;
  user: any;
  campaigns: any[];
}

const { apiEndpoint } = config;

export const EMPTY_FILTERS = {
  workspaceId: '',
  budgetYear: [],
  productCategory: '',
  productBrand: '',
  estimateStatus: '',
  payingCountries: '',
  budgetSource: '',
  campaignStatus: '',
  leadMarket: '',
  division: '',
  productionComplexity: '',
  approvalDate: {},
  budgetCenter: '',
  mediaType: '',
  estimateCreationDate: {},
  agencyJobNumber: [],
  clientIoNumber: [],
  campaignHumanId: [],
  reportingDate: '',
  productionStartDate: '',
  deliveryDate: '',
  scaleOrNonScale: [],
};

const useStyles = makeStyles({
  exportButton: {
    marginLeft: 10,
  },

  filterContainer: {
    width: '100%',

    '@media screen and (min-width: 900px)': {
      width: '25%',
    },

    '@media screen and (min-width: 1250px)': {
      width: '20%',
    },
  },

  info: {
    marginBottom: 20,
  },
});

const hasEmptyValues = (obj: object) => {
  for (const value of Object.values(obj)) {
    if (!isEmpty(value)) {
      return false;
    }
  }

  return true;
};

const ReportFiltersForm = ({
  isBrandsLoading,
  isWorkspacesLoading,
  workspaces = [],
  brands = [],
  displayCurrency,
  user,
  campaigns = [],
}: Props) => {
  const { values, setValues } = useFormikContext();
  const styles = useStyles();
  const [divisions, setDivisions] = useState<string[]>([]);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState<any[]>([]);
  const [clearSelectedWorkspaces, setClearSelectedWorkspaces] = useState<boolean>(false);

  const filterProps = { displayStackedOptions: true };
  const clientSettings = user?.client?.accountSettings;
  const budgetCenterEnabled = clientSettings?.[AccountSetting.BudgetCenter];
  const budgetYearEnabled = clientSettings?.[AccountSetting.BudgetYear];
  const extendedCampaignDetailsEnabled = clientSettings[AccountSetting.ExtendedCampaignDetails];

  const { data: queryWorkspaces } = useDivisionsWorkspaces(user.client.id, divisions, workspaces);

  const updateDivisions = (divisions: string[], clearWorkspaces: boolean) => {
    setDivisions(divisions);
    setClearSelectedWorkspaces(clearWorkspaces);
  };

  const updateSelectedWorkspaces = (value: any[]) => {
    setSelectedWorkspaces(value);
    clearSelectedWorkspaces && setClearSelectedWorkspaces(false);
  };

  const [agencyJobNumbers] = useMemo(
    () =>
      (campaigns || []).reduce(
        (acc, cur) => {
          if (!!cur.agencyJobNumber && !acc[0].includes(cur.agencyJobNumber)) {
            acc[0].push(cur?.agencyJobNumber);
          }
          return acc;
        },
        [[]]
      ),
    [campaigns]
  );

  const getCampaignFilters = () => [
    { filter: CampaignClientIOFilter, props: { ...filterProps } },
    { filter: CampaignAgencyJobFilter, props: { ...filterProps, agencyJobNumbers } },
    { filter: CampaignIDFilter, props: { ...filterProps } },
  ];

  const [brandCategories, setBrandCategories] = useState(null);
  const [clearFilters, setClearFilters] = useState(false);

  useEffect(() => {
    if (!isEmpty(brands) || clearFilters) {
      const groups = [...(new Set(brands.map(brand => brand.brandCategory)) as any)];
      let brandCategories: any = {};
      groups.forEach(item => {
        brandCategories[item] = [];
      });

      setBrandCategories(brandCategories);
    }
  }, [brands, clearFilters]);

  const filters = compact([
    ...(extendedCampaignDetailsEnabled ? getCampaignFilters() : []),
    budgetYearEnabled && { filter: BudgetYearFilter, props: filterProps },
    {
      filter: DivisionFilter,
      props: { ...filterProps, updateDivisions, selectedWorkspaces: !!selectedWorkspaces?.length },
    },
    {
      filter: WorkspaceFilter,
      props: {
        ...filterProps,
        workspaces: queryWorkspaces,
        updateSelectedWorkspaces,
        clearSelectedOptions: clearSelectedWorkspaces,
      },
    },
    budgetCenterEnabled && { filter: BudgetCenterFilter, props: filterProps },
    {
      filter: CategoryBrandFilter,
      props: { ...filterProps, brands, brandCategories, setBrandCategories },
    },
    { filter: MediaTypesFilter, props: filterProps },
    { filter: EstimateStatusFilter, props: filterProps },
    { filter: CampaignStatusFilter, props: filterProps },
    { filter: DateRangeFilter, props: { label: 'Approval Date', name: 'approvalDate' } },
    { filter: PayingCountryFilter, props: filterProps },
    { filter: ScaleOrNonScaleFilter, props: filterProps },
    { filter: BudgetSourceFilter, props: filterProps },
    { filter: LeadMarketFilter, props: filterProps },
    { filter: ProductionComplexityFilter, props: filterProps },
    { filter: ProductionStartDateControl, props: filterProps },
    { filter: DeliveryDateControl, props: filterProps },
    { filter: ReportingDateControl, props: filterProps },
  ]);

  const exportData = () => {
    // TODO: fix types
    const {
      approvalDate,
      estimateCreationDate,
      productionStartDate,
      deliveryDate,
      reportingDate,
      ...rest
    } = values as any;

    const date =
      approvalDate.start && approvalDate.end
        ? {
            approvalDateStart: format(new Date(approvalDate.start), 'yyyy-MM-dd'),
            approvalDateEnd: format(new Date(approvalDate.end), 'yyyy-MM-dd'),
          }
        : undefined;
    const reportingDateField =
      reportingDate.start && reportingDate.end
        ? {
            reportingDateStart: format(new Date(reportingDate.start), 'yyyy-MM-dd'),
            reportingDateEnd: format(new Date(reportingDate.end), 'yyyy-MM-dd'),
          }
        : undefined;

    const productionStartDateField =
      productionStartDate.start && productionStartDate.end
        ? {
            productionStartDateStart: format(new Date(productionStartDate.start), 'yyyy-MM-dd'),
            productionStartDateEnd: format(new Date(productionStartDate.end), 'yyyy-MM-dd'),
          }
        : undefined;

    const deliveryDateField =
      deliveryDate.start && deliveryDate.end
        ? {
            deliveryDateStart: format(new Date(deliveryDate.start), 'yyyy-MM-dd'),
            deliveryDateEnd: format(new Date(deliveryDate.end), 'yyyy-MM-dd'),
          }
        : undefined;

    const estimateCreationObj =
      estimateCreationDate.start && estimateCreationDate.end
        ? {
            estimateCreationDateStart: format(new Date(estimateCreationDate.start), 'yyyy-MM-dd'),
            estimateCreationDateEnd: format(new Date(estimateCreationDate.end), 'yyyy-MM-dd'),
          }
        : undefined;

    const url = queryString.stringifyUrl({
      url: `${apiEndpoint}/export/top-line`,
      query: stripEmpty({
        ...date,
        ...productionStartDateField,
        ...deliveryDateField,
        ...reportingDateField,
        ...estimateCreationObj,
        ...rest,
        displayCurrency,
        clientId: user.client.id,
        brandCategories: JSON.stringify(brandCategories),
      }),
    });

    download(url);
  };

  if (isWorkspacesLoading || isBrandsLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Form>
        <InfoBox className={styles.info}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              In addition to the filters below, this custom report will also export the following
              data:
              <Box mt={1}>
                Campaign name and ID #, Estimate ID #, Campaign Budget, Media Type, Originals and
                Edits, Deliverables #, PO #, Estimate creator, Estimated Spend, Export Currency, and
                Link to Estimate.
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flex="0 0 auto"
              justifyContent="flex-end"
              width={250}
            >
              {!hasEmptyValues(values as object) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setValues(EMPTY_FILTERS as any, false);
                    setClearFilters(true);
                  }}
                >
                  Clear all
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={exportData}
                className={styles.exportButton}
              >
                Export
              </Button>
            </Box>
          </Box>
        </InfoBox>
        <Box display="flex" flexWrap="wrap" mx="-10px">
          {filters.map(({ filter: Filter, props }, index) => (
            <Box key={index} mb={3} px="10px" className={styles.filterContainer}>
              {/* @ts-ignore */}
              <Filter {...props} />
            </Box>
          ))}
        </Box>
      </Form>
    </Box>
  );
};

export const mapStateToProps = (state: any) => ({
  brands: getProductBrand(state),
  isBrandsLoading: isResourceLoading('products')(state),
  workspaces: getUserWorkspaces(state),
  isWorkspacesLoading: isResourceLoading('workspaces')(state),
  displayCurrency: getDisplayCurrency(state),
  user: getUser(state),
  campaigns: getCampaigns(state),
});

export default connect(mapStateToProps)(ReportFiltersForm);
