var _toConsumableArray = require("/home/circleci/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

var _require = require('./generic'),
    MediaTypes = _require.MediaTypes,
    DemoAdditionalTypes = _require.DemoAdditionalTypes;

var EditTypes = {
  REVISIONS: 'REVISIONS',
  ADAPTATIONS: 'ADAPTATIONS',
  CUTDOWNS: 'CUTDOWNS'
};
var EditTypeLables = {
  REVISIONS: 'Revisions',
  ADAPTATIONS: 'Adaptations',
  CUTDOWNS: 'Cutdown Versions'
};
var Deliverables = {
  ANIMATED_VIDEO: 'ANIMATED_VIDEO',
  APP: 'APP',
  AR_VR_CONTENT: 'AR_VR_CONTENT',
  ARTICLES_ADVERTORIAL: 'ARTICLES_ADVERTORIAL',
  BANNER: 'BANNER',
  BTS_STILL: 'BTS_STILL',
  BTS_VIDEO: 'BTS_VIDEO',
  COUNTRY_VERSION: 'COUNTRY_VERSION',
  DIGITAL_VIDEO: 'DIGITAL_VIDEO',
  DOOH: 'DOOH',
  ECOMM_ASSET: 'ECOMM_ASSET',
  ECRM: 'ECRM',
  EDITORIAL_CONTENT: 'EDITORIAL_CONTENT',
  FULL_SHOOT: 'FULL_SHOOT',
  GIF: 'GIF',
  INDUSTRIAL_VIDEO: 'INDUSTRIAL_VIDEO',
  INFLUENCER_CONTENT: 'INFLUENCER_CONTENT',
  KV: 'KV',
  MOBILE_APP: 'MOBILE_APP',
  PACKAGING: 'PACKAGING',
  PODCAST: 'PODCAST',
  PR_CONTENT: 'PR_CONTENT',
  PRODUCT_DEMO: 'PRODUCT_DEMO',
  RADIO: 'RADIO',
  RICH_BANNER: 'RICH_BANNER',
  SOCIAL_ADS_FORMAT: 'SOCIAL_ADS_FORMAT',
  SOCIAL_FILM: 'SOCIAL_FILM',
  SOCIAL_STILL: 'SOCIAL_STILL',
  POINT_OF_SALE: 'POINT_OF_SALE',
  OUT_OF_HOME: 'OUT_OF_HOME',
  SONIC_BRANDING: 'SONIC_BRANDING',
  STANDARD_BANNER: 'STANDARD_BANNER',
  SWEEPSTAKES_AND_CONTEST: 'SWEEPSTAKES_AND_CONTEST',
  TAG: 'TAG',
  THUMBNAILS: 'THUMBNAILS',
  TOOLKIT: 'TOOLKIT',
  UGC: 'UGC',
  VOICE_ACTIVATION: 'VOICE_ACTIVATION',
  WEBSITE_ADAPTATION: 'WEBSITE_ADAPTATION',
  WEBSITE_NEW: 'WEBSITE_NEW'
};
var AdditionalDeliverableLabels = {
  ANIMATED_VIDEO: 'Animated Video',
  APP: 'App',
  AR_VR_CONTENT: 'AR/VR Content',
  ARTICLES_ADVERTORIAL: 'Article / Advertorial',
  BANNER: 'Banner',
  BTS_STILL: 'Behind the scenes - Still',
  BTS_VIDEO: 'Behind the scenes - Video',
  COUNTRY_VERSION: 'Country Version',
  DIGITAL_VIDEO: 'Digital Video',
  DOOH: 'DOOH',
  ECOMM_ASSET: 'E-commerce Asset',
  ECRM: 'Email/e-CRM',
  EDITORIAL_CONTENT: 'Editorial Content',
  FULL_SHOOT: 'Full Shoot',
  GIF: 'Gif',
  INDUSTRIAL_VIDEO: 'Industrial Video',
  INFLUENCER_CONTENT: 'Influencer Video / Content',
  KV: 'Key Visual',
  MOBILE_APP: 'Mobile App',
  PACKAGING: 'Packaging',
  PODCAST: 'Podcast',
  PR_CONTENT: 'PR content / pick up shots',
  PRODUCT_DEMO: 'Product Demo',
  RADIO: 'Radio',
  RICH_BANNER: 'Rich Banner / gif',
  SOCIAL_ADS_FORMAT: 'Social Ads Format',
  SOCIAL_FILM: 'Instagram / Facebook / Twitter / Snapchat - Video',
  SOCIAL_STILL: 'Instagram / Facebook / Twitter / Snapchat - Still',
  POINT_OF_SALE: 'Point of Sale',
  OUT_OF_HOME: 'Out of Home',
  SONIC_BRANDING: 'Sonic Branding',
  STANDARD_BANNER: 'Standard Banner',
  SWEEPSTAKES_AND_CONTEST: 'Sweepstakes & Contest',
  TAG: 'Tag',
  THUMBNAILS: 'Thumbnail',
  TOOLKIT: 'Toolkit',
  UGC: 'User Generated Content',
  VOICE_ACTIVATION: 'Voice Activation (Siri & Alexa)',
  WEBSITE_ADAPTATION: 'Website Refresh/Adaptation',
  WEBSITE_NEW: 'Website New/Relaunch'
};
var AdditionalDeliverablesGroups = [{
  name: 'Video',
  deliverables: [Deliverables.ANIMATED_VIDEO, Deliverables.AR_VR_CONTENT, Deliverables.BTS_VIDEO, Deliverables.COUNTRY_VERSION, Deliverables.DIGITAL_VIDEO, Deliverables.INDUSTRIAL_VIDEO, Deliverables.INFLUENCER_CONTENT, Deliverables.PRODUCT_DEMO, Deliverables.SOCIAL_ADS_FORMAT, Deliverables.SOCIAL_FILM, Deliverables.TAG, Deliverables.UGC]
}, {
  name: 'Still',
  deliverables: [Deliverables.BTS_STILL, Deliverables.ECOMM_ASSET, Deliverables.FULL_SHOOT, Deliverables.KV, Deliverables.PACKAGING, Deliverables.POINT_OF_SALE, Deliverables.OUT_OF_HOME, Deliverables.SOCIAL_STILL, Deliverables.THUMBNAILS, Deliverables.TOOLKIT]
}, {
  name: 'Digital',
  deliverables: [Deliverables.APP, Deliverables.DOOH, Deliverables.ECRM, Deliverables.MOBILE_APP, Deliverables.RICH_BANNER, Deliverables.STANDARD_BANNER, Deliverables.WEBSITE_ADAPTATION, Deliverables.WEBSITE_NEW]
}, {
  name: 'Audio',
  deliverables: [Deliverables.PODCAST, Deliverables.RADIO, Deliverables.SONIC_BRANDING, Deliverables.VOICE_ACTIVATION]
}, {
  name: 'PR',
  deliverables: [Deliverables.ARTICLES_ADVERTORIAL, Deliverables.EDITORIAL_CONTENT, Deliverables.PR_CONTENT, Deliverables.SWEEPSTAKES_AND_CONTEST]
}];
var MediaTypesWithDeliverables = [MediaTypes.TVC, MediaTypes.DIGITAL_VIDEO, MediaTypes.AUDIO, MediaTypes.DIGITAL, MediaTypes.PRINT, MediaTypes.MOBILE_AND_WEB_APPS, MediaTypes.ANIMATICS, // legacy
MediaTypes.AV, MediaTypes.SHOPPER, MediaTypes.CRM, MediaTypes.CINEMA, MediaTypes.EXPERIENTIAL, MediaTypes.SPECIAL_PROJECT, MediaTypes.TRADE, MediaTypes.INTEGRATED].concat(_toConsumableArray(DemoAdditionalTypes.map(function (_ref) {
  var value = _ref.value;
  return value;
})));
module.exports = {
  EditTypes: EditTypes,
  EditTypeLables: EditTypeLables,
  Deliverables: Deliverables,
  AdditionalDeliverableLabels: AdditionalDeliverableLabels,
  AdditionalDeliverablesGroups: AdditionalDeliverablesGroups,
  MediaTypesWithDeliverables: MediaTypesWithDeliverables
};