import React, { useMemo, useState, useEffect } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import { pick, uniqWith, isEqual } from 'lodash/fp';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  CircularProgress,
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TextField as TextInput } from '@material-ui/core';
import { Formik, Field, useField, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import NumberFormat from 'react-number-format';
import { useQuery } from 'react-query';
import { AccountSetting } from 'cr-core/constants';

import { updatedCurrencies } from 'cr-core/currencies';
import FormikComboBox from 'components/filters/formik.comboBox';

import { getClientSettings, getDisplayCurrency } from 'state/authentication/selectors';
import { createCampaign, updateCampaign } from 'state/campaigns/actions';
import { getCampaignById } from 'state/campaigns/selectors';
import { getUserWorkspaces } from 'state/workspaces/selectors';
import { parseQueryFilters } from 'components/filtersSidebar';
import WorkspaceService from 'services/WorkspaceService';
import { getProducts } from 'state/products/selectors';
import FormikAutocomplete from 'components/filters/formik.autocomplete';

const useStyles = makeStyles(theme => ({
  content: {
    minWidth: 520,
  },
  field: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  budgetContainer: {
    display: 'flex',
  },
  currencyField: {
    width: 112,
    marginLeft: theme.spacing(2),
    flexShrink: 0,
  },
  budgetField: {
    flexGrow: 1,
  },
  fieldDescription: {
    marginBottom: theme.spacing(2),
    marginTop: -theme.spacing(1.5),
    marginLeft: theme.spacing(0.5),
    color: theme.palette.text.hint,
  },

  actionContainer: {
    display: 'flex',
    padding: theme.spacing(1, 0),
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  actionButton: {
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

const NumberFormatCustom = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={2}
    />
  );
};

const CategoryBrandSelect = connect(state => {
  return {
    products: getProducts(state),
  };
})(({ products, campaign }) => {
  const classes = useStyles();
  const filteredProducts = products.filter(x => x.deletedAt === null);
  const estimateProducts =
    campaign.estimates && campaign.estimates.filter(estimate => estimate.products.length > 0);

  const [field] = useField('categories');
  const { setFieldValue, values } = useFormikContext();

  const [brandValue, setBrandValue] = useState(
    values.brands || { productBrand: '', productBrandId: '', productCategoryId: '' }
  );

  const options = uniqWith(
    isEqual,
    filteredProducts.map(x => ({
      productCategory: x.productCategory.name,
      productCategoryId: x.productCategoryId,
    }))
  ).sort((a, b) => -b.productCategory.localeCompare(a.productCategory));
  const brandFilteredProducts = useMemo(
    () =>
      field.value && typeof field.value !== 'undefined'
        ? products.filter(
            x => x.deletedAt === null && field.value.productCategory === x.productCategory.name
          )
        : [],
    [field.value, products]
  );

  const brandOptions = useMemo(
    () =>
      brandFilteredProducts
        .map(x => ({
          productBrand: x.productBrand.name,
          productBrandId: x.productBrandId,
          productCategory: x.productCategory.name,
          productCategoryId: x.productCategoryId,
        }))
        .filter(
          (product, index, self) =>
            index ===
            self.findIndex(
              p =>
                p.productBrandId === product.productBrandId &&
                p.productBrand === product.productBrand
            )
        ),
    [brandFilteredProducts]
  );

  useEffect(() => {
    setBrandValue(
      value =>
        brandOptions.find(brandOption => {
          return brandOption.productBrand === value.productBrand;
        }) || { productBrand: '', productBrandId: '', productCategoryId: '' }
    );
  }, [brandOptions]);

  useEffect(() => {
    setFieldValue('brands', brandValue);
  }, [brandValue, setFieldValue]);

  useEffect(() => {
    if (!field.value) {
      setBrandValue({ productBrand: '', productBrandId: '', productCategoryId: '' });
    }

    if (field.value && field.value.productCategoryId !== brandValue.productCategoryId) {
      setBrandValue({ productBrand: '', productBrandId: '', productCategoryId: '' });
    }
  }, [field.value, brandValue.productCategoryId]);

  return (
    <>
      <Field name="categories">
        {({ field, meta, form }) => (
          <FormikAutocomplete
            field={field}
            form={form}
            options={options}
            className={classes.field}
            optionLabelAttr="productCategory"
            autocompleteProps={{
              multiple: false,
              freeSolo: false,
              disabled: estimateProducts?.length,
              getOptionLabel: x => x.productCategory,
              getOptionSelected: (option, value) =>
                option.productCategoryId === value.productCategoryId,
              renderInput: params => {
                return (
                  <TextInput
                    {...params}
                    variant="outlined"
                    disabled={estimateProducts?.length}
                    fullWidth
                    error={meta.touched && !!meta.error}
                    helperText={meta.touched && meta.error}
                    label="Segment"
                    className={classes.formControl}
                  />
                );
              },
            }}
          />
        )}
      </Field>

      <Field name="brands">
        {({ field, meta, form }) => (
          <FormikAutocomplete
            value={brandValue}
            field={field}
            form={form}
            options={brandOptions}
            className={classes.field}
            optionLabelAttr="productBrand"
            onChange={(event, value) => {
              setFieldValue('brands', value);
              setBrandValue(value || '');
            }}
            autocompleteProps={{
              multiple: false,
              freeSolo: false,
              getOptionLabel: x => x.productBrand,
              getOptionSelected: (option, value) => option.productBrandId === value.productBrandId,
              renderInput: params => {
                return (
                  <TextInput
                    {...params}
                    variant="outlined"
                    fullWidth
                    error={meta.touched && !!meta.error}
                    helperText={meta.touched && meta.error}
                    label="Brand"
                    className={classes.formControl}
                  />
                );
              },
            }}
          />
        )}
      </Field>
    </>
  );
});

const CampaignModal = ({
  open,
  handleClose,
  campaign = {},
  saveCampaign,
  workspaces,
  displayCurrency,
  campaignId,
  clientSettings,
}) => {
  const extendedCampaignDetailsEnabled = clientSettings[AccountSetting.ExtendedCampaignDetails];

  //older campaigns doesn't have categories so we set it nullable so they can be updated
  const categoryPopulated = !!campaign.productCategories?.length;
  const isUpdate = !!campaign.id;

  const CampaignSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required('Required'),
        workspaceId: Yup.string().required('Required'),
        budgetValue: Yup.number()
          .typeError('Budget amount must be a number')
          .positive('Budget amount must be a number greater than zero')
          .required('Required'),
        budgetCurrency: Yup.string().required('Required'),
        poCode: Yup.string().nullable(),
        ...(extendedCampaignDetailsEnabled
          ? {
              clientIoNumber: Yup.string().nullable(),
              agencyJobNumber: Yup.string().nullable(),
              categories:
                isUpdate && !categoryPopulated
                  ? Yup.object().nullable()
                  : Yup.object().required('Required'),
            }
          : undefined),
      }),
    [extendedCampaignDetailsEnabled, categoryPopulated, isUpdate]
  );

  const classes = useStyles();
  const initialValues = {
    budgetCurrency: '',
    name: '',
    workspaceId: '',
    budgetValue: '',
    ...(extendedCampaignDetailsEnabled && { categories: '' }),
    ...pick(
      [
        'name',
        'workspaceId',
        'budgetValue',
        'budgetCurrency',
        'poCode',
        ...(extendedCampaignDetailsEnabled ? ['clientIoNumber', 'agencyJobNumber'] : []),
      ],
      campaign
    ),
    categories:
      extendedCampaignDetailsEnabled &&
      campaign.productCategories?.map(productCategory => ({
        productCategoryId: productCategory.id,
        productCategory: productCategory.name,
      }))?.[0],

    brands:
      extendedCampaignDetailsEnabled &&
      campaign.productBrands?.map(productBrand => ({
        productBrandId: productBrand.id,
        productBrand: productBrand.name,
        productCategoryId: productBrand.campaignCategoryBrand.productCategoryId,
      }))?.[0],
  };

  const isEditing = typeof campaign.id !== 'undefined';
  const accountId = campaign && campaign.workspace && campaign.workspace.accountId;

  const { data = [], isLoading, isError, isLoadingError, refetch } = useQuery(
    ['account', accountId, 'workspaces'],
    async () => {
      if (typeof accountId === 'undefined') {
        return Promise.reject();
      }

      const { data } = await WorkspaceService.getAccountWorkspaces(accountId);

      return data;
    },
    {
      enabled: isEditing && typeof accountId !== 'undefined',
    }
  );

  const options = (isEditing ? data : workspaces || []).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const cancel = () => handleClose(false);
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const success = await saveCampaign(
      !extendedCampaignDetailsEnabled
        ? values
        : {
            ...values,
            categories: values.categories?.productCategoryId,
          },
      parseQueryFilters(window.location.search)
    );
    setSubmitting(false);
    if (success) {
      resetForm(initialValues);
    }
    handleClose(true);
  };

  return (
    <Dialog
      open={open}
      onClose={() => cancel()}
      aria-labelledby="campaign-edit-metadata"
      id="campaign-modal"
    >
      <DialogTitle>{campaign.id ? 'Edit' : 'Create'} Campaign</DialogTitle>
      <DialogContent className={classes.content}>
        {isLoading && (
          <Box display="flex" alignItems="center" justifyContent="center" minHeight={200}>
            <CircularProgress />
          </Box>
        )}
        {!isLoading && (
          <>
            {(isError || isLoadingError) && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                minHeight={200}
              >
                <Box mb={1}>
                  <Typography>Oops, something went wrong.</Typography>
                </Box>
                <Button color="primary" variant="contained" onClick={refetch}>
                  Try again
                </Button>
              </Box>
            )}
            {!isError && !isLoadingError && (
              <Formik
                initialValues={initialValues}
                validationSchema={CampaignSchema}
                validateOnChange
                onSubmit={onSubmit}
              >
                {({
                  handleSubmit,
                  isSubmitting,
                  submitForm,
                  errors,
                  dirty,
                  touched,
                  setTouched,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Field
                      name="workspaceId"
                      label="Workspace"
                      variant="outlined"
                      id="workspace-select"
                      options={options}
                      className={classes.field}
                      classes={{ label: classes.label, root: classes.field }}
                      disableClearable={true}
                      component={FormikComboBox}
                    />
                    <Field
                      label="Campaign Name"
                      name="name"
                      variant="outlined"
                      className={classes.field}
                      component={TextField}
                    />

                    {clientSettings[AccountSetting.ExtendedCampaignDetails] && (
                      <CategoryBrandSelect campaign={campaign} />
                    )}
                    <div className={classes.budgetContainer}>
                      <Field
                        label="Budget Amount"
                        name="budgetValue"
                        variant="outlined"
                        fullWidth={false}
                        className={clsx(classes.field, classes.budgetField)}
                        component={TextField}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                      <Field
                        variant="outlined"
                        label="Currency"
                        name="budgetCurrency"
                        id="budget-currency-select"
                        disableClearable={true}
                        options={updatedCurrencies}
                        className={clsx(classes.field, classes.currencyField)}
                        component={FormikComboBox}
                      />
                    </div>
                    {clientSettings[AccountSetting.ExtendedCampaignDetails] && (
                      <Box display="flex" alignItems="center">
                        <Field
                          label="# Agency Job (optional)"
                          name="agencyJobNumber"
                          variant="outlined"
                          className={classes.field}
                          component={TextField}
                        />
                        <Box mx={1} />
                        <Field
                          label="Global Campaign ID (optional)"
                          name="clientIoNumber"
                          variant="outlined"
                          className={classes.field}
                          component={TextField}
                        />
                      </Box>
                    )}
                    <Field
                      label="# PO"
                      name="poCode"
                      variant="outlined"
                      className={classes.field}
                      component={TextField}
                    />
                    <div className={classes.actionContainer}>
                      <Button
                        color="primary"
                        data-test="modal-cancel"
                        onClick={() => cancel()}
                        className={classes.actionButton}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        data-test="save-campaign"
                        className={classes.actionButton}
                        disabled={isSubmitting}
                        onClick={async e => {
                          await setTouched({ name: true, budgetValue: true }, true);
                          submitForm(e);
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state, { name, campaignId }) => {
  return {
    workspaces: getUserWorkspaces(state),
    campaign: campaignId ? getCampaignById(campaignId)(state) : {},
    displayCurrency: getDisplayCurrency(state),
    clientSettings: getClientSettings(state),
  };
};

const mapDispatchToProps = (dispatch, { campaignId }) => ({
  saveCampaign: (values, query) =>
    dispatch(
      campaignId ? updateCampaign(campaignId, values, query) : createCampaign(values, query)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignModal);
