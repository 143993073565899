import React from 'react';
import { map } from 'lodash/fp';
import File from './file';
import styled from 'styled-components';

const FileListContainer = styled.div`
  margin-top: 10px;
`;

const FileList = ({ files, estimateId }) => (
  <FileListContainer>
    {map(
      file => (
        <File {...file} estimateId={estimateId} key={file.id} />
      ),
      files
    )}
  </FileListContainer>
);

export default FileList;
