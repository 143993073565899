import React, { useState } from 'react';
import { Checkbox, Box, CircularProgress, makeStyles } from '@material-ui/core';
import { updateEstimate } from 'state/estimates/actions';
import { connect } from 'react-redux';
import { showErrorNotification, showSuccessNotification } from 'state/notifications/actions';

const useStyles = makeStyles({
  checkbox: {
    padding: 0,
  },
  loader: {
    verticalAlign: 'middle',
  },
});

const Covid19Language = ({ initialValue = false, acceptRisks, canEdit = false }) => {
  const [state, setState] = useState({ value: initialValue, loading: false });
  const styles = useStyles();

  const handleChange = async e => {
    setState({ ...state, loading: true });

    const checked = e.target.checked;

    await acceptRisks(checked);

    setState({ value: checked, loading: false });
  };

  return (
    <>
      <Box p="10px">
        1) <strong>Limitations of Production Wrap Insurance Policy:</strong> While Agency will take
        out a Production Wrap insurance policy for this production to cover risk/ liability for
        certain losses (such as accidents on set or equipment breakdown) and will take reasonable
        steps to mitigate losses, due to the pandemic, the Production Wrap insurance policy will not
        cover any losses related to COVID-19 (or other communicable diseases), including, among
        other things, cancellation fees, talent becoming unavailable or unable to travel, or changes
        required due to governmental restrictions. I have confirmed that Colgate Marketing is
        willing to accept the risk that potential COVID-19-related losses that previously
        (pre-pandemic) were covered by Agency’s insurance may no longer be recoverable, in which
        case such losses will be passed through to the Region/subsidiary.
      </Box>
      {(canEdit || state.value) && (
        <Box p="10px">
          {canEdit && (
            <Box display="inline-block" mr={1}>
              {!state.loading && (
                <Checkbox
                  className={styles.checkbox}
                  size={20}
                  checked={state.value}
                  onChange={handleChange}
                />
              )}
              {state.loading && <CircularProgress className={styles.loader} size={21} />}
            </Box>
          )}
          2){' '}
          <strong>
            Potential Production Quality Issues Due to Remote Shoot Management by Agency:
          </strong>{' '}
          I have confirmed that Colgate Marketing is willing to accept the risks associated with
          Agency not being on-site at the shoot, namely the potential negative impact on some of the
          soft factors involved with production if the remote Agency team loses its connection with
          the on-site production team, such as make-up, hair, lighting, and placement of set props.
          In addition, while Agency contractually requires production companies and other
          third-party vendors to comply with applicable COVID-19 guidelines, Agency cannot confirm
          that such guidelines are being met on the set; Agency must rely on the Production
          company's representation that it is following all relevant guidelines. I confirm that
          Agency should move forward with the production, understanding that Agency will take
          reasonable efforts, to the extent possible, to ensure the highest possible production
          quality given timing requirements and take all reasonable steps to correct any such issues
          post-production.
        </Box>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch, { estimateId, pageId }) => ({
  acceptRisks: async (value = false) => {
    try {
      await dispatch(updateEstimate(estimateId, { acceptCovid19RelatedIssues: value }, pageId));

      dispatch(showSuccessNotification({ message: 'Estimate updated successfully' }));
    } catch {
      dispatch(showErrorNotification({ message: 'Failed to update estimate' }));
    }
  },
});

export default connect(null, mapDispatchToProps)(Covid19Language);
