import ApiService from './ApiService';
import qs from 'qs';

interface FilterData {
  displayCurrency: string;
  reportingYear: number;
  excludeCancelledEstimates: boolean;
}

const CampaignService = {
  getOverallBudget: (params: FilterData) =>
    ApiService.get(`/campaigns/budget?${qs.stringify(params, { indices: false })}`),
};

export default CampaignService;
