var AccountSetting = {
  Covid19Language: 'covid19Language',
  BudgetCenter: 'budgetCenter',
  TopLineReport: 'topLineReport',
  CostCategoriesExport: 'costCategoriesExport',
  CRMEstimateType: 'crmEstimateType',
  BudgetYear: 'budgetYear',
  ReportingYear: 'reportingYear',
  EstimateCreationDate: 'estimateCreationDate',
  AgencyApprovers: 'agencyApprovers',
  OverallBudget: 'overallBudget',
  DemoAdditionalTypes: 'demoAdditionalTypes',
  DeliverablesOriginalTypes: 'deliverablesOriginalTypes',
  ExtendedCampaignDetails: 'extendedCampaignDetails',
  EstimateBundle: 'estimateBundle',
  Suppliers: 'suppliers',
  ManageModerators: 'manageModerators',
  MediaAdvertisingType: 'mediaAdvertisingType',
  DetailedExportReports: 'detailedExportReports',
  LineItemChecks: 'lineItemChecks',
  MergeHistoricalLineItems: 'mergeHistoricalLineItems',
  DeliverablesReport: 'deliverablesReport'
};
module.exports = AccountSetting;