var _require = require('lodash/fp'),
    flow = _require.flow,
    words = _require.words,
    map = _require.map,
    join = _require.join,
    lowerCase = _require.lowerCase,
    upperFirst = _require.upperFirst,
    trim = _require.trim,
    replace = _require.replace;

var convertCamelToPretty = flow(words, map(upperFirst), join(' '));
var convertUpperCaseToPretty = flow(words, map(lowerCase), map(upperFirst), join(' '));

var cleanString = function cleanString(str) {
  return flow(trim, replace(/([^a-zA-Z0-9&#()/\-']+)/gi, ' '), // remove symbols
  replace(/\s\s+/g, ' '), // remove multiple spaces
  replace(/(\s*)\/(\s*)/g, '/'), replace(/(\s*)-(\s*)/g, '-'), replace(/\(\s/g, '('), replace(/\s\)/g, ')'))(str);
};

module.exports = {
  cleanString: cleanString,
  convertCamelToPretty: convertCamelToPretty,
  convertUpperCaseToPretty: convertUpperCaseToPretty
};