import { useInfiniteQuery } from 'react-query';
import SupplierService from 'services/SupplierService';

const useSuppliers = (accountId: string) => {
  return useInfiniteQuery(
    ['account', accountId, 'suppliers'],
    ({ pageParam = 1 }) =>
      SupplierService.getSuppliers({ accountId, page: pageParam }).then(({ data }) => data),
    { getNextPageParam: lastPage => lastPage.nextPage ?? undefined }
  );
};

export default useSuppliers;
