import { fetch } from 'state/fetchMiddleware';
import { getDisplayCurrency } from 'state/authentication/selectors';

export const ESTIMATE_HISTORY_FETCH_REQUEST = 'estimateHistory/ESTIMATE_HISTORY_FETCH_REQUEST';
export const ESTIMATE_HISTORY_FETCH_SUCCESS = 'estimateHistory/ESTIMATE_HISTORY_FETCH_SUCCESS';

export const fetchEstimateHistory = estimateId => async (dispatch, getState) => {
  const displayCurrency = getDisplayCurrency(getState());
  dispatch({ type: ESTIMATE_HISTORY_FETCH_REQUEST, estimateId });
  const { data: estimateHistory } = await dispatch(
    fetch(`/estimates/${estimateId}/history`, { params: { displayCurrency } })
  );
  dispatch({ type: ESTIMATE_HISTORY_FETCH_SUCCESS, estimateId, estimateHistory });
};
