import React from 'react';

const SvgAnimatics = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 31" {...props}>
    <g fill="currentColor">
      <polygon
        id="Path"
        points="9.16666667 5.3976 12.7777778 7.41982222 10.7554989 3.80871111 12.7777778 0.1976 9.16666667 2.21982222 5.55555556 0.1976 7.57777778 3.80871111 5.55555556 7.41982222"
      />
      <polygon
        id="Path"
        points="26.3888889 18.8864889 22.7777778 16.8642667 24.8 20.4753778 22.7777778 24.0864889 26.3888889 22.0642667 30 24.0864889 27.9777778 20.4753778 30 16.8642667"
      />
      <polygon
        id="Path"
        points="30 0.1976 26.3888889 2.21982222 22.7777778 0.1976 24.8 3.80871111 22.7777778 7.41982222 26.3888889 5.3976 30 7.41982222 27.9777778 3.80871111"
      />
      <path
        d="M19.076398,7.8469066 C18.5069549,7.27746076 17.6527903,7.27746076 17.0833473,7.8469066 L0.427082287,24.5031974 C-0.142360762,25.0726433 -0.142360762,25.926812 0.427082287,26.4962579 L3.70137982,29.7705156 C4.27082287,30.3399615 5.12498744,30.3399615 5.69443049,29.7705156 L22.3506955,13.1142806 C22.9201385,12.5448348 22.9201385,11.690666 22.3506955,11.1212202 L19.076398,7.8469066 Z M17.5104296,15.6767869 L14.5208536,12.6871962 L17.9375119,9.2705212 L20.9270879,12.2601119 L17.5104296,15.6767869 Z"
        id="Shape"
      />
    </g>
  </svg>
);

export default SvgAnimatics;
