import ApiService from './ApiService';
import queryString from 'query-string';

interface SignatureParams {
  contentType: string;
  objectName: string;
  path: string;
}

interface SuppliersQuery {
  accountId: string;
  q?: string;
  page: number;
}

const EstimateService = {
  getSuppliers: (query: SuppliersQuery) =>
    ApiService.get<{ data: any[]; nextPage: number | null }>(
      queryString.stringifyUrl({
        url: `/estimates/suppliers`,
        query: query as unknown as queryString.StringifiableRecord,
      })
    ),

  createSupplier: (data: { accountId: string; name: string }) =>
    ApiService.post<{ id: string; name: string }>(`/estimates/suppliers`, data),

  getUploadSignature: (estimateId: string, params: SignatureParams) =>
    ApiService.get(
      queryString.stringifyUrl({
        url: `/estimates/${estimateId}/uploadSignature`,
        query: { ...params },
      })
    ),

  getIds: () => ApiService.get<number[]>('/estimates/ids'),
};

export default EstimateService;
