import React, { useState } from 'react';
import { find, map, pick } from 'lodash/fp';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import ConfirmationModal from 'components/modals/confirmationModal';
import { deleteProduct, deleteCategoryAndBrand } from 'state/products/actions';
import { getAccountProducts } from 'state/products/selectors';
import CreateProductForm from './CreateProductForm';
import AddProduct from './createProductForm';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },

  tableRow: {
    height: 75,
  },

  tableContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  header: { fontWeight: 600 },

  deleted: {
    color: theme.palette.error.main,
  },

  deleteButton: {
    '&:hover': {
      color: theme.palette.error.main,
    },
  },

  modalProduct: {
    margin: theme.spacing(2, 1),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const ProductList = ({ products, deleteProduct, deleteCategoryAndBrand, onEdit }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} aria-label="products table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.header}>Segment</TableCell>
            <TableCell className={classes.header}>Brand</TableCell>
            <TableCell className={classes.header}>Name</TableCell>
            <TableCell className={classes.header}>Status</TableCell>
            <TableCell className={classes.header}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(
            product => (
              <TableRow key={product.id} className={classes.tableRow}>
                <TableCell scope="row">{product.brandCategory}</TableCell>
                <TableCell scope="row">{product.brandName}</TableCell>
                <TableCell scope="row">{product.productName}</TableCell>
                <TableCell scope="row">
                  {product.deletedAt ? <span className={classes.deleted}>Deleted</span> : 'Active'}
                </TableCell>
                <TableCell scope="row">
                  {!product.deletedAt ? (
                    <>
                      <IconButton aria-label="edit" onClick={() => onEdit(product.id)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        data-test="delete-product"
                        onClick={() => deleteProduct(product.id)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton
                        style={{ marginLeft: '42px' }}
                        aria-label="delete"
                        data-test="delete-product"
                        onClick={() => deleteCategoryAndBrand(product.id)}
                      >
                        <DeleteForeverIcon fontSize="small" />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ),
            products
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ProductsSettings = ({ products, accountId, deleteProduct, deleteCategoryAndBrand }) => {
  const classes = useStyles();

  const [modalState, setModalState] = useState({
    delete: false,
    hardDelete: false,
    edit: false,
    data: {},
  });

  const handleDelete = productId => {
    setModalState(prev => ({
      ...prev,
      delete: true,
      data: { productId, product: find({ id: productId }, products) },
    }));
  };

  const handleHardDelete = productId => {
    setModalState(prev => ({
      ...prev,
      hardDelete: true,
      data: { productId, product: find({ id: productId }, products) },
    }));
  };

  const handleCloseModal = check => {
    if (check) {
      deleteProduct(modalState.data.productId);
    }
    setModalState(prev => ({ ...prev, delete: false, data: {} }));
  };

  const handlehardDeleteCloseModal = check => {
    if (check) {
      deleteCategoryAndBrand(
        pick(['accountId', 'productBrandId', 'productCategoryId'], modalState.data.product)
      );
    }
    setModalState(prev => ({ ...prev, hardDelete: false, data: {} }));
  };

  const handleEdit = productId => {
    setModalState(prev => ({
      ...prev,
      edit: true,
      data: { productId, product: find({ id: productId }, products) },
    }));
  };

  const closeEditModal = () => {
    setModalState(prev => ({ ...prev, edit: false, data: {} }));
  };

  if (!accountId) {
    return <div>Select an account</div>;
  }

  return (
    <div>
      <AddProduct accountId={accountId} />
      <ProductList
        products={products}
        deleteProduct={handleDelete}
        deleteCategoryAndBrand={handleHardDelete}
        onEdit={handleEdit}
      />
      <Dialog open={modalState.edit} onClose={closeEditModal}>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent>
          <Box minWidth={650}>
            {typeof modalState.data.product !== 'undefined' && (
              <CreateProductForm
                accountId={accountId}
                onCancel={closeEditModal}
                product={modalState.data.product}
                onSuccess={closeEditModal}
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <ConfirmationModal
        open={modalState.delete}
        handleClose={handleCloseModal}
        actionLabel="Delete"
        title="Confirm delete product"
        message={
          <div>
            Do you want to delete the following product?
            {modalState.data.product && (
              <div className={classes.modalProduct}>
                {modalState.data.product.brandCategory} - {modalState.data.product.brandName}{' '}
                {modalState.data.product.productName}
              </div>
            )}
            Please be aware that if you delete it, you will not be able to add it in new estimates
            being created from now on.
          </div>
        }
      />
      <ConfirmationModal
        open={modalState.hardDelete}
        handleClose={handlehardDeleteCloseModal}
        actionLabel="Delete"
        title="Confirm delete Segment and Brand"
        message={
          <div>
            Do you want to delete the following Segment and Brand?
            {modalState.data.product && (
              <div className={classes.modalProduct}>
                {modalState.data.product.brandCategory} - {modalState.data.product.brandName}
              </div>
            )}
            Please be aware that if you delete it, you will not be able to add it in new estimates
            or campaigns being created from now on.
          </div>
        }
      />
    </div>
  );
};

const mapStateTopProps = (state, { accountId }) => ({
  products: getAccountProducts(accountId, { estimateCount: true })(state),
});

const mapDispatchToProps = dispatch => ({
  deleteProduct: id => dispatch(deleteProduct(id)),
  deleteCategoryAndBrand: product => dispatch(deleteCategoryAndBrand(product)),
});

export default connect(mapStateTopProps, mapDispatchToProps)(ProductsSettings);
