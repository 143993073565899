export const COLUMNS = {
  // estimate
  estimateStatus:
    'State of an estimate (draft, pending approval, approved, actualised or cancelled).',
  estimateName: 'Number of Estimates drilling down to the Name of each estimate.',
  estimateLeadMarket: 'The Country which we are mainly targeting and will be on air first.',
  estimateRegion: 'The Region for which a estimate is made',
  estimateProductCategory: 'The product segment of an estimate.',
  estimateOriginals:
    'The number of originals of an estimate. As an original counts anything produced from scratch.',
  estimateEdits:
    'The number of edits of an estimate. As edits count adaptations, revisions, cut versions or tags.',
  estimateLatestEstimate:
    'The sum of the estimated spend, based on the latest version of your estimates. Note: Estimated Spend refers to draft, pending approval, approved and actualised estimates. Please use the “estimate status” filter on the left and select approved & actualised, and customise further your report!',
  estimateActualised:
    'Actual spend for an estimate, based on the sum of the invoiced amounts provided by estimate.',
  estimateAverage:
    'The average spend for an estimate, based on the actualised spend - or the estimated spend for the cases  where an estimate has not been actualised yet.',
  // campaign
  campaignPayingDivision: 'The Region where a budget comes from.',
  campaignPayingCountry: 'The Country where a budget comes from.',
  campaignProductCategory: 'The product segment of the campaign.',
  campaignName: 'Name of a campaign.',
  campaignStatus: 'State of a campaign (open, closed, actualised).',
  campaignBudget: 'Budget of a campaign.',
  campaignLatestEstimate:
    'The sum of the estimated spend, based on the latest version of your estimates.',
  campaignVariance: 'The difference between Budget and Estimated Spend.',
  campaignActualised:
    'The sum of the Actualised Spend for a campaign. This value is shown only if one or more estimates in a campaign have been Actualised so we get the sum of the actual invoiced amounts.',
  campaignActualSavings:
    'The difference between Budget and Actualised Spend. Your Actual Savings by campaign for all actualised campaigns.',

  estimateStatusNote:
    'Note: Estimated Spend refers to draft, pending approval, approved and actualised estimates. Please use the “estimate status” filter on the left and select approved & actualised, and customise further your report!',

  notSet:
    'Not Set: The campaigns include estimates with the paying country or the brand (and therefore the segment) not set yet.',
  multipleCategories:
    'Multiple Segments: The campaigns include one or more estimates with brands from more than one product Segments.',
  multipleDivisions:
    'Multiple Regions & Multiple Countries: The campaigns include one or more estimates paid by more than one Paying Regions or Countries, respectively.',
};
