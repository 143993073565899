import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { flow, map, uniq, reject, forEach, filter, sumBy, keyBy } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { getFlags, getClientSettings } from 'state/authentication/selectors';
import { TableHeader } from '../components';
import DataTable from '../dataTable';
import getColumns, { getDetailedDownloadColumns, getDownloadColumns } from './columns';
import EstimateCard from 'components/estimateCard';
import { COLUMNS as legendUtils } from '../utils.legend';
import { concatenatePropItems } from '../utils';
import { csvDownload } from 'utils';
import { format as formatDate } from 'date-fns';
const { getMediaAdvertisingType } = require('cr-core/estimateUtils');
const { MediaTypeLabels, AccountSetting } = require('cr-core/constants');

const reportName = 'Campaigns by Segment';
const description = (
  <div>
    Campaigns by Segment report shows you Estimated and Actualised Spend per Campaign and Budget.
    The Report also offers clarity on what has been actualised and what has not been actualised yet.
    Know where you’ve saved and where you haven't means you can identify opportunities to reallocate
    savings and be proactive. Use the filters on the left to further customise this report.
    <br />
    {legendUtils.estimateStatusNote}
  </div>
);
const afterLegend = (
  <div>
    <div>{legendUtils.notSet}</div>
    <div>{legendUtils.multipleCategories}</div>
    <div>{legendUtils.multipleDivisions}</div>
  </div>
);

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
  estimateCard: {
    marginBottom: 0,
    borderRadius: 0,
  },
}));

const Table = ({
  estimates,
  campaigns,
  displayCurrency,
  flags,
  indexPropGet,
  title,
  clientSettings,
}) => {
  const classes = useStyles();

  const campaignById = keyBy('id', campaigns);
  const inlcudedCampaignIds = flow(map('campaignId'), uniq)(estimates);
  const campaignsNotIncluded = flow(reject(({ id }) => inlcudedCampaignIds.includes(id)))(
    campaigns
  );

  const campaignsNotIncludedData = map(campaign => {
    campaign.category = 'Not Set';
    return {
      approvals: [],
      approvedAt: null,
      brand: null,
      campaignId: campaign.id,
      campaign: campaign,
      createdAt: campaign.createdAt,
      updatedAt: campaign.updatedAt,
      createdBy: campaign.createdBy,
      creator: campaign.creator,
      customData: {},
      date: null,
      deletedAt: null,
      externalMetadata: { contentTypes: [] },
      externalUrl: null,
      files: [],
      humanId: null,
      id: null,
      leadMarket: null,
      lockedExchangeRatesAt: null,
      mediaType: null,
      name: null,
      // name: '(Empty Campaign)',
      notes: null,
      numberOfRATVs: null,
      numberOfTVCs: null,
      products: [],
      quoteCurrency: 'usd',
      recommendedBid: null,
      recommendedBidTotal: 0,
      status: null,
      subBrand: null,
      version: 1,
      watchers: [],
    };
  }, campaignsNotIncluded);

  const data = [...estimates, ...campaignsNotIncludedData];
  const exportData = [...estimates, ...campaignsNotIncludedData]
    .filter(estimate => {
      const campaign = campaignById[estimate.campaignId];
      if (!campaign) {
        return false;
      }
      return true;
    })
    .map(estimate => {
      const campaign = campaignById[estimate.campaignId];
      return {
        ...estimate,
        mediaAdvertisingType: getMediaAdvertisingType(MediaTypeLabels[estimate.mediaType]),
        campaign: {
          ...estimate.campaign,
          category: concatenatePropItems('brandCategory')(campaign.estimates),
          brand: concatenatePropItems('brandName')(campaign.estimates),
        },
      };
    });

  forEach(estimate => {
    const campaign = campaignById[estimate.campaignId];

    if (!campaign) {
      return;
    }

    estimate.campaign.category = indexPropGet('brandCategory', 'Segments')(campaign.estimates);

    campaign.actualised =
      flow(filter({ campaignId: campaign.id }), sumBy('actualisedTotal'))(estimates) || 0;
  }, data);

  const columns = useMemo(
    () => getColumns(data, displayCurrency, clientSettings),
    [data, displayCurrency, clientSettings]
  );
  const [expandAll, setExpandAll] = useState(false);
  const toggleExpandAll = () => setExpandAll(!expandAll);

  const download = () => {
    csvDownload(
      exportData,
      clientSettings[AccountSetting.DetailedExportReports]
        ? getDetailedDownloadColumns({ clientSettings })
        : getDownloadColumns({ clientSettings }),
      `Report_${reportName}_${formatDate(new Date(), 'yyyy-MM-dd')}`
    );
  };

  return (
    <>
      <TableHeader
        flags={flags}
        expandAll={expandAll}
        toggleExpandAll={toggleExpandAll}
        download={download}
        title={title}
      />
      <DataTable
        data={data}
        columns={columns}
        reportEventName={reportName}
        expandAll={expandAll}
        reportDescription={description}
        afterLegend={afterLegend}
        initialState={{
          groupBy: ['productCategory', 'campaign'],
          sortBy: ['productCategory', 'campaign', 'estimates'],
        }}
        SubComponent={({ row }) => (
          <EstimateCard estimate={row.original} target="_blank" className={classes.estimateCard} />
        )}
      />
    </>
  );
};

const mapStateToProps = state => ({
  flags: getFlags(state),
  clientSettings: getClientSettings(state),
});

export default connect(mapStateToProps)(Table);
