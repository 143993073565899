var africa = ['Algeria', 'Angola', 'Benin', 'Botswana', 'Burkina Faso', 'Burundi', 'Cameroon', 'Cape Verde', 'Central African Republic', 'Chad', 'Comoros', 'Democratic Republic of Congo', "Côte D'Ivoire", 'Djibouti', 'Egypt', 'Equatorial Guinea', 'Eritrea', 'Ethiopia', 'Gabon', 'Gambia', 'Ghana', 'Guinea', 'Guinea-Bissau', 'Kenya', 'Lesotho', 'Liberia', 'Libya', 'Madagascar', 'Malawi', 'Mali', 'Mauritania', 'Mauritius', 'Mayotte', 'Morocco', 'Mozambique', 'Namibia', 'Niger', 'Nigeria', 'Rwanda', 'Saint Helena', 'Sao Tome and Principe', 'Senegal', 'Seychelles', 'Sierra Leone', 'Somalia', 'South Africa', 'South Sudan', 'Sudan', 'Swaziland', 'Tanzania', 'Togo', 'Tunisia', 'Uganda', 'Western Sahara', 'Zambia', 'Zimbabwe'];
var caribbean = ['Anguilla', 'Antigua and Barbuda', 'Aruba', 'Bahamas', 'Barbados', 'Cayman Islands', 'Cuba', 'Dominica', 'Dominican Republic', 'French Guiana', 'Grenada', 'Guadeloupe', 'Haiti', 'Jamaica', 'Martinique', 'Montserrat', 'Puerto Rico', 'Saint Barthelemy', 'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Martin', 'Saint Vincent and the Grenadines', 'Sint Maarten', 'Trinidad and Tobago', 'Turks and Caicos Islands', 'Virgin Islands, British', 'Virgin Islands, U.S.'];
var centralAmerica = ['Belize', 'Costa Rica', 'El Salvador', 'Guatemala', 'Honduras', 'Mexico', 'Nicaragua', 'Panama'];
var northAmerica = ['Bermuda', 'Canada', 'United States'];
var southAmerica = ['Argentina', 'Bolivia', 'Brazil', 'Chile', 'Colombia', 'Ecuador', 'Falkland Islands (Malvinas)', 'Guyana', 'Paraguay', 'Peru', 'Suriname', 'Uruguay', 'Venezuela'];
var asiaPacific = ['Afghanistan', 'American Samoa', 'Antarctica', 'Australia', 'Azerbaijan', 'Bangladesh', 'Bhutan', 'Brunei Darussalam', 'Cambodia', 'China', 'Christmas Island', 'Cocos (Keeling) Islands', 'Cook Islands', 'Fiji', 'French Polynesia', 'Guam', 'Heard Island and McDonald Islands', 'Hong Kong', 'India', 'Indonesia', 'Japan', 'Kazakhstan', 'Kiribati', 'Kyrgyzstan', "Lao People's Democratic Republic", 'Macau', 'Malaysia', 'Maldives', 'Marshall Islands', 'Micronesia, Federated States of', 'Mongolia', 'Myanmar', 'Nauru', 'Nepal', 'New Caledonia', 'New Zealand', 'Niue', 'Norfolk Island', 'Northern Mariana Islands', 'Pakistan', 'Palau', 'Papua New Guinea', 'Philippines', 'Pitcairn Islands', 'Reunion', 'Samoa', 'Singapore', 'Solomon Islands', 'South Korea', 'Sri Lanka', 'Taiwan', 'Tajikistan', 'Thailand', 'Timor-Leste', 'Tokelau', 'Tonga', 'Turkmenistan', 'Tuvalu', 'Uzbekistan', 'Vanuatu', 'Vietnam', 'Wallis and Futuna'];
var europe = ['Albania', 'Andorra', 'Armenia', 'Austria', 'Belarus', 'Belgium', 'Bosnia and Herzegovina', 'Bulgaria', 'Croatia', 'Cyprus', 'Czech Republic', 'Denmark', 'Estonia', 'Faroe Islands', 'Finland', 'France', 'Georgia', 'Germany', 'Gibraltar', 'Greece', 'Greenland', 'Guernsey', 'Hungary', 'Iceland', 'Ireland', 'Isle of Man', 'Israel', 'Italy', 'Jersey', 'Latvia', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Macedonia', 'Malta', 'Moldova', 'Monaco', 'Montenegro', 'Netherlands', 'Norway', 'Poland', 'Portugal', 'Romania', 'Russian Federation', 'San Marino', 'Serbia', 'Slovakia', 'Slovenia', 'Spain', 'Sweden', 'Switzerland', 'Turkey', 'Ukraine', 'United Kingdom'];
var middleEast = ['Bahrain', 'Iran', 'Iraq', 'Jordan', 'Kuwait', 'Lebanon', 'Oman', 'Palestinian Territory', 'Qatar', 'Saudi Arabia', 'Syria', 'United Arab Emirates', 'Yemen'];
var AFRICA = 'AFRICA';
var CARIBBEAN = 'CARIBBEAN';
var ASIA_PACIFIC = 'ASIA_PACIFIC';
var EUROPE = 'EUROPE';
var CENTRAL_AMERICA = 'CENTRAL_AMERICA';
var NORTH_AMERICA = 'NORTH_AMERICA';
var SOUTH_AMERICA = 'SOUTH_AMERICA';
var MIDDLE_EAST = 'MIDDLE_EAST';
var GLOBAL = 'GLOBAL';
var regions = [{
  name: 'Africa',
  code: AFRICA,
  countries: africa
}, {
  name: 'Caribbean',
  code: CARIBBEAN,
  countries: caribbean
}, {
  name: 'Asia Pacific',
  code: ASIA_PACIFIC,
  countries: asiaPacific
}, {
  name: 'Europe',
  code: EUROPE,
  countries: europe
}, {
  name: 'Central America',
  code: CENTRAL_AMERICA,
  countries: centralAmerica
}, {
  name: 'North America',
  code: NORTH_AMERICA,
  countries: northAmerica
}, {
  name: 'South America',
  code: SOUTH_AMERICA,
  countries: southAmerica
}, {
  name: 'Middle East',
  code: MIDDLE_EAST,
  countries: middleEast
}];
var worldCountries = [].concat(africa, caribbean, asiaPacific, europe, centralAmerica, northAmerica, southAmerica, middleEast);
module.exports = {
  regions: regions,
  worldCountries: worldCountries,
  africa: africa,
  caribbean: caribbean,
  asiaPacific: asiaPacific,
  europe: europe,
  centralAmerica: centralAmerica,
  northAmerica: northAmerica,
  southAmerica: southAmerica,
  middleEast: middleEast,
  AFRICA: AFRICA,
  CARIBBEAN: CARIBBEAN,
  ASIA_PACIFIC: ASIA_PACIFIC,
  EUROPE: EUROPE,
  CENTRAL_AMERICA: CENTRAL_AMERICA,
  NORTH_AMERICA: NORTH_AMERICA,
  SOUTH_AMERICA: SOUTH_AMERICA,
  MIDDLE_EAST: MIDDLE_EAST,
  GLOBAL: GLOBAL
};