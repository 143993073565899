import {
  ADD_COST_LINE_ITEM_SUCCESS,
  REMOVE_EMPTY_LINE_ITEM_TO_COST_CATEGORY,
  RESET_LAST_LINE_ITEM_ADDED,
} from 'state/lineItems/actions';
import { ESTIMATES_FETCH_SUCCESS } from 'state/estimates/actions';
import { LINE_ITEM_EDIT_SUCCESS } from 'state/bids/actions';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_COST_LINE_ITEM_SUCCESS:
      return {
        ...state,
        lastLineItemAdded: action.lineItemId,
        lastLineItemAddedCategory: action.costCategoryId,
      };
    case REMOVE_EMPTY_LINE_ITEM_TO_COST_CATEGORY:
    case ESTIMATES_FETCH_SUCCESS:
    case RESET_LAST_LINE_ITEM_ADDED:
    case LINE_ITEM_EDIT_SUCCESS:
      return {
        ...state,
        lastLineItemAdded: null,
        lastLineItemAddedCategory: null,
      };

    default:
      return state;
  }
};
