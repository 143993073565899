import React from 'react';

const SvgHistoryIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="history-icon_svg__a"
        width="128.6%"
        height="133.3%"
        x="-14.3%"
        y="-16.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      d="M15.947 0C22.603 0 28 5.373 28 12s-5.397 12-12.053 12c-3.24 0-6.265-1.278-8.517-3.52a.367.367 0 0 1 0-.52l1.844-1.836a.37.37 0 0 1 .523 0 8.66 8.66 0 0 0 6.15 2.554c4.82 0 8.717-3.88 8.717-8.678 0-4.798-3.897-8.678-8.717-8.678-4.695 0-8.515 3.683-8.709 8.31h3.517c.33 0 .495.397.261.629l-5.244 5.208a.37.37 0 0 1-.592-.096l-.064-.127L.11 12.26a.368.368 0 0 1 .262-.63H3.9C4.096 5.176 9.416 0 15.947 0zm5.382 14.695c.175.104.233.33.129.505l-.935 1.564a.37.37 0 0 1-.506.128l-5.556-3.283a.368.368 0 0 1-.181-.317V6.83a.37.37 0 0 1 .37-.368h1.947a.37.37 0 0 1 .37.368v5.283l4.362 2.582z"
      filter="url(#history-icon_svg__a)"
      transform="translate(1 3)"
    />
  </svg>
);

export default SvgHistoryIcon;
