import React, { useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import { getFormValues } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, IconButton, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { getSidePanelData } from 'state/ui/selectors';
import { closeSidePanel } from 'state/ui/actions';
import { createWorkspace, fetchWorkspaces } from 'state/workspaces/actions';
import { showErrorNotification, showSuccessNotification } from 'state/notifications/actions';

const useStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(4, 3),
    position: 'relative',
  },
  menuButton: {
    position: 'absolute',
    right: 2,
    top: 10,
  },
  nameField: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

const UserDetails = ({ sidePanelData, closeSidePanel, createWorkspace, accountId, filters }) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const handleSubmit = e => {
    e.preventDefault();
    createWorkspace({ name, accountId }, filters);
  };

  return (
    <div className={classes.body}>
      <Typography variant="h4">Create new workspace</Typography>
      <IconButton onClick={closeSidePanel} className={classes.menuButton}>
        <CloseIcon />
      </IconButton>

      <div>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <TextField
            label="Workspace Name"
            variant="outlined"
            type="text"
            fullWidth
            onChange={event => setName(event.target.value)}
            value={name}
            className={classes.nameField}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={!name || name.length < 4}
            className={classes.button}
          >
            Create Workspace
          </Button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  sidePanelData: getSidePanelData(state),
  accountId: get('accountId', getFormValues('workspaceFilters')(state)),
  filters: getFormValues('workspaceFilters')(state) || {},
});

const mapDispatchToProps = (dispatch, state) => ({
  closeSidePanel: () => dispatch(closeSidePanel()),
  createWorkspace: async ({ name, accountId }, filters) => {
    try {
      await dispatch(createWorkspace({ name, accountId }));
      dispatch(showSuccessNotification({ message: `Workspace ${name} created` }));
      dispatch(closeSidePanel());
      dispatch(fetchWorkspaces(filters));

      // TODO: go to new workspace to add teams
    } catch (e) {
      dispatch(showErrorNotification(e));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
