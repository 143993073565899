import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { compose, withHandlers } from 'recompose';
import { map, flow } from 'lodash/fp';
import { Events, track, getEstimateData } from 'components/analytics';
import withEditConfirmationDialog from 'components/estimateEdit/withEditConfirmationDialog';
import { Select } from 'components/forms';
import { font } from 'shared/styles';
import { addCostCategory } from 'state/costCategories/actions';
import { getCostCategoriesAvailableForEstimate } from 'state/costCategories/selectors';

const CostCategorySelect = styled(
  ({ costCategoryOptions, addCostCategoryToEstimateHandler, className }) => (
    <Select
      placeholder="Select Cost Category"
      className={className}
      options={costCategoryOptions}
      onChange={addCostCategoryToEstimateHandler}
      data-test="select-cost-category"
    />
  )
)`
  width: 400px;
  margin: 10px 0;
  position: relative;
  font-weight: ${font.weights.regular};
  input {
    line-height: 28px !important;
    padding: 0px !important;
  }
`;

const mapStateToProps = (state, { estimate }) => ({
  costCategoryOptions: flow(
    getCostCategoriesAvailableForEstimate(estimate),
    map(({ id, name }) => ({ value: id, label: name }))
  )(state),
});

const mapDispatchToProps = (dispatch, { estimate, pageId }) => ({
  addCostCategoryToEstimate: costCategoryId => {
    dispatch(addCostCategory(estimate.id, costCategoryId, 99, pageId));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    addCostCategoryToEstimateHandler: ({ addCostCategoryToEstimate, estimate }) => ({
      value: costCategoryId,
      label: costCategoryName,
    }) => {
      addCostCategoryToEstimate(costCategoryId);
      track(Events.ESTIMATE_ADD_COST_CATEGORY_SUCCESS, {
        ...getEstimateData(estimate),
        costCategoryId,
        costCategoryName,
      });
    },
  }),
  withEditConfirmationDialog('addCostCategoryToEstimateHandler', {
    trackEvent: {
      cancel: Events.ESTIMATE_ADD_COST_CATEGORY_CANCEL,
    },
  })
)(CostCategorySelect);
