import { forEach, isUndefined, without, getOr } from 'lodash/fp';
import { Events, track } from 'components/analytics';
import { getDisplayCurrency } from 'state/authentication/selectors';
import { fetch } from 'state/fetchMiddleware';
import { showErrorNotification } from 'state/notifications/actions';

export const ESTIMATES_FETCH_REQUEST = 'estimates/FETCH_REQUEST';
export const ESTIMATES_FETCH_SUCCESS = 'estimates/FETCH_SUCCESS';
export const ESTIMATES_RESET = 'estimates/ESTIMATES_RESET';

export const ESTIMATES_CAMPAIGN_FETCH_REQUEST = 'estimates/ESTIMATES_CAMPAIGN_FETCH_REQUEST';
export const ESTIMATES_CAMPAIGN_FETCH_SUCCESS = 'estimates/ESTIMATES_CAMPAIGN_FETCH_SUCCESS';

export const defaultLimit = 10;

export const resetEstimates = () => async dispatch => dispatch({ type: ESTIMATES_RESET });

export const getQueryFromFilters = query => {
  const params = {};
  const fields = [
    'search',
    'mediaType',
    'productCategory',
    'productBrand',
    'estimateStatus',
    'budgetYear',
    'reportingYear',
    'budgetSource',
    'assignedToMe',
    'leadMarket',
    'productionComplexity',
    'campaignStatus',
    'workspaceId',
    'excludeCancelledEstimates',
    'payingCountries',
    'budgetCenter',
    'campaignId',
    'campaignHumanId',
    'clientIoNumber',
    'brandCategories',
    'scaleOrNonScale',
  ];

  forEach(field => {
    const value = query[field];
    if (!isUndefined(value)) {
      if (Array.isArray(value)) {
        if (value.length) {
          params[field] = value;
        }
      } else if (value !== '' && value !== false) {
        params[field] = value;
      }
    }
  }, fields);

  if (query.reportingDate) {
    params.reportingDateStart = query.reportingDate.start.toISOString();
    params.reportingDateEnd = query.reportingDate.end.toISOString();
  }

  if (query.productionStartDate) {
    params.productionStartDateStart = query.productionStartDate.start.toISOString();
    params.productionStartDateEnd = query.productionStartDate.end.toISOString();
  }

  if (query.deliveryDate) {
    params.deliveryDateStart = query.deliveryDate.start.toISOString();
    params.deliveryDateEnd = query.deliveryDate.end.toISOString();
  }

  if (getOr([], 'campaignStatus', params).includes('EMPTY')) {
    params.emptyCampaign = true;
    if (params.campaignStatus.length === 1) {
      delete params.campaignStatus;
    } else {
      params.campaignStatus = without(['EMPTY'], params.campaignStatus);
    }
  }

  if (query.brandCategories) {
    params.brandCategories =
      typeof query.brandCategories === 'object'
        ? JSON.stringify(query.brandCategories)
        : query.brandCategories;
  }

  return params;
};

export const fetchEstimates =
  (query = {}, pageId) =>
  async (dispatch, getState) => {
    const displayCurrency = getDisplayCurrency(getState());

    const filters = getQueryFromFilters(query);
    const requestId = pageId;
    dispatch({ type: ESTIMATES_FETCH_REQUEST, query, requestId });
    const t = Date.now();
    try {
      const {
        data: { data: estimates = [], pagination },
      } = await dispatch(
        fetch('/estimates', {
          params: {
            ...filters,
            displayCurrency,
            page: query.page,
            limit: query.limit,
            withLineItems: query.withLineItems,
          },
        })
      );
      track(Events.PERFORMANCE, {
        metric: query.withLineItems ? 'fetch_estimates_full' : 'fetch_estimates',
        t: Date.now() - t,
        items: estimates.length,
      });
      dispatch({
        type: ESTIMATES_FETCH_SUCCESS,
        estimates,
        pagination: query.page ? pagination : {},
        query,
        requestId,
      });
      return estimates;
    } catch (error) {
      console.log(error);
      dispatch(
        showErrorNotification({ message: 'An error occurred while fetching the estimates list' })
      );
    }
  };

export const fetchCampaignEstimates = (campaignId, requestId) => async (dispatch, getState) => {
  const displayCurrency = getDisplayCurrency(getState());
  const query = { campaignId, displayCurrency };
  dispatch({ type: ESTIMATES_CAMPAIGN_FETCH_REQUEST, campaignId, requestId });
  const t = Date.now();
  try {
    const {
      data: { data: estimates = [] },
    } = await dispatch(
      fetch('/estimates', {
        params: {
          ...query,
          limit: 1000,
        },
      })
    );
    track(Events.PERFORMANCE, {
      metric: 'fetch_campaign_estimates',
      t: Date.now() - t,
      items: estimates.length,
    });
    dispatch({
      type: ESTIMATES_CAMPAIGN_FETCH_SUCCESS,
      estimates,
      campaignId,
      requestId,
    });
    return estimates;
  } catch (error) {
    console.log(error);
    dispatch(
      showErrorNotification({ message: 'An error occurred while fetching the campaign estimates' })
    );
  }
};
