import debounce from 'debounce';
import {
  get,
  getOr,
  compact,
  map,
  flow,
  noop,
  find,
  values,
  sumBy,
  sum,
  uniq,
  filter,
  reject,
  uniqBy,
  difference,
  keys,
  isEqual,
} from 'lodash/fp';
import { withHandlers, withState } from 'recompose';
import { converter } from 'cr-core/currencyUtils';
import { defaultFilters, getCleanFilters } from 'components/filtersSidebar';
import { getCosts } from 'state/estimates/selectors';
import Events from './events';

export { Events };

const uuidRegex = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;

const log = process.env.NODE_ENV === 'development' ? console.log : noop;

export const pageLoad = (properties = {}) => {
  const page = window.location.pathname.replace(uuidRegex, ':id');
  log('pageLoad', properties);
  setTimeout(() => window.analytics && window.analytics.page({ page }), 0);
};

export const track = (eventName, properties = {}) => {
  log('tracking', eventName, properties);
  setTimeout(() => window.analytics && window.analytics.track(eventName, properties), 0);
};

export const trackWithDebounce = (eventName, t) => {
  return debounce((properties = {}) => track(eventName, properties), t);
};

export const identify = (userId, properties, options) => {
  log('identify', userId, properties);
  options = {
    ...options,
    integrations: {
      Intercom: {
        user_hash: get('user_hash', properties),
      },
    },
  };
  setTimeout(() => window.analytics && window.analytics.identify(userId, properties, options), 0);
};

export const reset = (userId, properties, options) => {
  setTimeout(() => window.analytics && window.analytics.reset(), 0);
};

export const getUserData = profile => {
  const userType = flow(
    get('accounts'),
    map(({ agency, consultancy }) => (agency ? 'agency' : consultancy ? 'consultancy' : 'client')),
    uniq,
    types => (types.length > 1 ? 'multiple' : types[0]),
  )(profile);

  return {
    userId: get('id', profile),
    accountName:
      get('accounts.length', profile) === 1 ? get('accounts.0.name', profile) : 'Multiple Accounts',
    company:
      get('accounts.length', profile) === 1 ? get('accounts.0.name', profile) : 'Multiple Accounts',
    accountId:
      get('accounts.length', profile) === 1 ? get('accounts.0.id', profile) : 'multiple-accounts',
    currency: get('currency', profile),
    email: get('email', profile),
    fullName: compact([get('firstName', profile), get('lastName', profile)]).join(' '),
    isAdmin: Boolean(get('isAdmin', profile)),
    isConsultant: Boolean(get('isConsultant', profile)),
    userType,
    flags: get('flags', profile),
    locale: get('locale', profile),
    isMCAUser: Boolean(find({ name: 'MCA' }, getOr([], 'accounts', profile))),
    hasMultiAccount: get('accounts', profile) > 1,
    user_hash: get('intercomHash', profile),
    url: window.location.hostname,
  };
};

export const getEstimateData = (estimate, displayCurrency) => ({
  estimateId: estimate.id,
  campaignId: estimate.campaignId,
  campaignName: get('campaign.name', estimate),
  estimateReportingDate: estimate.date,
  estimateLeadMarket: estimate.leadMarket,
  estimateAdditionalMarkets: get('customData.additionalMarkets', estimate),
  estimateAdditionalMarketsCount: get('customData.additionalMarkets.length', estimate),
  estimatePayingCountries: get('customData.payingCountries', estimate),
  estimatePayingCountriesCount: get('customData.payingCountries.length', estimate),
  estimateBudgetSource: get('customData.budgetSource', estimate),
  estimateProductionComplexity: get('customData.productionComplexity', estimate),
  estimateIsSubmitted: Boolean(estimate.lockedExchangeRatesAt),
  estimateType: estimate.mediaType,
  estimateName: estimate.name,
  estimateOriginals: estimate.numberOfTVCs,
  estimateEdits: estimate.numberOfRATVs,
  estimateAdditionalDeliverables: estimate.additionalDeliverables
    ? flow(values, sum)(estimate.additionalDeliverables)
    : 0,
  estimateProductIds: flow(getOr([], 'products'), map('id'))(estimate),
  estimateProductNames: flow(
    getOr([], 'products'),
    map(
      ({ brandCategory, brandName, productName }) => `${brandCategory} ${brandName} ${productName}`,
    ),
  )(estimate),
  estimateProductCount: getOr(0, 'products.length', estimate),
  estimateStatus: estimate.status,
  estimateTotalCost: getCosts(estimate, 'usd', displayCurrency).estimated,
  estimateActualToDateCost: getCosts(estimate, 'usd', displayCurrency).actualised,
  workspaceId: get('campaign.workspaceId', estimate),
  workspaceName: get('campaign.workspace.name', estimate),
  estimateApprovalsCount: getOr(0, 'approvals.length', estimate),
  estimateBidsCount: getOr(0, 'bids.length', estimate),
  estimateDeliverablesCount: getOr(0, 'deliverables.length', estimate),
  estimateOriginalsCount: flow(get('deliverables'), values, sumBy('originals'))(estimate),
  estimateEditsCount: flow(get('deliverables'), values, sumBy('edits'))(estimate),
  estimateLineItemsCount: flow(
    getOr([], 'bidValues'),
    filter({ bidId: get('recommendedBid', estimate), type: 'cost' }),
    lineItems => lineItems.length,
  )(estimate),
  estimateMetaLineItemsCount: flow(
    getOr([], 'bidValues'),
    filter({ bidId: get('recommendedBid', estimate) }),
    reject({ type: 'cost' }),
    lineItems => lineItems.length,
  )(estimate),
  estimateCostCategoriesCount: flow(
    getOr([], 'bidValues'),
    filter({ bidId: get('recommendedBid', estimate), type: 'cost' }),
    map(({ lineItemNameId }) =>
      find(
        ({ lineItemNames }) => find({ id: lineItemNameId }, lineItemNames),
        getOr([], 'lineItemGroups', estimate),
      ),
    ),
    uniqBy('id'),
    categories => categories.length,
  )(estimate),
});

export const getCampaignData = campaign => ({
  campaignId: campaign.id,
  campaignName: campaign.name,
  campaignBudget: campaign.exchangeRates
    ? converter(campaign.exchangeRates)(campaign.budgetValue, campaign.budgetCurrency).to('usd')
    : 0,
  campaignBudgetCurrency: campaign.budgetCurrency,
  workspaceId: campaign.workspaceId,
  workspaceName: get('workspace.name', campaign),
});

export const getFiltersChangedData = (values, prevValues) => {
  const cleanValues = getCleanFilters(values, defaultFilters);
  const cleanPrevValues = getCleanFilters(prevValues, defaultFilters);
  const cleanValuesKeys = keys(cleanValues);
  const prevValuesKeys = keys(cleanPrevValues);

  const removedFilter = difference(prevValuesKeys, cleanValuesKeys);
  const addedFilter = difference(cleanValuesKeys, prevValuesKeys)[0];
  const changedFilter =
    !addedFilter &&
    !removedFilter &&
    find(key => !isEqual(cleanValues[key], cleanPrevValues[key]), cleanValuesKeys);

  return {
    filters: keys(cleanValues),
    removedFilters: removedFilter,
    addedFilter: addedFilter,
    changedFilter: changedFilter,
    changedFilterValue: cleanValues[changedFilter],
  };
};

export const scrollTracker = eventName => {
  const trackScrollDebounce = trackWithDebounce(eventName, 4000);

  return [
    withState('lastScrollTop', 'setLastScrollTop', 0),
    withState('lastScrollDiff', 'setLastScrollDiff', 0),
    withState('totalScroll', 'setTotalScroll', 0),
    withState('maxScroll', 'setMaxScroll', 0),
    withState('scrollCount', 'setScrollCount', 0),
    withHandlers({
      trackScroll: ({
        totalScroll,
        setTotalScroll,
        lastScrollTop,
        setLastScrollTop,
        scrollCount,
        setScrollCount,
        maxScroll,
        setMaxScroll,
        lastScrollDiff,
        setLastScrollDiff,
      }) => ({ scrollHeight, scrollTop }) => {
        const eventData = { maxScroll, scrollCount };
        const diff = scrollTop - lastScrollTop;
        eventData.totalScroll = totalScroll + Math.abs(diff);
        setTotalScroll(eventData.totalScroll);
        setLastScrollTop(scrollTop);

        if (scrollTop > maxScroll) {
          setMaxScroll(scrollTop);
          eventData.maxScroll = scrollTop;
        }

        if (Math.sign(lastScrollDiff) !== Math.sign(diff)) {
          eventData.scrollCount++;
          setScrollCount(eventData.scrollCount);
        }
        setLastScrollDiff(diff);

        trackScrollDebounce(eventData);
      },
    }),
  ];
};

export const trackReportTableClick = tableName => (state, rowInfo, column) => ({
  onClick: (e, handleOriginal) => {
    const columnId = get('id', column);
    const columnValue = get(`row.${columnId}`, rowInfo);
    const level = get('viewIndex', rowInfo);
    const pivotColumn = get('pivoted', column);
    const expander = getOr(false, 'expander', column);

    track(Events.REPORTS_TABLE_CLICK, {
      columnId,
      columnValue,
      level,
      pivotColumn,
      expander,
      tableName,
    });
    if (handleOriginal) {
      handleOriginal();
    }
  },
});

export const trackReportNewTableClick = (tableName, cell) => {
  const columnId = get('column.id', cell);
  const columnValue = get('value', cell);
  const level = get('row.index', cell);
  const expanded = getOr(false, 'row.isExpanded', cell);

  track(Events.REPORTS_TABLE_CLICK, {
    columnId,
    columnValue,
    level,
    expanded,
    tableName,
  });
};

export const addTrackReportNewTableClick = (tableName, tdProps, cell) => {
  const originalOnClick = tdProps.onClick;
  tdProps.onClick = (...props) => {
    trackReportNewTableClick(tableName, cell);
    originalOnClick(...props);
  };
};
