import StorageService from './StorageService';

const ACCESS_TOKEN = 'access_token';

const AuthService = {
  getApiToken: () => {
    return StorageService.getItem<string>(ACCESS_TOKEN, { raw: true });
  },
};

export default AuthService;
