import { EstimateStatuses } from 'cr-core/constants';

export const widgetStyles = theme => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  widget: {
    marginTop: theme.spacing(4),
  },
  widgetTitle: {
    ...theme.typography.body,
    marginBottom: theme.spacing(1),
    fontSize: '1rem',
  },
});

export const getCost = ({ status, recommendedBidTotal, actualisedTotal }) =>
  status === EstimateStatuses.ACTUALISED ? actualisedTotal : recommendedBidTotal;
