import { FETCH_EXCHANGE_RATES_SUCCESS } from './actions';

const defaultState = {
  rates: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_EXCHANGE_RATES_SUCCESS:
      return {
        ...state,
        rates: action.exchangeRates.baseCurrencies,
      };

    default:
      return state;
  }
};
