import React from 'react';
import { find } from 'lodash/fp';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { compose, withHandlers } from 'recompose';
import { EstimateStatuses } from 'cr-core/constants';
import { Events, track, getEstimateData } from 'components/analytics';
import { SmallFormButton } from 'components/buttons';
import SvgAddIcon from 'icons/AddIcon';
import { getEstimateById } from 'state/estimates/selectors';
import { addCostCategory } from 'state/costCategories/actions';
import withEditConfirmationDialog from '../estimateEdit/withEditConfirmationDialog';
import { colors } from 'shared/styles';

const COST_CATEGROY_ID = 'afafba3b-7853-4654-b46d-d8b1b4c15c59';

const Button = styled(SmallFormButton)`
  background-color: ${colors.button};
  height: auto;
  width: auto;
  margin: 5px;
  padding: 15px;
  border: 0;
  opacity: 0.8;
  transition: 0.2s opacity;

  &:hover {
    opacity: 1;
    color: white;
    background-color: ${colors.buttonHover};
  }

  .icon {
    font-size: inherit;
  }
`;

const AddBidButton = ({ onClick, trackClick, style, estimate }) => {
  if (
    estimate.status === EstimateStatuses.DRAFT ||
    estimate.status === EstimateStatuses.CANCELLED ||
    find({ id: COST_CATEGROY_ID }, estimate.lineItemGroups)
  ) {
    return <div></div>;
  }

  return (
    <Button
      onClick={() => {
        trackClick();
        onClick();
      }}
      style={style}
      data-test="add-supplemetaty-costs"
    >
      <SvgAddIcon className="icon" />
      Add Suppl. Costs
    </Button>
  );
};

const mapStateToProps = (state, { estimateId }) => ({
  estimate: getEstimateById(estimateId)(state),
});

const mapDispatchToProps = (dispatch, { estimateId, pageId }) => ({
  onClick: () => dispatch(addCostCategory(estimateId, COST_CATEGROY_ID, 99, pageId)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    trackClick: ({ estimate }) => () =>
      track(Events.ESTIMATE_ADD_SUPPLEMENTARY_COSTS_CLICK, getEstimateData(estimate)),
  }),
  withEditConfirmationDialog('onClick', {
    trackEvent: {
      success: Events.ESTIMATE_ADD_SUPPLEMENTARY_COSTS_SUCCESS,
      cancel: Events.ESTIMATE_ADD_SUPPLEMENTARY_COSTS_CANCEL,
    },
  })
)(AddBidButton);
