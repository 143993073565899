import React, { useEffect } from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import AppContainer from 'components/appContainer';
import { getUsersPagination } from 'state/users/selectors';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Hidden } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/Add';
import BackupIcon from '@material-ui/icons/Backup';
import InviteUsers from 'components/users/inviteUsers';
import UserDetails from 'components/users/userDetails';
import UsersFilters from 'components/users/usersFilters';
import UsersList from 'components/users/usersList';
import { getAccountById } from 'state/accounts/selectors';
import { getUser } from 'state/authentication/selectors';
import { openSidePanel, closeSidePanel } from 'state/ui/actions';
import { getSidePanelData } from 'state/ui/selectors';
import { getSearchFromQSParams } from 'shared/browserUtils';

const sidePanelViews = {
  userDetails: 'USER_DETAILS',
  inviteUsers: 'INVITE_USERS',
};

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  headerRight: {
    display: 'flex',
  },
}));

const UsersPage = ({
  pagination,
  sidePanelData,
  openSidePanel,
  closeSidePanel,
  match,
  history,
  user,
  location,
  account,
}) => {
  const classes = useStyles();
  const canInviteUser = account && (!account.onlyAdminInviteUsers || user.isAdmin);

  let sidePanelContent;
  if (sidePanelData.view === sidePanelViews.userDetails) {
    sidePanelContent = <UserDetails />;
  } else if (canInviteUser && sidePanelData.view === sidePanelViews.inviteUsers) {
    sidePanelContent = <InviteUsers />;
  } else {
    sidePanelContent = null;
  }

  useEffect(() => {
    const { userId } = match.params;
    if (canInviteUser && userId && userId === 'new') {
      openSidePanel({ view: sidePanelViews.inviteUsers });
    }
    return () => closeSidePanel();
  }, [canInviteUser, match]); // eslint-disable-line react-hooks/exhaustive-deps

  const inviteUsers = () => {
    history.push({ pathname: '/users/new', search: getSearchFromQSParams() });
    openSidePanel({ view: sidePanelViews.inviteUsers });
  };

  const onCloseSidePanel = () => history.push('/users' + location.search);

  return (
    <AppContainer sidePanelContent={sidePanelContent} onCloseSidePanel={onCloseSidePanel}>
      <div className={classes.header}>
        <div className={classes.headerRight}>
          <Typography variant="h4">Users</Typography>
          <UsersFilters />
        </div>
        <div>
          {Boolean(pagination.totalResults) && (
            <Hidden smDown>{pagination.totalResults} users</Hidden>
          )}
          {user.isAdmin && (
            <Button
              variant="contained"
              className={classes.button}
              startIcon={<BackupIcon />}
              disabled
            >
              Import csv
            </Button>
          )}
          {account && canInviteUser && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<AddCircleIcon />}
              onClick={inviteUsers}
            >
              Add User
            </Button>
          )}
        </div>
      </div>
      <UsersList />
    </AppContainer>
  );
};

const mapStateToProps = (state, { location }) => {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const accountId = get('accountId', queryParams);

  return {
    pagination: getUsersPagination(state),
    sidePanelData: getSidePanelData(state),
    user: getUser(state),
    account: getAccountById(accountId)(state),
  };
};

const mapDispatchToProps = dispatch => ({
  openSidePanel: data => dispatch(openSidePanel(data)),
  closeSidePanel: () => dispatch(closeSidePanel()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
