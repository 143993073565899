import React from 'react';

const SvgAttachmentsIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="M21.105 9.952a.287.287 0 0 1 .384-.132l1.147.584a.288.288 0 0 1 .12.389l-6.4 12.559c-1.298 2.548-4.341 3.595-6.804 2.34-2.462-1.255-3.404-4.332-2.106-6.88l6.802-13.35c.964-1.892 3.225-2.67 5.054-1.738 1.828.931 2.528 3.218 1.564 5.11L14.87 20.601c-.63 1.235-2.109 1.744-3.303 1.135-1.194-.608-1.652-2.104-1.022-3.339L16.138 7.42a.287.287 0 0 1 .384-.132l1.147.584a.288.288 0 0 1 .12.389l-5.594 10.977a.588.588 0 0 0 .241.792.588.588 0 0 0 .783-.27l5.996-11.769c.484-.948.132-2.096-.783-2.562-.915-.466-2.05-.076-2.533.873l-6.802 13.35c-.818 1.605-.225 3.544 1.324 4.333 1.55.79 3.467.13 4.285-1.475l6.399-12.56z"
    />
  </svg>
);

export default SvgAttachmentsIcon;
