import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getDisplayCurrency, getFlags } from 'state/authentication/selectors';
import { getEstimates } from 'state/estimates/selectors';
import { Message, ReportHeader } from '../components';
import Table from './table';
import Chart from './chart';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
  estimateCard: {
    marginBottom: 0,
    borderRadius: 0,
  },
}));

const Report = ({ estimates, displayCurrency, flags }) => {
  const classes = useStyles();
  const title = 'Cumulative Spend Report';

  return (
    <div className={classes.container}>
      <ReportHeader title={title} />
      {estimates && !estimates.length && <Message>No data for the selected filters</Message>}
      {estimates && Boolean(estimates.length) && (
        <>
          <Chart estimates={estimates} />
          <Table estimates={estimates} title={title} />
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  estimates: getEstimates(state),
  displayCurrency: getDisplayCurrency(state),
  flags: getFlags(state),
});

export default connect(mapStateToProps)(Report);
