import React from 'react';
import clsx from 'clsx';
import { map, slice } from 'lodash/fp';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    // height: theme.spacing(3),
    // width: theme.spacing(3),
    marginLeft: -theme.spacing(1),
    '&:first-child': {
      marginLeft: 0,
    },
  },
  more: {
    fontSize: '0.875rem',
  },
}));

export default ({ members, maxUsers = 5, size = 24 }) => {
  const classes = useStyles();
  const moreCount = members.length - maxUsers;
  const allMembers = members;
  const showMore = moreCount > 0;
  if (showMore) {
    members = slice(0, maxUsers, allMembers);
  }
  return (
    <div className={classes.container}>
      {map(
        user => (
          <Avatar
            alt={user.firstName}
            src={user.picture}
            className={classes.avatar}
            title={user.firstName ? `${user.firstName} ${user.lastName}` : user.email}
            key={user.id}
            style={{
              height: size,
              width: size,
            }}
          />
        ),
        members
      )}
      {showMore && (
        <Avatar
          className={clsx(classes.avatar, classes.more)}
          style={{
            height: size,
            width: size,
          }}
          title={map(
            user => (user.firstname ? `${user.firstName} ${user.lastName} \n` : `${user.email} \n`),
            allMembers.slice(maxUsers)
          )}
        >
          +{moreCount}
        </Avatar>
      )}
    </div>
  );
};
