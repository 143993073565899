import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get, map } from 'lodash/fp';
import { branch, compose, renderComponent, withContext, lifecycle } from 'recompose';
import PropTypes from 'prop-types';
import { canEdit, getEstimateById } from 'state/estimates/selectors';
import BidsTable from './table';
import { FullScreenLoader } from 'components/spinner';
import { getDisplayCurrency } from 'state/authentication/selectors';
import { fetchCostCategories } from 'state/costCategories/actions';
import { getEstimateFromHistoryItem } from 'state/estimateHistory/selectors';

const getEstimate = ({ id, historyId }) =>
  historyId ? getEstimateFromHistoryItem(historyId) : getEstimateById(id);

const mapStateToProps = (state, { match, ...props }) => {
  const estimate = getEstimate(match.params)(state);
  return {
    estimate,
    quoteCurrency: getDisplayCurrency(state),
    bidIds: map('id', estimate.bids),
    costCategoryIds: map('id', estimate.lineItemGroups),
    canEdit: canEdit(estimate ? estimate.id : undefined)(state),
  };
};

const mapDispatchToProps = (dispatch, state) => ({
  fetchCostCategories: () => {
    const accountId = get('estimate.campaign.workspace.accountId', state);

    if (accountId) {
      return dispatch(fetchCostCategories(accountId));
    }
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  branch(
    ({ estimate }) => !estimate || !estimate.mediaType || !estimate.exchangeRates,
    renderComponent(FullScreenLoader),
  ),
  withContext(
    {
      estimate: PropTypes.object,
      readOnly: PropTypes.bool,
      expanded: PropTypes.bool,
    },
    ({ estimate, readOnly = false, canEdit }) => ({
      estimate,
      readOnly: !canEdit || readOnly,
      expanded: true,
    }),
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchCostCategories();
    },
  }),
)(BidsTable);
