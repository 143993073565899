import React from 'react';

const SvgLaptopIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <filter
        id="laptop-icon_svg__a"
        width="128.6%"
        height="142.1%"
        x="-14.3%"
        y="-21.1%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.156221694 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill="currentColor"
      d="M23.333 16.625c1.284 0 2.322-1.069 2.322-2.375l.012-11.875c0-1.306-1.05-2.375-2.334-2.375H4.667C3.383 0 2.333 1.069 2.333 2.375V14.25c0 1.306 1.05 2.375 2.334 2.375H0V19h28v-2.375h-4.667zM4.667 2.375h18.666V14.25H4.667V2.375z"
      filter="url(#laptop-icon_svg__a)"
      transform="translate(1 6)"
    />
  </svg>
);

export default SvgLaptopIcon;
