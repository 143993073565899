import axios, { AxiosRequestConfig, Method } from 'axios';
import config from 'config';
import { LOGOUT_SUCCESS } from 'state/authentication/actions';
import store from 'state/store';

const { apiEndpoint } = config;

const client = axios.create();

let requestInterceptor: number | null = null;
let responseInterceptor: number | null = null;

const setRequestInterceptor = () => {
  if (requestInterceptor !== null) {
    return;
  }

  requestInterceptor = client.interceptors.request.use(
    config => {
      const token = window.localStorage.getItem('access_token');

      if (token !== null) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
    },
    error => Promise.reject(error)
  );
};

const setResponseInterceptor = () => {
  if (responseInterceptor !== null) {
    return;
  }

  responseInterceptor = client.interceptors.response.use(undefined, error => {
    if (error && error.response && error.response.status === 401) {
      // TODO: do this properly later on and remove redux
      window.localStorage.removeItem('id_token');
      window.localStorage.removeItem('access_token');
      window.localStorage.removeItem('profile');

      store.dispatch({ type: LOGOUT_SUCCESS });
    }

    return Promise.reject(error);
  });
};

export const setInterceptors = () => {
  setRequestInterceptor();
  setResponseInterceptor();
};

export const clearInterceptors = () => {
  if (requestInterceptor !== null) {
    client.interceptors.request.eject(requestInterceptor);
    requestInterceptor = null;
  }

  if (responseInterceptor !== null) {
    client.interceptors.response.eject(responseInterceptor);
    responseInterceptor = null;
  }
};

const request = async <R = unknown>(
  method: Method,
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig
) => {
  return client.request<R>({
    ...config,
    method,
    url: `${apiEndpoint}${url}`,
    data,
  });
};

const ApiService = {
  get: <R>(url: string, config?: AxiosRequestConfig) => request<R>('GET', url, undefined, config),

  post: <R>(url: string, data?: unknown, config?: AxiosRequestConfig) =>
    request<R>('POST', url, data, config),

  put: <R>(url: string, data?: unknown, config?: AxiosRequestConfig) =>
    request<R>('PUT', url, data, config),

  delete: <R>(url: string, config?: AxiosRequestConfig) =>
    request<R>('DELETE', url, undefined, config),
};

export default ApiService;
