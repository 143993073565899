import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { get, isUndefined, difference, isString, map, without, getOr } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Paper, List, Popper } from '@material-ui/core';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { InputComponent } from './input';
import { NoResultsOptions, LoadingOption, SelectAllOption, SimpleOption } from './options';
import SelectedItems from '../SelectedItems';

export { InputComponent, NoResultsOptions, LoadingOption, SelectAllOption, SimpleOption };

const useStyles = makeStyles(theme => {
  return {
    popper: {
      zIndex: 100,
    },
    listbox: {
      margin: 0,
      padding: 0,
      zIndex: 1,
      listStyle: 'none',
      overflow: 'auto',
      maxHeight: 200,
    },
    listIcon: {
      minWidth: 24,
    },
    listItem: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    listItemImg: {
      height: 10,
      display: 'inline',
    },
  };
});

export const BaseComponent = ({
  name,
  label,
  values,
  options,
  getInputProps,
  getInputLabelProps,
  selectedCount,
  isSelectedAll,
  setAnchorEl,
  anchorEl,
  getRootProps,
  getListboxProps,
  className,
  disableValue,
  children,
  margin,
  noRenderValues,
  showMultipleValues,
}) => {
  const classes = useStyles();
  const isMenuOpen = getRootProps()['aria-expanded'];

  return (
    <FormControl className={className} variant="outlined" fullWidth>
      <div {...getRootProps()}>
        <InputComponent
          name={name}
          label={label}
          values={values}
          options={options}
          getInputProps={getInputProps}
          getInputLabelProps={getInputLabelProps}
          isMenuOpen={isMenuOpen}
          disableValue={disableValue}
          selectedCount={selectedCount}
          isSelectedAll={isSelectedAll}
          setAnchorEl={setAnchorEl}
          margin={margin}
          noRenderValues={noRenderValues}
          showMultipleValues={showMultipleValues}
        />
      </div>

      {isMenuOpen && (
        <Popper
          open
          role="presentation"
          anchorEl={anchorEl}
          style={{
            width: anchorEl ? anchorEl.clientWidth : null,
          }}
          className={classes.popper}
        >
          <Paper {...getListboxProps()}>
            <List className={classes.listbox}>{children}</List>
          </Paper>
        </Popper>
      )}
    </FormControl>
  );
};

const MultiSearchAutocomplete = ({
  id,
  name,
  options = [],
  displayStackedOptions = false,
  label,
  setValue,
  values = [],
  isLoading,
  className,
  margin,
  disableSelectAll,
  filterSelectedOptions,
  noRenderValues,
  showMultipleValues,
  withCheckbox,
}) => {
  const classes = useStyles();
  const {
    getRootProps,
    // getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getPopupIndicatorProps,
    groupedOptions,
    anchorEl,
    setAnchorEl,
  } = useAutocomplete({
    id,
    options,
    multiple: true,
    getOptionLabel: get('label'),
    filterSelectedOptions,
  });

  const selectedCount = values.length;
  const isSelectedAll = selectedCount > 0 && selectedCount === options.length;
  const isMenuOpen = getRootProps()['aria-expanded'];
  const showSelectAll = !disableSelectAll && !(isMenuOpen && getInputProps().value);

  const isSelected = value => values.includes(value);

  const handleItemClick = ({ value }) => {
    if (value === 'select-all') {
      setValue(isSelectedAll ? [] : map('value', options));
    } else if (values.includes(value)) {
      setValue(without([value], values));
    } else {
      setValue([...values, value]);
    }
    if (!withCheckbox) {
      // close the menu
      getPopupIndicatorProps().onClick();
    }
  };

  const optionProps = ({ option, index }) => {
    const props = getOptionProps({ option, index });
    props.onClick = () => handleItemClick(option, index);
    if (isUndefined(props.key)) {
      props.key = option;
    }
    props['aria-selected'] = values.includes(option);
    if (props['aria-selected']) {
      props.className = classes.optionSelected;
    }
    return props;
  };

  return (
    <>
      <BaseComponent
        name={name}
        label={label}
        values={values}
        options={options}
        getInputProps={getInputProps}
        selectedCount={selectedCount}
        isSelectedAll={isSelectedAll}
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
        getRootProps={getRootProps}
        getListboxProps={getListboxProps}
        className={className}
        margin={margin}
        disableValue={displayStackedOptions}
        noRenderValues={noRenderValues}
        showMultipleValues={showMultipleValues}
      >
        {isLoading ? (
          <LoadingOption />
        ) : groupedOptions.length > 0 ? (
          <>
            {showSelectAll && (
              <SelectAllOption
                optionProps={optionProps}
                isSelectedAll={isSelectedAll}
                selectedCount={selectedCount}
              />
            )}
            {groupedOptions.map((option, index) => (
              <SimpleOption
                option={option}
                index={index}
                optionProps={optionProps}
                isSelected={isSelected}
                key={index}
                withCheckbox={withCheckbox}
              />
            ))}
          </>
        ) : (
          <NoResultsOptions />
        )}
      </BaseComponent>
      {displayStackedOptions && (
        <SelectedItems selected={values} options={options} removeOption={handleItemClick} />
      )}
    </>
  );
};

const AutocompleteMultiSelect = ({
  displayStackedOptions,
  updateSelectedWorkspaces = false,
  clearSelectedOptions = false,
  ...props
}) => {
  // TODO add useMemo
  const id = get('id', props);
  const label = get('label', props);
  const name = get('field.name', props);
  const fieldOptions = get('options', props);
  const isLoading = get('isLoading', props);
  const className = get('className', props);
  const margin = get('margin', props);
  const withCheckbox = getOr(true, 'withCheckbox', props);
  const filterSelectedOptions = get('filterSelectedOptions', props);
  const noRenderValues = get('noRenderValues', props);
  const showMultipleValues = get('showMultipleValues', props);
  const [field, , helpers] = useField(name);
  const options =
    fieldOptions.length && isString(fieldOptions[0])
      ? map(value => ({ value, label: value }), fieldOptions)
      : fieldOptions;
  const [selectedValues, setSelectedValues] = useState(field.value || []);
  const setSelectedValuesWrapped = values => {
    setSelectedValues(values);
    helpers.setValue(values);
    updateSelectedWorkspaces && updateSelectedWorkspaces(values);
  };

  useEffect(() => {
    clearSelectedOptions && !!field.value?.length && helpers.setValue([]);

    if (
      field.value.length !== selectedValues.length ||
      difference(field.value, selectedValues).length > 0
    ) {
      setSelectedValues(field.value);
    }
  }, [field.value, selectedValues, clearSelectedOptions, helpers]);

  return (
    <MultiSearchAutocomplete
      id={id}
      name={name}
      options={options}
      label={label}
      isLoading={isLoading}
      values={selectedValues}
      setValue={setSelectedValuesWrapped}
      className={className}
      margin={margin}
      displayStackedOptions={displayStackedOptions}
      disableSelectAll={withCheckbox === false}
      filterSelectedOptions={filterSelectedOptions}
      noRenderValues={noRenderValues}
      showMultipleValues={showMultipleValues}
      withCheckbox={withCheckbox}
    />
  );
};

export default AutocompleteMultiSelect;
