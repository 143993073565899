import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose, get } from 'lodash/fp';
import onClickOutside from 'react-onclickoutside';
import { withState, withHandlers, withProps } from 'recompose';
import withEditConfirmationDialog from 'components/estimateEdit/withEditConfirmationDialog';
import SaveCancelPopup from 'components/forms/saveCancelPopup';
import { saveLineItemValue } from 'state/bids/actions';
import { getBidValueForLineItemId, getLineItemById } from 'state/bids/selectors';
import { shadows } from 'shared/styles';
import { DropdownCell } from './tableComponents';
import { Select } from 'components/forms';
import { CountryOption, CountryMultiValueLabel, countries } from 'components/countrySelect';

const EditableContainer = styled(DropdownCell)`
  padding: 0;
  min-height: 100%;
  width: 100%;
  border: 1px solid #2684ff;
  position: relative;
  overflow: visible;
`;

export const PseudoInput = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: ${shadows.inputBoxShadow};

  border: 1px solid lightgray;
  background-color: white;
`;

const EditableCell = ({
  handleSubmit,
  cancel,
  isDirty,
  onValueChange,
  value,
  handleFocus,
  handleKeyPress,
  className,
  mandatory,
  costCategoryId,
}) => {
  return (
    <EditableContainer className={className}>
      <PseudoInput>
        <Select
          isMulti
          defaultValue={value}
          name="value"
          autoFocus
          options={countries}
          onChange={onValueChange}
          value={value}
          components={{ Option: CountryOption, MultiValueLabel: CountryMultiValueLabel }}
          placeholder="Select Country"
          className="selected-countries-select"
          styles={{
            control: (base, state) => ({
              ...base,
              border: 'none',
              borderRadius: '0%',
              boxShadow: 'none',
              height: 'auto !important',
            }),
          }}
        />
      </PseudoInput>
      {isDirty && <SaveCancelPopup onSave={handleSubmit} onCancel={cancel} />}
    </EditableContainer>
  );
};

export const EditableCountryDropdown = compose(
  withState('value', 'setValue', get('initialValue')),
  withHandlers({
    onValueChange:
      ({ setValue }) =>
      item => {
        setValue(item);
      },
  }),
  withProps(({ value, initialValue }) => {
    return {
      handleFocus: event => event.target.select(),
      isDirty: value !== initialValue,
    };
  }),
  withProps(({ action, value, isDirty }) => ({ handleSubmit: () => action(value, isDirty) })),
  withEditConfirmationDialog('handleSubmit'),
  withProps(({ isDirty, handleSubmit, save }) => ({
    saveValue: event => {
      isDirty && handleSubmit();
      !isDirty && save();
    },
  })),
  withProps(({ saveValue }) => ({
    handleClickOutside: evt => saveValue(),
    handleKeyPress: event => {
      if (event.key === 'Enter') {
        saveValue();
      }
    },
  })),
  onClickOutside
)(EditableCell);

const mapStateToProps = (state, { bidId, lineItemNameId }) => {
  const lineItemName = getLineItemById(lineItemNameId)(state);
  return {
    initialValue: getBidValueForLineItemId(bidId, lineItemNameId)(state)
      ? countries.filter(item =>
          getBidValueForLineItemId(bidId, lineItemNameId)(state).split(', ').includes(item.label)
        )
      : '',
    mandatory: lineItemName.mandatory,
    costCategoryId: lineItemName.costCategoryId,
  };
};

const mapDispatchToProps = (
  dispatch,
  { estimateId, bidId, lineItemNameId, save, pageId, mandatory, costCategoryId }
) => ({
  action: (value, isDirty) => {
    const mappedValue = (value || []).map(item => item.label).join(', ');
    if (isDirty) {
      dispatch(
        saveLineItemValue({
          estimateId,
          bidId,
          lineItemNameId,
          value: mappedValue,
          pageId,
          mandatory,
          costCategoryId,
        })
      );
    }
    save();
  },
});

export default compose(
  connect(mapStateToProps),
  connect(null, mapDispatchToProps)
)(EditableCountryDropdown);
