import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { Events, track, getEstimateData } from 'components/analytics';
import { RoundButton } from 'components/buttons';
import SvgRecommendedIcon from 'icons/RecommendedIcon';
import { updateEstimate } from 'state/estimates/actions';
import { getEstimateById } from 'state/estimates/selectors';
import withEditConfirmationDialog from '../estimateEdit/withEditConfirmationDialog';

const SelectBidButton = ({ onClick }) => (
  <RoundButton onClick={onClick} data-test="select-bid">
    <SvgRecommendedIcon className="icon" title="Select this bid" />
  </RoundButton>
);

const mapStateToProps = (state, { estimateId }) => ({
  estimate: getEstimateById(estimateId)(state),
});

const mapDispatchToProps = (dispatch, { estimateId, bidId, pageId }) => ({
  selectBid: () => dispatch(updateEstimate(estimateId, { recommendedBid: bidId }, pageId)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    onClick: ({ selectBid, estimate, bidId }) => () => {
      selectBid();
      track(Events.ESTIMATE_SELECT_PREFERRED_BID_CLICK, {
        ...getEstimateData(estimate),
        preferredBidId: bidId,
      });
    },
  }),
  withEditConfirmationDialog('onClick')
)(SelectBidButton);
