import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { get, compact, map, flow, noop, filter, find } from 'lodash/fp';
import { Button, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LockIcon from '@material-ui/icons/Lock';
import { CampaignStatuses, CampaignStatusLabels } from 'cr-core/constants';
import { formatAsCurrency } from 'cr-core/currencyUtils';
import { Events, track } from 'components/analytics';
import Menu from 'components/common/menu';
import { LoadingBlockLong } from 'components/estimateCard';
import LoadingBlock from 'components/loadingBlock';
import { colors } from 'shared/styles';
import { canBeClosed } from 'state/campaigns/selectors';
import { getSidePanelData } from 'state/ui/selectors';
import EstimateListItem, {
  EstimateListItemLoading,
  EmptyListPlaceHolder,
  NoFilteredResultsPlaceHolder,
  EstimatesFilteredOut,
  estimateHeight,
} from './estimateListItem';
import { sortEstimates } from './utils';

const defaultSpace = 8;
const headerHeight = (1 + defaultSpace) * 2 + 45;
const footerHeight = 2 + defaultSpace * 2 * 2 + 40;
const margins = defaultSpace * 2;
const minimumItemSize = headerHeight + footerHeight + margins;
export const estimatedItemSize = minimumItemSize + estimateHeight * 2;

const useStyles = makeStyles(theme => {
  return {
    listItem: {
      whiteSpace: 'pre',
      textOverflow: 'ellipsis',
      width: '100%',
      background: theme.palette.background.paper,
      flexDirection: 'column',
      textAlign: 'left',
      alignItems: 'flex-start',
      padding: 0,
    },

    header: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.grey[200],
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: headerHeight,
      fontSize: '1rem',
      cursor: 'pointer',
      '&:hover': {
        background: theme.palette.action.hover,
      },
    },
    headerSelcted: {
      background: theme.palette.action.focus,
    },
    headerLeft: {
      flexGrow: 1,
      '&:focus': {
        outline: 'none',
      },
      padding: theme.spacing(1, 2),
      height: headerHeight - 2,
      display: 'flex',
      alignItems: 'center',
    },
    headerRight: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1, 2),
    },

    footer: {
      padding: theme.spacing(2, 2),
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.grey[200],
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: footerHeight,
    },
    footerRight: {
      textAlign: 'right',
    },

    estimates: {
      width: '100%',
    },

    humanId: {
      color: theme.palette.text.secondary,
      display: 'inline-block',
      fontWeight: theme.typography.fontWeightBold,
      width: 48,
    },
    status: {
      marginRight: theme.spacing(0.5),
    },
    lockIcon: {
      marginRight: theme.spacing(0.5),
      color: theme.palette.text.disabled,
    },
    statusActualised: {
      color: colors.actualised,
    },

    name: {
      fontSize: '1rem',
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0.0075em',
      display: 'inline-block',
      marginLeft: theme.spacing(1),
    },

    cost: {
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0.0075em',
    },
    totalCost: {
      fontSize: '1.25rem',
    },
    overbudget: {
      color: theme.palette.error.main,
    },
    savings: {
      color: theme.palette.success.main,
    },
  };
});

const getCampaignEstimates = (campaign, estimates = {}) =>
  flow(
    map(({ id }) => estimates[id]),
    compact,
  )(campaign.estimates || []);

const isItemLoaded = (data, isLoading) => index => Boolean(get('id', data[index]));

const getUrl = (campaignId, workspaceId) =>
  `/estimates/new?campaignId=${campaignId}&workspaceId=${workspaceId}`;

export const getItemSize = (
  campaigns,
  estimates,
  areEstimatesLoading,
  campaignsPageStatus,
) => index => {
  if (!isItemLoaded(campaigns)(index)) {
    return minimumItemSize + estimateHeight * 3;
  }

  const campaignStatus = find({ id: campaigns[index].id }, campaignsPageStatus.campaigns);
  const filteredEstimates = filter({ campaignId: campaigns[index].id }, estimates);
  let estimatesCount =
    (get('estimatesLoaded', campaignStatus)
      ? filteredEstimates.length
      : campaigns[index].estimates.length) || 1;
  const filteredOutHeight = campaigns[index].estimates.length - estimatesCount > 0 ? 30 : 0;
  return minimumItemSize + estimatesCount * estimateHeight + filteredOutHeight;
};

export const CampaignLoading = ({ ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.listItemContainer}>
      <ListItem {...props} className={classes.listItem}>
        <div className={clsx(classes.header, classes.headerLoa)}>
          <div className={classes.headerLeft}>
            <LoadingBlockLong />
          </div>
        </div>

        <div className={classes.estimates}>
          <EstimateListItemLoading />
          <EstimateListItemLoading />
          <EstimateListItemLoading />
        </div>

        <div className={classes.footer}>
          <LoadingBlock />
        </div>
      </ListItem>
    </div>
  );
};

const Header = ({
  campaign,
  editCampaign,
  closeCampaign,
  deleteCampaign,
  openCampaignDetails,
  index,
  showCloseBtn,
  isSelected,
}) => {
  const classes = useStyles();
  const menuItems = compact([
    {
      label: 'Edit Campaign',
      Icon: EditIcon,
      dataTest: 'edit-campaign-menu-item',
      onClick: () => editCampaign('campaign_list_item_menu')(campaign),
    },
    showCloseBtn && {
      label: 'Close Campaign',
      Icon: LockIcon,
      dataTest: 'close-campaign-menu-item',
      onClick: () => closeCampaign('campaign_list_item_menu')(campaign),
    },
    !campaign.estimates.length && {
      label: 'Delete Campaign',
      Icon: DeleteIcon,
      dataTest: 'delete-campaign-menu-item',
      onClick: () => deleteCampaign('campaign_list_item_menu')(campaign),
    },
  ]);

  return (
    <div
      className={clsx(classes.header, {
        [classes.headerSelcted]: isSelected,
      })}
    >
      <div
        className={classes.headerLeft}
        onClick={() => openCampaignDetails(campaign)}
        onKeyPress={noop}
        role="link"
        tabIndex="0"
      >
        <span className={classes.humanId} data-test="campaignHumanId">
          #{campaign.humanId}
        </span>
        <span className={classes.name} data-test="campaignName">
          {campaign.name}
        </span>
      </div>
      <div className={classes.headerRight}>
        <div
          className={clsx(classes.status, {
            [classes.statusActualised]: campaign.status === CampaignStatuses.ACTUALISED,
          })}
          data-test={`${campaign.status.toLowerCase()}-campaign-bagde`}
        >
          {CampaignStatusLabels[campaign.status]}
        </div>
        {[CampaignStatuses.CLOSED, CampaignStatuses.ACTUALISED].includes(campaign.status) && (
          <LockIcon
            className={clsx(classes.lockIcon, {
              [classes.statusActualised]: campaign.status === CampaignStatuses.ACTUALISED,
            })}
            fontSize="small"
            data-test={'closed-campaign-icon'}
          />
        )}
        <Menu items={menuItems} />
      </div>
    </div>
  );
};

const Estimates = ({
  campaign,
  estimates = {},
  displayCurrency,
  areEstimatesLoading,
  isScrolling,
  sidePanelOpened,
}) => {
  const classes = useStyles();
  let list;
  list = getCampaignEstimates(campaign, estimates);
  list = sortEstimates(list);

  if (!campaign.estimates.length) {
    return (
      <div className={classes.estimates}>
        <EmptyListPlaceHolder />
      </div>
    );
  }

  if (!areEstimatesLoading && campaign.estimates.length && !list.length) {
    // && isEmpty(estimates)
    return (
      <div className={classes.estimates}>
        <NoFilteredResultsPlaceHolder />
      </div>
    );
  }

  if (!list.length && campaign.estimates.length && areEstimatesLoading) {
    list = campaign.estimates;
  }

  return (
    <div className={classes.estimates} data-test="campaign-estimates-list">
      {map(estimate => {
        if (get('name', estimate)) {
          return (
            <EstimateListItem
              estimate={estimate}
              displayCurrency={displayCurrency}
              sidePanelOpened={sidePanelOpened}
              key={estimate.id}
            />
          );
        }
        if (areEstimatesLoading) {
          return <EstimateListItemLoading key={estimate.id} />;
        }
        // TODO: handle that if necessary
        return <EstimateListItemLoading key={estimate.id} />;
      }, list)}
      {list.length < campaign.estimates.length && (
        <EstimatesFilteredOut
          campaign={campaign}
          estimates={list}
          displayCurrency={displayCurrency}
        />
      )}
    </div>
  );
};

const Footer = ({ campaign, displayCurrency, setModal, history }) => {
  const classes = useStyles();
  const remainingBudget = campaign.budget - campaign.totalCost;
  const prefix =
    remainingBudget > 0
      ? campaign.status === CampaignStatuses.ACTUALISED
        ? 'savings'
        : 'budget left'
      : 'overbudget of';

  return (
    <div className={classes.footer}>
      <div className={classes.headerLeft}>
        {/*campaign.status === CampaignStatuses.OPEN && ( */}
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddIcon />}
          data-test="create-new-estimate-btn"
          onClick={() => {
            track(Events.ESTIMATE_CREATE_CLICK, { origin: 'page_campaigns__estimate_card' });
            const url = getUrl(campaign.id, campaign.workspaceId);
            if (campaign.status === CampaignStatuses.CLOSED) {
              setModal('openCampaign', { campaignId: campaign.id, url });
            } else {
              history.push(url);
            }
          }}
        >
          Create Estimate
        </Button>
      </div>
      <div className={classes.footerRight}>
        <div className={clsx(classes.cost, classes.totalCost)} data-test="totalCost">
          {formatAsCurrency(displayCurrency, campaign.totalCost)}
        </div>
        <div
          data-test="remaining"
          className={clsx(classes.cost, {
            [classes.overbudget]: remainingBudget < 0,
            [classes.savings]:
              remainingBudget > 0 && campaign.status === CampaignStatuses.ACTUALISED,
          })}
        >
          {prefix} {formatAsCurrency(displayCurrency, Math.abs(remainingBudget))}
        </div>
      </div>
    </div>
  );
};

const CampaignListItem = ({
  campaign,
  estimates,
  areEstimatesLoading,
  editCampaign,
  closeCampaign,
  deleteCampaign,
  openCampaignDetails,
  displayCurrency,
  setModal,
  history,
  index,
  sidePanelOpened,
  showCloseBtn,
  isSelected,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.listItemContainer}>
      <ListItem className={classes.listItem} data-test="campaignListItem">
        <Header
          campaign={campaign}
          editCampaign={editCampaign}
          closeCampaign={closeCampaign}
          deleteCampaign={deleteCampaign}
          openCampaignDetails={openCampaignDetails}
          index={index}
          showCloseBtn={showCloseBtn}
          isSelected={isSelected}
        />
        <Estimates
          campaign={campaign}
          estimates={estimates}
          displayCurrency={displayCurrency}
          areEstimatesLoading={areEstimatesLoading}
          sidePanelOpened={sidePanelOpened}
        />
        <Footer
          campaign={campaign}
          displayCurrency={displayCurrency}
          setModal={setModal}
          history={history}
        />
      </ListItem>
    </div>
  );
};

const mapStateToProps = (state, { campaign }) => ({
  showCloseBtn: canBeClosed(campaign.id)(state),
  isSelected: get('campaign.id', getSidePanelData(state)) === campaign.id,
});

export default connect(mapStateToProps)(CampaignListItem);
