import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useField } from 'formik';
import { get, isEqual } from 'lodash/fp';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FormControl, InputAdornment, Popover, TextField } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { DateRangePicker } from 'react-date-range';
import staticRanges from './staticRanges';

const useStyles = makeStyles(theme => {
  return {
    input: {
      cursor: 'pointer!important',
      color: `${theme.palette.text.primary}!important`,
    },
    inputAdornment: {
      color: theme.palette.text.secondary,
    },
    label: {
      color: `${theme.palette.text.secondary}!important`,
    },
    dateRangePicker: {
      '& .rdrInputRanges': {
        display: 'none',
      },
      '& .rdrStaticRanges': {
        height: '100%',
      },
      '& .rdrStaticRange': {
        flexGrow: 1,
      },
    },
  };
});

const renderValue = ({ start, end } = {}) => {
  if (!start || !end) {
    return '';
  }
  const startValue = moment(start).format('D MMM YY');
  const endValue = moment(end).format('D MMM YY');
  return `${startValue} - ${endValue}`;
};

const DateRangePickerField = ({ ...props }) => {
  const theme = useTheme();
  const classes = useStyles();
  const id = get('id', props);
  const label = get('label', props);
  const className = get('className', props);
  const margin = get('margin', props);
  const name = get('field.name', props);
  const [field, , helpers] = useField(name);
  const [internalValue, setInternalValue] = useState(field.value || {});
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const selectionRange = {
    startDate: internalValue.start || new Date(),
    endDate: internalValue.end || new Date(),
    key: 'selection',
    color: theme.palette.primary.main,
  };
  const renderedValue = renderValue(field.value);

  useEffect(() => {
    if (!isEqual(internalValue, field.value)) {
      setInternalValue(field.value || {});
    }
  }, [field.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelect = value => {
    const { endDate, startDate } = value.selection;
    setInternalValue({
      start: startDate,
      end: endDate,
    });

    if (startDate && endDate && startDate.getTime() !== endDate.getTime()) {
      handleClose();
      helpers.setValue({
        start: startDate,
        end: endDate,
      });
    }
  };

  return (
    <FormControl className={className} variant="outlined" fullWidth>
      <TextField
        id={id}
        variant="outlined"
        margin={margin}
        fullWidth
        name={name}
        label={label}
        value={renderedValue}
        onClick={handleClick}
        InputLabelProps={{
          shrink: Boolean(renderedValue),
          classes: { disabled: classes.label },
        }}
        InputProps={{
          autoFocus: false,
          endAdornment: [
            <InputAdornment position="end" className={classes.inputAdornment} key="toggle-list">
              <DateRangeIcon />
            </InputAdornment>,
          ],
          classes: { root: classes.input, input: classes.input },
        }}
      />

      <Popover
        id={`${id}-popover`}
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        getContentAnchorEl={null}
      >
        <DateRangePicker
          onChange={handleSelect}
          ranges={[selectionRange]}
          staticRanges={staticRanges}
          showDateDisplay={false}
          inputRanges={[]}
          className={classes.dateRangePicker}
        />
      </Popover>
    </FormControl>
  );
};

export default DateRangePickerField;
