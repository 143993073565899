import React, { useMemo, useState } from 'react';
import { difference, filter, flow, get, isUndefined, map, reject, uniq, without } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse, IconButton, List, ListItemSecondaryAction } from '@material-ui/core';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  BaseComponent,
  NoResultsOptions,
  SelectAllOption,
  SimpleOption,
} from './formik.autocompleteMultiSelect';
import SelectedItems from './SelectedItems';

const useStyles = makeStyles(theme => {
  return {
    nested: {
      paddingLeft: theme.spacing(4),
    },

    notchedLabel: {
      maxWidth: 1000,
      transition: `max-width 100ms ${theme.transitions.easeOut} 50ms`,
    },
  };
});

const GroupOption = ({
  group,
  options,
  index,
  isSelected,
  isGroupSelected,
  optionProps,
  getInputProps,
  openNestedList,
  handleToggleNestedList,
  collapsed,
}) => {
  const classes = useStyles();
  const groupOption = options[0];
  options = options.slice(1); // then use +1 on the options index

  return (
    <>
      <SimpleOption
        option={groupOption}
        index={index}
        optionProps={optionProps}
        isSelected={isSelected}
        SecondaryAction={
          Boolean(options.length) && (
            <ListItemSecondaryAction onClick={handleToggleNestedList(group)}>
              <IconButton edge="end" aria-label="delete">
                {collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </ListItemSecondaryAction>
          )
        }
      />
      {Boolean(options.length) && (
        <Collapse in={collapsed} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {options.map((option, secondaryIndex) => (
              <SimpleOption
                option={option}
                key={index + secondaryIndex + 1}
                index={index + secondaryIndex + 1}
                optionProps={optionProps}
                isSelected={isSelected}
                className={classes.nested}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export const MultiSearchGroupAutocomplete = ({
  id,
  options,
  label,
  setValues,
  setGroups,
  values = [],
  groups = [],
  displayStackedOptions = false,
  className,
  margin,
  brandCategories,
  setBrandCategories,
  setBrandCategoriesValues,
}) => {
  const classes = useStyles();
  const [openNestedList, setOpenNestedList] = useState({});
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    anchorEl,
    setAnchorEl,
  } = useAutocomplete({
    id,
    options,
    multiple: true,
    getOptionLabel: get('label'),
    groupBy: get('group'),
  });

  const selectedCount = values.length + groups.length;
  const isSelectedAll = useMemo(
    () => Boolean(groups.length) && difference(uniq(map('group', options)), groups).length === 0,
    [groups, options]
  );

  const isGroupSelected = (value, group) => {
    if (brandCategories && brandCategories.hasOwnProperty(value)) {
      return groups.includes(value);
    }

    if (!group) {
      return false;
    }

    if (!brandCategories) {
      return false;
    }

    if (!brandCategories[group]) {
      return false;
    }

    return values.includes(value) && brandCategories[group].includes(value);
  };

  const isSelected = (value, group) => {
    if (isSelectedAll) {
      return true;
    }

    if (!group || !brandCategories) {
      return false;
    }

    if (!brandCategories) {
      return false;
    }

    if (!brandCategories[group]) {
      return false;
    }

    if (brandCategories[group].includes(group)) {
      return true;
    }

    return values.includes(value) && brandCategories[group].includes(value);
  };

  const removeFromValues = (values, excludeArr) => {
    return values.filter(v => {
      if (excludeArr.includes(v)) {
        excludeArr.splice(excludeArr.indexOf(v), 1);
        return false;
      }
      return true;
    });
  };

  const handleItemClick = ({ value, group }, index) => {
    const items = flow(
      filter({ group }),
      reject(({ group, value }) => group === value)
    )(options);

    if (value === 'select-all') {
      const brandCategoriesValue = {};
      for (const [key] of Object.entries(brandCategories)) {
        setBrandCategories(prevState => ({
          ...prevState,
          [key]: !isSelectedAll ? [key] : [],
        }));
        brandCategoriesValue[key] = !isSelectedAll ? [key] : [];
      }
      setBrandCategoriesValues(brandCategoriesValue);
      setValues([]);
      return setGroups(isSelectedAll ? [] : flow(map('group'), uniq)(options));
    }

    if (brandCategories) {
      if (brandCategories[group].includes(group) && value !== group) {
        setBrandCategories(prevState => ({
          ...prevState,
          [group]: [...flow(map('value'), without([group, value]))(items)],
        }));
        setBrandCategoriesValues({
          ...brandCategories,
          [group]: [...flow(map('value'), without([group, value]))(items)],
        });
      } else if (
        !brandCategories[group].includes(value) &&
        flow(map('value'), without([value]))(items).every(v => brandCategories[group].includes(v))
      ) {
        setBrandCategories(prevState => ({
          ...prevState,
          [group]: [group],
        }));
        setBrandCategoriesValues({
          ...brandCategories,
          [group]: [group],
        });
      } else if (
        !brandCategories[group].includes(value) &&
        !brandCategories[group].includes(group)
      ) {
        setBrandCategories(prevState => ({
          ...prevState,
          [group]: [...prevState[group], value],
        }));
        setBrandCategoriesValues({
          ...brandCategories,
          [group]: [...brandCategories[group], value],
        });
      } else {
        setBrandCategories(prevState => ({
          ...prevState,
          [group]: without([value], prevState[group]),
        }));
        setBrandCategoriesValues({
          ...brandCategories,
          [group]: without([value], brandCategories[group]),
        });
        setValues(removeFromValues(values, [value, group]));
        setGroups(without([group], groups));
      }
    }

    if (value === group) {
      if (groups.includes(group)) {
        setBrandCategories(prevState => ({
          ...prevState,
          [group]: [],
        }));
        setBrandCategoriesValues({
          ...brandCategories,
          [group]: [],
        });
        setValues(removeFromValues(values, [group]));
        return setGroups(without([group], groups));
      }
      setValues(removeFromValues(values, [...map('value', items), group]));
      return setGroups([...groups, group]);
    }

    if (groups.includes(group)) {
      // deselect single option
      setValues(values.concat(...flow(map('value'), without([value]))(items)));
      return setGroups(without([group], groups));
    }

    const groupValues = map('value', items);

    let newValues = [];
    if (brandCategories && brandCategories[group].includes(value) && values.includes(value)) {
      const index = values.indexOf(value);
      if (index > -1) {
        values.splice(index, 1);
      }
      newValues = values;
    } else {
      newValues = values.concat(value);
    }

    if (difference(groupValues, newValues).length === 0) {
      // all group options selected
      setValues(removeFromValues(values, groupValues));
      return setGroups([...groups, group]);
    }

    setValues(newValues);
  };

  const optionProps = ({ option, index }) => {
    const props = getOptionProps({ option, index });
    props.onClick = () => handleItemClick(option, index);
    if (isUndefined(props.key)) {
      props.key = option;
    }
    props['aria-selected'] =
      (values.includes(option.value) && brandCategories[option.group].includes(option.value)) ||
      (option.value === option.group && groups.includes(option.group));
    if (props['aria-selected']) {
      props.className = classes.optionSelected;
    }
    return props;
  };

  const handleToggleNestedList = group => event => {
    event.stopPropagation();
    setOpenNestedList({ ...openNestedList, [group]: !openNestedList[group] });
  };

  let getInputLabelPropsFn = getInputLabelProps;
  let getInputPropsFn = getInputProps;
  if (selectedCount) {
    getInputPropsFn = () => ({
      ...getInputProps(),
      notched: `${Boolean(selectedCount)}`,
      className: classes.notchedLabel,
    });

    getInputLabelPropsFn = () => ({
      ...getInputLabelProps(),
      // wtf is this for?
      // shrink: Boolean(selectedCount),
    });
  }

  return (
    <>
      <BaseComponent
        label={label}
        values={values.filter(v => !groups.includes(v)).concat(groups)}
        options={options}
        getInputProps={getInputPropsFn}
        getInputLabelProps={getInputLabelPropsFn}
        selectedCount={selectedCount}
        isSelectedAll={isSelectedAll}
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
        disableValue={displayStackedOptions}
        getRootProps={getRootProps}
        getListboxProps={getListboxProps}
        className={className}
        margin={margin}
      >
        {groupedOptions.length > 0 ? (
          <>
            {!(groupedOptions.length > 0 && getInputProps().value) && (
              <SelectAllOption
                optionProps={optionProps}
                isSelectedAll={isSelectedAll}
                selectedCount={selectedCount}
              />
            )}
            {groupedOptions.map(({ group, options, index }) => (
              <GroupOption
                key={index}
                group={group}
                options={options}
                index={index}
                isSelected={isSelected}
                isGroupSelected={isGroupSelected}
                optionProps={optionProps}
                getInputProps={getInputProps}
                openNestedList={openNestedList}
                handleToggleNestedList={handleToggleNestedList}
                collapsed={openNestedList[group] || Boolean(getInputProps().value)}
              />
            ))}
          </>
        ) : (
          <NoResultsOptions />
        )}
      </BaseComponent>
      {displayStackedOptions && (
        <SelectedItems
          selected={values.filter(v => !groups.includes(v)).concat(groups)}
          brandCategories={brandCategories}
          options={options}
          removeOption={handleItemClick}
        />
      )}
    </>
  );
};
