import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

export default ({ open, handleClose, title, message, actionLabel }) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id="confirm-modal"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary" data-test="confirm-modal-cancel">
          Cancel
        </Button>
        <Button onClick={() => handleClose(true)} color="primary" data-test="confirm-modal-confirm">
          {actionLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
